import { useQuery } from 'react-query'
import { crudService } from '_services'

const getPhotoAlbumId = async (id: Number) => {
  const { data } = await crudService.get(`albuns/${id}`)
  return data
}

export default function usePhotoAlbumId(id: Number) {
  return useQuery(['photoAlbum-id', id], () => getPhotoAlbumId(id), {
    enabled: id > 0,
    cacheTime: 0
  })
}
