import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

//? Own imports
import { PlanPrice } from 'types/api'
import { crudService } from '_services'

const deletePlanPrice = async (idPlanPrice: Number) => {
  const { data } = await crudService.deleteDetail(`planosValor/${idPlanPrice}`)
  return data
}

export default function useMutationPlanPriceDelete() {
  const queryClient = useQueryClient()
  return useMutation(
    'PlanPrice-delete',
    (idPlanPrice: Number) => deletePlanPrice(idPlanPrice),
    {
      onError: (error) => {
        toast.error(
          // @ts-ignore
          error.message || 'Erro ao excluir valor/periodicidade do plano!'
        )
      },
      onSuccess: (response: PlanPrice) => {
        toast.success('Valor/Periodicidade do plano excluído com sucesso!')
      }
    }
  )
}
