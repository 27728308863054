import { crudService } from '../_services'
import { history, slugMask } from '../utils'
import { toast } from 'react-toastify'

export const cupomDescontoAction = {
  getUtilizacaoCupomDesconto,
  getCupomDesconto,
  getCupomDescontoById,
  getCupomDescontoByEstabelecimento,
  getCupomDescontoByEvento,
  onChangeProps,
  onChangeData,
  editCupomDescontoInfo,
  createCupomDesconto,
  deleteCupomDescontoById,
  selectValidOnAllEventos,
  clear,
  getListCupomDesconto
}

function getUtilizacaoCupomDesconto(loteId, eventoId, estabelecimentoId) {
  return (dispatch) => {
    let apiEndpoint = `cuponsDesconto/utilizacao?LoteId=${loteId}&EventoId=${eventoId}&EstabelecimentoId=${estabelecimentoId}`
    dispatch(request())
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request() {
    return { type: 'REQUEST_UTILIZACAO_CUPOM_DESCONTO' }
  }

  function success(utilizacaoCuponsDesconto) {
    return {
      type: 'REQUEST_UTILIZACAO_CUPOM_DESCONTO_SUCCESS',
      utilizacaoCuponsDesconto: utilizacaoCuponsDesconto
    }
  }

  function failure(error) {
    return { type: 'REQUEST_UTILIZACAO_CUPOM_DESCONTO_FAILURE', error }
  }
}

function getListCupomDesconto(loteId, eventoId, estabelecimentoId) {
  return (dispatch) => {
    let apiEndpoint = `cuponsDesconto/lista?LoteId=${loteId}&EventoId=${eventoId}&EstabelecimentoId=${estabelecimentoId}`
    dispatch(request())
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request() {
    return { type: 'REQUEST_LISTA_CUPOM_DESCONTO' }
  }

  function success(listaCupomDesconto) {
    return {
      type: 'REQUEST_LISTA_CUPOM_DESCONTO_SUCCESS',
      cupomDesconto: listaCupomDesconto
    }
  }

  function failure(error) {
    return { type: 'REQUEST_LISTA_CUPOM_DESCONTO_FAILURE', error }
  }
}

function getCupomDesconto() {
  return (dispatch) => {
    let apiEndpoint = 'cuponsDescontos'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeCuponsDescontosList(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getCupomDescontoByEstabelecimento(estabelecimentoId) {
  return (dispatch) => {
    let apiEndpoint = 'cuponsDesconto/estabelecimento/' + estabelecimentoId
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeCuponsDescontosList(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function getCupomDescontoByEvento(eventoId) {
  return (dispatch) => {
    let apiEndpoint = 'cuponsDesconto/evento/' + eventoId
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeCuponsDescontosListByEvento(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createCupomDesconto(payload) {
  return (dispatch) => {
    let apiEndpoint = 'cuponsDesconto/'
    crudService.post(apiEndpoint, payload).then((response) => {
      dispatch(createUserInfo())
      toast.success('Feito! Cupom de desconto cadastrado! 😁')
      history.push('/cupomDescontos')
    })
  }
}

function getCupomDescontoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'cuponsDesconto/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editCuponsDescontosDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      if (props === 'codigo') {
        var valorFormatado = slugMask(event.target.value)
        dispatch(handleOnChangeProps(props, valorFormatado.toUpperCase()))
      } else {
        dispatch(handleOnChangeProps(props, event.target.value))
      }
    }
  }
}

function selectValidOnAllEventos(event) {
  return (dispatch) => {
    dispatch(handleOnChangeProps('validOnAllEventos', event.target.checked))
    if (event.target.checked) {
      dispatch(handleOnChangeProps('eventoId', ''))
      dispatch(handleOnChangeProps('loteId', ''))
    }
  }
}

function editCupomDescontoInfo(id, payload) {
  return (dispatch) => {
    let apiEndpoint = 'cuponsDesconto/' + id
    crudService.put(apiEndpoint, payload).then(() => {
      dispatch(updatedUserInfo())
      history.push('/cupomDescontos')
    })
  }
}

function deleteCupomDescontoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'cuponsDesconto/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteCuponsDescontosDetails())
      dispatch(
        cupomDescontoAction.getCupomDescontoByEstabelecimento(
          response.data.estabelecimentoId
        )
      )
    })
  }
}

function onChangeData(prop, newValue) {
  return (dispatch) => {
    dispatch(handleOnChangeProps(prop, newValue))
  }
}

export function changeCuponsDescontosList(cupomDesconto) {
  return {
    type: 'FETCHED_ALL_CUPOMDESCONTO',
    cupomDesconto: cupomDesconto
  }
}
export function changeCuponsDescontosListByEvento(cupomDesconto) {
  return {
    type: 'FETCHED_ALL_CUPOMDESCONTO_BY_EVENTO',
    cupomDesconto: cupomDesconto
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'CUPOMDESCONTO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editCuponsDescontosDetails(cupomDesconto) {
  return {
    type: 'CUPOMDESCONTO_DETAIL',
    id: cupomDesconto._id,
    codigo: cupomDesconto.codigo,
    percentualDesconto: cupomDesconto.percentualDesconto,
    quantidadeMinima: cupomDesconto.quantidadeMinima,
    quantidade: cupomDesconto.quantidade,
    valorDesconto: cupomDesconto.valorDesconto,
    dataInicioValidade: cupomDesconto.dataInicioValidade,
    dataTerminoValidade: cupomDesconto.dataTerminoValidade,
    estabelecimentoId: cupomDesconto.estabelecimentoId,
    promoterId: cupomDesconto.promoterId,
    eventoId: cupomDesconto.eventoId,
    loteId: cupomDesconto.loteId,
    excluido: cupomDesconto.excluido
  }
}

export function clear() {
  return {
    type: 'CUPOMDESCONTO_CLEAR'
  }
}
export function updatedUserInfo() {
  return {
    type: 'CUPOMDESCONTO_UPDATED'
  }
}
export function createUserInfo() {
  return {
    type: 'CUPOMDESCONTO_CREATED_SUCCESSFULLY'
  }
}
export function deleteCuponsDescontosDetails() {
  return {
    type: 'DELETED_CUPOMDESCONTO_DETAILS'
  }
}
