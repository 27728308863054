import { history } from 'utils'
import { crudService } from '../_services/'

export const taxaAction = {
  getTaxa,
  addTaxa,
  getTaxaById,
  onChangeProps,
  editTaxaInfo,
  editTaxasDetails,
  createTaxa,
  deleteTaxaById,
  clear,
  clearAll
}

function getTaxa() {
  return (dispatch) => {
    let apiEndpoint = 'taxas'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeTaxasList(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createTaxa(payload) {
  return (dispatch) => {
    let apiEndpoint = 'taxas/'
    crudService.post(apiEndpoint, payload).then(() => {
      dispatch(createTaxaInfo())
      dispatch(clear())
      history.push('/taxas')
    })
  }
}

function getTaxaById(id) {
  return (dispatch) => {
    let apiEndpoint = 'taxas/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editTaxasDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props == 'repassarCliente') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editTaxaInfo(id, payload) {
  return (dispatch) => {
    let apiEndpoint = 'taxas/' + id
    crudService.put(apiEndpoint, payload).then(() => {
      dispatch(updatedTaxaInfo())
      dispatch(clear())
      history.push('/taxas')
    })
  }
}

function deleteTaxaById(id) {
  return (dispatch) => {
    let apiEndpoint = 'taxas/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteTaxasDetails())
      dispatch(getTaxa())
    })
  }
}

export function addTaxa(payload) {
  return [{ type: 'ADD_TAXA', taxa: payload }, clear()]
}

export function changeTaxasList(taxa) {
  return {
    type: 'FETCHED_ALL_TAXA',
    taxa: taxa
  }
}

export function clear() {
  return {
    type: 'TAXA_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'TAXA_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'TAXA_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editTaxasDetails(taxa) {
  return {
    type: 'TAXA_DETAIL',
    id: taxa.id,
    percentual: taxa.percentual,
    valor: taxa.valor,
    estabelecimentoId: taxa.estabelecimentoId,
    eventoId: taxa.eventoId,
    loteId: taxa.loteId
  }
}

export function updatedTaxaInfo() {
  return {
    type: 'TAXA_UPDATED'
  }
}

export function createTaxaInfo() {
  return {
    type: 'TAXA_CREATED_SUCCESSFULLY'
  }
}

export function deleteTaxasDetails() {
  return {
    type: 'DELETED_TAXA_DETAILS'
  }
}
