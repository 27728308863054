import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  makeStyles
} from '@material-ui/core'
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded'
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded'
import QuestionAnswerRoundedIcon from '@material-ui/icons/QuestionAnswerRounded'
import AspectRatioRoundedIcon from '@material-ui/icons/AspectRatioRounded'
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded'
import Rating from '@material-ui/lab/Rating'
import { toLocalDateFormat } from 'utils'
import { MessageData, SkeletonTable } from 'components'
import { avaliacaoAction, usuarioAction } from '_actions'
import { AccessControl } from 'components/Utils/AccessControl'
import DetailAndComment from '../DetailAndComment'
import AnswerRating from '../AnswerRating'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  paper: {
    ...constants.shadowCard
  }
}))
const PlaceRating = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { avaliacao: rating, isLoading } = useSelector(
    (state) => state.avaliacao
  )
  const estabelecimentoId = useSelector(
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const { usuario } = useSelector((state) => state.usuario)

  function getUsuarioNameById(usuarioId) {
    let user = ''
    user = usuario.find((element) => element.id == usuarioId)
    if (user !== (null || undefined)) {
      return user.nome
    } else {
      return ''
    }
  }

  const handleOpenAnswer = (avaliacao) => {
    dispatch(avaliacaoAction.setAnswerRatingOpen(true, avaliacao))
  }

  const handleOpenDetail = (avaliacao) => {
    dispatch(avaliacaoAction.setDetailOpen(true, avaliacao))
  }

  const handleLikeRating = (avaliacaoId) => {
    dispatch(avaliacaoAction.likeRating(avaliacaoId))
  }

  const handleReportComment = () => {
    //const handleReportComment = (commentId) => {
    //dispatch(avaliacaoAction.reportComment(commentId))
  }

  useEffect(() => {
    dispatch(avaliacaoAction.getAvaliacaoByEstabelecimento(estabelecimentoId))
    dispatch(usuarioAction.getUsuario())
  }, [dispatch, estabelecimentoId])

  return (
    <div>
      {isLoading ? (
        <SkeletonTable />
      ) : rating.length === 0 ? (
        <MessageData
          text="Não há avaliações do estabelecimento! 😥"
          srcImage="/images/sad_face.svg"
        />
      ) : (
        <TableContainer component={Paper} className={classes.paper}>
          <AnswerRating />
          <DetailAndComment />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Nota</TableCell>
                <TableCell align="center">Mensagem</TableCell>
                <TableCell align="center">Usuário</TableCell>
                <TableCell align="right">Curtidas</TableCell>
                <TableCell align="right">Data/Hora</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rating.map((n) => {
                return (
                  <TableRow hover key={n.avaliacao.id}>
                    <TableCell align="left" component="th" scope="row">
                      <Rating
                        name="rating"
                        value={n.avaliacao.nota}
                        precision={0.1}
                        readOnly
                      />
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {n.avaliacao.mensagem}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {getUsuarioNameById(n.avaliacao.usuarioId)}
                    </TableCell>
                    <TableCell align="right" component="th" scope="row">
                      {n.numeroCurtidas}
                    </TableCell>
                    <TableCell align="right" component="th" scope="row">
                      {toLocalDateFormat(n.avaliacao.dataHora)}
                    </TableCell>
                    <TableCell align="right">
                      <AccessControl
                        rule={'comentarios.create'}
                        yes={() => (
                          <IconButton
                            size="small"
                            aria-label="Create"
                            component="a"
                            onClick={() => handleOpenAnswer(n.avaliacao)}
                          >
                            <Tooltip title="Responder">
                              <QuestionAnswerRoundedIcon fontSize="small" />
                            </Tooltip>
                          </IconButton>
                        )}
                      />
                      <AccessControl
                        rule={'comentarios.list'}
                        yes={() => (
                          <IconButton
                            size="small"
                            aria-label="Details"
                            onClick={() => handleOpenDetail(n.avaliacao)}
                          >
                            <Tooltip title="Ver Detalhes/Comentários">
                              <AspectRatioRoundedIcon fontSize="small" />
                            </Tooltip>
                          </IconButton>
                        )}
                      />
                      <AccessControl
                        rule={'comentarios.list'}
                        yes={() => (
                          <IconButton
                            size="small"
                            aria-label="Curtir"
                            onClick={() => handleLikeRating(n.avaliacao.id)}
                          >
                            <>
                              {n.usuarioCurtiu ? (
                                <Tooltip title="Descurtir Avaliação">
                                  <FavoriteRoundedIcon fontSize="small" />
                                </Tooltip>
                              ) : (
                                <Tooltip title="Curtir Avaliação">
                                  <FavoriteBorderRoundedIcon fontSize="small" />
                                </Tooltip>
                              )}
                            </>
                          </IconButton>
                        )}
                      />
                      <AccessControl
                      /*rule={'comentarios.list'}
                        yes={() => (
                          <IconButton
                            size="small"
                            aria-label="Reportar"
                            onClick={() => handleReportComment(n.avaliacao.id)}
                          >
                            <Tooltip title="Reportar Avaliação">
                              <ReportProblemRoundedIcon fontSize="small" />
                            </Tooltip>
                          </IconButton>
                        )}*/
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )
}

PlaceRating.propTypes = {
  className: PropTypes.string
}

export default PlaceRating
