import { crudService } from '../_services/'
import { history } from '../utils'

export const ingressoAction = {
  getIngresso,
  getIngressoById,
  onChangeProps,
  editIngressoInfo,
  createIngresso,
  deleteIngressoById,
  getIngressoByEstabelecimento,
  searchIngressos,
  getIngressosLiberadosByEstabelecimento,
  getIngressosNaoLiberadosByEstabelecimento,
  clearAll
}
function getIngressoByEstabelecimento(estabelecimentoId) {
  return (dispatch) => {
    dispatch(request({ estabelecimentoId }))
    let apiEndpoint = 'ingressos/estabelecimento/' + estabelecimentoId
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request(estabelecimentoId) {
    return {
      type: 'FETCHED_INGRESSOS_ESTABELECIMENTO_REQUEST',
      estabelecimentoId
    }
  }

  function success(ingressosList) {
    return {
      type: 'FETCHED_INGRESSOS_ESTABELECIMENTO_SUCCESS',
      ingressosList: ingressosList
    }
  }

  function failure(error) {
    return { type: 'FETCHED_INGRESSOS_ESTABELECIMENTO_FAILURE', error }
  }
}

function searchIngressos(payload) {
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'ingressos/search/'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request(payload) {
    return {
      type: 'SEARCH_INGRESSOS_REQUEST',
      payload
    }
  }

  function success(ingressosList) {
    return {
      type: 'SEARCH_INGRESSOS_SUCCESS',
      ingressosList: ingressosList.lista
    }
  }

  function failure(error) {
    return { type: 'SEARCH_INGRESSOS_FAILURE', error }
  }
}

function getIngressosNaoLiberadosByEstabelecimento(estabelecimentoId) {
  return (dispatch) => {
    dispatch(request(estabelecimentoId))
    let apiEndpoint = `ingressos/naoliberados/estabelecimento/${estabelecimentoId}`
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request(payload) {
    return {
      type: 'GET_INGRESSOS_NAO_LIBERADOS_BY_ESTABELECIMENTO_REQUEST',
      payload
    }
  }

  function success(ingressos) {
    return {
      type: 'GET_INGRESSOS_NAO_LIBERADOS_BY_ESTABELECIMENTO_SUCCESS',
      ingressos: ingressos
    }
  }

  function failure(error) {
    return {
      type: 'GET_INGRESSOS_NAO_LIBERADOS_BY_ESTABELECIMENTO_FAILURE',
      error
    }
  }
}
function getIngressosLiberadosByEstabelecimento(estabelecimentoId) {
  return (dispatch) => {
    dispatch(request(estabelecimentoId))
    let apiEndpoint = `ingressos/liberados/estabelecimento/${estabelecimentoId}`
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request(payload) {
    return {
      type: 'GET_INGRESSOS_LIBERADOS_BY_ESTABELECIMENTO_REQUEST',
      payload
    }
  }

  function success(ingressos) {
    return {
      type: 'GET_INGRESSOS_LIBERADOS_BY_ESTABELECIMENTO_SUCCESS',
      ingressos: ingressos
    }
  }

  function failure(error) {
    return { type: 'GET_INGRESSOS_LIBERADOS_BY_ESTABELECIMENTO_FAILURE', error }
  }
}

function getIngresso() {
  return (dispatch) => {
    let apiEndpoint = 'ingressos'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeIngressosList(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
export function clearAll() {
  return {
    type: 'INGRESSO_CLEAR_ALL'
  }
}
function createIngresso(payload) {
  return (dispatch) => {
    let apiEndpoint = 'ingressos/'
    crudService.post(apiEndpoint, payload).then(() => {
      dispatch(createUserInfo())
      history.push('/ingressos')
    })
  }
}
function getIngressoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'ingressos/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editIngressosDetails(response.data))
    })
  }
}
function onChangeProps(props, event) {
  return (dispatch) => {
    dispatch(handleOnChangeProps(props, event.target.value))
  }
}
function editIngressoInfo(id, payload) {
  return (dispatch) => {
    let apiEndpoint = 'ingressos/' + id
    crudService.put(apiEndpoint, payload).then(() => {
      dispatch(updatedUserInfo())
      history.push('/ingressos')
    })
  }
}
function deleteIngressoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'ingressos/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteIngressosDetails())
      dispatch(ingressoAction.getIngresso())
    })
  }
}
export function changeIngressosList(ingresso) {
  return {
    type: 'FETCHED_ALL_INGRESSO',
    ingresso: ingresso
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'INGRESSO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editIngressosDetails(ingresso) {
  return {
    type: 'INGRESSO_DETAIL',
    id: ingresso._id,
    descricao: ingresso.descricao,
    estabelecimentoId: ingresso.estabelecimentoId,
    excluido: ingresso.excluido,
    estabelecimento: ingresso.estabelecimento,
    lote: ingresso.lote
  }
}
export function updatedUserInfo() {
  return {
    type: 'INGRESSO_UPDATED'
  }
}
export function createUserInfo() {
  return {
    type: 'INGRESSO_CREATED_SUCCESSFULLY'
  }
}
export function deleteIngressosDetails() {
  return {
    type: 'DELETED_INGRESSO_DETAILS'
  }
}
