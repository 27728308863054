import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'

//? Own imports
import { Page, SkeletonTable } from 'components'
import { Filters, TableOrderManagementList, Header } from './components'
import { AccessControl } from 'components/Utils'
import AccessDenied from 'views/Errors/AccessDenied'
import { useOrders } from 'hooks-querys'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  results: {
    marginTop: theme.spacing(3)
  }
}))

function useSearchQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const OrderManagementList = () => {
  const classes = useStyles()
  const querySearch = useSearchQuery()
  const [filters, setFilters] = useState({
    // page: Number(querySearch.get('page')) || 1,
    textSearch: querySearch.get('Term') || '',
    statusPedidoId: querySearch.get('StatusPedidoId') || '0',
    formaPagamentoId: querySearch.get('FormaPagamentoId') || '0',
    dataHoraInicio: querySearch.get('DataHoraInicio') || '',
    dataHoraFim: querySearch.get('DataHoraFim') || '',
    selectedEvento: querySearch.get('EventoId') || ''
  })

  useEffect(() => {
    setFilters({
      // page: Number(querySearch.get('page')) || 1,
      textSearch: querySearch.get('Term') || '',
      statusPedidoId: querySearch.get('StatusPedidoId') || '0',
      formaPagamentoId: querySearch.get('FormaPagamentoId') || '0',
      dataHoraInicio: querySearch.get('DataHoraInicio') || '',
      dataHoraFim: querySearch.get('DataHoraFim') || '',
      selectedEvento: querySearch.get('EventoId') || ''
    })
  }, [querySearch])

  const { isLoading, data } = useOrders({
    term: filters.textSearch,
    statusPedidoId: filters.statusPedidoId,
    formaPagamentoId: filters.formaPagamentoId,
    dataHoraInicio: filters.dataHoraInicio,
    dataHoraFim: filters.dataHoraFim,
    eventoId: filters.selectedEvento
  })

  return (
    <AccessControl
      rule={'pedidos.listAdmin'}
      // @ts-ignore
      yes={() => (
        // @ts-ignore
        <Page className={classes.root} title="Gerência de Pedidos">
          <Header />
          <Filters />
          {isLoading ? (
            <SkeletonTable />
          ) : (
            <TableOrderManagementList orders={data.items} mt={2} />
          )}
        </Page>
      )}
      // @ts-ignore
      no={() => <AccessDenied />}
    />
  )
}

export default OrderManagementList
