import { useQuery } from 'react-query'

//? Own imports
import { DashboardEstabelecimento } from 'types/dashboard'
import { crudService } from '_services'

const getEstablishmentDashboard = async (establishmentId: number) => {
  const apiEndpoint = `dashboard/estabelecimento/${establishmentId}`
  try {
    const { data } = await crudService.get(apiEndpoint)
    return data
  } catch (e) {
    return e
  }
}

export default function useEstablishmentDashboard(establishmentId: number) {
  return useQuery<DashboardEstabelecimento>(
    ['establishmentDashboard', establishmentId],
    () => getEstablishmentDashboard(establishmentId),
    {
      enabled: !!establishmentId,
      refetchOnWindowFocus: false
    }
  )
}
