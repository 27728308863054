import React from 'react'
import {
  TableBody,
  Table,
  makeStyles,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Toolbar,
  Tooltip,
  IconButton
} from '@material-ui/core'
import PrintRoundedIcon from '@material-ui/icons/PrintRounded'
import ReactToPrint from 'react-to-print'

//? Own imports
import { User } from 'types/api'
import { RowTableUsers } from './components'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700
  },
  rootToolbarStyles: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  titleToolbarStyles: {
    flex: '1 1 100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    ...constants.shadowCard
  }
}))

const TableUsers = ({ users }: { users: User[] }) => {
  const classes = useStyles()
  const refTable = React.useRef(null)

  return (
    <div style={{ paddingTop: 15 }}>
      <Typography variant="body2" align="left" gutterBottom>
        {users && users.length > 0 ? (
          <>
            Total de dados encontrados: <strong>{users.length}</strong>
          </>
        ) : (
          <strong>Nenhum dado encontrado.</strong>
        )}
      </Typography>
      {users && users.length > 0 && (
        <Paper ref={refTable} className={classes.paper}>
          <Toolbar className={classes.rootToolbarStyles}>
            <Typography
              className={classes.titleToolbarStyles}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Lista de usuários
            </Typography>
            <ReactToPrint
              trigger={() => (
                <Tooltip title="Imprimir">
                  <IconButton aria-label="gerar relatório">
                    <PrintRoundedIcon />
                  </IconButton>
                </Tooltip>
              )}
              content={() => refTable.current}
            />
          </Toolbar>
          {/* usado para 'forçar' a orientação da pagina para 'paisagem' afim de imprimir mostrando todos os dados */}
          <style type="text/css" media="print">
            {'\
             @page { size: landscape; }\
            '}
          </style>
          {/* ------------------------------------------------------------>	 */}
          <TableContainer>
            {users !== undefined && users.length > 0 && (
              <Table className={classes.table} aria-label="tabela de users">
                <TableHead>
                  <TableRow>
                    <TableCell>#ID</TableCell>
                    <TableCell align="left">Nome</TableCell>
                    <TableCell align="center">CPF/CNPJ</TableCell>
                    <TableCell align="center">Email</TableCell>
                    <TableCell align="center">Localização</TableCell>
                    <TableCell align="center">Telefone</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => {
                    return <RowTableUsers user={user} key={user.id} />
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Paper>
      )}
    </div>
  )
}

export default TableUsers
