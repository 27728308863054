import { crudService } from '../_services'
import { history } from '../utils'
import { toast } from 'react-toastify'

export const parametroAction = {
  getParametro,
  getParametroById,
  onChangeProps,
  editParametroInfo,
  createParametro,
  deleteParametroById,
  clear
}

function getParametro() {
  return (dispatch) => {
    let apiEndpoint = 'parametros'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeParametrosList(response.data))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

function createParametro(payload, redirect = false) {
  return (dispatch) => {
    let apiEndpoint = 'parametros/'
    crudService.post(apiEndpoint, payload).then((response) => {
      dispatch(createParametroInfo(response.data))
      toast.success('Feito! Parâmetro criado! 😁')
      if (redirect) {
        history.push('/parametros')
      }
    })
  }
}

//function getParametroById(id) {
//  return (dispatch) => {
//    let apiEndpoint = 'parametros/' + id
//    crudService.get(apiEndpoint).then((response) => {
//      dispatch(editParametrosDetails(response.data))
//    })
//  }
//}

function getParametroById(id) {
  return (dispatch) => {
    dispatch(request({ id }))
    let apiEndpoint = 'parametros/' + id
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(editParametrosDetails(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request(id) {
    return { type: 'FETCHED_ALL_TIPOINGRESSO_REQUEST', id }
  }

  function success() {
    return {
      type: 'FETCHED_ALL_TIPOINGRESSO_SUCCESS'
    }
  }

  function failure(error) {
    return { type: 'FETCHED_ALL_TIPOINGRESSO_FAILURE', error }
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    dispatch(handleOnChangeProps(props, event.target.value))
  }
}

function editParametroInfo(id, payload) {
  return (dispatch) => {
    let apiEndpoint = 'parametros/' + id
    crudService.put(apiEndpoint, payload).then(() => {
      dispatch(updatedUserInfo())
      history.push('/parametros')
    })
  }
}

function deleteParametroById(id) {
  return (dispatch) => {
    let apiEndpoint = 'parametros/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteParametrosDetails())
    })
  }
}

export function changeParametrosList(parametro) {
  return {
    type: 'FETCHED_ALL_PARAMETRO',
    parametro: parametro
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'PARAMETRO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editParametrosDetails(parametro) {
  return {
    type: 'PARAMETRO_DETAIL',
    id: parametro._id,
    chave: parametro.chave,
    valor: parametro.valor,
    descricao: parametro.descricao
  }
}

export function clear() {
  return {
    type: 'PARAMETRO_CLEAR'
  }
}

export function updatedUserInfo() {
  return {
    type: 'PARAMETRO_UPDATED'
  }
}
export function createParametroInfo(parametro) {
  return {
    type: 'PARAMETRO_CREATED_SUCCESSFULLY',
    parametro: parametro
  }
}
export function deleteParametrosDetails() {
  return {
    type: 'DELETED_PARAMETRO_DETAILS'
  }
}
