import { useQuery } from 'react-query'
import { Ingresso } from 'types/api'
import { crudService } from '_services'

const getTickets = async (
  eventId: number,
  textSearch: string,
  ticketStatusId: number
) => {
  const payload = {
    EventoId: eventId,
    Texto: textSearch,
    StatusIngressoId: ticketStatusId
  }
  const { data } = await crudService.post('ingressos/evento/search/', payload)
  return data
}

export default function useTickets(
  eventId: number,
  textSearch = '',
  ticketStatusId: number
) {
  return useQuery<Ingresso[]>(
    ['tickets', eventId, textSearch, ticketStatusId],
    () => getTickets(eventId, textSearch, ticketStatusId),
    {
      enabled: eventId > 0
    }
  )
}
