import React from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Typography,
  makeStyles,
  Switch,
  Box
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded'

//? Own imports
import { User } from 'types/api'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    ...constants.shadowCard
  },
  mainActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  notice: {
    marginTop: theme.spacing(1)
  },
  deleteButton: {
    marginTop: theme.spacing(1),
    color: '#fff',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  }
}))

type OtherActionsProps = {
  user: User
}
const OtherActions = ({ user }: OtherActionsProps) => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardHeader title="Outras ações" />
      <Divider />
      <CardContent>
        <Typography variant="h5">Email verificado</Typography>
        <Typography variant="body2">
          Habilitar ou desabilitar o email verificado.
        </Typography>
        <Switch
          color="primary"
          edge="start"
          name="verified"
          // onChange={handleFieldChange}
          value={user?.emailVerificado}
          checked={user?.emailVerificado}
        />
        <Box marginY={1}>
          <Button startIcon={<MailOutlineRoundedIcon />}>
            Reenviar email de verificação
          </Button>
        </Box>
        <Divider />
        <Typography className={classes.notice} variant="body2" gutterBottom>
          Remova os dados deste cliente se ele solicitou, se não, por favor
          esteja ciente de que o que for excluído nunca poderá ser recuperado.
        </Typography>
        <Button className={classes.deleteButton} startIcon={<DeleteIcon />}>
          Excluir conta do cliente
        </Button>
      </CardContent>
    </Card>
  )
}

export default OtherActions
