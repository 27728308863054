import React, { useEffect } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useParams } from 'react-router-dom'

//? Own imports
import { Header, Page } from 'components'
import { OrderCancelInfo } from './components'
import { Pedido } from 'types/api'
import { useOrderCancel } from 'hooks-querys'
import {
  OrderItems,
  OrderTickets,
  OrderInfo
} from 'views/OrderManagementDetails/components'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  container: {
    marginTop: theme.spacing(2)
  }
}))

const OrderCancelDetails = () => {
  const classes = useStyles()
  const { id } = useParams<{ id: string }>()

  const { data, isLoading } = useOrderCancel(Number(id), undefined)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])

  return (
    <>
      {data ? (
        <Page
          // @ts-ignore
          className={classes.root}
          title={`Solicitação de cancelamento N.º #${id}`}
        >
          <Header
            title={`Solicitação de cancelamento N.º #${id}`}
            subtitle="Solicitações de cancelamento"
          />
          <Grid className={classes.container} container spacing={3}>
            <Grid item md={5} xl={4} xs={12}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <OrderCancelInfo orderCancel={data} />
                </Grid>
                {data.pedido && (
                  <Grid item md={12} xs={12}>
                    <OrderInfo order={data.pedido} />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item md={7} xl={8} xs={12}>
              <Grid container spacing={3}>
                {data.pedido && data.pedido?.carrinhoItem && (
                  <Grid item md={12} xs={12}>
                    <OrderItems carrinhoItens={data.pedido?.carrinhoItem} />
                  </Grid>
                )}
                {data.pedido?.ingresso && data.pedido?.ingresso.length > 0 && (
                  <Grid item md={12} xs={12}>
                    <OrderTickets tickets={data.pedido?.ingresso} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Page>
      ) : null}
    </>
  )
}

export default OrderCancelDetails
