import React from 'react'
import {
  TableRow,
  TableCell,
  Grid,
  Tooltip,
  IconButton,
  Avatar,
  makeStyles,
  Typography,
  Link
} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { Link as RouterLink } from 'react-router-dom'

//? Own imports
import { User } from 'types/api'
import { cepMask, cpfCnpjMask, getInitials, phoneMask } from 'utils'

const useStyles = makeStyles((theme) => ({
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  }
}))

const RowTableUsers = ({ user }: { user: User }) => {
  const classes = useStyles()

  return (
    <TableRow hover>
      <TableCell component="th" scope="user">
        {user.id}
      </TableCell>
      <TableCell align="left">
        {user && user.nome ? (
          <div className={classes.nameCell}>
            <Avatar className={classes.avatar} src={user?.imagem}>
              {getInitials(user?.nome)}
            </Avatar>
            <div>
              <Typography color="inherit" variant="h6">
                {user?.nome}
              </Typography>
              <div>@{user?.username}</div>
            </div>
          </div>
        ) : (
          'Usuário não encontrado'
        )}
      </TableCell>
      <TableCell align="center">
        {cpfCnpjMask(user.cpfCnpj) || 'Não informado'}
      </TableCell>
      <TableCell align="center">{user?.email || 'Não identificado'}</TableCell>
      <TableCell align="center">
        <div>
          {`${user?.endereco ? user?.endereco : ''}${
            user?.numero ? `, ${user?.numero}` : ''
          } ${user?.complemento ? ` - ${user?.complemento}` : ''}${
            user?.bairro ? ` - ${user?.bairro}` : ''
          }`}
        </div>
        <div>{cepMask(user?.cep)}</div>
        {user?.cidade ? (
          <div>
            {`${user?.cidade?.nome} -
                  ${user?.cidade?.estado.uf.toUpperCase()}/${
              user?.cidade?.estado?.pais?.nomePt
            }`}
          </div>
        ) : null}
      </TableCell>
      <TableCell align="center">
        {phoneMask(user.telefone) || 'Não informado'}
      </TableCell>
      <TableCell align="right">
        <Grid wrap="nowrap" container justify="flex-end">
          <Grid>
            <Tooltip title="Detalhes">
              <Link component={RouterLink} to={`usuario/${user.id}`}>
                <IconButton size="small" aria-label="detalhes do usuario">
                  <InfoOutlinedIcon />
                </IconButton>
              </Link>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
}

export default RowTableUsers
