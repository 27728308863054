import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { avaliacaoAction, comentarioAction } from '_actions'
import { getCurrentDateTime } from 'utils'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button
} from '@material-ui/core'

const AnswerRating = () => {
  const dispatch = useDispatch()
  const { usuario } = useSelector((state) => state.usuario)
  const avaliacao = useSelector((state) => state.avaliacao)
  const comentario = useSelector((state) => state.comentario)
  const usuarioEstabelecimento = useSelector(
    (state) => state.usuarioEstabelecimento
  )

  const handleClose = () => {
    dispatch(comentarioAction.changeAnswer(null, null))
    dispatch(avaliacaoAction.setAnswerRatingOpen(false, null))
  }

  function getUsuarioNameById(usuarioId) {
    let user = ''
    user = usuario.find((element) => element.id == usuarioId)
    if (user !== (null || undefined)) {
      return user.nome
    } else {
      return ''
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    let payload = {
      usuarioId: usuarioEstabelecimento.usuarioId,
      dataHora: getCurrentDateTime(),
      mensagem: comentario.mensagem,
      avaliacaoId: avaliacao.id
    }
    dispatch(comentarioAction.sendAnswer(payload))
    dispatch(avaliacaoAction.setAnswerRatingOpen(false, null))
  }

  const handleChange = (prop) => (event) => {
    dispatch(comentarioAction.changeAnswer(prop, event))
  }

  return (
    <Dialog
      open={avaliacao.answerOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      //fullScreen
    >
      <DialogTitle id="form-dialog-title">
        Responder avaliação de {getUsuarioNameById(avaliacao.usuarioId)}
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>{avaliacao.mensagem}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="answer"
            label="Resposta"
            type="text"
            multiline
            value={comentario.mensagem}
            onChange={handleChange('answer')}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button type="submit" color="primary">
            Enviar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AnswerRating
