import React, { useRef } from 'react'
import {
  Box,
  Typography,
  Divider,
  Paper,
  Dialog,
  Button,
  Slide,
  Avatar,
  DialogContent,
  useTheme
} from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
import ReactToPrint from 'react-to-print'
import { QRCodeSVG } from 'qrcode.react'
import { format } from 'date-fns'

// Own imports
import { useStyles } from './styles'
import { MemberPlan } from 'types/api'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export type MembershipCardProps = {
  setOpenDialogMembershipCard: (open: boolean) => void
  openDialogMembershipCard: boolean
  memberPlan: MemberPlan
}

const MembershipCard = ({
  setOpenDialogMembershipCard,
  openDialogMembershipCard,
  memberPlan
}: MembershipCardProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const refMembershipCard = useRef(null)
  const backgroundImage =
    memberPlan?.plano?.imagem || memberPlan?.plano?.estabelecimento?.imagem
  return (
    <Dialog
      TransitionComponent={Transition}
      open={openDialogMembershipCard}
      onClose={() => setOpenDialogMembershipCard(false)}
    >
      <DialogContent className={classes.dialogContent}>
        <Paper ref={refMembershipCard} className={classes.card} elevation={0}>
          <div
            style={{
              position: 'absolute',
              height: '2.5cm',
              width: '5.35cm',
              borderTopRightRadius: '0.45cm',
              borderTopLeftRadius: '0.45cm',
              backgroundImage: `url("${backgroundImage}")`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              overflow: 'hidden',
              backgroundColor: backgroundImage
                ? 'transparent'
                : theme.palette.primary.main,
              // filter: 'blur(0.01cm)',
              opacity: backgroundImage ? 0.2 : 1
            }}
          />
          <Box className={classes.header}>
            <Box className={classes.hole} />
            <Avatar
              imgProps={{ style: { borderRadius: '0.75cm' } }}
              className={classes.avatar}
              variant="circular"
              src={
                memberPlan?.membro?.imagem ||
                memberPlan?.membro?.usuario?.imagem
              }
            />
          </Box>
          <Box className={classes.content}>
            <Box>
              <Typography style={{ fontSize: '0.3cm' }} variant="h6">
                {memberPlan?.plano?.nome?.toUpperCase()}
              </Typography>
              <Typography
                display="inline"
                className={classes.title}
                variant="body1"
              >
                Matrícula:{' '}
                <Typography className={classes.subtitle} variant="h6">
                  {/* @ts-ignore */}
                  {memberPlan?.matricula}
                </Typography>
              </Typography>
              <Typography
                display="inline"
                className={classes.title}
                variant="body1"
              >
                Nome:{' '}
                <Typography
                  display="inline"
                  className={classes.subtitle}
                  variant="h6"
                >
                  {memberPlan?.membro?.nome}
                </Typography>
              </Typography>
            </Box>
            <Divider className={classes.divider} />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <QRCodeSVG
                value={memberPlan?.qrCode}
                style={{
                  width: '2.35cm',
                  height: '2.35cm',
                  padding: '0.1cm',
                  marginBottom: '0.1cm'
                }}
                bgColor={'#ffffff'}
                fgColor={'#000000'}
                level={'M'}
              />
              <Typography
                display="inline"
                className={classes.title}
                variant="body1"
              >
                Válido até{' '}
                {memberPlan?.dataHoraInicio && (
                  <Typography
                    display="inline"
                    className={classes.subtitle}
                    variant="h6"
                  >
                    {format(new Date(memberPlan?.dataHoraInicio), 'dd/MM/yyyy')}
                  </Typography>
                )}
              </Typography>
            </Box>
          </Box>
        </Paper>
        <Box mt={3}>
          <ReactToPrint
            trigger={() => (
              <Button
                color="primary"
                variant="contained"
                startIcon={<GetAppRoundedIcon />}
                onClick={() => setOpenDialogMembershipCard(true)}
              >
                Imprimir/Salvar
              </Button>
            )}
            content={() => refMembershipCard.current}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default MembershipCard
