import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
  Divider,
  CircularProgress
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { usuarioAction } from '_actions'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    ...constants.shadowCard
  },
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

const Notifications = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const { user } = useSelector((state) => state.authentication)
  const { loading } = useSelector((state) => state.usuario)
  const [emailVendas, setEmailVendas] = useState(user.emailVendas)

  useEffect(() => {
    setEmailVendas(user.emailVendas)
    return () => {
      setEmailVendas(user.emailVendas)
    }
  }, [user.emailVendas])

  const dispatch = useDispatch()
  const handleSubmit = async () => {
    let payload = {
      ...user,
      emailVendas: emailVendas
    }
    dispatch(usuarioAction.editUsuarioInfo(user.id, payload))
  }
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Notificações" />
      <Divider />
      <CardContent>
        <form>
          <Grid container spacing={6} wrap="wrap">
            <Grid className={classes.item} item xs={12}>
              <Typography gutterBottom variant="h6">
                Sistema
              </Typography>
              <Typography gutterBottom variant="body2">
                Você receberá notificações informativas do sistema no seu e-mail
                {': '}
                <strong>({user.email})</strong>
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event) => setEmailVendas(event.target.checked)}
                    color="primary"
                    checked={emailVendas}
                  />
                }
                label="Alerta de novas vendas"
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked //
                  />
                }
                label={
                  <Fragment>
                    <Typography variant="body1">Phone calls</Typography>
                    <Typography variant="caption">
                      Short voice phone updating you
                    </Typography>
                  </Fragment>
                }
              /> */}
            </Grid>
            {/* <Grid className={classes.item} item md={4} sm={6} xs={12}>
              <Typography gutterBottom variant="h6">
                Chat App
              </Typography>
              <Typography gutterBottom variant="body2">
                You will recieve emails in your business email address
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked //
                  />
                }
                label="Email"
              />
              <FormControlLabel
                control={<Checkbox color="primary" defaultChecked />}
                label="Push notifications"
              />
            </Grid> */}
          </Grid>
        </form>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading || emailVendas === user.emailVendas}
          endIcon={loading && <CircularProgress size={24} />}
        >
          {loading ? 'Salvando...' : 'Salvar alterações'}
        </Button>
      </CardActions>
    </Card>
  )
}

Notifications.propTypes = {
  className: PropTypes.string
}

export default Notifications
