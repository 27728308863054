import React, { useEffect, useState } from 'react'
import {
  FormControl,
  makeStyles,
  Switch,
  TextField,
  InputAdornment,
  useTheme,
  useMediaQuery,
  Chip,
  CircularProgress
} from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Autocomplete } from '@material-ui/lab'

//? Own imports
import { useDidMountEffect } from 'utils/useDidMountEffect'
import { useEvents } from 'hooks-querys'
import { history, toLocalDateFormat } from 'utils'
import { Evento } from 'types/api'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    width: '100%'
  },
  divider: {
    height: 28,
    margin: 4
  }
}))

const SelectFilters = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [selectedEvento, setSelectedEvento] = useState<Evento | null>(null)
  const [eventoInputValue, setEventoInputValue] = useState('')
  const [showPastEvents, setShowPastEvents] = useState(false)
  const { eventoId } = useParams<{ eventoId: string }>()

  const estabelecimentoId = useSelector(
    // @ts-ignore: //ainda não foi tipado o redux state
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const {
    data: events,
    isLoading: isLoadingEvents,
    isFetching: isFetchingEvents
  } = useEvents(estabelecimentoId, showPastEvents, isMobile)

  useEffect(() => {
    //? função executada para buscar o evento o qual esta na url (?eventoId=)
    //? se não existir evento na url não faz nada

    if (eventoId && Number(eventoId) > 0) {
      const event =
        events &&
        events.length > 0 &&
        events.find((e) => e.id === Number(eventoId))
      //? se encontrar o evento na url, seta o evento selecionado,
      //? se não encontrar, troca a o estado showPastEvents para true,
      //? pois o evento informado pode ser um evento passado, e não existir na lista de eventos;
      //? com isso, a lista de evento efetua um novo fetch para buscar os eventos passados,
      //? e gera um novo efeito colateral caindo nesse useEffect novamente

      if (event) {
        setSelectedEvento(event)
      } else if (selectedEvento === null) {
        setShowPastEvents(!showPastEvents)
      }
    }
  }, [events, eventoId])

  useDidMountEffect(() => {
    if (selectedEvento && selectedEvento.id) {
      history.push(`/dashboard/${selectedEvento?.id}`)
    }
  }, [selectedEvento])

  useEffect(() => {
    if (!eventoId) {
      setSelectedEvento(null)
      setEventoInputValue('')
    }
  }, [eventoId])

  useEffect(() => {
    if (selectedEvento && selectedEvento.id) {
      if (eventoInputValue === '') {
        setEventoInputValue(
          selectedEvento.nome &&
            `${selectedEvento.nome} ${
              selectedEvento.dataHoraInicio
                ? ` - (${toLocalDateFormat(selectedEvento.dataHoraInicio)})`
                : ''
            }`
        )
      }
    }
  }, [selectedEvento])

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <Autocomplete
          loading={isLoadingEvents}
          id="combo-box-evento"
          options={events || []}
          value={selectedEvento}
          onChange={(event, newValue, reason) => {
            setSelectedEvento(newValue)
            if (reason === 'clear') {
              //? se o usuário clicar no botão limpar
              setEventoInputValue('')
              history.push('/dashboard')
            }
          }}
          getOptionLabel={(evento) =>
            evento.nome &&
            `${evento.nome} ${
              evento.dataHoraInicio
                ? ` - (${toLocalDateFormat(evento.dataHoraInicio)})`
                : ''
            }`
          }
          placeholder="Filtrar por evento..."
          onInputChange={(event, newInputValue) => {
            newInputValue !== null
              ? setEventoInputValue(newInputValue)
              : setEventoInputValue('')
          }}
          inputValue={eventoInputValue}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filtrar por evento"
              placeholder="Filtrar por evento..."
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {!isMobile && (
                      <InputAdornment position="end">
                        <>
                          {isFetchingEvents && (
                            <CircularProgress
                              style={{ marginRight: 10 }}
                              size={20}
                              color="primary"
                            />
                          )}
                          <Switch
                            color="primary"
                            onChange={(event) => {
                              setShowPastEvents(event.target.checked)
                            }}
                            checked={showPastEvents}
                            size="small"
                            inputProps={{
                              'aria-label': 'Listar eventos encerrados'
                            }}
                          />
                          <Chip
                            variant="default"
                            size="small"
                            label="Listar eventos encerrados"
                            color={showPastEvents ? 'primary' : 'default'}
                            onClick={() => {
                              setShowPastEvents(!showPastEvents)
                            }}
                          />
                        </>
                      </InputAdornment>
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </FormControl>
    </div>
  )
}

export default SelectFilters
