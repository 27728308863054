import { toast } from 'react-toastify'
import { hashtagMask, history } from 'utils'
import { crudService } from '../_services/'

export const hashtagAction = {
  createHashtag,
  deleteHashtagById,
  getHashtags,
  addHashtag,
  getHashtagById,
  onChangeProps,
  editHashtagInfo,
  editHashtagsDetails,
  clear,
  clearAll
}

function getHashtags(page, limitItems, searchName, orderName, direction) {
  return (dispatch) => {
    if (page == '') {
      page = 1
    }
    if (limitItems == '') {
      limitItems = 100
    }
    let apiEndpoint = `hashtags/pagination?Page=${page}&Limit=${limitItems}&Term=${searchName}&Order=${orderName}&Direction=${direction}`
    //hashtags/pagination?Page=1&Limit=10&Term&Order=descricao&Direction=asc
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeHashtagsList(response.data.items))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createHashtag(payload) {
  return (dispatch) => {
    let apiEndpoint = 'hashtags/'
    crudService.post(apiEndpoint, payload).then(() => {
      dispatch(createHashtagInfo())
      dispatch(clear())
      toast.success('Hashtag cadastrada com sucesso! 👍', {
        position: 'top-right'
      })
      history.push('/hashtags')
    })
  }
}

function getHashtagById(id) {
  return (dispatch) => {
    let apiEndpoint = 'hashtags/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editHashtagsDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (typeof event === 'object') {
      dispatch(handleOnChangeProps(props, event.target.value))
    } else {
      dispatch(handleOnChangeProps(props, event))
    }
    if (props === 'descricao') {
      dispatch(handleOnChangeProps(props, hashtagMask(event.target.value)))
    }
  }
}

function editHashtagInfo(id, payload) {
  return (dispatch) => {
    let apiEndpoint = 'hashtags/' + id
    crudService.put(apiEndpoint, payload).then(() => {
      dispatch(updatedHashtagInfo())
      dispatch(clear())
      history.push('/hashtags')
    })
  }
}

function deleteHashtagById(id) {
  return (dispatch) => {
    let apiEndpoint = 'hashtags/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteHashtagsDetails())
      dispatch(getHashtags('', '', '', '', ''))
    })
  }
}

export function addHashtag(payload) {
  return [{ type: 'HASHTAG_ADD', hashtag: payload }, clear()]
}

export function changeHashtagsList(hashtag) {
  return {
    type: 'HASHTAG_FETCHED_ALL',
    hashtag: hashtag
  }
}

export function clear() {
  return {
    type: 'HASHTAG_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'HASHTAG_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'HASHTAG_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editHashtagsDetails(hashtag) {
  return {
    type: 'HASHTAG_DETAIL',
    id: hashtag.id,
    descricao: hashtag.descricao,
    imagem: hashtag.imagem,
    banner: hashtag.banner,
    categoriaId: hashtag.categoriaId
  }
}

export function updatedHashtagInfo() {
  return {
    type: 'HASHTAG_UPDATED'
  }
}

export function createHashtagInfo() {
  return {
    type: 'HASHTAG_CREATED_SUCCESSFULLY'
  }
}

export function deleteHashtagsDetails() {
  return {
    type: 'HASHTAG_DELETED_DETAILS'
  }
}
