import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Divider,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Link,
  makeStyles,
  Grid,
  Tooltip,
  CircularProgress
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'

//? Own imports
import { CancelamentoIngresso } from 'types/api'
import { getStatusCancelamentoIngressoColor, toLocalDateFormat } from 'utils'
import { Label } from 'components'
import { useMutationFinishOrderCancel } from 'hooks-querys'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    ...constants.shadowCard
  },
  content: {
    padding: 0
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}))

const OrderCancelInfo = ({
  orderCancel
}: {
  orderCancel: CancelamentoIngresso
}) => {
  const classes = useStyles()
  const mutationFinishOrderCancel = useMutationFinishOrderCancel()
  return (
    <Card className={clsx(classes.root)}>
      <CardHeader title="Detalhes da solicitação de cancelamento" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>#{orderCancel.id || 'ID não identificado'}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Motivo</TableCell>
              <TableCell>
                {orderCancel.motivoCancelamentoIngresso?.descricao ||
                  'Motivo não identificado'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Data solicitação</TableCell>
              <TableCell>
                {orderCancel && orderCancel.dataHora
                  ? toLocalDateFormat(orderCancel.dataHora)
                  : '----------'}
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Data finalização</TableCell>
              <TableCell>
                {orderCancel && orderCancel.dataHoraProcessamento
                  ? toLocalDateFormat(orderCancel.dataHoraProcessamento)
                  : '----------'}
              </TableCell>
            </TableRow>
            {orderCancel.chavePix && (
              <TableRow>
                <TableCell>Chave PIX</TableCell>
                <TableCell>{orderCancel.chavePix}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>
                {orderCancel.statusCancelamentoIngresso ? (
                  <Label
                    color={getStatusCancelamentoIngressoColor(
                      orderCancel.statusCancelamentoIngresso
                    )}
                    variant="contained"
                  >
                    {orderCancel.statusCancelamentoIngresso.descricao}
                  </Label>
                ) : (
                  'Status não identificado'
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
      <CardActions>
        <Grid container spacing={2} justify="space-between">
          {/* {orderCancel.statusCancelamentoIngresso?.finalizado && (
            <Grid item>
              <Button>
                <CloseIcon className={classes.buttonIcon} />
                Cancelar
              </Button>
            </Grid>
          )}
          {orderCancel.statusCancelamentoIngresso?.finalizado && (
            <Grid item>
              <Button>
                <ReceiptIcon className={classes.buttonIcon} />
                Reenviar notificações
              </Button>
            </Grid>
          )} */}
          {orderCancel.statusCancelamentoIngresso?.pendente && (
            <Grid item>
              <Tooltip title="Finalizar cancelamento">
                <Button
                  disabled={mutationFinishOrderCancel.isLoading}
                  color={'primary'}
                  size="small"
                  variant="outlined"
                  endIcon={
                    mutationFinishOrderCancel.isLoading ? (
                      <CircularProgress size={18} />
                    ) : (
                      <CheckRoundedIcon />
                    )
                  }
                  onClick={() => {
                    mutationFinishOrderCancel.mutate(orderCancel)
                  }}
                >
                  {mutationFinishOrderCancel.isLoading
                    ? 'Finalizando...'
                    : 'Finalizar'}
                </Button>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </CardActions>
    </Card>
  )
}

export default OrderCancelInfo
