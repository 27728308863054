import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Page } from 'components'

import {
  Notifications,
  Header,
  Statistics,
  Summary,
  FirstEstabelecimento
} from './components'
import { useDispatch, useSelector } from 'react-redux'
import { dashboardAction, globalActions } from '_actions'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(0)
    }
  },
  statistics: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3)
    }
  },
  notifications: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(3)
    }
  },
  summary: {
    marginTop: theme.spacing(3)
  }
}))

const Home = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { dashboardHome, isLoading } = useSelector((state) => state.dashboard)
  const { estabelecimentoId } = useSelector(
    (state) => state.usuarioEstabelecimento
  )

  useEffect(() => {
    if (estabelecimentoId !== '') {
      dispatch(dashboardAction.getDashboardHome(estabelecimentoId))
    }
  }, [dispatch, estabelecimentoId])

  useEffect(() => {
    dispatch(globalActions.setIsVisibleButtonShowValues(true))
    return () => {
      dispatch(globalActions.setIsVisibleButtonShowValues(false))
    }
  }, [])

  return (
    <Page className={classes.root} title="Home">
      <FirstEstabelecimento />
      <Notifications className={classes.notifications} />
      <Header />
      <Statistics
        isLoading={isLoading}
        dashboardHome={dashboardHome}
        className={classes.statistics}
      />
      {/* <Summary className={classes.summary} /> */}
    </Page>
  )
}

export default Home
