import React from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded'
import PermDataSettingRoundedIcon from '@material-ui/icons/PermDataSettingRounded'
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded'
import LockRoundedIcon from '@material-ui/icons/LockRounded'
import { Tabs, Tab, Divider, colors, withStyles } from '@material-ui/core'
import { Header, Page } from 'components'
import { General, Parametros, Notifications, Security } from './components'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
})

const Settings = (props) => {
  const { match, history } = props
  const { classes } = props
  const { tab } = match.params

  const handleTabsChange = (event, value) => {
    history.push(value)
  }

  const tabs = [
    { value: 'general', label: 'Geral', icon: <PersonOutlineRoundedIcon /> },
    // {
    //   value: 'parameters',
    //   label: 'Parâmetros ',
    //   icon: <PermDataSettingRoundedIcon />
    // },
    {
      value: 'notifications',
      label: 'Notificações',
      icon: <NotificationsNoneRoundedIcon />
    }
    // { value: 'security', label: 'Segurança', icon: <LockRoundedIcon /> }
  ]

  if (!tab) {
    return <Redirect to="/settings/general" />
  }

  if (!tabs.find((t) => t.value === tab)) {
    return <Redirect to="/errors/error-404" />
  }

  return (
    <Page className={classes.root} title="Configurações">
      <Header subtitle="Configurações" title=" Alterar informações da conta" />
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            icon={tab.icon}
          />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {tab === 'general' && <General />}
        {/* {tab === 'parameters' && <Parametros />} */}
        {tab === 'notifications' && <Notifications />}
        {/* {tab === 'security' && <Security />} */}
      </div>
    </Page>
  )
}

Settings.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    usuario: state.usuario,
    usuarioEstabelecimento: state.usuarioEstabelecimento
  }
}
const connectedSettingsPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Settings))
)
export { connectedSettingsPage as Settings }
