const initialState = {
  anchor: 'left',
  instituicaoBancaria: [],
  open: false,
  id: 0,
  codigo: '',
  nome: ''
}

export function instituicaoBancaria(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_INSTITUICAOBANCARIA':
      return {
        ...state,
        instituicaoBancaria: action.instituicaoBancaria
      }
    case 'INSTITUICAOBANCARIA_DETAIL':
      return {
        ...state,
        id: action.id,
        codigo: action.codigo.toUpperCase(),
        nome: action.nome
      }
    case 'INSTITUICAOBANCARIA_UPDATED':
      return state
    case 'INSTITUICAOBANCARIA_CLEAR':
      return initialState
    case 'INSTITUICAOBANCARIA_CLEAR_ALL':
      return {
        ...state,
        instituicaoBancaria: []
      }
    case 'INSTITUICAOBANCARIA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    default:
      return state
  }
}
