import React, { useState, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Link,
  Grid
} from '@material-ui/core'

import getInitials from 'utils/getInitials'
import { dashboard } from 'mock'
import { formatDistance } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'
import { LabelWithValue } from 'components'
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    ...constants.shadowCard
  },
  statsItem: {
    padding: theme.spacing(1.2, 2),
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    flexGrow: 1
  },
  content: {
    padding: 0,
    marginBottom: theme.spacing(-2.5)
  },
  title: {
    fontSize: '10px'
  },
  date: {
    whiteSpace: 'nowrap'
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1),
    height: 15,
    minWidth: 15,
    padding: 7,
    fontSize: 9
  }
}))

const CustomerActivity = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()
  const { isLoading } = useSelector((state) => state.dashboard)
  const { interacoesUsuarios } = useSelector(
    (state) => state.dashboard.dashboardAdmin
  )
  const { showValues } = useSelector((state) => state.global)

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Atividades de usuários" />
      <Divider />
      <Grid container>
        <Grid item lg={6} md={4} xs={6} className={classes.statsItem}>
          <Typography
            align="center"
            component="h5"
            gutterBottom
            variant="overline"
            className={classes.title}
          >
            Interações
          </Typography>
          {showValues && !isLoading ? (
            <div className={classes.valueContainer}>
              <Typography align="center" variant="h4">
                {interacoesUsuarios &&
                  interacoesUsuarios.totalInteracoes &&
                  interacoesUsuarios.totalInteracoes.valorAtual}
              </Typography>

              <LabelWithValue
                value={
                  interacoesUsuarios &&
                  interacoesUsuarios.totalInteracoes &&
                  interacoesUsuarios.totalInteracoes.percentualComparativo
                }
                oldValue={
                  interacoesUsuarios &&
                  interacoesUsuarios.totalInteracoes &&
                  interacoesUsuarios.totalInteracoes.valorAnterior
                }
                className={classes.label}
                variant="contained"
              />
            </div>
          ) : (
            <Skeleton
              animation={isLoading ? 'pulse' : 'false'}
              width="100%"
              height={24}
            />
          )}
        </Grid>
        <Divider />
        <Grid item lg={6} md={4} xs={6} className={classes.statsItem}>
          <Typography
            align="center"
            component="h5"
            gutterBottom
            variant="overline"
            className={classes.title}
          >
            Comentários
          </Typography>
          {showValues && !isLoading ? (
            <div className={classes.valueContainer}>
              <Typography align="center" variant="h4">
                {interacoesUsuarios &&
                  interacoesUsuarios.totalComentarios &&
                  interacoesUsuarios.totalComentarios.valorAtual}
              </Typography>
              <LabelWithValue
                value={
                  interacoesUsuarios &&
                  interacoesUsuarios.totalComentarios &&
                  interacoesUsuarios.totalComentarios.percentualComparativo
                }
                oldValue={
                  interacoesUsuarios &&
                  interacoesUsuarios.totalComentarios &&
                  interacoesUsuarios.totalComentarios.valorAnterior
                }
                className={classes.label}
                variant="contained"
              />
            </div>
          ) : (
            <Skeleton
              animation={isLoading ? 'pulse' : 'false'}
              width="100%"
              height={24}
            />
          )}
        </Grid>
        <Divider />
        <Grid item lg={6} md={4} xs={6} className={classes.statsItem}>
          <Typography
            align="center"
            component="h5"
            gutterBottom
            variant="overline"
            className={classes.title}
          >
            Conexões
          </Typography>
          {showValues && !isLoading ? (
            <div className={classes.valueContainer}>
              <Typography align="center" variant="h4">
                {interacoesUsuarios &&
                  interacoesUsuarios.totalConexoes &&
                  interacoesUsuarios.totalConexoes.valorAtual}
              </Typography>
              <LabelWithValue
                value={
                  interacoesUsuarios &&
                  interacoesUsuarios.totalConexoes &&
                  interacoesUsuarios.totalConexoes.percentualComparativo
                }
                oldValue={
                  interacoesUsuarios &&
                  interacoesUsuarios.totalConexoes &&
                  interacoesUsuarios.totalConexoes.valorAnterior
                }
                className={classes.label}
                variant="contained"
              />
            </div>
          ) : (
            <Skeleton
              animation={isLoading ? 'pulse' : 'false'}
              width="100%"
              height={24}
            />
          )}
        </Grid>
        <Divider />
        <Grid item lg={6} md={4} xs={6} className={classes.statsItem}>
          <Typography
            align="center"
            component="h5"
            gutterBottom
            variant="overline"
            className={classes.title}
          >
            Avaliações
          </Typography>
          {showValues && !isLoading ? (
            <div className={classes.valueContainer}>
              <Typography align="center" variant="h4">
                {interacoesUsuarios &&
                  interacoesUsuarios.totalAvaliacoes &&
                  interacoesUsuarios.totalAvaliacoes.valorAtual}
              </Typography>
              <LabelWithValue
                value={
                  interacoesUsuarios &&
                  interacoesUsuarios.totalAvaliacoes &&
                  interacoesUsuarios.totalAvaliacoes.percentualComparativo
                }
                oldValue={
                  interacoesUsuarios &&
                  interacoesUsuarios.totalAvaliacoes &&
                  interacoesUsuarios.totalAvaliacoes.valorAnterior
                }
                className={classes.label}
                variant="contained"
              />
            </div>
          ) : (
            <Skeleton
              animation={isLoading ? 'pulse' : 'false'}
              width="100%"
              height={24}
            />
          )}
        </Grid>
        <Divider />
        <Grid item lg={6} md={4} xs={6} className={classes.statsItem}>
          <Typography
            align="center"
            component="h5"
            gutterBottom
            variant="overline"
            className={classes.title}
          >
            Novos usuários
          </Typography>
          {showValues && !isLoading ? (
            <div className={classes.valueContainer}>
              <Typography align="center" variant="h4">
                {interacoesUsuarios &&
                  interacoesUsuarios.novosUsuarios &&
                  interacoesUsuarios.novosUsuarios.valorAtual}
              </Typography>
              <LabelWithValue
                value={
                  interacoesUsuarios &&
                  interacoesUsuarios.novosUsuarios &&
                  interacoesUsuarios.novosUsuarios.percentualComparativo
                }
                oldValue={
                  interacoesUsuarios &&
                  interacoesUsuarios.novosUsuarios &&
                  interacoesUsuarios.novosUsuarios.valorAnterior
                }
                className={classes.label}
                variant="contained"
              />
            </div>
          ) : (
            <Skeleton
              animation={isLoading ? 'pulse' : 'false'}
              width="100%"
              height={24}
            />
          )}
        </Grid>
        <Divider />
        <Grid item lg={6} md={4} xs={6} className={classes.statsItem}>
          <Typography
            align="center"
            component="h5"
            gutterBottom
            variant="overline"
            className={classes.title}
          >
            Usuários ativos
          </Typography>
          {showValues && !isLoading ? (
            <div className={classes.valueContainer}>
              <Typography align="center" variant="h4">
                {interacoesUsuarios &&
                  interacoesUsuarios.usuariosAtivos &&
                  interacoesUsuarios.usuariosAtivos.valorAtual}
              </Typography>
              <LabelWithValue
                value={
                  interacoesUsuarios &&
                  interacoesUsuarios.usuariosAtivos &&
                  interacoesUsuarios.usuariosAtivos.percentualComparativo
                }
                oldValue={
                  interacoesUsuarios &&
                  interacoesUsuarios.usuariosAtivos &&
                  interacoesUsuarios.usuariosAtivos.valorAnterior
                }
                className={classes.label}
                variant="contained"
              />
            </div>
          ) : (
            <Skeleton
              animation={isLoading ? 'pulse' : 'false'}
              width="100%"
              height={24}
            />
          )}
        </Grid>
      </Grid>
      <CardContent className={classes.content}>
        <List disablePadding>
          {interacoesUsuarios &&
            interacoesUsuarios.atividades &&
            interacoesUsuarios.atividades.length > 0 &&
            interacoesUsuarios.atividades.map((activity, i) => {
              const isUser =
                activity &&
                activity.usuario &&
                activity.usuario.id &&
                activity.usuario.id > 0
              return (
                <ListItem
                  hover
                  divider={i < interacoesUsuarios.atividades.length - 1}
                  key={activity.id}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt="foto de perfil do usuário"
                      component={isUser ? RouterLink : 'div'}
                      src={
                        isUser
                          ? activity.usuario.imagem
                          : activity.estabelecimento.imagem
                      }
                      to={isUser ? `usuario/${activity.usuario.id}` : null}
                    >
                      {getInitials(
                        isUser
                          ? activity.usuario.nome
                          : activity.estabelecimento.nome
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    disableTypography
                    primary={
                      <Link
                        color="inherit"
                        component={isUser ? RouterLink : 'div'}
                        to={isUser ? `usuario/${activity.usuario.id}` : null}
                        variant="h6"
                      >
                        {isUser
                          ? activity.usuario.nome
                          : activity.estabelecimento.nome}
                        <Typography
                          align="center"
                          variant="caption"
                          color="textSecondary"
                        >
                          {` (@${isUser
                              ? activity.usuario.username
                              : activity.estabelecimento.username
                            })`}
                        </Typography>
                      </Link>
                    }
                    secondary={
                      <Typography noWrap variant="body2">
                        {activity.titulo}
                        {/* {user.type === 'payment' && (
                      <Link color="inherit" component={RouterLink} to="#">
                        {' '}
                        | Ver pedido
                      </Link>
                    )} */}
                      </Typography>
                    }
                  />
                  <Typography className={classes.date} variant="body2">
                    {formatDistance(new Date(activity.dataHora), new Date(), {
                      locale: brLocale
                    })}
                  </Typography>
                </ListItem>
              )
            })}
        </List>
      </CardContent>
    </Card>
  )
}

CustomerActivity.propTypes = {
  className: PropTypes.string
}

export default CustomerActivity
