import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

//? Own imports
import { MembershipPlan } from 'types/api'
import { crudService } from '_services'

const deleteMembershipPlan = async (idMembershipPlan: Number) => {
  const { data } = await crudService.deleteDetail(`planos/${idMembershipPlan}`)
  return data
}

export default function useMutationMembershipPlanDelete() {
  const queryClient = useQueryClient()
  return useMutation(
    'membershipPlan-delete',
    (idMembershipPlan: Number) => deleteMembershipPlan(idMembershipPlan),
    {
      onError: (error) => {
        // @ts-ignore
        toast.error(error.message || 'Erro ao excluir plano de sócios!')
      },
      onSuccess: (response: MembershipPlan) => {
        // toast.success('Plano de sócios excluido com sucesso!')
        queryClient.setQueryData<MembershipPlan[] | undefined>(
          ['membershipPlan-list', response.estabelecimentoId],
          (oldData) => {
            if (oldData) {
              return oldData.filter((item) => item.id !== response.id)
            }
            return oldData
          }
        )
        queryClient.invalidateQueries([
          'membershipPlan-list',
          response.estabelecimentoId
        ])
      }
    }
  )
}
