import { crudService } from '../_services'
import { toast } from 'react-toastify'

export const permissaoTipoUsuarioAction = {
  getPermissaoTipoUsuario,
  getPermissaoTipoUsuarioByChave,
  onChangePropsList,
  createPermissaoTipoUsuario,
  clear,
  handleCheckedPermitido,
  handleCheckAllPermissaoTipoUsuario,
  handleChangeCheckAll
}
function getPermissaoTipoUsuario(id) {
  return (dispatch) => {
    let apiEndpoint = 'permissoesTiposUsuarios/' + id
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changePermissaoTipoUsuariosList(response.data, 1, id))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function getPermissaoTipoUsuarioByChave(chave) {
  return (dispatch) => {
    let apiEndpoint = 'permissoesTiposUsuarios/' + chave
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(editPermissaoTipoUsuariosDetails(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createPermissaoTipoUsuario(payload) {
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'permissoesTiposUsuarios'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request.status === 200) {
          var responseJSON = JSON.parse(response.request.responseText)
          dispatch(success(responseJSON))
          toast.success('Permissões alteradas com sucesso! 👍', {})
        } else {
          dispatch(failure(response))
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        return err
      })
  }

  function request(payload) {
    return { type: 'PERMISSAO_TIPO_USUARIO_CREATE_REQUEST', payload }
  }

  function success(responseJSON) {
    return {
      type: 'PERMISSAO_TIPO_USUARIO_CREATE_SUCCESS',
      response: responseJSON
    }
  }

  function failure(error) {
    return { type: 'PERMISSAO_TIPO_USUARIO_CREATE_FAILURE', error }
  }
}
function onChangePropsList(props, event, position) {
  return (dispatch) => {
    dispatch(handleOnChangePropsList(props, event.target.checked, position))
  }
}
export function changePermissaoTipoUsuariosList(
  permissaoTipoUsuario,
  totalPages,
  tipoUsuarioId
) {
  return {
    type: 'FETCHED_ALL_PERMISSAO_TIPO_USUARIO',
    permissaoTipoUsuario: permissaoTipoUsuario,
    totalPages: totalPages,
    tipoUsuarioId: tipoUsuarioId
  }
}
export function clear() {
  return {
    type: 'PERMISSAO_TIPO_USUARIO_CLEAR'
  }
}

export function handleCheckedPermitido(idPermissaoTipoUsuario) {
  return {
    type: 'HANDLE_CHECKED_PERMITIDO_PERMISSAO_TIPO_USUARIO',
    id: idPermissaoTipoUsuario
  }
}
export function handleChangeCheckAll() {
  return {
    type: 'HANDLE_CHANGE_CHECK_ALL_PERMISSAO_TIPO_USUARIO'
  }
}
export function handleCheckAllPermissaoTipoUsuario(checked) {
  return {
    type: 'HANDLE_CHECK_ALL_PERMISSAO_TIPO_USUARIO',
    checked: checked
  }
}

export function handleOnChangePropsList(props, value, position) {
  return {
    type: 'HANDLE_ON_CHANGE_LIST_PERMISSAO_TIPO_USUARIO',
    props: props,
    value: value,
    position: position
  }
}
export function editPermissaoTipoUsuariosDetails(permissaoTipoUsuario) {
  return {
    type: 'PERMISSAO_TIPO_USUARIO_DETAIL',
    id: permissaoTipoUsuario.id,
    idPermissao: permissaoTipoUsuario.idPermissao,
    idTipoUsuario: permissaoTipoUsuario.idTipoUsuario,
    permitido: permissaoTipoUsuario.permitido
  }
}
export function createUserInfo() {
  return {
    type: 'PERMISSAO_TIPO_USUARIO_CREATED_SUCCESSFULLY'
  }
}
export function deletePermissaoTipoUsuariosDetails() {
  return {
    type: 'DELETED_PERMISSAO_TIPO_USUARIO_DETAILS'
  }
}
