import React, { useEffect, useState } from 'react'
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react'
import Skeleton from '@material-ui/lab/Skeleton'
import { estabelecimentoAction } from '_actions'
import { useDispatch, useSelector } from 'react-redux'

const mapStyles = {
  width: '100%',
  position: 'relative',
  height: '300px',
  borderRadius: '10px'
}
const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '100%'
}

const MapContainer = (props) => {
  const [showingInfoWindow, setShowingInfoWindow] = useState(false)
  const [activeMarker, setActiveMarker] = useState({})
  const [selectedPlace, setSelectedPlace] = useState({})
  const dispatch = useDispatch()
  const estabelecimento = useSelector((state) => state.estabelecimento)

  const onMarkerClick = (props, marker, e) => {
    setSelectedPlace(props)
    setActiveMarker(marker)
    setShowingInfoWindow(true)
  }

  const onClose = (props) => {
    if (showingInfoWindow) {
      setActiveMarker(null)
      setShowingInfoWindow(false)
    }
  }

  const onClick = (ref, map, coord) => {
    const { latLng } = coord
    const lat = latLng.lat()
    const lng = latLng.lng()
    let latLon = {
      x: lat,
      y: lng
    }
    dispatch(estabelecimentoAction.changeCoordinates(latLon))

    setActiveMarker(null)
  }

  return (
    <div>
      {estabelecimento.latLon && (
        <Map
          google={props.google}
          zoom={16}
          onClick={onClick}
          style={mapStyles}
          containerStyle={containerStyle}
          initialCenter={{
            lat: estabelecimento.latLon.x,
            lng: estabelecimento.latLon.y
          }}
        >
          <Marker
            id={1}
            title={estabelecimento.nome}
            name={estabelecimento.nome}
            draggable={true}
            onDragend={onClick}
            position={{
              lat: estabelecimento.latLon.x,
              lng: estabelecimento.latLon.y
            }}
            onClick={onMarkerClick}
          />
          <InfoWindow
            marker={activeMarker}
            visible={showingInfoWindow}
            onClose={onClose}
          >
            <h4>{estabelecimento.nome}</h4>
            <h6>{estabelecimento.endereco}</h6>
            <h6>
              {estabelecimento.bairro} - {estabelecimento.cep}
            </h6>
          </InfoWindow>
        </Map>
      )}
    </div>
  )
}

const LoadingContainer = (props) => (
  <div>
    <Skeleton animation="wave" />
    <Skeleton animation="wave" />
    <Skeleton animation="wave" />
  </div>
)
export default GoogleApiWrapper({
  language: 'pt-BR',
  apiKey: process.env.REACT_APP_MAPS_KEY,
  LoadingContainer: LoadingContainer
})(MapContainer)
