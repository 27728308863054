export default function formatCurrency(valueToFormat) {
  if (
    valueToFormat !== null &&
    valueToFormat !== undefined &&
    valueToFormat >= 0
  ) {
    return Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(valueToFormat)
  } else {
    return ''
  }
}
