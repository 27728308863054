import React, { useEffect } from 'react'
import { eventoAction } from '../../../../../../_actions'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Typography,
  Link
} from '@material-ui/core'
import { useConfirm } from 'material-ui-confirm'
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded'
import { history, toLocalDateFormat } from 'utils'
import PieChartRoundedIcon from '@material-ui/icons/PieChartRounded'
import { makeStyles } from '@material-ui/styles'
import { AccessControl } from 'components/Utils/AccessControl'
import { MessageData, SkeletonTable } from 'components'
import constants from 'theme/constants'
import AddPhotoAlternateRoundedIcon from '@material-ui/icons/AddPhotoAlternateRounded'

const useStyles = makeStyles(() => ({
  root: {
    ...constants.shadowCard
  }
}))

const PastEvents = () => {
  const classes = useStyles()
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { evento: eventos, isLoading } = useSelector((state) => state.evento)
  const estabelecimentoId = useSelector(
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )

  const handleClickDuplicate = (eventoId) => {
    confirm({
      title: 'Você deseja duplicar esse evento?',
      description: (
        <div>
          <Typography gutterBottom variant="body1" color="textPrimary">
            Todos os dados serão copiados e você só precisa realizar as
            alterações necessárias.
          </Typography>
          <Typography variant="body2" color="textPrimary">
            Obs: O evento só é salvo no final do processo.
          </Typography>
        </div>
      ),
      confirmationText: 'Sim, duplicar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(eventoAction.duplicateEvento(eventoId))
    })
  }

  useEffect(() => {
    dispatch(eventoAction.getEventoByEstabelecimento(estabelecimentoId, true))
  }, [dispatch, estabelecimentoId])

  const handleClickNewEvento = () => {
    dispatch(eventoAction.prepareStateToNewEvento())
  }

  return (
    <>
      {isLoading ? (
        <SkeletonTable />
      ) : eventos.length === 0 ? (
        <MessageData
          text="Você ainda não realizou um evento! 😥"
          srcImage="/images/new-evento.svg"
          buttonText="Criar evento"
          buttonHref={handleClickNewEvento}
        />
      ) : (
        <TableContainer component={Paper} className={classes.root}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Nome do evento</TableCell>
                <TableCell align="center">Data de início</TableCell>
                <TableCell align="center">Data de término</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eventos.map((n) => {
                return (
                  <TableRow hover key={n.id}>
                    <TableCell align="left" component="th" scope="row">
                      <Tooltip arrow title="Ver dashboard deste evento">
                        <Link
                          component="button"
                          color="inherit"
                          onClick={() =>
                            history.push(`/evento/dashboard/${n.id}`, null)
                          }
                        >
                          {n.nome}
                        </Link>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {toLocalDateFormat(n.dataHoraInicio)}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {toLocalDateFormat(n.dataHoraFim)}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        size="small"
                        aria-label="Photo Album"
                        component="a"
                        onClick={() =>
                          history.push(
                            `/photoAlbum/cadastro/evento/${n.id}`,
                            null
                          )
                        }
                      >
                        <Tooltip title="Criar Álbum de Fotos">
                          <AddPhotoAlternateRoundedIcon fontSize="small" />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        size="small"
                        aria-label="Edit"
                        component="a"
                        onClick={() =>
                          history.push(`/evento/dashboard/${n.id}`, null)
                        }
                      >
                        <Tooltip title="Ver dashboard deste evento">
                          <PieChartRoundedIcon fontSize="small" />
                        </Tooltip>
                      </IconButton>
                      <AccessControl
                        rule={'eventos.create'}
                        yes={() => (
                          <Tooltip arrow title="Duplicar evento">
                            <IconButton
                              size="small"
                              className={classes.button}
                              aria-label="Botão para duplicar o evento"
                              onClick={() => handleClickDuplicate(n.id)}
                            >
                              <FileCopyRoundedIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

PastEvents.propTypes = {
  className: PropTypes.string
}

export default PastEvents
