import React from 'react'
import { Bar } from 'react-chartjs-2'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  makeStyles,
  useTheme
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { options } from './options'
import { Skeleton } from '@material-ui/lab'
import constants from 'theme/constants'
import { useEstablishmentDashboard } from 'hooks-querys'

const useStyles = makeStyles(() => ({
  root: {
    ...constants.shadowCard
  },
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}))

const VendasPorEvento = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { estabelecimentoId } = useSelector(
    // @ts-ignore
    (state) => state.usuarioEstabelecimento
  )
  const { data, isFetching } = useEstablishmentDashboard(
    Number(estabelecimentoId)
  )

  const { graficoIngressosEvento } = data || {}

  // @ts-ignore
  const { showValues } = useSelector((state) => state.global)

  const generateData = () => {
    if (graficoIngressosEvento) {
      const data = {
        labels: graficoIngressosEvento.options,
        datasets: [
          {
            label: 'Quantidade de Ingressos',
            yAxisID: 'A',
            backgroundColor: theme.palette.primary.main,
            data: graficoIngressosEvento.series[0]
          },
          {
            label: 'Valor arrecadado',
            yAxisID: 'B',
            backgroundColor: theme.palette.grey[200],
            data: graficoIngressosEvento.series[1]
          }
        ]
      }
      return data
    } else {
      return []
    }
  }

  return (
    <Card className={classes.root}>
      <CardHeader title="QUANTIDADE DE VENDAS POR EVENTO" />
      <Divider />
      <CardContent className={classes.chartContainer}>
        {isFetching || !showValues ? (
          <Skeleton
            animation={isFetching ? 'pulse' : false}
            style={{ marginTop: -140 }}
            height={600}
          />
        ) : (
          <Bar data={generateData} options={options} />
        )}
      </CardContent>
      <Divider />
    </Card>
  )
}

export default VendasPorEvento
