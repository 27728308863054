import { useQuery } from 'react-query'

//? Own imports
import { DashboardEvento } from 'types/dashboard'
import { crudService } from '_services'

const getEventDashboard = async (eventId: number) => {
  const apiEndpoint = `dashboard/evento/${eventId}`
  try {
    const { data } = await crudService.get(apiEndpoint)
    return data
  } catch (e) {
    return e
  }
}

export default function useEventDashboard(eventId: number) {
  return useQuery<DashboardEvento>(
    ['eventDashboard', eventId],
    () => getEventDashboard(eventId),
    {
      enabled: !!eventId,
      refetchOnWindowFocus: false
    }
  )
}
