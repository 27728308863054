import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Card, Typography, Grid } from '@material-ui/core'
import { LabelWithValue } from 'components'
import { formatCurrency } from 'utils'
import { Skeleton } from '@material-ui/lab'
import { useSelector } from 'react-redux'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    ...constants.shadowCard
  },
  content: {
    padding: 0
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  }
}))

const Overview = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()

  const { isLoading, dashboardAdmin } = useSelector((state) => state.dashboard)
  const { showValues } = useSelector((state) => state.global)

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="center" container justify="space-between">
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Receita bruta
          </Typography>
          {showValues && !isLoading ? (
            <div className={classes.valueContainer}>
              <Typography variant="h3">
                {formatCurrency(
                  dashboardAdmin &&
                    dashboardAdmin.receitaBruta &&
                    dashboardAdmin.receitaBruta.valorAtual
                )}
              </Typography>
              <LabelWithValue
                isCurrency
                value={
                  dashboardAdmin &&
                  dashboardAdmin.receitaBruta &&
                  dashboardAdmin.receitaBruta.percentualComparativo
                }
                oldValue={
                  dashboardAdmin &&
                  dashboardAdmin.receitaBruta &&
                  dashboardAdmin.receitaBruta.valorAnterior
                }
                className={classes.label}
                variant="contained"
              />
            </div>
          ) : (
            <Skeleton
              animation={isLoading ? 'pulse' : 'false'}
              width="100%"
              height={28}
            />
          )}
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Lucro liquido
          </Typography>
          {showValues && !isLoading ? (
            <div className={classes.valueContainer}>
              <Typography variant="h3">
                {formatCurrency(
                  dashboardAdmin &&
                    dashboardAdmin.lucroLiquido &&
                    dashboardAdmin.lucroLiquido.valorAtual
                )}
              </Typography>
              <LabelWithValue
                isCurrency
                value={
                  dashboardAdmin &&
                  dashboardAdmin.lucroLiquido &&
                  dashboardAdmin.lucroLiquido.percentualComparativo
                }
                oldValue={
                  dashboardAdmin &&
                  dashboardAdmin.lucroLiquido &&
                  dashboardAdmin.lucroLiquido.valorAnterior
                }
                className={classes.label}
                variant="contained"
              />
            </div>
          ) : (
            <Skeleton
              animation={isLoading ? 'pulse' : 'false'}
              width="100%"
              height={28}
            />
          )}
        </Grid>
        <Grid className={classes.item} item md={2} sm={4} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Valor a transferir
          </Typography>
          {showValues && !isLoading ? (
            <div className={classes.valueContainer}>
              <Typography variant="h3">
                {formatCurrency(
                  dashboardAdmin && dashboardAdmin.valorATransferir
                )}
              </Typography>
            </div>
          ) : (
            <Skeleton
              animation={isLoading ? 'pulse' : 'false'}
              width="100%"
              height={28}
            />
          )}
        </Grid>
        <Grid className={classes.item} item md={2} sm={4} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Tarifa média
          </Typography>
          {showValues && !isLoading ? (
            <div className={classes.valueContainer}>
              <Typography variant="h3">
                {dashboardAdmin &&
                  dashboardAdmin.tarifaMedia &&
                  dashboardAdmin.tarifaMedia.valorAtual.toLocaleString(
                    'pt-BR',
                    { maximumFractionDigits: 2 }
                  )}
                %
              </Typography>
              <LabelWithValue
                value={
                  dashboardAdmin &&
                  dashboardAdmin.tarifaMedia &&
                  dashboardAdmin.tarifaMedia.percentualComparativo
                }
                oldValue={
                  dashboardAdmin &&
                  dashboardAdmin.tarifaMedia &&
                  dashboardAdmin.tarifaMedia.valorAnterior
                }
                className={classes.label}
                variant="contained"
                isPercentage
              />
            </div>
          ) : (
            <Skeleton
              animation={isLoading ? 'pulse' : 'false'}
              width="100%"
              height={28}
            />
          )}
        </Grid>
        <Grid className={classes.item} item md={2} sm={4} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Eventos realizados
          </Typography>
          {showValues && !isLoading ? (
            <div className={classes.valueContainer}>
              <Typography variant="h3">
                {dashboardAdmin &&
                  dashboardAdmin.eventosRealizados &&
                  dashboardAdmin.eventosRealizados.valorAtual}
              </Typography>
              <LabelWithValue
                className={classes.label}
                variant="contained"
                value={
                  dashboardAdmin &&
                  dashboardAdmin.eventosRealizados &&
                  dashboardAdmin.eventosRealizados.percentualComparativo
                }
                oldValue={
                  dashboardAdmin &&
                  dashboardAdmin.eventosRealizados &&
                  dashboardAdmin.eventosRealizados.valorAnterior
                }
              />
            </div>
          ) : (
            <Skeleton
              animation={isLoading ? 'pulse' : 'false'}
              width="100%"
              height={28}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  )
}

Overview.propTypes = {
  className: PropTypes.string
}

export default Overview
