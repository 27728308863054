import { colors } from '@material-ui/core'

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    white: { main: string }
    black: Palette['primary']
  }
  interface PaletteOptions {
    white: { main: string }
    black: PaletteOptions['primary']
  }
}

const white = '#FFFFFF'
const black = '#000000'
//palette Corujas' brand
export const OXFORD_BLUE = '#0B1D35'
export const MIKADO_YELLOW = '#FFC20A'
export const DAVYS_GREY = '#4A4A4A'
export const CHROME_YELLOW = '#F7A400'
export const DIM_GREY = '#666666'
export const BLACK = '#000000'

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: CHROME_YELLOW,
    main: MIKADO_YELLOW,
    light: colors.grey[200]
  },
  secondary: {
    contrastText: white,
    dark: '#030518',
    main: '#030517',
    light: DAVYS_GREY
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: CHROME_YELLOW,
    white: white
  },
  background: {
    default: '#F4F6F8',
    paper: white
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200]
}
