import axios from 'axios'
import { toast } from 'react-toastify'
import { errorHandler } from './errorHandler'

export const cepService = {
  get
}
function get(cep) {
  return axios
    .get(`https://viacep.com.br/ws/${cep}/json`)
    .then((response) => {
      if (response.data.erro === true) {
        return Promise.reject(response)
      } else {
        return response
      }
    })
    .catch((error) => {
      if (error.data.erro === true) {
        toast.error('CEP Inválido!😥 Preencha novamente com atenção.')
        return Promise.reject(error)
      } else {
        return errorHandler(error)
      }
    })
}
