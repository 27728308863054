import { toLocalDateFormat } from 'utils'
const initialState = {
  anchor: 'left',
  lote: [],
  open: false,
  id: 0,
  descricao: '',
  tipoIngressoId: '',
  eventoId: 0,
  valor: '',
  quantidade: '',
  limiteQuantidadePedido: '',
  genero: '',
  repassarTaxa: false,
  dataHoraTermino: null,
  dataHoraInicio: null,
  disponivel: true,
  excluido: false,
  openModalTipoDeIngresso: false
}

export function lote(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_LOTE':
      return {
        ...state,
        lote: action.lote
      }
    case 'LOTE_DETAIL':
      return {
        ...state,
        id: action.id,
        descricao: action.descricao,
        tipoIngressoId: action.tipoIngressoId,
        eventoId: action.eventoId,
        valor: action.valor,
        quantidade: action.quantidade,
        limiteQuantidadePedido: action.limiteQuantidadePedido,
        genero: action.genero,
        repassarTaxa: action.repassarTaxa,
        dataHoraTermino: toLocalDateFormat(action.dataHoraTermino),
        dataHoraInicio: toLocalDateFormat(action.dataHoraInicio),
        disponivel: action.disponivel,
        excluido: action.excluido
      }
    case 'LOTE_UPDATED':
      return state
    case 'OPEN_MODAL_TIPO_DE_INGRESSO':
      return {
        ...state,
        openModalTipoDeIngresso: action.open
      }
    case 'LOTES_BY_EVENTO':
      return {
        ...state,
        lote: action.lotes
      }
    case 'LOTE_CLEAR':
      return { ...initialState, repassarTaxa: action.repassarTaxa }
    case 'LOTE_CLEAR_ALL':
      return {
        ...state,
        lote: []
      }
    case 'LOTE_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    default:
      return state
  }
}
