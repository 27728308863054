import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Typography,
  Grid,
  Button,
  Hidden,
  useTheme,
  useMediaQuery
} from '@material-ui/core'
import BarChartIcon from '@material-ui/icons/BarChart'
import messageTime from 'utils/stringTimeHello'
import { history } from 'utils'
import Image from 'material-ui-image'
import { eventoAction } from '_actions'
import EventRoundedIcon from '@material-ui/icons/EventRounded'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

const Header = (props) => {
  const { className, ...rest } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const dispatch = useDispatch()
  const user = useSelector((state) => state.authentication.user)
  const classes = useStyles()
  const isDisabled = useSelector((state) => {
    if (
      state.usuarioEstabelecimento.usuarioEstabelecimento.length <= 0 ||
      !state.authentication.user.emailVerificado
    ) {
      return true
    }
    return false
  })

  const handleClickNewEvent = () => {
    dispatch(eventoAction.prepareStateToNewEvento())
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="center" container justify="space-between" spacing={3}>
        <Grid item md={6} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Página inicial
          </Typography>
          <Typography component="h1" gutterBottom variant="h3">
            {messageTime()}, {user.nome.split(' ').slice(0, -1).join(' ')}
          </Typography>
          <Typography gutterBottom variant="subtitle1">
            Dê uma espiada no que está acontecendo hoje
          </Typography>
          <Grid
            container
            spacing={2}
            wrap={isMobile ? 'wrap' : 'nowrap'}
            justifyContent="flex-start"
          >
            <Grid item xs={isMobile ? 12 : undefined}>
              <Button
                fullWidth={isMobile}
                edge="start"
                variant="contained"
                disabled={isDisabled}
                onClick={() => history.push('/dashboard')}
                startIcon={<BarChartIcon />}
              >
                Ver dashboard
              </Button>
            </Grid>
            <Grid item xs={isMobile ? 12 : undefined}>
              <Button
                fullWidth={isMobile}
                edge="start"
                variant="contained"
                disabled={isDisabled}
                onClick={() => handleClickNewEvent()}
                startIcon={<EventRoundedIcon />}
              >
                Criar novo evento
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Hidden smDown>
          <Grid item md={6}>
            <Image
              src="/images/home.svg"
              aspectRatio={16 / 9}
              color="transparent"
              disableTransition
            />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string
}

export default Header
