const initialState = {
  anchor: 'left',
  taxa: [],
  open: false,
  id: 0,
  percentual: '',
  valor: '',
  estabelecimentoId: '',
  eventoId: '',
  loteId: ''
}

export function taxa(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_TAXA':
      return {
        ...state,
        taxa: action.taxa
      }
    case 'TAXA_DETAIL':
      return {
        ...state,
        id: action.id,
        percentual: action.percentual,
        valor: action.valor,
        estabelecimentoId: action.estabelecimentoId,
        eventoId: action.eventoId,
        loteId: action.loteId
      }
    case 'TAXA_UPDATED':
      return state
    case 'TAXA_CLEAR':
      return initialState
    case 'TAXA_CLEAR_ALL':
      return {
        ...state,
        taxa: []
      }
    case 'TAXA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    default:
      return state
  }
}
