import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  withStyles,
  makeStyles,
  Table,
  colors,
  Collapse,
  Badge,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Paper,
  IconButton,
  Tooltip
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { ingressoAction } from '_actions'
import { formatCurrency, toLocalDateFormat } from 'utils'
import { MessageData, Label, SkeletonTable } from 'components'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { SortSwitches } from './components'
import { Skeleton } from '@material-ui/lab'

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px'
  }
}))(Badge)

const statusColors = {
  1: colors.orange[600],
  2: colors.red[600],
  3: colors.green[600]
}

const headCells = [
  {
    id: 'evento-lote',
    numeric: false,
    disablePadding: true,
    label: 'Evento - (Lote)'
  },
  {
    id: 'data-evento',
    numeric: true,
    disablePadding: false,
    label: 'Data evento'
  },
  {
    id: 'valor',
    numeric: true,
    disablePadding: false,
    label: 'Valor total (R$)'
  },
  { id: 'status', numeric: true, disablePadding: false, label: 'Status' }
]

function EnhancedTableHead(props) {
  const { order, orderBy } = props

  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  title: {
    flex: '1 1 100%'
  }
}))

export default function TableIngressosNaoLiberados() {
  function Row(props) {
    const { row } = props
    const [open, setOpen] = useState(false)
    const [openLote, setOpenLote] = useState(false)
    const haveRowEvento =
      row.ingressosSortedByEvento !== undefined &&
      row.ingressosSortedByEvento.length > 0
        ? true
        : false

    const sizeIngressoByEvento = ingressos.filter(
      (c) => c.lote.eventoId === row.lote.eventoId
    ).length

    function totalValueRowLote(ingressosSortedByLote) {
      var value = 0
      const haveRowLote =
        ingressosSortedByLote !== undefined && ingressosSortedByLote.length > 0
          ? true
          : false
      if (haveRowLote) {
        value +=
          ingressosSortedByLote.reduce((a, b) => a + (b['valor'] || 0), 0) -
          ingressosSortedByLote.reduce(
            (a, b) => a + (b['valorDesconto'] || 0),
            0
          ) -
          ingressosSortedByLote.reduce((a, b) => a + (b['valorTaxa'] || 0), 0)
      }
      return value
    }

    function totalValueRowEvento(ingressosSortedByEvento) {
      var value = 0
      ingressosSortedByEvento.map((row) => {
        const haveRowLote =
          row.ingressosSortedByLote !== undefined &&
          row.ingressosSortedByLote.length > 0
            ? true
            : false
        if (haveRowLote) {
          value += totalValueRowLote(row.ingressosSortedByLote)
        } else {
          value += row.valor - row.valorTaxa - row.valorDesconto
        }
      })

      return value
    }
    const totalValueRow = haveRowEvento
      ? totalValueRowEvento(row.ingressosSortedByEvento)
      : row.valor - row.valorTaxa - row.valorDesconto

    return (
      <>
        <TableRow
          hover
          role="checkbox"
          aria-checked={open}
          selected={open}
          tabIndex={-1}
          key={row.lote.eventoId}
        >
          <TableCell padding="checkbox">
            <div style={{ display: 'flex', paddingRight: 10 }}>
              {haveRowEvento && (
                <Tooltip title={open ? 'Esconder lotes' : 'Mostrar lotes'}>
                  <IconButton
                    aria-label="expand row"
                    onClick={() => setOpen(!open)}
                  >
                    <StyledBadge
                      max={9999}
                      badgeContent={sizeIngressoByEvento}
                      color="primary"
                    >
                      {open ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </StyledBadge>
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </TableCell>
          <TableCell align="left" component="th" scope="row" padding="none">
            {haveRowEvento
              ? row.lote.evento.nome
              : `${row.lote.evento.nome} - (${row.lote.descricao} - ${row.lote.tipoIngresso.descricao})`}
          </TableCell>
          <TableCell align="right">
            {toLocalDateFormat(row.lote.evento.dataHoraFim)}
          </TableCell>
          <TableCell align="right">
            {showValues ? (
              formatCurrency(totalValueRow)
            ) : (
              <Skeleton
                animation={isLoading ? 'pulse' : 'false'}
                variant="text"
                height={20}
              />
            )}
          </TableCell>
          <TableCell align="right">
            {!haveRowEvento && (
              <Label
                color={statusColors[row.statusIngresso.id]}
                variant="outlined"
              >
                {row.statusIngresso.descricao}
              </Label>
            )}
          </TableCell>
        </TableRow>
        {haveRowEvento && (
          <TableRow>
            <TableCell padding="checkbox" style={{ padding: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Table size="small" aria-label="ingressos por evento">
                  <TableBody>
                    {row.ingressosSortedByEvento.map((row) => {
                      const hasLote =
                        row.ingressosSortedByLote !== undefined &&
                        row.ingressosSortedByLote.length > 0
                          ? true
                          : false

                      const sizeIngressoByLote = ingressos.filter(
                        (c) => c.lote.id === row.lote.id
                      ).length

                      return (
                        <>
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.lote.id}
                          >
                            <TableCell
                              style={{ paddingLeft: 40 }}
                              padding="checkbox"
                            >
                              <div
                                style={{ display: 'flex', paddingRight: 10 }}
                              >
                                {hasLote && (
                                  <Tooltip
                                    title={
                                      openLote
                                        ? 'Esconder ingressos'
                                        : 'Mostrar ingressos'
                                    }
                                  >
                                    <IconButton
                                      aria-label="expand row"
                                      onClick={() => setOpenLote(!openLote)}
                                    >
                                      <StyledBadge
                                        max={9999}
                                        badgeContent={sizeIngressoByLote}
                                        color="primary"
                                      >
                                        {openLote ? (
                                          <KeyboardArrowUpIcon />
                                        ) : (
                                          <KeyboardArrowDownIcon />
                                        )}
                                      </StyledBadge>
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </div>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                              padding="none"
                            >
                              {`${row.lote.descricao} - ${row.lote.tipoIngresso.descricao}`}
                            </TableCell>
                            <TableCell align="right">
                              {toLocalDateFormat(row.lote.evento.dataHoraFim)}
                            </TableCell>
                            <TableCell align="right">
                              {hasLote
                                ? formatCurrency(
                                    totalValueRowLote(row.ingressosSortedByLote)
                                  )
                                : formatCurrency(
                                    row.valor -
                                      row.valorTaxa -
                                      row.valorDesconto
                                  )}
                            </TableCell>
                            <TableCell align="right">
                              {!hasLote && (
                                <Label
                                  color={statusColors[row.statusIngresso.id]}
                                  variant="outlined"
                                >
                                  {row.statusIngresso.descricao}
                                </Label>
                              )}
                            </TableCell>
                          </TableRow>
                          {hasLote && (
                            <TableRow>
                              <TableCell
                                padding="checkbox"
                                style={{ padding: 0 }}
                                colSpan={6}
                              >
                                <Collapse
                                  in={openLote}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Table
                                    size="small"
                                    aria-label="lista de ingressos"
                                  >
                                    <TableBody>
                                      {row.ingressosSortedByLote.map((row) => {
                                        return (
                                          <TableRow
                                            style={{ padding: 0 }}
                                            role="checkbox"
                                            hover
                                            tabIndex={-1}
                                            key={row.id}
                                          >
                                            <TableCell
                                              style={{ paddingLeft: 80 }}
                                              padding="checkbox"
                                            ></TableCell>
                                            <TableCell align="left">
                                              {`Ingresso n.º: ${row.id}`}
                                            </TableCell>
                                            <TableCell align="right">
                                              {`Cliente: ${row.usuario.nome}`}
                                            </TableCell>
                                            <TableCell align="right">
                                              {formatCurrency(
                                                row.valor -
                                                  row.valorTaxa -
                                                  row.valorDesconto
                                              )}
                                            </TableCell>
                                            <TableCell align="right">
                                              <Label
                                                color={
                                                  statusColors[
                                                    row.statusIngresso.id
                                                  ]
                                                }
                                                variant="outlined"
                                              >
                                                {row.statusIngresso.descricao}
                                              </Label>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      })}
                                    </TableBody>
                                  </Table>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )
                    })}
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </>
    )
  }
  const EnhancedTableToolbar = () => {
    const classes = useToolbarStyles()
    return (
      <Toolbar className={classes.root}>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Ingressos a liberar
        </Typography>
        <SortSwitches
          sortByEvento={sortByEvento}
          setSortByEvento={setSortByEvento}
          sortByLote={sortByLote}
          setSortByLote={setSortByLote}
        />
      </Toolbar>
    )
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%'
    },
    table: {
      minWidth: 750
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      boxShadow: 'rgb(0 0 0 / 8%) 0px 2px 8px',
      borderRadius: '8px',
      webkitBorderRadius: '8px',
      mozBorderRadius: '8px'
    }
  }))

  const classes = useStyles()
  const [sortByEvento, setSortByEvento] = useState(true)
  const [sortByLote, setSortByLote] = useState(true)
  const { showValues } = useSelector((state) => state.global)
  const dispatch = useDispatch()
  const ingressos = useSelector((state) => state.ingresso.ingressoALiberar)
  const { isLoading } = useSelector((state) => state.ingresso)
  const estabelecimentoId = useSelector(
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const ingressosSortByEventoAndLote = (ingressos) => {
    var ingressosSorted = []
    if (sortByEvento) {
      ingressos.map((row, index) => {
        if (index === 0) {
          ingressosSorted.push(row)
        } else {
          const ingressosSortedByEventoFindIndex = ingressosSorted.findIndex(
            (item) => item.lote.eventoId === row.lote.eventoId
          )
          if (ingressosSortedByEventoFindIndex !== -1) {
            if (
              ingressosSorted[ingressosSortedByEventoFindIndex]
                .ingressosSortedByEvento === undefined
            ) {
              ingressosSorted[ingressosSortedByEventoFindIndex] = {
                ...ingressosSorted[ingressosSortedByEventoFindIndex],
                ingressosSortedByEvento: [
                  ingressosSorted[ingressosSortedByEventoFindIndex]
                ]
              }

              ingressosSorted[
                ingressosSortedByEventoFindIndex
              ].ingressosSortedByEvento.push(row)
            } else {
              ingressosSorted[
                ingressosSortedByEventoFindIndex
              ].ingressosSortedByEvento.push(row)
            }
          } else {
            ingressosSorted.push(row)
          }
        }
      })
      if (sortByLote) {
        ingressosSorted.map((row, index) => {
          if (row.ingressosSortedByEvento !== undefined) {
            const arrayIngressos = []
            row.ingressosSortedByEvento.map(
              (rowArrayEvento, indexArrayEvento) => {
                if (indexArrayEvento === 0) {
                  arrayIngressos.push(rowArrayEvento)
                } else {
                  const ingressosSortedByLoteFindIndex = arrayIngressos.findIndex(
                    (item) => item.lote.id === rowArrayEvento.lote.id
                  )
                  if (ingressosSortedByLoteFindIndex !== -1) {
                    if (
                      arrayIngressos[ingressosSortedByLoteFindIndex]
                        .ingressosSortedByLote === undefined
                    ) {
                      arrayIngressos[ingressosSortedByLoteFindIndex] = {
                        ...arrayIngressos[ingressosSortedByLoteFindIndex],
                        ingressosSortedByLote: [
                          arrayIngressos[ingressosSortedByLoteFindIndex]
                        ]
                      }

                      arrayIngressos[
                        ingressosSortedByLoteFindIndex
                      ].ingressosSortedByLote.push(rowArrayEvento)
                    } else {
                      arrayIngressos[
                        ingressosSortedByLoteFindIndex
                      ].ingressosSortedByLote.push(rowArrayEvento)
                    }
                  } else {
                    arrayIngressos.push(rowArrayEvento)
                  }
                }
              }
            )
            ingressosSorted[index].ingressosSortedByEvento = arrayIngressos
          }
        })
      }
    } else {
      ingressosSorted = ingressos
    }
    return ingressosSorted
  }

  useEffect(() => {
    !isLoading &&
      dispatch(
        ingressoAction.getIngressosNaoLiberadosByEstabelecimento(
          estabelecimentoId
        )
      )
  }, [estabelecimentoId])

  return (
    <div className={classes.root}>
      {!isLoading ? (
        <>
          {ingressos && ingressos.length > 0 ? (
            <>
              <Typography variant="body2" align="left" gutterBottom>
                Total de ingressos encontrados: <b>{ingressos.length}</b>
              </Typography>
              <Paper className={classes.paper}>
                <EnhancedTableToolbar numSelected={0} />
                <TableContainer>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size="medium"
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead
                      classes={classes}
                      numSelected={0}
                      rowCount={ingressos.length}
                    />
                    <TableBody>
                      {ingressosSortByEventoAndLote(ingressos).map(
                        (row, index) => {
                          return <Row key={row.id} row={row} index={index} />
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </>
          ) : (
            <MessageData
              text="Nenhum ingresso à liberar foi encontrado! 😥 "
              srcImage="/images/no-tickets-found.svg"
              buttonText="Consultar ingressos liberados! 🎟️"
              buttonHref="liberados"
            />
          )}
        </>
      ) : (
        <SkeletonTable />
      )}
    </div>
  )
}
