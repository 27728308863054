import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Header, Page } from 'components'
import {
  Notifications,
  CardSaldo,
  IngressosLiberados,
  IngressosNaoLiberados,
  ContasBancaria,
  Retiradas
} from './components'
import { Redirect, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { globalActions, ingressoAction } from '_actions'
import messageTime from 'utils/stringTimeHello'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  header: {
    paddingBottom: theme.spacing(4)
  },
  cardSaldo: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3)
    }
  },
  notifications: {
    marginTop: theme.spacing(4)
  },
  ingressosLiberados: {
    marginTop: theme.spacing(3)
  }
}))

const Carteira = () => {
  const classes = useStyles()
  const { tab } = useParams()
  const dispatch = useDispatch()
  const { nome } = useSelector((state) => state.authentication.user)
  const estabelecimentoId = useSelector(
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  useEffect(() => {
    dispatch(
      ingressoAction.getIngressosLiberadosByEstabelecimento(estabelecimentoId)
    )
  }, [estabelecimentoId])

  useEffect(() => {
    dispatch(globalActions.setIsVisibleButtonShowValues(true))
    return () => {
      dispatch(globalActions.setIsVisibleButtonShowValues(false))
    }
  }, [])

  if (!tab) {
    return <Redirect to="/carteira/liberados" />
  }
  return (
    <Page className={classes.root} title="Carteira">
      <Header
        className={classes.header}
        subtitle="Carteira"
        title={`${messageTime()}, ${nome.split(' ').slice(0, -1).join(' ')}`}
      />
      <CardSaldo isLoading={false} className={classes.cardSaldo} />
      <Notifications className={classes.notifications} />
      {tab === 'liberados' && (
        <IngressosLiberados className={classes.ingressosLiberados} />
      )}
      {tab === 'aliberar' && (
        <IngressosNaoLiberados className={classes.ingressosLiberados} />
      )}
      {tab === 'contasBancarias' && <ContasBancaria />}
      {tab === 'retiradas' && <Retiradas />}
    </Page>
  )
}

export default Carteira
