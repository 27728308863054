import { crudService } from '../_services/'
import { estabelecimentoAction } from '_actions'
import { toast } from 'react-toastify'

export const usuarioEstabelecimentoAction = {
  changeDetailsUsuarioEstabelecimento,
  getUsuarioEstabelecimento,
  getUsuarioEstabelecimentos,
  addUsuarioByEmail,
  editUsuarioEstabelecimentosDetails,
  deleteUsuarioById,
  setIsAddPlace,
  getUsuarioEstabelecimentoByEstabelecimento
}

function addUsuarioByEmail(payload) {
  return (dispatch) => {
    dispatch(request({ payload }))
    let apiEndpoint = 'usuariosEstabelecimentos/email'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        dispatch(success(response.data))
        toast.success('Usuário adicionado com sucesso! 😎')
        dispatch(
          getUsuarioEstabelecimentoByEstabelecimento(payload.estabelecimentoId)
        )
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(
          getUsuarioEstabelecimentoByEstabelecimento(payload.estabelecimentoId)
        )
      })
  }

  function request(data) {
    return { type: 'USUARIO_ESTABELECIMENTO_ADD_REQUEST', data }
  }

  function success(data) {
    return {
      type: 'USUARIO_ESTABELECIMENTO_ADD_SUCCESS',
      data: data
    }
  }

  function failure(error) {
    return { type: 'USUARIO_ESTABELECIMENTO_ADD_FAILURE', error }
  }
}

function deleteUsuarioById(id) {
  return (dispatch) => {
    dispatch(request(id))
    let apiEndpoint = 'usuariosEstabelecimentos/' + id
    crudService
      .deleteDetail(apiEndpoint)
      .then((response) => {
        dispatch(success(id))
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request(id) {
    return { type: 'USUARIO_ESTABELECIMENTO_DELETE_REQUEST', id }
  }

  function success(id) {
    return {
      type: 'USUARIO_ESTABELECIMENTO_DELETE_SUCCESS',
      usuarioEstabelecimentoId: id
    }
  }

  function failure(error) {
    return { type: 'USUARIO_ESTABELECIMENTO_DELETE_FAILURE', error }
  }
}

function changeDetailsUsuarioEstabelecimento(usuarioEstabelecimento) {
  return (dispatch) => {
    dispatch(onChangeDetailsUsuarioEstabelecimento(usuarioEstabelecimento))
  }
}

function getUsuarioEstabelecimentos(usuarioEstabelecimento) {
  return (dispatch) => {
    dispatch(changeUsuarioEstabelecimentosList(usuarioEstabelecimento))
    if (
      undefined !== usuarioEstabelecimento &&
      usuarioEstabelecimento.length === 1
    ) {
      dispatch(editUsuarioEstabelecimentosDetails(usuarioEstabelecimento[0]))
    }
  }
}

function getUsuarioEstabelecimentoByEstabelecimento(id) {
  return (dispatch) => {
    let apiEndpoint = 'usuariosEstabelecimentos/estabelecimento/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(changeUserPlaceList(response.data))
    })
  }
}

function getUsuarioEstabelecimento(usuarioEstabelecimento) {
  return (dispatch) => {
    dispatch(editUsuarioEstabelecimentosDetails(usuarioEstabelecimento))
  }
}

function setIsAddPlace(isAddPlace, backupId) {
  return {
    type: 'SET_IS_ADD_PLACE',
    isAddPlace: isAddPlace,
    backupId: backupId
  }
}

export function addUsuarioEstabelecimento(payload) {
  return [
    { type: 'ADD_USUARIOESTABELECIMENTO', usuarioEstabelecimento: payload },
    clear()
  ]
}

export function changeUsuarioEstabelecimentosList(usuarioEstabelecimento) {
  return {
    type: 'FETCHED_ALL_USUARIOESTABELECIMENTO',
    usuarioEstabelecimento: usuarioEstabelecimento
  }
}

export function changeUserPlaceList(usuarioEstabelecimento) {
  return {
    type: 'FETCHED_ALL_USERPLACE',
    listUserPlace: usuarioEstabelecimento
  }
}

export function clear() {
  return {
    type: 'USUARIOESTABELECIMENTO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'USUARIOESTABELECIMENTO_CLEAR_ALL'
  }
}

export function onChangeDetailsUsuarioEstabelecimento(usuarioEstabelecimento) {
  return [
    {
      type: 'CHANGE_DETAILS_USUARIOESTABELECIMENTO',
      id: usuarioEstabelecimento.id,
      usuarioId: usuarioEstabelecimento.usuarioId,
      estabelecimentoId: usuarioEstabelecimento.estabelecimentoId,
      excluido: usuarioEstabelecimento.excluido,
      estabelecimento: usuarioEstabelecimento.estabelecimento
    },
    estabelecimentoAction.getEstabelecimentoById(
      usuarioEstabelecimento.estabelecimentoId
    )
  ]
}

export function editUsuarioEstabelecimentosDetails(usuarioEstabelecimento) {
  return {
    type: 'USUARIOESTABELECIMENTO_DETAIL',
    id: usuarioEstabelecimento.id,
    usuarioId: usuarioEstabelecimento.usuarioId,
    estabelecimentoId: usuarioEstabelecimento.estabelecimentoId,
    excluido: usuarioEstabelecimento.excluido,
    estabelecimento: usuarioEstabelecimento.estabelecimento
  }
}

export function updatedUsuarioEstabelecimentoInfo() {
  return {
    type: 'USUARIOESTABELECIMENTO_UPDATED'
  }
}

export function createUsuarioEstabelecimentoInfo() {
  return {
    type: 'USUARIOESTABELECIMENTO_CREATED_SUCCESSFULLY'
  }
}
