import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { cepMask, cpfCnpjMask, history } from 'utils'
import Typography from '@material-ui/core/Typography'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  MenuItem,
  Grid,
  CircularProgress,
  InputAdornment,
  Tooltip,
  IconButton,
  makeStyles,
  FormControlLabel,
  Switch,
  TextField
} from '@material-ui/core'
import YoutubeSearchedForRoundedIcon from '@material-ui/icons/YoutubeSearchedForRounded'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useDispatch, useSelector } from 'react-redux'
import {
  estabelecimentoAction,
  categoriaAction,
  cidadeAction,
  estadoAction,
  hashtagAction
} from '_actions'
import { cnpj, cpf } from 'cpf-cnpj-validator'
import { isEmpty } from 'lodash'
import { HelperToolTip } from 'components'
import { useDidMountEffect } from 'utils/useDidMountEffect'
import constants from 'theme/constants'

const useStyles = makeStyles(() => ({
  root: {},
  paper: {
    ...constants.shadowCard
  }
}))

const EstabelecimentoForm = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const estados = useSelector((state) => state.estado.estado)
  const { cidade } = useSelector((state) => state.cidade)
  const { categoria } = useSelector((state) => state.categoria)
  const { hashtag } = useSelector((state) => state.hashtag)
  const estabelecimento = useSelector((state) => state.estabelecimento)
  const usuarioId = useSelector((state) => state.authentication.user.id)
  const isAddPlace = useSelector(
    (state) => state.usuarioEstabelecimento.isAddPlace
  )
  const minHashtags = 1
  const maxHashtags = 5
  let hashtagLength = 0
  if (estabelecimento.estabelecimentoHashtag !== (null || undefined)) {
    hashtagLength = estabelecimento.estabelecimentoHashtag.length
  }

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidCpf', (value) => {
      if (value && value.length <= 14) {
        if (cpf.isValid(value)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    })

    ValidatorForm.addValidationRule('isValidCnpj', (value) => {
      if (value && value.length >= 15) {
        if (cnpj.isValid(value)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    })

    ValidatorForm.addValidationRule('isValidCep', (value) => {
      if (value.length === 9) {
        return true
      } else {
        return false
      }
    })
    dispatch(categoriaAction.getCategoria())
    dispatch(hashtagAction.getHashtags('', '', '', '', ''))
    dispatch(estadoAction.getEstado())
    if (estabelecimento.estadoId > 0) {
      dispatch(cidadeAction.getCidadeByUf(estabelecimento.estadoId))
    }
  }, [])

  useDidMountEffect(() => {
    if (estabelecimento.estadoId > 0) {
      dispatch(cidadeAction.getCidadeByUf(estabelecimento.estadoId))
    }
  }, [estabelecimento.estadoId])

  useEffect(() => {
    return () => {
      ValidatorForm.removeValidationRule('isValidCpf')
      ValidatorForm.removeValidationRule('isValidCnpj')
      ValidatorForm.removeValidationRule('isValidCep')
    }
  }, [])

  const hashtagPrepareData = (estabelecimentoHashtag) => {
    let readyEstabelecimentoHashtag = []
    estabelecimentoHashtag.map((row) => {
      readyEstabelecimentoHashtag.push({
        id: 0,
        estabelecimentoId: 0,
        hashtagId: row.id,
        excluido: false
      })
    })
    return readyEstabelecimentoHashtag
  }

  const handleOnChangeHashtag = (event) => {
    dispatch(
      estabelecimentoAction.handleOnChangeHashtag(hashtagPrepareData(event))
    )
  }

  function getHashtagObjectById(hashtagId) {
    let hashtagObject = ''
    const hashtagItem = hashtag.find((element) => element.id == hashtagId)
    if (hashtagItem !== undefined) {
      hashtagObject = hashtagItem
    }
    return hashtagObject
  }

  function getHashtagsObjects(estabelecimentoHashtag) {
    let hashtags = []
    if (estabelecimentoHashtag === [] || estabelecimentoHashtag === undefined) {
      hashtags = []
    } else {
      estabelecimentoHashtag.forEach((item) =>
        item.hashtag === undefined
          ? hashtags.push(getHashtagObjectById(item.hashtagId))
          : hashtags.push(getHashtagObjectById(item.hashtag.id))
      )
    }
    return hashtags
  }

  function getCategoriaName(categoriaId) {
    const categoriaItem = categoria.find((element) => element.id == categoriaId)
    if (categoriaItem !== (undefined && null)) {
      return categoriaItem.descricao
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (estabelecimento.username.length < 1) {
      generateUsername()
    }

    let usuarioEstabelecimento = []
    usuarioEstabelecimento.push({
      id: 0,
      usuarioId: usuarioId,
      estabelecimentoId: 0
    })

    let payload = {
      id: estabelecimento.id,
      nome: estabelecimento.nome,
      detalhes: estabelecimento.detalhes,
      endereco: estabelecimento.endereco,
      numero: estabelecimento.numero,
      complemento: estabelecimento.complemento,
      capacidade: estabelecimento.capacidade,
      cidadeId: estabelecimento.cidadeId,
      estadoId: estabelecimento.estadoId,
      imagem: estabelecimento.imagem,
      banner: estabelecimento.banner,
      bairro: estabelecimento.bairro,
      cep: estabelecimento.cep,
      latLon: estabelecimento.latLon,
      username: estabelecimento.username,
      categoriaId: estabelecimento.categoriaId,
      usuarioEstabelecimento: usuarioEstabelecimento,
      cpfCnpj: estabelecimento.cpfCnpj
        .split('.')
        .join('')
        .replace(/[-/.]/g, ''),
      repassarTaxa: estabelecimento.repassarTaxa,
      estabelecimentoHashtag: estabelecimento.estabelecimentoHashtag
    }

    if (estabelecimento.id > 0 && !isAddPlace) {
      delete payload.usuarioEstabelecimento
      dispatch(
        estabelecimentoAction.editEstabelecimentoInfo(
          estabelecimento.id,
          payload
        )
      )
    } else {
      history.push('location')
    }
  }

  const generateUsername = () => {
    let usernameGenerate = {
      target: {
        value: estabelecimento.nome
      }
    }
    dispatch(estabelecimentoAction.onChangeProps('username', usernameGenerate))
  }

  const handleBlur = (prop) => (event) => {
    if (prop === 'cep') {
      var numerosCep = event.target.value.replace('-', '').replace(/\D/g, '')
      if (numerosCep.length === 8) {
        dispatch(estabelecimentoAction.getEnderecoByCep(numerosCep))
      }
    }
  }

  const handleChange = (prop) => (event) => {
    dispatch(estabelecimentoAction.onChangeProps(prop, event))
  }

  return (
    <div className={classes.root}>
      <Grid item md={12} xs={12}>
        <Card className={classes.paper}>
          <ValidatorForm
            className={classes.form}
            id="formEstabelecimento"
            onSubmit={(event) => handleSubmit(event)}
            debounceTime={1100}
          >
            <CardHeader
              title="Dados do estabelecimento "
              subheader={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant="caption">
                    Aqui ficam as informações sobre quem esta organizando os
                    eventos!
                  </Typography>
                  <HelperToolTip>
                    O estabelecimento pode ser um lugar físico ou conter seus
                    dados pessoais caso você seja o organizador.
                  </HelperToolTip>
                </div>
              }
              titleTypographyProps={{ variant: 'h4' }}
            />
            <Divider />
            <CardContent>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12}>
                  <TextValidator
                    variant="outlined"
                    id="nome"
                    label="Nome *"
                    value={estabelecimento.nome}
                    onChange={handleChange('nome')}
                    fullWidth
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    variant="outlined"
                    id="username"
                    label="Apelido *"
                    value={estabelecimento.username}
                    onChange={handleChange('username')}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Gerar apelido">
                            <IconButton
                              aria-label="gerar apelido automaticamente"
                              onClick={() => generateUsername()}
                            >
                              <YoutubeSearchedForRoundedIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                      startAdornment: <Typography>@</Typography>
                    }}
                    onFocus={() =>
                      (estabelecimento.username === '' ||
                        estabelecimento.username === null) &&
                      generateUsername()
                    }
                    // validators={['required']}
                    // errorMessages={['Campo obrigatório!']}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextValidator
                    variant="outlined"
                    fullWidth
                    id="cpfCnpj"
                    label="CPF/CNPJ *"
                    name="cpfCnpj"
                    type="text"
                    inputProps={{
                      maxLength: '18'
                    }}
                    value={cpfCnpjMask(estabelecimento.cpfCnpj)}
                    onChange={handleChange('cpfCnpj')}
                    autoComplete="cpf"
                    validators={['required', 'isValidCpf', 'isValidCnpj']}
                    errorMessages={[
                      'Campo obrigatório!',
                      'CPF inválido!',
                      'CNPJ inválido!'
                    ]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextValidator
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {estabelecimento.isSearchingCep && (
                            <CircularProgress size={24} />
                          )}
                        </InputAdornment>
                      )
                    }}
                    id="cep"
                    disabled={estabelecimento.isSearchingCep}
                    label="CEP *"
                    name="cep"
                    type="text"
                    inputProps={{
                      maxLength: '9'
                    }}
                    value={cepMask(estabelecimento.cep)}
                    onChange={handleChange('cep')}
                    onBlur={handleBlur('cep')}
                    autoComplete="cep"
                    validators={['required', 'isValidCep']}
                    errorMessages={['Campo obrigatório!', 'O CEP não é válido']}
                  />
                </Grid>
                <Grid item sm={5} xs={6}>
                  <SelectValidator
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    variant="outlined"
                    disabled={estabelecimento.isSearchingCep}
                    id="estadoId"
                    label="Estado *"
                    value={estabelecimento.estadoId}
                    onChange={handleChange('estadoId')}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    displayEmpty
                    labelId="estadoId"
                  >
                    <MenuItem disabled value="">
                      <em>Estado *</em>
                    </MenuItem>
                    {!isEmpty(estados) &&
                      undefined !== estados &&
                      estados.length &&
                      estados.map((row) => (
                        <MenuItem key={row.id} value={row.id}>
                          {row.nome}
                        </MenuItem>
                      ))}
                  </SelectValidator>
                </Grid>
                <Grid item sm={7} xs={12}>
                  <SelectValidator
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    disabled={
                      estabelecimento.estadoId === '' ||
                      estabelecimento.isSearchingCep
                        ? true
                        : false
                    }
                    variant="outlined"
                    id="cidadeId"
                    label="Cidade *"
                    value={estabelecimento.cidadeId}
                    onChange={handleChange('cidadeId')}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    displayEmpty
                    labelId="cidadeId"
                  >
                    <MenuItem disabled value="">
                      <em>Cidade *</em>
                    </MenuItem>
                    {!isEmpty(cidade) &&
                      cidade.length &&
                      cidade.map((row) => (
                        <MenuItem key={row.id} value={row.id}>
                          {row.nome}
                        </MenuItem>
                      ))}
                  </SelectValidator>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextValidator
                    variant="outlined"
                    id="endereco"
                    label="Endereço *"
                    value={estabelecimento.endereco}
                    onChange={handleChange('endereco')}
                    fullWidth
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <TextValidator
                    variant="outlined"
                    id="numero"
                    label="Número *"
                    value={estabelecimento.numero}
                    onChange={handleChange('numero')}
                    fullWidth
                    type="text"
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <TextValidator
                    variant="outlined"
                    id="complemento"
                    label="Complemento"
                    value={estabelecimento.complemento}
                    onChange={handleChange('complemento')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextValidator
                    variant="outlined"
                    id="bairro"
                    label="Bairro *"
                    value={estabelecimento.bairro}
                    onChange={handleChange('bairro')}
                    fullWidth
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <SelectValidator
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    variant="outlined"
                    id="categoriaId"
                    label="Categoria *"
                    value={estabelecimento.categoriaId}
                    onChange={handleChange('categoriaId')}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    displayEmpty
                    labelId="categoriaId"
                  >
                    <MenuItem disabled value="">
                      <em>Categoria *</em>
                    </MenuItem>
                    {!isEmpty(categoria) &&
                      undefined !== categoria &&
                      typeof undefined !== categoria &&
                      categoria.length &&
                      categoria.map((row) => (
                        <MenuItem key={row.id} value={row.id}>
                          {row.descricao}
                        </MenuItem>
                      ))}
                  </SelectValidator>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <TextValidator
                    variant="outlined"
                    id="capacidade"
                    label="Capacidade de pessoas"
                    value={estabelecimento.capacidade}
                    onChange={handleChange('capacidade')}
                    fullWidth
                    type="number"
                    inputProps={{
                      min: '1'
                    }}
                    validators={['minNumber:1']}
                    errorMessages={['O valor deve ser positivo!']}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    value={getHashtagsObjects(
                      estabelecimento.estabelecimentoHashtag
                    )}
                    onChange={(event, value) => {
                      handleOnChangeHashtag(value)
                    }}
                    multiple
                    getOptionDisabled={(option) =>
                      hashtagLength === maxHashtags ? true : false
                    }
                    filterSelectedOptions
                    limitTags={5}
                    id="hashtag-selection"
                    options={hashtag}
                    getOptionLabel={(option) => `#${option.descricao}`}
                    groupBy={(option) => getCategoriaName(option.categoriaId)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Selecione hashtags *"
                        placeholder="#hashtags"
                        error={hashtagLength < minHashtags && true}
                        helperText={
                          (hashtagLength === maxHashtags &&
                            'Número máximo de hashtags foi atingido!') ||
                          (hashtagLength < minHashtags &&
                            'Selecione ao menos uma hashtag!')
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    variant="outlined"
                    id="detalhes"
                    label="Quem é o organizador?"
                    value={estabelecimento.detalhes}
                    onChange={handleChange('detalhes')}
                    fullWidth
                    multiline
                    lines="5"
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder={`- Fale um pouco mais sobre quem é o organizador
- Que tal contar uma pequena história?
- Quais são os seus diferenciais?
- O que quer transmitir para o seu público?`}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={estabelecimento.repassarTaxa}
                          onChange={handleChange('repassarTaxa')}
                          color="primary"
                        />
                      }
                      label="Repassar taxa para cliente"
                    />
                    <HelperToolTip>
                      Se ativado, por padrão, a taxa de serviço será repassada
                      para o cliente.
                    </HelperToolTip>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                disabled={estabelecimento.loading}
                type="submit"
                variant="contained"
                color="primary"
              >
                {isAddPlace
                  ? 'Avançar'
                  : estabelecimento.id
                  ? estabelecimento.loading
                    ? 'Atualizando... '
                    : 'Atualizar'
                  : 'Avançar'}
                {estabelecimento.loading && <CircularProgress size={24} />}
              </Button>
            </CardActions>
          </ValidatorForm>
        </Card>
      </Grid>
    </div>
  )
}

EstabelecimentoForm.propTypes = {
  className: PropTypes.string
}

export default EstabelecimentoForm
