import React, { useEffect } from 'react'
import { EventoDashboard as EventoDashboardComponent } from './components'
import { Header, Page } from 'components'
import { useDispatch } from 'react-redux'
import { globalActions } from '_actions'
import { useParams } from 'react-router-dom'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { useEventDashboard } from 'hooks-querys'
import { format } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(3)
    }
  },
  header: {
    marginBottom: theme.spacing(3)
  }
}))

const EventoDashboard = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { eventoId } = useParams<{ eventoId: string }>()
  const {
    data,
    isLoading,
    isFetching,
    isError,
    refetch,
    dataUpdatedAt
  } = useEventDashboard(Number(eventoId))
  const { evento } = data || {}

  useEffect(() => {
    dispatch(globalActions.setIsVisibleButtonShowValues(true))
    return () => {
      dispatch(globalActions.setIsVisibleButtonShowValues(false))
    }
  }, [])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [eventoId])

  return (
    <Page
      // @ts-ignore
      className={classes.root}
      title={`Dashboard por evento: ${evento && evento.nome}`}
    >
      <Header
        className={classes.header}
        subtitle={`Dashboard por evento`}
        title={isLoading ? 'Carregando...' : evento?.nome || ''}
        isRefreshing={isFetching}
        onRefresh={refetch}
      />
      <Box mb={1}>
        {!isLoading ? (
          <Typography display="inline" variant="caption" color="textSecondary">
            Última atualização:{' '}
            {format(dataUpdatedAt, "dd/MM/yyyy 'às' HH:mm:ss", {
              locale: brLocale
            })}{' '}
            {isFetching ? '(Atualizando...)' : ''}
          </Typography>
        ) : null}
      </Box>
      <EventoDashboardComponent />
    </Page>
  )
}

export default EventoDashboard
