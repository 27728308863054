import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { TableIngressosNaoLiberados } from './components'

const useStyles = makeStyles((theme) => ({
  root: {},
  value: {
    fontWeight: theme.typography.fontWeightMedium
  },
  card: {
    borderRadius: '8px',
    boxShadow: '0px 2px 8px rgb(0 0 0 / 8%)',
    height: 200
  }
}))

const IngressosNaoLiberados = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()

  return (
    <Grid
      spacing={2}
      container
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid item xs={12}>
        <TableIngressosNaoLiberados className={classes.card} />
      </Grid>
    </Grid>
  )
}

IngressosNaoLiberados.propTypes = {
  className: PropTypes.string
}

export default IngressosNaoLiberados
