import { useQuery } from 'react-query'

//? Own imports
import { crudService } from '_services'
import {
  EstabelecimentoListAdmin,
  FilterQuery,
  PagedCollectionResponse
} from 'types/api'

const getEstablishments = async ({
  page,
  limit,
  term,
  order,
  direction
}: FilterQuery) => {
  const { data } = await crudService.get(
    `estabelecimentos/admin?Page=${page}&Limit=${limit}&Term=${term}&Order=${order}&Direction=${direction}`
  )
  return data
}

export default function useEstablishments(params: FilterQuery) {
  return useQuery<PagedCollectionResponse<EstabelecimentoListAdmin>>(
    [
      'establishments',
      params.limit,
      params.term,
      params.order,
      params.direction
    ],
    () => getEstablishments(params)
  )
}
