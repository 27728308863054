const initialState = {
  anchor: 'left',
  permissaoUsuario: [],
  open: false,
  id: 0,
  permissaoId: 0,
  usuarioId: 0,
  permitido: false,
  checkedAll: false,
  isLoading: false
}

export function permissaoUsuario(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_PERMISSAO_USUARIO':
      return {
        ...state,
        permissaoUsuario: action.permissaoUsuario,
        usuarioId: action.usuarioId
      }
    case 'PERMISSAO_USUARIO_DETAIL':
      return {
        ...state,
        id: action.id,
        permissaoId: action.permissaoId,
        usuarioId: action.usuarioId,
        permitido: action.permitido
      }
    case 'PERMISSAO_USUARIO_CLEAR':
      return initialState
    case 'PERMISSAO_USUARIO_CLEAR_ALL':
      return {
        ...state,
        permissaoUsuario: []
      }
    case 'PERMISSAO_USUARIO_CREATE_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'PERMISSAO_USUARIO_CREATE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        usuarioId: 0,
        checkedAll: false,
        permissaoUsuario: []
      }
    case 'PERMISSAO_USUARIO_CREATE_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'PERMISSAO_USUARIO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'HANDLE_CHANGE_CHECK_ALL_PERMISSAO_USUARIO':
      return {
        ...state,
        checkedAll: !state.checkedAll
      }
    case 'HANDLE_CHECKED_PERMITIDO_PERMISSAO_USUARIO':
      return {
        ...state,
        permissaoUsuario: state.permissaoUsuario.map((permissaoUsuario) =>
          permissaoUsuario.id === action.id
            ? {
              ...permissaoUsuario,
              permitido: !permissaoUsuario.permitido
            }
            : permissaoUsuario
        )
      }
    case 'HANDLE_CHECK_ALL_PERMISSAO_USUARIO':
      return {
        ...state,
        permissaoUsuario: state.permissaoUsuario.map((permissaoUsuario) => ({
          ...permissaoUsuario,
          permitido: action.checked
        }))
      }
    default:
      return state
  }
}
