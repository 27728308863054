import React, { useEffect } from 'react'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { estabelecimentoAction } from '_actions'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Button,
  Divider,
  makeStyles,
  Typography
} from '@material-ui/core'
import Map from './map'
import Geocode from 'react-geocode'
import { history } from 'utils'
import constants from 'theme/constants'
// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey(process.env.REACT_APP_MAPS_KEY)

// set response language. Defaults to english.
Geocode.setLanguage('pt-BR')

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion('pt-BR')

// Enable or disable logs. Its optional.
Geocode.enableDebug()

const useStyles = makeStyles(() => ({
  root: {},
  paper: {
    ...constants.shadowCard
  }
}))

const Location = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const estabelecimento = useSelector((state) => state.estabelecimento)
  const { cidade } = useSelector((state) => state.cidade)
  const { estado } = useSelector((state) => state.estado)
  const usuarioId = useSelector((state) => state.authentication.user.id)
  const isAddPlace = useSelector(
    (state) => state.usuarioEstabelecimento.isAddPlace
  )
  // const onSubmit = props => {
  //   let payload = {
  //     id: estabelecimento.id,
  //     latLon: [lat, long]
  //   }
  //   dispatch(estabelecimentoAction.editEstabelecimentoInfo(props.estabelecimento.id, payload));
  // };

  // useEffect(() => {
  //   var options = {
  //     enableHighAccuracy: true,
  //     timeout: 5000,
  //     maximumAge: 0
  //   }

  //   function success(position) {
  //     const { latitude, longitude } = position.coords
  //     setCoordinatesInit({
  //       ...estabelecimento.latLon,
  //       x: latitude,
  //       y: longitude
  //     })
  //   }

  //   function error(err) {
  //     setCoordinatesInit({
  //       ...estabelecimento.latLon,
  //       x: -29.4487586,
  //       y: -51.9659174
  //     })
  //   }

  //   if (estabelecimento.latLon !== null) {
  //     setCoordinatesInit({
  //       ...estabelecimento.latLon,
  //       x: estabelecimento.latLon.x,
  //       y: estabelecimento.latLon.y
  //     })
  //   } else {
  //     navigator.geolocation.getCurrentPosition(success, error, options)
  //   }
  // }, [])

  useEffect(() => {
    if (!estabelecimento.id) {
      Geocode.fromAddress(
        prepareAddressFromState(estabelecimento, cidade, estado)
      ).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location
          let latLon = {
            x: lat,
            y: lng
          }
          dispatch(estabelecimentoAction.changeCoordinates(latLon))
        },
        (error) => {
          console.error(error)
        }
      )
    }
  }, [])

  const onClickSaveButton = () => {
    let usuarioEstabelecimento = []
    usuarioEstabelecimento.push({
      id: 0,
      usuarioId: usuarioId,
      estabelecimentoId: 0
    })

    let payload = {
      id: estabelecimento.id,
      nome: estabelecimento.nome,
      detalhes: estabelecimento.detalhes,
      endereco: estabelecimento.endereco,
      numero: estabelecimento.numero,
      complemento: estabelecimento.complemento,
      capacidade: estabelecimento.capacidade,
      cidadeId: estabelecimento.cidadeId,
      estadoId: estabelecimento.estadoId,
      imagem: estabelecimento.imagem,
      banner: estabelecimento.banner,
      bairro: estabelecimento.bairro,
      cep: estabelecimento.cep,
      latLon: estabelecimento.latLon,
      username: estabelecimento.username,
      categoriaId: estabelecimento.categoriaId,
      repassarTaxa: estabelecimento.repassarTaxa,
      usuarioEstabelecimento: usuarioEstabelecimento,
      cpfCnpj: estabelecimento.cpfCnpj
        .split('.')
        .join('')
        .replace(/[-/.]/g, ''),
      estabelecimentoHashtag: estabelecimento.estabelecimentoHashtag
    }
    if (estabelecimento.id && !isAddPlace) {
      delete payload.usuarioEstabelecimento
      dispatch(
        estabelecimentoAction.editEstabelecimentoInfo(
          estabelecimento.id,
          payload
        )
      )
    } else {
      dispatch(estabelecimentoAction.createEstabelecimento(payload, isAddPlace))
    }
  }
  const prepareAddressFromState = (estabelecimento, cidade, estado) => {
    var estadoSelected = estado.find(
      (item) => item.id === estabelecimento.estadoId
    )
    var cidadeSelected = cidade.find(
      (item) => item.id === estabelecimento.cidadeId
    )
    return `${estabelecimento.endereco}, ${estabelecimento.numero} - ${estabelecimento.bairro} , ${cidadeSelected.nome} - ${estadoSelected.uf}, ${estabelecimento.cep}`
  }
  return (
    <Card {...rest} className={clsx(classes.paper, className)}>
      <CardHeader
        title={
          estabelecimento.id ? 'Localização' : 'Essa é a localização correta?'
        }
      />
      <Divider />
      <CardContent>
        <Typography gutterBottom variant="body2" color="textPrimary">
          {estabelecimento.id
            ? 'Mantenha sempre a localização atualizada. '
            : 'Confirme se essa é a localização correta. '}
          Para alterar a localização é só clicar no mapa.{' '}
          <Typography gutterBottom variant="body2" color="textPrimary">
            Obs: A localização ajuda a Corujas a mostrar seu evento para as
            pessoas próximas do local.
          </Typography>
        </Typography>
        <Grid container md={12} xs={12}>
          <Grid item md={12} xs={12}>
            {estabelecimento.latLon && <Map />}
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          onClick={() => history.push('general')}
          variant="contained"
          color="inherit"
        >
          Voltar
        </Button>
        <Button onClick={onClickSaveButton} variant="contained" color="primary">
          {estabelecimento.id ? 'Salvar alterações' : 'Salvar'}
        </Button>
      </CardActions>
    </Card>
  )
}

export default Location
