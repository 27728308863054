import React from 'react'

const Ajuda = () => {
  return (
    <>
      <iframe
        width="100%"
        height="100%"
        src="https://tawk.to/chat/5e4fe5ff298c395d1ce91e1d/default"
        frameBorder="0"
      ></iframe>
    </>
  )
}

export default Ajuda
