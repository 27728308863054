import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  useTheme
} from '@material-ui/core'
import { CarrinhoItem } from 'types/api'
import { formatCurrency, getGenderFull } from 'utils'
import constants from 'theme/constants'

const useStyles = makeStyles(() => ({
  root: {
    ...constants.shadowCard
  },
  content: {
    padding: 0
  }
}))

const OrderItems = ({ carrinhoItens }: { carrinhoItens: CarrinhoItem[] }) => {
  const classes = useStyles()
  const theme = useTheme()
  const getTotalWithDiscount = (item: CarrinhoItem) => {
    if (item.valorDesconto && item.valorDesconto > 0) {
      return (
        <Typography display="inline">
          <Typography
            display="inline"
            color="error"
            style={{
              textDecoration: 'line-through'
            }}
          >
            {formatCurrency(item.valor * item.quantidade)}
          </Typography>
          {' - '}
          <Typography display="inline">
            {formatCurrency(
              (item.valor - item.valorDesconto) * item.quantidade
            )}
          </Typography>
        </Typography>
      )
    } else {
      return (
        <Typography>{formatCurrency(item.valor * item.quantidade)}</Typography>
      )
    }
  }
  const textLoteFormatted = (item: CarrinhoItem) => {
    if (item.lote) {
      return `${item.quantidade}x ${item.lote.tipoIngresso?.descricao}${' '}
            - ${item.lote?.descricao} (${getGenderFull(item.lote?.genero)})`
    }
    return 'Lote não encontrado'
  }

  return (
    <Card className={classes.root}>
      <CardHeader title="Itens do pedido" />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Lote</TableCell>
                <TableCell align="right">Valor (R$)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {carrinhoItens.map((item) => (
                <TableRow key={item.id} hover>
                  <TableCell>{textLoteFormatted(item)}</TableCell>
                  <TableCell align="right">
                    {getTotalWithDiscount(item)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  )
}

export default OrderItems
