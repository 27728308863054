import { useQuery } from 'react-query'
import { crudService } from '_services'

const getMemberById = async (id: number) => {
  const { data } = await crudService.get(`membros/${id}`)
  return data
}

export default function useMemberById(id: number) {
  return useQuery(['member-id', id], () => getMemberById(id), {
    enabled: id > 0,
    cacheTime: 0
  })
}
