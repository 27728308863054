import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  root: {}
}))

const TitleContentWithoutButton = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="flex-end" container justify="space-between" spacing={3}>
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline">
            {props.subTitle}
          </Typography>
          <Typography component="h1" variant="h3">
            {props.title}
          </Typography>
        </Grid>
      </Grid>
      <br></br>
      {props.length !== undefined && props.length !== 0 && (
        <Grid item>
          <Typography variant="body2" align="left" gutterBottom>
            Total de dados encontrados: <b>{props.length}</b>
          </Typography>
        </Grid>
      )}
    </div>
  )
}

TitleContentWithoutButton.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  buttonOnClick: PropTypes.object.isRequired,
  rule: PropTypes.string
}
export default TitleContentWithoutButton
