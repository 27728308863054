import { format } from 'date-fns'

export default function toLocalDateFormat(isoDateTime) {
  if (isoDateTime !== null && isoDateTime !== undefined && isoDateTime !== '') {
    const date = new Date(isoDateTime)
    return format(date, 'dd/MM/yyyy HH:mm')
  } else {
    return null
  }
}
