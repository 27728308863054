import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import validator from 'validator'
import {
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Link,
  Avatar,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TextField,
  Grid,
  Typography
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { withRouter, Link as RouterLink } from 'react-router-dom'
import { usuarioEstabelecimentoAction } from '../../../../../_actions'
import { useConfirm } from 'material-ui-confirm'
import getInitials from 'utils/getInitials'
import constants from 'theme/constants'
import { AccessControl } from 'components/Utils'
const styles = (theme) => ({
  root: {},
  paper: {
    ...constants.shadowCard
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'right'
  }
})
const Usuarios = (props) => {
  const [open, setOpen] = React.useState(false)
  const [email, setEmail] = React.useState('')

  const handleClickOpen = (event) => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    if (validator.isEmail(email)) {
      let payload = {
        email: email,
        estabelecimentoId: props.usuarioEstabelecimento.estabelecimentoId
      }
      dispatch(usuarioEstabelecimentoAction.addUsuarioByEmail(payload))
      setOpen(false)
    }
  }

  const handleChange = (event) => {
    setEmail(event.target.value)
  }

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes, usuarioEstabelecimento } = props

  useEffect(() => {
    dispatch(
      usuarioEstabelecimentoAction.getUsuarioEstabelecimentoByEstabelecimento(
        usuarioEstabelecimento.estabelecimentoId
      )
    )
  }, [dispatch, usuarioEstabelecimento.estabelecimentoId])

  const handleDeleteClick = (id) => {
    confirm({
      title: 'Você deseja excluir este Usuário?',
      description:
        'Confirmando essa operação, este usuário não terá mais acesso ao sistema.',
      confirmationText: 'Sim, excluir!',
      cancellationText: 'Nãããoo!'
    }).then(() => {
      dispatch(usuarioEstabelecimentoAction.deleteUsuarioById(id))
    })
  }

  return (
    <main className={classes.root}>
      <div className={classes.addButton}>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          ADICIONAR USUÁRIO
        </Button>
      </div>
      {usuarioEstabelecimento.listUserPlace.length !== undefined &&
      usuarioEstabelecimento.listUserPlace.length !== 0 ? (
        <Grid item>
          <Typography variant="body2" align="left" gutterBottom>
            Total de dados encontrados:{' '}
            <b>{usuarioEstabelecimento.listUserPlace.length}</b>
          </Typography>
        </Grid>
      ) : (
        <Typography variant="body2" align="left" gutterBottom>
          <b>{'Nenhum dado encontrado.'}</b>
        </Typography>
      )}
      <TableContainer component={Paper} className={classes.paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Email</TableCell>
              {/* <TableCell>Função</TableCell> */}
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {undefined !== usuarioEstabelecimento.listUserPlace &&
              usuarioEstabelecimento.listUserPlace.length &&
              usuarioEstabelecimento.listUserPlace.map((n) => {
                return (
                  <TableRow hover key={n.id}>
                    <TableCell component="th" scope="row">
                      <div className={classes.nameCell}>
                        <Avatar
                          className={classes.avatar}
                          src={n.usuario.imagem}
                        >
                          {getInitials(n.usuario.nome)}
                        </Avatar>
                        <AccessControl
                          rule={'usuarios.listAdmin'} //permissão necessária para acessar conteúdo
                          yes={() => (
                            <div>
                              <Link
                                color="inherit"
                                component={RouterLink}
                                to={`/usuario/${n.usuario.id}`}
                                variant="h6"
                              >
                                {n.usuario.nome}
                              </Link>
                              <div>{n.usuario.email}</div>
                            </div>
                          )}
                          no={() => (
                            <div>
                              <Typography color="inherit" variant="h6">
                                {n.usuario.nome}
                              </Typography>
                              <div>{n.usuario.email}</div>
                            </div>
                          )}
                        />
                      </div>
                    </TableCell>
                    {/* <TableCell>{n.email}</TableCell> */}
                    <TableCell>{n.usuario.email}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        size="small"
                        aria-label="Delete"
                        onClick={(event) => handleDeleteClick(n.id)}
                      >
                        <Tooltip title="Excluir">
                          <DeleteIcon />
                        </Tooltip>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Adicionar usuário</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              Para vincular um usuário a este estabelecimento, é necessário
              informar o e-mail do usuário cadastrado no Corujas. Após o usuário
              aceitar o convite, o mesmo passará a ter privilégios para
              gerenciar o estabelecimento.
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              label="E-mail"
              type="email"
              value={email}
              onChange={handleChange}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Adicionar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </main>
  )
}
Usuarios.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    usuario: state.usuario,
    usuarioEstabelecimento: state.usuarioEstabelecimento
  }
}
const connectedUsuarioPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Usuarios))
)
export { connectedUsuarioPage as Usuarios }
