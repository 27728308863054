import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import { formatDistance } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'

//? Own imports
import getInitials from 'utils/getInitials'
import { Review } from 'types/api'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    ...constants.shadowCard,
    marginTop: theme.spacing(2)
  },
  header: {
    paddingBottom: 0
  },
  subheader: {
    flexWrap: 'wrap',
    display: 'flex',
    alignItems: 'center'
  },
  stars: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  rating: {
    marginLeft: theme.spacing(1),
    fontWeight: 'bold'
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  message: {
    padding: theme.spacing(2, 3)
  },
  noMessage: {
    padding: theme.spacing(1, 3)
  }
}))

const ReviewCard = ({ reviewItem }: { reviewItem: Review }) => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar
            alt="Reviewer"
            src={reviewItem.usuario?.imagem}
            component={RouterLink}
            to={`/usuario/${reviewItem.usuarioId}`}
          >
            {getInitials(reviewItem.usuario?.nome)}
          </Avatar>
        }
        className={classes.header}
        disableTypography
        subheader={
          <div className={classes.subheader}>
            <div className={classes.stars}>
              <Rating
                name="rating"
                value={reviewItem.nota}
                precision={0.1}
                readOnly
              />
              <Typography className={classes.rating} variant="h6">
                {reviewItem.nota}
              </Typography>
            </div>
            {reviewItem.evento && (
              <Typography variant="body2">
                | Referente ao evento{' '}
                <Link
                  color="textPrimary"
                  variant="h6"
                  // href={`evento/dashboard/${reviewItem.eventoId}`}
                >
                  {`${reviewItem.evento?.nome} `}
                </Link>
              </Typography>
            )}
            {reviewItem.estabelecimento && (
              <Typography variant="body2">
                | Estabelecimento:{' '}
                <Link
                  color="textPrimary"
                  variant="h6"
                  // href={`estabelecimento/dashboard/${reviewItem.estabelecimentoId}`}
                >
                  {`${reviewItem.estabelecimento?.nome} `}
                </Link>{' '}
              </Typography>
            )}
            <Typography variant="body2">
            {' '}|{' '}
              {formatDistance(
                new Date(reviewItem.dataHora),
                new Date(),
                {
                  locale: brLocale,
                  addSuffix: true
                }
              )}
            </Typography>
          </div>
        }
        title={
          <Link
            color="textPrimary"
            component={RouterLink}
            to={`/usuario/${reviewItem.usuarioId}`}
            variant="h5"
          >
            {reviewItem.usuario?.nome}
          </Link>
        }
      />
      <CardContent className={classes.content}>
        <div
          className={
            reviewItem.mensagem ? classes.message : classes.noMessage
          }
        >
          <Typography variant="subtitle2">
            {reviewItem.mensagem}
          </Typography>
        </div>
      </CardContent>
    </Card>
  )
}

export default ReviewCard
