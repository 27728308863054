import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { avaliacaoAction, comentarioAction } from '_actions'
import { toLocalDateFormat, useDidMountEffect } from 'utils'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from 'components/Utils/AccessControl'
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded'
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded'
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'
import { Rating } from '@material-ui/lab'
import { MessageData, SkeletonTable } from 'components'

const DetailAndComment = () => {
  const dispatch = useDispatch()
  const confirm = useConfirm()
  const { usuario } = useSelector((state) => state.usuario)
  const avaliacao = useSelector((state) => state.avaliacao)
  const usuarioEstabelecimento = useSelector(
    (state) => state.usuarioEstabelecimento
  )
  const avaliacaoId = useSelector((state) => state.avaliacao.id)
  const { comentario } = useSelector((state) => state.comentario)
  const isLoading = useSelector((state) => state.comentario.isLoading)
  const reload = useSelector((state) => state.comentario.reload)

  const handleClose = () => {
    dispatch(avaliacaoAction.setDetailOpen(false, null))
  }

  const handleLikeComment = (commentId) => {
    dispatch(comentarioAction.likeComment(commentId, avaliacaoId))
  }

  const handleReportComment = () => {
    //dispatch(comentarioAction.reportComment(commentId))
  }

  const handleDeleteComment = (commentId) => {
    confirm({
      title: 'Você deseja excluir esse comentário?',
      description: (
        <div>
          <Typography gutterBottom variant="body1" color="textPrimary">
            Confirmando essa operação, seu comentário será excluido.
          </Typography>
        </div>
      ),
      confirmationText: 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(comentarioAction.deleteComment(commentId, reload))
    })
  }

  function getUsuarioNameById(usuarioId) {
    let user = ''
    user = usuario.find((element) => element.id == usuarioId)
    if (user !== (null || undefined)) {
      return user.nome
    } else {
      return ''
    }
  }

  useEffect(() => {
    dispatch(comentarioAction.getComentarioByAvaliacao(avaliacaoId))
  }, [dispatch, avaliacaoId])

  useDidMountEffect(() => {
    dispatch(comentarioAction.getComentarioByAvaliacao(avaliacaoId))
  }, [reload])

  return (
    <Dialog
      open={avaliacao.detailOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      fullScreen
    >
      <DialogTitle id="form-dialog-title">
        Comentários da avaliação de {getUsuarioNameById(avaliacao.usuarioId)}
      </DialogTitle>
      <DialogContent>
        <Rating
          name="rating"
          value={avaliacao.nota}
          precision={0.1}
          readOnly
          fontSize="small"
        />
        <DialogContentText>{avaliacao.mensagem}</DialogContentText>
        <>
          {comentario !== undefined && comentario.length > 0 ? (
            <TableContainer component={Paper}>
              <div>
                {isLoading ? (
                  <SkeletonTable />
                ) : (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Usuário</TableCell>
                        <TableCell align="center">Comentário</TableCell>
                        <TableCell align="right">Curtidas</TableCell>
                        <TableCell align="right">Data/Hora</TableCell>
                        <TableCell align="right">Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {comentario.map((n) => {
                        return (
                          <>
                            {n.comentario != (null || undefined) && (
                              <TableRow hover key={n.comentario.id}>
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                >
                                  {getUsuarioNameById(n.comentario.usuarioId)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                >
                                  {n.comentario.mensagem}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  component="th"
                                  scope="row"
                                >
                                  {n.numeroCurtidas}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  component="th"
                                  scope="row"
                                >
                                  {toLocalDateFormat(n.comentario.dataHora)}
                                </TableCell>
                                <TableCell align="right">
                                  <AccessControl
                                    rule={'comentarios.list'}
                                    yes={() => (
                                      <IconButton
                                        size="small"
                                        aria-label="Curtir"
                                        onClick={() =>
                                          handleLikeComment(n.comentario.id)
                                        }
                                        disabled={
                                          n.comentario.usuarioId ===
                                          usuarioEstabelecimento.usuarioId
                                        }
                                      >
                                        <>
                                          {n.usuarioCurtiu ? (
                                            <Tooltip title="Descurtir">
                                              <FavoriteRoundedIcon fontSize="small" />
                                            </Tooltip>
                                          ) : (
                                            <Tooltip title="Curtir">
                                              <FavoriteBorderRoundedIcon fontSize="small" />
                                            </Tooltip>
                                          )}
                                        </>
                                      </IconButton>
                                    )}
                                  />
                                  <AccessControl
                                  /*rule={'comentarios.list'}
                                    yes={() => (
                                      <IconButton
                                        size="small"
                                        aria-label="Reportar"
                                        onClick={() =>
                                          handleReportComment(n.comentario.id)
                                        }
                                        disabled={
                                          n.comentario.usuarioId ===
                                          usuarioEstabelecimento.usuarioId
                                        }
                                      >
                                        <Tooltip title="Reportar">
                                          <ReportProblemRoundedIcon fontSize="small" />
                                        </Tooltip>
                                      </IconButton>
                                    )}*/
                                  />
                                  <AccessControl
                                    rule={'comentarios.delete'}
                                    yes={() => (
                                      <IconButton
                                        size="small"
                                        aria-label="Excluir"
                                        onClick={() =>
                                          handleDeleteComment(n.comentario.id)
                                        }
                                        disabled={
                                          n.comentario.usuarioId !==
                                          usuarioEstabelecimento.usuarioId
                                        }
                                      >
                                        <Tooltip title="Excluir">
                                          <DeleteForeverRoundedIcon fontSize="small" />
                                        </Tooltip>
                                      </IconButton>
                                    )}
                                  />
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        )
                      })}
                    </TableBody>
                  </Table>
                )}
              </div>
            </TableContainer>
          ) : (
            <div>
              {isLoading ? (
                <SkeletonTable />
              ) : (
                <MessageData
                  text="Ainda não há comentários para essa avaliação! 😥"
                  srcImage="/images/sad_face.svg"
                />
              )}
            </div>
          )}
        </>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DetailAndComment
