import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  taxaAction,
  estabelecimentoAction,
  eventoAction,
  loteAction
} from '../../_actions'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { formatCurrency, history } from '../../utils'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import { Page } from 'components'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  paper: {
    ...constants.shadowCard
  }
}))

const Taxa = () => {
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const classes = useStyles()
  const { taxa } = useSelector((state) => state.taxa)
  const { estabelecimento } = useSelector((state) => state.estabelecimento)
  const { evento } = useSelector((state) => state.evento)
  const { lote } = useSelector((state) => state.lote)

  useEffect(() => {
    dispatch(taxaAction.getTaxa())
    dispatch(estabelecimentoAction.getEstabelecimento())
    dispatch(eventoAction.getAllEvents())
    dispatch(loteAction.getLote())
  }, [dispatch])

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir esta Taxa?',
      description: 'Confirmando essa operação, esta taxa não valerá mais.',
      confirmationText: 'Sim, excluir!',
      cancellationText: 'Nãããoo!'
    }).then(() => {
      dispatch(taxaAction.deleteTaxaById(id))
    })
  }

  function getEstabelecimentoNameById(estabelecimentoId) {
    let estabelecimentoObject = ''
    if (estabelecimento !== undefined) {
      estabelecimentoObject = estabelecimento.find(
        (element) => element.id == estabelecimentoId
      )
      if (estabelecimentoObject !== undefined) {
        return estabelecimentoObject.nome
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  function getEventoNameById(eventoId) {
    let eventoObject = ''
    if (evento !== undefined) {
      eventoObject = evento.find((element) => element.id == eventoId)
      if (eventoObject !== undefined) {
        return eventoObject.nome
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  function getLoteNameById(loteId) {
    let loteObject = ''
    if (lote !== undefined) {
      loteObject = lote.find((element) => element.id == loteId)
      if (loteObject !== undefined) {
        return loteObject.descricao
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  return (
    <Page className={classes.root} title="Lista de taxas">
      <AccessControl
        rule={'taxas.list'}
        yes={() => (
          <div>
            {undefined !== taxa && taxa.length > 0 ? (
              <TitleContent
                rule={'taxas.create'}
                length={taxa.length}
                subTitle={'GERÊNCIA DE TAXAS'}
                title={'Lista de taxas'}
                href={'/taxa'}
              />
            ) : (
              <TitleContent
                rule={'taxas.create'}
                length={0}
                subTitle={'GERÊNCIA DE TAXAS'}
                title={'Lista de taxas'}
                href={'/taxa'}
              />
            )}
            <TableContainer component={Paper} className={classes.paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Código</TableCell>
                    <TableCell align="center">Percentual (%)</TableCell>
                    <TableCell align="center">Valor (R$)</TableCell>
                    <TableCell align="center">Estabelecimento</TableCell>
                    <TableCell align="center">Evento</TableCell>
                    <TableCell align="center">Lote</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {taxa.map((n) => {
                    return (
                      <TableRow hover key={n.id}>
                        <TableCell align="left" component="th" scope="row">
                          {n.id}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {n.percentual !== null && n.percentual !== ''
                            ? `${n.percentual}%`
                            : '-----'}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {n.valor !== null && n.valor !== ''
                            ? formatCurrency(n.valor)
                            : '-----'}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {getEstabelecimentoNameById(n.estabelecimentoId)}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {getEventoNameById(n.eventoId)}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {getLoteNameById(n.loteId)}
                        </TableCell>
                        <TableCell align="right">
                          <AccessControl
                            rule={'taxas.edit'} //permissão necessária para acessar conteúdo
                            yes={() => (
                              <IconButton
                                size="small"
                                aria-label="Edit"
                                component="a"
                                onClick={() => history.push(`/taxa/${n.id}`)}
                              >
                                <Tooltip title="Editar">
                                  <EditIcon />
                                </Tooltip>
                              </IconButton>
                            )}
                          />
                          <AccessControl
                            rule={'taxas.delete'} //permissão necessária para acessar conteúdo
                            yes={() => (
                              <IconButton
                                size="small"
                                aria-label="Delete"
                                onClick={() => handleClick(n.id)}
                              >
                                <Tooltip title="Excluir">
                                  <DeleteIcon />
                                </Tooltip>
                              </IconButton>
                            )}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      />
    </Page>
  )
}
Taxa.propTypes = {
  classes: PropTypes.object.isRequired
}

export default Taxa
