import { useMutation } from 'react-query'
import { MembershipPlan } from 'types/api'
import { crudService } from '_services'
import { toast } from 'react-toastify'

const postCreateMembershipPlan = async (payload: MembershipPlan) => {
  const { data } = await crudService.post('planos', payload)
  return data
}

export default function useMutationMembershipPlanCreate() {
  return useMutation(
    'membershipPlan-create',
    (payload: MembershipPlan) => postCreateMembershipPlan(payload),
    {
      onError: (error) => {
        // @ts-ignore
        toast.error(error.message || 'Erro ao criar Plano de Membros')
      },
      onSuccess: () => {
        // @ts-ignore
        toast.success('Plano de Membros criado com sucesso!')
      }
    }
  )
}
