const initialState = {
  anchor: 'left',
  tipoUsuario: [],
  open: false,
  id: 0,
  descricao: '',
  excluido: false
}

export function tipoUsuario(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_TIPOUSUARIO':
      return {
        ...state,
        tipoUsuario: action.tipoUsuario
      }
    case 'TIPOUSUARIO_DETAIL':
      return {
        ...state,
        id: action.id,
        descricao: action.descricao,
        excluido: action.excluido
      }
    case 'TIPOUSUARIO_UPDATED':
      return state
    case 'TIPOUSUARIO_CLEAR':
      return initialState
    case 'TIPOUSUARIO_CLEAR_ALL':
      return {
        ...state,
        tipoUsuario: []
      }
    case 'TIPOUSUARIO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    default:
      return state
  }
}
