const initialState = {
  avaliacao: [],
  id: 0,
  usuarioId: 0,
  dataHora: null,
  nota: null,
  mensagem: '',
  estabelecimentoId: 0,
  eventoId: 0,
  privada: false,
  excluido: false,
  isLoading: false,
  answerOpen: false,
  detailOpen: false
}
export function avaliacao(state = initialState, action) {
  const newRatingArray = []
  switch (action.type) {
    case 'REQUEST_ALL_AVALIACOES_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'REQUEST_ALL_AVALIACOES_SUCCESS':
      return {
        ...state,
        isLoading: false,
        avaliacao: action.avaliacoes
      }
    case 'REQUEST_ALL_AVALIACOES_FAILURE':
      return {
        ...state,
        isLoading: false
      }
    case 'SEARCH_AVALIACOES_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'SEARCH_AVALIACOES_SUCCESS':
      return {
        ...state,
        isLoading: false,
        avaliacao: action.avaliacoes,
        eventoId: action.eventoId
      }
    case 'SEARCH_AVALIACOES_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'AVALIACAO_CLEAR_ALL':
      return initialState
    case 'AVALIACAO_ANSWER_OPEN':
      return {
        ...state,
        answerOpen: action.answerOpen,
        usuarioId: action.usuarioId,
        mensagem: action.mensagem,
        id: action.id
      }
    case 'AVALIACAO_ANSWER_SUCCESS':
      return {
        ...state,
        answerOpen: false,
        usuarioId: 0,
        mensagem: '',
        id: 0
      }
    case 'AVALIACAO_DETAIL_OPEN':
      return {
        ...state,
        detailOpen: action.detailOpen,
        usuarioId: action.usuarioId,
        mensagem: action.mensagem,
        id: action.id,
        nota: action.nota
      }
    case 'LIKE_AVALIACOES_REQUEST':
      state.avaliacao.map((rating) => {
        rating.avaliacao.id === action.ratingId
          ? newRatingArray.push({
            ...rating,
            usuarioCurtiu: !rating.usuarioCurtiu,
            numeroCurtidas: rating.usuarioCurtiu
              ? --rating.numeroCurtidas
              : ++rating.numeroCurtidas
          })
          : newRatingArray.push(rating)
      })
      return {
        ...state,
        avaliacao: newRatingArray
      }
    case 'LIKE_AVALIACOES_SUCCESS':
      return {
        ...state
      }
    case 'LIKE_AVALIACOES_FAILURE':
      return {
        ...state
      }
    default:
      return state
  }
}
