import React from 'react'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  Button,
  makeStyles
} from '@material-ui/core'
import LocalOfferTwoToneIcon from '@material-ui/icons/LocalOfferTwoTone'
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import LoyaltyTwoToneIcon from '@material-ui/icons/LoyaltyTwoTone'
import { history } from 'utils'
import { AccessControl } from 'components/Utils/AccessControl'
import { useParams } from 'react-router-dom'
import constants from 'theme/constants'
import { useEventDashboard } from 'hooks-querys'
var pluralize = require('pluralize')

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    ...constants.shadowCard
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 46,
    width: 46
  },
  icon: {
    height: 22,
    width: 22
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  },
  button: {
    marginTop: theme.spacing(2)
  }
}))

const CuponsAtivos = () => {
  const classes = useStyles()
  const { eventoId } = useParams<{ eventoId: string }>()
  const { data, isFetching } = useEventDashboard(Number(eventoId))
  const { cuponsAtivos } = data || {}

  // @ts-ignore
  const { showValues } = useSelector((state) => state.global)
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              CUPONS ATIVOS
            </Typography>
            <Typography variant="h4">
              {isFetching || !showValues ? (
                <Skeleton animation={isFetching ? 'pulse' : false} />
              ) : cuponsAtivos === 1 ? (
                `${cuponsAtivos} CUPOM`
              ) : (
                `${cuponsAtivos} CUPONS`
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <LocalOfferTwoToneIcon className={classes.icon} />
            </Avatar>
          </Grid>
          <Grid item xs={12}>
            <AccessControl
              rule={'cuponsDesconto.list'} //permissão necessária para acessar conteúdo
              // @ts-ignore
              yes={() => (
                <Button
                  className={classes.button}
                  variant="outlined"
                  color="primary"
                  startIcon={<LoyaltyTwoToneIcon />}
                  onClick={() =>
                    history.push(`/cupomDescontos/lista?eventoId=${eventoId}`)
                  }
                >
                  VER LISTA DE CUPONS
                </Button>
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default CuponsAtivos
