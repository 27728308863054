import { useQuery } from 'react-query'

//? Own imports
import { crudService } from '_services'
import { Cidade } from '../../types/api'

const getCityInfo = async (id: number) => {
  const { data } = await crudService.get(`cidades/${id}`)
  return data
}

export default function useCityInfo(id: number) {
  return useQuery<Cidade>(['city-info', id], () => getCityInfo(id), {
    enabled: id > 0
  })
}
