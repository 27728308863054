import { history } from 'utils'
import { crudService } from '../_services/'

export const categoriaAction = {
  getCategoria,
  addCategoria,
  getCategoriaById,
  onChangeProps,
  editCategoriaInfo,
  editCategoriasDetails,
  createCategoria,
  deleteCategoriaById,
  clear,
  clearAll
}

function getCategoria() {
  return (dispatch) => {
    let apiEndpoint = 'categorias'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeCategoriasList(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createCategoria(payload) {
  return (dispatch) => {
    let apiEndpoint = 'categorias/'
    crudService.post(apiEndpoint, payload).then(() => {
      dispatch(createCategoriaInfo())
      dispatch(clear())
      history.push('/categorias')
    })
  }
}

function getCategoriaById(id) {
  return (dispatch) => {
    let apiEndpoint = 'categorias/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editCategoriasDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    dispatch(handleOnChangeProps(props, event.target.value))
  }
}

function editCategoriaInfo(id, payload) {
  return (dispatch) => {
    let apiEndpoint = 'categorias/' + id
    crudService.put(apiEndpoint, payload).then(() => {
      dispatch(updatedCategoriaInfo())
      dispatch(clear())
      history.push('/categorias')
    })
  }
}

function deleteCategoriaById(id) {
  return (dispatch) => {
    let apiEndpoint = 'categorias/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteCategoriasDetails())
      dispatch(getCategoria())
    })
  }
}

export function addCategoria(payload) {
  return [{ type: 'ADD_CATEGORIA', categoria: payload }, clear()]
}

export function changeCategoriasList(categoria) {
  return {
    type: 'FETCHED_ALL_CATEGORIA',
    categoria: categoria
  }
}

export function clear() {
  return {
    type: 'CATEGORIA_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'CATEGORIA_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'CATEGORIA_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editCategoriasDetails(categoria) {
  return {
    type: 'CATEGORIA_DETAIL',
    id: categoria.id,
    descricao: categoria.descricao
  }
}

export function updatedCategoriaInfo() {
  return {
    type: 'CATEGORIA_UPDATED'
  }
}

export function createCategoriaInfo() {
  return {
    type: 'CATEGORIA_CREATED_SUCCESSFULLY'
  }
}

export function deleteCategoriasDetails() {
  return {
    type: 'DELETED_CATEGORIA_DETAILS'
  }
}
