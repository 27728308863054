import { useQuery } from 'react-query'

//? Own imports
import { crudService } from '_services'
import { Estado } from '../../types/api'

const getCountryStateInfo = async (id: number) => {
  const { data } = await crudService.get(`estados/${id}`)
  return data
}

export default function useCountryStateInfo(id: number) {
  return useQuery<Estado>(
    ['countryState-info', id],
    () => getCountryStateInfo(id),
    {
      enabled: id > 0
    }
  )
}
