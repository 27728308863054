import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  colors,
  makeStyles
} from '@material-ui/core'
import { format, formatRelative } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'

//? Own imports
import { Label } from 'components'
import { Cidade, Hashtag, User } from 'types/api'
import constants from 'theme/constants'

const useStyles = makeStyles(() => ({
  root: {
    ...constants.shadowCard
  },
  content: {
    padding: 0
  }
}))

type ExtraInfoProps = {
  user: User
}

const ExtraInfo = ({ user }: ExtraInfoProps) => {
  const classes = useStyles()

  const extractedUserHashtag = () => {
    if (user?.usuarioHashtag && user?.usuarioHashtag.length > 0) {
      const extractedUserHashtag: Hashtag[] = []
      user?.usuarioHashtag?.map((usuarioHashtagItem) => {
        extractedUserHashtag.push(usuarioHashtagItem.hashtag)
      })
      return extractedUserHashtag
    }
    return []
  }

  const extractedUserCities = () => {
    if (user?.usuarioCidade && user?.usuarioCidade.length > 0) {
      const extractedUserCities: Cidade[] = []
      user?.usuarioCidade?.map((usuarioCidadeItem) => {
        extractedUserCities.push(usuarioCidadeItem.cidade)
      })
      return extractedUserCities
    }
    return []
  }

  return (
    <Card className={classes.root}>
      <CardHeader title="Informações extras" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Tipo de usuário</TableCell>
              <TableCell>
                {user && user?.tipoUsuario && user?.tipoUsuario?.descricao
                  ? user?.tipoUsuario?.descricao
                  : 'Não identificado'}
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Data de cadastro</TableCell>
              <TableCell>
                {user?.dataHoraCadastro
                  ? format(
                      new Date(user.dataHoraCadastro),
                      "dd/MM/yyyy 'às' HH:mm:ss"
                    )
                  : 'Não informado'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Último acesso</TableCell>
              <TableCell>
                {user?.dataHoraUltimoAcesso
                  ? format(
                      new Date(user.dataHoraUltimoAcesso),
                      "dd/MM/yyyy 'às' HH:mm:ss"
                    )
                  : 'Não informado'}{' '}
                <br></br>
                {user?.dataHoraUltimoAcesso &&
                  formatRelative(
                    new Date(user.dataHoraUltimoAcesso),
                    new Date(),
                    {
                      locale: brLocale
                    }
                  )}
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Permite publicidade</TableCell>
              <TableCell>
                <div>
                  <Label
                    color={
                      user?.aceiteEmails ? colors.green[600] : colors.red[600]
                    }
                  >
                    {user?.aceiteEmails ? 'SIM' : 'NÃO'}
                  </Label>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Atividade privadas</TableCell>
              <TableCell>
                <div>
                  <Label
                    color={
                      user?.atividadesPrivadas
                        ? colors.green[600]
                        : colors.red[600]
                    }
                  >
                    {user?.atividadesPrivadas ? 'SIM' : 'NÃO'}
                  </Label>
                </div>
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Aceite termos de uso</TableCell>
              <TableCell>
                <div>
                  <Label
                    color={
                      user?.aceiteTermosUso
                        ? colors.green[600]
                        : colors.red[600]
                    }
                  >
                    {user?.aceiteTermosUso ? 'SIM' : 'NÃO'}
                  </Label>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email vendas</TableCell>
              <TableCell>
                <div>
                  <Label
                    color={
                      user?.emailVendas ? colors.green[600] : colors.red[600]
                    }
                  >
                    {user?.emailVendas ? 'SIM' : 'NÃO'}
                  </Label>
                </div>
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Hashtag</TableCell>
              <TableCell>
                {extractedUserHashtag() &&
                  extractedUserHashtag().length > 0 &&
                  extractedUserHashtag().map((hashtag) =>
                    hashtag && hashtag.descricao
                      ? `#${hashtag.descricao}  `
                      : ''
                  )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cidades selecionadas</TableCell>
              <TableCell>
                {extractedUserCities &&
                  extractedUserCities().length > 0 &&
                  extractedUserCities().map((cidade, index) =>
                    cidade && cidade.nome
                      ? `${cidade.nome} - ${cidade.estado.uf}${
                          index < extractedUserCities().length - 1 ? ', ' : ''
                        }`
                      : ''
                  )}
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Dispositivos cadastrados</TableCell>
              <TableCell>{user?.usuarioDispositivo?.length}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total seguidores</TableCell>
              <TableCell>{user?.totalSeguidores}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Total seguindo</TableCell>
              <TableCell>{user?.totalSeguindo}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default ExtraInfo
