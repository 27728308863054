import React from 'react'
import { Avatar, Typography, makeStyles } from '@material-ui/core'

//? Own imports
import { User } from 'types/api'

const useStyles = makeStyles((theme) => ({
  root: {},
  cover: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 180
    },
    [theme.breakpoints.up('md')]: {
      height: 360
    },
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    // filter: 'blur(10px)',
    // webkitFilter: 'blur(10px)',
    // mozFilter: 'blur(10px)',
    // oFilter: 'blur(10px)',
    // msFilter: 'blur(10px)',
    '&:before': {
      position: 'absolute',
      content: '" "',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      backgroundImage:
        'linear-gradient(-180deg, rgba(0,0,0,0.00) 58%, rgba(0,0,0,0.32) 100%)'
    },
    '&:hover': {
      '& $changeButton': {
        visibility: 'visible'
      }
    }
  },
  container: {
    maxWidth: '100%',
    padding: theme.spacing(2, 3),
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  avatar: {
    border: `2px solid ${theme.palette.white}`,
    height: 120,
    width: 120,
    top: -60,
    left: theme.spacing(3),
    position: 'absolute'
  },
  details: {
    marginLeft: 136
  }
}))

type HeaderProps = {
  user: User | undefined
}
const Header = ({ user }: HeaderProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div
        className={classes.cover}
        style={{ backgroundImage: `url(${user?.imagem})` }}
      />
      <div className={classes.container}>
        <Avatar alt="Person" className={classes.avatar} src={user?.imagem} />
        <div className={classes.details}>
          <Typography component="h2" gutterBottom variant="overline">
            @{user?.username}
          </Typography>
          <Typography component="h1" variant="h4">
            {user?.nome}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default Header
