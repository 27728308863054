import { useMutation } from 'react-query'
import { PlanPrice } from 'types/api'
import { crudService } from '_services'
import { toast } from 'react-toastify'

const postCreatePlanPrice = async (payload: PlanPrice) => {
  const { data } = await crudService.post('planosValor', payload)
  return data
}

export default function useMutationPlanPriceCreate() {
  return useMutation(
    'PlanPrice-create',
    (payload: PlanPrice) => postCreatePlanPrice(payload),
    {
      onError: (error) => {
        toast.error(
          // @ts-ignore
          error.message || 'Erro ao criar valor/periodicidade do plano!'
        )
      },
      onSuccess: (payload) => {
        // @ts-ignore
        toast.success(`Valor/periodicidade adicionado ao plano com sucesso!`)
      }
    }
  )
}
