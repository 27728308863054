import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { Page } from 'components'
import { useDispatch } from 'react-redux'
import {
  Overview,
  MostProfitableOrganizes,
  CustomerActivity,
  Header,
  PlatformSales,
  TypeInteractions,
  PaymentTypeSales
} from './components'
import { globalActions } from '_actions'
import { AccessControl } from '../../components/Utils'
import AccessDenied from 'views/Errors/AccessDenied'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },

  container: {
    '& > *': {
      height: '100%'
    }
  }
}))

const DashboardAdmin = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(globalActions.setIsVisibleButtonShowValues(true))
    return () => {
      dispatch(globalActions.setIsVisibleButtonShowValues(false))
    }
  }, [])

  return (
    <Page
      className={classes.root}
      title="Dashboard Administrativo - Visão geral de finanças"
    >
      <AccessControl
        rule={'dashboardAdmin.view'} //permissão necessária para acessar conteúdo
        yes={() => (
          <>
            <Header />
            <Grid className={classes.container} container spacing={3}>
              <Grid item xs={12}>
                <Overview />
              </Grid>
              <Grid item lg={7} xs={12}>
                <MostProfitableOrganizes />
              </Grid>
              <Grid item lg={5} xs={12}>
                <CustomerActivity />
              </Grid>
              <Grid item lg={4} xs={12}>
                <PaymentTypeSales />
              </Grid>
              <Grid item lg={4} xs={12}>
                <PlatformSales />
              </Grid>
              <Grid item lg={4} xs={12}>
                <TypeInteractions />
              </Grid>
            </Grid>
          </>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}

export default DashboardAdmin
