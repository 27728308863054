import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  width: 100%;
  /* margin: 30px; */
  background: #fff;
  border-radius: 4px;
  /* padding: 20px; */
`
