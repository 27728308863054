import { useQuery } from 'react-query'

//? Own imports
import { crudService } from '_services'

const getUserLogs = async (userId: Number) => {
  const { data } = await crudService.get(`logs/usuario/${userId}`)
  return data
}

export default function useUserLogs(userId: Number) {
  return useQuery(['user-logs', userId], () => getUserLogs(userId))
}
