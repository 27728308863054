import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Link,
  Hidden
} from '@material-ui/core'
import Image from 'material-ui-image'
import PersonAddIcon from '@material-ui/icons/PersonAddOutlined'

import gradients from 'utils/gradients'
import { Page } from 'components'
import { CadastroForm } from './components'
import SocialButtons from './components/SocialButtons/SocialButtons'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  dataLayer: {
    event: 'pageView',
    page: '/cadastro'
  },
  dataLayerName: 'PaginaCadastro'
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(6, 2)
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: '100%',
    overflow: 'unset',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(20),
      marginBottom: theme.spacing(3)
    }
  },
  content: {
    padding: theme.spacing(6, 4, 3, 3)
  },
  media: {
    // borderTopRightRadius: 4,
    // borderBottomRightRadius: 4,
    padding: theme.spacing(7)
    // color: theme.palette.white,
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'flex-end',
    // [theme.breakpoints.down('md')]: {
    //   display: 'none'
    // }
  },
  icon: {
    backgroundImage: gradients.primary,
    color: theme.palette.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32
  },
  registerForm: {
    marginTop: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  person: {
    marginTop: theme.spacing(2),
    display: 'flex'
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}))

const Cadastro = () => {
  TagManager.dataLayer(tagManagerArgs)
  const classes = useStyles()
  return (
    <Page className={classes.root} title="Cadastro">
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <PersonAddIcon className={classes.icon} />
          <Typography gutterBottom variant="h3">
            Cadastrar-se
          </Typography>
          <Typography gutterBottom variant="subtitle2">
            Descubra uma forma simples de gerenciar eventos
          </Typography>
          <SocialButtons />
          <Divider className={classes.divider} />
          {/* <Typography align="center" variant="caption">
            ou
          </Typography> */}
          <CadastroForm className={classes.registerForm} />
          <Divider className={classes.divider} />
          <Link
            align="center"
            color="secondary"
            component={RouterLink}
            to="/login"
            underline="always"
            variant="subtitle2"
          >
            Já tem uma conta? 🤔
          </Link>
        </CardContent>
        <Hidden smDown>
          <Image
            animationDuration={3000}
            className={classes.media}
            src="/images/img-register.svg"
            aspectRatio={16 / 9}
          />
        </Hidden>
      </Card>
    </Page>
  )
}

export default Cadastro
