import React from 'react'
import clsx from 'clsx'
import { Grid, makeStyles } from '@material-ui/core'
import { ProfileDetails, EstabelecimentoForm } from './components'
import { Page } from 'components'

const useStyles = makeStyles(() => ({
  root: {}
}))

const General = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Estabelecimento">
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={4} md={6} xl={3} xs={12}>
          <ProfileDetails />
        </Grid>
        <Grid item lg={8} md={6} xl={9} xs={12}>
          <EstabelecimentoForm />
        </Grid>
      </Grid>
    </Page>
  )
}

export default General
