import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
  Divider,
  Tooltip
} from '@material-ui/core'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import CloseIcon from '@material-ui/icons/Close'

//? Own imports
import { useDidMountEffect } from 'utils/useDidMountEffect'
import { history } from 'utils'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2)
  },
  divider: {
    height: 28,
    margin: 4
  }
}))

function useSearchQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const Filters = () => {
  const classes = useStyles()
  const querySearch = useSearchQuery()
  const [filters, setFilters] = useState({
    textSearch: querySearch.get('textSearch') || ''
  })

  const runQuery = () => {
    history.push(`/usuarios?textSearch=${filters.textSearch}`)
  }

  const handleChangeText = (value: string) => {
    setFilters((prev) => ({ ...prev, textSearch: value }))
  }

  const handleSubmitSearch = (event: React.FormEvent) => {
    event.preventDefault()
    if (filters.textSearch && filters.textSearch.length > 0) {
      runQuery()
    }
  }

  const clearTextSearch = () => {
    setFilters((prev) => ({ ...prev, textSearch: '' }))
  }

  useDidMountEffect(() => {
    if (filters.textSearch === '') {
      runQuery()
    }
  }, [filters.textSearch])

  useDidMountEffect(() => {
    setFilters((prev) => ({
      ...prev,
      textSearch: querySearch.get('textSearch') || ''
    }))
  }, [querySearch])

  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={12}>
          <form autoComplete="off" onSubmit={handleSubmitSearch}>
            <TextField
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {filters.textSearch.length >= 1 && (
                      <>
                        <Tooltip title="Limpar">
                          <IconButton onClick={clearTextSearch}>
                            <CloseIcon opacity="0.8" />
                          </IconButton>
                        </Tooltip>
                        <Divider
                          className={classes.divider}
                          orientation="vertical"
                        />
                      </>
                    )}
                    <Tooltip title="Buscar">
                      <IconButton type="submit" aria-label="buscar">
                        <SearchRoundedIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                )
              }}
              fullWidth
              required
              value={filters.textSearch}
              onChange={(event) =>
                handleChangeText && handleChangeText(event.target.value)
              }
              variant="outlined"
              id="textForSearch"
              label="Buscar"
              placeholder="Busque por nome, email, CPF/CNPJ ou nome do usuário..."
            />
          </form>
        </Grid>
      </Grid>
    </div>
  )
}

export default Filters
