import { isPast, isAfter, isBefore } from 'date-fns'
import toISODateFormat from './toISODateFormat'

export default function validateDatesLote(dataInicio, dataFim, loteId) {
  var isValid = true
  if (
    isAfter(
      new Date(toISODateFormat(dataInicio)),
      new Date(toISODateFormat(dataFim))
    ) ||
    isBefore(
      new Date(toISODateFormat(dataFim)),
      new Date(toISODateFormat(dataInicio))
    )
  ) {
    isValid = false
  } else if (isPast(new Date(toISODateFormat(dataFim)))) {
    isValid = false
  }
  //Comentado para aceitar datas passadas, facilitar input..
  /*if (loteId === 0) {
    if (isPast(new Date(toISODateFormat(dataInicio)))) {
      isValid = false
    }
  }*/
  return isValid
}
