import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'

import {
  CardMovimentacaoFinanceira,
  CardContaBancaria,
  CardEstabelecimento,
  OtherActions
} from './components'
import { AccessControl } from 'components/Utils/AccessControl'

const useStyles = makeStyles(() => ({
  root: {}
}))

const Summary = ({ movimentacaoFinanceira }, props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const { contaBancaria, estabelecimento } = movimentacaoFinanceira

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >
      <AccessControl
        rule={'movimentacoesFinanceiras.finish'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid item lg={7} md={7} xl={7} xs={12}>
            <CardMovimentacaoFinanceira
              movimentacaoFinanceira={movimentacaoFinanceira}
            />
          </Grid>
        )}
        no={() => (
          <Grid item lg={6} md={6} xl={6} xs={12}>
            <CardMovimentacaoFinanceira
              movimentacaoFinanceira={movimentacaoFinanceira}
            />
          </Grid>
        )}
      />
      <AccessControl
        rule={'movimentacoesFinanceiras.finish'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid item lg={5} md={5} xl={5} xs={12}>
            <OtherActions />
          </Grid>
        )}
      />
      <Grid item lg={6} md={6} xl={6} xs={12}>
        <CardContaBancaria contaBancaria={contaBancaria} />
      </Grid>
      <AccessControl
        rule={'movimentacoesFinanceiras.finish'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid item lg={6} md={6} xl={6} xs={12}>
            <CardEstabelecimento estabelecimento={estabelecimento} />
          </Grid>
        )}
      />
    </Grid>
  )
}

Summary.propTypes = {
  className: PropTypes.string
}

export default Summary
