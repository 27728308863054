export const globalActions = {
  setShowValues,
  setIsVisibleButtonShowValues
}

function setShowValues() {
  return {
    type: 'SET_SHOW_VALUES'
  }
}

function setIsVisibleButtonShowValues(isVisible) {
  return {
    type: 'SET_IS_VISIBLE_BUTTON_SHOW_VALUES',
    isVisible
  }
}
