const initialState = {
  contaBancaria: [],
  id: '',
  estabelecimentoId: 0,
  instituicaoBancaria: {},
  instituicaoBancariaId: 0,
  descricao: '',
  agencia: '',
  conta: '',
  cpfCnpjTitular: '',
  nomeTitular: '',
  chavePix: '',
  tipoChavePix: '',
  padrao: false,
  isFetching: false
}

export function contaBancaria(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_CONTABANCARIA_BY_ESTABELECIMENTO_REQUEST':
      return {
        ...state,
        isFetching: true
      }
    case 'FETCHED_CONTABANCARIA_BY_ESTABELECIMENTO_SUCCESS':
      return {
        ...state,
        isFetching: false,
        contaBancaria: action.contaBancaria
      }
    case 'FETCHED_CONTABANCARIA_BY_ESTABELECIMENTO_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    case 'GET_CONTABANCARIA_BY_ID_REQUEST':
      return {
        ...state,
        isFetching: true
      }
    case 'GET_CONTABANCARIA_BY_ID_SUCCESS':
      return {
        ...state,
        isFetching: false
      }
    case 'GET_CONTABANCARIA_BY_ID_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    case 'FETCHED_ALL_CONTABANCARIA':
      return {
        ...state,
        contaBancaria: action.contaBancaria
      }
    case 'CONTABANCARIA_DETAIL':
      return {
        ...state,
        id: action.id,
        estabelecimentoId: action.estabelecimentoId,
        instituicaoBancariaId: action.instituicaoBancariaId,
        instituicaoBancaria: action.instituicaoBancaria,
        descricao: action.descricao,
        agencia: action.agencia,
        conta: action.conta,
        cpfCnpjTitular: action.cpfCnpjTitular,
        nomeTitular: action.nomeTitular,
        chavePix: action.chavePix,
        tipoChavePix: action.tipoChavePix,
        padrao: action.padrao
      }
    case 'CONTABANCARIAO_UPDATED':
      return state
    case 'CONTABANCARIA_CLEAR':
      return initialState
    case 'CONTABANCARIA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    default:
      return state
  }
}
