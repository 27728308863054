export const albumAction = {
  addFoto,
  deleteFoto,
  deleteAllFoto,
  updateFoto,
  shortFoto,
  setFotoCapaId,
  toggleEnabledSortableContext
}

function shortFoto(foto) {
  return {
    type: 'SHORT_PHOTO',
    foto: foto
  }
}

function toggleEnabledSortableContext() {
  return {
    type: 'TOGGLE_ENABLED_SORTABLE_CONTEXT'
  }
}

function addFoto(foto) {
  return {
    type: 'ADD_PHOTO',
    foto: foto
  }
}

function updateFoto(data) {
  return {
    type: 'UPDATE_PHOTO',
    data: data
  }
}

function deleteFoto(foto) {
  return {
    type: 'DELETE_PHOTO',
    foto: foto
  }
}

function deleteAllFoto() {
  return {
    type: 'DELETE_ALL_PHOTO'
  }
}

function setFotoCapaId(fotoCapaId) {
  return {
    type: 'SET_FOTO_CAPA_ID',
    fotoCapaId: fotoCapaId
  }
}
