import React, { useEffect } from 'react'
import { eventoAction } from '../../../../../../_actions'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Typography,
  Link
} from '@material-ui/core'
import DeleteIconRoundedIcon from '@material-ui/icons/DeleteRounded'
import EditIconRoundedIcon from '@material-ui/icons/EditRounded'
import { useConfirm } from 'material-ui-confirm'
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded'
import EventAvailableTwoToneIcon from '@material-ui/icons/EventAvailableTwoTone'
import EventBusyTwoToneIcon from '@material-ui/icons/EventBusyTwoTone'
import { history, toLocalDateFormat } from 'utils'
import PieChartRoundedIcon from '@material-ui/icons/PieChartRounded'
import ShareIcon from '@material-ui/icons/Share'
import { makeStyles } from '@material-ui/styles'
import { AccessControl } from 'components/Utils/AccessControl'
import { MessageData, SkeletonTable } from 'components'
import { onShare } from 'utils/linkShare'
import { toast } from 'react-toastify'
import constants from 'theme/constants'
import GroupAddRoundedIcon from '@material-ui/icons/GroupAddRounded'

const useStyles = makeStyles(() => ({
  root: {
    ...constants.shadowCard
  }
}))

const FutureEvents = () => {
  const classes = useStyles()
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { evento: eventos, isLoading } = useSelector((state) => state.evento)
  const estabelecimentoId = useSelector(
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )

  const handleClickDuplicate = (eventoId) => {
    confirm({
      title: 'Você deseja duplicar esse evento?',
      description: (
        <div>
          <Typography gutterBottom variant="body1" color="textPrimary">
            Todos os dados serão copiados e você só precisa realizar as
            alterações necessárias.
          </Typography>
          <Typography variant="body2" color="textPrimary">
            Obs: O evento só é salvo no final do processo.
          </Typography>
        </div>
      ),
      confirmationText: 'Sim, duplicar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(eventoAction.duplicateEvento(eventoId))
    })
  }

  const handleClickEditEvento = (eventoId) => {
    dispatch(eventoAction.prepareStateToEditEvento(eventoId))
  }

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir este Evento?',
      description:
        'Confirmando essa operação, seu evento não aparecerá mais no site da Corujas.',
      confirmationText: 'Sim, excluir!',
      cancellationText: 'Nãããoo!'
    }).then(() => {
      dispatch(eventoAction.deleteEventoById(id))
    })
  }

  const handleClickGenerateTicketsToMembers = (eventId) => {
    confirm({
      title:
        'Você deseja gerar os ingressos, para os membros ativos, para este Evento?',
      confirmationText: 'Sim, gerar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(eventoAction.generateTicketsToMembers(eventId))
    })
  }

  useEffect(() => {
    dispatch(eventoAction.getEventoByEstabelecimento(estabelecimentoId, false))
  }, [dispatch, estabelecimentoId])

  const handleClickNewEvento = () => {
    dispatch(eventoAction.prepareStateToNewEvento())
  }

  return (
    <>
      {isLoading ? (
        <SkeletonTable />
      ) : eventos.length === 0 ? (
        <MessageData
          text="Não há eventos futuros! 😥"
          srcImage="/images/new-evento.svg"
          buttonText="Criar evento"
          buttonHref={handleClickNewEvento}
        />
      ) : (
        <TableContainer component={Paper} className={classes.root}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Nome do evento</TableCell>
                <TableCell align="center">Data de início</TableCell>
                <TableCell align="center">Data de término</TableCell>
                <TableCell align="center">Disponível</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eventos.map((n) => {
                return (
                  <TableRow hover key={n.id}>
                    <TableCell align="left" component="th" scope="row">
                      <Tooltip arrow title="Ver dashboard deste evento">
                        <Link
                          component="button"
                          color="inherit"
                          onClick={() =>
                            history.push(`/evento/dashboard/${n.id}`, null)
                          }
                        >
                          {n.nome}
                        </Link>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {toLocalDateFormat(n.dataHoraInicio)}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {toLocalDateFormat(n.dataHoraFim)}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {n.disponivel ? (
                        <EventAvailableTwoToneIcon color="primary" />
                      ) : (
                        <EventBusyTwoToneIcon color="error" />
                      )}
                    </TableCell>

                    <TableCell align="right">
                      <AccessControl
                        // rule={'membros.create'}
                        rule={'movimentacoesFinanceiras.finish'}
                        yes={() => (
                          <Tooltip
                            arrow
                            title="Gerar Ingressos Para Membros Ativos"
                          >
                            <IconButton
                              size="small"
                              className={classes.button}
                              aria-label="generateTickets"
                              onClick={() =>
                                handleClickGenerateTicketsToMembers(n.id)
                              }
                            >
                              <GroupAddRoundedIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      />
                      <IconButton
                        size="small"
                        aria-label="Edit"
                        component="a"
                        onClick={() =>
                          history.push(`/evento/dashboard/${n.id}`, null)
                        }
                      >
                        <Tooltip title="Ver dashboard deste evento">
                          <PieChartRoundedIcon fontSize="small" />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        size="small"
                        aria-label="CopyLink"
                        component="a"
                        onClick={async () => {
                          const link = await onShare({
                            ...n,
                            dataHoraInicio: toLocalDateFormat(n.dataHoraInicio),
                            dataHoraFim: toLocalDateFormat(n.dataHoraFim)
                          })
                          navigator.clipboard.writeText(link)
                          toast.success(
                            'Link para o evento copiado com sucesso! 🎉',
                            {
                              autoClose: 4000
                            }
                          )
                        }}
                      >
                        <Tooltip title="Copiar link para evento">
                          <ShareIcon fontSize="small" />
                        </Tooltip>
                      </IconButton>
                      <AccessControl
                        rule={'eventos.create'}
                        yes={() => (
                          <Tooltip arrow title="Duplicar evento">
                            <IconButton
                              size="small"
                              className={classes.button}
                              aria-label="Botão para duplicar o evento"
                              onClick={() => handleClickDuplicate(n.id)}
                            >
                              <FileCopyRoundedIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      />
                      <AccessControl
                        rule={'eventos.edit'}
                        yes={() => (
                          <IconButton
                            size="small"
                            aria-label="Edit"
                            component="a"
                            onClick={() => handleClickEditEvento(n.id)}
                          >
                            <Tooltip title="Editar">
                              <EditIconRoundedIcon fontSize="small" />
                            </Tooltip>
                          </IconButton>
                        )}
                      />
                      <AccessControl
                        rule={'eventos.delete'}
                        yes={() => (
                          <IconButton
                            size="small"
                            aria-label="Delete"
                            onClick={() => handleClick(n.id)}
                          >
                            <Tooltip title="Excluir">
                              <DeleteIconRoundedIcon fontSize="small" />
                            </Tooltip>
                          </IconButton>
                        )}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

FutureEvents.propTypes = {
  className: PropTypes.string
}

export default FutureEvents
