const initialState = {
  anchor: 'left',
  hashtag: [],
  open: false,
  id: 0,
  descricao: '',
  imagem: null,
  banner: null,
  categoriaId: ''
}

export function hashtag(state = initialState, action) {
  switch (action.type) {
    case 'HASHTAG_FETCHED_ALL':
      return {
        ...state,
        hashtag: action.hashtag
      }
    case 'HASHTAG_DETAIL':
      return {
        ...state,
        id: action.id,
        descricao: action.descricao,
        imagem: action.imagem,
        banner: action.banner,
        categoriaId: action.categoriaId
      }
    case 'HASHTAG_UPDATED':
      return state
    case 'HASHTAG_CLEAR':
      return initialState
    case 'HASHTAG_CLEAR_ALL':
      return {
        ...state,
        hashtag: []
      }
    case 'HASHTAG_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    default:
      return state
  }
}
