import React, { Component } from 'react'
import { MdFileUpload, MdError, MdDone } from 'react-icons/md'
import Dropzone from 'react-dropzone'

import { DropContainer, UploadMessage } from './styles'

export default class Upload extends Component {
  renderDragMessage = (isDragActive, isDragReject) => {
    if (!isDragActive) {
      return (
        <UploadMessage>
          Clique ou arraste a imagem aqui...
          <MdFileUpload size={24} color="#999" />
        </UploadMessage>
      )
    }

    if (isDragReject) {
      return (
        <UploadMessage type="error">
          Arquivo não suportado!
          <MdError size={24} color="#e57878" />
        </UploadMessage>
      )
    }

    return (
      <UploadMessage type="success">
        Solte a imagem aqui!
        <MdDone size={24} color="#78e5d5" />
      </UploadMessage>
    )
  }

  render() {
    const { onUpload } = this.props

    return (
      <Dropzone accept="image/*" onDropAccepted={onUpload}>
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <DropContainer
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
          >
            <input {...getInputProps()} />
            {this.renderDragMessage(isDragActive, isDragReject)}
          </DropContainer>
        )}
      </Dropzone>
    )
  }
}
