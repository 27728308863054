import React, { Component, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { crudService } from '../../_services'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Grid
} from '@material-ui/core'
import { connect } from 'react-redux'
import { parametroAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import { HelperToolTip } from 'components'
import { Page } from 'components'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  }
})

class ParametroEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = (prop) => (event) => {
    const { dispatch } = this.props
    dispatch(parametroAction.onChangeProps(prop, event))
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props
    const { dispatch } = this.props
    if (params.id) {
      dispatch(parametroAction.getParametroById(params.id))
    } else {
      dispatch(parametroAction.clear())
    }
  }

  handleSubmit(event) {
    const {
      match: { params }
    } = this.props
    const { dispatch } = this.props

    let payload = {
      id: params.id,
      chave: this.props.parametro.chave,
      valor: this.props.parametro.valor,
      descricao: this.props.parametro.descricao
    }

    if (params.id) {
      dispatch(parametroAction.editParametroInfo(params.id, payload))
    } else {
      dispatch(parametroAction.createParametro(payload))
    }
  }

  render() {
    const { classes } = this.props
    const {
      match: { params }
    } = this.props

    function InsertText() {
      return (
        <Typography variant="h6">
          <b>{'Novo Parâmetro'}</b>
        </Typography>
      )
    }

    function EditText() {
      return (
        <Typography variant="h6">
          <b>{'Editar Parâmetro'}</b>
        </Typography>
      )
    }

    function SegHeader() {
      if (params.id) {
        return <EditText />
      }
      return <InsertText />
    }
    return (
      <Page
        className={classes.root}
        title={
          params.id
            ? 'Gerência de Parâmetros - Editar Parâmetros'
            : 'Gerência de Parâmetros - Novo Parâmetro'
        }
      >
        <Grid item md={12} xs={12}>
          <Card>
            <ValidatorForm
              className={classes.form}
              ref="formParametro"
              id="formParametro"
              onSubmit={(event) => this.handleSubmit(event)}
            >
              <CardHeader
                subheader="Cadastro de Parâmetros"
                title={<SegHeader />}
              />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextValidator
                      variant="outlined"
                      id="chave"
                      label="Chave *"
                      className={classes.textField}
                      value={this.props.parametro.chave}
                      onChange={this.handleChange('chave')}
                      placeholder="Chave do Parâmetro"
                      validators={['required']}
                      errorMessages={['Campo obrigatório!']}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextValidator
                      variant="outlined"
                      id="descricao"
                      label="Descrição *"
                      className={classes.textField}
                      value={this.props.parametro.descricao}
                      onChange={this.handleChange('descricao')}
                      placeholder="Descrição do Parâmetro"
                      validators={['required']}
                      errorMessages={['Campo obrigatório!']}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextValidator
                      variant="outlined"
                      id="valor"
                      label="Valor *"
                      className={classes.textField}
                      value={this.props.parametro.valor}
                      onChange={this.handleChange('valor')}
                      placeholder="Valor do Parâmetro"
                      validators={['required']}
                      errorMessages={['Campo obrigatório!']}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  onClick={() => history.push('/parametros')}
                  color="secondary"
                  variant="outlined"
                >
                  Cancelar
                </Button>
                <Button type="submit" color="primary" variant="outlined">
                  {params.id ? 'Atualizar' : 'Salvar'}
                </Button>
              </CardActions>
            </ValidatorForm>
          </Card>
        </Grid>
      </Page>
    )
  }
}
ParametroEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedParametroEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ParametroEdit))
)
export { connectedParametroEditPage as ParametroEdit }
