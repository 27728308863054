import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { RouteWithLayout } from './components'
import { PrivateRouteWithLayout } from './components'
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts'

import {
  Dashboard as DashboardView,
  Carteira as CarteiraView,
  Avaliacoes as AvaliacoesView,
  MovimentacoesFinanceiras as MovimentacoesFinanceirasView,
  OrderManagementList as OrderManagementListView,
  OrderManagementDetails as OrderManagementDetailsView,
  CancelamentosIngressos as CancelamentosIngressosView,
  OrderCancelDetails as OrderCancelDetailsView,
  EventoDashboard as EventoDashboardView,
  Settings as SettingsView,
  Error404 as Error404View,
  Login as LoginView,
  EmailVerification as EmailVerificationView,
  TipoIngresso as TipoIngressoView,
  TipoIngressoEdit as TipoIngressoEditView,
  EventoList as EventosView,
  EventoEdit as EventosEditView,
  EstabelecimentosList as EstabelecimentosListView,
  EstabelecimentoEdit as EstabelecimentoEditView,
  CustomerManagementDetails as CustomerManagementDetailsView,
  PasswordReset as PasswordResetView,
  CuponsDesconto as CupomDescontoView,
  // CupomDescontoEdit as CupomDescontoEditView,
  PhotoAlbum as PhotoAlbumView,
  Members as MembersView,
  Categoria as CategoriaView,
  CategoriaEdit as CategoriaEditView,
  Tickets as TicketsView,
  Hashtag as HashtagView,
  HashtagEdit as HashtagEditView,
  Conquista as ConquistaView,
  ConquistaEdit as ConquistaEditView,
  Taxa as TaxaView,
  TaxaEdit as TaxaEditView,
  Cadastro as CadastroView,
  Permissao as PermissaoView,
  // PermissaoTipoUsuario as PermissaoTipoUsuarioView,
  Parametro as ParametroView,
  ParametroEdit as ParametroEditView,
  Home as HomeView,
  Ajuda as AjudaView,
  DashboardAdmin as DashboardAdminView,
  CustomerManagementList as CustomerManagementListView,
  MemberPlanDetails as MemberPlanDetailsView
} from './views'

const Routes = () => {
  return (
    <Switch>
      <PrivateRouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <PrivateRouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard/:eventoId"
      />
      <PrivateRouteWithLayout
        component={CarteiraView}
        exact
        layout={MainLayout}
        path="/carteira"
      />
      <PrivateRouteWithLayout
        component={CarteiraView}
        exact
        layout={MainLayout}
        path="/carteira/:tab"
      />
      <PrivateRouteWithLayout
        component={CarteiraView}
        exact
        layout={MainLayout}
        path="/carteira/:tab/cadastro"
      />
      <PrivateRouteWithLayout
        component={CarteiraView}
        exact
        layout={MainLayout}
        path="/carteira/:tab/pix"
      />
      <PrivateRouteWithLayout
        component={CarteiraView}
        exact
        layout={MainLayout}
        path="/carteira/:tab/cadastro/:idContaBancaria"
      />
      <PrivateRouteWithLayout
        component={CarteiraView}
        exact
        layout={MainLayout}
        path="/carteira/:tab/pix/:idContaBancaria"
      />
      <PrivateRouteWithLayout
        component={HomeView}
        exact
        layout={MainLayout}
        path="/home"
      />
      <PrivateRouteWithLayout
        component={EstabelecimentosListView}
        exact
        layout={MainLayout}
        path="/estabelecimentos"
      />
      <PrivateRouteWithLayout
        component={CustomerManagementListView}
        exact
        layout={MainLayout}
        path="/usuarios"
      />
      <PrivateRouteWithLayout
        component={EstabelecimentoEditView}
        exact
        layout={MainLayout}
        path="/estabelecimento"
      />
      {/* <PrivateRouteWithLayout
        component={EstabelecimentoEditView}
        exact
        layout={MainLayout}
        path="/estabelecimento/:id"
      /> */}
      <PrivateRouteWithLayout
        component={EstabelecimentoEditView}
        exact
        layout={MainLayout}
        path="/estabelecimento/:tab"
      />
      <PrivateRouteWithLayout
        component={CustomerManagementDetailsView}
        exact
        layout={MainLayout}
        path="/usuario/:userId"
      />
      <PrivateRouteWithLayout
        component={CustomerManagementDetailsView}
        exact
        layout={MainLayout}
        path="/usuario/:userId/:tab/"
      />
      <PrivateRouteWithLayout
        component={MovimentacoesFinanceirasView}
        exact
        layout={MainLayout}
        path="/movimentacoesFinanceiras"
      />
      <PrivateRouteWithLayout
        component={CancelamentosIngressosView}
        exact
        layout={MainLayout}
        path="/cancelamentosIngressos"
      />
      <PrivateRouteWithLayout
        component={OrderCancelDetailsView}
        exact
        layout={MainLayout}
        path="/cancelamentosIngressos/info/:id"
      />
      <PrivateRouteWithLayout
        component={MovimentacoesFinanceirasView}
        exact
        layout={MainLayout}
        path="/movimentacaoFinanceira/:id/:tab"
      />
      <PrivateRouteWithLayout
        component={OrderManagementListView}
        exact
        layout={MainLayout}
        path="/pedidos/admin"
      />
      <PrivateRouteWithLayout
        component={OrderManagementDetailsView}
        exact
        layout={MainLayout}
        path="/pedido/:pedidoId"
      />
      <PrivateRouteWithLayout
        component={TicketsView}
        exact
        layout={MainLayout}
        path="/ingressos/lista/:eventoId"
      />
      <PrivateRouteWithLayout
        component={AvaliacoesView}
        exact
        layout={MainLayout}
        path="/avaliacoes"
      />
      <PrivateRouteWithLayout
        component={AvaliacoesView}
        exact
        layout={MainLayout}
        path="/avaliacoes/:tab"
      />
      <PrivateRouteWithLayout
        component={AvaliacoesView}
        exact
        layout={MainLayout}
        path="/avaliacoes/:tab/:eventoId"
      />
      <PrivateRouteWithLayout
        component={CupomDescontoView}
        exact
        layout={MainLayout}
        path="/cupomDescontos/:tab"
      />
      <PrivateRouteWithLayout
        component={CupomDescontoView}
        exact
        layout={MainLayout}
        path="/cupomDescontos"
      />
      <PrivateRouteWithLayout
        component={CupomDescontoView}
        exact
        layout={MainLayout}
        path="/cupomDescontos/:tab/evento/:eventoId"
      />
      <PrivateRouteWithLayout
        component={CupomDescontoView}
        exact
        layout={MainLayout}
        path="/cupomDesconto/:tab/:idCupomDesconto"
      />
      <PrivateRouteWithLayout
        component={CupomDescontoView}
        exact
        layout={MainLayout}
        path="/cupomDesconto/lista/:eventoId"
      />
      <PrivateRouteWithLayout
        component={PhotoAlbumView}
        exact
        layout={MainLayout}
        path="/photoAlbum/:tab"
      />
      <PrivateRouteWithLayout
        component={PhotoAlbumView}
        exact
        layout={MainLayout}
        path="/photoAlbum"
      />
      <PrivateRouteWithLayout
        component={PhotoAlbumView}
        exact
        layout={MainLayout}
        path="/photoAlbum/:tab/evento/:eventoId"
      />
      <PrivateRouteWithLayout
        component={PhotoAlbumView}
        exact
        layout={MainLayout}
        path="/photoAlbum/:tab/:photoAlbumId"
      />
      <PrivateRouteWithLayout
        component={MemberPlanDetailsView}
        exact
        layout={MainLayout}
        path="/members/memberPlanDetails/:id/:tab"
      />
      <PrivateRouteWithLayout
        component={MemberPlanDetailsView}
        exact
        layout={MainLayout}
        path="/members/memberPlanDetails/:id"
      />
      <PrivateRouteWithLayout
        component={MembersView}
        exact
        layout={MainLayout}
        path="/members/:tab"
      />
      <PrivateRouteWithLayout
        component={MembersView}
        exact
        layout={MainLayout}
        path="/members/:tab/:membershipPlanId"
      />
      <PrivateRouteWithLayout
        component={MembersView}
        exact
        layout={MainLayout}
        path="/members"
      />
      <PrivateRouteWithLayout
        component={TipoIngressoView}
        exact
        layout={MainLayout}
        path="/tipoIngressos"
      />
      <PrivateRouteWithLayout
        component={TipoIngressoEditView}
        exact
        layout={MainLayout}
        path="/tipoIngresso"
      />
      <PrivateRouteWithLayout
        component={TipoIngressoEditView}
        exact
        layout={MainLayout}
        path="/tipoIngresso/:id"
      />
      <PrivateRouteWithLayout
        component={CategoriaView}
        exact
        layout={MainLayout}
        path="/categorias"
      />
      <PrivateRouteWithLayout
        component={CategoriaEditView}
        exact
        layout={MainLayout}
        path="/categoria"
      />
      <PrivateRouteWithLayout
        component={CategoriaEditView}
        exact
        layout={MainLayout}
        path="/categoria/:id"
      />
      <PrivateRouteWithLayout
        component={TicketsView}
        exact
        layout={MainLayout}
        path="/ingressos/:tab"
      />
      <PrivateRouteWithLayout
        component={TicketsView}
        exact
        layout={MainLayout}
        path="/ingressos"
      />
      <PrivateRouteWithLayout
        component={HashtagView}
        exact
        layout={MainLayout}
        path="/hashtags"
      />
      <PrivateRouteWithLayout
        component={HashtagEditView}
        exact
        layout={MainLayout}
        path="/hashtag"
      />
      <PrivateRouteWithLayout
        component={HashtagEditView}
        exact
        layout={MainLayout}
        path="/hashtag/:id"
      />
      <PrivateRouteWithLayout
        component={ConquistaView}
        exact
        layout={MainLayout}
        path="/conquistas"
      />
      <PrivateRouteWithLayout
        component={ConquistaEditView}
        exact
        layout={MainLayout}
        path="/conquista"
      />
      <PrivateRouteWithLayout
        component={ConquistaEditView}
        exact
        layout={MainLayout}
        path="/conquista/:id"
      />
      <PrivateRouteWithLayout
        component={TaxaView}
        exact
        layout={MainLayout}
        path="/taxas"
      />
      <PrivateRouteWithLayout
        component={TaxaEditView}
        exact
        layout={MainLayout}
        path="/taxa"
      />
      <PrivateRouteWithLayout
        component={TaxaEditView}
        exact
        layout={MainLayout}
        path="/taxa/:id"
      />

      <PrivateRouteWithLayout
        component={PermissaoView}
        exact
        layout={MainLayout}
        path="/permissoes/"
      />

      <PrivateRouteWithLayout
        component={PermissaoView}
        exact
        layout={MainLayout}
        path="/permissoes/:tab"
      />

      <PrivateRouteWithLayout
        component={ParametroView}
        exact
        layout={MainLayout}
        path="/parametros"
      />
      <PrivateRouteWithLayout
        component={ParametroEditView}
        exact
        layout={MainLayout}
        path="/parametro"
      />
      <PrivateRouteWithLayout
        component={ParametroEditView}
        exact
        layout={MainLayout}
        path="/parametros/:id"
      />

      <PrivateRouteWithLayout
        component={EventosView}
        exact
        layout={MainLayout}
        path="/eventos"
      />
      <PrivateRouteWithLayout
        component={EventosView}
        exact
        layout={MainLayout}
        path="/eventos/:tab"
      />
      <PrivateRouteWithLayout
        component={EventosEditView}
        exact
        layout={MainLayout}
        path="/evento"
      />
      <PrivateRouteWithLayout
        component={EventosEditView}
        exact
        layout={MainLayout}
        path="/evento/:id"
      />
      <PrivateRouteWithLayout
        component={EventoDashboardView}
        exact
        layout={MainLayout}
        path="/evento/dashboard/:eventoId"
      />
      <Route component={LoginView} exact path="/login" />
      <Route
        component={EmailVerificationView}
        exact
        path="/email-verification"
      />
      <PrivateRouteWithLayout
        component={HomeView}
        exact
        layout={MainLayout}
        path="/"
      />
      <PrivateRouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
        f
      />
      <PrivateRouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings/:tab"
      />
      <PrivateRouteWithLayout
        component={AjudaView}
        exact
        layout={MainLayout}
        path="/ajuda"
      />
      <RouteWithLayout
        component={Error404View}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={CadastroView}
        exact
        layout={MinimalLayout}
        path="/cadastro"
      />
      <Route
        component={PasswordResetView}
        layout={MinimalLayout}
        exact
        path="/password-reset"
      />
      <PrivateRouteWithLayout
        component={DashboardAdminView}
        exact
        layout={MainLayout}
        path="/dashboardAdministrativo"
      />
      <Redirect to="/not-found" />
    </Switch>
  )
}

export default Routes
