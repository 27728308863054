import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useLocation } from 'react-router-dom'

//? Own imports
import { SkeletonTable } from 'components'
import { SelectFilters, TableIngressos } from './components'
import { AccessControl } from 'components/Utils/AccessControl'
import { useTickets } from 'hooks-querys'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  }
}))

function useSearchQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const TicketsList = () => {
  const querySearch = useSearchQuery()
  const classes = useStyles()
  const [selectFilters, setSelectFilters] = useState({
    eventId: querySearch.get('eventId') || 0,
    textSearch: querySearch.get('textSearch') || '',
    statusId: querySearch.get('statusId') || 0
  })
  const { data, isLoading } = useTickets(
    Number(selectFilters.eventId),
    selectFilters.textSearch,
    Number(selectFilters.statusId)
  )

  useEffect(() => {
    setSelectFilters({
      eventId: querySearch.get('eventId') || 0,
      textSearch: querySearch.get('textSearch') || '',
      statusId: querySearch.get('statusId') || 0
    })
  }, [querySearch])

  return (
    <AccessControl
      rule={'ingressos.list'} //permissão necessária para acessar conteúdo
      // @ts-ignore
      yes={() => (
        <div className={classes.root}>
          <SelectFilters />
          {isLoading ? (
            <SkeletonTable />
          ) : (
            data && <TableIngressos tickets={data} />
          )}
        </div>
      )}
    />
  )
}

export default TicketsList
