import React from 'react'
import {
  Grid,
  Tooltip,
  Typography,
  IconButton,
  makeStyles
} from '@material-ui/core'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import CachedRoundedIcon from '@material-ui/icons/CachedRounded'
import clsx from 'clsx'

//? Own imports
import { history } from 'utils'

type Props = {
  className?: string
  title: string
  subtitle?: string
  onBack?: () => void
  onRefresh?: () => void
  isRefreshing?: boolean
}
const useStyles = makeStyles(() => ({
  root: {}
}))

const Header = ({
  className,
  title,
  subtitle,
  onBack,
  onRefresh,
  isRefreshing
}: Props) => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.root, className)}>
      <Grid
        container
        spacing={1}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        alignContent="stretch"
        wrap="nowrap"
      >
        <Grid item>
          <Tooltip title="Voltar">
            <IconButton
              aria-label="Voltar"
              onClick={() => onBack || history.goBack()}
            >
              <ArrowBackRoundedIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            {subtitle}
          </Typography>
          <Typography component="h1" variant="h3">
            {title}
          </Typography>
        </Grid>
        {onRefresh && (
          <Grid item>
            <Tooltip title="Atualizar">
              <IconButton disabled={isRefreshing} onClick={onRefresh}>
                <CachedRoundedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default Header
