import { useQuery } from 'react-query'

//? Own imports
import { crudService } from '_services'
import { UserItem } from '../types/api'

const getUserSearch = async (text: string) => {
  const { data } = await crudService.get(`usuarios/search/${text}`)
  return data
}

export default function useUserSearch(text: string) {
  return useQuery<UserItem[]>(
    ['user-search', text],
    () => getUserSearch(text),
    {
      enabled: !!text && text.length > 2
    }
  )
}
