import { crudService } from '../_services/'

export const avaliacaoAction = {
  getAvaliacaoByEstabelecimento,
  searchAvaliacaoByEventId,
  clearAll,
  setAnswerRatingOpen,
  setDetailOpen,
  likeRating
}

function getAvaliacaoByEstabelecimento(estabelecimentoId) {
  return (dispatch) => {
    let apiEndpoint = `avaliacoes/estabelecimento/${estabelecimentoId}`
    dispatch(request())
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request() {
    return { type: 'REQUEST_ALL_AVALIACOES_REQUEST' }
  }

  function success(data) {
    return {
      type: 'REQUEST_ALL_AVALIACOES_SUCCESS',
      avaliacoes: data.avaliacoes
    }
  }

  function failure(error) {
    return { type: 'REQUEST_ALL_AVALIACOES_FAILURE', error }
  }
}

function searchAvaliacaoByEventId(payload, eventoId) {
  let apiEndpoint = ''
  return (dispatch) => {
    dispatch(request(payload))
    if (payload.SearchText === '') {
      apiEndpoint = `avaliacoes/evento/${payload.EventoId}`
    } else {
      apiEndpoint = `avaliacoes/evento/search/${payload.EventoId}/${payload.SearchText}`
    }
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data, eventoId))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request(payload) {
    return {
      type: 'SEARCH_AVALIACOES_REQUEST',
      payload
    }
  }

  function success(data, eventoId) {
    return {
      type: 'SEARCH_AVALIACOES_SUCCESS',
      avaliacoes: data.avaliacoes,
      eventoId: eventoId
    }
  }

  function failure(error) {
    return { type: 'SEARCH_AVALIACOES_FAILURE', error }
  }
}

function clearAll() {
  return {
    type: 'AVALIACAO_CLEAR_ALL'
  }
}

function setAnswerRatingOpen(answerOpen, avaliacao) {
  if (avaliacao === null) {
    return {
      type: 'AVALIACAO_ANSWER_OPEN',
      answerOpen: answerOpen,
      usuarioId: 0,
      mensagem: '',
      id: 0
    }
  } else {
    return {
      type: 'AVALIACAO_ANSWER_OPEN',
      answerOpen: answerOpen,
      usuarioId: avaliacao.usuarioId,
      mensagem: avaliacao.mensagem,
      id: avaliacao.id
    }
  }
}

function setDetailOpen(detailOpen, avaliacao) {
  if (avaliacao === null) {
    return {
      type: 'AVALIACAO_DETAIL_OPEN',
      detailOpen: detailOpen,
      usuarioId: 0,
      mensagem: '',
      id: 0,
      nota: 0
    }
  } else {
    return {
      type: 'AVALIACAO_DETAIL_OPEN',
      detailOpen: detailOpen,
      usuarioId: avaliacao.usuarioId,
      mensagem: avaliacao.mensagem,
      id: avaliacao.id,
      nota: avaliacao.nota
    }
  }
}

function likeRating(avaliacaoId) {
  return (dispatch) => {
    dispatch(request())
    let apiEndpoint = `interacoes/curtida/avaliacao/${avaliacaoId}`
    crudService
      .post(apiEndpoint)
      .then(() => {
        dispatch(success())
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return {
      type: 'LIKE_AVALIACOES_REQUEST',
      ratingId: avaliacaoId
    }
  }

  function success() {
    return {
      type: 'LIKE_AVALIACOES_SUCCESS'
    }
  }

  function failure(error) {
    return { type: 'LIKE_AVALIACOES_FAILURE', error }
  }
}
