const initialState = {
  loading: false,
  isSearchingCep: false,
  estabelecimento: [],
  open: false,
  id: 0,
  nome: '',
  detalhes: '',
  cidadeId: '',
  estadoId: '',
  endereco: '',
  repassarTaxa: false,
  latLon: '',
  capacidade: '',
  cidade: null,
  imagem: null,
  banner: null,
  excluido: false,
  bairro: '',
  cep: '',
  numero: '',
  username: '',
  cpfCnpj: '',
  categoriaId: '',
  complemento: '',
  estabelecimentoHashtag: []
}

export function estabelecimento(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_ESTABELECIMENTO_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'CREATE_ESTABELECIMENTO_SUCCESS':
      return action.estabelecimento
    case 'CREATE_ESTABELECIMENTO_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case 'EDIT_ESTABELECIMENTO_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'EDIT_ESTABELECIMENTO_SUCCESS':
      return {
        ...state,
        loading: false
      }
    case 'EDIT_ESTABELECIMENTO_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case 'SET_UPLOAD_URL':
      return {
        ...state,
        imagem: action.value
      }
    case 'FETCHED_ALL_ESTABELECIMENTO':
      return {
        ...state,
        estabelecimento: action.estabelecimento
      }
    case 'ESTABELECIMENTO_DETAIL':
      return action.estabelecimento
    case 'SEARCH_ENDERECO_BY_CEP_REQUEST':
      return {
        ...state,
        isSearchingCep: true
      }
    case 'SEARCH_ENDERECO_BY_CEP_SUCCESS':
      return {
        ...state,
        isSearchingCep: false,
        endereco: action.endereco,
        complemento: action.complemento,
        bairro: action.bairro,
        ibge: action.ibge
      }
    case 'SEARCH_ENDERECO_BY_CEP_FAILURE':
      return {
        ...state,
        isSearchingCep: false,
        error: action.error
      }
    case 'ESTABELECIMENTO_ESTADO_DETAIL':
      return {
        ...state,
        estadoId: action.estadoId
      }
    case 'SEARCH_CIDADE_BY_IBGE_REQUEST':
      return {
        ...state,
        isSearchingCep: true
      }
    case 'SEARCH_CIDADE_BY_IBGE_SUCCESS':
      return {
        ...state,
        isSearchingCep: false,
        estadoId: action.estadoId,
        cidadeId: action.cidadeId,
        paisId: action.paisId
      }
    case 'SEARCH_CIDADE_BY_IBGE_FAILURE':
      return {
        ...state,
        isSearchingCep: false,
        error: action.error
      }
    case 'ESTABELECIMENTO_CHANGE_COORDINATES':
      return {
        ...state,
        latLon: action.latLon
      }
    case 'ESTABELECIMENTO_UPDATED':
      return state
    case 'ESTABELECIMENTO_CLEAR':
      return initialState
    case 'ESTABELECIMENTO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'ESTABELECIMENTO_HASHTAG_CHANGE':
      return {
        ...state,
        estabelecimentoHashtag: action.hashtags
      }
    default:
      return state
  }
}
