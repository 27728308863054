import React, { useEffect } from 'react'
import { loteAction, eventoAction, tipoIngressoAction } from '_actions'
import MenuItem from '@material-ui/core/MenuItem'
import { toISODateFormat, validateDatesLote } from 'utils'
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  FormControlLabel,
  makeStyles,
  InputAdornment,
  Chip,
  Switch,
  Tooltip,
  Typography
} from '@material-ui/core'
import { uniqueId } from 'lodash'
import { HelperToolTip, ValidatedDatePicker } from 'components'
import { toast } from 'react-toastify'
import { isValid } from 'date-fns'
import { ModalAddTipoDeIngresso } from './components'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import constants from 'theme/constants'
import { colors } from 'material-ui/styles'

const LOTE_DESCRICAO_MAX_LENGTH = 50

const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 0, 0, 0)
  },
  contentRoot: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  paper: {
    ...constants.shadowCard
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1)
  },
  margin: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  input: {
    display: 'flex'
  }
}))
const LoteEdit = () => {
  const { lote, usuarioEstabelecimento } = useSelector((state) => state)
  const { tipoIngresso } = useSelector((state) => state.tipoIngresso)
  const repassarTaxaEstabelecimento = useSelector(
    (state) => state.estabelecimento.repassarTaxa
  )
  const dispatch = useDispatch()
  const classes = useStyles()
  let { id } = useParams()

  const handleChange = (prop) => (event) => {
    if (prop === 'descricao') {
      if (event?.target?.value?.length <= LOTE_DESCRICAO_MAX_LENGTH) {
        dispatch(loteAction.onChangeProps(prop, event))
      }
    } else {
      dispatch(loteAction.onChangeProps(prop, event))
    }
  }
  const handleSwitchValor = (event) => {
    dispatch(loteAction.onSwitchValor(event))
  }
  const setRepassarTaxaDefault = (repassarTaxaEstabecimento) => {
    dispatch(
      loteAction.setRepassarTaxaByEstabelecimento(repassarTaxaEstabecimento)
    )
  }

  const handleChangeData = (prop, newValue) => {
    dispatch(loteAction.onChangeData(prop, newValue))
  }
  const handleClickOpenModalAddTipoDeIngresso = () => {
    dispatch(loteAction.openModalTipoDeIngresso(true))
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    ValidatorForm.addValidationRule('isValidDate', (value) => {
      if (value !== undefined && value !== null) {
        return isValid(new Date(toISODateFormat(value)))
      } else {
        return true
      }
    })
  }, [])

  useEffect(() => {
    return () => {
      ValidatorForm.removeValidationRule('isValidDate')
    }
  }, [])

  useEffect(() => {
    dispatch(
      tipoIngressoAction.getTipoIngressoByEstabelecimento(
        usuarioEstabelecimento.estabelecimentoId
      )
    )
  }, [])

  useEffect(() => {
    if (!lote.id) {
      setRepassarTaxaDefault(repassarTaxaEstabelecimento)
    }
  }, [lote.id, repassarTaxaEstabelecimento])

  const clear = () => {
    dispatch(loteAction.clear(repassarTaxaEstabelecimento))
  }

  const add = () => {
    if (
      !validateDatesLote(lote.dataHoraInicio, lote.dataHoraTermino, lote.id)
    ) {
      toast.error('Opss! 😥 Datas incorretas, revise por favor!')
    } else {
      let payload = {
        id: lote.id,
        descricao: lote.descricao,
        tipoIngressoId: lote.tipoIngressoId,
        eventoId: lote.eventoId,
        valor: parseFloat(lote.valor),
        quantidade: parseInt(lote.quantidade),
        limiteQuantidadePedido: parseInt(lote.limiteQuantidadePedido),
        genero: lote.genero,
        dataHoraInicio: toISODateFormat(lote.dataHoraInicio),
        dataHoraTermino: toISODateFormat(lote.dataHoraTermino),
        disponivel: lote.disponivel,
        excluido: lote.excluido,
        repassarTaxa: lote.repassarTaxa
      }
      if (id) {
        if (payload.id) {
          dispatch(loteAction.editLoteInfo(payload.id, payload))
        } else {
          payload.eventoId = id
          dispatch(loteAction.createLote(payload))
        }
      } else {
        if (payload.id) {
          dispatch(eventoAction.editLoteLocale(payload.id, payload))
        } else {
          payload.id = uniqueId()
          dispatch(eventoAction.addLoteLocale(payload))
        }
      }
    }
  }

  return (
    <div className={classes.root}>
      <ModalAddTipoDeIngresso />
      <Grid item md={12} xs={12}>
        <Card className={classes.paper}>
          <ValidatorForm onSubmit={() => add()}>
            <CardHeader
              subheader="Adicione os lotes de ingressos para seu evento "
              title={'3 - Dados sobre os lotes'}
              titleTypographyProps={{ variant: 'h4' }}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <TextValidator
                    variant="outlined"
                    id="descricao"
                    label="Descrição *"
                    className={classes.textField}
                    value={lote.descricao}
                    placeholder="Lote 1, Lote único, Lote promocional, etc."
                    onChange={handleChange('descricao')}
                    fullWidth
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    InputProps={{
                      endAdornment: (
                        <>
                          <InputAdornment position="end">
                            <Typography
                              variant="body2"
                              align="right"
                              color={
                                lote?.descricao?.length >=
                                LOTE_DESCRICAO_MAX_LENGTH
                                  ? 'error'
                                  : undefined
                              }
                            >
                              {lote?.descricao?.length}/
                              {LOTE_DESCRICAO_MAX_LENGTH}
                            </Typography>
                          </InputAdornment>
                        </>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <SelectValidator
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    variant="outlined"
                    id="tipoIngressoId"
                    label="Tipo Ingresso *"
                    className={classes.textField}
                    value={lote.tipoIngressoId}
                    onChange={handleChange('tipoIngressoId')}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    displayEmpty
                    labelId="tipoIngressoId"
                  >
                    <MenuItem disabled value="">
                      <em>Tipo de Ingresso *</em>
                    </MenuItem>
                    {undefined !== tipoIngresso && tipoIngresso.length > 0 ? (
                      tipoIngresso.map((row) => (
                        <MenuItem key={row.id} value={row.id}>
                          {row.descricao}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled value={uniqueId()}>
                        Nenhum resultado encontrado
                      </MenuItem>
                    )}
                    <Button
                      fullWidth
                      startIcon={<AddCircleTwoToneIcon />}
                      className={classes.buttonAddTipoDeIngresso}
                      variant="text"
                      color="primary"
                      onClick={() => handleClickOpenModalAddTipoDeIngresso()}
                    >
                      Cadastrar novo
                    </Button>
                  </SelectValidator>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <SelectValidator
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    variant="outlined"
                    id="Sexo"
                    label="Sexo *"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={lote.genero}
                    onChange={handleChange('genero')}
                    fullWidth
                  >
                    <MenuItem disabled value="">
                      <em>Sexo</em>
                    </MenuItem>
                    <MenuItem value="M">Masculino</MenuItem>
                    <MenuItem value="F">Feminino</MenuItem>
                    <MenuItem value="U">Unissex</MenuItem>
                  </SelectValidator>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <TextValidator
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <>
                            <Switch
                              color="primary"
                              onChange={handleSwitchValor}
                              checked={lote.valor === 0 ? true : false}
                              size="small"
                              inputProps={{
                                'aria-label': 'criar ingresso gratuito'
                              }}
                            />
                            <Chip
                              variant="default"
                              size="small"
                              label="Gratuito"
                              color={lote.valor === 0 ? 'primary' : 'default'}
                            />
                          </>
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                    id="valor"
                    disabled={lote.valor === 0 ? true : false}
                    label="Valor em R$ *"
                    className={classes.textField}
                    value={lote.valor === 0 ? 'Gratuito' : lote.valor}
                    onChange={handleChange('valor')}
                    fullWidth
                    type={lote.valor === 0 ? 'text' : 'number'}
                    inputProps={{
                      min: '0',
                      step: '0.01'
                    }}
                    lines="5"
                    validators={
                      lote.valor === 0 ? [] : ['required', 'minNumber:3']
                    }
                    errorMessages={[
                      'Campo obrigatório!',
                      'O ticket deve ter um valor superior a R$ 3,00!'
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <TextValidator
                    variant="outlined"
                    id="quantidade"
                    label="Quantidade *"
                    inputProps={{
                      min: '1'
                    }}
                    className={classes.textField}
                    value={lote.quantidade}
                    onChange={handleChange('quantidade')}
                    fullWidth
                    type="number"
                    lines="5"
                    validators={['required', 'minNumber:1']}
                    errorMessages={[
                      'Campo obrigatório!',
                      'Quantidade mínima é 1'
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <TextValidator
                    variant="outlined"
                    id="limiteQuantidade"
                    label="Limite por Pedido"
                    la
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip
                            title={`Função nova! Nessa função você pode limitar a quantidade de ingressos que um usuário pode comprar para determinado lote.`}
                          >
                            <Chip
                              size="small"
                              variant="default"
                              label="NOVIDADE!"
                              color="primary"
                            />
                          </Tooltip>
                          <HelperToolTip>
                            Quantidade máxima de ingressos que o usuário poderá
                            comprar por pedido. Caso não definido, o limite é de
                            10 ingressos.
                          </HelperToolTip>
                        </InputAdornment>
                      )
                    }}
                    inputProps={{
                      min: '1'
                    }}
                    className={classes.textField}
                    value={lote.limiteQuantidadePedido}
                    onChange={handleChange('limiteQuantidadePedido')}
                    fullWidth
                    type="number"
                    lines="5"
                    validators={
                      parseInt(lote.limiteQuantidadePedido) >
                      parseInt(lote.quantidade)
                        ? ['maxNumber']
                        : []
                    }
                    errorMessages={[
                      'Quantidade máxima maior que quantidade disponibilizada'
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <ValidatedDatePicker
                    validators={['isValidDate']}
                    errorMessages={['Data inválida!']}
                    autoOk
                    id="dataHoraInicio"
                    label="Início das Vendas"
                    fullWidth
                    inputVariant="outlined"
                    ampm={false}
                    value={lote.dataHoraInicio}
                    inputValue={lote.dataHoraInicio}
                    onChange={(_, newValue) =>
                      handleChangeData('dataHoraInicio', newValue)
                    }
                    //Comentado para aceitar datas passadas, facilitar input..
                    //disablePast={lote.id !== 0 ? false : true}
                    format="dd/MM/yyyy HH:mm"
                    invalidDateMessage="Data inválida"
                    invalidLabel="dd/MM/aaaa HH:mm"
                    maxDateMessage="A data não deve ser posterior à data máxima"
                    minDateMessage="A data não deve ser anterior à data mínima"
                    cancelLabel="Cancelar"
                    okLabel="Definir"
                    clearable
                    clearLabel="Limpar"
                    placeholder="dd/MM/aaaa HH:mm"
                    todayLabel="Hoje"
                    defaultValue={undefined}
                    openTo="month"
                    views={['month', 'date', 'hours', 'minutes']}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <ValidatedDatePicker
                    validators={['isValidDate']}
                    errorMessages={['Data inválida!']}
                    autoOk
                    id="dataHoraTermino"
                    label="Término das Vendas"
                    fullWidth
                    inputVariant="outlined"
                    ampm={false}
                    value={lote.dataHoraTermino}
                    inputValue={lote.dataHoraTermino}
                    onChange={(_, newValue) =>
                      handleChangeData('dataHoraTermino', newValue)
                    }
                    disablePast
                    minDate={toISODateFormat(lote.dataHoraInicio)}
                    initialFocusedDate={toISODateFormat(lote.dataHoraInicio)}
                    format="dd/MM/yyyy HH:mm"
                    invalidDateMessage="Data inválida"
                    invalidLabel="dd/MM/aaaa HH:mm"
                    maxDateMessage="A data não deve ser posterior à data máxima"
                    minDateMessage={
                      lote.dataHoraInicio != null
                        ? 'A data não deve ser anterior à data mínima'
                        : 'A data não deve estar no passado'
                    }
                    cancelLabel="Cancelar"
                    okLabel="Definir"
                    clearable
                    clearLabel="Limpar"
                    placeholder="dd/MM/aaaa HH:mm"
                    todayLabel="Hoje"
                    defaultValue={undefined}
                    openTo="month"
                    views={['month', 'date', 'hours', 'minutes']}
                  />
                </Grid>{' '}
              </Grid>
              <Grid style={{ paddingTop: 15 }} container>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={lote.disponivel}
                        onChange={handleChange('disponivel')}
                        color="primary"
                      />
                    }
                    label="Disponibilizar Lote"
                  />
                </Grid>
                {lote.valor !== 0 && (
                  <Grid item sm={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={lote.repassarTaxa}
                            onChange={handleChange('repassarTaxa')}
                            color="primary"
                          />
                        }
                        label={'Repassar taxa para cliente'}
                      />
                      <HelperToolTip>
                        Se ativado, a taxa de serviço será repassada para o
                        cliente.
                      </HelperToolTip>
                    </div>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <CardActions>
              <Grid container item justify="flex-end">
                <Button
                  className={classes.backButton}
                  variant="outlined"
                  color="secondary"
                  onClick={() => clear()}
                >
                  Limpar
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  {lote.id ? 'Atualizar' : 'Adicionar'}
                </Button>
              </Grid>
            </CardActions>
          </ValidatorForm>
        </Card>
      </Grid>
    </div>
  )
}

export default LoteEdit
