import React from 'react'
import { Pie } from 'react-chartjs-2'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Grid,
  makeStyles,
  useTheme
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import { getMonthByNumber } from 'utils'
import { colors } from '@material-ui/core'
import constants from 'theme/constants'
import { useEstablishmentDashboard } from 'hooks-querys'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    ...constants.shadowCard
  },
  chartContainer: {
    position: 'relative',
    height: '300px',
    justifyContent: 'center'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  meses: {
    textAlign: 'center'
  }
}))

const ReceitaUltimos6Meses = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { estabelecimentoId } = useSelector(
    // @ts-ignore
    (state) => state.usuarioEstabelecimento
  )
  const { data, isFetching } = useEstablishmentDashboard(
    Number(estabelecimentoId)
  )

  // @ts-ignore
  const { showValues } = useSelector((state) => state.global)
  const { graficoIngressosMes } = data || {}

  const generateData = () => {
    if (graficoIngressosMes && graficoIngressosMes.options) {
      const monthWithName = [] as any
      graficoIngressosMes.options.map((v) =>
        monthWithName.push(getMonthByNumber(v))
      )
      const data = {
        datasets: [
          {
            data: graficoIngressosMes.series[0],
            backgroundColor: [
              colors.deepPurple[200],
              colors.teal[200],
              colors.lightGreen[200],
              colors.brown[200],
              colors.blueGrey[200],
              colors.lightBlue[200]
            ],
            borderWidth: 3,
            borderColor: theme.palette.white,
            hoverBorderColor: [
              colors.deepPurple[400],
              colors.teal[400],
              colors.lightGreen[400],
              colors.brown[400],
              colors.blueGrey[400],
              colors.lightBlue[400]
            ]
          }
        ],
        labels: monthWithName
      }
      return data
    } else {
      return []
    }
  }

  const generateStrings = () => {
    var data = [] as any
    if (graficoIngressosMes && graficoIngressosMes.options) {
      data = [
        {
          title: getMonthByNumber(graficoIngressosMes.options[0]),
          value:
            graficoIngressosMes.series[0][0] !== undefined
              ? Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(graficoIngressosMes.series[0][0])
              : null,
          color: colors.deepPurple[200]
        },
        {
          title: getMonthByNumber(graficoIngressosMes.options[1]),
          value:
            graficoIngressosMes.series[0][1] !== undefined
              ? Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(graficoIngressosMes.series[0][1])
              : null,
          color: colors.teal[200]
        },
        {
          title: getMonthByNumber(graficoIngressosMes.options[2]),
          value:
            graficoIngressosMes.series[0][2] !== undefined
              ? Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(graficoIngressosMes.series[0][2])
              : null,
          color: colors.lightGreen[200]
        },
        {
          title: getMonthByNumber(graficoIngressosMes.options[3]),
          value:
            graficoIngressosMes.series[0][3] !== undefined
              ? Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(graficoIngressosMes.series[0][3])
              : null,
          color: colors.brown[200]
        },
        {
          title: getMonthByNumber(graficoIngressosMes.options[4]),
          value:
            graficoIngressosMes.series[0][4] !== undefined
              ? Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(graficoIngressosMes.series[0][4])
              : null,
          color: colors.blueGrey[400]
        },
        {
          title: getMonthByNumber(graficoIngressosMes.options[5]),
          value:
            graficoIngressosMes.series[0][5] !== undefined
              ? Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(graficoIngressosMes.series[0][5])
              : null,
          color: colors.lightBlue[200]
        }
      ]
      return data
    } else {
      return data
    }
  }

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    //animation: false,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',

      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        // @ts-ignore
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex]
          var meta = dataset._meta[Object.keys(dataset._meta)[0]]
          var total = meta.total
          var currentValue = dataset.data[tooltipItem.index]
          var percentage = parseFloat(((currentValue / total) * 100).toFixed(1))
          return (
            Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            }).format(currentValue) +
            ' (' +
            percentage.toLocaleString('pt-BR', {
              maximumFractionDigits: 2
            }) +
            '%)'
          )
        },
        // @ts-ignore
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index]
        }
      }
    }
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        // action={
        //   <IconButton size="small">
        //     <RefreshIcon />
        //   </IconButton>
        // }
        title="RECEITA DOS ÚLTIMOS 6 MESES"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          {isFetching || !showValues ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Skeleton
                variant="circle"
                animation={isFetching ? 'pulse' : false}
                height={300}
                width={300}
              />
            </div>
          ) : (
            <Pie data={generateData} options={options} />
          )}
        </div>
        <div className={classes.stats}>
          {isFetching || !showValues ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Skeleton
                animation={isFetching ? 'pulse' : false}
                height={50}
                width="100%"
              />
            </div>
          ) : (
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              spacing={2}
            >
              {generateStrings().map(
                // @ts-ignore
                (meses) =>
                  meses.value !== null && (
                    <Grid
                      item
                      xs={4}
                      className={classes.meses}
                      key={meses.title}
                    >
                      <Typography variant="body2">{meses.title}</Typography>
                      <Typography style={{ color: meses.color }} variant="h6">
                        {meses.value}
                      </Typography>
                    </Grid>
                  )
              )}
            </Grid>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

export default ReceitaUltimos6Meses
