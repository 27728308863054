import { useQuery } from 'react-query'
import { Lote } from 'types/api'

//? Own Imports
import { crudService } from '_services'

const getLotesByEvent = async (eventId: number | undefined) => {
  const apiEndpoint = `lotes/evento/${eventId}`
  const { data } = await crudService.get(apiEndpoint)
  return data
}

export default function useLotesByEvent(eventId: number | undefined) {
  return useQuery<Lote[]>(['lotes', eventId], () => getLotesByEvent(eventId), {
    enabled: !!eventId && eventId > 0
  })
}
