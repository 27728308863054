import React from 'react'
import { SkeletonTable } from 'components'
import { Grid, makeStyles } from '@material-ui/core'
import { SelectEvent, TableEventRating } from './components'
import { useSelector } from 'react-redux'
import { AccessControl } from 'components/Utils/AccessControl'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(3, 2, 2, 2)
  },
  header: {
    paddingBottom: theme.spacing(2)
  }
}))

const EventRating = () => {
  const classes = useStyles()
  const { avaliacao: ratings, isLoading } = useSelector(
    (state) => state.avaliacao
  )

  return (
    <AccessControl
      rule={'avaliacoes.list'} //permissão necessária para acessar conteúdo
      yes={() => (
        <div className={classes.root}>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <SelectEvent />
            </Grid>
            {isLoading ? (
              <SkeletonTable />
            ) : (
              <Grid item xs={12} sm={12}>
                <TableEventRating ingressos={ratings} />
              </Grid>
            )}
          </Grid>
        </div>
      )}
    />
  )
}

export default EventRating
