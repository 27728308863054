import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  colors,
  Typography
} from '@material-ui/core'

import { Label } from 'components'
import { cpfCnpjMask } from 'utils'
import { isEmpty } from 'validate.js'
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    ...constants.shadowCard
  },
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}))

const CardContaBancaria = ({ contaBancaria, className, ...rest }) => {
  const classes = useStyles()
  const { isLoading } = useSelector((state) => state.movimentacaoFinanceira)

  function getNameTipoPix(tipoChavePix) {
    switch (tipoChavePix) {
      case 0:
        return 'CPF/CNPJ'
      case 1:
        return 'Email'
      case 2:
        return 'Nº Celular'
      case 3:
        return 'Chave Aleatória'
    }
  }

  return (
    <>
      {!isEmpty(contaBancaria) && contaBancaria.conta !== '0' ? (
        <Card {...rest} className={clsx(classes.paper, className)}>
          <CardHeader title="Conta bancária" />
          <Divider />
          <CardContent className={classes.content}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Descrição:</TableCell>
                  <TableCell>
                    {isLoading ? <Skeleton /> : contaBancaria.descricao}
                    <div>
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        <Typography variant="caption" color="textPrimary">
                          Conta padrão:{' '}
                        </Typography>
                      )}
                      {!isLoading && (
                        <Label
                          color={
                            contaBancaria.padrao
                              ? colors.green[600]
                              : colors.red[600]
                          }
                        >
                          {contaBancaria.padrao ? 'SIM' : 'NÃO'}
                        </Label>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow selected>
                  <TableCell>Instituição bancária:</TableCell>
                  <TableCell>
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      contaBancaria.instituicaoBancaria.nome
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Agência:</TableCell>
                  <TableCell>
                    {isLoading ? <Skeleton /> : contaBancaria.agencia}
                  </TableCell>
                </TableRow>
                <TableRow selected>
                  <TableCell>Conta:</TableCell>
                  <TableCell>
                    {isLoading ? <Skeleton /> : contaBancaria.conta}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Nome titular:</TableCell>
                  <TableCell>
                    {isLoading ? <Skeleton /> : contaBancaria.nomeTitular}
                  </TableCell>
                </TableRow>
                <TableRow selected>
                  <TableCell>CPF/CNPJ Titular:</TableCell>
                  <TableCell>
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      cpfCnpjMask(contaBancaria.cpfCnpjTitular)
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      ) : (
        <Card {...rest} className={clsx(classes.paper, className)}>
          <CardHeader title="Conta bancária" />
          <Divider />
          <CardContent className={classes.content}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Descrição:</TableCell>
                  <TableCell>
                    {isLoading ? <Skeleton /> : contaBancaria.descricao}
                    <div>
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        <Typography variant="caption" color="textPrimary">
                          Conta padrão:{' '}
                        </Typography>
                      )}
                      {!isLoading && (
                        <Label
                          color={
                            contaBancaria.padrao
                              ? colors.green[600]
                              : colors.red[600]
                          }
                        >
                          {contaBancaria.padrao ? 'SIM' : 'NÃO'}
                        </Label>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow selected>
                  <TableCell>Chave Pix:</TableCell>
                  <TableCell>
                    {isLoading ? <Skeleton /> : contaBancaria.chavePix}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tipo Chave Pix:</TableCell>
                  <TableCell>
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      getNameTipoPix(contaBancaria.tipoChavePix)
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      )}
    </>
  )
}

CardContaBancaria.propTypes = {
  className: PropTypes.string,
  contaBancaria: PropTypes.object.isRequired
}

export default CardContaBancaria
