import React from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Avatar, Typography } from '@material-ui/core'
import getInitials from 'utils/getInitials'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}))

const Profile = (props) => {
  const { user, className, ...rest } = props

  const classes = useStyles()

  const isAddPlace = useSelector((state) => {
    return state.usuarioEstabelecimento.isAddPlace
  })

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar
        alt={getInitials(user.nome)}
        className={classes.avatar}
        component={RouterLink}
        src={user.imagem}
        to={!isAddPlace ? '/settings' : '/estabelecimento/general'}
      >
        {' '}
        {getInitials(user.nome)}
      </Avatar>
      <Typography align="center" className={classes.name} variant="h4">
        {user.nome}
      </Typography>
      <Typography noWrap variant="body1">
        {user.email}
      </Typography>
      <Typography variant="body2">{user.tipoUsuario.descricao}</Typography>
    </div>
  )
}

Profile.propTypes = {
  className: PropTypes.string
}

export default Profile
