import React from 'react'
import {
  TableBody,
  colors,
  Table,
  withStyles,
  makeStyles,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Tooltip,
  IconButton
} from '@material-ui/core'
import { formatCurrency, history, toLocalDateFormat } from 'utils'
import { Label } from 'components'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell)

const useStyles = makeStyles({
  table: {
    minWidth: 700
  }
})

const TableIngressos = ({ movimentacoesFinanceiras }) => {
  const classes = useStyles()

  const statusColors = {
    1: colors.orange[600],
    2: colors.red[600],
    3: colors.green[600]
  }

  return (
    <div style={{ paddingTop: 15 }}>
      {movimentacoesFinanceiras.length !== undefined &&
      movimentacoesFinanceiras.length !== 0 ? (
        <Typography variant="body2" align="left" gutterBottom>
          Total de dados encontrados: <b>{movimentacoesFinanceiras.length}</b>
        </Typography>
      ) : (
        <Typography variant="body2" align="left" gutterBottom>
          <b>{'Nenhum dado encontrado.'}</b>
        </Typography>
      )}
      <TableContainer component={Paper}>
        {movimentacoesFinanceiras !== undefined &&
          movimentacoesFinanceiras.length > 0 && (
            <Table
              size="small"
              className={classes.table}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">N.º</StyledTableCell>
                  <StyledTableCell align="center">
                    Estabelecimento
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Data solicitação
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Data processamento
                  </StyledTableCell>
                  <StyledTableCell align="center">Valor (R$)</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="right"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {movimentacoesFinanceiras.map((row) => (
                  <TableRow hover key={row.id}>
                    <StyledTableCell align="left" component="th" scope="row">
                      {row.id}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.estabelecimento.nome}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {toLocalDateFormat(row.dataHora)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.dataHoraProcessamento !== null
                        ? toLocalDateFormat(row.dataHoraProcessamento)
                        : '--------'}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {formatCurrency(row.valor)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Label
                        color={statusColors[row.statusMovimentacaoFinanceiraId]}
                        variant="outlined"
                      >
                        {row.statusMovimentacaoFinanceira.descricao}
                      </Label>
                    </StyledTableCell>
                    <StyledTableCell width={30} align="right">
                      <Tooltip title="Detalhes">
                        <IconButton
                          size="small"
                          aria-label="detalhes movimentação financeira"
                          onClick={() =>
                            history.push(
                              `movimentacaoFinanceira/${row.id}/resumo`
                            )
                          }
                        >
                          <InfoOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
      </TableContainer>
    </div>
  )
}

export default TableIngressos
