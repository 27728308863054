import { useMutation } from 'react-query'
import { PhotoAlbum } from 'types/api'
import { crudService } from '_services'
import { toast } from 'react-toastify'

const postCreatePhotoAlbum = async (payload: PhotoAlbum) => {
  const { data } = await crudService.post('albuns', payload)
  return data
}

export default function useMutationPhotoAlbumCreate() {
  return useMutation(
    'photoAlbum-create',
    (payload: PhotoAlbum) => postCreatePhotoAlbum(payload),
    {
      onError: (error) => {
        // @ts-ignore
        toast.error(error.message || 'Erro ao criar álbum de fotos')
      },
      onSuccess: () => {
        // @ts-ignore
        toast.success('Álbum de fotos criado com sucesso!')
      }
    }
  )
}
