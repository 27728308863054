import React, { ChangeEvent } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import {
  Tabs,
  Tab,
  Divider,
  colors,
  makeStyles,
  LinearProgress
} from '@material-ui/core'

//? Own imports
import { Page, Header } from 'components'
import { Info, Financial } from './components'
import { history, useSearchQuery } from 'utils'
import { AccessControl } from 'components/Utils'
import { useMemberPlanDetails, useMemberSubscription } from 'hooks-querys'
import AccessDenied from 'views/Errors/AccessDenied'

const useStyles = makeStyles((theme) => ({
  root: {},
  inner: {
    padding: theme.spacing(3, 2, 2, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 1, 1, 1)
    }
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  },
  header: {
    marginBottom: theme.spacing(3)
  }
}))

const MemberPlanDetails = () => {
  const classes = useStyles()
  const { tab, id: memberPlanId } = useParams<{ tab: string; id: string }>()
  const querySearch = useSearchQuery()

  const {
    isLoading: isLoadingMemberPlanDetails,
    isFetching: isFetchingMemberPlanDetails,
    refetch: refetchMemberPlanDetails
  } = useMemberPlanDetails(
    {
      memberPlanId: Number(memberPlanId)
    },
    {
      config: {
        enabled: !!(tab === 'informacoes')
      }
    }
  )

  const {
    isLoading: isLoadingMemberSubscriptions,
    isFetching: isFetchingMemberSubscriptions,
    refetch: refetchMemberSubscriptions
  } = useMemberSubscription(
    {
      membroPlanoId: Number(memberPlanId),
      pago: querySearch.get('Pago'),
      page: 1,
      limit: 9999
    },
    { config: { enabled: !!(tab === 'financeiro') } }
  )

  const isLoading =
    tab === 'informacoes'
      ? isLoadingMemberPlanDetails
      : tab === 'financeiro'
      ? isLoadingMemberSubscriptions
      : false

  const isFetching =
    tab === 'informacoes'
      ? isFetchingMemberPlanDetails
      : tab === 'financeiro'
      ? isFetchingMemberSubscriptions
      : false

  const refetch = () => {
    if (tab === 'informacoes') {
      refetchMemberPlanDetails()
    } else if (tab === 'financeiro') {
      refetchMemberSubscriptions()
    }
  }

  const handleTabsChange = (_event: ChangeEvent<{}>, value: string) => {
    history.push(value)
  }

  const tabs = [
    { value: 'informacoes', label: 'Informações' },
    { value: `financeiro`, label: 'Financeiro' }
  ]

  if (!tab) {
    return (
      <Redirect to={`/members/memberPlanDetails/${memberPlanId}/informacoes`} />
    )
  }

  if (!tabs.find((t) => t.value === tab)) {
    return <Redirect to="/errors/error-404" />
  }

  return (
    // @ts-ignore
    <AccessControl
      rule={'membros.list'}
      // @ts-ignore
      yes={() => (
        <Page
          title={
            isLoading
              ? 'Carregando...'
              : `Informações membro N.º: ${memberPlanId}`
          }
        >
          <>
            {isFetching && (
              <LinearProgress
                style={{
                  width: '100%',
                  position: 'absolute'
                }}
              />
            )}
            <div className={classes.inner}>
              <Header
                className={classes.header}
                title={
                  isLoading
                    ? 'Carregando...'
                    : `Informações membro N.º: ${memberPlanId}`
                }
                subtitle={`Módulo - Membros${
                  tabs?.find((item) => item.value === tab)?.label
                    ? ` → ${tabs.find((item) => item.value === tab)?.label}`
                    : ''
                }`}
                isRefreshing={isFetching}
                onRefresh={refetch}
              />
              <Tabs
                onChange={handleTabsChange}
                scrollButtons="auto"
                indicatorColor="primary"
                textColor="primary"
                value={tab}
                variant="scrollable"
              >
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
              <Divider className={classes.divider} />
              <div className={classes.content}>
                {tab === 'informacoes' && <Info />}
                {tab === 'financeiro' && <Financial />}
              </div>
            </div>
          </>
        </Page>
      )}
      // @ts-ignore
      no={() => <AccessDenied />}
    />
  )
}

export default MemberPlanDetails
