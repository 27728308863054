import React, { useState } from 'react'
import { Button, Grid } from '@material-ui/core'
import { Google } from '../../../../icons'
import { authActions } from '../../../../_actions'
import FacebookIcon from '@material-ui/icons/Facebook'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { makeStyles } from '@material-ui/styles'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  facebook: {
    backgroundColor: '#3b5998',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light
    }
  },
  google: {
    backgroundColor: '#fff',
    textTransform: 'none',
    color: theme.palette.primary.dark,
    '&:hover': {
      color: 'white'
    }
  }
}))

const SocialButtons = () => {
  const classes = useStyles()
  const [submitted, setSubmitted] = useState(false)
  const dispatch = useDispatch()
  const responseFacebook = (response) => {
    if (response.accessToken) {
      dispatch(authActions.facebookLogin(response.accessToken, true))
    }
  }
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <FacebookLogin
            appId="648984027274250"
            isMobile={false}
            disableMobileRedirect={true}
            autoLoad={false}
            fields="name,email,picture"
            callback={responseFacebook}
            render={(renderProps) => (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={submitted}
                onClick={renderProps.onClick}
                className={classes.facebook}
                startIcon={<FacebookIcon />}
              >
                {submitted ? (
                  'Validando informações...'
                ) : (
                  <>
                    Entrar com
                    <strong>&nbsp;Facebook</strong>
                  </>
                )}
              </Button>
            )}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={submitted}
            // onClick={renderProps.onClick}
            className={classes.google}
            startIcon={<Google />}
          >
            {submitted ? (
              'Validando informações...'
            ) : (
              <>
                Entrar com
                <strong>&nbsp;Google</strong>
              </>
            )}
          </Button>
        </Grid> */}
      </Grid>
    </div>
  )
}

export default SocialButtons
