import React, {  useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { albumAction } from '_actions'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  InputAdornment,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  useTheme,
  useMediaQuery,
  Box,
  makeStyles
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import SendIcon from '@material-ui/icons/Send'
import { ImagePreviewClientSide } from '../PhotoList/PhotoList'
import { useConfirm } from 'material-ui-confirm'
import { getImageUrls, useDidMountEffect } from 'utils'

type PhotoEditDialog = {
  openModal: boolean
  setOpenModal: (open: boolean) => void
  photo: ImagePreviewClientSide
}

const useStyles = makeStyles((theme) => ({
  formDialog: {
    overflow: 'hidden',
    overflowY: 'hidden',
    overflowX: 'hidden'
  }
}))

export default function PhotoEditDialog ({
  openModal,
  setOpenModal,
  photo
}: PhotoEditDialog) {
  const dispatch = useDispatch()
  const confirm = useConfirm()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [title, setTitle] = useState(photo?.title)
  const [description, setDescription] = useState(photo?.description)
  const { fotoCapaId }: { fotoCapaId: Number } = useSelector(
    // @ts-ignore
    (state) => state.album
    )
  const [isCover, setCover] = useState(fotoCapaId == Number(photo.id) ? true : false)

  useDidMountEffect(() => {
    setCover(fotoCapaId == Number(photo.id) ? true : false)
  }, [fotoCapaId])

  const handleClose = () => {
    setOpenModal(false)
    setTitle(photo?.title)
    setDescription(photo?.description)
    setCover(fotoCapaId == Number(photo.id) ? true : false)
  }

  const handleDelete = () => {
    confirm({
      title: 'Você deseja mesmo excluir esta foto?',
      description: 'Confirmando essa operação, esta foto não aparecerá mais no álbum de fotos',
      confirmationText: 'Sim, excluir!',
      cancellationText: 'Nãããoo!'
    }).then(() => {
      if (fotoCapaId == Number(photo.id)) {
        dispatch(albumAction.setFotoCapaId(null))
      }
      dispatch(albumAction.deleteFoto(photo))
    })
  }

  const updateFile = (data: ImagePreviewClientSide) => {
    if (isCover) {
      dispatch(albumAction.setFotoCapaId(photo.id))
    } else if (fotoCapaId == Number(photo.id)) {
      dispatch(albumAction.setFotoCapaId(null))
    }

    dispatch(
      albumAction.updateFoto({
        ...photo,
        ...data
      })
    )
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    updateFile({
      ...photo,
      title: title,
      description: description,
      isCover: isCover
    })
    setOpenModal(false)
  }

  const handleChangeTitle = (value: string) => {
    setTitle(value)
  }

  const handleChangeDescription = (value: string) => {
    setDescription(value)
  }

  const handleFotoCapa = () => {
    setCover(!isCover)
  }

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      fullScreen={isMobile}
      maxWidth="lg"
      className={classes.formDialog}
      >
      <DialogTitle id="form-dialog-title">
        Edição Foto
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent className={classes.formDialog}>
          <DialogContentText></DialogContentText>
          <Grid container  spacing={4} alignItems="center" >
            <Grid item xs={12} sm={12} md={6} lg={6} >
              <img
                style={{ width: '100%', maxHeight: "calc(100vh - 300px)", maxWidth: 'fit-content'}}
                src={photo.file != null ? photo?.preview : getImageUrls(photo?.preview)?.compressedUrl}
                alt="image"
              />
              {!isMobile && !isSmallScreen && (
                <Box textAlign='left'>
                  <Button
                    variant="outlined"
                    onClick={handleDelete}
                    color="primary"
                    startIcon={<DeleteIcon />}
                  >
                    Excluir Foto
                  </Button>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                autoFocus
                margin="dense"
                id="title"
                label="Título"
                type="text"
                multiline
                value={title}
                onChange={(event) => handleChangeTitle(event.target.value)}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <>
                          {!!title && title.length > 200 ? (
                            <Typography variant="body2" align="right" color="error">
                              {title?.length}/200
                            </Typography>
                          ) :
                            (<Typography variant="body2" align="right" >
                              {title?.length}/200
                            </Typography>)
                          }
                        </>
                      </InputAdornment>
                    </>
                  )
                }}
              />
              <TextField
                margin="dense"
                id="description"
                label="Descrição"
                type="text"
                multiline
                value={description}
                onChange={(event) => handleChangeDescription(event.target.value)}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                      <>
                          {description != undefined && description.length > 280 ? (
                            <Typography variant="body2" align="right" color="error">
                              {description?.length}/280
                            </Typography>
                          ) :
                            (<Typography variant="body2" align="right" >
                              {description?.length}/280
                            </Typography>)
                          }
                        </>
                      </InputAdornment>
                    </>
                  )
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCover}
                    onChange={handleFotoCapa}
                    name="fotoCapa"
                    color="primary"
                  />
                }
                label={
                  <Typography variant="inherit">
                    Definir foto como capa do álbum
                  </Typography>
                }
              />
          </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
          {(isMobile || isSmallScreen) && (
            <Button
              variant="outlined"
              onClick={handleDelete}
              color="primary"
              startIcon={<DeleteIcon />}
            >
              Excluir
            </Button>
          )}
          <Button variant="outlined" onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button variant="contained" type="submit" color="primary" endIcon={<SendIcon />}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
