import { useQuery } from 'react-query'
import { Evento } from 'types/api'
import { crudService } from '_services'

const getEvents = async (
  estabelecimentoId: number,
  showFinishedEvents: boolean,
  showAll: boolean
) => {
  const stringPendentes = !showFinishedEvents ? 'pendentes' : 'realizados'
  const apiEndpoint = showAll
    ? `eventos/estabelecimento/${estabelecimentoId}`
    : `eventos/${stringPendentes}/estabelecimento/${estabelecimentoId}`
  try {
    const { data } = await crudService.get(apiEndpoint)
    return data
  } catch (e) {
    return e
  }
}

export default function useEvents(
  estabelecimentoId: number,
  showFinishedEvents: boolean,
  showAll: boolean
) {
  return useQuery<Evento[]>(
    ['eventsSelect', estabelecimentoId, showFinishedEvents, showAll],
    () => getEvents(estabelecimentoId, showFinishedEvents, showAll),
    {
      enabled: estabelecimentoId > 0
    }
  )
}
