import { createDynamicLink } from '_services/firebaseDynamicLinks'

export const genericOnShare = async (
  url: string,
  title: string,
  message: string,
  socialImageLink?: string
) => {
  const dynamicLink = await createDynamicLink({
    dynamicLinkInfo: {
      domainUriPrefix: 'corujas.page.link',
      link: url,
      androidInfo: {
        androidPackageName: 'com.quintos.corujas',
        androidMinPackageVersionCode: '1'
      },
      iosInfo: {
        iosBundleId: 'com.quintos.corujas',
        iosAppStoreId: '1593667736',
        iosFallbackLink: url
      },
      navigationInfo: {
        enableForcedRedirect: true
      },
      socialMetaTagInfo: {
        socialTitle: title,
        socialDescription: message,
        socialImageLink:
          socialImageLink ||
          'https://corujas.s3.us-east-2.amazonaws.com/images/general/splash.png'
      }
    },
    suffix: {
      option: 'SHORT'
    }
  })

  const link = dynamicLink || url

  return link
}
