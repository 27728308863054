const initialState = {
  anchor: 'left',
  categoria: [],
  open: false,
  id: 0,
  descricao: ''
}

export function categoria(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_CATEGORIA':
      return {
        ...state,
        categoria: action.categoria
      }
    case 'CATEGORIA_DETAIL':
      return {
        ...state,
        id: action.id,
        descricao: action.descricao
      }
    case 'CATEGORIA_UPDATED':
      return state
    case 'CATEGORIA_CLEAR':
      return initialState
    case 'CATEGORIA_CLEAR_ALL':
      return {
        ...state,
        categoria: []
      }
    case 'CATEGORIA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    default:
      return state
  }
}
