import React, { useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { cepMask } from 'utils/masks'
import { makeStyles } from '@material-ui/styles'
import {
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Divider,
  MenuItem
} from '@material-ui/core'
import { isEmpty } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { eventoAction, estadoAction, cidadeAction } from '_actions'
import { useDidMountEffect } from 'utils/useDidMountEffect'
import constants from 'theme/constants'

const useStyles = makeStyles(() => ({
  root: {
    ...constants.shadowCard
  }
}))

const EventoEditLocale = (props) => {
  const { className, ...rest } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const evento = useSelector((state) => state.evento)
  const { estado } = useSelector((state) => state.estado)
  const { cidade } = useSelector((state) => state.cidade)
  const estabelecimento = useSelector((state) => state.estabelecimento)

  useEffect(() => {
    if (evento.id > 0 && evento.tipoLocalizacao !== 'O') {
      dispatch(estadoAction.getEstado())
    }
  }, [])

  useDidMountEffect(() => {
    if (evento.tipoLocalizacao !== 'O' && evento.tipoLocalizacao !== '') {
      if (estado.length === 0) {
        dispatch(estadoAction.getEstado())
      }
    }
  }, [evento.tipoLocalizacao])

  useEffect(() => {
    if (evento.estadoId > 0) {
      dispatch(cidadeAction.getCidadeByUf(evento.estadoId))
    }
  }, [evento.estadoId])

  const handleChange = (prop) => (event) => {
    dispatch(eventoAction.onChangeProps(prop, event))
  }

  const onChangeTipoEvento = (event) => {
    dispatch(eventoAction.onChangeProps('tipoLocalizacao', event))
    if (event.target.value === 'E') {
      dispatch(eventoAction.copyEstabelecimentoAddress(estabelecimento))
    } else {
      dispatch(eventoAction.clearAddress())
    }
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        title="2 - Onde vai ser o evento? "
        subheader="Informe o endereço completo de onde acontecerá o evento!"
        titleTypographyProps={{ variant: 'h4' }}
      />
      <Divider />
      <CardContent>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} style={{ paddingBottom: '20px' }}>
            <Grid item xs={12} sm={6} md={5} lg={5}>
              <SelectValidator
                validators={['required']}
                errorMessages={['Campo obrigatório!']}
                variant="outlined"
                id="Local"
                label="Local *"
                InputLabelProps={{
                  shrink: true
                }}
                value={evento.tipoLocalizacao}
                onChange={onChangeTipoEvento}
                fullWidth
              >
                <MenuItem disabled value="">
                  <em>Local</em>
                </MenuItem>
                <MenuItem value={'N'}>Cadastrar endereço</MenuItem>
                <MenuItem value={'A'}>Local a definir</MenuItem>
                <MenuItem value={'O'}>Evento online</MenuItem>
                <MenuItem value={'E'}>
                  O evento será no mesmo endereço do estabelecimento
                </MenuItem>
              </SelectValidator>
            </Grid>
          </Grid>
          {evento.tipoLocalizacao !== '' && evento.tipoLocalizacao !== null && (
            <>
              {(evento.tipoLocalizacao === 'E' ||
                evento.tipoLocalizacao === 'N') && (
                <Grid item xs={12}>
                  <TextValidator
                    variant="outlined"
                    id="nomeLocal"
                    label="Nome do local *"
                    value={evento.nomeLocal}
                    onChange={handleChange('nomeLocal')}
                    fullWidth
                    disabled={evento.tipoLocalizacao === 'E' ? true : false}
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                  />
                </Grid>
              )}
              {evento.tipoLocalizacao !== 'O' && (
                <>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <SelectValidator
                      validators={['required']}
                      errorMessages={['Campo obrigatório!']}
                      variant="outlined"
                      id="estadoId"
                      label="Estado *"
                      value={evento.estadoId}
                      onChange={handleChange('estadoId')}
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                      disabled={evento.tipoLocalizacao === 'E' ? true : false}
                      displayEmpty
                      labelId="estadoId"
                    >
                      <MenuItem disabled value="">
                        <em>Estado *</em>
                      </MenuItem>
                      {undefined !== estado && estado.length
                        ? estado.map((row) => (
                            <MenuItem key={row.id} value={row.id}>
                              {row.nome}
                            </MenuItem>
                          ))
                        : null}
                    </SelectValidator>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <SelectValidator
                      validators={['required']}
                      errorMessages={['Campo obrigatório!']}
                      variant="outlined"
                      id="cidadeId"
                      label="Cidade *"
                      value={evento.cidadeId}
                      onChange={handleChange('cidadeId')}
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                      disabled={
                        evento.tipoLocalizacao === 'E' || evento.estadoId === ''
                          ? true
                          : false
                      }
                      displayEmpty
                      labelId="cidadeId"
                    >
                      <MenuItem disabled value="">
                        <em>Cidade *</em>
                      </MenuItem>
                      {!isEmpty(cidade) &&
                        cidade.map((row) => (
                          <MenuItem key={row.id} value={row.id}>
                            {row.nome}
                          </MenuItem>
                        ))}
                    </SelectValidator>
                  </Grid>
                  {evento.tipoLocalizacao !== 'A' && (
                    <>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <TextValidator
                          variant="outlined"
                          fullWidth
                          id="cep"
                          label="CEP *"
                          name="cep"
                          type="text"
                          disabled={
                            evento.tipoLocalizacao === 'E' ? true : false
                          }
                          inputProps={{
                            maxlength: '9'
                          }}
                          value={cepMask(evento.cep)}
                          onChange={handleChange('cep')}
                          autoComplete="cep"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={5}>
                        <TextValidator
                          variant="outlined"
                          id="endereco"
                          label="Endereço *"
                          value={evento.endereco}
                          onChange={handleChange('endereco')}
                          fullWidth
                          disabled={
                            evento.tipoLocalizacao === 'E' ? true : false
                          }
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3} md={2}>
                        <TextValidator
                          variant="outlined"
                          id="numero"
                          label="Número *"
                          value={evento.numero}
                          onChange={handleChange('numero')}
                          fullWidth
                          disabled={
                            evento.tipoLocalizacao === 'E' ? true : false
                          }
                          type="text"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3} md={2}>
                        <TextValidator
                          variant="outlined"
                          id="complemento"
                          label="Complemento"
                          value={evento.complemento}
                          onChange={handleChange('complemento')}
                          fullWidth
                          disabled={
                            evento.tipoLocalizacao === 'E' ? true : false
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextValidator
                          variant="outlined"
                          id="bairro"
                          label="Bairro *"
                          value={evento.bairro}
                          onChange={handleChange('bairro')}
                          fullWidth
                          disabled={
                            evento.tipoLocalizacao === 'E' ? true : false
                          }
                          validators={['required', 'isValidNome']}
                          errorMessages={[
                            'Campo obrigatório!',
                            'Eii, o nome é muito curto!'
                          ]}
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

EventoEditLocale.propTypes = {
  className: PropTypes.string
}

export default EventoEditLocale
