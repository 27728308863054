import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

//? Own imports
import { MemberPlan } from 'types/api'
import { crudService } from '_services'

const deleteMemberPlan = async (idMemberPlan: Number) => {
  let payload = {
    membroPlanoId: idMemberPlan,
    statusMembroPlanoId: 2
  }
  const { data } = await crudService.post('membrosPlanos/cancelar', payload)
  return data
}

export default function useMutationMemberPlanDelete() {
  return useMutation(
    'memberPlan-delete',
    (idMemberPlan: Number) => deleteMemberPlan(idMemberPlan),
    {
      onError: (error) => {
        // @ts-ignore
        toast.error(error.message || 'Erro ao excluir membro!')
      },
      onSuccess: (response: MemberPlan) => {
        toast.success('Membro excluido com sucesso!')
      }
    }
  )
}
