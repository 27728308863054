import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors,
  makeStyles,
  Box
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'

//? Own imports
import { Label } from 'components'
import { MembershipCard, UserEdit } from './components'
import { cepMask, cpfCnpjMask, getGenderFull, history, phoneMask } from 'utils'
import { MemberPlan } from 'types/api'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    ...constants.shadowCard
  },
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  label: {
    marginTop: theme.spacing(1)
  }
}))

type UserInfoProps = {
  memberPlan: MemberPlan
}

const UserInfo = ({ memberPlan }: UserInfoProps) => {
  const classes = useStyles()
  const [openEdit, setOpenEdit] = useState(false)
  const [openDialogMembershipCard, setOpenDialogMembershipCard] = useState(
    false
  )

  const handleEditOpen = () => {
    setOpenEdit(true)
  }

  const handleEditClose = () => {
    setOpenEdit(false)
  }

  return (
    <Card className={classes.root}>
      <CardHeader title="Dados cadastrais" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Matrícula</TableCell>
              <TableCell>#{memberPlan.matricula
                            ? memberPlan.matricula
                            : memberPlan?.id}
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Nome</TableCell>
              <TableCell>
                {memberPlan?.membro?.nome}
                {memberPlan?.membro?.usuario && (
                  <Box mt={1}>
                    <Label color={colors.green[600]}>
                      {'POSSUI USUÁRIO VINCULADO'}
                    </Label>
                  </Box>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>CPF/CNPJ</TableCell>
              <TableCell>{cpfCnpjMask(memberPlan?.membro?.cpfCnpj)}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Email</TableCell>
              <TableCell>{memberPlan?.membro?.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Telefone</TableCell>
              <TableCell>
                {memberPlan?.membro?.telefone
                  ? phoneMask(memberPlan?.membro?.telefone)
                  : 'Não informado'}
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Data de nascimento</TableCell>
              <TableCell>
                {memberPlan?.membro?.dataNascimento
                  ? `${memberPlan?.membro?.dataNascimento
                      .toString()
                      .split('-')[2]
                      .substring(0, 2)}/${
                      memberPlan?.membro?.dataNascimento
                        .toString()
                        .split('-')[1]
                    }/${
                      memberPlan?.membro?.dataNascimento
                        .toString()
                        .split('-')[0]
                    }`
                  : 'Não informada'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Endereço</TableCell>
              <TableCell>
                {memberPlan?.membro?.endereco
                  ? `${memberPlan?.membro?.endereco}, ${
                      memberPlan?.membro?.numero
                    }${
                      memberPlan?.membro?.complemento
                        ? `, ${memberPlan?.membro?.complemento}`
                        : ''
                    } - ${memberPlan?.membro?.bairro} - ${cepMask(
                      memberPlan?.membro?.cep
                    )}`
                  : 'Não informado'}
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Cidade</TableCell>
              <TableCell>
                {memberPlan &&
                  memberPlan.membro?.cidade &&
                  memberPlan?.membro?.cidade?.nome}{' '}
                -{' '}
                {memberPlan &&
                  memberPlan.membro?.cidade &&
                  memberPlan?.membro?.cidade?.estado?.uf}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Pais</TableCell>
              <TableCell>
                {memberPlan?.membro?.cidade?.estado?.pais?.nomePt ||
                  'Não informado'}
              </TableCell>
            </TableRow>
            {memberPlan?.membro?.usuario && (
              <>
                <TableRow selected>
                  <TableCell>Apelido</TableCell>
                  <TableCell>
                    {memberPlan?.membro?.usuario?.apelido
                      ? memberPlan?.membro?.usuario?.apelido
                      : 'Não informado'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Nome do usuário</TableCell>
                  <TableCell>
                    @{memberPlan?.membro?.usuario?.username}
                  </TableCell>
                </TableRow>
              </>
            )}
            <TableRow selected>
              <TableCell>Gênero</TableCell>
              <TableCell>{getGenderFull(memberPlan?.membro?.genero)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
              >
                QRCode
              </TableCell>
              <TableCell
                style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
              >
                {memberPlan?.qrCode}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          startIcon={<EditIcon />}
          onClick={() =>
            history.push(`/members/cadastroMembro/${memberPlan?.membro?.id}`)
          }
          // disabled={true}
        >
          Editar
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<GetAppRoundedIcon />}
          onClick={() => setOpenDialogMembershipCard(true)}
        >
          Gerar carteira em PDF
        </Button>
      </CardActions>
      {/* <UserEdit
        memberPlan={memberPlan}
        onClose={handleEditClose}
        open={openEdit}
      /> */}

      <MembershipCard
        setOpenDialogMembershipCard={setOpenDialogMembershipCard}
        openDialogMembershipCard={openDialogMembershipCard}
        memberPlan={memberPlan}
      />
    </Card>
  )
}

export default UserInfo
