import { useQuery } from 'react-query'

//? Own imports
import { SelectListItem } from 'types/api'
import { crudService } from '_services'

const getStatusIngressoSelect = async () => {
  const apiEndpoint = `statusIngressos/select`
  try {
    const { data } = await crudService.get(apiEndpoint)
    return data
  } catch (e) {
    return e
  }
}

export default function useStatusIngressoSelect() {
  return useQuery<SelectListItem[]>(
    ['statusIngressoSelect'],
    () => getStatusIngressoSelect(),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      refetchOnWindowFocus: false
    }
  )
}
