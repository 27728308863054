const initialState = {
  comentario: [],
  id: 0,
  usuarioId: 0,
  dataHora: null,
  mensagem: '',
  comentarioId: 0,
  avaliacaoId: 0,
  privado: false,
  excluido: false,
  isLoading: false,
  reload: false
}

export function comentario(state = initialState, action) {
  const newCommentsReview = []
  switch (action.type) {
    case 'COMENTARIO_CHANGE_ANSWER':
      return {
        ...state,
        mensagem: action.mensagem
      }
    case 'COMENTARIO_ANSWER_SUCCESS':
      return {
        ...state,
        usuarioId: 0,
        mensagem: ''
      }
    case 'REQUEST_ALL_COMENTARIOS_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'REQUEST_ALL_COMENTARIOS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        comentario: action.comentarios
      }
    case 'REQUEST_ALL_COMENTARIOS_FAILURE':
      return {
        ...state,
        isLoading: false
      }
    case 'DELETE_COMENTARIO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'DELETE_COMENTARIO_SUCCESS':
      return {
        ...state,
        isLoading: false,
        reload: !action.reload
      }
    case 'DELETE_COMENTARIO_FAILURE':
      return {
        ...state,
        isLoading: false
      }
    case 'LIKE_COMENTARIO_REQUEST':
      state.comentario.map((comment) => {
        comment.comentario.id === action.commentId
          ? newCommentsReview.push({
            ...comment,
            usuarioCurtiu: !comment.usuarioCurtiu,
            numeroCurtidas: comment.usuarioCurtiu
              ? --comment.numeroCurtidas
              : ++comment.numeroCurtidas
          })
          : newCommentsReview.push(comment)
      })
      return {
        ...state,
        comentario: newCommentsReview
      }
    case 'LIKE_COMENTARIO_SUCCESS':
      return {
        ...state
      }
    case 'LIKE_COMENTARIO_FAILURE':
      return {
        ...state
      }
    default:
      return state
  }
}
