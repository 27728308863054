const initialState = {
  showValues: true,
  isVisibleButtonShowValues: false
}

export function global(state = initialState, action) {
  switch (action.type) {
    case 'SET_SHOW_VALUES':
      return {
        ...state,
        showValues: !state.showValues
      }
    case 'SET_IS_VISIBLE_BUTTON_SHOW_VALUES':
      return {
        ...state,
        isVisibleButtonShowValues: action.isVisible
      }
    default:
      return state
  }
}
