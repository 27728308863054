import { useQuery } from 'react-query'
import { toast } from 'react-toastify'

//? Own imports
import { crudService } from '_services'

type Payload = {
  loteId: number
  segueEstabelecimento: boolean
  favoritouEvento: boolean
}

const postUsersDrawTicket = async (payload: Payload) => {
  const { data } = await crudService.post(`ingressos/sorteio/usuarios`, payload)
  return data
}

export default function useUsersDrawTicket(payload: Payload) {
  return useQuery<number>(
    ['usersDrawTicket', payload],
    () => postUsersDrawTicket(payload),
    {
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      enabled:
        payload.loteId !== 0 &&
        (payload.segueEstabelecimento || payload.favoritouEvento),
      onError: (error) => {
        toast.error(
          // @ts-ignore
          error.data || 'Erro ao buscar o número de participantes do sorteio',
          {
            // @ts-ignore
            toastId: error.data || 'errorUsersDrawTicket'
          }
        )
      },
      onSuccess: (data) => {}
    }
  )
}
