import { useQuery } from 'react-query'
import { toast } from 'react-toastify'

//? Own imports
import { crudService } from '_services/'
import {
  MembershipPaymentParams,
  MembershipPaymentReturn,
  UseQueryOptions
} from './member'

const getMembershipPayment = async (
  params?: MembershipPaymentParams
): Promise<MembershipPaymentReturn> => {
  try {
    const { data } = await crudService.get(
      `pagamentos/subscricao/${params?.membershipId}`
    )
    if (data) {
      return data
    } else {
      throw new Error('Invalid response')
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export default function useMembershipPayment(
  params?: MembershipPaymentParams,
  options?: UseQueryOptions<MembershipPaymentReturn>
) {
  return useQuery<MembershipPaymentReturn>(
    ['membershipPayment', params],
    () => getMembershipPayment(params),
    {
      enabled: !!params?.membershipId,
      staleTime: 600000, // 10 minutes
      ...options?.config,
      onError: (error) => {
        toast.error(
          // @ts-ignore
          error?.message || 'Erro ao buscar histórico de transações',
          {
            toastId: 'membershipPayment'
          }
        )
      }
    }
  )
}
