import {
  DialogContentText,
  Typography,
  makeStyles,
  Tooltip
} from '@material-ui/core'
import React from 'react'
import { cpfCnpjMask } from 'utils'
import PropTypes from 'prop-types'
import { CheckCircleTwoTone } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  card: {
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: '5px',
    webkitBorderRadius: '5px',
    mozBorderRadius: '5px',
    padding: 10
  },
  totalLabel: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4)
    }
  },
  radio: { display: 'flex', alignItems: 'flex-end' }
}))

const CardContaBancaria = ({ contaBancaria }) => {
  const classes = useStyles()

  return (
    <div className={classes.card}>
      {contaBancaria !== undefined && (
        <DialogContentText>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              paddingBottom: 10
            }}
          >
            <Typography style={{ paddingRight: 10 }} variant="h5">
              {contaBancaria.padrao
                ? contaBancaria.conta === '0'
                  ? 'Chave Pix padrão selecionada'
                  : 'Conta padrão selecionada:'
                : contaBancaria.conta === '0'
                ? 'Chave Pix selecionada'
                : 'Conta selecionada:'}
            </Typography>
            {contaBancaria.padrao && (
              <Tooltip title="Conta padrão">
                <CheckCircleTwoTone color="primary" fontSize="small" />
              </Tooltip>
            )}
          </div>
          <Typography color="initial">
            <strong>{contaBancaria.descricao}</strong>
          </Typography>
          <>
            {contaBancaria.conta !== '0' ? (
              <>
                <Typography>{`Banco: ${contaBancaria.instituicaoBancaria.nome}`}</Typography>
                <Typography>
                  {`Nome titular: ${contaBancaria.nomeTitular}`}
                </Typography>
                <Typography>
                  {`CPF/CNPJ: ${cpfCnpjMask(contaBancaria.cpfCnpjTitular)}`}
                </Typography>
                <Typography>{`Agência: ${contaBancaria.agencia}`}</Typography>
                <Typography>{`Conta: ${contaBancaria.conta}`}</Typography>
              </>
            ) : (
              <Typography>{`Chave PIX: ${contaBancaria.chavePix}`}</Typography>
            )}
          </>
        </DialogContentText>
      )}
    </div>
  )
}

CardContaBancaria.propTypes = {
  contaBancaria: PropTypes.object
}

export default CardContaBancaria
