import { useQuery } from 'react-query'

//? Own imports
import { crudService } from '_services'
import { FilterQuery, PagedCollectionResponse, User } from 'types/api'

const getUsers = async ({
  page,
  limit,
  term,
  order,
  direction
}: FilterQuery) => {
  const { data } = await crudService.get(
    `usuarios/admin?Page=${page}&Limit=${limit}&Term=${term}&Order=${order}&Direction=${direction}`
  )
  return data
}

export default function useUsers(params: FilterQuery) {
  return useQuery<PagedCollectionResponse<User>>(
    ['users', params.limit, params.term, params.order, params.direction],
    () => getUsers(params),
    {
      enabled: params.term && params.term.length > 2 ? true : false
    }
  )
}
