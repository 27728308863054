import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { eventoAction, estabelecimentoAction, loteAction } from '../../_actions'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  MenuItem,
  Grid,
  Tooltip,
  makeStyles
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { taxaAction } from '../../_actions'
import { history } from '../../utils'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { Page } from 'components'
import { useDidMountEffect } from 'utils/useDidMountEffect'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  paper: {
    ...constants.shadowCard
  }
}))

const TaxaEdit = (props) => {
  const classes = useStyles()
  const {
    match: { params }
  } = props

  const { estabelecimento } = useSelector((state) => state.estabelecimento)
  const { evento, lote } = useSelector((state) => state.evento)
  const taxa = useSelector((state) => state.taxa)
  const dispatch = useDispatch()

  const handleChange = (prop) => (event) => {
    dispatch(taxaAction.onChangeProps(prop, event))
  }

  useEffect(() => {
    dispatch(estabelecimentoAction.getEstabelecimento())
    if (params.id) {
      dispatch(taxaAction.getTaxaById(params.id))
    } else {
      dispatch(taxaAction.clear())
    }
    return () => {
      dispatch(taxaAction.clear())
    }
  }, [])

  useDidMountEffect(() => {
    taxa.estabelecimentoId !== null &&
      taxa.estabelecimentoId !== undefined &&
      taxa.estabelecimentoId !== '' &&
      dispatch(eventoAction.getEventoByEstabelecimento(taxa.estabelecimentoId))
  }, [taxa.estabelecimentoId])

  useDidMountEffect(() => {
    taxa.eventoId !== null &&
      taxa.eventoId !== undefined &&
      taxa.eventoId !== '' &&
      dispatch(loteAction.getLotesByEvento(taxa.eventoId))
  }, [taxa.eventoId])

  const handleSubmit = (event) => {
    event.preventDefault()
    let payload = {
      id: taxa.id,
      percentual: taxa.percentual,
      valor: taxa.valor,
      estabelecimentoId: taxa.estabelecimentoId,
      eventoId: taxa.eventoId,
      loteId: taxa.loteId
    }

    if (params.id) {
      dispatch(taxaAction.editTaxaInfo(params.id, payload))
    } else {
      dispatch(taxaAction.createTaxa(payload))
    }
  }

  return (
    <Page className={classes.root} title="Editar Taxa">
      <div>
        <Grid item md={12} xs={12}>
          <Card className={classes.paper}>
            <ValidatorForm
              className={classes.form}
              id="formTaxa"
              onSubmit={(event) => handleSubmit(event)}
            >
              <CardHeader
                subheader="Cadastro Taxa"
                title={params.id ? 'Editar taxa' : 'Nova taxa'}
              />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6}>
                    <Tooltip
                      disableHoverListener={
                        taxa.valor !== '' && taxa.valor !== null ? false : true
                      }
                      disableFocusListener={
                        taxa.valor !== '' && taxa.valor !== null ? false : true
                      }
                      disableTouchListener={
                        taxa.valor !== '' && taxa.valor !== null ? false : true
                      }
                      title="Campo desabilitado porque o valor foi preenchido"
                    >
                      <TextValidator
                        // validators={['required']}
                        // errorMessages={['Campo obrigatório!']}
                        inputProps={{
                          min: '0.01',
                          step: '0.01'
                        }}
                        className={classes.textField}
                        fullWidth
                        disabled={
                          taxa.valor !== '' && taxa.valor !== null
                            ? true
                            : false
                        }
                        id="percentual"
                        label="Percentual (%) *"
                        onChange={handleChange('percentual')}
                        value={taxa.percentual}
                        variant="outlined"
                        type="number"
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Tooltip
                      disableHoverListener={
                        taxa.percentual !== '' && taxa.percentual !== null
                          ? false
                          : true
                      }
                      disableFocusListener={
                        taxa.percentual !== '' && taxa.percentual !== null
                          ? false
                          : true
                      }
                      disableTouchListener={
                        taxa.percentual !== '' && taxa.percentual !== null
                          ? false
                          : true
                      }
                      title="Campo desabilitado porque o percentual foi preenchido"
                    >
                      <TextValidator
                        // validators={['required', 'minNumber:0']}
                        // errorMessages={[
                        //   'Campo obrigatório!',
                        //   'Valor negativo não pode heim!'
                        // ]}
                        // inputProps={{
                        //   min: '0.01',
                        //   step: '0.01'
                        // }}
                        disabled={
                          taxa.percentual !== '' && taxa.percentual !== null
                            ? true
                            : false
                        }
                        className={classes.textField}
                        fullWidth
                        id="valor"
                        label="Valor em R$ *"
                        onChange={handleChange('valor')}
                        value={taxa.valor}
                        variant="outlined"
                        type="number"
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <SelectValidator
                      validators={['required']}
                      errorMessages={['Campo obrigatório!']}
                      variant="outlined"
                      id="estabelecimentoId"
                      label="Estabelecimento *"
                      className={classes.textField}
                      value={taxa.estabelecimentoId}
                      onChange={handleChange('estabelecimentoId')}
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                      displayEmpty
                      labelId="estabelecimentoId"
                    >
                      <MenuItem disabled value="">
                        <em>Estabelecimento *</em>
                      </MenuItem>
                      <MenuItem value={null}>
                        <strong>Limpar seleção</strong>
                      </MenuItem>
                      {estabelecimento !== undefined &&
                        estabelecimento.map((row) => (
                          <MenuItem key={row.id} value={row.id}>
                            {row.nome + ' - ' + row.endereco}
                          </MenuItem>
                        ))}
                    </SelectValidator>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectValidator
                      variant="outlined"
                      id="eventoId"
                      label="Evento"
                      disabled={taxa.estabelecimentoId > 0 ? false : true}
                      className={classes.textField}
                      value={taxa.eventoId}
                      onChange={handleChange('eventoId')}
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                      displayEmpty
                      labelId="eventoId"
                    >
                      <MenuItem disabled value="">
                        <em>Evento</em>
                      </MenuItem>
                      <MenuItem value={null}>
                        <strong>Limpar seleção</strong>
                      </MenuItem>
                      {evento !== undefined &&
                        evento.map((row) => (
                          <MenuItem key={row.id} value={row.id}>
                            {row.nome}
                          </MenuItem>
                        ))}
                    </SelectValidator>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectValidator
                      variant="outlined"
                      id="loteId"
                      label="Lote"
                      disabled={taxa.eventoId > 0 ? false : true}
                      className={classes.textField}
                      value={taxa.loteId}
                      onChange={handleChange('loteId')}
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                      displayEmpty
                      labelId="loteId"
                    >
                      <MenuItem disabled value="">
                        <em>Lote</em>
                      </MenuItem>
                      <MenuItem value={null}>
                        <strong>Limpar seleção</strong>
                      </MenuItem>
                      {lote !== undefined &&
                        lote.map((row) => (
                          <MenuItem key={row.id} value={row.id}>
                            {row.descricao}
                          </MenuItem>
                        ))}
                    </SelectValidator>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  color="secondary"
                  onClick={() => history.push('/taxas')}
                  variant="outlined"
                >
                  Cancelar
                </Button>
                <Button color="primary" type="submit" variant="outlined">
                  {params.id ? 'Atualizar' : 'Salvar'}
                </Button>
              </CardActions>
            </ValidatorForm>
          </Card>
        </Grid>
      </div>
    </Page>
  )
}
TaxaEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
export default TaxaEdit
