import React from 'react'
import { useSelector } from 'react-redux'
import { LoteList } from '../../components'
import { EventoCard } from './EventoCard'
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core'
import EventAvailableTwoToneIcon from '@material-ui/icons/EventAvailableTwoTone'
import EventBusyTwoToneIcon from '@material-ui/icons/EventBusyTwoTone'
import { Row } from '@mui-treasury/components/flex'
import constants from 'theme/constants'
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 0, 4, 0)
  },
  card: {
    marginBottom: theme.spacing(2)
  },
  paper: {
    ...constants.shadowCard
  }
}))

const EventoSummary = () => {
  const evento = useSelector((state) => state.evento)
  const usuarioEstabelecimento = useSelector(
    (state) => state.usuarioEstabelecimento
  )
  const classes = useStyles()
  const { estado: estados } = useSelector((state) => state.estado)
  const { cidade: cidades } = useSelector((state) => state.cidade)
  const { hashtag: hashtags } = useSelector((state) => state.hashtag)

  function getNameEstadoById(estadoId) {
    const nameEstado = estados.find((estado) => estado.id === estadoId)
    return nameEstado ? nameEstado.nome : ''
  }

  function getNameCidadeById(cidadeId) {
    const nameCidade = cidades.find((cidade) => cidade.id === cidadeId)
    return nameCidade ? nameCidade.nome : ''
  }

  function getHashtagNameById(hashtagId) {
    let hashtagName = ''
    const hashtagItem = hashtags.find((element) => element.id === hashtagId)
    if (hashtagItem !== undefined) {
      hashtagName = hashtagItem.descricao
    }
    return hashtagName
  }

  return (
    <main className={classes.root}>
      <Card className={classes.paper}>
        <CardHeader
          subheader="Nesta última etapa, revise os dados cuidadosamente!"
          title={'4 - Resumo do evento'}
          titleTypographyProps={{ variant: 'h4' }}
        />
        <Divider />
        <CardContent className={classes.card}>
          <Grid container direction="row" justify="space-between" spacing={4}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography gutterBottom variant="h6">
                <b>{'Como seu evento aparecerá na Corujas: '}</b>
              </Typography>
              {/*<EventoCard
                evento={evento}
                estabelecimento={usuarioEstabelecimento}
              />*/}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h6">
                    <b>{'1 - Informações sobre o evento '}</b>
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    <b>Nome: </b> {evento.nome}
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    <b>Data de Início: </b> {evento.dataHoraInicio}
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    <b>Data de Término: </b> {evento.dataHoraFim}
                  </Typography>
                  <Row>
                    <Typography gutterBottom variant="body2">
                      <b>Disponibilizar evento: </b>{' '}
                    </Typography>
                    {evento.disponivel ? (
                      <EventAvailableTwoToneIcon
                        fontSize="small"
                        color="primary"
                      />
                    ) : (
                      <EventBusyTwoToneIcon fontSize="small" color="error" />
                    )}
                  </Row>
                  <Typography gutterBottom variant="body2">
                    <b>Hashtags: </b>{' '}
                    {evento.eventoHashtag.map(
                      (row) =>
                        `#${
                          row.hashtag === undefined
                            ? getHashtagNameById(row.hashtagId)
                            : getHashtagNameById(row.hashtag.id)
                        } `
                    )}
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    <b>Detalhes do Evento: </b>
                  </Typography>
                  <Typography variant="body2" align="justify">
                    {' '}
                    <td
                      dangerouslySetInnerHTML={{
                        __html: evento.detalhes
                      }}
                    />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h6">
                    <b>{'2 - Onde vai ser o evento '}</b>
                  </Typography>
                  {evento.tipoLocalizacao === 'O' ? (
                    <Typography gutterBottom variant="body2">
                      <b>Evento online </b>
                    </Typography>
                  ) : (
                    <>
                      {evento.tipoLocalizacao === 'N' && (
                        <Typography gutterBottom variant="body2">
                          <b>Nome do local: </b> {evento.nomeLocal}
                        </Typography>
                      )}
                      <Typography gutterBottom variant="body2">
                        <b>Estado: </b> {getNameEstadoById(evento.estadoId)}
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        <b>Cidade: </b> {getNameCidadeById(evento.cidadeId)}
                      </Typography>
                      {evento.tipoLocalizacao !== 'A' && (
                        <>
                          <Typography gutterBottom variant="body2">
                            <b>CEP: </b> {evento.cep}
                          </Typography>
                          <Typography gutterBottom variant="body2">
                            <b>Endereço: </b> {evento.endereco}
                          </Typography>
                          <Typography gutterBottom variant="body2">
                            <b>Número: </b> {evento.numero}
                          </Typography>
                          <Typography gutterBottom variant="body2">
                            <b>Bairro: </b> {evento.bairro}
                          </Typography>
                          <Typography gutterBottom variant="body2">
                            <b>Complemento: </b>{' '}
                            {evento.complemento !== null
                              ? evento.complemento
                              : '-'}
                          </Typography>
                        </>
                      )}
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <LoteList />
    </main>
  )
}

export default EventoSummary
