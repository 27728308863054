import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Page } from 'components'
import { ContaBancariaList, ContaBancariaEdit, PixEdit } from './components'
import useRouter from 'utils/useRouter'

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(4, 0, 2, 0) }
}))

const ContasBancaria = () => {
  const classes = useStyles()
  const { match } = useRouter()
  const { path } = match

  return (
    <Page className={classes.root} title="ContasBancaria">
      {path && path.includes('pix') ? (
        <PixEdit />
      ) : path.includes('cadastro') ? (
        <ContaBancariaEdit />
      ) : (
        <ContaBancariaList />
      )}
    </Page>
  )
}

export default ContasBancaria
