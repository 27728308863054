import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  useTheme
} from '@material-ui/core'
import { Ingresso } from 'types/api'
import { formatCurrency, getGenderFull, getStatusIngressoColor } from 'utils'
import { Label } from 'components'
import constants from 'theme/constants'

const useStyles = makeStyles(() => ({
  root: {
    ...constants.shadowCard
  },
  content: {
    padding: 0
  }
}))

const OrderTickets = ({ tickets }: { tickets: Ingresso[] }) => {
  const classes = useStyles()
  const theme = useTheme()
  const getTotalWithDiscount = (item: Ingresso) => {
    if (item.valorDesconto && item.valorDesconto > 0) {
      return (
        <Typography display="inline">
          <Typography
            display="inline"
            color="error"
            style={{
              textDecoration: 'line-through'
            }}
          >
            {formatCurrency(item.valor)}
          </Typography>
          {' - '}
          <Typography display="inline">
            {formatCurrency(item.valor - item.valorDesconto)}
          </Typography>
        </Typography>
      )
    } else {
      return <Typography>{formatCurrency(item.valor)}</Typography>
    }
  }
  const textLoteFormatted = (item: Ingresso) => {
    if (item.lote) {
      return `1x ${item.lote.tipoIngresso?.descricao}${' '}
            - ${item.lote?.descricao} (${getGenderFull(item.lote?.genero)})`
    }
    return 'Lote não encontrado'
  }

  return (
    <Card className={classes.root}>
      <CardHeader title="Ingressos gerados" />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Lote</TableCell>
                <TableCell>Valor (R$)</TableCell>
                <TableCell align="right">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tickets.map((item) => (
                <TableRow key={item.id} hover>
                  <TableCell>{textLoteFormatted(item)}</TableCell>
                  <TableCell>{getTotalWithDiscount(item)}</TableCell>
                  <TableCell align="right">
                    {item.statusIngresso && (
                      <Label
                        color={getStatusIngressoColor(item.statusIngresso)}
                        variant="outlined"
                      >
                        {item.statusIngresso.descricao}
                      </Label>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  )
}

export default OrderTickets
