import React from 'react'
import { Tooltip, makeStyles } from '@material-ui/core'
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    paddingLeft: theme.spacing(1)
  },
  icon: {
    fontSize: 14,
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'help'
    }
  }
}))

type Props = {
  children: NonNullable<React.ReactNode>
} & React.HTMLAttributes<HTMLDivElement>

const HelperToolTip = ({ children, ...rest }: Props) => {
  const classes = useStyles()

  return (
    <div {...rest} className={classes.root}>
      <Tooltip
        arrow
        placement="top"
        enterTouchDelay={0}
        leaveTouchDelay={3000}
        title={children}
      >
        <HelpOutlineRoundedIcon className={classes.icon} />
      </Tooltip>
    </div>
  )
}

export default HelperToolTip
