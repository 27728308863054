import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { parametroAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import TitleContent from '../TitleContent'
import { Page } from 'components'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone'
import EmptyFirstParametro from './components/EmptyFirstParametro/EmptyFirstParametro'
import constants from 'theme/constants'
const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  paper: {
    ...constants.shadowCard
  }
})

const Parametro = (props) => {
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { parametro } = props.parametro

  useEffect(() => {
    dispatch(parametroAction.getParametro())
  }, [dispatch])

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir esta Parâmetro?',
      description: 'Confirmando essa operação, este parâmetro não valerá mais.',
      confirmationText: 'Sim, excluir!',
      cancellationText: 'Nãããoo!'
    }).then(() => {
      dispatch(parametroAction.deleteParametroById(id))
    })
  }

  return (
    <Page className={classes.root} title="Parâmetros">
      <AccessControl
        rule={'parametros.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {undefined !== parametro && parametro.length > 0 ? (
              <TitleContent
                rule={'parametros.create'}
                length={parametro.length}
                subTitle={'GERÊNCIA DE PARÂMETROS'}
                title={'Lista de parâmetros'}
                href={'/parametro'}
              />
            ) : (
              <TitleContent
                rule={'parametros.create'}
                length={0}
                subTitle={'GERÊNCIA DE PARÂMETROS'}
                title={'Lista de parâmetros'}
                href={'/parametro'}
              />
            )}
            {undefined !== parametro && parametro.length > 0 ? (
              <TableContainer component={Paper} className={classes.paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Chave</TableCell>
                      <TableCell>Valor</TableCell>
                      <TableCell>Descrição</TableCell>
                      <TableCell align="right">Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {parametro.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row">
                            {n.chave}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.valor}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.descricao}
                          </TableCell>
                          <TableCell align="right">
                            <AccessControl
                              rule={'parametros.edit'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  size="small"
                                  aria-label="Edit"
                                  component="a"
                                  onClick={() =>
                                    history.push(`/parametros/${n.id}`)
                                  }
                                >
                                  <Tooltip title="Editar">
                                    <EditIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              rule={'parametros.delete'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  size="small"
                                  aria-label="Delete"
                                  onClick={() => handleClick(n.id)}
                                >
                                  <Tooltip title="Excluir">
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <EmptyFirstParametro />
            )}
          </div>
        )}
      />
    </Page>
  )
}
Parametro.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    parametro: state.parametro
  }
}
const connectedParametroPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Parametro))
)
export { connectedParametroPage as Parametro }
