let token = localStorage.getItem('token')
let expires = localStorage.getItem('expires')
let user = JSON.parse(localStorage.getItem('user'))

const initialState = user
  ? { refreshed: false, loggedIn: true, token, expires, user }
  : {}

export function authentication(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return {
        ...state,
        loggingIn: true,
        user: action.user
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        loggedIn: true,
        loggingIn: false,
        user: action.user,
        token: action.token,
        expires: action.expires
      }
    case 'LOGIN_FAILURE':
      return {}
    case 'AUTHENTICATION_REFRESH_REQUEST':
      return {
        ...state,
        refreshed: false,
        token: action.token
      }
    case 'AUTHENTICATION_REFRESH_SUCCESS':
      return {
        ...state,
        loggedIn: true,
        refreshed: true,
        loggingIn: false,
        user: action.user
      }
    case 'AUTHENTICATION_REFRESH_FAILURE':
      return {
        ...state,
        refreshed: true,
        loggedIn: false,
        user: null,
        token: null,
        expires: null
      }
    case 'VERIFICATION_USER_SUCCESS':
      return {
        ...state,
        user: { ...state.user, emailVerificado: true }
      }
    case 'EDIT_USER_SUCCESS':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user
        }
      }
    case 'UPDATE_USER_DETAILS':
      return {
        ...state,
        user: action.user
      }
    default:
      return state
  }
}
