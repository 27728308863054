import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core'
import MaiIcon from '@material-ui/icons/MailOutline'
import { toLocalDateFormat } from 'utils'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    ...constants.shadowCard
  },
  content: {},
  sendButton: {
    marginTop: theme.spacing(2)
  },
  mailIcon: {
    marginRight: theme.spacing(1)
  },
  table: {
    marginTop: theme.spacing(2)
  },
  cell: {
    padding: theme.spacing(1)
  }
}))
const OtherActions = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()

  const options = ['Reenviar email de confirmação', 'Enviar comprovante']

  const [option, setOption] = useState(options[0])

  const handleChange = (event) => {
    event.persist()

    setOption(event.target.value)
  }

  return (
    <Card {...rest} className={clsx(classes.paper, className)}>
      <CardHeader title="Outras ações" />
      <Divider />
      <CardContent className={classes.content}>
        <TextField
          fullWidth
          name="option"
          onChange={handleChange}
          select
          // eslint-disable-next-line react/jsx-sort-props
          SelectProps={{ native: true }}
          value={option}
          variant="outlined"
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </TextField>
        <Button className={classes.sendButton} variant="contained">
          <MaiIcon className={classes.mailIcon} />
          Enviar email
        </Button>
        <Table className={classes.table}>
          <TableBody>
            <TableRow key={64564}>
              <TableCell className={classes.cell}>
                {toLocalDateFormat(new Date())}
              </TableCell>
              <TableCell className={classes.cell}>emailenviadopara</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

OtherActions.propTypes = {
  className: PropTypes.string
}

export default OtherActions
