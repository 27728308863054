import { isPast, isAfter, isBefore } from 'date-fns'
import toISODateFormat from './toISODateFormat'

export default function validateDates(dataInicio, dataFim) {
  var isValid = true
  if (
    isAfter(
      new Date(toISODateFormat(dataInicio)),
      new Date(toISODateFormat(dataFim))
    ) ||
    isBefore(
      new Date(toISODateFormat(dataFim)),
      new Date(toISODateFormat(dataInicio))
    )
  ) {
    isValid = false
  } else if (
    isPast(new Date(toISODateFormat(dataFim))) ||
    isPast(new Date(toISODateFormat(dataInicio)))
  ) {
    isValid = false
  }
  return isValid
}
