import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  Tooltip,
  Paper,
  Typography,
  IconButton
} from '@material-ui/core'
import { formatCurrency } from 'utils'
import VisibilityOffTwoToneIcon from '@material-ui/icons/VisibilityOffTwoTone'
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone'
import { Skeleton } from '@material-ui/lab'
import { Navigation } from '..'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  tab: {
    width: '100%',
    // maxWidth: theme.breakpoints.values.md,
    ...constants.shadowCard,
    padding: 10
  },
  divMiddlePaper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  middlePaper: {
    position: 'relative',
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    boxShadow: 'rgb(0 0 0 / 8%) 0px 2px 16px',
    height: '100px',
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(40),
      marginBottom: theme.spacing(-5)
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(4)
    }
  }
}))

export default function CardSaldo() {
  const classes = useStyles()
  const { tab } = useParams()
  const saldo = useSelector(
    (state) =>
      state.ingresso.ingresso.reduce((a, b) => a + (b['valor'] || 0), 0) -
      state.ingresso.ingresso.reduce(
        (a, b) => a + (b['valorDesconto'] || 0),
        0
      ) -
      state.ingresso.ingresso.reduce((a, b) => a + (b['valorTaxa'] || 0), 0)
  )
  const saldoALiberar = useSelector(
    (state) =>
      state.ingresso.ingressoALiberar.reduce(
        (a, b) => a + (b['valor'] || 0),
        0
      ) -
      state.ingresso.ingressoALiberar.reduce(
        (a, b) => a + (b['valorDesconto'] || 0),
        0
      ) -
      state.ingresso.ingressoALiberar.reduce(
        (a, b) => a + (b['valorTaxa'] || 0),
        0
      )
  )

  const { isLoading } = useSelector((state) => state.ingresso)
  const { showValues } = useSelector((state) => state.global)
  const [visible, setVisible] = useState(showValues)

  useEffect(() => {
    setVisible(showValues)
  }, [showValues])

  return (
    <div className={classes.root}>
      <div className={classes.divMiddlePaper}>
        <Paper className={classes.middlePaper}>
          <Typography
            style={{ margin: 10 }}
            gutterBottom
            variant="h4"
            color="textSecondary"
          >
            {tab === 'aliberar' ? 'Saldo a liberar:' : 'Saldo:'}
          </Typography>
          <div style={{ display: 'flex', width: '100%' }}>
            <Typography style={{ margin: 10 }} variant="h3" color="textPrimary">
              {visible && !isLoading ? (
                formatCurrency(tab === 'aliberar' ? saldoALiberar : saldo)
              ) : (
                <Skeleton
                  animation={isLoading ? 'pulse' : 'false'}
                  width={100}
                />
              )}
            </Typography>
            <Tooltip title={visible ? 'Ocultar saldo' : 'Mostrar saldo'}>
              <IconButton
                aria-label="esconder/mostrar saldo"
                onClick={() => setVisible(!visible)}
              >
                {visible ? (
                  <VisibilityTwoToneIcon color="primary" />
                ) : (
                  <VisibilityOffTwoToneIcon />
                )}
              </IconButton>
            </Tooltip>
          </div>
        </Paper>
      </div>
      <Navigation />
    </div>
  )
}
