import React, { useEffect } from 'react'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  makeStyles,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { contaBancariaAction } from '_actions'
import { useParams } from 'react-router-dom'
import { cpfCnpjMask, history } from 'utils'
import { HelperToolTip } from 'components'
import { Page } from 'components'
import constants from 'theme/constants'
import { cnpj, cpf } from 'cpf-cnpj-validator'

const useStyles = makeStyles(() => ({
  root: {},
  paper: {
    ...constants.shadowCard
  }
}))

const PixEdit = () => {
  const dispatch = useDispatch()
  const { idContaBancaria } = useParams()
  const classes = useStyles()
  const contaBancaria = useSelector((state) => state.contaBancaria)
  const estabelecimentoId = useSelector(
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const tipoChavePix = []
  tipoChavePix.push({ id: 0, tipo: 'CPF/CNPJ' })
  tipoChavePix.push({ id: 1, tipo: 'Email' })
  tipoChavePix.push({ id: 2, tipo: 'Nº Celular' })
  tipoChavePix.push({ id: 3, tipo: 'Chave Aleatória' })

  const handleChange = (prop) => (event) => {
    dispatch(contaBancariaAction.onChangeProps(prop, event))
  }

  useEffect(() => {
    if (idContaBancaria) {
      dispatch(contaBancariaAction.getContaBancariaById(idContaBancaria))
    } else {
      dispatch(contaBancariaAction.clear())
    }
    ValidatorForm.addValidationRule('isValidCpf', (value) => {
      if (value && value.length <= 14) {
        if (cpf.isValid(value)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    })

    ValidatorForm.addValidationRule('isValidCnpj', (value) => {
      if (value && value.length >= 15) {
        if (cnpj.isValid(value)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    })
    return () => {
      ValidatorForm.removeValidationRule('isValidCpf')
      ValidatorForm.removeValidationRule('isValidCnpj')
      dispatch(contaBancariaAction.clear())
    }
  }, [])

  const handleSubmit = () => {
    let payload = {
      id: idContaBancaria,
      estabelecimentoId: estabelecimentoId,
      descricao: contaBancaria.descricao,
      agencia: 0,
      conta: 0,
      nomeTitular: contaBancaria.nomeTitular,
      instituicaoBancariaId: 1,
      padrao: contaBancaria.padrao,
      cpfCnpjTitular: contaBancaria.cpfCnpjTitular
        .split('.')
        .join('')
        .replace(/[-/.]/g, ''),
      chavePix: contaBancaria.chavePix,
      tipoChavePix: contaBancaria.tipoChavePix
    }

    if (idContaBancaria) {
      dispatch(
        contaBancariaAction.editContaBancariaInfo(idContaBancaria, payload)
      )
    } else {
      dispatch(contaBancariaAction.createContaBancaria(payload))
    }
  }

  return (
    <Page
      className={classes.root}
      title={
        idContaBancaria
          ? 'Carteira - Contas Bancárias - Editar Chave Pix'
          : 'Carteira - Contas Bancárias - Nova Chave Pix'
      }
    >
      <Grid item md={12} xs={12}>
        <Card className={classes.paper}>
          <ValidatorForm
            className={classes.form}
            id="formContaBancaria"
            onSubmit={(event) => handleSubmit(event)}
          >
            <CardHeader
              title="Cadastro de Chave Pix"
              subheader={
                idContaBancaria ? 'Alteração de chave pix' : 'Nova chave pix'
              }
            />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <TextValidator
                    variant="outlined"
                    id="descricao"
                    label="Descrição *"
                    className={classes.textField}
                    value={contaBancaria.descricao}
                    onChange={handleChange('descricao')}
                    placeholder="Conta pessoal,  Conta do banco, etc"
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <TextValidator
                    variant="outlined"
                    id="chave-pix"
                    label="Chave Pix *"
                    className={classes.textField}
                    value={contaBancaria.chavePix}
                    onChange={handleChange('chavePix')}
                    placeholder="Chave aleatória, cpf/cnpj, celular..."
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <SelectValidator
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    variant="outlined"
                    id="tipoChavePix"
                    label="Tipo Chave PIX *"
                    value={contaBancaria.tipoChavePix}
                    onChange={handleChange('tipoChavePix')}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    labelId="tipoChavePix"
                  >
                    <MenuItem disabled value="">
                      <em>Tipo Chave Pix *</em>
                    </MenuItem>
                    {tipoChavePix.map((row) => (
                      <MenuItem key={row.id} value={row.id}>
                        {row.tipo}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextValidator
                    variant="outlined"
                    id="nome-titular"
                    label="Nome Titular *"
                    className={classes.textField}
                    value={contaBancaria.nomeTitular}
                    onChange={handleChange('nomeTitular')}
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextValidator
                    variant="outlined"
                    fullWidth
                    id="cpfCnpjTitular"
                    label="CPF/CNPJ Nota Fiscal *"
                    name="cpfCnpj"
                    type="text"
                    inputProps={{
                      maxlength: '18'
                    }}
                    value={cpfCnpjMask(contaBancaria.cpfCnpjTitular)}
                    onChange={handleChange('cpfCnpjTitular')}
                    autoComplete="cpf"
                    validators={['required', 'isValidCpf', 'isValidCnpj']}
                    errorMessages={[
                      'Campo obrigatório!',
                      'CPF inválido!',
                      'CNPJ inválido!'
                    ]}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={contaBancaria.padrao}
                        onChange={handleChange('padrao')}
                        color="primary"
                      />
                    }
                    label={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        Padrão
                        <HelperToolTip>
                          Se selecionado, todos os depósitos serão feitos nessa
                          conta!
                        </HelperToolTip>
                      </div>
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                onClick={() => history.push('/carteira/contasBancarias')}
                color="secondary"
                variant="outlined"
              >
                Cancelar
              </Button>
              <Button type="submit" color="primary" variant="outlined">
                {idContaBancaria ? 'Atualizar' : 'Salvar'}
              </Button>
            </CardActions>
          </ValidatorForm>
        </Card>
      </Grid>
    </Page>
  )
}

export default PixEdit
