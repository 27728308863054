import React from 'react'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  makeStyles
} from '@material-ui/core'
import ListAltTwoToneIcon from '@material-ui/icons/ListAltTwoTone'
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import constants from 'theme/constants'
import { useParams } from 'react-router-dom'
import { useEventDashboard } from 'hooks-querys'
var pluralize = require('pluralize')

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    ...constants.shadowCard
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 46,
    width: 46
  },
  icon: {
    height: 22,
    width: 22
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  }
}))

const PedidosPendentes = () => {
  const classes = useStyles()

  const { eventoId } = useParams<{ eventoId: string }>()
  const { data, isFetching } = useEventDashboard(Number(eventoId))
  const { quantidadePedidosPendentes, valorPedidosPendentes } = data || {}

  // @ts-ignore
  const { showValues } = useSelector((state) => state.global)
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              PEDIDOS PENDENTES
            </Typography>
            <Typography variant="h4">
              {isFetching || !showValues ? (
                <Skeleton animation={isFetching ? 'pulse' : false} />
              ) : (
                pluralize('PEDIDOS', quantidadePedidosPendentes, true)
              )}
            </Typography>
            <Typography variant="body2">TOTALIZANDO</Typography>
            <Typography variant="h3">
              {isFetching || !showValues ? (
                <Skeleton animation={isFetching ? 'pulse' : false} />
              ) : (
                Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(valorPedidosPendentes || 0)
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <ListAltTwoToneIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        {/* <div className={classes.difference}>
          <Typography className={classes.caption} variant="caption">
            Desde de 1 de janeiro de 2020
          </Typography>
        </div> */}
      </CardContent>
    </Card>
  )
}

export default PedidosPendentes
