import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Grid, Typography } from '@material-ui/core'
import Image from 'material-ui-image'
import { history } from 'utils'
const useStyles = makeStyles((theme) => ({
  root: {},
  noResultText: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    textAlign: 'center'
  },
  image: { marginTop: theme.spacing(1) },
  button: {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2)
    },
    webkitTransition: ' all 300ms ease',
    transition: 'all 300ms ease',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0 9px 40px -14px #FFC20A',
      webkitTransition: ' all 300ms ease',
      transition: 'all 300ms ease',
      letterSpacing: '1.2px'
    }
  }
}))
const EmptyFirstContaBancaria = () => {
  const classes = useStyles()

  const handleClickContaBancaria = () => {
    history.push(`/carteira/contasBancarias/cadastro`)
  }

  const handleClickPix = () => {
    history.push(`/carteira/contasBancarias/pix`)
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
        direction="column"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
      >
        <Grid item>
          <Image
            className={classes.image}
            disableTransition
            style={{ width: '300px', height: '120px' }}
            color="transparent"
            src="/images/bank-account.svg"
            aspectRatio={16 / 9}
          />
        </Grid>
        <Grid item>
          <Typography
            className={classes.noResultText}
            gutterBottom
            variant="h4"
            color="textPrimary"
          >
            Você ainda não cadastrou nenhuma conta bancária
          </Typography>
        </Grid>
        <Grid alignContent="center" item>
          <Button
            className={classes.button}
            variant="contained"
            onClick={handleClickContaBancaria}
            color="primary"
          >
            Cadastrar conta! 💰
          </Button>
        </Grid>
        <Grid alignContent="center" item>
          <Button
            className={classes.button}
            variant="contained"
            onClick={handleClickPix}
            color="primary"
          >
            Cadastrar pix! 💰
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default EmptyFirstContaBancaria
