import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors,
  makeStyles
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

//? Own imports
import { Label } from 'components'
import { UserEdit } from './components'
import { cepMask, cpfCnpjMask, getGenderFull, phoneMask } from 'utils'
import { User } from 'types/api'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    ...constants.shadowCard
  },
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  label: {
    marginTop: theme.spacing(1)
  }
}))

type UserInfoProps = {
  user: User
}

const UserInfo = ({ user }: UserInfoProps) => {
  const classes = useStyles()
  const [openEdit, setOpenEdit] = useState(false)

  const handleEditOpen = () => {
    setOpenEdit(true)
  }

  const handleEditClose = () => {
    setOpenEdit(false)
  }

  return (
    <Card className={classes.root}>
      <CardHeader title="Informações cadastrais" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>#{user?.id}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Nome</TableCell>
              <TableCell>{user?.nome}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Apelido</TableCell>
              <TableCell>
                {user?.apelido ? user?.apelido : 'Não informado'}
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Nome do usuário</TableCell>
              <TableCell>@{user?.username}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>CPF/CNPJ</TableCell>
              <TableCell>{cpfCnpjMask(user?.cpfCnpj)}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Data de nascimento</TableCell>
              <TableCell>
                {user?.dataNascimento
                  ? `${user?.dataNascimento
                      .toString()
                      .split('-')[2]
                      .substring(0, 2)}/${
                      user?.dataNascimento.toString().split('-')[1]
                    }/${user?.dataNascimento.toString().split('-')[0]}`
                  : 'Não informada'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>
                {user?.email}
                <div className={classes.label}>
                  <Label
                    color={
                      user?.emailVerificado
                        ? colors.green[600]
                        : colors.orange[600]
                    }
                  >
                    {user?.emailVerificado
                      ? 'Email verificado'
                      : 'Email não verificado'}
                  </Label>
                </div>
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Telefone</TableCell>
              <TableCell>
                {user?.telefone ? phoneMask(user?.telefone) : 'Não informado'}
                {user?.telefone && (
                  <div className={classes.label}>
                    <Label
                      color={
                        user?.telefoneVerificado
                          ? colors.green[600]
                          : colors.orange[600]
                      }
                    >
                      {user?.telefoneVerificado
                        ? 'Telefone verificado'
                        : 'Telefone não verificado'}
                    </Label>
                  </div>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Género</TableCell>
              <TableCell>{getGenderFull(user?.genero)}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Endereço</TableCell>
              <TableCell>
                {user?.endereco
                  ? `${user?.endereco}, ${user?.numero}${
                      user?.complemento ? `, ${user?.complemento}` : ''
                    } - ${user?.bairro} - ${cepMask(user?.cep)}`
                  : 'Não informado'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cidade</TableCell>
              <TableCell>
                {user && user.cidade && user?.cidade?.nome} -{' '}
                {user && user.cidade && user?.cidade?.estado?.uf}
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Pais</TableCell>
              <TableCell>
                {user?.cidade?.estado?.pais?.nomePt || 'Não informado'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button startIcon={<EditIcon />} onClick={handleEditOpen}>
          Editar
        </Button>
      </CardActions>
      <UserEdit user={user} onClose={handleEditClose} open={openEdit} />
    </Card>
  )
}

export default UserInfo
