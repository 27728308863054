import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogContent,
  makeStyles,
  Slide,
  useTheme,
  useMediaQuery,
  Zoom,
  Box
} from '@material-ui/core'

//? Own imports
import { DrawingAnimation, WinnerAnimation } from './components'
import { useMutationDrawTicket } from 'hooks-querys'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('sm')]: {
      '& .MuiDialog-paper': {
        borderRadius: '8px',
        webkitBorderRadius: '8px',
        mozBorderRadius: '8px',
        overflow: 'hidden',
        overflowY: 'hidden',
        overflowX: 'hidden'
      }
    }
  }
}))

type ModalDrawTicketProps = {
  openModal: boolean
  setOpenModal: (open: boolean) => void
  loteId: number
  segueEstabelecimento: boolean
  favoritouEvento: boolean
}
export default function ModalSolicitarRetirada({
  openModal,
  setOpenModal,
  loteId,
  segueEstabelecimento,
  favoritouEvento
}: ModalDrawTicketProps) {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [isDrawingAnimation, setIsDrawingAnimation] = useState(false)
  const mutationDrawTicket = useMutationDrawTicket()

  const handleClose = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    if (openModal) {
      setIsDrawingAnimation(true)
      mutationDrawTicket
        .mutateAsync({
          loteId: loteId || 0,
          favoritouEvento: favoritouEvento,
          segueEstabelecimento: segueEstabelecimento
        })
        .then(() => {
          setTimeout(() => {
            setIsDrawingAnimation(false)
          }, 3000)
        })
        .catch(() => {
          setIsDrawingAnimation(false)
        })
    }
  }, [openModal])

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      // @ts-ignore
      TransitionComponent={Transition}
      open={openModal}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      className={classes.dialog}
    >
      <DialogContent dividers>
        <Box
          height={'100%'}
          minHeight={'100%'}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {isDrawingAnimation && (
            <Zoom mountOnEnter unmountOnExit in={isDrawingAnimation}>
              <DrawingAnimation />
            </Zoom>
          )}
          <Zoom
            mountOnEnter
            unmountOnExit
            in={
              !isDrawingAnimation &&
              mutationDrawTicket.isSuccess &&
              mutationDrawTicket.data !== undefined
            }
          >
            <WinnerAnimation user={mutationDrawTicket.data} />
          </Zoom>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
