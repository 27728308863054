import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { eventoAction, categoriaAction, hashtagAction } from '_actions'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Switch,
  FormControlLabel,
  makeStyles,
  Typography,
  MenuItem,
  TextField,
  useTheme,
  InputAdornment,
  Tooltip,
  IconButton
} from '@material-ui/core'
import { ImageCrop } from '../../../../components'
import { HelperToolTip, ValidatedDatePicker } from 'components/'
import { toISODateFormat, validateDates } from 'utils'
import EventoEditLocale from '../EventoEditLocale'
import { toast } from 'react-toastify'
import { isValid } from 'date-fns'
import Geocode from 'react-geocode'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useConfirm } from 'material-ui-confirm'
import YoutubeSearchedForRoundedIcon from '@material-ui/icons/YoutubeSearchedForRounded'
import constants from 'theme/constants'

Geocode.setApiKey(process.env.REACT_APP_MAPS_KEY)
Geocode.setLanguage('pt-BR')
Geocode.setRegion('pt-BR')

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 0, 4, 0)
  },
  divider: {
    padding: theme.spacing(2, 0, 2, 0)
  },
  card: {
    marginBottom: theme.spacing(2),
    ...constants.shadowCard
  }
}))

const EventoEdit = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const confirm = useConfirm()
  const theme = useTheme()
  const { estabelecimento } = useSelector((state) => state.estabelecimento)
  const { evento, cidade, estado } = useSelector((state) => state)
  const usuarioEstabelecimento = useSelector(
    (state) => state.usuarioEstabelecimento
  )
  const { categoria } = useSelector((state) => state.categoria)
  const { hashtag } = useSelector((state) => state.hashtag)
  const minHashtags = 1
  const maxHashtags = 5
  let eventoHashtagLength = 0
  if (evento.eventoHashtag !== (null || undefined)) {
    eventoHashtagLength = evento.eventoHashtag.length
  }
  const getURL = (url) => {
    dispatch(eventoAction.onChangeProps('imagem', url))
  }

  const handleChange = (prop) => (event) => {
    dispatch(eventoAction.onChangeProps(prop, event))
  }

  const hashtagPrepareData = (eventoHashtag) => {
    let readyEventoHashtag = []
    eventoHashtag.map((row) => {
      readyEventoHashtag.push({
        id: 0,
        eventoId: 0,
        hashtagId: row.id,
        excluido: false
      })
    })
    return readyEventoHashtag
  }

  const handleOnChangeHashtag = (event) => {
    dispatch(eventoAction.handleOnChangeHashtag(hashtagPrepareData(event)))
  }

  const generateSlug = () => {
    dispatch(eventoAction.onChangeProps('generate-slug', evento.nome))
  }

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidNome', (value) => {
      if (value !== '' && value !== null) {
        if (value.length >= 4) {
          return true
        }
        return false
      }
      return false
    })
    ValidatorForm.addValidationRule('isValidDate', (value) => {
      return isValid(new Date(toISODateFormat(value)))
    })
    dispatch(categoriaAction.getCategoria())
    dispatch(hashtagAction.getHashtags('', '', '', '', ''))
    return () => {
      ValidatorForm.removeValidationRule('isValidNome')
      ValidatorForm.removeValidationRule('isValidDate')
    }
  }, [])

  const handleChangeData = (prop, newValue) => {
    dispatch(eventoAction.onChangeData(prop, newValue))
  }

  const prepareAddressFromState = (evento, cidade, estado) => {
    var estadoSelected = estado.find((item) => item.id === evento.estadoId)
    var cidadeSelected = cidade.find((item) => item.id === evento.cidadeId)
    return `${evento.endereco}, ${evento.numero} - ${evento.bairro} , ${cidadeSelected.nome} - ${estadoSelected.uf}, ${evento.cep}`
  }

  const generatePayload = async () => {
    let payload = {
      id: evento.id,
      nome: evento.nome,
      imagem: evento.imagem,
      estabelecimentoId: usuarioEstabelecimento.estabelecimentoId,
      detalhes: evento.detalhes,
      dataHoraInicio: toISODateFormat(evento.dataHoraInicio),
      dataHoraFim: toISODateFormat(evento.dataHoraFim),
      categoriaId: evento.categoriaId,
      disponivel: evento.disponivel,
      cidadeId: evento.cidadeId,
      endereco: evento.endereco,
      cep: evento.cep,
      bairro: evento.bairro,
      latLon: evento.latLon,
      slug: evento.slug,
      tipoLocalizacao: evento.tipoLocalizacao,
      nomeLocal: evento.nomeLocal,
      complemento: evento.complemento,
      numero: evento.numero,
      eventoHashtag: evento.eventoHashtag
    }
    if (evento.tipoLocalizacao === 'N') {
      await Geocode.fromAddress(
        prepareAddressFromState(evento, cidade.cidade, estado.estado)
      ).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location
          let latLonResult = {
            x: lat,
            y: lng
          }
          dispatch(eventoAction.changeCoordinates(latLonResult))
          payload = { ...payload, latLon: latLonResult }
        },
        (error) => {
          console.error(error)
        }
      )
    }
    return payload
  }

  const dispatchAfterGeneratePayload = async () => {
    const payload = await generatePayload()
    dispatch(eventoAction.editEventoInfo(evento.id, payload))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (!validateDates(evento.dataHoraInicio, evento.dataHoraFim)) {
      toast.error('Opss! 😥 Datas incorretas, revise por favor!')
      window.scrollTo(0, 250)
    } else if (evento.imagem === '') {
      toast.error('Você precisa adicionar uma imagem para o evento!')
      window.scrollTo(0, 500)
    } else if (evento.detalhes === '') {
      toast.error(
        'Você precisa adicionar detalhes sobre o evento! Dica: Quanto mais informações, melhor 😉'
      )
      window.scrollTo(0, 1000)
    } else if (evento.eventoHashtag.length === 0) {
      toast.error('Você precisa adicionar ao menos uma hashtag!')

      window.scrollTo(0, 250)
    } else if (evento.id) {
      dispatchAfterGeneratePayload()
    } else {
      dispatch(eventoAction.setNextStep())
    }
  }

  function getCategoriaName(categoriaId) {
    const categoriaItem = categoria.find((element) => element.id == categoriaId)
    if (categoriaItem !== (undefined && null)) {
      return categoriaItem.descricao
    }
  }

  function getHashtagObjectById(hashtagId) {
    let hashtagObject = ''
    const hashtagItem = hashtag.find((element) => element.id == hashtagId)
    if (hashtagItem !== undefined) {
      hashtagObject = hashtagItem
    }
    return hashtagObject
  }

  function getHashtagsObjects(eventoHashtag) {
    let hashtags = []
    if (eventoHashtag === [] || eventoHashtag === undefined) {
      hashtags = []
    } else {
      eventoHashtag.forEach((item) =>
        item.hashtag === undefined
          ? hashtags.push(getHashtagObjectById(item.hashtagId))
          : hashtags.push(getHashtagObjectById(item.hashtag.id))
      )
    }
    return hashtags
  }

  return (
    <div className={classes.root}>
      <Grid item md={12} xs={12}>
        <ValidatorForm instantValidate id="formEvento" onSubmit={handleSubmit}>
          <Card className={classes.card}>
            <CardHeader
              subheader="Adicione uma imagem e os detalhes com as principais informações do evento!"
              title={'1 - Informações sobre o evento'}
              titleTypographyProps={{ variant: 'h4' }}
            />
            <Divider />
            <CardContent>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={8}>
                  <TextValidator
                    variant="outlined"
                    id="nome"
                    label="Nome do evento *"
                    value={evento.nome}
                    onChange={handleChange('nome')}
                    fullWidth
                    validators={['required', 'isValidNome']}
                    errorMessages={[
                      'Campo obrigatório!',
                      'Eii, o nome é muito curto!'
                    ]}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={4} md={3} lg={3}>
                  <Typography noWrap variant="h5" color="textSecondary">
                    www.corujasapp.com/
                  </Typography>
                </Grid> */}
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <TextValidator
                    variant="outlined"
                    id="slug"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Gerar slug">
                            <IconButton
                              aria-label="gerar slug automaticamente"
                              onClick={() => generateSlug()}
                            >
                              <YoutubeSearchedForRoundedIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      )
                    }}
                    onFocus={() =>
                      (evento.slug === '' || evento.slug === null) &&
                      generateSlug()
                    }
                    label="Slug *"
                    value={evento.slug}
                    onChange={handleChange('slug')}
                    fullWidth
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <SelectValidator
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    variant="outlined"
                    id="categoriaId"
                    label="Categoria *"
                    value={evento.categoriaId}
                    onChange={handleChange('categoriaId')}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    labelId="categoriaId"
                  >
                    <MenuItem disabled value="">
                      <em>Categoria *</em>
                    </MenuItem>
                    {categoria.map((row) => (
                      <MenuItem key={row.id} value={row.id}>
                        {row.descricao}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <ValidatedDatePicker
                    validators={['required', 'isValidDate']}
                    errorMessages={['Campo obrigatório!', 'Data inválida!']}
                    autoOk
                    id="dataHoraInicio"
                    label="Data/Hora Início *"
                    fullWidth
                    animateYearScrolling
                    inputVariant="outlined"
                    ampm={false}
                    value={evento.dataHoraInicio}
                    inputValue={evento.dataHoraInicio}
                    onChange={(_, newValue) =>
                      handleChangeData('dataHoraInicio', newValue)
                    }
                    disablePast
                    format="dd/MM/yyyy HH:mm"
                    invalidDateMessage="Data inválida!"
                    invalidLabel="dd/MM/aaaa HH:mm"
                    maxDateMessage="A data não deve ser posterior à data máxima"
                    minDateMessage="A data não deve ser anterior à data mínima"
                    cancelLabel="Cancelar"
                    okLabel="Definir"
                    clearable
                    clearLabel="Limpar"
                    placeholder="dd/MM/aaaa HH:mm"
                    todayLabel="Hoje"
                    defaultValue={undefined}
                    openTo="month"
                    views={['month', 'date', 'hours', 'minutes']}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <ValidatedDatePicker
                    validators={['required', 'isValidDate']}
                    errorMessages={['Campo obrigatório!', 'Data inválida!']}
                    autoOk
                    id="dataHoraFim"
                    label="Data/Hora Fim *"
                    animateYearScrolling
                    fullWidth
                    inputVariant="outlined"
                    ampm={false}
                    value={evento.dataHoraFim}
                    inputValue={evento.dataHoraFim}
                    onChange={(_, newValue) =>
                      handleChangeData('dataHoraFim', newValue)
                    }
                    disablePast
                    minDate={toISODateFormat(evento.dataHoraInicio)}
                    initialFocusedDate={toISODateFormat(evento.dataHoraInicio)}
                    format="dd/MM/yyyy HH:mm"
                    invalidDateMessage="Data inválida!"
                    invalidLabel="dd/MM/aaaa HH:mm"
                    maxDateMessage="A data não deve ser posterior à data máxima"
                    minDateMessage="A data não deve ser anterior à data de início"
                    cancelLabel="Cancelar"
                    okLabel="Definir"
                    clearable
                    clearLabel="Limpar"
                    placeholder="dd/MM/aaaa HH:mm"
                    todayLabel="Hoje"
                    defaultValue={undefined}
                    openTo="month"
                    views={['month', 'date', 'hours', 'minutes']}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    value={getHashtagsObjects(evento.eventoHashtag)}
                    onChange={(event, value) => {
                      handleOnChangeHashtag(value)
                    }}
                    multiple
                    getOptionDisabled={(option) =>
                      eventoHashtagLength === maxHashtags ? true : false
                    }
                    filterSelectedOptions
                    limitTags={5}
                    id="hashtag-selection"
                    options={hashtag}
                    getOptionLabel={(option) => `#${option.descricao}`}
                    groupBy={(option) => getCategoriaName(option.categoriaId)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Selecione hashtags *"
                        placeholder="#hashtags"
                        error={eventoHashtagLength < minHashtags && true}
                        helperText={
                          (eventoHashtagLength === maxHashtags &&
                            'Número máximo de hashtags foi atingido!') ||
                          (eventoHashtagLength < minHashtags &&
                            'Selecione ao menos uma hashtag!')
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={evento.disponivel}
                          onChange={handleChange('disponivel')}
                          color="primary"
                        />
                      }
                      label="Disponibilizar evento"
                    />
                    <HelperToolTip>
                      Se ativado, o evento passa a ficar visível no portal da
                      Corujas.
                    </HelperToolTip>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom>Imagem de divulgação *</Typography>
                  <Grid item>
                    <ImageCrop />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.divider}>
                    <Divider />
                  </div>
                  <Typography>{'Detalhes do evento *'}</Typography>
                  <CKEditor
                    config={{
                      language: 'pt-br',
                      removePlugins: ['ImageUpload', 'Table']
                    }}
                    editor={ClassicEditor}
                    data={evento.detalhes}
                    onInit={(editor) => {}}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      dispatch(eventoAction.onChangeDetails(data, event))
                    }}
                    onBlur={(event, editor) => {}}
                    onFocus={(event, editor) => {}}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <EventoEditLocale />
        </ValidatorForm>
      </Grid>
    </div>
  )
}

export default EventoEdit
