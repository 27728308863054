import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

//? Own imports
import { User } from 'types/api'
import { crudService } from '_services'

type Payload = {
  loteId: number
  segueEstabelecimento: boolean
  favoritouEvento: boolean
}

const postDrawTicket = async (payload: Payload) => {
  const { data } = await crudService.post(`ingressos/sorteio`, payload)
  return data
}

export default function useMutationDrawTicket() {
  return useMutation(
    'mutationDrawTicket',
    (payload: Payload) => postDrawTicket(payload),
    {
      onError: (error) => {
        // @ts-ignore
        toast.error(error.message || 'Erro ao gerar ingresso do sorteio', {
          toastId: 'errorDrawTicket'
        })
      },
      onSuccess: (data: User) => {}
    }
  )
}
