import { crudService } from '../_services'

export const dashboardAction = {
  getDashboardHome,
  getDashboardEstabelecimento,
  getDashboardEvento,
  getDashboardAdmin
}

function getDashboardAdmin(dataInicial, dataFinal) {
  const payload = {
    dataInicial,
    dataFinal
  }
  return (dispatch) => {
    dispatch(request())
    let apiEndpoint = 'dashboardAdmin/all'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request() {
    return {
      type: 'FETCHED_DASHBOARD_ADMIN_REQUEST'
    }
  }
  function success(dashboardAdminResponse) {
    return {
      type: 'FETCHED_DASHBOARD_ADMIN_SUCCESS',
      dashboardAdmin: dashboardAdminResponse
    }
  }
  function failure(error) {
    return {
      type: 'FETCHED_DASHBOARD_ADMIN_FAILURE',
      error: error
    }
  }
}

function getDashboardHome(estabelecimentoId) {
  return (dispatch) => {
    dispatch(request({ estabelecimentoId }))
    let apiEndpoint = 'dashboard/estabelecimento/home/' + estabelecimentoId
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request(estabelecimentoId) {
    return { type: 'FETCHED_DASHBOARD_HOME_REQUEST', estabelecimentoId }
  }

  function success(dashboardHomeResponse) {
    return {
      type: 'FETCHED_DASHBOARD_HOME_SUCCESS',
      dashboardHome: dashboardHomeResponse
    }
  }

  function failure(error) {
    return { type: 'FETCHED_DASHBOARD_HOME_FAILURE', error }
  }
}

function getDashboardEstabelecimento(estabelecimentoId) {
  return (dispatch) => {
    dispatch(request({ estabelecimentoId }))
    let apiEndpoint = 'dashboard/estabelecimento/' + estabelecimentoId
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request(estabelecimentoId) {
    return {
      type: 'FETCHED_DASHBOARD_ESTABELECIMENTO_REQUEST',
      estabelecimentoId
    }
  }

  function success(dashboardEstabelecimentoResponse) {
    return {
      type: 'FETCHED_DASHBOARD_ESTABELECIMENTO_SUCCESS',
      dashboardEstabelecimento: dashboardEstabelecimentoResponse
    }
  }

  function failure(error) {
    return { type: 'FETCHED_DASHBOARD_ESTABELECIMENTO_FAILURE', error }
  }
}

function getDashboardEvento(eventoId) {
  return (dispatch) => {
    dispatch(request({ eventoId }))
    let apiEndpoint = 'dashboard/evento/' + eventoId
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request(eventoId) {
    return {
      type: 'FETCHED_DASHBOARD_EVENTO_REQUEST',
      eventoId
    }
  }

  function success(dashboardEventoResponse) {
    return {
      type: 'FETCHED_DASHBOARD_EVENTO_SUCCESS',
      dashboardEvento: dashboardEventoResponse
    }
  }

  function failure(error) {
    return { type: 'FETCHED_DASHBOARD_EVENTO_FAILURE', error }
  }
}
