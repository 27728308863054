import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { eventoAction } from '../../../../_actions'
import {
  LoteList,
  LoteEdit,
  EventoEditForm,
  EventoSummary
} from '../../components'
import Grid from '@material-ui/core/Grid'
import { history } from '../../../../utils'
import { toast } from 'react-toastify'
import { toISODateFormat } from 'utils'
import { Header, Page } from 'components'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import EventTwoToneIcon from '@material-ui/icons/EventTwoTone'
import ConfirmationNumberTwoToneIcon from '@material-ui/icons/ConfirmationNumberTwoTone'
import AddToQueueTwoToneIcon from '@material-ui/icons/AddToQueueTwoTone'
import StepConnector from '@material-ui/core/StepConnector'
import { Box, Paper, StepButton } from '@material-ui/core'
import SocialShare from 'components/Share/Share'
import { onShare } from 'utils/linkShare'
import constants from 'theme/constants'

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,#f7ac52 0%,  rgba(225, 194, 10, 0.8) 80%,rgba(3, 5, 23, 1) 100%)'
    }
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,#f7ac52 0%,  rgba(225, 194, 10, 0.8) 80%,rgba(3, 5, 23, 1) 100%)'
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1
  }
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, #f7ac52 0%,   rgba(225, 194, 10, 0.8) 50%, rgba(225, 194, 10, 1) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, #f7ac52 0%,   rgba(225, 194, 10, 0.8) 50%, rgba(225, 194, 10, 1) 100%)'
  }
})

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props

  const icons = {
    1: <EventTwoToneIcon />,
    2: <ConfirmationNumberTwoToneIcon />,
    3: <AddToQueueTwoToneIcon />
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  paper: {
    ...constants.shadowCard
  },
  header: {
    marginBottom: theme.spacing(3)
  },
  button: {
    display: 'flex',
    justifyContent: 'center'
  },
  backButton: {
    marginRight: theme.spacing(2)
  },
  instructions: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  },
  imageGooglePlay: {
    width: '160px',
    height: 'auto'
  },
  imageAppStore: {
    width: '145px',
    height: 'auto'
  },
  containerAppValidate: {
    marginBottom: theme.spacing(3)
  }
}))

function getSteps() {
  return ['Dados do evento', 'Gerenciar lotes', 'Resumo final']
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <EventoEditForm />
    case 1:
      return (
        <div>
          <LoteEdit /> <LoteList actions={true} />
        </div>
      )
    case 2:
      return <EventoSummary />
    default:
      return 'Etapa desconhecida'
  }
}

const Steppers = (props) => {
  const classes = useStyles()
  const steps = getSteps()
  const {
    match: { params }
  } = props
  const dispatch = useDispatch()
  const activeStep = useSelector((state) => state.evento.activeStep)
  const evento = useSelector((state) => state.evento)
  const usuarioEstabelecimento = useSelector(
    (state) => state.usuarioEstabelecimento
  )
  const [link, setLink] = useState('')

  const generateLink = useCallback(async () => {
    let link = await onShare(evento)
    setLink(link)
  }, [evento])

  useEffect(() => {
    if (activeStep === 3) {
      generateLink()
    }
  }, [activeStep, generateLink])

  const handleNext = () => {
    if (activeStep === 1) {
      dispatch(eventoAction.setNextStep())
    } else if (activeStep === 2) {
      if (params.id) {
        toast.success(
          'Feito! Os dados do evento foram alterados com sucesso! 🎉🧡'
        )
        dispatch(eventoAction.setNextStep())
      } else {
        dispatch(eventoAction.createEvento(generatePayload()))
      }
    }
  }

  const setActiveStep = (step) => () => {
    dispatch(eventoAction.setStep(step))
  }

  const generatePayload = () => {
    let payload = {
      id: evento.id,
      nome: evento.nome,
      imagem: evento.imagem,
      estabelecimentoId: usuarioEstabelecimento.estabelecimentoId,
      detalhes: evento.detalhes,
      dataHoraInicio: toISODateFormat(evento.dataHoraInicio),
      dataHoraFim: toISODateFormat(evento.dataHoraFim),
      categoriaId: evento.categoriaId,
      disponivel: evento.disponivel,
      cidadeId: evento.cidadeId,
      slug: evento.slug,
      endereco: evento.endereco,
      cep: evento.cep,
      bairro: evento.bairro,
      latLon: evento.latLon,
      tipoLocalizacao: evento.tipoLocalizacao,
      nomeLocal: evento.nomeLocal,
      complemento: evento.complemento,
      numero: evento.numero,
      eventoHashtag: evento.eventoHashtag
    }
    if (!params.id) {
      let lote = evento.lote
      lote.forEach((loteItem) => {
        delete loteItem.id
      })
      payload = { ...payload, lote: lote, id: 0 }
    }
    return payload
  }

  const handleBack = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    if (activeStep === 0) {
      history.push('/eventos')
    } else {
      dispatch(eventoAction.setPrevStep())
    }
  }

  const handleReset = () => {
    dispatch(eventoAction.prepareStateToNewEvento())
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de eventos - Editar evento'
          : 'Gerência de eventos - Novo evento'
      }
    >
      <div className={classes.appFrame}>
        <main className={classes.content}>
          <Header
            className={classes.header}
            title={params.id ? 'Editar evento' : 'Novo evento'}
            subtitle={'Gerência de eventos'}
          />
          <Stepper
            className={classes.paper}
            alternativeLabel
            nonLinear={params.id && params.id > 0}
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton
                  disabled={!(params.id && params.id > 0)}
                  onClick={setActiveStep(index)}
                >
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div style={{ paddingTop: 20 }}>
                <Typography gutterBottom className={classes.instructions}>
                  Todos os dados deste evento foram salvos! 😄
                </Typography>
                <Typography gutterBottom className={classes.instructions}>
                  Compartilhe o link para o evento nas redes sociais!
                  <Typography
                    gutterBottom
                    onClick={() => {
                      navigator.clipboard.writeText(link)
                      toast.success(
                        'Link para o evento copiado com sucesso! 🎉',
                        {
                          autoClose: 4000,
                          toastId: 'eventocopiadolink'
                        }
                      )
                    }}
                  >
                    {link}
                  </Typography>
                </Typography>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems={'center'}
                  mb={3}
                >
                  <SocialShare evento={evento} link={link} />
                </Box>
                <Grid
                  container
                  alignContent="center"
                  justifyContent="center"
                  alignItems="center"
                  align="center"
                  className={classes.containerAppValidate}
                >
                  <Grid item xs={12}>
                    <Typography align="center" gutterBottom>
                      Para ler os ingressos no dia do evento baixe o nosso app
                      validador de ingressos!
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                    <Grid
                      container
                      alignContent="center"
                      justifyContent="center"
                      alignItems="center"
                      align="center"
                    >
                      <Grid item sm={12} md={6} lg={5} xl={4}>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.quintos.corujas.validador"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img
                            className={classes.imageGooglePlay}
                            src="https://corujas.s3.us-east-2.amazonaws.com/images/general/google-play-badge+(1).svg"
                          />
                        </a>
                      </Grid>
                      <Grid item sm={12} md={6} lg={5} xl={4}>
                        <a
                          href="https://apps.apple.com/us/app/corujas-validador-de-ingressos/id1598654541"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img
                            className={classes.imageAppStore}
                            src="https://corujas.s3.us-east-2.amazonaws.com/images/general/Download_on_the_App_Store_Badge_PTBR_RGB_blk_092917.svg"
                          />
                        </a>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={classes.button} item md={12} xs={12}>
                  <Button
                    onClick={() => history.push('/eventos')}
                    variant="contained"
                    style={{ marginRight: 10 }}
                  >
                    Ver lista de eventos
                  </Button>
                  <Button onClick={handleReset} variant="contained">
                    Novo evento
                  </Button>
                </Grid>
              </div>
            ) : (
              <div>
                <Typography>{getStepContent(activeStep)}</Typography>
                <Grid className={classes.button} item md={12} xs={12}>
                  <Button
                    onClick={handleBack}
                    className={classes.backButton}
                    variant="outlined"
                    color="secondary"
                  >
                    Voltar
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    type="submit"
                    form="formEvento"
                  >
                    {activeStep === steps.length - 1
                      ? 'Finalizar'
                      : activeStep === 0
                      ? params.id && params.id > 0
                        ? 'Salvar'
                        : 'Próxima etapa'
                      : 'Revisão final'}
                  </Button>
                </Grid>
              </div>
            )}
          </div>
        </main>
      </div>
    </Page>
  )
}
export default Steppers
