import React from 'react'
import PropTypes from 'prop-types'
import {
  Tabs,
  Tab,
  Divider,
  colors,
  useTheme,
  makeStyles,
  useMediaQuery
} from '@material-ui/core'
import PeopleAltTwoToneIcon from '@material-ui/icons/PeopleAltTwoTone'
import PersonOutlineTwoToneIcon from '@material-ui/icons/PersonOutlineTwoTone'
import { Redirect } from 'react-router-dom'
import { AccessControl } from '../../components/Utils/AccessControl'
import { Header, Page } from 'components'
import { PermissaoTipoUsuario, PermissaoUsuario } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  tabs: { paddingTop: theme.spacing(2) },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}))

const Permissao = (props) => {
  const { match, history } = props
  const classes = useStyles()
  const { tab } = match.params
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const handleTabsChange = (event, value) => {
    history.push(value)
  }

  const tabs = [
    {
      value: 'permissaoTipoUsuario',
      label: 'Permissão por tipo de usuário',
      icon: <PeopleAltTwoToneIcon />
    }
  ]
  {
    tabs.push({
      value: 'permissaoUsuario',
      label: 'Permissão por usuário',
      icon: <PersonOutlineTwoToneIcon />
    })
  }
  if (!tab) {
    return <Redirect to={`/permissoes/permissaoTipoUsuario`} />
  }

  if (!tabs.find((t) => t.value === tab)) {
    return <Redirect to="/errors/error-404" />
  }

  return (
    <Page className={classes.root} title="Gerência de permissões">
      <AccessControl
        rule="permissoes" //permissão necessária para acessar conteúdo
        yes={() => (
          <>
            <Header
              title="Lista de permissões"
              subtitle="Gerência de permissões"
            />
            <Tabs
              className={classes.tabs}
              onChange={handleTabsChange}
              scrollButtons="auto"
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              variant={isMobile ? 'fullWidth' : 'scrollable'}
            >
              {tabs.map((tab) => (
                <Tab
                  disabled={tab.disabled}
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  icon={tab.icon}
                />
              ))}
            </Tabs>
            <Divider className={classes.divider} />
            <div className={classes.content}>
              {tab === 'permissaoTipoUsuario' && <PermissaoTipoUsuario />}
              {tab === 'permissaoUsuario' && <PermissaoUsuario />}
            </div>
          </>
        )}
      ></AccessControl>
    </Page>
  )
}

Permissao.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

export default Permissao
