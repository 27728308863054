import React, { useState, useEffect } from 'react'
import {
  Box,
  CircularProgress,
  makeStyles,
  Typography
} from '@material-ui/core'

//? Own Imports
import { OverallReviews, ReviewCard } from './components'
import { useUserReviews } from 'hooks-querys'
import { Review } from 'types/api'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

const Reviews = ({ userId }: { userId: number }) => {
  const classes = useStyles()
  const isError = false
  const {
    data: userReviews,
    isLoading
  } = useUserReviews(userId)

  return (
    <div className={classes.root}>
      {isLoading ? (
        <Box marginY={4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box mb={2}>
              <Typography variant="h6">Carregando dados...</Typography>
            </Box>
            <Box>
              <CircularProgress />
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <OverallReviews reviews={userReviews} />
          {userReviews &&
            userReviews?.map((review: Review) => (
              <ReviewCard key={review.id} reviewItem={review} />
            ))}
        </>
      )}
    </div>
  )
}

export default Reviews
