import React from 'react'
import { Grid, Typography, makeStyles, Box } from '@material-ui/core'
import { Rating } from '@material-ui/lab'

//? Own imports
import { ReviewItem } from 'types/api'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    ...constants.shadowCard,
    padding: '1rem',
    backgroundColor: theme.palette.background.paper
  },
  stars: {
    display: 'flex',
    alignItems: 'center'
  },
  rating: {
    marginLeft: theme.spacing(2),
    fontWeight: 'bold'
  }
}))

const OverallReviews = ({ reviews }: { reviews: ReviewItem[] }) => {
  const classes = useStyles()
  let rating = 0

  if (reviews?.length > 0) {
    rating =
      reviews.reduce((acc, review) => acc + review.nota, 0) /
      reviews.length
  }

  return (
    <Box className={classes.root}>
      <Grid alignItems="center" container spacing={3}>
        <Grid item>
          <Typography variant="h5">Avaliações no geral</Typography>
        </Grid>
        <Grid className={classes.stars} item>
          <Rating name="rating" value={rating} precision={0.1} readOnly />
          <Typography className={classes.rating} variant="h6">
            {rating}
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="textSecondary" variant="body2">
            {reviews?.length && reviews?.length > 1 ? (
            `${reviews?.length} avaliações no total`)
            : `${reviews?.length} avaliação no total`}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default OverallReviews
