const initialState = {
  anchor: 'left',
  promoter: [],
  open: false,
  id: 0,
  usuarioId: '',
  excluido: false
}

export function promoter(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_PROMOTER':
      return {
        ...state,
        promoter: action.promoter
      }
    case 'PROMOTER_DETAIL':
      return {
        ...state,
        id: action.id,
        usuarioId: action.usuarioId,
        excluido: action.excluido
      }
    case 'PROMOTER_UPDATED':
      return state
    case 'PROMOTER_CLEAR':
      return initialState
    case 'PROMOTER_CLEAR_ALL':
      return {
        ...state,
        promoter: []
      }
    case 'PROMOTER_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    default:
      return state
  }
}
