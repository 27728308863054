import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { conquistaAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import { Page } from 'components'
import { toast } from 'react-toastify'
///////////////////////////////////////////////////////////////////////////////
// material-ui imports
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button
} from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { UploadField } from 'components/Utils/Uploads'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  }
})

class ConquistaEdit extends Component {
  getURL = (url) => {
    const { dispatch } = this.props
    dispatch(conquistaAction.onChangeProps('imagem', url))
  }

  handleChange = (prop) => (event) => {
    const { dispatch } = this.props
    dispatch(conquistaAction.onChangeProps(prop, event))
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props
    const { dispatch } = this.props
    if (params.id) {
      dispatch(conquistaAction.getConquistaById(params.id))
    }
  }

  handleSubmit(event) {
    const {
      match: { params }
    } = this.props
    const { dispatch } = this.props

    if (this.props.conquista.imagem === ('' || null)) {
      toast.error('Você precisa adicionar uma imagem para a conquista!')
    } else {
      let payload = {
        id: params.id,
        nome: this.props.conquista.nome,
        descricao: this.props.conquista.descricao,
        exp: this.props.conquista.experiencia,
        imagem: this.props.conquista.imagem
      }

      if (params.id) {
        dispatch(conquistaAction.editConquistaInfo(params.id, payload))
      } else {
        dispatch(conquistaAction.createConquista(payload))
      }
    }
  }

  handleCancel() {
    const { dispatch } = this.props
    history.push('/conquistas')
    dispatch(conquistaAction.clear())
  }

  render() {
    const { classes } = this.props
    const {
      match: { params }
    } = this.props

    function InsertText() {
      return (
        <Typography variant="h4">
          <b>{'Nova Conquista'}</b>
        </Typography>
      )
    }

    function EditText() {
      return (
        <Typography variant="h4">
          <b>{'Editar Conquista'}</b>
        </Typography>
      )
    }

    function HeaderSelection() {
      if (params.id) {
        return <EditText />
      }
      return <InsertText />
    }

    return (
      <Page
        className={classes.root}
        title={
          params.id
            ? 'Gerência de Conquistas - Editar Conquista'
            : 'Gerência de Conquistas - Nova Conquista'
        }
      >
        <Grid item md={12} xs={12}>
          <Card>
            <form>
              <ValidatorForm
                className={classes.form}
                ref="formConquista"
                id="formConquista"
                onSubmit={(event) => this.handleSubmit(event)}
              >
                <CardHeader title={<HeaderSelection />} />
                <Divider />
                <CardContent>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    id="nome"
                    label="Nome"
                    placeholder="Nome"
                    margin="normal"
                    onChange={this.handleChange('nome')}
                    value={this.props.conquista.nome}
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    autoFocus
                  />
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    id="descricao"
                    label="Descrição"
                    placeholder="Descrição"
                    margin="normal"
                    onChange={this.handleChange('descricao')}
                    value={this.props.conquista.descricao}
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                  />
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    id="experiencia"
                    label="Experiência"
                    placeholder="Experiência"
                    margin="normal"
                    onChange={this.handleChange('experiencia')}
                    value={this.props.conquista.experiencia}
                    type="number"
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                  />
                  <UploadField
                    parentCallback={this.getURL}
                    url={this.props.conquista.imagem}
                    labelText={'Imagem da conquista*'}
                  />
                </CardContent>
                <Divider />
                <CardActions>
                  <Button
                    color="secondary"
                    onClick={() => this.handleCancel()}
                    variant="outlined"
                  >
                    Cancelar
                  </Button>
                  <Button color="primary" type="submit" variant="outlined">
                    {params.id ? 'Atualizar' : 'Salvar'}
                  </Button>
                </CardActions>
              </ValidatorForm>
            </form>
          </Card>
        </Grid>
      </Page>
    )
  }
}

ConquistaEdit.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return state
}

const connectedHashtagEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ConquistaEdit))
)

export { connectedHashtagEditPage as ConquistaEdit }
