import React from 'react'
import { useSelector } from 'react-redux'
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  Typography,
  Avatar
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { HelperToolTip } from 'components'
import { getInitials } from 'utils'

const listNiveis = [
  {
    title: 'Alfa',
    subtitle: '0-25XP',
    image: `https://picsum.photos/2${Math.floor(
      Math.random() * 10
    )}${Math.floor(Math.random() * 10)}`,
    beneficios: [
      {
        title: '10%',
        subtitle: 'DE TAXA'
      },
      {
        title: '30 DIAS',
        subtitle: 'PARA PAGAMENTO'
      },
      {
        title: 'VISIBILIDADE ABAIXO DA MÉDIA',
        subtitle: ''
      }
    ]
  },
  {
    title: 'Beta',
    subtitle: '25-50XP',
    image: `https://picsum.photos/2${Math.floor(
      Math.random() * 10
    )}${Math.floor(Math.random() * 10)}`,
    beneficios: [
      {
        title: '10%',
        subtitle: 'DE TAXA'
      },
      {
        title: '30 DIAS',
        subtitle: 'PARA PAGAMENTO'
      },
      {
        title: 'VISIBILIDADE ABAIXO DA MÉDIA',
        subtitle: ''
      }
    ]
  },
  {
    title: 'Gama',
    subtitle: '50-75XP',
    image: `https://picsum.photos/2${Math.floor(
      Math.random() * 10
    )}${Math.floor(Math.random() * 10)}`,
    beneficios: [
      {
        title: '10%',
        subtitle: 'DE TAXA'
      },
      {
        title: '30 DIAS',
        subtitle: 'PARA PAGAMENTO'
      },
      {
        title: 'VISIBILIDADE ABAIXO DA MÉDIA',
        subtitle: ''
      }
    ]
  },
  {
    title: 'Delta',
    subtitle: '75-100XP',
    image: `https://picsum.photos/2${Math.floor(
      Math.random() * 10
    )}${Math.floor(Math.random() * 10)}`,
    beneficios: [
      {
        title: '10%',
        subtitle: 'DE TAXA'
      },
      {
        title: '30 DIAS',
        subtitle: 'PARA PAGAMENTO'
      },
      {
        title: 'VISIBILIDADE ABAIXO DA MÉDIA',
        subtitle: ''
      }
    ]
  }
]

const listBeneficiosNivel = [
  {
    title: 'Taxa',
    subtitle: '10%',
    info: 'A Corujas ficará com 10% do valor do ingresso.',
    image: `https://picsum.photos/2${Math.floor(
      Math.random() * 10
    )}${Math.floor(Math.random() * 10)}`
  },
  {
    title: 'Prazo de Recebimento',
    subtitle: `X dias para receber!`,
    info: 'Você irá receber o pagamento dos eventos em X dias.',
    image: `https://picsum.photos/2${Math.floor(
      Math.random() * 10
    )}${Math.floor(Math.random() * 10)}`
  },
  {
    title: 'Visibilidade',
    subtitle: `Sua pontuação é: 6/10`,
    info:
      'Quanto maior a pontuação, o estabelecimento estará mais visível na plataforma.',
    image: `https://picsum.photos/2${Math.floor(
      Math.random() * 10
    )}${Math.floor(Math.random() * 10)}`
  }
]

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  rewardContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    textAlign: 'center'
  },
  avatar: {
    height: 130,
    width: 130
  },
  cardNivel: {
    borderRadius: '8px',
    webkitBorderRadius: '8px',
    mozBorderRadius: '8px',
    boxShadow: '0px 2px 8px rgb(0 0 0 / 8%)',
    height: '350px'
  },
  cardNextNiveis: {
    borderRadius: '8px',
    webkitBorderRadius: '8px',
    mozBorderRadius: '8px',
    boxShadow: '0px 2px 8px rgb(0 0 0 / 8%)'
  },
  cardBeneficio: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '8px'
  },
  card: {
    minHeight: '350px',
    borderRadius: '8px',
    webkitBorderRadius: '8px',
    mozBorderRadius: '8px',
    boxShadow: '0px 2px 8px rgb(0 0 0 / 8%)'
  },
  alignContentCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(3)
  }
}))

const Level = () => {
  const classes = useStyles()
  const estabelecimento = useSelector((state) => state.estabelecimento)

  return (
    <Grid className={classes.root} container spacing={3}>
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <Card className={classes.cardNivel}>
          <CardHeader title={'Nível'} />
          <Divider />
          <CardContent className={classes.content}>
            <Avatar
              variant="rounded"
              className={classes.avatar}
              src={estabelecimento.imagem}
            >
              {getInitials(estabelecimento.nome)}
            </Avatar>
            <Typography align="center" gutterBottom variant="h3">
              {estabelecimento.nome}
            </Typography>
            <Typography component="h2" variant="overline">
              Nível atual: Alfa 6/100 xp
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={8} md={6} xl={9} xs={12}>
        <Card className={classes.card}>
          <CardHeader title={'Benefícios do nível'} />
          <Divider />
          <CardContent className={classes.content}>
            <Grid container spacing={3}>
              {listBeneficiosNivel.map((item, index) => (
                <Grid key={index} item lg={4} md={4} xs={12}>
                  <Card className={classes.cardBeneficio}>
                    <CardHeader title={item.title} align="center" />
                    <div className={classes.alignContentCenter}>
                      <Avatar
                        variant="square"
                        className={classes.avatar}
                        src={item.image}
                      ></Avatar>
                    </div>
                    <CardContent className={classes.rewardContent}>
                      <Typography noWrap component="h2" variant="overline">
                        {item.subtitle}
                      </Typography>
                      <HelperToolTip>{item.info}</HelperToolTip>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={12}>
        <Card className={classes.cardNextNiveis}>
          <CardHeader title={'Níveis na Corujas'} />
          <Divider />
          <CardContent className={classes.content}>
            <Grid container spacing={2}>
              {listNiveis.map((item, index) => (
                <Grid key={index} item sm={6} lg={3} md={3} xs={12}>
                  <Card className={classes.root}>
                    <div className={classes.alignContentCenter}>
                      <Avatar
                        variant="square"
                        className={classes.avatar}
                        src={item.image}
                      ></Avatar>
                    </div>
                    <CardContent>
                      <Typography align="center" noWrap component="h3">
                        {item.title}
                      </Typography>
                      <Typography
                        component="h1"
                        variant="overline"
                        gutterBottom
                        align="center"
                      >
                        {item.subtitle}
                      </Typography>
                      {item.beneficios.map((item, index) => (
                        <Typography
                          key={index}
                          noWrap
                          component="h2"
                          variant="overline"
                        >
                          {item.title} {item.subtitle}
                        </Typography>
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default Level
