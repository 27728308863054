import {
  FormControl,
  InputLabel,
  makeStyles,
  Select,
  MenuItem,
  CircularProgress,
  Box,
  Typography,
  TextField,
  Chip,
  InputAdornment,
  Switch,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Page } from 'components'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cupomDescontoAction, eventoAction, loteAction } from '_actions'
import TableUtilizacao from './components/TableUtilizacao'
import { toLocalDateFormat } from 'utils'
const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  divider: {
    height: 28,
    margin: 4
  }
}))

const CuponsDescontoUtilizacao = () => {
  const classes = useStyles()
  const [selectedEvento, setSelectedEvento] = useState({
    id: 0,
    nome: '',
    dataHoraInicio: null
  })
  const [eventoInputValue, setEventoInputValue] = useState('')
  const [loteId, setLoteId] = useState(0)
  const [showPastEvents, setShowPastEvents] = useState(false)
  const dispatch = useDispatch()
  const estabelecimentoId = useSelector(
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const eventos = useSelector((state) => state.evento.evento)
  const { utilizacaoCuponsDesconto, isLoading } = useSelector(
    (state) => state.cupomDesconto
  )
  const { lote: lotes } = useSelector((state) => state.evento)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  useEffect(() => {
    setSelectedEvento({
      id: 0,
      nome: '',
      dataHoraInicio: null
    })
    setLoteId(0)
    setShowPastEvents(false)
    setEventoInputValue('')
  }, [estabelecimentoId])

  useEffect(() => {
    if (isMobile) {
      dispatch(eventoAction.getAllEventosByEstabelecimento(estabelecimentoId))
    } else {
      dispatch(
        eventoAction.getEventoByEstabelecimento(
          estabelecimentoId,
          showPastEvents
        )
      )
    }
  }, [estabelecimentoId, showPastEvents, isMobile])

  useEffect(() => {
    dispatch(
      cupomDescontoAction.getUtilizacaoCupomDesconto(
        loteId,
        selectedEvento ? selectedEvento.id : 0,
        estabelecimentoId
      )
    )
  }, [selectedEvento, loteId, estabelecimentoId])

  useEffect(() => {
    if (selectedEvento === null || selectedEvento.id === 0) {
      setLoteId(0)
    }
  }, [selectedEvento])

  useEffect(() => {
    if (selectedEvento && selectedEvento.id > 0) {
      dispatch(loteAction.getLotesByEvento(selectedEvento.id))
    }
  }, [selectedEvento])

  return (
    <Page className={classes.root} title="Cupons de Desconto - Utilização">
      <FormControl className={classes.formControl}>
        <Autocomplete
          id="combo-box-evento"
          className={classes.selectEmpty}
          options={eventos}
          value={selectedEvento}
          onChange={(event, newValue) => {
            setSelectedEvento(newValue)
          }}
          getOptionLabel={(evento) =>
            evento.nome &&
            `${evento.nome} ${
              evento.dataHoraInicio
                ? ` - (${toLocalDateFormat(evento.dataHoraInicio)})`
                : ''
            }`
          }
          placeholder="Selecione o evento..."
          onInputChange={(event, newInputValue) => {
            newInputValue !== null
              ? setEventoInputValue(newInputValue)
              : setEventoInputValue('')
          }}
          inputValue={eventoInputValue}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filtrar por evento"
              placeholder="Selecione o evento..."
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {!isMobile && (
                      <InputAdornment position="end">
                        <>
                          <Switch
                            color="primary"
                            onChange={(event) => {
                              setShowPastEvents(event.target.checked)
                            }}
                            checked={showPastEvents}
                            size="small"
                            inputProps={{
                              'aria-label': 'Listar eventos encerrados'
                            }}
                          />
                          <Chip
                            variant="default"
                            size="small"
                            label="Listar eventos encerrados"
                            color={showPastEvents ? 'primary' : 'default'}
                            onClick={() => {
                              setShowPastEvents(!showPastEvents)
                            }}
                          />
                        </>
                      </InputAdornment>
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </FormControl>
      {selectedEvento && selectedEvento.id > 0 && lotes && lotes.length > 0 && (
        <FormControl className={classes.formControl}>
          <InputLabel shrink id="lote-select-label">
            Filtrar por lote
          </InputLabel>
          <Select
            className={classes.selectEmpty}
            variant="outlined"
            id="loteId"
            value={loteId}
            onChange={(event) => setLoteId(event.target.value)}
            fullWidth
            displayEmpty
            labelId="lote-select-label"
          >
            {loteId === 0 ? (
              <MenuItem disabled value={0}>
                <em>Selecione o lote...</em>
              </MenuItem>
            ) : (
              <MenuItem value={0}>
                <strong>Limpar seleção</strong>
              </MenuItem>
            )}
            {lotes &&
              lotes.length > 0 &&
              lotes.map((row) => (
                <MenuItem key={row.id} value={row.id}>
                  {row.tipoIngresso
                    ? `${row.tipoIngresso.descricao} - ${row.descricao}`
                    : row.descricao}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
      {isLoading ? (
        <Box marginY={4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box mb={2}>
              <Typography variant="h6">Carregando dados...</Typography>
            </Box>
            <Box>
              <CircularProgress />
            </Box>
          </Box>
        </Box>
      ) : (
        <TableUtilizacao cuponsDescontoResult={utilizacaoCuponsDesconto} />
      )}
    </Page>
  )
}

export default CuponsDescontoUtilizacao
