import React, { useEffect, useState } from 'react'
import { LinearProgress, makeStyles } from '@material-ui/core'

//? Own imports
import { AccessControl } from 'components/Utils'
import { Header, Page, SkeletonTable } from 'components'
import AccessDenied from 'views/Errors/AccessDenied'
import { Filters, TableEstabelecimentos } from './components'
import { useEstablishments } from 'hooks-querys'
import { useLocation } from 'react-router-dom'
import { EstabelecimentoListAdmin } from 'types/api'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  header: {
    marginBottom: theme.spacing(4)
  }
}))

function useSearchQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const EstabelecimentoList = () => {
  const classes = useStyles()
  const querySearch = useSearchQuery()
  const [filters, setFilters] = useState({
    // page: Number(querySearch.get('page')) || 1,
    textSearch: querySearch.get('textSearch') || ''
  })

  useEffect(() => {
    setFilters({
      // page: Number(querySearch.get('page')) || 1,
      textSearch: querySearch.get('textSearch') || ''
    })
  }, [querySearch])

  const { data, isLoading, refetch, isFetching } = useEstablishments({
    page: 1, // valor fixo para paginação, pois o componente de paginação não está implementado
    direction: 'desc',
    order: 'id',
    limit: 10000, //limite alto para não haver paginação
    term: filters.textSearch
  })
  return (
    <Page title={'Lista de estabelecimentos - Gerência de estabelecimentos'}>
      {isFetching && (
        <LinearProgress
          style={{
            width: '100%',
            position: 'absolute'
          }}
        />
      )}
      <AccessControl
        rule={'estabelecimentos.listAdmin'} //permissão necessária para acessar conteúdo
        // rule={'estabelecimentos.list'} //permissão necessária para acessar conteúdo
        // @ts-ignore
        yes={() => (
          <div className={classes.root}>
            <Header
              className={classes.header}
              subtitle="GERÊNCIA DE ESTABELECIMENTOS"
              title="Lista de estabelecimentos"
              isRefreshing={isLoading}
              onRefresh={refetch}
            />
            <Filters />
            {isLoading ? (
              <SkeletonTable />
            ) : (
              <TableEstabelecimentos
                estabelecimentos={
                  data?.items || ([] as EstabelecimentoListAdmin[])
                }
              />
            )}
          </div>
        )}
        // @ts-ignore
        no={() => <AccessDenied />}
      />
    </Page>
  )
}

export default EstabelecimentoList
