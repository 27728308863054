import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  CircularProgress
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

import { Label } from 'components'
import { formatCurrency, toLocalDateFormat } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import { isEmpty } from 'validate.js'
import {
  movimentacaoFinanceiraAction,
  statusMovimentacaoFinanceiraAction
} from '_actions'
import { UploadField } from 'components/Utils/Uploads'
import { toast } from 'react-toastify'
import { AccessControl } from 'components/Utils/AccessControl'
import { useDidMountEffect } from 'utils/useDidMountEffect'
import constants from 'theme/constants'

const statusColors = {
  1: colors.orange[600],
  2: colors.red[600],
  3: colors.green[600]
}

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    ...constants.shadowCard
  },
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}))

const CardMovimentacaoFinanceira = ({
  movimentacaoFinanceira,
  className,
  ...rest
}) => {
  const classes = useStyles()
  const [openEdit, setOpenEdit] = useState(false)
  const { isLoading } = useSelector((state) => state.movimentacaoFinanceira)
  const {
    statusMovimentacaoFinanceiraSelect,
    isFetching: isFetchingStatusMovimentacaoFinanceira
  } = useSelector((state) => state.statusMovimentacaoFinanceira)

  const dispatch = useDispatch()

  const handleSubmit = () => {
    if (movimentacaoFinanceira.statusMovimentacaoFinanceiraId == 3) {
      if (
        movimentacaoFinanceira.anexo !== null &&
        movimentacaoFinanceira.anexo !== ''
      ) {
        setOpenEdit(false)
        dispatch(
          movimentacaoFinanceiraAction.finishMovimentacaoFinanceira(
            movimentacaoFinanceira
          )
        )
      } else {
        toast.error('Eii! Faltou anexar o comprovante da transferência!')
      }
    } else if (movimentacaoFinanceira.statusMovimentacaoFinanceiraId == 2) {
      if (
        movimentacaoFinanceira.motivoCancelamento !== null &&
        movimentacaoFinanceira.motivoCancelamento !== ''
      ) {
        setOpenEdit(false)
        dispatch(
          movimentacaoFinanceiraAction.cancelMovimentacaoFinanceira(
            movimentacaoFinanceira
          )
        )
      } else {
        toast.error('Eii! Faltou descrever o motivo do cancelamento!')
      }
    }
  }
  const handleEditOpen = () => {
    setOpenEdit(true)
  }

  const handleEditClose = () => {
    dispatch(
      movimentacaoFinanceiraAction.getMovimentacaoFinanceiraById(
        movimentacaoFinanceira.id
      )
    )
    setOpenEdit(false)
  }

  const handleChange = (prop) => (event) => {
    dispatch(movimentacaoFinanceiraAction.onChangeProps(prop, event))
  }

  useDidMountEffect(() => {
    if (openEdit === true) {
      if (
        statusMovimentacaoFinanceiraSelect !== undefined &&
        statusMovimentacaoFinanceiraSelect.length === 0
      ) {
        dispatch(
          statusMovimentacaoFinanceiraAction.getStatusMovimentacaoFinanceiraSelect()
        )
      }
    }
  }, [openEdit])

  const getURL = (url) => {
    dispatch(movimentacaoFinanceiraAction.onChangeProps('anexo', url))
  }

  return (
    <Card {...rest} className={clsx(classes.paper, className)}>
      <CardHeader title="Movimentação financeira" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell height={67}>Número: </TableCell>
              <TableCell>
                {isLoading ? <Skeleton /> : movimentacaoFinanceira.id}
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Estabelecimento:</TableCell>
              <TableCell>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  movimentacaoFinanceira.estabelecimento.nome
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Data solicitação:</TableCell>
              <TableCell>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  toLocalDateFormat(movimentacaoFinanceira.dataHora)
                )}
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Data finalização:</TableCell>
              <TableCell>
                {isLoading ? (
                  <Skeleton />
                ) : movimentacaoFinanceira.dataHoraProcessamento !== null ? (
                  toLocalDateFormat(
                    movimentacaoFinanceira.dataHoraProcessamento
                  )
                ) : (
                  '---------'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Valor:</TableCell>
              <TableCell>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  formatCurrency(movimentacaoFinanceira.valor)
                )}
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Status:</TableCell>
              {openEdit ? (
                <TableCell>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="status-movimentacao-financeira-label">
                      Status
                    </InputLabel>
                    <Select
                      autoWidth
                      endAdornment={
                        isFetchingStatusMovimentacaoFinanceira && (
                          <CircularProgress size={24} />
                        )
                      }
                      disabled={isFetchingStatusMovimentacaoFinanceira}
                      placeholder="Status"
                      labelId="status-movimentacao-financeira-label"
                      id="status-movimentacao-financeira-select"
                      value={
                        movimentacaoFinanceira.statusMovimentacaoFinanceiraId
                      }
                      onChange={handleChange('statusMovimentacaoFinanceiraId')}
                      label="Status"
                    >
                      {!isEmpty(statusMovimentacaoFinanceiraSelect) &&
                        statusMovimentacaoFinanceiraSelect.map((status) => (
                          <MenuItem
                            disabled={
                              status.value ==
                              movimentacaoFinanceira.statusMovimentacaoFinanceiraId
                                ? true
                                : false
                            }
                            key={status.value}
                            value={status.value}
                          >
                            {status.text}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </TableCell>
              ) : (
                <TableCell style={{ padding: 0 }}>
                  {isLoading ? (
                    <Skeleton />
                  ) : (
                    !!movimentacaoFinanceira.statusMovimentacaoFinanceira && (
                      <Label
                        color={
                          statusColors[
                            movimentacaoFinanceira
                              .statusMovimentacaoFinanceiraId
                          ]
                        }
                      >
                        {
                          movimentacaoFinanceira.statusMovimentacaoFinanceira
                            .descricao
                        }
                      </Label>
                    )
                  )}
                </TableCell>
              )}
            </TableRow>
            {movimentacaoFinanceira.statusMovimentacaoFinanceiraId == 3 && (
              <TableRow>
                <TableCell>Comprovante *:</TableCell>
                <TableCell>
                  <UploadField
                    disabled={!openEdit}
                    parentCallback={getURL}
                    url={
                      movimentacaoFinanceira.anexo !== null
                        ? movimentacaoFinanceira.anexo
                        : ''
                    }
                  />
                </TableCell>
              </TableRow>
            )}
            {movimentacaoFinanceira.statusMovimentacaoFinanceiraId == 2 && (
              <TableRow>
                <TableCell>Motivo de cancelamento *:</TableCell>
                <TableCell>
                  <TextField
                    error={
                      movimentacaoFinanceira.motivoCancelamento === null ||
                      movimentacaoFinanceira.motivoCancelamento === ''
                    }
                    disabled={!openEdit}
                    id="text-field-motivo-de-cancelamento"
                    label="Motivo de cancelamento *"
                    multiline
                    value={movimentacaoFinanceira.motivoCancelamento}
                    onChange={handleChange('motivoCancelamento')}
                    fullWidth
                    rows={3}
                    variant="filled"
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
      <AccessControl
        rule={'movimentacoesFinanceiras.finish'} //permissão necessária para acessar conteúdo
        yes={() =>
          openEdit ? (
            <CardActions className={classes.actions}>
              <Button
                color="default"
                onClick={handleEditClose}
                variant="contained"
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={handleSubmit}
                variant="contained"
              >
                Salvar
              </Button>
            </CardActions>
          ) : (
            <CardActions className={classes.actions}>
              <Button onClick={handleEditOpen} variant="text">
                <EditIcon className={classes.buttonIcon} />
                Editar
              </Button>
            </CardActions>
          )
        }
      />
    </Card>
  )
}

CardMovimentacaoFinanceira.propTypes = {
  className: PropTypes.string,
  movimentacaoFinanceira: PropTypes.object.isRequired
}

export default CardMovimentacaoFinanceira
