import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { contaBancariaAction } from '_actions'
import {
  makeStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Typography,
  Button,
  Grid
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { history } from 'utils'
import { Page, SkeletonTable } from 'components'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from 'components/Utils/AccessControl'
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone'
import EmptyFirstContaBancaria from '../EmptyFirstContaBancaria/EmptyFirstContaBancaria'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    marginTop: theme.spacing(1),
    ...constants.shadowCard
  }
}))

const ContaBancariaList = () => {
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const classes = useStyles()

  const { contaBancaria, isFetching } = useSelector(
    (state) => state.contaBancaria
  )
  const estabelecimentoId = useSelector(
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )

  useEffect(() => {
    dispatch(
      contaBancariaAction.getContaBancariaByEstabelecimento(estabelecimentoId)
    )
  }, [estabelecimentoId])

  const handleDeleteClick = (id, strFrom) => {
    confirm({
      title: `Você deseja excluir esta ${strFrom}?`,
      description: `Confirmando essa operação, esta ${strFrom} não valerá mais.`,
      confirmationText: 'Sim, excluir!',
      cancellationText: 'Nãããoo!'
    }).then(() => {
      dispatch(contaBancariaAction.deleteContaBancariaById(id))
    })
  }

  const thereIsPix = contaBancaria.some((element) => element.conta === '0')

  const thereIsContaBancaria = contaBancaria.some(
    (element) => element.conta !== '0'
  )

  return (
    <Page className={classes.root} title="Carteira - Lista de Contas Bancárias">
      <AccessControl
        rule={'contasBancarias.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <>
            {isFetching ? (
              <SkeletonTable />
            ) : (
              <>
                {undefined !== contaBancaria && contaBancaria.length > 0 ? (
                  <div>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justify="flex-end"
                      alignItems="flex-end"
                      alignContent="stretch"
                    >
                      <Grid item>
                        <Typography
                          gutterBottom
                          variant="caption"
                          color="textPrimary"
                        >{`Total de dados encontrados: ${contaBancaria.length}`}</Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={() => history.push('contasBancarias/pix')}
                          variant="contained"
                          color="primary"
                        >
                          Nova Chave Pix
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={() =>
                            history.push('contasBancarias/cadastro')
                          }
                          variant="contained"
                          color="primary"
                        >
                          Nova conta
                        </Button>
                      </Grid>
                    </Grid>

                    <>
                      {thereIsContaBancaria && (
                        <TableContainer
                          className={classes.paper}
                          component={Paper}
                        >
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">Descrição</TableCell>
                                <TableCell align="center">Agência</TableCell>
                                <TableCell align="center">Conta</TableCell>
                                <TableCell align="center">CPF/CNPJ</TableCell>
                                <TableCell align="center">
                                  Nome Titular
                                </TableCell>
                                <TableCell align="center">Padrão</TableCell>
                                <TableCell align="right">Ações</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {contaBancaria.map((n) => {
                                return (
                                  <>
                                    {n.conta !== '0' && (
                                      <TableRow hover key={n.id}>
                                        <TableCell
                                          align="left"
                                          component="th"
                                          scope="row"
                                        >
                                          {n.descricao}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          component="th"
                                          scope="row"
                                        >
                                          {n.agencia}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          component="th"
                                          scope="row"
                                        >
                                          {n.conta}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          component="th"
                                          scope="row"
                                        >
                                          {n.cpfCnpjTitular}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          component="th"
                                          scope="row"
                                        >
                                          {n.nomeTitular}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          component="th"
                                          scope="row"
                                        >
                                          {n.padrao && (
                                            <CheckCircleTwoToneIcon color="primary" />
                                          )}
                                        </TableCell>
                                        <TableCell align="right">
                                          {/* <AccessControl
                                            rule={'contasBancarias.edit'} //permissão necessária para acessar conteúdo
                                            yes={() => (
                                              <IconButton
                                                size="small"
                                                aria-label="Edit"
                                                component="a"
                                                onClick={() =>
                                                  history.push(
                                                    `/carteira/contasBancarias/cadastro/${n.id}`
                                                  )
                                                }
                                              >
                                                <Tooltip title="Editar">
                                                  <EditIcon />
                                                </Tooltip>
                                              </IconButton>
                                            )}
                                          /> */}
                                          <AccessControl
                                            rule={'contasBancarias.delete'} //permissão necessária para acessar conteúdo
                                            yes={() => (
                                              <IconButton
                                                size="small"
                                                aria-label="Delete"
                                                onClick={() =>
                                                  handleDeleteClick(
                                                    n.id,
                                                    'Conta Bancária'
                                                  )
                                                }
                                              >
                                                <Tooltip title="Excluir">
                                                  <DeleteIcon />
                                                </Tooltip>
                                              </IconButton>
                                            )}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </>

                    <>
                      {thereIsPix && (
                        <TableContainer
                          className={classes.paper}
                          component={Paper}
                        >
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">Descrição</TableCell>
                                <TableCell align="left">Chave PIX</TableCell>
                                <TableCell align="center">
                                  Nome Titular
                                </TableCell>
                                <TableCell align="right">Padrão</TableCell>
                                <TableCell align="right">Ações</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {contaBancaria.map((n) => {
                                return (
                                  <>
                                    {n.conta === '0' && (
                                      <TableRow hover key={n.id}>
                                        <TableCell
                                          align="left"
                                          component="th"
                                          scope="row"
                                        >
                                          {n.descricao}
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          component="th"
                                          scope="row"
                                        >
                                          {n.chavePix}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          component="th"
                                          scope="row"
                                        >
                                          {n.nomeTitular}
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          component="th"
                                          scope="row"
                                        >
                                          {n.padrao && (
                                            <CheckCircleTwoToneIcon color="primary" />
                                          )}
                                        </TableCell>
                                        <TableCell align="right">
                                          {/* <AccessControl
                                            rule={'contasBancarias.edit'}
                                            yes={() => (
                                              <IconButton
                                                size="small"
                                                aria-label="Edit"
                                                component="a"
                                                onClick={() =>
                                                  history.push(
                                                    `/carteira/contasBancarias/pix/${n.id}`
                                                  )
                                                }
                                              >
                                                <Tooltip title="Editar">
                                                  <EditIcon />
                                                </Tooltip>
                                              </IconButton>
                                            )}
                                          /> */}
                                          <AccessControl
                                            rule={'contasBancarias.delete'}
                                            yes={() => (
                                              <IconButton
                                                size="small"
                                                aria-label="Delete"
                                                onClick={() =>
                                                  handleDeleteClick(
                                                    n.id,
                                                    'Chave PIX'
                                                  )
                                                }
                                              >
                                                <Tooltip title="Excluir">
                                                  <DeleteIcon />
                                                </Tooltip>
                                              </IconButton>
                                            )}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </>
                  </div>
                ) : (
                  <EmptyFirstContaBancaria />
                )}
              </>
            )}
          </>
        )}
      />
    </Page>
  )
}

export default ContaBancariaList
