import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import VisibilityOffTwoToneIcon from '@material-ui/icons/VisibilityOffTwoTone'
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone'
import { useDispatch, useSelector } from 'react-redux'
import { globalActions } from '_actions'
import { SpeedDial } from '@material-ui/lab'

const PrivateRouteWithLayout = (props) => {
  const { layout: Layout, component: Component, ...rest } = props
  const dispatch = useDispatch()
  const { showValues, isVisibleButtonShowValues } = useSelector(
    (state) => state.global
  )
  const setShowValues = () => {
    dispatch(globalActions.setShowValues())
  }
  return (
    <Route
      {...rest}
      render={(matchProps) =>
        localStorage.getItem('user') ? (
          <React.Fragment>
            <Layout>
              <Component {...matchProps} />
            </Layout>
            <Tooltip
              title={showValues ? 'Ocultar valores' : 'Mostrar valores'}
              placement="left"
            >
              <SpeedDial
                ariaLabel="mostrar/esconder dados"
                style={{
                  margin: 0,
                  top: 'auto',
                  right: 20,
                  bottom: 20,
                  left: 'auto',
                  position: 'fixed'
                }}
                hidden={!isVisibleButtonShowValues}
                icon={
                  showValues ? (
                    <VisibilityTwoToneIcon />
                  ) : (
                    <VisibilityOffTwoToneIcon color="disabled" />
                  )
                }
                FabProps={{
                  size: 'small',
                  color: showValues ? 'primary' : 'inherit'
                }}
                onClick={() => setShowValues(!showValues)}
              />
            </Tooltip>
          </React.Fragment>
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}

PrivateRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
}

export default PrivateRouteWithLayout
