import React, { useState, useEffect } from 'react'
import { Grid, makeStyles } from '@material-ui/core'

//? Own imports
import { UserInfo, ExtraInfo, OtherActions } from './components'
import { User } from 'types/api'

const useStyles = makeStyles(() => ({
  root: {}
}))

type UserInfoProps = {
  user: User | undefined
}

const Summary = ({ user }: UserInfoProps) => {
  const classes = useStyles()

  return (
    <Grid className={classes.root} container spacing={3}>
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <UserInfo user={user || {} as User} />
      </Grid>
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <ExtraInfo user={user || {} as User} />
      </Grid>
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <OtherActions user={user || {} as User} />
      </Grid>
    </Grid>
  )
}

export default Summary
