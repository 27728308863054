import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'

//? Own imports
import { Estabelecimento } from 'types/api'
import { crudService } from '_services'
import { editEstabelecimentosDetails, getEstabelecimentoById } from '_actions'

const editEstablishment = async (
  establishmentId: number,
  payload: Estabelecimento
) => {
  const { data } = await crudService.put(
    `estabelecimentos/${establishmentId}`,
    payload
  )
  return data
}

export default function useMutationEditEstablishment(establishmentId: number) {
  const dispatch = useDispatch()
  return useMutation(
    'editEstablishment',
    (payload: Estabelecimento) => editEstablishment(establishmentId, payload),
    {
      onError: (error) => {
        toast.error(
          // @ts-ignore
          error?.message || 'Erro ao editar os dados do estabelecimento'
        )
      },
      onSuccess: (__, variables) => {
        dispatch(editEstabelecimentosDetails(variables))
        dispatch(getEstabelecimentoById(variables?.id))
      }
    }
  )
}
