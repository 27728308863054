import { colors } from '@material-ui/core'
import { StatusCancelamentoIngresso } from 'types/api'

export const getStatusCancelamentoIngressoColor = (
  statusCancelamentoIngresso: StatusCancelamentoIngresso
) => {
  if (statusCancelamentoIngresso.finalizado) {
    return colors.green[500]
  }
  if (statusCancelamentoIngresso.cancelado) {
    return colors.red[500]
  }
  if (statusCancelamentoIngresso.pendente) {
    return colors.orange[500]
  }
  return colors.grey[500]
}
