const initialState = {
  usuarioEstabelecimento: [],
  id: '',
  isLoading: false,
  usuarioId: '',
  estabelecimentoId: '',
  excluido: null,
  estabelecimento: {},
  categoriaId: 0,
  isAddPlace: false,
  backupId: '',
  listUserPlace: []
}

export function usuarioEstabelecimento(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_ESTABELECIMENTO_SUCCESS':
      return {
        ...state,
        estabelecimentoId: '',
        usuarioEstabelecimento: [
          ...state.usuarioEstabelecimento,
          action.usuarioEstabelecimento
        ],
        isAddPlace: false,
        backupId: ''
      }
    case 'FETCHED_ALL_USUARIOESTABELECIMENTO':
      return {
        ...state,
        usuarioEstabelecimento: action.usuarioEstabelecimento
      }
    case 'FETCHED_ALL_USERPLACE':
      return {
        ...state,
        listUserPlace: action.listUserPlace
      }
    case 'USUARIOESTABELECIMENTO_DETAIL':
      return {
        ...state,
        id: action.id,
        usuarioId: action.usuarioId,
        estabelecimentoId: action.estabelecimentoId,
        excluido: action.excluido,
        estabelecimento: action.estabelecimento,
        categoriaId: action.categoriaId
      }
    case 'USUARIOESTABELECIMENTO_UPDATED':
      return state
    case 'USUARIO_ESTABELECIMENTO_ADD_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'USUARIO_ESTABELECIMENTO_ADD_SUCCESS':
      return {
        ...state,
        isLoading: false
      }
    case 'USUARIO_ESTABELECIMENTO_ADD_FAILURE':
      return {
        ...state,
        isLoading: false
      }
    case 'USUARIO_ESTABELECIMENTO_DELETE_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'USUARIO_ESTABELECIMENTO_DELETE_SUCCESS':
      return {
        ...state,
        usuarioEstabelecimento:
          action.usuarioEstabelecimentoId == state.id
            ? []
            : [...state.usuarioEstabelecimento],
        listUserPlace: [
          ...state.listUserPlace.filter(
            (item) => item.id != action.usuarioEstabelecimentoId
          )
        ],
        isLoading: false
      }
    case 'USUARIO_ESTABELECIMENTO_DELETE_FAILURE':
      return {
        ...state,
        isLoading: false
      }
    case 'USUARIOESTABELECIMENTO_CLEAR':
      return initialState
    case 'USUARIOESTABELECIMENTO_CLEAR_ALL':
      return {
        ...state,
        usuarioEstabelecimento: []
      }
    case 'CHANGE_DETAILS_USUARIOESTABELECIMENTO':
      return {
        ...state,
        id: action.id,
        usuarioId: action.usuarioId,
        estabelecimentoId: action.estabelecimentoId,
        excluido: action.excluido,
        estabelecimento: action.estabelecimento
      }
    case 'AUTHENTICATION_REFRESH_SUCCESS':
      return {
        ...state,
        usuarioEstabelecimento: action.user.usuarioEstabelecimento
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        usuarioEstabelecimento: action.user.usuarioEstabelecimento
      }
    case 'SET_IS_ADD_PLACE':
      return {
        ...state,
        isAddPlace: action.isAddPlace,
        backupId: action.backupId
      }
    default:
      return state
  }
}
