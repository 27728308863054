import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  withStyles,
  Tooltip
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import InputIcon from '@material-ui/icons/Input'
import { authActions } from '../../../../_actions'
import { withRouter } from 'react-router-dom'
import { SelectEstabelecimento } from './components'

const styles = (theme) => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(5)
  },
  selectEstabelecimento: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // position: 'absolute',
    left: 0,
    right: 0
  }
})

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props
  const { classes } = props
  const dispatch = useDispatch()
  // const [notifications] = useState([]);
  const logout = () => {
    dispatch(authActions.logout())
  }

  return (
    <AppBar
      color="secondary"
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/home">
          <img
            width="50%"
            height="60%"
            alt="Logo"
            src="/images/logo-svg/topbar-logo.svg"
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          {/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <div className={classes.selectEstabelecimento}>
            <SelectEstabelecimento />
          </div>
          <Tooltip title="Sair">
            <IconButton
              className={classes.signOutButton}
              color="inherit"
              onClick={logout}
            >
              <InputIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden lgUp>
          <div className={classes.selectEstabelecimento}>
            <SelectEstabelecimento />
          </div>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    usuario: state.usuario
  }
}
const TopbarPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Topbar))
)
export default TopbarPage
