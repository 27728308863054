import { useMutation } from 'react-query'
import { PhotoAlbum } from 'types/api'
import { crudService } from '_services'
import { toast } from 'react-toastify'

const putEditPhotoAlbum = async (payload: PhotoAlbum) => {
  let apiEndpoint = 'albuns/' + payload.id
  const { data } = await crudService.put(apiEndpoint, payload)
  return data
}

export default function useMutationPhotoAlbumEdit() {
  return useMutation(
    'photoAlbum-edit',
    (payload: PhotoAlbum) => putEditPhotoAlbum(payload),
    {
      onError: (error) => {
        // @ts-ignore
        toast.error(error.message || 'Erro ao editar álbum de fotos')
      },
      onSuccess: () => {
        // @ts-ignore
        toast.success('Álbum de fotos editado com sucesso!')
      }
    }
  )
}
