import React, { useState, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import validate from 'validate.js'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { usuarioAction } from '../../../../_actions'
import {
  Button,
  Checkbox,
  FormHelperText,
  TextField,
  Typography,
  Link,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useDispatch, useSelector } from 'react-redux'

const schema = {
  nome: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    length: {
      maximum: 65
    }
  },
  usuario: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    length: {
      maximum: 65
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    email: true,
    length: {
      maximum: 64
    }
  },
  senha: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    length: {
      maximum: 128
    }
  },
  termosDeUso: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    checked: true
  }
}

const useStyles = makeStyles((theme) => ({
  root: {},
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1)
    }
  },
  termosDeUso: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(-1)
  },
  termosDeUsoCheckbox: {
    marginLeft: '-14px'
  },
  submitButton: {
    marginTop: theme.spacing(0.5),
    width: '100%'
  }
}))

const CadastroForm = (props) => {
  const { className, ...rest } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const loading = useSelector((state) => state.usuario.loading)
  const user = useSelector((state) => state.usuario)
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  })

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  useEffect(() => {
    const errors = validate(formState.values, schema)

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }))
  }, [formState.values])

  const handleChange = (event) => {
    event.persist()

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }))
  }

  const handleChangeUsername = (prop) => (event) => {
    event.persist()

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        usuario: event.target.value
      }
    }))
    dispatch(usuarioAction.onChangeProps(prop, event))
  }

  const handleChangeEmail = (event) => {
    event.persist()

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        email: event.target.value.toLowerCase()
      }
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    let payload = {
      id: 0,
      email: formState.values.email,
      nome: formState.values.nome,
      senha: formState.values.senha,
      username: formState.values.usuario,
      tipoUsuarioId: 1
    }
    dispatch(usuarioAction.createUsuario(payload))
  }

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false

  return (
    <form
      {...rest}
      className={clsx(classes.root, className)}
      onSubmit={handleSubmit}
    >
      <div className={classes.fields}>
        <TextField
          required
          error={hasError('nome')}
          helperText={hasError('nome') ? formState.errors.nome[0] : null}
          label="Nome completo"
          name="nome"
          onChange={handleChange}
          value={formState.values.nome || ''}
          variant="outlined"
        />
        <TextField
          required
          error={hasError('usuario')}
          fullWidth
          helperText={hasError('usuario') ? formState.errors.usuario[0] : null}
          label="Nome de Usuário"
          name="usuario"
          onChange={handleChangeUsername('username')}
          //value={formState.values.usuario || ''}
          value={user.username}
          variant="outlined"
          InputProps={{
            startAdornment: <Typography>@</Typography>
          }}
        />
        <TextField
          required
          error={hasError('email')}
          fullWidth
          helperText={hasError('email') ? 'Esse email não é válido' : null}
          label="Email"
          name="email"
          onChange={handleChangeEmail}
          value={formState.values.email || ''}
          variant="outlined"
        />
        <FormControl
          className={clsx(classes.margin, classes.textField)}
          variant="outlined"
        >
          <InputLabel htmlFor="senha">Senha *</InputLabel>
          <OutlinedInput
            required
            error={hasError('senha')}
            fullWidth
            label="Senha *"
            name="senha"
            onChange={handleChange}
            value={formState.values.senha || ''}
            variant="outlined"
            id="senha"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
          {hasError('senha') && (
            <FormHelperText error>{formState.errors.senha[0]}</FormHelperText>
          )}
        </FormControl>
        <div>
          <div className={classes.termosDeUso}>
            <Checkbox
              checked={formState.values.termosDeUso || false}
              className={classes.termosDeUsoCheckbox}
              color="primary"
              name="termosDeUso"
              onChange={handleChange}
            />
            <Typography color="textPrimary" variant="body1">
              Concordo com os{' '}
              <Link
                color="secondary"
                href="https://corujastermos.s3.us-east-2.amazonaws.com/termos_uso.pdf"
                underline="always"
                target="_blank"
                rel="noopener"
                variant="h6"
              >
                termos de uso
              </Link>{' '}
              e{' '}
              <Link
                color="secondary"
                href="https://corujastermos.s3.us-east-2.amazonaws.com/politica_privacidade.pdf"
                underline="always"
                target="_blank"
                rel="noopener"
                variant="h6"
              >
                políticas de privacidade
              </Link>
            </Typography>
          </div>
          {hasError('termosDeUso') && (
            <FormHelperText error>
              {'Você precisa aceitar os termos de uso e políticas.'}
            </FormHelperText>
          )}
        </div>
      </div>
      {loading ? (
        <Button
          className={classes.submitButton}
          color="primary"
          disabled
          size="large"
          type="submit"
          variant="contained"
        >
          Cadastrando...
        </Button>
      ) : (
        <Button
          className={classes.submitButton}
          color="primary"
          disabled={!formState.isValid}
          size="large"
          type="submit"
          variant="contained"
        >
          Criar conta
        </Button>
      )}
    </form>
  )
}

CadastroForm.propTypes = {
  className: PropTypes.string
}

export default CadastroForm
