import { crudService } from '../_services/'

export const estadoAction = {
  getEstado
}
function getEstado() {
  return (dispatch) => {
    let apiEndpoint = 'estados/'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeEstadosList(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
export function changeEstadosList(estado) {
  return {
    type: 'FETCHED_ALL_ESTADO',
    estado: estado
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
