import { useQuery } from 'react-query'
import { crudService } from '_services'

const getPhotoAlbums = async (placeId: Number) => {
  const { data } = await crudService.get(
    `albuns/estabelecimento?EstabelecimentoId=${placeId}&Order=id`
  )
  return data.result.items
}

export default function usePhotoAlbumList(placeId: Number) {
  return useQuery(['photoAlbum-list', placeId], () => getPhotoAlbums(placeId))
}
