import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useConfirm } from 'material-ui-confirm'
import { toast } from 'react-toastify'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  makeStyles,
  Typography,
  Collapse,
  CardActions,
  InputAdornment,
  CircularProgress
} from '@material-ui/core'

import FacebookIcon from '@material-ui/icons/Facebook'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import PowerOffIcon from '@material-ui/icons/PowerOff'
import PowerIcon from '@material-ui/icons/Power'

//? Own imports
import AccessDenied from 'views/Errors/AccessDenied'
import { HelperToolTip, Page } from 'components'
import { AccessControl } from 'components/Utils'
import constants from 'theme/constants'
import { Estabelecimento } from 'types/api'
import { useMutationEditEstablishment } from 'hooks-querys'

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    ...constants.shadowCard,
    marginBottom: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(2)
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 0, 2, 2)
  },
  gridContainer: {
    marginTop: theme.spacing(1)
  },
  inputField: {
    marginTop: theme.spacing(2)
  },
  subtitle: {
    marginTop: theme.spacing(1)
  },
  connectedMessage: {
    marginTop: theme.spacing(1),
    color: theme.palette.success.main
  }
}))

const Integrations = () => {
  const classes = useStyles()
  const confirm = useConfirm()
  const estabelecimento = useSelector(
    //@ts-ignore
    (state) => state.estabelecimento as Estabelecimento
  )

  const { mutateAsync, isLoading } = useMutationEditEstablishment(
    estabelecimento?.id
  )
  const pixelId = estabelecimento?.facebookPixelId
  const [inputPixelId, setInputPixelId] = useState(pixelId || '')
  const [isEditing, setIsEditing] = useState(false)
  const isEdition = pixelId?.localeCompare(inputPixelId) !== 0
  const isSameString = pixelId?.localeCompare(inputPixelId) !== 0

  const isConnected = !!pixelId
  const showInput = isEditing || isConnected
  const showCardActions = isEditing || isConnected
  const showConnectButton = !isConnected && !isEditing
  const showRemoveButton = !isEditing && isConnected && !isEdition
  const showCancelButton = isEditing
  const buttonTitle = isLoading ? 'Conectando...' : 'Conectar'

  useEffect(() => {
    setInputPixelId(estabelecimento?.facebookPixelId || '')
  }, [estabelecimento])

  const handlePressRemoveButton = () => {
    confirm({
      title: 'Você deseja desconectar o Pixel?',
      description: (
        <div>
          <Typography gutterBottom variant="body1" color="textPrimary">
            Ao desconectar, o Pixel do Facebook deixará de rastrear os eventos e
            conversões deste estabelecimento.
          </Typography>
          <Typography variant="body2" color="textPrimary">
            Obs: Essa ação pode impactar negativamente o desempenho de suas
            campanhas.
          </Typography>
        </div>
      ),
      confirmationText: 'Sim, desconectar!',
      cancellationText: 'Não, cancelar'
    }).then(() => {
      mutateAsync({
        ...estabelecimento,
        facebookPixelId: null
      })
        .then(() => {
          setInputPixelId('')
          setIsEditing(false)
          toast.success('Facebook pixel desconectado com sucesso!')
        })
        .finally(() => {})
    })
  }
  const handleSavePixelId = () => {
    if (inputPixelId.trim() !== '') {
      setIsEditing(false)
      mutateAsync({
        ...estabelecimento,
        facebookPixelId: inputPixelId
      })
        .then(() => {
          toast.success(
            isEdition
              ? 'Facebook pixel alterado com sucesso!'
              : 'Facebook pixel conectado com sucesso!'
          )
        })
        .finally(() => {})
    }
  }

  const handlePressEdit = () => {
    setIsEditing(true)
  }

  const handleChangePixelId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputPixelId(event.target.value)
  }

  const handlePressConnectButton = () => {
    setIsEditing(!isEditing)
  }
  const handlePressCancelEditButton = () => {
    setIsEditing(false)
    setInputPixelId(pixelId || '')
  }

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidFacebookPixelId', (value) => {
      if (value?.length < 15 || value?.length > 20) {
        return false
      } else {
        return true
      }
    })

    return () => {
      ValidatorForm.removeValidationRule('isValidFacebookPixelId')
    }
  }, [])

  return (
    <AccessControl
      rule={'estabelecimentos.edit'}
      // @ts-ignore
      yes={() => (
        <Page
          // @ts-ignore
          className={classes.root}
          title="Estabelecimento - Integrações"
        >
          <Card className={classes.card}>
            <CardHeader title="Integrações" />
            <Divider />
            <CardContent className={classes.content}>
              <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} sm={6} md={6}>
                  <ValidatorForm
                    id="formFacebookPixelId"
                    onSubmit={handleSavePixelId}
                  >
                    <Card variant="outlined">
                      <CardContent>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          gridGap="12px"
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            gridGap="8px"
                          >
                            <FacebookIcon
                              style={{
                                fontSize: 48,
                                color: '#3b5998'
                              }}
                            />
                            <Typography variant="h5">Facebook Pixel</Typography>
                          </Box>
                          {showConnectButton && (
                            <Button
                              disabled={isLoading}
                              variant="outlined"
                              color="primary"
                              onClick={handlePressConnectButton}
                              endIcon={
                                isLoading && <CircularProgress size={18} />
                              }
                              startIcon={<PowerIcon />}
                            >
                              {buttonTitle}
                            </Button>
                          )}
                          {showCancelButton && (
                            <Button
                              disabled={isLoading}
                              variant="outlined"
                              color="primary"
                              onClick={handlePressCancelEditButton}
                            >
                              Cancelar
                            </Button>
                          )}
                          {showRemoveButton && (
                            <Button
                              disabled={isLoading}
                              variant="outlined"
                              color="primary"
                              onClick={handlePressRemoveButton}
                              startIcon={<PowerOffIcon />}
                              endIcon={
                                isLoading && <CircularProgress size={18} />
                              }
                            >
                              {isLoading ? 'Desconectando...' : 'Desconectar'}
                            </Button>
                          )}
                        </Box>
                        {isConnected && (
                          <Typography
                            variant="body2"
                            className={classes.connectedMessage}
                          >
                            Pixel conectado com sucesso!
                          </Typography>
                        )}
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className={classes.subtitle}
                        >
                          Use o Facebook Pixel para rastrear e otimizar seus
                          anúncios, alcançando mais clientes e medindo o
                          desempenho de suas campanhas em tempo real.
                        </Typography>
                        <Collapse in={showInput}>
                          <TextValidator
                            name="facebook-pixel-id"
                            id="facebook-pixel-id"
                            validators={['required', 'isValidFacebookPixelId']}
                            errorMessages={[
                              'Campo obrigatório!',
                              'O número de identificação do Pixel é inválido'
                            ]}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {isEditing && (
                                    <Typography
                                      variant="body2"
                                      align="right"
                                      color={
                                        inputPixelId?.length > 20
                                          ? 'error'
                                          : inputPixelId?.length >= 15
                                          ? 'primary'
                                          : 'initial'
                                      }
                                    >
                                      {inputPixelId?.length || 0}/20
                                    </Typography>
                                  )}
                                  <HelperToolTip>
                                    O Pixel ID é um identificador único para o
                                    seu Pixel do Facebook. Para encontrá-lo,
                                    acesse o Gerenciador de Eventos do Facebook,
                                    selecione o pixel desejado e copie o ID
                                    fornecido. Insira este ID aqui para conectar
                                    o pixel com sucesso.
                                  </HelperToolTip>
                                </InputAdornment>
                              )
                            }}
                            label="Pixel ID"
                            variant="outlined"
                            inputProps={{ type: 'number' }}
                            fullWidth
                            className={classes.inputField}
                            placeholder="Insira seu Pixel ID aqui"
                            value={inputPixelId}
                            onChange={handleChangePixelId}
                            disabled={!isEditing}
                          />
                        </Collapse>
                      </CardContent>
                      {showCardActions && (
                        <CardActions className={classes.actions}>
                          {isConnected && !isEditing && (
                            <Button
                              variant="outlined"
                              color="primary"
                              startIcon={<EditIcon />}
                              onClick={handlePressEdit}
                              disabled={isLoading}
                              endIcon={
                                isLoading && <CircularProgress size={18} />
                              }
                            >
                              {isLoading
                                ? 'Salvando alteração...'
                                : 'Editar Pixel ID'}
                            </Button>
                          )}
                          {isEditing && (
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<SaveIcon />}
                              type="submit"
                              disabled={isLoading || !isSameString}
                            >
                              Salvar
                            </Button>
                          )}
                        </CardActions>
                      )}
                    </Card>
                  </ValidatorForm>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Page>
      )}
      // @ts-ignore
      no={() => <AccessDenied />}
    />
  )
}

export default Integrations
