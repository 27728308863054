import { useQuery } from 'react-query'
import { useMemo } from 'react'

//? Own imports
import { crudService } from '_services'
import { CancelamentoIngresso } from 'types/api'

const getOrderCancel = async (orderCancelId: number | undefined) => {
  const { data } = await crudService.get(
    `cancelamentosIngressos/info/${orderCancelId}`
  )
  return data
}

export default function useOrderCancel(
  orderCancelId: number | undefined,
  orderCancelItem: CancelamentoIngresso | undefined
) {
  const initialData = useMemo(() => orderCancelItem, [orderCancelItem])
  return useQuery<CancelamentoIngresso>(
    ['orderCancel', orderCancelId],
    () => getOrderCancel(orderCancelId),
    {
      initialData,
      enabled: !!orderCancelId
    }
  )
}
