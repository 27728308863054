import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Tooltip
} from '@material-ui/core'
import MapTwoToneIcon from '@material-ui/icons/MapTwoTone'

import { cepMask, cpfCnpjMask } from 'utils'
import { isEmpty } from 'validate.js'
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    ...constants.shadowCard
  },
  content: {
    padding: 0
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
    padding: 5
  }
}))

const CardEstabelecimento = ({ estabelecimento, className, ...rest }) => {
  const classes = useStyles()
  const { isLoading } = useSelector((state) => state.movimentacaoFinanceira)
  return (
    <>
      {!isEmpty(estabelecimento) && (
        <Card {...rest} className={clsx(classes.paper, className)}>
          <CardHeader title="Estabelecimento" />
          <Divider />
          <CardContent className={classes.content}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Nome:</TableCell>
                  <TableCell>
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      <>
                        {estabelecimento.nome}
                        {estabelecimento.latLon && (
                          <Tooltip title="Abrir localização no mapa">
                            <IconButton
                              className={classes.buttonIcon}
                              target="_blank"
                              aria-label="abrir localização do estabelecimento no mapa"
                              href={`https://maps.google.com/?q=${estabelecimento.latLon.x},${estabelecimento.latLon.y}`}
                            >
                              <MapTwoToneIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow selected>
                  <TableCell>CPF/CNPJ: </TableCell>
                  <TableCell>
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      cpfCnpjMask(estabelecimento.cpfCnpj)
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Localização:</TableCell>
                  {!!estabelecimento.cidade && (
                    <TableCell>
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        `${estabelecimento.cidade.nome} - ${estabelecimento.cidade.estado.nome}`
                      )}
                    </TableCell>
                  )}
                </TableRow>
                <TableRow selected>
                  <TableCell>Endereço:</TableCell>
                  <TableCell>
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      `${estabelecimento.endereco}, ${estabelecimento.numero}${
                        estabelecimento.complemento !== null
                          ? ` - ${estabelecimento.complemento}`
                          : ''
                      }`
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Bairro:</TableCell>
                  <TableCell>
                    {isLoading ? <Skeleton /> : estabelecimento.bairro}
                  </TableCell>
                </TableRow>
                <TableRow selected>
                  <TableCell>CEP:</TableCell>
                  <TableCell>
                    {isLoading ? <Skeleton /> : cepMask(estabelecimento.cep)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      )}
    </>
  )
}

CardEstabelecimento.propTypes = {
  className: PropTypes.string,
  estabelecimento: PropTypes.object.isRequired
}

export default CardEstabelecimento
