import { crudService } from '../_services/'
import { history } from '../utils'
import { authActions } from './auth.actions'
import { toast } from 'react-toastify'
import { slugMask } from 'utils/masks'

export const usuarioAction = {
  getUsuario,
  getUsuarioSelect,
  getUsuarioById,
  onChangeProps,
  editUsuarioInfo,
  createUsuario,
  deleteUsuarioById,
  getUsuarioByEstabelecimento,
  emailVerification,
  requestEmailVerification,
  requestPasswordReset,
  passwordReset,
  openModalRequestPasswordReset
}
function getUsuario() {
  return (dispatch) => {
    let apiEndpoint = 'usuarios'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeUsuariosList(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getUsuarioSelect() {
  return (dispatch) => {
    let apiEndpoint = 'usuarios/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeUsuariosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function requestEmailVerification(email) {
  let payload = {
    email: email
  }
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'usuarios/request-email-verification'
    crudService
      .post(apiEndpoint, payload)
      .then(() => {
        dispatch(success())
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request(payload) {
    return { type: 'REQUEST_EMAIL_VERIFICATION_REQUEST', payload }
  }

  function success() {
    return {
      type: 'REQUEST_EMAIL_VERIFICATION_SUCCESS'
    }
  }

  function failure(error) {
    return { type: 'REQUEST_EMAIL_VERIFICATION_FAILURE', error }
  }
}

function createUsuario(payload) {
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'usuarios'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(authActions.login(payload.email, payload.senha))
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request(payload) {
    return { type: 'CREATE_USER_REQUEST', payload }
  }

  function success(user) {
    return {
      type: 'CREATE_USER_SUCCESS',
      user: user
    }
  }

  function failure(error) {
    return { type: 'CREATE_USER_FAILURE', error }
  }
}

function requestPasswordReset(email) {
  let payload = {
    email: email
  }
  return (dispatch) => {
    dispatch(request(email))
    let apiEndpoint = 'usuarios/request-reset-password'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        dispatch(success(response.data))
        if (response && response.request.status === 200) {
          toast.success('E-mail de recuperação enviado! 😎')
        } else if (response && response.request.status == 404) {
          toast.warn('Não existe nenhum usuário com este e-mail')
        } else {
          toast.error('Erro ao recuperar senha do usuário')
        }
      })
      .catch((err) => {
        console.log('err', err)
        dispatch(failure(err))
        toast.error('Erro ao recuperar senha do usuário')
      })
  }
  function request(payload) {
    return { type: 'REQUEST_RESET_PASSWORD_REQUEST', payload }
  }

  function success() {
    return {
      type: 'REQUEST_RESET_PASSWORD_SUCCESS'
    }
  }

  function failure(error) {
    return { type: 'REQUEST_RESET_PASSWORD_FAILURE', error }
  }
}

function passwordReset(payload, token) {
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'usuarios/reset-password'
    crudService
      .postWithToken(apiEndpoint, payload, token)
      .then((response) => {
        if (response && response.request.status === 200) {
          dispatch(success(response.data))
          toast.success('Senha redefinida com sucesso! 😎')
          history.push('/')
        } else if (response && response.request.status === 404) {
          toast.success('Não existe nenhum usuário com o e-mail informado!')
        } else {
          toast.error('Erro ao recuperar senha do usuário')
        }
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }
  function request(payload) {
    return { type: 'RESET_PASSWORD_REQUEST', payload }
  }

  function success() {
    return {
      type: 'RESET_PASSWORD_SUCCESS'
    }
  }

  function failure(error) {
    return { type: 'RESET_PASSWORD_FAILURE', error }
  }
}

function emailVerification(payload, token) {
  return (dispatch) => {
    let apiEndpoint = 'usuarios/email-verification'
    crudService
      .postWithToken(apiEndpoint, payload, token)
      .then((response) => {
        if (response && response.request.status === 200) {
          toast.success('E-mail verificado com sucesso! 😎')
          history.push('/')
          dispatch(success())
        } else if (response && response.request.status === 404) {
          toast.success('Não existe nenhum usuário com o e-mail informado!')
        } else {
          toast.error('Erro ao verificar e-mail do usuário')
        }
      })
      .catch(() => {
        toast.error('Erro ao verificar e-mail do usuário')
      })
  }

  function success() {
    return {
      type: 'VERIFICATION_USER_SUCCESS'
    }
  }
}

function editUsuarioInfo(id, payload) {
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'usuarios/' + id
    crudService
      .put(apiEndpoint, payload)
      .then(() => {
        dispatch(success(payload))
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request(payload) {
    return { type: 'EDIT_USER_REQUEST', payload }
  }

  function success(user) {
    return {
      type: 'EDIT_USER_SUCCESS',
      user: user
    }
  }

  function failure(error) {
    return { type: 'EDIT_USER_FAILURE', error }
  }
}

function getUsuarioById(id) {
  return (dispatch) => {
    let apiEndpoint = 'usuarios/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editUsuariosDetails(response.data))
    })
  }
}
function getUsuarioByEstabelecimento(id) {
  return (dispatch) => {
    let apiEndpoint = 'usuarios/estabelecimento/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(changeUsuariosList(response.data))
    })
  }
}
export function openModalRequestPasswordReset(open) {
  return {
    type: 'OPEN_MODAL_REQUEST_PASSWORD_RESET',
    open: open
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem') {
      dispatch(handleOnChangeProps(props, event))
    } else if (props === 'username') {
      dispatch(handleOnChangeProps(props, slugMask(event.target.value)))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function deleteUsuarioById(id) {
  return (dispatch) => {
    let apiEndpoint = 'usuarios/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteUsuariosDetails())
      dispatch(
        usuarioAction.getUsuarioByEstabelecimento(
          response.data.estabelecimentoId
        )
      )
    })
  }
}
export function changeUsuariosList(usuario) {
  return {
    type: 'FETCHED_ALL_USUARIO',
    usuario: usuario
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'USUARIO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editUsuariosDetails(usuario) {
  return {
    type: 'USUARIO_DETAIL',
    id: usuario._id,
    email: usuario.email,
    nome: usuario.nome,
    senha: usuario.senha,
    telefone: usuario.telefone,
    telefoneVerificado: usuario.telefoneVerificado,
    emailVerificado: usuario.emailVerificado,
    emailVendas: usuario.emailVendas
  }
}
export function createUserInfo() {
  return {
    type: 'USUARIO_CREATED_SUCCESSFULLY'
  }
}
export function deleteUsuariosDetails() {
  return {
    type: 'DELETED_USUARIO_DETAILS'
  }
}
