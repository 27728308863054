import { useQuery } from 'react-query'

//? Own imports
import { crudService } from '_services'

const getUserConnections = async (userId: Number, selectedChip: string) => {
  const { data } = await crudService.get(
    selectedChip === 'followers'
      ? `usuarios/seguidores?UsuarioId=${userId}`
      : `usuarios/seguindo?UsuarioId=${userId}`
  )
  return data
}

export default function useUserConnections(
  userId: Number,
  selectedChip: string
) {
  return useQuery(['user-connections', userId, selectedChip], () =>
    getUserConnections(userId, selectedChip)
  )
}
