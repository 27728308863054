import React from 'react'
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles
} from '@material-ui/core'

//? Own imports
import { history, useSearchQuery } from 'utils'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  divider: {
    height: 28,
    margin: 4
  }
}))

const Filters = () => {
  const classes = useStyles()

  const querySearch = useSearchQuery()
  const selectValue = querySearch.get('Pago')

  const handleRemoveFilterSelectStatus = () => {
    querySearch.delete('Pago')
  }

  const handleChangeSelectStatus = (selectValue: string) => {
    if (!selectValue) {
      handleRemoveFilterSelectStatus()
    } else {
      querySearch.set('Pago', selectValue)
    }
    history.push({ search: querySearch.toString() })
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <FormControl
            fullWidth
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel id="status-subscricao-label">
              Filtrar por status
            </InputLabel>
            <Select
              placeholder="Filtrar por status"
              labelId="status-subscricao-label"
              id="status-subscricao-select"
              value={selectValue}
              onChange={(event) => {
                handleChangeSelectStatus(event.target.value as string)
              }}
              label="Filtrar por status"
            >
              <MenuItem disabled={selectValue === ''} key={''} value={''}>
                {selectValue === '' ? (
                  'Filtrar por status'
                ) : (
                  <strong>Limpar filtro</strong>
                )}
              </MenuItem>
              <MenuItem
                disabled={selectValue === 'true'}
                key={'true'}
                value={'true'}
              >
                Pago
              </MenuItem>
              <MenuItem
                disabled={selectValue === 'false'}
                key={'false'}
                value={'false'}
              >
                Pendente
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  )
}

export default Filters
