import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { categoriaAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import constants from 'theme/constants'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  paper: {
    ...constants.shadowCard
  }
})

const Categoria = (props) => {
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { categoria } = props.categoria

  useEffect(() => {
    dispatch(categoriaAction.getCategoria())
  }, [dispatch])

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir este Categoria?',
      description:
        'Confirmando essa operação, você não poderá cadastrar novos lotes com este tipo de categoria.',
      confirmationText: 'Sim, excluir!',
      cancellationText: 'Nãããoo!'
    }).then(() => {
      dispatch(categoriaAction.deleteCategoriaById(id))
    })
  }

  return (
    <Page className={classes.root} title="Categorias">
      <AccessControl
        rule={'categorias'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {undefined !== categoria && categoria.length > 0 ? (
              <TitleContent
                rule={'categorias.create'}
                length={categoria.length}
                subTitle={'GERÊNCIA DE CATEGORIAS'}
                title={'Lista de categorias'}
                href={'/categoria'}
              />
            ) : (
              <TitleContent
                rule={'categorias.create'}
                length={0}
                subTitle={'GERÊNCIA DE CATEGORIAS'}
                title={'Lista de categorias'}
                href={'/categoria'}
              />
            )}
            <TableContainer component={Paper} className={classes.paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Descrição</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== categoria && categoria.length
                    ? categoria.map((n) => {
                        return (
                          <TableRow hover key={n.id}>
                            <TableCell component="th" scope="row">
                              {n.descricao}
                            </TableCell>
                            <TableCell align="right">
                              <AccessControl
                                rule={'categorias.edit'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="Edit"
                                    component="a"
                                    onClick={() =>
                                      history.push(`/categoria/${n.id}`)
                                    }
                                    size="small"
                                  >
                                    <Tooltip title="Editar">
                                      <Tooltip title="Editar">
                                        <EditIcon />
                                      </Tooltip>
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                              <AccessControl
                                rule={'categorias.delete'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="Delete"
                                    onClick={() => handleClick(n.id)}
                                    size="small"
                                  >
                                    <Tooltip title="Excluir">
                                      <DeleteIcon />
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}
Categoria.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    categoria: state.categoria
  }
}
const connectedCategoriaPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Categoria))
)
export { connectedCategoriaPage as Categoria }
