import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import { history } from 'utils'

const FirstEstabelecimento = () => {
  const { usuarioEstabelecimento } = useSelector(
    (state) => state.usuarioEstabelecimento
  )
  const emailVerificado = useSelector(
    (state) => state.authentication.user.emailVerificado
  )
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(false)
    history.push(`/estabelecimento/`)
  }
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (
      (usuarioEstabelecimento.length === 0 ||
        usuarioEstabelecimento === undefined) &&
      emailVerificado
    ) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [usuarioEstabelecimento, emailVerificado])

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Saudações do Corujas 🦉
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Seja bem vindo a plataforma para gerenciar seus eventos de forma
            simples, rápida e eficaz.
          </Typography>
          <Typography gutterBottom>
            O Corujas e todo seu time estão prontos e dispostos a lhe ajudar em
            quaisquer dúvidas e dificuldades que forem encontradas em nossa
            plataforma.
          </Typography>
          <Typography gutterBottom>
            Notamos que você ainda não cadastrou nenhum organizador e/ou
            estabelecimento. Para liberar e aproveitar todas as funções clique
            no botão abaixo para iniciar seu primeiro cadastro.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClick} color="primary">
            Vamos lá!
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default FirstEstabelecimento
