import React from 'react'
import { eventoAction } from '../../../../_actions'
import { connect, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Tabs, Tab, useMediaQuery, useTheme } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import TitleContentEvent from '../../../TitleContentEvent'
import EventAvailableTwoToneIcon from '@material-ui/icons/EventAvailableTwoTone'
import EventTwoToneIcon from '@material-ui/icons/EventTwoTone'
import { Page } from 'components'
import { FutureEvents, PastEvents } from './components'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  tabs: { marginBottom: theme.spacing(4) }
})

const EventoList = (props) => {
  const dispatch = useDispatch()
  const { classes, match, history } = props
  const { tab } = match.params
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const { evento: events } = useSelector((state) => state.evento)

  const handleClickNewEvento = () => {
    dispatch(eventoAction.prepareStateToNewEvento())
  }

  const handleTabsChange = (event, value) => {
    history.push(value)
  }

  const tabs = [
    {
      value: 'futureEvents',
      label: 'Eventos Futuros',
      icon: <EventTwoToneIcon />
    }
  ]
  {
    tabs.push({
      value: 'pastEvents',
      label: 'Eventos Realizados',
      icon: <EventAvailableTwoToneIcon />
    })
  }

  if (!tab) {
    return <Redirect to={`/eventos/futureEvents`} />
  }

  if (!tabs.find((t) => t.value === tab)) {
    return <Redirect to="/errors/error-404" />
  }

  return (
    <Page className={classes.root}>
      {undefined !== events && events.length > 0 ? (
        <TitleContentEvent
          rule={'eventos.create'}
          length={events.length}
          subTitle={'GERÊNCIA DE EVENTOS'}
          title={'Lista de eventos'}
          onClick={handleClickNewEvento}
        />
      ) : (
        <TitleContentEvent
          rule={'eventos.create'}
          length={0}
          subTitle={'GERÊNCIA DE EVENTOS'}
          title={'Lista de eventos'}
          onClick={handleClickNewEvento}
        />
      )}
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        variant={isMobile ? 'fullWidth' : 'standard'}
        centered={!isMobile}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            icon={tab.icon}
          />
        ))}
      </Tabs>
      <div className={classes.content}>
        {tab === 'futureEvents' && <FutureEvents />}
        {tab === 'pastEvents' && <PastEvents />}
      </div>
    </Page>
  )
}

EventoList.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

const connectedEventoPage = withRouter(
  connect(null, null, null, {
    pure: false
  })(withStyles(styles)(EventoList))
)

export { connectedEventoPage as EventoList }
