const initialState = {
  anchor: 'left',
  permissao: [],
  open: false,
  id: 0,
  nome: '',
  descricao: '',
  excluido: false
}

export function permissao(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_PERMISSAO':
      return {
        ...state,
        permissao: action.permissao
      }
    case 'PERMISSAO_DETAIL':
      return {
        ...state,
        id: action.id,
        nome: action.nome,
        descricao: action.descricao,
        excluido: action.excluido
      }
    case 'PERMISSAO_UPDATED':
      return state
    case 'PERMISSAO_CLEAR':
      return initialState
    case 'PERMISSAO_CLEAR_ALL':
      return {
        ...state,
        permissao: []
      }
    case 'PERMISSAO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    default:
      return state
  }
}
