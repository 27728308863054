import { toast } from 'react-toastify'
import { history } from 'utils'
import { crudService } from '../_services/'

export const conquistaAction = {
  createConquista,
  deleteConquistaById,
  getConquistas,
  addConquista,
  getConquistaById,
  onChangeProps,
  editConquistaInfo,
  editConquistasDetails,
  clear,
  clearAll,
  duplicateConquista,
  findConquistas
}

function getConquistas() {
  return (dispatch) => {
    let apiEndpoint = 'conquistas/'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeConquistasList(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createConquista(payload) {
  return (dispatch) => {
    let apiEndpoint = 'conquistas/'
    crudService.post(apiEndpoint, payload).then(() => {
      dispatch(createConquistaInfo())
      dispatch(clear())
      toast.success('Conquista cadastrada com sucesso! 👍', {
        position: 'top-right'
      })
      history.push('/conquistas')
    })
  }
}

function duplicateConquista(id) {
  return (dispatch) => {
    dispatch(request(id))
    let apiEndpoint = 'conquistas/' + id
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(generateDate(response.data)))
        history.push('/conquista')
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request(id) {
    return { type: 'DUPLICATE_CONQUISTA_REQUEST', id }
  }

  function success(conquista) {
    return {
      type: 'DUPLICATE_CONQUISTA_SUCCESS',
      conquista: conquista
    }
  }

  function failure(error) {
    return { type: 'DUPLICATE_CONQUISTA_FAILURE', error }
  }

  function generateDate(conquista) {
    let payload = {
      ...conquista,
      id: 0,
      nome: conquista.nome,
      descricao: conquista.descricao,
      experiencia: conquista.exp,
      imagem: conquista.imagem
    }
    return payload
  }
}

function findConquistas(textSearch) {
  return (dispatch) => {
    let apiEndpoint = `conquistas/find?Term=${textSearch}`
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeConquistasList(response.data.items))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getConquistaById(id) {
  return (dispatch) => {
    let apiEndpoint = 'conquistas/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editConquistasDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (typeof event === 'object') {
      dispatch(handleOnChangeProps(props, event.target.value))
    } else {
      dispatch(handleOnChangeProps(props, event))
    }
  }
}

function editConquistaInfo(id, payload) {
  return (dispatch) => {
    let apiEndpoint = 'conquistas/' + id
    crudService.put(apiEndpoint, payload).then(() => {
      dispatch(updatedConquistaInfo())
      dispatch(clear())
      history.push('/conquistas')
    })
  }
}

function deleteConquistaById(id) {
  return (dispatch) => {
    let apiEndpoint = 'conquistas/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteConquistasDetails())
      dispatch(getConquistas())
    })
  }
}

export function addConquista(payload) {
  return [{ type: 'CONQUISTA_ADD', conquista: payload }, clear()]
}

export function changeConquistasList(conquista) {
  return {
    type: 'CONQUISTA_FETCHED_ALL',
    conquista: conquista
  }
}

export function clear() {
  return {
    type: 'CONQUISTA_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'CONQUISTA_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'CONQUISTA_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editConquistasDetails(conquista) {
  return {
    type: 'CONQUISTA_DETAIL',
    id: conquista.id,
    nome: conquista.nome,
    descricao: conquista.descricao,
    experiencia: conquista.exp,
    imagem: conquista.imagem
  }
}

export function updatedConquistaInfo() {
  return {
    type: 'CONQUISTA_UPDATED'
  }
}

export function createConquistaInfo() {
  return {
    type: 'CONQUISTA_CREATED_SUCCESSFULLY'
  }
}

export function deleteConquistasDetails() {
  return {
    type: 'CONQUISTA_DELETED_DETAILS'
  }
}
