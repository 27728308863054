import { useCallback } from 'react'
import { useDidMountEffect } from './useDidMountEffect'

export const useDebouncedEffect = (effect, delay, deps) => {
  const callback = useCallback(effect, deps)

  useDidMountEffect(() => {
    const handler = setTimeout(() => {
      callback()
    }, delay)
    return () => {
      clearTimeout(handler)
    }
  }, [callback, delay])
}
