import React from 'react'
import { MdFileUpload, MdError, MdDone } from 'react-icons/md'
import { useDropzone } from 'react-dropzone'
import { uniqueId } from 'lodash'
import filesize from 'filesize'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

//? Own imports
import { ImagePreviewClientSide } from '../../PhotoList'
import { DropContainer, UploadMessage } from './styles'
import { albumAction } from '_actions'

var pluralize = require('pluralize')

function Upload() {
  const dispatch = useDispatch()
  const { foto }: { foto: ImagePreviewClientSide[] } = useSelector(
    // @ts-ignore
    (state) => state.album
  )
  const MAX_FOTOS = 300

  const onDrop = (acceptedFiles: File[]) => {
    if (foto.length + acceptedFiles.length <= MAX_FOTOS) {
      const files: ImagePreviewClientSide[] = acceptedFiles.map((file) => {
        var name = file.name.replaceAll('(', ' ')
        name = name.replaceAll(')', ' ')
        return {
          file,
          id: uniqueId('new'),
          name: name,
          readableSize: filesize(file.size),
          preview: URL.createObjectURL(file),
          progress: 0,
          uploaded: false,
          isError: false,
          url: null,
          retry: false
        }
      })
      dispatch(albumAction.addFoto(files))
    } else {
      toast.error(`O número máximo de fotos para cada álbum é de ${MAX_FOTOS}!`)
    }
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    draggedFiles
  } = useDropzone({
    onDrop,
    onDropRejected(fileRejections) {
      toast.error("Formato não suportado!")
      toast.error(fileRejections.length > 1
        ? `${fileRejections.length} fotos foram rejeitadas!`
        : `${fileRejections.length} foto foi rejeitada!`)
    },
    accept: ['image/jpeg', 'image/png', 'image/webp', 'image/avif', 'image/tiff']
  })

  const renderDragMessage = (isDragActive: boolean, isDragReject: boolean) => {
    if (!isDragActive) {
      return (
        <UploadMessage>
          Clique ou arraste as imagens aqui...
          <br></br>Formatos aceitos: JPEG, PNG, WebP, AVIF e TIFF
          <MdFileUpload size={24} color="#999" />
        </UploadMessage>
      )
    }

    if (isDragReject) {
      return (
        // @ts-ignore
        <UploadMessage type="error">
          Arquivo não suportado!
          <MdError size={24} color="#e57878" />
        </UploadMessage>
      )
    }

    return (
      // @ts-ignore
      <UploadMessage type="success">
        Solte as imagens aqui! <MdDone size={24} color="#78e5d5" />
        {pluralize('arquivos', draggedFiles.length, true)}{' '}
        {pluralize('selecionados', draggedFiles.length)}
      </UploadMessage>
    )
  }

  return (
    <DropContainer
      {...getRootProps()}
      isDragActive={isDragActive}
      isDragReject={isDragReject}
    >
      <input {...getInputProps()} />
      {renderDragMessage(isDragActive, isDragReject)}
    </DropContainer>
  )
}

export default Upload
