import { combineReducers } from 'redux'
import { global } from './global.reducer'
import { authentication } from './auth.reducer'
import { evento } from './evento.reducer'
import { usuario } from './usuario.reducer'
import { tipoIngresso } from './tipoIngresso.reducer'
import { estabelecimento } from './estabelecimento.reducer'
import { cupomDesconto } from './cupomDesconto.reducer'
import { lote } from './lote.reducer'
import { ingresso } from './ingresso.reducer'
import { categoria } from './categoria.reducer'
import { hashtag } from './hashtag.reducer'
import { conquista } from './conquista.reducer'
import { taxa } from './taxa.reducer'
import { usuarioEstabelecimento } from './usuarioEstabelecimento.reducer'
import { contaBancaria } from './contaBancaria.reducer'
import { cidade } from './cidade.reducer'
import { estado } from './estado.reducer'
import { promoter } from './promoter.reducer'
import { dashboard } from './dashboard.reducer'
import { instituicaoBancaria } from './instituicaoBancaria.reducer'
import { permissao } from './permissao.reducer'
import { permissaoTipoUsuario } from './permissaoTipoUsuario.reducer'
import { permissaoUsuario } from './permissaoUsuario.reducer'
import { tipoUsuario } from './tipoUsuario.reducer'
import { parametro } from './parametro.reducer'
import { movimentacaoFinanceira } from './movimentacaoFinanceira.reducer'
import { statusMovimentacaoFinanceira } from './statusMovimentacaoFinanceira.reducer'
import { reducer as formReducer } from 'redux-form'
import { avaliacao } from './avaliacao.reducer'
import { comentario } from './comentario.reducer'
import { album } from './album.reducer'

const appReducer = combineReducers({
  global,
  authentication,
  evento,
  tipoIngresso,
  estabelecimento,
  usuario,
  cupomDesconto,
  lote,
  ingresso,
  categoria,
  hashtag,
  conquista,
  taxa,
  usuarioEstabelecimento,
  contaBancaria,
  estado,
  cidade,
  promoter,
  dashboard,
  instituicaoBancaria,
  permissao,
  permissaoTipoUsuario,
  permissaoUsuario,
  tipoUsuario,
  parametro,
  movimentacaoFinanceira,
  statusMovimentacaoFinanceira,
  avaliacao,
  comentario,
  album,
  form: formReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_SUCCESS') {
    const { routing } = state
    state = { routing }
  }
  return appReducer(state, action)
}
export default rootReducer
