const initialState = {
  dashboardHome: {
    quantidadePedidosPendentes: 0,
    quantidadePagamentos7Dias: 0,
    valorPagamentos7Dias: 0,
    eventosAtivos: 0
  },
  dashboardEstabelecimento: {
    quantidadeIngressosNaoTransferidos: 0,
    valorIngressosNaoTransferidos: 0,
    quantidadeIngressosTransferidos: 0,
    valorIngressosTransferidos: 0,
    eventosFuturos: 0,
    eventosPassados: 0,
    graficoIngressosEventos: { options: [], series: [] },
    graficoIngressosMes: {}
  },
  dashboardEvento: {
    quantidadePedidosPendentes: 0,
    valorPedidosPendentes: 0,
    quantidadeIngressos: 0,
    valorIngressos: 0,
    quantidadeIngressosDisponiveis: 0,
    quantidadeIngressosTotal: 0,
    cuponsAtivos: 0,
    graficoIngressosDia: { options: [], series: [] },
    graficoIngressosTipoIngresso: {},
    pedidos: []
  },
  dashboardAdmin: {
    receitaBruta: {
      valorAtual: 0,
      valorAnterior: 0,
      percentualComparativo: 0
    },
    lucroLiquido: {
      valorAtual: 0,
      valorAnterior: 0,
      percentualComparativo: 0
    },
    valorATranferir: 0,
    tarifaMedia: {
      valorAtual: 0,
      valorAnterior: 0,
      percentualComparativo: 0
    },
    eventosRealizados: {
      valorAtual: 0,
      valorAnterior: 0,
      percentualComparativo: 0
    },
    interacoesUsuarios: {
      totalInteracoes: {
        valorAtual: 0,
        valorAnterior: 0,
        percentualComparativo: 0
      },
      totalComentarios: {
        valorAtual: 0,
        valorAnterior: 0,
        percentualComparativo: 0
      },
      totalConexoes: {
        valorAtual: 0,
        valorAnterior: 0,
        percentualComparativo: 0
      },
      totalAvaliacoes: {
        valorAtual: 0,
        valorAnterior: 0,
        percentualComparativo: 0
      },
      novosUsuarios: {
        valorAtual: 0,
        valorAnterior: 0,
        percentualComparativo: 0
      },
      usuariosAtivos: {
        valorAtual: 0,
        valorAnterior: 0,
        percentualComparativo: 0
      },
      atividades: []
    },
    graficoVendasPorFormaPagamento: { options: [], series: [] },
    graficoVendasPorPlataforma: { options: [], series: [] },
    graficoInteracoesPorTipo: { options: [], series: [] }
  },
  isLoading: false
}

export function dashboard(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_DASHBOARD_ADMIN_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'FETCHED_DASHBOARD_ADMIN_SUCCESS':
      return {
        ...state,
        dashboardAdmin: action.dashboardAdmin,
        isLoading: false
      }
    case 'FETCHED_DASHBOARD_ADMIN_FAILURE':
      return {
        ...state,
        isLoading: false
      }
    case 'FETCHED_DASHBOARD_HOME_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'FETCHED_DASHBOARD_HOME_SUCCESS':
      return {
        ...state,
        isLoading: false,
        dashboardHome: action.dashboardHome
      }
    case 'FETCHED_DASHBOARD_HOME_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_DASHBOARD_ESTABELECIMENTO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'FETCHED_DASHBOARD_ESTABELECIMENTO_SUCCESS':
      return {
        ...state,
        isLoading: false,
        dashboardEstabelecimento: action.dashboardEstabelecimento
      }
    case 'FETCHED_DASHBOARD_ESTABELECIMENTO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_DASHBOARD_EVENTO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'FETCHED_DASHBOARD_EVENTO_SUCCESS':
      return {
        ...state,
        isLoading: false,
        dashboardEvento: action.dashboardEvento
      }
    case 'FETCHED_DASHBOARD_EVENTO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}
