import React, { useEffect, useState } from 'react'
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  Switch,
  TextField,
  InputAdornment,
  IconButton,
  Divider,
  useTheme,
  useMediaQuery,
  Chip,
  CircularProgress,
  Tooltip
} from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Autocomplete } from '@material-ui/lab'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import CloseIcon from '@material-ui/icons/Close'

//? Own imports
import { useDidMountEffect } from 'utils/useDidMountEffect'
import { useEvents, useStatusIngressoSelect } from 'hooks-querys'
import { history, toLocalDateFormat } from 'utils'
import { Evento } from 'types/api'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    width: '100%'
  },
  divider: {
    height: 28,
    margin: 4
  }
}))

function useSearchQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const SelectFilters = () => {
  const classes = useStyles()
  const theme = useTheme()
  const querySearch = useSearchQuery()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [selectedEvento, setSelectedEvento] = useState<Evento | null>(null)
  const [eventoInputValue, setEventoInputValue] = useState('')
  const [showPastEvents, setShowPastEvents] = useState(false)

  const estabelecimentoId = useSelector(
    // @ts-ignore: //ainda não foi tipado o redux state
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const [textSearch, setTextSearch] = useState(
    querySearch.get('textSearch') || ''
  )
  const [statusId, setStatusId] = useState(querySearch.get('statusId') || '0')
  const {
    data: events,
    isLoading: isLoadingEvents,
    isFetching: isFetchingEvents
  } = useEvents(estabelecimentoId, showPastEvents, isMobile)
  const {
    data: statusIngressoSelect,
    isLoading: isLoadingStatusIngressoSelect
  } = useStatusIngressoSelect()

  useEffect(() => {
    //? função executada para buscar o evento o qual esta na url (?eventId=)
    //? se não existir evento na url não faz nada

    const eventId = querySearch.get('eventId') || 0
    if (eventId && Number(eventId) > 0) {
      const event =
        events &&
        events.length > 0 &&
        events.find((e) => e.id === Number(eventId))
      //? se encontrar o evento na url, seta o evento selecionado,
      //? se não encontrar, troca a o estado showPastEvents para true,
      //? pois o evento informado pode ser um evento passado, e não existir na lista de eventos;
      //? com isso, a lista de evento efetua um novo fetch para buscar os eventos passados,
      //? e gera um novo efeito colateral caindo nesse useEffect novamente

      if (event) {
        setSelectedEvento(event)
      } else if (selectedEvento === null) {
        setShowPastEvents(!showPastEvents)
      }
    }
  }, [events])

  const handleChangeText = (value: string) => {
    setTextSearch(value)
  }

  const runQuery = () => {
    history.push(
      `/ingressos/lista?eventId=${
        selectedEvento && selectedEvento.id ? selectedEvento.id : 0
      }&textSearch=${textSearch}&statusId=${statusId ? statusId : 0}`
    )
  }

  const handleSubmitSearch = (event: React.FormEvent) => {
    event.preventDefault()
    if (textSearch && textSearch.length > 0) {
      runQuery()
    }
  }

  const clearTextSearch = () => {
    setTextSearch('')
  }

  useDidMountEffect(() => {
    if (textSearch === '') {
      runQuery()
    }
  }, [textSearch])

  useDidMountEffect(() => {
    history.push('/ingressos')
  }, [estabelecimentoId])

  useDidMountEffect(() => {
    if (selectedEvento && selectedEvento.id) {
      runQuery()
    }
  }, [statusId, selectedEvento])

  useEffect(() => {
    if (selectedEvento && selectedEvento.id) {
      if (eventoInputValue === '') {
        setEventoInputValue(
          selectedEvento.nome &&
            `${selectedEvento.nome} ${
              selectedEvento.dataHoraInicio
                ? ` - (${toLocalDateFormat(selectedEvento.dataHoraInicio)})`
                : ''
            }`
        )
      }
    }
  }, [selectedEvento])

  useDidMountEffect(() => {
    setStatusId(querySearch.get('statusId') || '0')
    setTextSearch(querySearch.get('textSearch') || '')
  }, [querySearch])

  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <Autocomplete
              loading={isLoadingEvents}
              id="combo-box-evento"
              options={events || []}
              value={selectedEvento}
              onChange={(event, newValue, reason) => {
                setSelectedEvento(newValue)
                if (reason === 'clear') {
                  //? se o usuário clicar no botão limpar
                  setEventoInputValue('')
                  history.push('/ingressos')
                }
              }}
              getOptionLabel={(evento) =>
                evento.nome &&
                `${evento.nome} ${
                  evento.dataHoraInicio
                    ? ` - (${toLocalDateFormat(evento.dataHoraInicio)})`
                    : ''
                }`
              }
              placeholder="Selecione o evento..."
              onInputChange={(event, newInputValue) => {
                newInputValue !== null
                  ? setEventoInputValue(newInputValue)
                  : setEventoInputValue('')
              }}
              inputValue={eventoInputValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Selecione o evento"
                  placeholder="Selecione o evento..."
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {!isMobile && (
                          <InputAdornment position="end">
                            <>
                              {isFetchingEvents && (
                                <CircularProgress
                                  style={{ marginRight: 10 }}
                                  size={20}
                                  color="primary"
                                />
                              )}
                              <Switch
                                color="primary"
                                onChange={(event) => {
                                  setShowPastEvents(event.target.checked)
                                }}
                                checked={showPastEvents}
                                size="small"
                                inputProps={{
                                  'aria-label': 'Listar eventos encerrados'
                                }}
                              />
                              <Chip
                                variant="default"
                                size="small"
                                label="Listar eventos encerrados"
                                color={showPastEvents ? 'primary' : 'default'}
                                onClick={() => {
                                  setShowPastEvents(!showPastEvents)
                                }}
                              />
                            </>
                          </InputAdornment>
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
        {selectedEvento && selectedEvento.id > 0 && (
          <>
            <Grid item xs={12} sm={6}>
              <form autoComplete="off" onSubmit={handleSubmitSearch}>
                <TextField
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {textSearch.length >= 1 && (
                          <>
                            <Tooltip title="Limpar">
                              <IconButton onClick={clearTextSearch}>
                                <CloseIcon opacity="0.8" />
                              </IconButton>
                            </Tooltip>
                            <Divider
                              className={classes.divider}
                              orientation="vertical"
                            />
                          </>
                        )}
                        <Tooltip title="Buscar">
                          <IconButton
                            type="submit"
                            aria-label="mostrar ingressos validos ou pendentes"
                          >
                            <SearchRoundedIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    )
                  }}
                  fullWidth
                  required
                  value={textSearch}
                  onChange={(event) =>
                    handleChangeText && handleChangeText(event.target.value)
                  }
                  variant="outlined"
                  id="textForSearch"
                  label="Buscar"
                  placeholder="Busque por nome, CPF ou N.º..."
                />
              </form>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="status-ingresso-label">
                  Filtrar por status
                </InputLabel>
                <Select
                  disabled={isLoadingStatusIngressoSelect}
                  placeholder="Filtrar por status"
                  labelId="status-ingresso-label"
                  id="status-ingresso-select"
                  value={statusId.toString()}
                  onChange={(event) => {
                    setStatusId(event.target.value as string)
                  }}
                  label="Filtrar por status"
                >
                  <MenuItem disabled={statusId === '0'} key={'0'} value={'0'}>
                    {statusId === '0' ? (
                      'Filtrar por status'
                    ) : (
                      <strong>Limpar filtro</strong>
                    )}
                  </MenuItem>
                  {statusIngressoSelect &&
                    statusIngressoSelect.length > 0 &&
                    statusIngressoSelect.map((statusIngresso) => (
                      <MenuItem
                        disabled={
                          statusIngresso.value == statusId.toString()
                            ? true
                            : false
                        }
                        key={statusIngresso.value}
                        value={statusIngresso.value}
                      >
                        {statusIngresso.text}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  )
}

export default SelectFilters
