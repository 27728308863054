export default function getMonthByNumber(monthNumber) {
  if (monthNumber !== undefined) {
    var monthNumberSplit = monthNumber.split('/', 2)
    var meses = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ]
    return meses[monthNumberSplit[1] - 1]
  } else return null
}
