import { useQuery } from 'react-query'

//? Own imports
import { crudService } from '_services'

type UseOrdersCancelParams = {
  page: number
  limit: number
  term: string
  order: string
  direction: string
  statusCancelamentoIngressoId: string
  motivoCancelamentoIngressoId?: string
}

const getOrdersCancel = async ({
  page,
  limit,
  term,
  order,
  direction,
  statusCancelamentoIngressoId,
  motivoCancelamentoIngressoId
}: UseOrdersCancelParams) => {
  const { data } = await crudService.get(
    `cancelamentosIngressos?Page=${page}&Limit=${limit}&Term=${term}&Order=${order}&Direction=${direction}&StatusCancelamentoIngressoId=${statusCancelamentoIngressoId}&MotivoCancelamentoIngressoId=${motivoCancelamentoIngressoId}`
  )
  return data
}

export default function useOrdersCancel(params: UseOrdersCancelParams) {
  return useQuery(
    [
      'ordersCancel',
      params.limit,
      params.term,
      params.order,
      params.direction,
      params.statusCancelamentoIngressoId,
      params.motivoCancelamentoIngressoId
    ],
    () => getOrdersCancel(params)
  )
}
