import React, { useEffect, useState } from 'react'
import { Box, LinearProgress, makeStyles, Typography } from '@material-ui/core'

//? Own imports
import { AccessControl } from 'components/Utils'
import { Header, Page, SkeletonTable } from 'components'
import AccessDenied from 'views/Errors/AccessDenied'
import { Filters, TableUsers } from './components'
import { useUsers } from 'hooks-querys'
import { useLocation } from 'react-router-dom'
import { User } from 'types/api'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  header: {
    marginBottom: theme.spacing(4)
  }
}))

function useSearchQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const CustomerManagementList = () => {
  const classes = useStyles()
  const querySearch = useSearchQuery()
  const [filters, setFilters] = useState({
    // page: Number(querySearch.get('page')) || 1,
    textSearch: querySearch.get('textSearch') || ''
  })

  useEffect(() => {
    setFilters({
      // page: Number(querySearch.get('page')) || 1,
      textSearch: querySearch.get('textSearch') || ''
    })
  }, [querySearch])

  const { data, isLoading, refetch, isFetching, isSuccess } = useUsers({
    page: 1, // valor fixo para paginação, pois o componente de paginação não está implementado
    direction: 'desc',
    order: '',
    limit: 10000, //limite alto para não haver paginação
    term: filters.textSearch
  })
  return (
    <Page title={'Lista de usuários - Gerência de usuários'}>
      <AccessControl
        rule={'usuarios.listAdmin'} //permissão necessária para acessar conteúdo
        // @ts-ignore
        yes={() => (
          <>
            {isFetching && (
              <LinearProgress
                style={{
                  width: '100%',
                  position: 'absolute'
                }}
              />
            )}
            <div className={classes.root}>
              <Header
                className={classes.header}
                subtitle="GERÊNCIA DE USUÁRIOS"
                title="Lista de usuários"
                isRefreshing={isLoading}
                onRefresh={refetch}
              />
              <Filters />
              {(filters.textSearch === undefined ||
                filters.textSearch.length < 3) && (
                <Box mt={1}>
                  <Typography variant="body1" color="textSecondary">
                    Digite pelo menos 3 caracteres para iniciar a busca
                  </Typography>
                </Box>
              )}
              {isLoading ? (
                <SkeletonTable />
              ) : (
                isSuccess && (
                  <TableUsers users={data?.items || ([] as User[])} />
                )
              )}
            </div>
          </>
        )}
        // @ts-ignore
        no={() => <AccessDenied />}
      />
    </Page>
  )
}

export default CustomerManagementList
