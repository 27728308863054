import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
  Divider,
  Tooltip,
  Switch,
  Chip,
  useMediaQuery,
  useTheme,
  CircularProgress
} from '@material-ui/core'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import CloseIcon from '@material-ui/icons/Close'

//? Own imports
import { useDidMountEffect } from 'utils/useDidMountEffect'
import { useStatusPedidoSelect, useFormaPagamentoSelect, useEvents, useOrders } from 'hooks-querys'
import { history, toLocalDateFormat } from 'utils'
import { Autocomplete } from '@material-ui/lab'
import { Evento } from 'types/api'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4)
  },
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  divider: {
    height: 28,
    margin: 4
  }
}))

function useSearchQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const Filters = () => {
  const classes = useStyles()
  const theme = useTheme()
  const querySearch = useSearchQuery()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [selectedEvento, setSelectedEvento] = useState<Evento | null>(null)
  const [eventoInputValue, setEventoInputValue] = useState('')
  const [showPastEvents, setShowPastEvents] = useState(false)
    const estabelecimentoId = useSelector(
    // @ts-ignore: //ainda não foi tipado o redux state
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const {
    data: events,
    isLoading: isLoadingEvents,
    isFetching: isFetchingEvents
  } = useEvents(estabelecimentoId, showPastEvents, isMobile)

  const [filters, setFilters] = useState({
    textSearch: querySearch.get('Term') || '',
    selectedStatusPedidoId: querySearch.get('StatusPedidoId') || '0',
    selectedFormaPagamentoId: querySearch.get('FormaPagamentoId') || '0',
    dataHoraInicio: querySearch.get('DataHoraInicio') || '',
    dataHoraFim: querySearch.get('DataHoraFim') || ''
  })
  const {
    data: statusPedidoSelect,
    isLoading: isLoadingstatusPedidoSelect
  } = useStatusPedidoSelect()

  const {
    data: formaPagamentoSelect,
    isLoading: isLoadingFormaPagamentoSelect
  } = useFormaPagamentoSelect()

  useEffect(() => {
    const eventId = querySearch.get('EventoId') || 0
    if (eventId && Number(eventId) > 0) {
      const event =
        events &&
        events.length > 0 &&
        events.find((e) => e.id === Number(eventId))

      if (event) {
        setSelectedEvento(event)
      } else if (selectedEvento === null) {
        setShowPastEvents(!showPastEvents)
      }
    }
  }, [events])

  const runQuery = () => {
    history.push(
      `/pedidos/admin?StatusPedidoId=${
        filters.selectedStatusPedidoId
      }&FormaPagamentoId=${
        filters.selectedFormaPagamentoId ? filters.selectedFormaPagamentoId : 0
      }&Term=${filters.textSearch
      }&DataHoraInicio=${filters.dataHoraInicio
      }&DataHoraFim=${filters.dataHoraFim
      }&EventoId=${selectedEvento && selectedEvento.id ? selectedEvento.id : ''}`
    )
  }

  const handleChangeText = (value: string) => {
    setFilters((prev) => ({ ...prev, textSearch: value }))
  }

  const handleSubmitSearch = (event: React.FormEvent) => {
    event.preventDefault()
    if (filters.textSearch && filters.textSearch.length > 0) {
      runQuery()
    }
  }

  const clearTextSearch = () => {
    setFilters((prev) => ({ ...prev, textSearch: '' }))
  }

  useDidMountEffect(() => {
    if (filters.textSearch === '') {
      runQuery()
    }
  }, [filters.textSearch])

  useDidMountEffect(() => {
    runQuery()
  }, [filters.selectedStatusPedidoId, filters.selectedFormaPagamentoId])

  useDidMountEffect(() => {
    history.push(
      `/pedidos/admin?StatusPedidoId=${
        filters.selectedStatusPedidoId
      }&FormaPagamentoId=${
        filters.selectedFormaPagamentoId ? filters.selectedFormaPagamentoId : 0
      }&Term=${filters.textSearch
      }&DataHoraInicio=${filters.dataHoraInicio
      }&DataHoraFim=${filters.dataHoraFim
      }&EventoId=${selectedEvento && selectedEvento.id ? selectedEvento.id : ''}`
    )
  }, [estabelecimentoId])

  useDidMountEffect(() => {
    if (selectedEvento && selectedEvento.id) {
      runQuery()
    }
  }, [selectedEvento])

  useEffect(() => {
    if (selectedEvento && selectedEvento.id) {
      if (eventoInputValue === '') {
        setEventoInputValue(
          selectedEvento.nome &&
            `${selectedEvento.nome} ${
              selectedEvento.dataHoraInicio
                ? ` - (${toLocalDateFormat(selectedEvento.dataHoraInicio)})`
                : ''
            }`
        )
      }
    }
  }, [selectedEvento])

  useDidMountEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedStatusPedidoId: querySearch.get('StatusPedidoId') || '0',
      selectedFormaPagamentoId: querySearch.get('FormaPagamentoId') || '0',
      textSearch: querySearch.get('Term') || '',
      dataHoraInicio: querySearch.get('DataHoraInicio') || '',
      dataHoraFim: querySearch.get('DataHoraFim') || ''
    }))
  }, [querySearch])

  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel id="status-pedido-label">Filtrar por status</InputLabel>
            <Select
              disabled={isLoadingstatusPedidoSelect}
              placeholder="Filtrar por status"
              labelId="status-pedido-label"
              id="status-pedido-select"
              value={filters.selectedStatusPedidoId.toString()}
              onChange={(event) => {
                setFilters((prev) => ({
                  ...prev,
                  selectedStatusPedidoId: event.target.value as string
                }))
              }}
              label="Filtrar por status"
            >
              <MenuItem
                disabled={filters.selectedStatusPedidoId === '0'}
                key={'0'}
                value={'0'}
              >
                {filters.selectedStatusPedidoId === '0' ? (
                  'Filtrar por status'
                ) : (
                  <strong>Limpar filtro</strong>
                )}
              </MenuItem>
              {statusPedidoSelect &&
                statusPedidoSelect.length > 0 &&
                statusPedidoSelect.map((statusPedido) => (
                  <MenuItem
                    disabled={
                      statusPedido.value ==
                      filters.selectedStatusPedidoId.toString()
                        ? true
                        : false
                    }
                    key={statusPedido.value}
                    value={statusPedido.value}
                  >
                    {statusPedido.text}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel id="forma-pagamento-label">
              Filtrar por forma de pagamento
            </InputLabel>
            <Select
              disabled={isLoadingFormaPagamentoSelect}
              placeholder="Filtrar por forma de pagamento"
              labelId="forma-pagamento-label"
              id="forma-pagamento-select"
              value={filters.selectedFormaPagamentoId.toString()}
              onChange={(event) => {
                setFilters((prev) => ({
                  ...prev,
                  selectedFormaPagamentoId: event.target.value as string
                }))
              }}
              label="Filtrar por forma de pagamento"
            >
              <MenuItem
                disabled={filters.selectedFormaPagamentoId === '0'}
                key={'0'}
                value={'0'}
              >
                {filters.selectedFormaPagamentoId === '0' ? (
                  'Filtrar por forma de pagamento'
                ) : (
                  <strong>Limpar filtro</strong>
                )}
              </MenuItem>
              {formaPagamentoSelect &&
                formaPagamentoSelect.length > 0 &&
                formaPagamentoSelect.map((formaPagamento) => (
                  <MenuItem
                    disabled={
                      formaPagamento.value ==
                      filters.selectedFormaPagamentoId.toString()
                        ? true
                        : false
                    }
                    key={formaPagamento.value}
                    value={formaPagamento.value}
                  >
                    {formaPagamento.text}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <Autocomplete
              loading={isLoadingEvents}
              id="combo-box-evento"
              options={events || []}
              value={selectedEvento}
              onChange={(event, newValue, reason) => {
                setSelectedEvento(newValue)
                if (reason === 'clear') {
                  setEventoInputValue('')
                  history.push(
                    `/pedidos/admin?StatusPedidoId=${
                      filters.selectedStatusPedidoId
                    }&FormaPagamentoId=${
                      filters.selectedFormaPagamentoId ? filters.selectedFormaPagamentoId : 0
                    }&Term=${filters.textSearch}&DataHoraInicio=${filters.dataHoraInicio
                    }&DataHoraFim=${filters.dataHoraFim
                    }&EventoId=`
                  )
                }
              }}
              getOptionLabel={(evento) =>
                evento.nome &&
                `${evento.nome} ${
                  evento.dataHoraInicio
                    ? ` - (${toLocalDateFormat(evento.dataHoraInicio)})`
                    : ''
                }`
              }
              placeholder="Selecione o evento..."
              onInputChange={(event, newInputValue) => {
                newInputValue !== null
                  ? setEventoInputValue(newInputValue)
                  : setEventoInputValue('')
              }}
              inputValue={eventoInputValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Selecione o evento"
                  placeholder="Selecione o evento..."
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {!isMobile && (
                          <InputAdornment position="end">
                            <>
                              {isFetchingEvents && (
                                <CircularProgress
                                  style={{ marginRight: 10 }}
                                  size={20}
                                  color="primary"
                                />
                              )}
                              <Switch
                                color="primary"
                                onChange={(event) => {
                                  setShowPastEvents(event.target.checked)
                                }}
                                checked={showPastEvents}
                                size="small"
                                inputProps={{
                                  'aria-label': 'Listar eventos encerrados'
                                }}
                              />
                              <Chip
                                variant="default"
                                size="small"
                                label="Listar eventos encerrados"
                                color={showPastEvents ? 'primary' : 'default'}
                                onClick={() => {
                                  setShowPastEvents(!showPastEvents)
                                }}
                              />
                            </>
                          </InputAdornment>
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <form autoComplete="off" onSubmit={handleSubmitSearch}>
            <TextField
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {filters.textSearch.length >= 1 && (
                      <>
                        <Tooltip title="Limpar">
                          <IconButton onClick={clearTextSearch}>
                            <CloseIcon opacity="0.8" />
                          </IconButton>
                        </Tooltip>
                        <Divider
                          className={classes.divider}
                          orientation="vertical"
                        />
                      </>
                    )}
                    <Tooltip title="Buscar">
                      <IconButton type="submit" aria-label="buscar">
                        <SearchRoundedIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                )
              }}
              fullWidth
              //required
              value={filters.textSearch}
              onChange={(event) =>
                handleChangeText && handleChangeText(event.target.value)
              }
              variant="outlined"
              id="textForSearch"
              label="Buscar"
              placeholder="Busque por id, nome, email, CPF/CNPJ, username e evento..."
            />
          </form>
        </Grid>
      </Grid>
    </div>
  )
}

export default Filters
