import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { hashtagAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import { Page } from 'components'
///////////////////////////////////////////////////////////////////////////////
// material-ui imports
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  MenuItem
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { UploadField } from 'components/Utils/Uploads'
import { toast } from 'react-toastify'
import constants from 'theme/constants'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  paper: {
    ...constants.shadowCard
  }
})

class HashtagEdit extends Component {
  getURL = (prop) => (url) => {
    const { dispatch } = this.props
    dispatch(hashtagAction.onChangeProps(prop, url))
  }

  handleChange = (prop) => (event) => {
    const { dispatch } = this.props
    dispatch(hashtagAction.onChangeProps(prop, event))
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props
    const { dispatch } = this.props
    if (params.id) {
      dispatch(hashtagAction.getHashtagById(params.id))
    } else {
      dispatch(hashtagAction.clear())
    }
  }

  handleSubmit(event) {
    const {
      match: { params }
    } = this.props
    const { dispatch } = this.props

    if (this.props.hashtag.imagem === ('' || null)) {
      toast.error('Você precisa adicionar uma imagem para a hashtag!')
    } else {
      let payload = {
        id: params.id,
        descricao: this.props.hashtag.descricao,
        imagem: this.props.hashtag.imagem,
        banner: this.props.hashtag.banner,
        categoriaId: this.props.hashtag.categoriaId
      }

      if (params.id) {
        dispatch(hashtagAction.editHashtagInfo(params.id, payload))
      } else {
        dispatch(hashtagAction.createHashtag(payload))
      }
    }
  }

  render() {
    const { classes } = this.props
    const {
      match: { params }
    } = this.props
    const { categoria } = this.props.categoria

    function InsertText() {
      return (
        <Typography variant="h4">
          <b>{'Nova Hashtag'}</b>
        </Typography>
      )
    }

    function EditText() {
      return (
        <Typography variant="h4">
          <b>{'Editar Hashtag'}</b>
        </Typography>
      )
    }

    function HeaderSelection() {
      if (params.id) {
        return <EditText />
      }
      return <InsertText />
    }

    return (
      <Page
        className={classes.root}
        title={
          params.id
            ? 'Gerência de Hashtags - Editar Hashtag'
            : 'Gerência de Hashtags - Nova Hashtag'
        }
      >
        <Grid item md={12} xs={12}>
          <Card className={classes.paper}>
            <form>
              <ValidatorForm
                className={classes.form}
                ref="formHashtag"
                id="formHashtag"
                onSubmit={(event) => this.handleSubmit(event)}
                instantValidate
              >
                <CardHeader
                  //subheader="Cadastro Hashtag"
                  title={<HeaderSelection />}
                />
                <Divider />
                <CardContent>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="descricao"
                        label="hashtag"
                        placeholder="nome"
                        margin="normal"
                        onChange={this.handleChange('descricao')}
                        value={this.props.hashtag.descricao}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <SelectValidator
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        id="categoriaId"
                        label="Categoria *"
                        value={this.props.hashtag.categoriaId}
                        onChange={this.handleChange('categoriaId')}
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        labelId="categoriaId"
                      >
                        <MenuItem disabled value="">
                          <em>Categoria *</em>
                        </MenuItem>
                        {categoria.map((row) => (
                          <MenuItem key={row.id} value={row.id}>
                            {row.descricao}
                          </MenuItem>
                        ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <UploadField
                        parentCallback={this.getURL('imagem')}
                        url={this.props.hashtag.imagem}
                        labelText={'Imagem da hashtag*'}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <UploadField
                        parentCallback={this.getURL('banner')}
                        url={this.props.hashtag.banner}
                        labelText={'Banner da hashtag'}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Button
                    color="secondary"
                    onClick={() => history.push('/hashtags')}
                    variant="outlined"
                  >
                    Cancelar
                  </Button>
                  <Button color="primary" type="submit" variant="outlined">
                    {params.id ? 'Atualizar' : 'Salvar'}
                  </Button>
                </CardActions>
              </ValidatorForm>
            </form>
          </Card>
        </Grid>
      </Page>
    )
  }
}

HashtagEdit.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return state
}

const connectedHashtagEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(HashtagEdit))
)

export { connectedHashtagEditPage as HashtagEdit }
