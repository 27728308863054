import React from 'react'
import {
  TableBody,
  Table,
  makeStyles,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Toolbar,
  Tooltip,
  IconButton,
  Box,
  BoxProps
} from '@material-ui/core'
import PrintRoundedIcon from '@material-ui/icons/PrintRounded'
import ReactToPrint from 'react-to-print'

//? Own imports
import { Pedido } from 'types/api'
import { RowTableOrderManagementList } from './components'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700
  },
  rootToolbarStyles: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  titleToolbarStyles: {
    flex: '1 1 100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    ...constants.shadowCard
  }
}))

type TableOrderManagementListProps = BoxProps & {
  orders: Pedido[]
}

const TableOrderManagementList = ({
  orders,
  ...rest
}: TableOrderManagementListProps) => {
  const classes = useStyles()
  const refTable = React.useRef(null)

  return (
    <Box {...rest}>
      <Typography variant="body2" align="left" gutterBottom>
        {orders && orders.length > 0 ? (
          <>
            Total de dados encontrados: <strong>{orders.length}</strong>
          </>
        ) : (
          <strong>Nenhum dado encontrado.</strong>
        )}
      </Typography>
      {orders && orders.length > 0 && (
        <Paper ref={refTable} className={classes.paper}>
          <Toolbar className={classes.rootToolbarStyles}>
            <Typography
              className={classes.titleToolbarStyles}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Lista de pedidos
            </Typography>
            <ReactToPrint
              trigger={() => (
                <Tooltip title="Imprimir">
                  <IconButton aria-label="gerar relatório">
                    <PrintRoundedIcon />
                  </IconButton>
                </Tooltip>
              )}
              content={() => refTable.current}
            />
          </Toolbar>
          {/* usado para 'forçar' a orientação da pagina para 'paisagem' afim de imprimir mostrando todos os dados */}
          <style type="text/css" media="print">
            {'\
             @page { size: landscape; }\
            '}
          </style>
          {/* ------------------------------------------------------------>	 */}
          <TableContainer>
            {orders !== undefined && orders.length > 0 && (
              <Table className={classes.table} aria-label="tabela de pedidos">
                <TableHead>
                  <TableRow>
                    <TableCell>N.º</TableCell>
                    <TableCell align="center">Usuário</TableCell>
                    <TableCell align="center">Evento</TableCell>
                    <TableCell align="center">Forma pagamento</TableCell>
                    <TableCell align="center">Data compra</TableCell>
                    <TableCell align="center">Data validade</TableCell>
                    <TableCell align="center">Valor (R$)</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map((orderItem) => {
                    return (
                      <RowTableOrderManagementList
                        orderItem={orderItem}
                        key={orderItem.id}
                      />
                    )
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Paper>
      )}
    </Box>
  )
}

export default TableOrderManagementList
