import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  TableRow,
  TableCell,
  Grid,
  Tooltip,
  IconButton,
  colors,
  Link,
  Avatar,
  makeStyles
} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

//? Own imports
import { Pedido } from 'types/api'
import {
  formatCurrency,
  getInitials,
  getLabelGatewayColor,
  getStatusPedidoColor,
  toLocalDateFormat
} from 'utils'
import { Label } from 'components'

const useStyles = makeStyles((theme) => ({
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  }
}))

const RowTableOrderManagementList = ({ orderItem }: { orderItem: Pedido }) => {
  const classes = useStyles()
  const evento = orderItem.carrinhoItem[0]?.lote?.evento
  const usuario = orderItem.carrinhoItem[0]?.usuario
  return (
    <TableRow hover>
      <TableCell component="th" scope="orderItem">
        {orderItem.id}
      </TableCell>
      <TableCell align="center">
        {usuario && usuario.nome ? (
          <div className={classes.nameCell}>
            <Avatar className={classes.avatar} src={usuario?.imagem}>
              {getInitials(usuario?.nome)}
            </Avatar>
            <div>
              <Link
                color="inherit"
                component={RouterLink}
                to={`/usuario/${usuario?.id}`}
                variant="h6"
              >
                {usuario?.nome}
              </Link>
              <div>{usuario?.email}</div>
            </div>
          </div>
        ) : (
          'Usuário não encontrado'
        )}
      </TableCell>
      <TableCell align="center">
        {evento && evento.nome ? evento.nome : 'Evento não encontrado'}
      </TableCell>
      <TableCell align="center">
        {orderItem && orderItem.formaPagamento?.descricao}
        {orderItem && orderItem.formaPagamento === null && orderItem.gateway && (
          <Label
            color={getLabelGatewayColor(orderItem.gateway)}
            variant="outlined"
          >
            {orderItem.gateway.toUpperCase()}
          </Label>
        )}
      </TableCell>
      <TableCell align="center">
        {orderItem && orderItem.dataHora
          ? toLocalDateFormat(orderItem.dataHora)
          : '----------'}
      </TableCell>
      <TableCell align="center">
        {orderItem && orderItem.dataHoraValidade
          ? toLocalDateFormat(orderItem.dataHoraValidade)
          : '----------'}
      </TableCell>
      <TableCell align="center">
        {orderItem && orderItem.valorTotal
          ? formatCurrency(orderItem.valorTotal)
          : '----------'}
      </TableCell>
      <TableCell align="center">
        {orderItem && orderItem.statusPedido ? (
          <Label
            color={getStatusPedidoColor(orderItem.statusPedido)}
            variant="outlined"
          >
            {orderItem.statusPedido.descricao}
          </Label>
        ) : (
          '----------'
        )}
      </TableCell>
      <TableCell align="right">
        <Grid wrap="nowrap" container justify="flex-end">
          <Grid>
            <Tooltip title="Detalhes">
              <Link component={RouterLink} to={`/pedido/${orderItem.id}`}>
                <IconButton
                  size="small"
                  aria-label="detalhes pedido ingresso"
                  style={{ marginLeft: 10 }}
                >
                  <InfoOutlinedIcon />
                </IconButton>
              </Link>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
}

export default RowTableOrderManagementList
