import { toast } from 'react-toastify'
import { crudService } from '../_services'

export const movimentacaoFinanceiraAction = {
  createMovimentacaoFinanceira,
  finishMovimentacaoFinanceira,
  cancelMovimentacaoFinanceira,
  getMovimentacaoFinanceiraByEstabelecimento,
  getAllMovimentacaoFinanceira,
  getMovimentacaoFinanceiraById,
  onChangeProps,
  clear
}

function cancelMovimentacaoFinanceira(payload) {
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'movimentacoesFinanceiras/cancel'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        toast.success('Feito! Movimentação financeira cancelada com sucesso!')
        dispatch(success(response.data))
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request(payload) {
    return { type: 'MOVIMENTACAO_FINANCEIRA_CANCEL_REQUEST', payload }
  }

  function success(response) {
    return [
      {
        type: 'MOVIMENTACAO_FINANCEIRA_CANCEL_SUCCESS',
        movimentacaoFinanceira: response
      },
      {
        type: 'MOVIMENTACAO_FINANCEIRA_DETAIL',
        movimentacaoFinanceira: response
      }
    ]
  }

  function failure(error) {
    return { type: 'MOVIMENTACAO_FINANCEIRA_CANCEL_FAILURE', error }
  }
}

function finishMovimentacaoFinanceira(payload) {
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'movimentacoesFinanceiras/finish'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        toast.success('Feito! Movimentação financeira finalizada com sucesso!')
        dispatch(success(response.data))
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request(payload) {
    return { type: 'MOVIMENTACAO_FINANCEIRA_FINISH_REQUEST', payload }
  }

  function success(response) {
    return [
      {
        type: 'MOVIMENTACAO_FINANCEIRA_FINISH_SUCCESS',
        movimentacaoFinanceira: response
      },
      {
        type: 'MOVIMENTACAO_FINANCEIRA_DETAIL',
        movimentacaoFinanceira: response
      }
    ]
  }

  function failure(error) {
    return { type: 'MOVIMENTACAO_FINANCEIRA_FINISH_FAILURE', error }
  }
}

function createMovimentacaoFinanceira(payload) {
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'movimentacoesFinanceiras/request'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((err) => {
        dispatch(failure(err))
        return err
      })
  }

  function request(payload) {
    return { type: 'MOVIMENTACAO_FINANCEIRA_CREATE_REQUEST', payload }
  }

  function success(response) {
    return [
      {
        type: 'MOVIMENTACAO_FINANCEIRA_CREATE_SUCCESS',
        movimentacaoFinanceira: response
      },
      {
        type: 'MOVIMENTACAO_FINANCEIRA_DETAIL',
        movimentacaoFinanceira: response
      }
    ]
  }

  function failure(error) {
    return { type: 'MOVIMENTACAO_FINANCEIRA_CREATE_FAILURE', error }
  }
}

function getMovimentacaoFinanceiraByEstabelecimento(estabelecimentoId) {
  return (dispatch) => {
    dispatch(request(estabelecimentoId))
    let apiEndpoint = `movimentacoesFinanceiras/estabelecimento/${estabelecimentoId}`
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((err) => {
        dispatch(failure(err))
        return err
      })
  }

  function request(estabelecimentoId) {
    return {
      type: 'GET_MOVIMENTACAO_FINANCEIRA_BY_ESTABELECIMENTO_REQUEST',
      estabelecimentoId
    }
  }

  function success(response) {
    return {
      type: 'GET_MOVIMENTACAO_FINANCEIRA_BY_ESTABELECIMENTO_SUCCESS',
      movimentacaoFinanceira: response
    }
  }

  function failure(error) {
    return {
      type: 'GET_MOVIMENTACAO_FINANCEIRA_BY_ESTABELECIMENTO_FAILURE',
      error
    }
  }
}
function getMovimentacaoFinanceiraById(movimentacaoFinanceiraId) {
  return (dispatch) => {
    dispatch(request(movimentacaoFinanceiraId))
    let apiEndpoint = `movimentacoesFinanceiras/${movimentacaoFinanceiraId}`
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((err) => {
        dispatch(failure(err))
        return err
      })
  }

  function request(movimentacaoFinanceiraId) {
    return {
      type: 'GET_MOVIMENTACAO_FINANCEIRA_BY_ID_REQUEST',
      movimentacaoFinanceiraId
    }
  }

  function success(response) {
    return {
      type: 'GET_MOVIMENTACAO_FINANCEIRA_BY_ID_SUCCESS',
      movimentacaoFinanceira: response
    }
  }

  function failure(error) {
    return {
      type: 'GET_MOVIMENTACAO_FINANCEIRA_BY_ID_FAILURE',
      error
    }
  }
}

function getAllMovimentacaoFinanceira(payload) {
  return (dispatch) => {
    dispatch(request())
    let apiEndpoint = `movimentacoesFinanceiras/search`
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((err) => {
        dispatch(failure(err))
        return err
      })
  }

  function request(payload) {
    return {
      type: 'GET_ALL_MOVIMENTACAO_FINANCEIRA_REQUEST',
      payload
    }
  }

  function success(response) {
    return {
      type: 'GET_ALL_MOVIMENTACAO_FINANCEIRA_SUCCESS',
      movimentacaoFinanceira: response
    }
  }

  function failure(error) {
    return {
      type: 'GET_ALL_MOVIMENTACAO_FINANCEIRA_FAILURE',
      error
    }
  }
}

export function clear() {
  return {
    type: 'MOVIMENTACAO_FINANCEIRA_CLEAR_ALL'
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (typeof event === 'object') {
      if (event.target.type === 'checkbox') {
        dispatch(handleOnChangeProps(props, event.target.checked))
      } else {
        dispatch(handleOnChangeProps(props, event.target.value))
      }
    } else {
      dispatch(handleOnChangeProps(props, event))
    }
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'MOVIMENTACAO_FINANCEIRA_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
