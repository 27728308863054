import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  withStyles,
  makeStyles,
  Table,
  colors,
  Button,
  useTheme,
  useMediaQuery,
  Collapse,
  Badge,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { ingressoAction } from '_actions'
import { formatCurrency, toLocalDateFormat, useDidMountEffect } from 'utils'
import { MessageData, Label, SkeletonTable } from 'components'
import { ModalSolicitarRetirada } from '..'
import DeleteIcon from '@material-ui/icons/Delete'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck'
import { SortSwitches } from './components'
import { Skeleton } from '@material-ui/lab'

var pluralize = require('pluralize')

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px'
  }
}))(Badge)

const statusColors = {
  5: colors.green[600],
  2: colors.red[600]
}

const headCells = [
  {
    id: 'evento-lote',
    numeric: false,
    disablePadding: true,
    label: 'Evento - (Lote)'
  },
  {
    id: 'data-evento',
    numeric: true,
    disablePadding: false,
    label: 'Data evento'
  },
  {
    id: 'valor',
    numeric: true,
    disablePadding: false,
    label: 'Valor total (R$)'
  },
  { id: 'status', numeric: true, disablePadding: false, label: 'Status' }
]

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount } = props

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Tooltip
            title={
              rowCount > 0 && numSelected === rowCount
                ? 'Desmarcar todos'
                : 'Marcar todos'
            }
          >
            <Checkbox
              checkedIcon={<LibraryAddCheckIcon />}
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all tickets' }}
            />
          </Tooltip>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight: {
    borderRadius: '8px 8px 0px 0px',
    webkitBorderRadius: '8px 8px 0px 0px',
    mozBorderRadius: '8px 8px 0px 0px',
    color: theme.palette.text.primary,
    backgroundColor: 'rgba(225, 194, 10, 0.1)'
  },
  title: {
    flex: '1 1 100%'
  },
  buttonRetirar: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      minWidth: 150,
      marginRight: theme.spacing(4)
    },
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  }
}))

export default function TableIngressosLiberados() {
  function Row(props) {
    const { row, index, showValues } = props
    const [open, setOpen] = useState(false)
    const [openLote, setOpenLote] = useState(false)
    const labelId = `enhanced-table-checkbox-${index}`
    const haveRowEvento =
      row.ingressosSortedByEvento !== undefined &&
      row.ingressosSortedByEvento.length > 0
        ? true
        : false

    const sizeIngressoByEvento = ingressos.filter(
      (c) => c.lote.eventoId === row.lote.eventoId
    ).length
    const sizeSelectedIngressoByEvento = selectedIngressos.filter(
      (c) => c.lote.eventoId === row.lote.eventoId
    ).length

    const allIngressosSelectedByEvento =
      sizeSelectedIngressoByEvento === sizeIngressoByEvento ? true : false
    const isItemSelected = (eventoId) =>
      selectedIngressos.some((n) => n.id === eventoId)
    function totalValueRowLote(ingressosSortedByLote) {
      var value = 0
      const haveRowLote =
        ingressosSortedByLote !== undefined && ingressosSortedByLote.length > 0
          ? true
          : false
      if (haveRowLote) {
        value +=
          ingressosSortedByLote.reduce((a, b) => a + (b['valor'] || 0), 0) -
          ingressosSortedByLote.reduce(
            (a, b) => a + (b['valorDesconto'] || 0),
            0
          ) -
          ingressosSortedByLote.reduce((a, b) => a + (b['valorTaxa'] || 0), 0)
      }
      return value
    }

    function totalValueRowEvento(ingressosSortedByEvento) {
      var value = 0
      ingressosSortedByEvento.map((row) => {
        const haveRowLote =
          row.ingressosSortedByLote !== undefined &&
          row.ingressosSortedByLote.length > 0
            ? true
            : false
        if (haveRowLote) {
          value += totalValueRowLote(row.ingressosSortedByLote)
        } else {
          value += row.valor - row.valorTaxa - row.valorDesconto
        }
      })

      return value
    }
    const totalValueRow = haveRowEvento
      ? totalValueRowEvento(row.ingressosSortedByEvento)
      : row.valor - row.valorTaxa - row.valorDesconto

    const handleSelectAllRowEvento = (eventoId) => {
      if (allIngressosSelectedByEvento) {
        const newSelecteds = selectedIngressos.filter(
          (n) => n.lote.eventoId !== eventoId
        )
        setSelectedIngressos(newSelecteds)
        return
      } else if (sizeSelectedIngressoByEvento > 0) {
        const selectedIngressosExcludeEvento = selectedIngressos.filter(
          (n) => n.lote.eventoId !== eventoId
        )
        const newSelecteds = ingressos.filter(
          (n) => n.lote.eventoId === eventoId
        )
        setSelectedIngressos([
          ...selectedIngressosExcludeEvento,
          ...newSelecteds
        ])
        return
      } else {
        const newSelecteds = ingressos.filter(
          (n) => n.lote.eventoId === eventoId
        )
        setSelectedIngressos([...selectedIngressos, ...newSelecteds])
        return
      }
    }

    return (
      <>
        <TableRow
          hover
          role="checkbox"
          aria-checked={allIngressosSelectedByEvento}
          tabIndex={-1}
          key={row.lote.eventoId}
          selected={allIngressosSelectedByEvento || open}
        >
          <TableCell padding="checkbox">
            <div style={{ display: 'flex', paddingRight: 10 }}>
              {haveRowEvento ? (
                <Tooltip
                  title={
                    allIngressosSelectedByEvento
                      ? 'Desmarcar todos do evento'
                      : 'Marcar todos do evento'
                  }
                >
                  <Checkbox
                    indeterminate={
                      !allIngressosSelectedByEvento &&
                      sizeSelectedIngressoByEvento > 0
                        ? true
                        : false
                    }
                    onClick={() => handleSelectAllRowEvento(row.lote.eventoId)}
                    checkedIcon={<LibraryAddCheckIcon />}
                    color="primary"
                    checked={allIngressosSelectedByEvento}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </Tooltip>
              ) : (
                <Checkbox
                  onClick={() => handleClick(row)}
                  color="primary"
                  checked={isItemSelected(row.id)}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              )}
              {haveRowEvento && (
                <Tooltip title={open ? 'Esconder lotes' : 'Mostrar lotes'}>
                  <IconButton
                    aria-label="expand row"
                    onClick={() => setOpen(!open)}
                  >
                    <StyledBadge
                      max={9999}
                      badgeContent={sizeIngressoByEvento}
                      color="primary"
                    >
                      {open ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </StyledBadge>
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </TableCell>
          <TableCell align="left" component="th" scope="row" padding="none">
            {haveRowEvento
              ? row.lote.evento.nome
              : `${row.lote.evento.nome} - (${row.lote.descricao} - ${row.lote.tipoIngresso.descricao})`}
          </TableCell>
          <TableCell align="right">
            {toLocalDateFormat(row.lote.evento.dataHoraFim)}
          </TableCell>
          <TableCell align="right">
            {showValues ? (
              formatCurrency(totalValueRow)
            ) : (
              <Skeleton
                animation={isLoading ? 'pulse' : 'false'}
                variant="text"
                height={20}
              />
            )}
          </TableCell>
          <TableCell align="right">
            <Label
              color={statusColors[row.statusIngresso.id]}
              variant="outlined"
            >
              {row.statusIngresso.descricao}
            </Label>
          </TableCell>
        </TableRow>
        {haveRowEvento && (
          <TableRow>
            <TableCell padding="checkbox" style={{ padding: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Table size="small" aria-label="ingressos por evento">
                  <TableBody>
                    {row.ingressosSortedByEvento.map((row) => {
                      const hasLote =
                        row.ingressosSortedByLote !== undefined &&
                        row.ingressosSortedByLote.length > 0
                          ? true
                          : false

                      const sizeIngressoByLote = ingressos.filter(
                        (c) => c.lote.id === row.lote.id
                      ).length
                      const sizeSelectedIngressoByLote = selectedIngressos.filter(
                        (c) => c.lote.id === row.lote.id
                      ).length

                      const allIngressosSelectedByLote =
                        sizeSelectedIngressoByLote === sizeIngressoByLote
                          ? true
                          : false
                      const handleSelectAllRowLote = (loteId) => {
                        if (allIngressosSelectedByLote) {
                          const newSelecteds = selectedIngressos.filter(
                            (n) => n.lote.id !== loteId
                          )
                          setSelectedIngressos(newSelecteds)
                          return
                        } else if (sizeSelectedIngressoByLote > 0) {
                          const selectedIngressosExcludeLote = selectedIngressos.filter(
                            (n) => n.lote.id !== loteId
                          )
                          const newSelecteds = ingressos.filter(
                            (n) => n.lote.id === loteId
                          )
                          setSelectedIngressos([
                            ...selectedIngressosExcludeLote,
                            ...newSelecteds
                          ])
                          return
                        } else {
                          const newSelecteds = ingressos.filter(
                            (n) => n.lote.id === loteId
                          )
                          setSelectedIngressos([
                            ...selectedIngressos,
                            ...newSelecteds
                          ])
                          return
                        }
                      }
                      return (
                        <>
                          <TableRow
                            selected={allIngressosSelectedByLote}
                            hover
                            role="checkbox"
                            aria-checked={allIngressosSelectedByLote}
                            tabIndex={-1}
                            key={row.lote.id}
                          >
                            <TableCell
                              style={{ paddingLeft: 40 }}
                              padding="checkbox"
                            >
                              <div
                                style={{ display: 'flex', paddingRight: 10 }}
                              >
                                {hasLote ? (
                                  <Tooltip
                                    title={
                                      allIngressosSelectedByLote
                                        ? 'Desmarcar todos do lote'
                                        : 'Marcar todos do lote'
                                    }
                                  >
                                    <Checkbox
                                      indeterminate={
                                        !allIngressosSelectedByLote &&
                                        sizeSelectedIngressoByLote > 0
                                          ? true
                                          : false
                                      }
                                      onClick={() =>
                                        handleSelectAllRowLote(row.lote.id)
                                      }
                                      checkedIcon={<LibraryAddCheckIcon />}
                                      color="primary"
                                      checked={allIngressosSelectedByLote}
                                      inputProps={{
                                        'aria-labelledby': labelId
                                      }}
                                    />
                                  </Tooltip>
                                ) : (
                                  <Checkbox
                                    onClick={() => handleClick(row)}
                                    color="primary"
                                    checked={isItemSelected(row.id)}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                )}
                                {hasLote && (
                                  <Tooltip
                                    title={
                                      openLote
                                        ? 'Esconder ingressos'
                                        : 'Mostrar ingressos'
                                    }
                                  >
                                    <IconButton
                                      aria-label="expand row"
                                      onClick={() => setOpenLote(!openLote)}
                                    >
                                      <StyledBadge
                                        max={9999}
                                        badgeContent={sizeIngressoByLote}
                                        color="primary"
                                      >
                                        {openLote ? (
                                          <KeyboardArrowUpIcon />
                                        ) : (
                                          <KeyboardArrowDownIcon />
                                        )}
                                      </StyledBadge>
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </div>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                              padding="none"
                            >
                              {`${row.lote.descricao} - ${row.lote.tipoIngresso.descricao}`}
                            </TableCell>
                            <TableCell>
                              {toLocalDateFormat(row.lote.evento.dataHoraFim)}
                            </TableCell>
                            <TableCell align="right">
                              {hasLote
                                ? formatCurrency(
                                    totalValueRowLote(row.ingressosSortedByLote)
                                  )
                                : formatCurrency(
                                    row.valor -
                                      row.valorTaxa -
                                      row.valorDesconto
                                  )}
                            </TableCell>
                            <TableCell align="right">
                              <Label
                                color={statusColors[row.statusIngresso.id]}
                                variant="outlined"
                              >
                                {row.statusIngresso.descricao}
                              </Label>
                            </TableCell>
                          </TableRow>
                          {hasLote && (
                            <TableRow>
                              <TableCell
                                padding="checkbox"
                                style={{ padding: 0 }}
                                colSpan={6}
                              >
                                <Collapse
                                  in={openLote}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Table
                                    size="small"
                                    aria-label="lista de ingressos"
                                  >
                                    <TableBody>
                                      {row.ingressosSortedByLote.map((row) => {
                                        return (
                                          <TableRow
                                            style={{ padding: 0 }}
                                            role="checkbox"
                                            selected={isItemSelected(row.id)}
                                            hover
                                            tabIndex={-1}
                                            aria-checked={isItemSelected(
                                              row.id
                                            )}
                                            key={row.id}
                                          >
                                            <TableCell
                                              style={{ paddingLeft: 80 }}
                                              padding="checkbox"
                                            >
                                              <Checkbox
                                                style={{ padding: 0 }}
                                                onClick={() => handleClick(row)}
                                                color="primary"
                                                checked={isItemSelected(row.id)}
                                                inputProps={{
                                                  'aria-labelledby': labelId
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell align="left">
                                              {`Ingresso n.º: ${row.id}`}
                                            </TableCell>
                                            <TableCell align="right">
                                              {`Cliente: ${row.usuario.nome}`}
                                            </TableCell>
                                            <TableCell align="right">
                                              {formatCurrency(
                                                row.valor -
                                                  row.valorTaxa -
                                                  row.valorDesconto
                                              )}
                                            </TableCell>
                                            <TableCell align="right">
                                              <Label
                                                color={
                                                  statusColors[
                                                    row.statusIngresso.id
                                                  ]
                                                }
                                                variant="outlined"
                                              >
                                                {row.statusIngresso.descricao}
                                              </Label>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      })}
                                    </TableBody>
                                  </Table>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )
                    })}
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </>
    )
  }
  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles()
    const { numSelected, showValues } = props
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0
        })}
      >
        {numSelected > 0 ? (
          <Typography
            inline
            className={classes.title}
            color="inherit"
            variant={isMobile ? 'subtitle2' : 'subtitle1'}
            component="div"
          >
            {`${pluralize('selecionados', numSelected, true)}`}
          </Typography>
        ) : (
          <>
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Ingressos disponíveis para retirada
            </Typography>
            <SortSwitches
              sortByEvento={sortByEvento}
              setSortByEvento={setSortByEvento}
              sortByLote={sortByLote}
              setSortByLote={setSortByLote}
            />
          </>
        )}

        {numSelected > 0 && (
          <>
            <Typography
              style={{ marginRight: 10 }}
              className={classes.title}
              color="inherit"
              variant={isMobile ? 'subtitle2' : 'subtitle1'}
              component="div"
              align="right"
            >
              {`Valor total à retirar: ${
                showValues ? formatCurrency(valorTotalARetirar) : `R$ **,**`
              }`}
            </Typography>
            {!hasContaBancaria ? (
              <Tooltip title="Opss! Você precisa cadastrar uma conta bancária!">
                <span style={{ cursor: 'not-allowed' }}>
                  <Button
                    disabled={!hasContaBancaria}
                    onClick={handleClickSubmit}
                    className={classes.buttonRetirar}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    {isMobile ? 'Retirar' : 'Solicitar retirada'}
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button
                onClick={handleClickSubmit}
                className={classes.buttonRetirar}
                variant="contained"
                color="primary"
                size="small"
              >
                {isMobile ? 'Retirar' : 'Solicitar retirada'}
              </Button>
            )}

            <Tooltip title="Limpar seleção">
              <IconButton onClick={handleClickDelete} aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <SortSwitches
              sortByEvento={sortByEvento}
              setSortByEvento={setSortByEvento}
              sortByLote={sortByLote}
              setSortByLote={setSortByLote}
            />
          </>
        )}
      </Toolbar>
    )
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%'
    },
    table: {
      minWidth: 750
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      boxShadow: 'rgb(0 0 0 / 8%) 0px 2px 8px',
      borderRadius: '8px',
      webkitBorderRadius: '8px',
      mozBorderRadius: '8px'
    }
  }))

  const classes = useStyles()
  const [sortByEvento, setSortByEvento] = useState(true)
  const [sortByLote, setSortByLote] = useState(true)
  const { showValues } = useSelector((state) => state.global)
  const [openModal, setOpenModal] = useState(false)
  const [selectedIngressos, setSelectedIngressos] = useState([])
  const dispatch = useDispatch()
  const ingressos = useSelector((state) => state.ingresso.ingresso)
  const { isLoading } = useSelector((state) => state.ingresso)
  const estabelecimentoId = useSelector(
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const valorTotalARetirar =
    selectedIngressos.reduce((a, b) => a + (b['valor'] || 0), 0) -
    selectedIngressos.reduce((a, b) => a + (b['valorDesconto'] || 0), 0) -
    selectedIngressos.reduce((a, b) => a + (b['valorTaxa'] || 0), 0)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const hasContaBancaria = useSelector((state) => {
    if (state.contaBancaria.contaBancaria.length > 0) {
      return true
    }
    return false
  })

  const ingressosSortByEventoAndLote = (ingressos) => {
    var ingressosSorted = []
    if (sortByEvento) {
      ingressos.map((row, index) => {
        if (index === 0) {
          ingressosSorted.push(row)
        } else {
          const ingressosSortedByEventoFindIndex = ingressosSorted.findIndex(
            (item) => item.lote.eventoId === row.lote.eventoId
          )
          if (ingressosSortedByEventoFindIndex !== -1) {
            if (
              ingressosSorted[ingressosSortedByEventoFindIndex]
                .ingressosSortedByEvento === undefined
            ) {
              ingressosSorted[ingressosSortedByEventoFindIndex] = {
                ...ingressosSorted[ingressosSortedByEventoFindIndex],
                ingressosSortedByEvento: [
                  ingressosSorted[ingressosSortedByEventoFindIndex]
                ]
              }

              ingressosSorted[
                ingressosSortedByEventoFindIndex
              ].ingressosSortedByEvento.push(row)
            } else {
              ingressosSorted[
                ingressosSortedByEventoFindIndex
              ].ingressosSortedByEvento.push(row)
            }
          } else {
            ingressosSorted.push(row)
          }
        }
      })
      if (sortByLote) {
        ingressosSorted.map((row, index) => {
          if (row.ingressosSortedByEvento !== undefined) {
            const arrayIngressos = []
            row.ingressosSortedByEvento.map(
              (rowArrayEvento, indexArrayEvento) => {
                if (indexArrayEvento === 0) {
                  arrayIngressos.push(rowArrayEvento)
                } else {
                  const ingressosSortedByLoteFindIndex = arrayIngressos.findIndex(
                    (item) => item.lote.id === rowArrayEvento.lote.id
                  )
                  if (ingressosSortedByLoteFindIndex !== -1) {
                    if (
                      arrayIngressos[ingressosSortedByLoteFindIndex]
                        .ingressosSortedByLote === undefined
                    ) {
                      arrayIngressos[ingressosSortedByLoteFindIndex] = {
                        ...arrayIngressos[ingressosSortedByLoteFindIndex],
                        ingressosSortedByLote: [
                          arrayIngressos[ingressosSortedByLoteFindIndex]
                        ]
                      }

                      arrayIngressos[
                        ingressosSortedByLoteFindIndex
                      ].ingressosSortedByLote.push(rowArrayEvento)
                    } else {
                      arrayIngressos[
                        ingressosSortedByLoteFindIndex
                      ].ingressosSortedByLote.push(rowArrayEvento)
                    }
                  } else {
                    arrayIngressos.push(rowArrayEvento)
                  }
                }
              }
            )
            ingressosSorted[index].ingressosSortedByEvento = arrayIngressos
          }
        })
      }
    } else {
      ingressosSorted = ingressos
    }
    return ingressosSorted
  }

  useEffect(() => {
    !isLoading &&
      dispatch(
        ingressoAction.getIngressosLiberadosByEstabelecimento(estabelecimentoId)
      )
    setSelectedIngressos([])
  }, [estabelecimentoId])

  useDidMountEffect(() => {
    setSelectedIngressos([])
  }, [ingressos])

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = ingressos
      setSelectedIngressos(newSelecteds)
      return
    }
    setSelectedIngressos([])
  }
  const handleClickDelete = () => {
    setSelectedIngressos([])
  }
  const handleClickSubmit = () => {
    setOpenModal(true)
  }

  const handleClick = (ingresso) => {
    const isSelected = selectedIngressos.some((n) => n.id === ingresso.id)
    if (isSelected) {
      const newSelected = selectedIngressos.filter((n) => n.id !== ingresso.id)
      setSelectedIngressos(newSelected)
    } else {
      const newSelecteds = ingressos.filter((n) => n.id === ingresso.id)
      setSelectedIngressos([...selectedIngressos, ...newSelecteds])
    }
  }

  return (
    <div className={classes.root}>
      {!isLoading ? (
        <>
          {ingressos && ingressos.length > 0 ? (
            <>
              <Typography variant="body2" align="left" gutterBottom>
                Total de ingressos encontrados: <b>{ingressos.length}</b>
              </Typography>
              <Paper className={classes.paper}>
                <EnhancedTableToolbar
                  numSelected={selectedIngressos.length}
                  showValues={showValues}
                />
                <TableContainer>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size="medium"
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead
                      classes={classes}
                      numSelected={selectedIngressos.length}
                      onSelectAllClick={handleSelectAllClick}
                      rowCount={ingressos.length}
                    />
                    <TableBody>
                      {ingressosSortByEventoAndLote(ingressos).map(
                        (row, index) => {
                          return (
                            <Row
                              key={row.id}
                              row={row}
                              index={index}
                              showValues={showValues}
                            />
                          )
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </>
          ) : (
            <MessageData
              text="Nenhum ingresso liberado para retirada"
              srcImage="/images/no-tickets-found.svg"
              buttonText="Consultar ingressos a liberar 🎟️"
              buttonHref="aliberar"
            />
          )}
          <ModalSolicitarRetirada
            ingressos={selectedIngressos}
            openModal={openModal}
            setOpenModal={setOpenModal}
            valorTotalARetirar={valorTotalARetirar}
          />
        </>
      ) : (
        <SkeletonTable />
      )}
    </div>
  )
}
