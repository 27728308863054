import { useQuery } from 'react-query'
import { crudService } from '_services'

const getMembershipPlans = async (placeId: Number) => {
  const { data } = await crudService.get(`planos/estabelecimento/${placeId}`)
  return data
}

export default function useMembershipPlanList(placeId: Number) {
  return useQuery(['membershipPlan-list', placeId], () =>
    getMembershipPlans(placeId)
  )
}
