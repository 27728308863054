import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Toolbar,
  colors,
  Divider,
  TablePagination,
  useTheme,
  useMediaQuery,
  Tooltip,
  IconButton
} from '@material-ui/core'
import { formatCurrency, history, toLocalDateFormat } from 'utils'
import { Label, MessageData, Page, SkeletonTable } from 'components'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { Skeleton } from '@material-ui/lab'
import constants from 'theme/constants'

const statusColors = {
  1: colors.orange[600],
  2: colors.red[600],
  3: colors.green[600]
}

const useStyles = makeStyles((theme) => ({
  root: { paddingTop: theme.spacing(2) },
  paper: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      minHeight: 380
    },
    ...constants.shadowCard
  },
  tableCell: {
    fontSize: '8pt'
  },
  container: {
    height: 290
  },
  toolbar: {}
}))

const CardRetiradas = ({ movimentacoesFinanceiras, title }) => {
  const { isLoading } = useSelector((state) => state.movimentacaoFinanceira)
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(isMobile ? 5 : 8)
  const { showValues } = useSelector((state) => state.global)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  return (
    <Page className={classes.root} title="Carteira - Retiradas">
      <>
        {isLoading ? (
          <SkeletonTable />
        ) : (
          <>
            <Paper className={classes.paper}>
              <Toolbar className={classes.toolbar} variant="dense">
                <Typography variant="h6" color="textPrimary">
                  {title}
                </Typography>
              </Toolbar>
              <Divider />
              {undefined !== movimentacoesFinanceiras &&
              movimentacoesFinanceiras.length > 0 ? (
                <>
                  <TableContainer className={classes.container}>
                    <PerfectScrollbar>
                      <Table stickyHeader size="small">
                        <TableHead>
                          <TableRow tabIndex={-1}>
                            <TableCell
                              className={classes.tableCell}
                              align="left"
                            >
                              Valor (R$)
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              align="center"
                            >
                              Solicitação
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              align="center"
                            >
                              Finalização
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              align="right"
                            >
                              Status
                            </TableCell>
                            <TableCell width={10}></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {movimentacoesFinanceiras
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((n) => {
                              return (
                                <TableRow
                                  onClick={() =>
                                    history.push(
                                      `/movimentacaoFinanceira/${n.id}/resumo`
                                    )
                                  }
                                  style={{ cursor: 'pointer' }}
                                  hover
                                  key={n.id}
                                >
                                  <TableCell
                                    className={classes.tableCell}
                                    align="left"
                                    component="th"
                                    scope="row"
                                  >
                                    {showValues ? (
                                      formatCurrency(n.valor)
                                    ) : (
                                      <Skeleton
                                        animation={
                                          isLoading ? 'pulse' : 'false'
                                        }
                                        variant="text"
                                        height={16}
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                    component="th"
                                    scope="row"
                                  >
                                    {toLocalDateFormat(n.dataHora)}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                    component="th"
                                    scope="row"
                                  >
                                    {n.dataHoraProcessamento !== null
                                      ? toLocalDateFormat(
                                          n.dataHoraProcessamento
                                        )
                                      : '------'}
                                  </TableCell>
                                  <Tooltip
                                    title={
                                      n.motivoCancelamento !== null
                                        ? `Motivo do cancelamento:
                                        ${n.motivoCancelamento}`
                                        : ''
                                    }
                                    arrow
                                  >
                                    <TableCell
                                      align="right"
                                      component="th"
                                      scope="row"
                                    >
                                      <Label
                                        style={{
                                          fontSize: '6.5pt',
                                          height: 17
                                        }}
                                        color={
                                          statusColors[
                                            n.statusMovimentacaoFinanceira.id
                                          ]
                                        }
                                        variant="contained"
                                      >
                                        {
                                          n.statusMovimentacaoFinanceira
                                            .descricao
                                        }
                                      </Label>
                                    </TableCell>
                                  </Tooltip>
                                  <TableCell>
                                    <Tooltip title="Mais detalhes">
                                      <IconButton
                                        style={{ padding: 0 }}
                                        size="small"
                                        aria-label="detalhes movimentação financeira"
                                        onClick={() =>
                                          history.push(
                                            `/movimentacaoFinanceira/${n.id}/resumo`
                                          )
                                        }
                                      >
                                        <InfoOutlinedIcon
                                          style={{ fontSize: '13pt' }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                        </TableBody>
                      </Table>
                    </PerfectScrollbar>
                  </TableContainer>
                  <TablePagination
                    labelRowsPerPage="Retiradas por página"
                    rowsPerPageOptions={
                      isMobile
                        ? [5]
                        : [
                            5,
                            8,
                            10,
                            25,
                            {
                              label: 'Todas',
                              value: movimentacoesFinanceiras.length
                            }
                          ]
                    }
                    component="div"
                    count={movimentacoesFinanceiras.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <MessageData
                  text="Nenhuma retirada encontrada! 😥"
                  srcImage="/images/retiradas-not-found.svg"
                />
              )}
            </Paper>
          </>
        )}
      </>
    </Page>
  )
}

CardRetiradas.propTypes = {
  movimentacoesFinanceiras: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
}

export default CardRetiradas
