import { useQuery } from 'react-query'
import { crudService } from '_services'

const getMembershipPlans = async (placeId: Number) => {
  const { data } = await crudService.get(
    `membros/admin?EstabelecimentoId=${placeId}&Limit=9999`
  )
  return data.items
}

export default function useMemberList(placeId: Number) {
  return useQuery(['member-list', placeId], () => getMembershipPlans(placeId))
}
