const initialState = {
  statusMovimentacaoFinanceira: [],
  statusMovimentacaoFinanceiraSelect: [],
  id: 0,
  descricao: '',
  solicitado: false,
  cancelado: false,
  finalizado: false,
  isFetching: false
}

export function statusMovimentacaoFinanceira(state = initialState, action) {
  switch (action.type) {
    case 'STATUS_MOVIMENTACAO_FINANCEIRA_DETAIL':
      return {
        ...state,
        id: action.id,
        descricao: action.descricao,
        solicitado: action.solicitado,
        cancelado: action.cancelado,
        finalizado: action.finalizado
      }
    case 'STATUS_MOVIMENTACAO_FINANCEIRA_CLEAR_ALL':
      return initialState
    case 'STATUS_MOVIMENTACAO_FINANCEIRA_CLEAR_LIST':
      return {
        ...state,
        instituicaoBancaria: []
      }
    case 'FETCHED_STATUS_MOVIMENTACAO_FINANCEIRA_SELECT_REQUEST':
      return {
        ...state,
        isFetching: true
      }
    case 'FETCHED_STATUS_MOVIMENTACAO_FINANCEIRA_SELECT_SUCCESS':
      return {
        ...state,
        isFetching: false,
        statusMovimentacaoFinanceiraSelect:
          action.statusMovimentacaoFinanceiraSelect
      }
    case 'FETCHED_STATUS_MOVIMENTACAO_FINANCEIRA_SELECT_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    default:
      return state
  }
}
