import { useQuery } from 'react-query'

//? Own Imports
import { crudService } from '_services'

const getUserOrders = async (userId: Number) => {
  const { data } = await crudService.get(`pedidos/usuario/${userId}`)
  return data
}

export default function useUserOrders(userId: Number) {
  return useQuery(['user-orders', userId], () => getUserOrders(userId))
}
