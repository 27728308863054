import { crudService } from '../_services/'
import { history, slugMask } from 'utils/'
import { toast } from 'react-toastify'
import { uniqueId } from 'lodash'

export const eventoAction = {
  getEventoByEstabelecimento,
  getEventoById,
  getEvento,
  getAllEvents,
  onChangeProps,
  onChangeDetails,
  editEventoInfo,
  createEvento,
  deleteEventoById,
  clear,
  onChangeData,
  copyEstabelecimentoAddress,
  duplicateEvento,
  addLoteLocale,
  deleteLoteLocaleById,
  editLoteLocale,
  prepareStateToNewEvento,
  prepareStateToEditEvento,
  clearAddress,
  setNextStep,
  setPrevStep,
  changeCoordinates,
  handleOnChangeHashtag,
  getAllEventosByEstabelecimento,
  setStep,
  generateTicketsToMembers
}
function getEvento() {
  return (dispatch) => {
    let apiEndpoint = 'eventos'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeEventoList(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

// Retorna todos eventos independente de estabelecimento
function getAllEvents() {
  return (dispatch) => {
    let apiEndpoint = 'eventos/allEvents'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function success(events) {
    return {
      type: 'REQUEST_ALL_EVENTS_SUCCESS',
      events: events
    }
  }
}

function getEventoByEstabelecimento(estabelecimentoId, pastEvents) {
  return (dispatch) => {
    let apiEndpoint = `eventos/${pastEvents ? 'realizados' : 'pendentes'
      }/estabelecimento/${estabelecimentoId}`
    dispatch(request())
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request() {
    return { type: 'REQUEST_ALL_EVENTS_REQUEST' }
  }

  function success(events) {
    return {
      type: 'REQUEST_ALL_EVENTS_SUCCESS',
      events: events
    }
  }

  function failure(error) {
    return { type: 'REQUEST_ALL_EVENTS_FAILURE', error }
  }
}
function getAllEventosByEstabelecimento(estabelecimentoId) {
  return (dispatch) => {
    let apiEndpoint = `eventos/estabelecimento/${estabelecimentoId}`
    dispatch(request())
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request() {
    return { type: 'REQUEST_ALL_EVENTS_REQUEST' }
  }

  function success(events) {
    return {
      type: 'REQUEST_ALL_EVENTS_SUCCESS',
      events: events
    }
  }

  function failure(error) {
    return { type: 'REQUEST_ALL_EVENTS_FAILURE', error }
  }
}

function createEvento(payload) {
  return (dispatch) => {
    let apiEndpoint = 'eventos/'
    crudService.post(apiEndpoint, payload).then((response) => {
      toast.success('Aeee! Seu evento foi criado com sucesso! 🎉🧡')
      dispatch(createEventoInfo(response.data))
      dispatch(eventoAction.setNextStep())
    })
  }
}
function getEventoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'eventos/' + id
    crudService.get(apiEndpoint).then((response) => {
      if (response.data.tipoLocalizacao === 'O') {
        dispatch(editEventoOnlineDetails(response.data))
      } else {
        dispatch(editEventoDetails(response.data))
      }
    })
  }
}

function duplicateEvento(id) {
  return (dispatch) => {
    dispatch(request(id))
    let apiEndpoint = 'eventos/' + id
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(generateDate(response.data)))
        history.push('/evento')
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request(id) {
    return { type: 'DUPLICATE_EVENTO_REQUEST', id }
  }

  function success(evento) {
    return {
      type: 'DUPLICATE_EVENTO_SUCCESS',
      event: evento
    }
  }

  function failure(error) {
    return { type: 'DUPLICATE_EVENTO_FAILURE', error }
  }

  function generateDate(evento) {
    let lote = evento.lotes
    lote.forEach((loteItem) => {
      loteItem.id = uniqueId()
      delete loteItem.eventoId
      delete loteItem.carrinhoItem
      //delete loteItem.ingresso
      delete loteItem.tipoIngresso
      delete loteItem.taxa
    })

    let cupomDesconto = evento.cuponsDesconto
    cupomDesconto.forEach((cupomDescontoItem) => {
      delete cupomDescontoItem.id
      delete cupomDescontoItem.eventoId
      delete cupomDescontoItem.estabelecimento
      delete cupomDescontoItem.promoter
    })

    let eventoHashtag = []
    evento.eventosHashtags.map((row) => {
      eventoHashtag.push({
        id: 0,
        eventoId: 0,
        hashtagId: row.hashtag.id,
        excluido: false
      })
    })

    let payload = {
      ...evento,
      id: 0,
      estadoId: evento.evento.cidade ? evento.evento.cidade.estadoId : '',
      lote: lote,
      eventoHashtag: eventoHashtag,
      cupomDesconto: cupomDesconto
    }
    return payload
  }
}

function addLoteLocale(lote) {
  toast.success('Lote inserido com sucesso! 👍', {
    position: 'bottom-right'
  })
  return [
    {
      type: 'ADD_LOTE_LOCALE',
      lote: lote
    },
    { type: 'LOTE_CLEAR' }
  ]
}
function deleteLoteLocaleById(id) {
  toast.success('Lote exclúido com sucesso! 👍', {
    position: 'bottom-right'
  })
  return {
    type: 'DELETE_LOTE_LOCALE',
    id: id
  }
}
function editLoteLocale(loteId, lote) {
  toast.success('Alterações realizadas! 👍', {
    position: 'bottom-right'
  })
  return [
    {
      type: 'EDIT_LOTE_LOCALE',
      id: loteId,
      lote: lote
    },
    { type: 'LOTE_CLEAR' }
  ]
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem' || props === 'banner') {
      dispatch(handleOnChangeProps(props, event))
    } else if (typeof event === 'object') {
      if (event.target.type === 'checkbox') {
        dispatch(handleOnChangeProps(props, event.target.checked))
      } else {
        dispatch(handleOnChangeProps(props, event.target.value))
      }
    } else {
      dispatch(handleOnChangeProps(props, event))
    }
    if (props === 'generate-slug') {
      dispatch(handleOnChangeProps('slug', slugMask(event)))
    } else if (props === 'slug') {
      dispatch(handleOnChangeProps(props, slugMask(event.target.value)))
    }
  }
}

function onChangeDetails(data) {
  return (dispatch) => {
    dispatch(handleOnChangeProps('detalhes', data))
  }
}
function changeCoordinates(coordinates) {
  return {
    type: 'EVENTO_CHANGE_COORDINATES',
    latLon: coordinates
  }
}

function copyEstabelecimentoAddress(estabelecimento) {
  return {
    type: 'COPY_ESTABELECIMENTO_ADDRESS',
    estabelecimento: estabelecimento
  }
}

function clearAddress() {
  return {
    type: 'CLEAR_ADDRESS'
  }
}

export function createEventoInfo(evento) {
  return {
    type: 'EVENTO_CREATED_SUCCESSFULLY',
    id: evento.id
  }
}
function editEventoInfo(id, payload) {
  return (dispatch) => {
    let apiEndpoint = 'eventos/' + id
    crudService.put(apiEndpoint, payload).then(() => {
      dispatch(updatedEventoInfo())
      // dispatch(setNextStep())
    })
  }
}
function deleteEventoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'eventos/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteEventoDetails())
      dispatch(
        eventoAction.getEventoByEstabelecimento(response.data.estabelecimentoId)
      )
    })
  }
}

function onChangeData(prop, newValue) {
  return (dispatch) => {
    dispatch(handleOnChangeProps(prop, newValue))
  }
}

export function changeEventoList(evento) {
  return {
    type: 'FETCHED_ALL_EVENTO',
    evento: evento
  }
}
export function clear() {
  return {
    type: 'EVENTO_CLEAR'
  }
}
function prepareStateToNewEvento() {
  return [{ type: 'EVENTO_CLEAR' }, history.push('/evento')]
}
function prepareStateToEditEvento(eventoId) {
  return (dispatch) => {
    dispatch(getEventoById(eventoId))
    history.push(`/evento/${eventoId}`)
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'EVENTO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function handleOnChangeHashtag(hashtags) {
  return {
    type: 'EVENTO_HANDLE_ON_CHANGE_HASHTAG',
    hashtags: hashtags
  }
}

export function editEventoDetails(evento) {
  let estadoIdDiff = ''
  if (evento.evento.cidade !== null) {
    estadoIdDiff = evento.evento.cidade.estadoId
  } else {
    estadoIdDiff = ''
  }

  let eventosHashtags = []
  evento.eventosHashtags.map((row) => {
    eventosHashtags.push({
      id: 0,
      eventoId: evento.evento.id,
      hashtagId: row.hashtag.id,
      excluido: false
    })
  })

  return {
    type: 'EVENTO_DETAIL',
    id: evento.evento.id,
    nome: evento.evento.nome,
    estabelecimentoId: evento.evento.estabelecimentoId,
    categoriaId: evento.evento.categoriaId,
    detalhes: evento.evento.detalhes,
    dataHoraInicio: evento.evento.dataHoraInicio,
    dataHoraFim: evento.evento.dataHoraFim,
    imagem: evento.evento.imagem,
    cidadeId: evento.evento.cidadeId,
    cidade: evento.evento.cidade,
    estadoId: estadoIdDiff,
    endereco: evento.evento.endereco,
    latLon: evento.evento.latLon,
    excluido: evento.evento.excluido,
    disponivel: evento.evento.disponivel,
    prioridadeExibicao: evento.evento.prioridadeExibicao,
    slug: evento.evento.slug,
    cep: evento.evento.cep,
    bairro: evento.evento.bairro,
    tipoLocalizacao: evento.evento.tipoLocalizacao,
    categoria: evento.evento.categoria,
    nomeLocal: evento.evento.nomeLocal,
    complemento: evento.evento.complemento,
    numero: evento.evento.numero,
    lote: evento.lotes,
    cupomDesconto: evento.cuponsDesconto,
    eventoHashtag: eventosHashtags
  }
}

export function editEventoOnlineDetails(evento) {
  let eventosHashtags = []
  evento.eventosHashtags.map((row) => {
    eventosHashtags.push({
      id: 0,
      eventoId: evento.evento.id,
      hashtagId: row.hashtag.id,
      excluido: false
    })
  })

  return {
    type: 'EVENTO_DETAIL',
    id: evento.evento.id,
    nome: evento.evento.nome,
    estabelecimentoId: evento.evento.estabelecimentoId,
    categoriaId: evento.evento.categoriaId,
    detalhes: evento.evento.detalhes,
    dataHoraInicio: evento.evento.dataHoraInicio,
    dataHoraFim: evento.evento.dataHoraFim,
    imagem: evento.evento.imagem,
    endereco: evento.evento.endereco,
    latLon: evento.evento.latLon,
    excluido: evento.evento.excluido,
    disponivel: evento.evento.disponivel,
    prioridadeExibicao: evento.evento.prioridadeExibicao,
    slug: evento.evento.slug,
    cep: evento.evento.cep,
    bairro: evento.evento.bairro,
    tipoLocalizacao: evento.evento.tipoLocalizacao,
    categoria: evento.evento.categoria,
    nomeLocal: evento.evento.nomeLocal,
    complemento: evento.evento.complemento,
    numero: evento.evento.numero,
    lote: evento.lotes,
    cupomDesconto: evento.cuponsDesconto,
    eventoHashtag: eventosHashtags
  }
}

export function setNextStep() {
  return {
    type: 'NEXT_STEP_EVENTO_EDIT'
  }
}

export function setStep(step) {
  return {
    type: 'SET_STEP_EVENTO_EDIT',
    step: step
  }
}

export function setPrevStep() {
  return {
    type: 'PREV_STEP_EVENTO_EDIT'
  }
}

export function updatedEventoInfo() {
  return {
    type: 'EVENTO_UPDATED'
  }
}
export function deleteEventoDetails() {
  return {
    type: 'DELETED_EVENTO_DETAILS'
  }
}

function generateTicketsToMembers(eventId) {
  return (dispatch) => {
    let apiEndpoint = 'ingressos/gerar/membros'
    let payload = { eventoId: eventId }
    crudService.post(apiEndpoint, payload).then((response) => {
      toast.success('Aeee! Os ingressos foram gerados com sucesso!')
    })
  }
}
