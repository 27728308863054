import React, { useState, useEffect } from 'react'
import { cpfCnpjMask } from 'utils/masks'
import { crudService } from '_services'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { cnpj, cpf } from 'cpf-cnpj-validator'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  makeStyles,
  Checkbox,
  FormControlLabel,
  Grid
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { contaBancariaAction } from '_actions'
import { useParams } from 'react-router-dom'
import { history } from 'utils'
import { HelperToolTip } from 'components'
import { Page } from 'components'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { toast } from 'react-toastify'
import constants from 'theme/constants'

const useStyles = makeStyles(() => ({
  root: {},
  paper: {
    ...constants.shadowCard
  }
}))

const ContaBancariaEdit = () => {
  const dispatch = useDispatch()
  const { idContaBancaria } = useParams()
  const classes = useStyles()
  const contaBancaria = useSelector((state) => state.contaBancaria)
  const estabelecimentoId = useSelector(
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const [instituicoes, setInstituicoes] = useState([])
  const [instituicaoSelecionada, setInstituicaoSelecionada] = useState('')
  const [
    instituicaoSelecionadaInputValue,
    setInstituicaoSelecionadaInputValue
  ] = useState('')

  const handleChange = (prop) => (event) => {
    dispatch(contaBancariaAction.onChangeProps(prop, event))
  }

  const handleSelectInstituicao = (instituicao) => {
    if (instituicao === null) {
      setInstituicaoSelecionada('')
      setInstituicaoSelecionadaInputValue('')
    } else {
      setInstituicaoSelecionada(instituicao.id)
      setInstituicaoSelecionadaInputValue(instituicao.nome)
    }
  }

  useEffect(() => {
    if (idContaBancaria) {
      setInstituicaoSelecionadaInputValue(
        contaBancaria.instituicaoBancaria.nome
      )
      setInstituicaoSelecionada(contaBancaria.instituicaoBancariaId)
    }
  }, [contaBancaria.instituicaoBancaria.id])

  useEffect(() => {
    if (idContaBancaria) {
      dispatch(contaBancariaAction.getContaBancariaById(idContaBancaria))
    } else {
      dispatch(contaBancariaAction.clear())
    }
    crudService.get('InstituicoesBancarias/').then((response) => {
      setInstituicoes(response.data)
    })

    ValidatorForm.addValidationRule('isValidCpf', (value) => {
      if (value && value.length <= 14) {
        if (cpf.isValid(value)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    })

    ValidatorForm.addValidationRule('isValidCnpj', (value) => {
      if (value && value.length >= 15) {
        if (cnpj.isValid(value)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    })
    return () => {
      ValidatorForm.removeValidationRule('isValidCpf')
      ValidatorForm.removeValidationRule('isValidCnpj')
      dispatch(contaBancariaAction.clear())
    }
  }, [])

  const handleSubmit = () => {
    if (instituicaoSelecionada < 1) {
      toast.error('Opss! Selecione a instituição bancária!')
    } else {
      let payload = {
        id: idContaBancaria,
        estabelecimentoId: estabelecimentoId,
        instituicaoBancariaId: instituicaoSelecionada,
        descricao: contaBancaria.descricao,
        agencia: contaBancaria.agencia,
        conta: contaBancaria.conta,
        nomeTitular: contaBancaria.nomeTitular,
        padrao: contaBancaria.padrao,
        cpfCnpjTitular: contaBancaria.cpfCnpjTitular
          .split('.')
          .join('')
          .replace(/[-/.]/g, '')
      }

      if (idContaBancaria) {
        dispatch(
          contaBancariaAction.editContaBancariaInfo(idContaBancaria, payload)
        )
      } else {
        dispatch(contaBancariaAction.createContaBancaria(payload))
      }
    }
  }

  return (
    <Page
      className={classes.root}
      title={
        idContaBancaria
          ? 'Carteira - Contas Bancárias - Editar Conta Bancária'
          : 'Carteira - Contas Bancárias - Nova Conta Bancária'
      }
    >
      <Grid item md={12} xs={12}>
        <Card className={classes.paper}>
          <ValidatorForm
            className={classes.form}
            id="formContaBancaria"
            onSubmit={(event) => handleSubmit(event)}
          >
            <CardHeader
              title="Cadastro de Conta Bancária"
              subheader={
                idContaBancaria
                  ? 'Alteração de conta bancária'
                  : 'Nova conta bancária'
              }
            />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <TextValidator
                    variant="outlined"
                    id="descricao"
                    label="Descrição *"
                    className={classes.textField}
                    value={contaBancaria.descricao}
                    onChange={handleChange('descricao')}
                    placeholder="Conta pessoal,  Conta do banco, etc"
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Autocomplete
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    variant="outlined"
                    id="instituicaoBancariaId"
                    label="Instituição Bancária *"
                    value={instituicaoSelecionada}
                    onChange={(event, newValue) => {
                      handleSelectInstituicao(newValue)
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onInputChange={(event, newInputValue) => {
                      newInputValue !== null &&
                        newInputValue.length > 0 &&
                        setInstituicaoSelecionadaInputValue(newInputValue)
                    }}
                    inputValue={instituicaoSelecionadaInputValue}
                    fullWidth
                    labelId="instituicaoBancariaId"
                    options={instituicoes}
                    getOptionLabel={(option) => (option ? option.nome : '')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Instituição Bancária"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6} sm={6} md={2} lg={2}>
                  <TextValidator
                    validators={['required', 'minNumber:0']}
                    errorMessages={[
                      'Campo obrigatório!',
                      'Valor negativo não pode heim!'
                    ]}
                    variant="outlined"
                    id="agencia"
                    type="number"
                    label="Agência *"
                    className={classes.textField}
                    value={contaBancaria.agencia}
                    onChange={handleChange('agencia')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={3}>
                  <TextValidator
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    variant="outlined"
                    id="conta"
                    label="Conta com Dígito *"
                    className={classes.textField}
                    value={contaBancaria.conta}
                    onChange={handleChange('conta')}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <TextValidator
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    variant="outlined"
                    id="nomeTitular"
                    label="Nome Titular *"
                    className={classes.textField}
                    value={contaBancaria.nomeTitular}
                    onChange={handleChange('nomeTitular')}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextValidator
                    variant="outlined"
                    fullWidth
                    id="cpfCnpjTitular"
                    label="CPF/CNPJ *"
                    name="cpfCnpj"
                    type="text"
                    inputProps={{
                      maxlength: '18'
                    }}
                    value={cpfCnpjMask(contaBancaria.cpfCnpjTitular)}
                    onChange={handleChange('cpfCnpjTitular')}
                    autoComplete="cpf"
                    validators={['required', 'isValidCpf', 'isValidCnpj']}
                    errorMessages={[
                      'Campo obrigatório!',
                      'CPF inválido!',
                      'CNPJ inválido!'
                    ]}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={contaBancaria.padrao}
                        onChange={handleChange('padrao')}
                        color="primary"
                      />
                    }
                    label={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        Padrão
                        <HelperToolTip>
                          Se selecionado, todos os depósitos serão feitos nessa
                          conta!
                        </HelperToolTip>
                      </div>
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                onClick={() => history.push('/carteira/contasBancarias')}
                color="secondary"
                variant="outlined"
              >
                Cancelar
              </Button>
              <Button type="submit" color="primary" variant="outlined">
                {idContaBancaria ? 'Atualizar' : 'Salvar'}
              </Button>
            </CardActions>
          </ValidatorForm>
        </Card>
      </Grid>
    </Page>
  )
}

export default ContaBancariaEdit
