import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { conquistaAction } from '../../_actions'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import TitleContent from '../TitleContent'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { useDidMountEffect } from 'utils/useDidMountEffect'
///////////////////////////////////////////////////////////////////////////////
// material-ui imports
import {
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  Divider,
  Grid,
  Typography
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { useConfirm } from 'material-ui-confirm'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import CloseIcon from '@material-ui/icons/Close'
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  }
})

const Conquista = (props) => {
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { conquista } = props.conquista
  const [textSearch, setTextSearch] = useState('')

  const handleChangeText = (event) => {
    setTextSearch(event.target.value)
  }

  function searchForConquista() {
    if (textSearch !== '') {
      dispatch(conquistaAction.findConquistas(textSearch))
    }
  }

  const handleSubmitSearch = (event) => {
    event.preventDefault()
    searchForConquista()
  }

  const handleDeleteClick = (id) => {
    confirm({
      title: 'Você deseja excluir esta Conquista?',
      description:
        'Confirmando essa operação, esta conquista não estará mais disponível.',
      confirmationText: 'Sim, excluir!',
      cancellationText: 'Nãããoo!'
    }).then(() => {
      dispatch(conquistaAction.deleteConquistaById(id))
      dispatch(conquistaAction.getConquistas())
    })
  }

  const handleClickDuplicate = (id) => {
    confirm({
      title: 'Você deseja duplicar essa conquista?',
      description: (
        <div>
          <Typography gutterBottom variant="body1" color="textPrimary">
            Todos os dados serão copiados e você só precisa realizar as
            alterações necessárias.
          </Typography>
        </div>
      ),
      confirmationText: 'Sim, duplicar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(conquistaAction.duplicateConquista(id))
    })
  }

  const clearTextSearch = () => {
    setTextSearch('')
    dispatch(conquistaAction.getConquistas())
  }

  useDidMountEffect(() => {
    textSearch === '' && searchForConquista()
  }, [textSearch])

  useEffect(() => {
    dispatch(conquistaAction.getConquistas())
  }, [dispatch])

  return (
    <Page className={classes.root} title="Conquistas">
      <AccessControl
        rule={'conquistas'}
        yes={() => (
          <div>
            {undefined !== conquista && conquista.length > 0 ? (
              <TitleContent
                rule={'conquistas.create'}
                length={conquista.length}
                subTitle={'GERÊNCIA DE CONQUISTAS'}
                title={'Lista de Conquistas'}
                href={'/conquista'}
              />
            ) : (
              <TitleContent
                rule={'conquistas.create'}
                length={0}
                subTitle={'GERÊNCIA DE CONQUISTAS'}
                title={'Lista de Conquistas'}
                href={'/conquista'}
              />
            )}
            <Grid item>
              <Grid item xs={12} sm={6}>
                <form autoComplete="off" onSubmit={handleSubmitSearch}>
                  <TextField
                    align="right"
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {textSearch.length >= 1 && (
                            <>
                              <IconButton
                                onClick={clearTextSearch}
                                className={classes.iconButton}
                              >
                                <CloseIcon opacity="0.8" />
                              </IconButton>
                              <Divider
                                className={classes.divider}
                                orientation="vertical"
                              />
                            </>
                          )}
                          <IconButton
                            type="submit"
                            aria-label="procurar por conquistas"
                          >
                            <SearchRoundedIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    fullWidth
                    value={textSearch}
                    onChange={handleChangeText}
                    variant="outlined"
                    id="textForSearch"
                    label="Procurar"
                    placeholder="Busque conquistas..."
                  />
                </form>
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Experiêcia</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== conquista && conquista.length
                    ? conquista.map((n) => {
                        return (
                          <TableRow hover key={n.id}>
                            <TableCell component="th" scope="row">
                              {n.nome}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {n.descricao}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {n.exp}
                            </TableCell>
                            <TableCell align="right">
                              <AccessControl
                                rule={'conquistas.create'}
                                yes={() => (
                                  <Tooltip arrow title="Duplicar conquista">
                                    <IconButton
                                      size="small"
                                      className={classes.button}
                                      aria-label="Botão para duplicar o evento"
                                      onClick={() => handleClickDuplicate(n.id)}
                                    >
                                      <FileCopyRoundedIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              />
                              <AccessControl
                                rule={'conquistas.edit'}
                                yes={() => (
                                  <IconButton
                                    aria-label="Edit"
                                    component="a"
                                    onClick={() =>
                                      history.push(`/conquista/${n.id}`)
                                    }
                                    size="small"
                                  >
                                    <Tooltip title="Editar">
                                      <Tooltip title="Editar">
                                        <EditIcon />
                                      </Tooltip>
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                              <AccessControl
                                rule={'conquistas.delete'}
                                yes={() => (
                                  <IconButton
                                    aria-label="Delete"
                                    onClick={() => handleDeleteClick(n.id)}
                                    size="small"
                                  >
                                    <Tooltip title="Excluir">
                                      <DeleteIcon />
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}

Conquista.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    conquista: state.conquista
  }
}

const connectedHashtagPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Conquista))
)

export { connectedHashtagPage as Conquista }
