import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: theme.palette.background.default,
    [theme.breakpoints.up('sm')]: {
      height: 400
    }
  },
  cropButton: {
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 16
    },
    marginBottom: theme.spacing(1)
  },
  cancelButton: {
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      marginRight: 16
    }
  },
  controls: {
    paddingTop: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center'
    }
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center'
  },
  sliderLabel: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 65
    }
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 16,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px'
    }
  },
  cardMedia: {
    paddingTop: '56.25%' // 16:9
  }
}))
