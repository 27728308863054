import { useQuery } from 'react-query'

import { crudService } from '_services'
import { Cidade } from '../../types/api'

const getCities = async () => {
  const { data } = await crudService.get(`cidades`)
  return data
}

export default function useCities() {
  return useQuery<Cidade[]>(['cities-list'], () => getCities())
}
