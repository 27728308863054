import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

//? Own imports
import { PhotoAlbum } from 'types/api'
import { crudService } from '_services'

const deletePhotoAlbum = async (idPhotoAlbum: Number) => {
  const { data } = await crudService.deleteDetail(`albuns/${idPhotoAlbum}`)
  return data
}

export default function useMutationPhotoAlbumDelete() {
  const queryClient = useQueryClient()
  return useMutation(
    'photoAlbum-delete',
    (idPhotoAlbum: Number) => deletePhotoAlbum(idPhotoAlbum),
    {
      onError: (error) => {
        // @ts-ignore
        toast.error(error.message || 'Erro ao excluir álbum de fotos!')
      },
      onSuccess: (response: PhotoAlbum) => {
        // toast.success('Álbum de fotos excluido com sucesso!')
        queryClient.setQueryData<PhotoAlbum[] | undefined>(
          ['photoAlbum-list', response.estabelecimentoId],
          (oldData) => {
            if (oldData) {
              return oldData.filter((item) => item.id !== response.id)
            }
            return oldData
          }
        )
        queryClient.invalidateQueries([
          'photoAlbum-list',
          response.estabelecimentoId
        ])
      }
    }
  )
}
