import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Typography, Grid, Button, ButtonGroup, Chip } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import {
  format,
  subDays,
  differenceInDays,
  isAfter,
  isBefore,
  endOfDay,
  startOfDay,
  startOfYesterday,
  endOfYesterday
} from 'date-fns'
import CalendarTodayIcon from '@material-ui/icons/CalendarTodayOutlined'
import { useDispatch } from 'react-redux'
import { dashboardAction } from '_actions'

const chipOptions = [
  { label: 'Hoje', value: '0' },
  { label: 'Ontem', value: '0' },
  { label: '7 dias', value: '7' },
  { label: '30 dias', value: '30' },
  { label: '90 dias', value: '90' },
  { label: '1 ano', value: '365' }
]

const useStyles = makeStyles((theme) => ({
  root: {},
  dates: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
        marginBottom: theme.spacing(2)
      }
    }
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  },
  chip: {
    marginRight: theme.spacing(1)
  },
  calendarTodayGroup: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(1)
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1)
    }
  }
}))

const Header = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const [selectEdge, setSelectEdge] = useState(null)
  const [calendarDate, setCalendarDate] = useState(new Date())
  const [dateRange, setDateRange] = useState({
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
    value: '0'
  })
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)

  const handleCalendarOpen = (edge) => {
    setSelectEdge(edge)
  }

  const handleCalendarChange = (date) => {
    setCalendarDate(date)
  }

  const handleCalendarClose = () => {
    setCalendarDate(new Date())
    setSelectEdge(null)
  }

  const handleCalendarAccept = (date) => {
    setCalendarDate(new Date())

    if (selectEdge === 'start') {
      setDateRange({ ...dateRange, startDate: startOfDay(date) })

      if (isAfter(date, dateRange.endDate)) {
        setDateRange({ ...dateRange, endDate: endOfDay(date) })
      }
    } else {
      setDateRange({ ...dateRange, endDate: endOfDay(date) })

      if (isBefore(date, dateRange.startDate)) {
        setDateRange({ ...dateRange, startDate: startOfDay(date) })
      }
    }

    setSelectEdge(null)
  }

  const handleRangeChangeChip = (item) => {
    if (item.label === 'Ontem') {
      setDateRange({
        startDate: startOfYesterday(new Date()),
        endDate: endOfYesterday(new Date()),
        value: item.value
      })
    } else {
      setDateRange({
        startDate: startOfDay(subDays(new Date(), Number(item.value))),
        endDate: endOfDay(new Date()),
        value: item.value
      })
    }
  }

  const open = Boolean(selectEdge)

  useEffect(() => {
    dispatch(
      dashboardAction.getDashboardAdmin(dateRange.startDate, dateRange.endDate)
    )
  }, [dateRange.startDate, dateRange.endDate])

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container justify="space-between" spacing={3}>
        <Grid item md={5} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Dashboard
          </Typography>
          <Typography component="h1" gutterBottom variant="h3">
            Administrativo
          </Typography>
        </Grid>
        <Grid className={classes.dates} item md={7} xs={12}>
          {chipOptions &&
            chipOptions.length > 0 &&
            chipOptions.map((option) => (
              <Chip
                className={classes.chip}
                key={option.value}
                label={option.label}
                clickable
                onClick={() => handleRangeChangeChip(option)}
                color={
                  option.value != '0' &&
                  differenceInDays(dateRange.endDate, dateRange.startDate) ===
                    Number(option.value)
                    ? 'primary'
                    : (option.label === 'Hoje' &&
                        format(dateRange.startDate, 'dd/MM/yyyy') ===
                          format(today, 'dd/MM/yyyy')) ||
                      (option.label === 'Ontem' &&
                        format(dateRange.startDate, 'dd/MM/yyyy') ===
                          format(yesterday, 'dd/MM/yyyy') &&
                        format(dateRange.endDate, 'dd/MM/yyyy') ===
                          format(yesterday, 'dd/MM/yyyy'))
                    ? 'primary'
                    : 'default'
                }
              />
            ))}
          <ButtonGroup
            variant="contained"
            className={classes.calendarTodayGroup}
          >
            <Button onClick={() => handleCalendarOpen('start')}>
              <CalendarTodayIcon className={classes.calendarTodayIcon} />
              {dateRange && format(dateRange.startDate, 'dd/MM/yyyy')}
            </Button>
            <Button onClick={() => handleCalendarOpen('end')}>
              <CalendarTodayIcon className={classes.calendarTodayIcon} />
              {dateRange && format(dateRange.endDate, 'dd/MM/yyyy')}
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <DatePicker
        disableFuture
        onAccept={handleCalendarAccept}
        onChange={handleCalendarChange}
        onClose={handleCalendarClose}
        open={open}
        style={{ display: 'none' }} // Temporal fix to hide the input element
        value={calendarDate}
        variant="dialog"
        cancelLabel="Cancelar"
        okLabel="Definir"
      />
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string
}

Header.defaultProps = {}

export default Header
