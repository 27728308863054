import React, { Fragment, useRef, useState, memo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  FormControlLabel,
  Typography
} from '@material-ui/core'
import SortRoundedIcon from '@material-ui/icons/SortRounded'

const useStyles = makeStyles((theme) => ({
  title: { paddingLeft: theme.spacing(2), paddingTop: theme.spacing(1) }
}))

const SortSwitches = (props) => {
  const classes = useStyles()
  const moreRef = useRef(null)
  const { sortByEvento, setSortByEvento, sortByLote, setSortByLote } = props
  const [openMenu, setOpenMenu] = useState(false)

  const handleMenuOpen = () => {
    setOpenMenu(true)
  }

  const handleMenuClose = () => {
    setOpenMenu(false)
  }

  return (
    <Fragment>
      <Tooltip title="Opções de agrupamento">
        <IconButton
          {...props}
          onClick={handleMenuOpen}
          ref={moreRef}
          size="small"
        >
          <SortRoundedIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        classes={{ paper: classes.menu }}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Typography
          className={classes.title}
          gutterBottom
          variant="h6"
          color="textPrimary"
        >
          Agrupar por:
        </Typography>
        <MenuItem>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={sortByEvento}
                onChange={(event) => setSortByEvento(event.target.checked)}
                name="sortByEvento"
                inputProps={{ 'aria-label': 'agrupar por eventos' }}
              />
            }
            label="Eventos"
          />
        </MenuItem>
        {sortByEvento && (
          <MenuItem>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={sortByLote}
                  onChange={(event) => setSortByLote(event.target.checked)}
                  name="sortByLote"
                  inputProps={{ 'aria-label': 'agrupar por lotes' }}
                />
              }
              label="Lotes"
            />
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  )
}

SortSwitches.propTypes = {
  className: PropTypes.string
}

export default memo(SortSwitches)
