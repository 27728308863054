import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  TableRow,
  TableCell,
  Grid,
  Tooltip,
  IconButton,
  Button,
  colors,
  CircularProgress,
  Link,
  Avatar,
  makeStyles
} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

//? Own imports
import { CancelamentoIngresso, StatusCancelamentoIngresso } from 'types/api'
import {
  getInitials,
  getStatusCancelamentoIngressoColor,
  toLocalDateFormat
} from 'utils'
import { Label } from 'components'
import { useMutationFinishOrderCancel } from 'hooks-querys'

const useStyles = makeStyles((theme) => ({
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  }
}))

const RowTableCancelamentosIngressos = ({
  orderCancelItem
}: {
  orderCancelItem: CancelamentoIngresso
}) => {
  const classes = useStyles()
  const mutationFinishOrderCancel = useMutationFinishOrderCancel()
  const evento = orderCancelItem.pedido?.ingresso[0]?.lote?.evento
  const usuario = orderCancelItem.pedido?.ingresso[0]?.usuario
  return (
    <TableRow hover>
      <TableCell component="th" scope="orderCancelItem">
        {orderCancelItem.id}
      </TableCell>
      <TableCell align="center">
        {usuario && usuario.nome ? (
          <div className={classes.nameCell}>
            <Avatar className={classes.avatar} src={usuario?.imagem}>
              {getInitials(usuario?.nome)}
            </Avatar>
            <div>
              <Link
                color="inherit"
                component={RouterLink}
                to={`usuario/${usuario.id}`}
                variant="h6"
              >
                {usuario?.nome}
              </Link>
              <div>{usuario?.email}</div>
            </div>
          </div>
        ) : (
          'Usuário não encontrado'
        )}
      </TableCell>
      <TableCell align="center">
        {evento && evento.nome ? evento.nome : 'Evento não encontrado'}
      </TableCell>
      <TableCell align="center">
        {orderCancelItem &&
          orderCancelItem.motivoCancelamentoIngresso?.descricao}
      </TableCell>
      <TableCell align="center">
        {orderCancelItem && orderCancelItem.dataHora
          ? toLocalDateFormat(orderCancelItem.dataHora)
          : '----------'}
      </TableCell>
      <TableCell align="center">
        {orderCancelItem && orderCancelItem.dataHoraProcessamento
          ? toLocalDateFormat(orderCancelItem.dataHoraProcessamento)
          : '----------'}
      </TableCell>
      {orderCancelItem && orderCancelItem.statusCancelamentoIngresso && (
        <TableCell align="center">
          <Label
            color={getStatusCancelamentoIngressoColor(
              orderCancelItem.statusCancelamentoIngresso
            )}
            variant="outlined"
          >
            {orderCancelItem.statusCancelamentoIngresso.descricao}
          </Label>
        </TableCell>
      )}
      <TableCell align="right">
        <Grid wrap="nowrap" container justify="flex-end">
          {orderCancelItem &&
            orderCancelItem.statusCancelamentoIngresso?.pendente && (
              <Grid item>
                <Tooltip title="Finalizar cancelamento">
                  <Button
                    disabled={mutationFinishOrderCancel.isLoading}
                    color={'primary'}
                    size="small"
                    variant="outlined"
                    endIcon={
                      mutationFinishOrderCancel.isLoading && (
                        <CircularProgress size={18} />
                      )
                    }
                    onClick={() => {
                      mutationFinishOrderCancel.mutate(orderCancelItem)
                    }}
                  >
                    {mutationFinishOrderCancel.isLoading
                      ? 'Finalizando...'
                      : 'Finalizar'}
                  </Button>
                </Tooltip>
              </Grid>
            )}
          <Grid>
            <Tooltip title="Detalhes">
              <Link
                component={RouterLink}
                to={`/cancelamentosIngressos/info/${orderCancelItem.id}`}
              >
                <IconButton
                  size="small"
                  aria-label="detalhes cancelamento ingresso"
                  style={{ marginLeft: 10 }}
                >
                  <InfoOutlinedIcon />
                </IconButton>
              </Link>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
}

export default RowTableCancelamentosIngressos
