import React from 'react'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  LinearProgress,
  makeStyles
} from '@material-ui/core'
import ConfirmationNumberTwoToneIcon from '@material-ui/icons/ConfirmationNumberTwoTone'
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import constants from 'theme/constants'
import { useParams } from 'react-router-dom'
import { useEventDashboard } from 'hooks-querys'
var pluralize = require('pluralize')

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    ...constants.shadowCard
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 46,
    width: 46
  },
  icon: {
    height: 22,
    width: 22
  },
  progress: {
    marginTop: theme.spacing(3),
    height: 5,
    borderRadius: 5
  }
}))

const IngressosDisponiveis = () => {
  const classes = useStyles()
  const { eventoId } = useParams<{ eventoId: string }>()
  const { data, isFetching } = useEventDashboard(Number(eventoId))
  const { quantidadeIngressosDisponiveis, quantidadeIngressosTotal } =
    data || {}

  // @ts-ignore
  const { showValues } = useSelector((state) => state.global)

  const percentage = () => {
    if (quantidadeIngressosTotal && quantidadeIngressosDisponiveis) {
      var total = quantidadeIngressosTotal
      var currentValue = quantidadeIngressosDisponiveis
      var percentage = parseFloat(((currentValue / total) * 100).toFixed(1))
      if (isNaN(percentage)) {
        return ' '
      } else {
        return percentage.toLocaleString('pt-BR', {
          maximumFractionDigits: 2
        })
      }
    } else {
      return ' '
    }
  }
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              INGRESSOS RESTANTES
            </Typography>
            <Typography variant="h4">
              {isFetching || !showValues ? (
                <Skeleton animation={isFetching ? 'pulse' : false} />
              ) : (
                pluralize('INGRESSOS', quantidadeIngressosDisponiveis, true)
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <ConfirmationNumberTwoToneIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <LinearProgress
          className={classes.progress}
          value={Number(percentage().replace(',', '.'))}
          variant="determinate"
        />
        <Typography variant="body2">
          {isFetching || !showValues ? (
            <Skeleton animation={isFetching ? 'pulse' : false} />
          ) : (
            `${percentage()}% dos totais - (${quantidadeIngressosTotal} ingressos)`
          )}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default IngressosDisponiveis
