import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Typography,
  Avatar,
  Link,
  colors,
  makeStyles
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { withRouter } from 'react-router-dom'
import { cpfCnpjMask, dataMask, getImageUrls, getInitials, history, phoneMask, toLocalDateFormat } from '../../utils'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import { Label, Page, SkeletonTable } from 'components'
import constants from 'theme/constants'
import { useMutationMemberDelete, useMemberList } from 'hooks-querys'
import { Member } from 'types/api'
import ShareIcon from '@material-ui/icons/Share'
import { toast } from 'react-toastify'
import { genericOnShare } from 'utils/genericOnShare'
import EmptyFirstMember from './components/EmptyFirstMember'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { format } from 'date-fns'

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    ...constants.shadowCard
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  }
}))

// @ts-ignore
const MemberList = (props) => {
  const classes = useStyles()
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const mutationDeleteMember = useMutationMemberDelete()

  const estabelecimentoId = useSelector(
    // @ts-ignore: //ainda não foi tipado o redux state
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const { data : members } = useMemberList(estabelecimentoId)

  const handleDeleteClick = (idMember: number) => {
    confirm({
      title: 'Você deseja excluir esse membros?',
      //description: 'Confirmando essa operação, plano será excluido para sempre!',
      confirmationText: 'Sim, excluir!',
      cancellationText: 'Nãããoo!'
    }).then(() => {
      deleteMember(idMember)
    })
  }

  const deleteMember = (idMember: number) => {
    mutationDeleteMember.mutate(idMember)
  }

  return (
    // @ts-ignore
    <Page className={classes.root} title="Listagem de Membros">
      <AccessControl
        rule={'membros.list'}
        // @ts-ignore
        yes={() => (
          <div>
            {members && members.length > 0 ? (
              <Typography variant="body2" align="left" gutterBottom>
                Total de dados encontrados: <b>{members.length}</b>
              </Typography>
            ) : null}
            {undefined !== members && members.length ? (
              <TableContainer component={Paper} className={classes.paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Matrícula</TableCell>
                      <TableCell align="center">Membro</TableCell>
                      <TableCell align="center">CPF/CNPJ</TableCell>
                      <TableCell align="center">Telefone</TableCell>
                      <TableCell align="center">Data Nascimento</TableCell>
                      <TableCell align="center">Plano</TableCell>
                      <TableCell align="center">Data de início</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="right">Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {members.map((n: Member) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell align="left" component="th" scope="row">
                            {!!n.membroPlano
                              ? n.membroPlano[0].matricula
                                ? n.membroPlano[0].matricula
                                : n.membroPlano[0].id
                              : ''}
                            {/* {n.membroPlano[0].matricula} */}
                          </TableCell>
                          <TableCell align="center">
                            {n.nome ? (
                              <div className={classes.nameCell}>
                                <Avatar className={classes.avatar} src={n?.imagem}>
                                  {getInitials(n?.nome)}
                                </Avatar>
                                <div>
                                  {n?.nome}
                                <div>{n?.email}</div>
                                </div>
                              </div>
                            ) : (
                              'Usuário não encontrado'
                            )}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {cpfCnpjMask(n.cpfCnpj)}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {phoneMask(n.telefone)}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {n.dataNascimento ? format(new Date(n.dataNascimento), 'dd/MM/yyyy') : ''}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {n.membroPlano ? n.membroPlano[0].plano?.nome : ''}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {n.membroPlano ? format(new Date(n.membroPlano[0].dataHoraInicio), 'dd/MM/yyyy') : ''}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            <div>
                              <Label
                                color={
                                  n.membroPlano && n.membroPlano[0].statusMembroPlanoId === 1 ? colors.green[600] : colors.red[600]
                                }
                              >
                                {n.membroPlano && n.membroPlano[0].statusMembroPlano ? n.membroPlano[0].statusMembroPlano.descricao : 'INDEFINIDO'}
                              </Label>
                            </div>
                          </TableCell>

                          <TableCell align="right">
                            <AccessControl
                              rule={'membros.edit'}
                              // @ts-ignore
                              yes={() => (
                                <IconButton
                                  size="small"
                                  aria-label="MemberEdit"
                                  component="a"
                                  onClick={() =>
                                    history.push(`/members/cadastroMembro/${n.id}`)
                                  }
                                >
                                  <Tooltip title="Editar">
                                    <EditIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              rule={'membros.edit'}
                              // @ts-ignore
                              yes={() => (
                                <IconButton
                                  size="small"
                                  aria-label="MemberDetails"
                                  component="a"
                                  onClick={() =>
                                    n.membroPlano && history.push(`/members/memberPlanDetails/${n.membroPlano[0]?.id}`)
                                  }
                                >
                                  <Tooltip title="Informações">
                                    <InfoOutlinedIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <EmptyFirstMember />
            )}
          </div>
        )}
      />
    </Page>
  )
}

export default MemberList
