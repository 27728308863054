import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

//? Own imports
import { Member } from 'types/api'
import { crudService } from '_services'

const deleteMember = async (idMember: Number) => {
  const { data } = await crudService.deleteDetail(`membros/${idMember}`)
  return data
}

export default function useMutationMemberDelete() {
  const queryClient = useQueryClient()
  return useMutation(
    'membershipPlan-delete',
    (idMember: Number) => deleteMember(idMember)
    // {
    //   onError: (error) => {
    //     // @ts-ignore
    //     toast.error(error.message || 'Erro ao excluir plano de sócios!')
    //   },
    //   onSuccess: (response: Member) => {
    //     // toast.success('Plano de sócios excluido com sucesso!')
    //     queryClient.setQueryData<Member[] | undefined>(
    //       ['membershipPlan-list', response.estabelecimentoId],
    //       (oldData) => {
    //         if (oldData) {
    //           return oldData.filter((item) => item.id !== response.id)
    //         }
    //         return oldData
    //       }
    //     )
    //     queryClient.invalidateQueries([
    //       'membershipPlan-list',
    //       response.estabelecimentoId
    //     ])
    //   }
    // }
  )
}
