import React from 'react'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  makeStyles
} from '@material-ui/core'
import LocalAtmTwoToneIcon from '@material-ui/icons/LocalAtmTwoTone'
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import constants from 'theme/constants'
import { useEstablishmentDashboard } from 'hooks-querys'
var pluralize = require('pluralize')

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    ...constants.shadowCard
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 46,
    width: 46
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  }
}))

const Budget = () => {
  const classes = useStyles()
  const { estabelecimentoId } = useSelector(
    // @ts-ignore
    (state) => state.usuarioEstabelecimento
  )
  const { data, isFetching } = useEstablishmentDashboard(
    Number(estabelecimentoId)
  )
  const { quantidadeIngressosNaoTransferidos, valorIngressosNaoTransferidos } =
    data || {}

  // @ts-ignore
  const { showValues } = useSelector((state) => state.global)

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              VENDAS A RECEBER
            </Typography>
            <Typography variant="h3">
              {isFetching || !showValues ? (
                <Skeleton animation={isFetching ? 'pulse' : false} />
              ) : (
                pluralize('INGRESSOS', quantidadeIngressosNaoTransferidos, true)
              )}
            </Typography>
            <Typography variant="body2">TOTALIZANDO</Typography>
            <Typography variant="h3">
              {isFetching || !showValues ? (
                <Skeleton animation={isFetching ? 'pulse' : false} />
              ) : (
                Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(valorIngressosNaoTransferidos || 0)
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <LocalAtmTwoToneIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        {/* <div className={classes.difference}>
          <Typography className={classes.caption} variant="caption">
            Desde de 1 de janeiro de 2020
          </Typography>
        </div> */}
      </CardContent>
    </Card>
  )
}

export default Budget
