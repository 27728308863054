import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  permissaoTipoUsuarioAction,
  tipoUsuarioAction
} from '../../../../_actions'
import {
  MenuItem,
  Grid,
  makeStyles,
  FormControl,
  Select,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Button,
  useTheme,
  useMediaQuery,
  CircularProgress
} from '@material-ui/core'
import { Page } from 'components'
import TablePermissoes from '../TablePermissoes/TablePermissoes'
import DoneRoundedIcon from '@material-ui/icons/DoneRounded'
import { AccessControl } from 'components/Utils/AccessControl'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2, 2, 2)
  },
  formControl: {
    minWidth: 260
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  content: {
    marginBottom: theme.spacing(2)
  },
  buttonSubmit: {
    marginTop: theme.spacing(3)
  }
}))

const PermissaoTipoUsuario = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { tipoUsuario, permissaoTipoUsuario } = useSelector((state) => state)
  const { tipoUsuarioId, isLoading } = permissaoTipoUsuario
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isEmptyPermissaoTipoUsuario =
    permissaoTipoUsuario.permissaoTipoUsuario.length === 0 ? true : false

  useEffect(() => {
    dispatch(tipoUsuarioAction.getTipoUsuarioSelect())
    dispatch(permissaoTipoUsuarioAction.clear())
  }, [])

  useEffect(() => {
    dispatch(
      permissaoTipoUsuarioAction.handleCheckAllPermissaoTipoUsuario(
        permissaoTipoUsuario.checkedAll
      )
    )
  }, [permissaoTipoUsuario.checkedAll])

  const handleChangeSelected = () => (event) => {
    if (event.target.value && event.target.value > 0) {
      dispatch(
        permissaoTipoUsuarioAction.getPermissaoTipoUsuario(event.target.value)
      )
    } else {
      dispatch(permissaoTipoUsuarioAction.clear())
    }
  }

  const handleChangePermissaoTipoUsuario = (idPermissaoTipoUsuario) => {
    dispatch(
      permissaoTipoUsuarioAction.handleCheckedPermitido(idPermissaoTipoUsuario)
    )
  }

  const handleSubmitPermissaoTipoUsuario = () => {
    dispatch(
      permissaoTipoUsuarioAction.createPermissaoTipoUsuario(
        permissaoTipoUsuario.permissaoTipoUsuario
      )
    )
  }
  const handleChangeCheckedAll = () => {
    dispatch(permissaoTipoUsuarioAction.handleChangeCheckAll())
  }

  return (
    <AccessControl
      rule="permissoesTiposUsuarios"
      yes={() => (
        <Page title="Permissões - Tipo de usuário">
          <Grid
            className={classes.content}
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth={isMobile ? true : false}
                size="small"
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="permissao-tipo-usuario-label">
                  Tipo de usuário
                </InputLabel>
                <Select
                  label="Tipo de usuário"
                  labelId="permissao-tipo-usuario-label"
                  id="permissaoTipoUsuario  "
                  value={tipoUsuarioId}
                  onChange={handleChangeSelected('tipoUsuarioId')}
                >
                  <MenuItem disabled value="">
                    <em>Selecione...</em>
                  </MenuItem>
                  {tipoUsuario.tipoUsuario.map((row) => (
                    <MenuItem key={row.value} value={row.value}>
                      {row.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              style={isMobile ? { textAlign: 'left' } : { textAlign: 'right' }}
              item
              xs={12}
              sm={6}
            >
              <FormControlLabel
                disabled={isEmptyPermissaoTipoUsuario}
                control={
                  <Checkbox
                    checked={permissaoTipoUsuario.checkedAll}
                    onChange={handleChangeCheckedAll}
                    name="Marcar/desmarcar todos"
                    color="primary"
                  />
                }
                label="Marcar/desmarcar todos"
              />
            </Grid>
          </Grid>
          {!isEmptyPermissaoTipoUsuario && (
            <>
              <TablePermissoes
                permissoes={permissaoTipoUsuario.permissaoTipoUsuario}
                onCheckedPermissao={handleChangePermissaoTipoUsuario}
              ></TablePermissoes>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-end"
              >
                <Grid item xs={12}>
                  <Button
                    disabled={isLoading}
                    endIcon={
                      isLoading ? (
                        <CircularProgress color="inherit" size={24} />
                      ) : (
                        <DoneRoundedIcon />
                      )
                    }
                    className={classes.buttonSubmit}
                    variant="contained"
                    onClick={handleSubmitPermissaoTipoUsuario}
                    color="primary"
                  >
                    {isLoading ? 'Enviando informações...' : 'Salvar'}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Page>
      )}
    ></AccessControl>
  )
}

export default PermissaoTipoUsuario
