export function getImageUrls(imageUrl: string | undefined) {
  if (!imageUrl) return undefined
  const imageName = imageUrl.split('/').pop()
  const environment = imageUrl.split('/').at(-2)
  const originalUrl = `https://corujasimg.s3.amazonaws.com/images/${environment}/${imageName}`
  const compressedUrl = `https://corujasimg-compressed.s3.amazonaws.com/compressed-images/${environment}/${imageName}`
  const thumbnailUrl = `https://corujasimg-thumbnail.s3.amazonaws.com/thumb-compressed-images/${environment}/${imageName}`

  return { originalUrl, compressedUrl, thumbnailUrl }
}
