import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { history } from 'utils'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  Typography,
  Button,
  useTheme,
  useMediaQuery
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import useRouter from 'utils/useRouter'

const useStyles = makeStyles((theme) => ({
  button: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2)
    },
    webkitTransition: 'all 300ms ease',
    transition: 'all 300ms ease',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0 9px 40px -14px #FFC20A'
    }
  }
}))

const Notifications = (props) => {
  const { className } = props
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const { match } = useRouter()
  const { url } = match

  const hasContaBancaria = useSelector((state) => {
    if (state.contaBancaria.contaBancaria.length > 0) {
      return true
    }
    return false
  })

  const { isFetching } = useSelector((state) => state.contaBancaria)
  return (
    <>
      {!hasContaBancaria && !isFetching && !url.includes('contasBancarias') && (
        <Card className={clsx(classes.root, className)}>
          <Alert
            action={
              !isMobile && (
                <>
                  <Button
                    className={classes.button}
                    onClick={() =>
                      history.push(`/carteira/contasBancarias/cadastro`)
                    }
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Cadastrar conta! 💰
                  </Button>
                  <Button
                    className={classes.button}
                    onClick={() =>
                      history.push(`/carteira/contasBancarias/pix`)
                    }
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Cadastrar Pix! 💰
                  </Button>
                </>
              )
            }
            severity="error"
          >
            <AlertTitle>
              <Typography
                align={isMobile ? 'justify' : 'left'}
                variant="body1"
                color="textPrimary"
              >
                Notamos que você ainda não cadastrou nenhuma conta bancária.
              </Typography>
            </AlertTitle>
            <Typography
              align={isMobile ? 'justify' : 'left'}
              variant="caption"
              color="inherit"
            >
              ATENÇÃO! Você precisa cadastrar ao menos um conta bancária para
              realizar retiradas!
            </Typography>
            {isMobile && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  style={{ marginTop: 10 }}
                  fullWidth
                  onClick={() =>
                    history.push(`/carteira/contasBancarias/cadastro`)
                  }
                >
                  Cadastrar conta! 💰
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  style={{ marginTop: 10 }}
                  fullWidth
                  onClick={() => history.push(`/carteira/contasBancarias/pix`)}
                >
                  Cadastrar Pix! 💰
                </Button>
              </>
            )}
          </Alert>
        </Card>
      )}
    </>
  )
}

Notifications.propTypes = {
  className: PropTypes.string
}

export default Notifications
