import { useQuery } from 'react-query'

//? Own imports
import { crudService } from '_services'
import { Ingresso } from 'types/api'

const getIngressosMovimentacaoFinanceira = async (movimentacaoFinanceiraId: number | undefined) => {
  const { data } = await crudService.get(`ingressos/movimentacaoFinanceira/${movimentacaoFinanceiraId}`)
  return data
}

export default function useIngressosMovimentacaoFinanceira(
  movimentacaoFinanceiraId: number | undefined,
) {
  return useQuery<Ingresso[]>(['movimentacaoFinanceira', movimentacaoFinanceiraId], () => getIngressosMovimentacaoFinanceira(movimentacaoFinanceiraId), {
    enabled: !!movimentacaoFinanceiraId
  })
}
