import React from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Typography,
  makeStyles,
  Switch,
  Box,
  FormControlLabel,
  Tooltip
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded'
import { useConfirm } from 'material-ui-confirm'

//? Own imports
import { MemberPlan } from 'types/api'
import constants from 'theme/constants'
import { useMutationMemberPlanDelete } from 'hooks-querys'

const useStyles = makeStyles((theme) => ({
  root: {
    ...constants.shadowCard
  },
  mainActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  notice: {
    marginTop: theme.spacing(1)
  },
  deleteButton: {
    marginTop: theme.spacing(1),
    color: '#fff',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  controlLabel: {
    padding: theme.spacing(1)
  }
}))

type OtherActionsProps = {
  memberPlan: MemberPlan
}


const OtherActions = ({ memberPlan }: OtherActionsProps) => {
  const classes = useStyles()
  const confirm = useConfirm()
  const mutationDeleteMemberPlan = useMutationMemberPlanDelete()

  const handleDeleteMemberPlanClick = (idMemberPlan: number) => {
    confirm({
      title: 'Você deseja excluir esse membro?',
      description: 'Confirmando essa operação, o membro será excluido para sempre!',
      confirmationText: 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      deleteMemberPlan(idMemberPlan)
    })
  }

  const deleteMemberPlan = (idMemberPlan: number) => {
    mutationDeleteMemberPlan.mutate(idMemberPlan)
  }

  return (
    <Card className={classes.root}>
      <CardHeader title="Outras ações" />
      <Divider />
      <CardContent>
        <Typography variant="h5">Status de acesso</Typography>
        <Typography variant="body2">
          Permitir ou bloquear acesso do membro
        </Typography>
        <FormControlLabel
          className={classes.controlLabel}
          control={
            <Tooltip title={memberPlan?.ativo ? 'Bloquear' : 'Permitir'}>
              <Switch
                color="primary"
                edge="start"
                name="verified"
                contentEditable
                // onChange={handleFieldChange}
                checked={true}
                disabled={true}
              />
            </Tooltip>
          }
          label={memberPlan?.ativo ? 'Permitido' : 'Bloqueado'}
        />
        <Box marginY={1}>
          <Button startIcon={<MailOutlineRoundedIcon />} disabled={true}>
            Reenviar email de associação
          </Button>
        </Box>
        <Divider />
        <Typography className={classes.notice} variant="body2" gutterBottom>
          Remova os dados deste membro se ele solicitou, se não, por favor
          esteja ciente de que o que for excluído nunca poderá ser recuperado.
        </Typography>
        <Button
          className={classes.deleteButton}
          startIcon={<DeleteIcon />}
          onClick={() => handleDeleteMemberPlanClick(memberPlan.id)}
          >
          Excluir membro
        </Button>
      </CardContent>
    </Card>
  )
}

export default OtherActions
