import React from 'react'
import { Pie } from 'react-chartjs-2'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  useTheme,
  Grid
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import { colors } from '@material-ui/core'
import constants from 'theme/constants'
import { useParams } from 'react-router-dom'
import { useEventDashboard } from 'hooks-querys'
var pluralize = require('pluralize')

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    ...constants.shadowCard
  },
  chartContainer: {
    position: 'relative',
    height: '300px',
    justifyContent: 'center'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  tipoDeIngresso: {
    textAlign: 'center'
  },
  quantity: {
    fontSize: 9
  }
}))

const GraficoIngressosTipoIngresso = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { eventoId } = useParams<{ eventoId: string }>()
  const { data, isFetching } = useEventDashboard(Number(eventoId))

  // @ts-ignore
  const { showValues } = useSelector((state) => state.global)
  const { graficoIngressosTipoIngresso } = data || {}

  const generateData = () => {
    if (graficoIngressosTipoIngresso && graficoIngressosTipoIngresso.options) {
      const data = {
        datasets: [
          {
            data: graficoIngressosTipoIngresso.series[1],
            backgroundColor: [
              colors.deepPurple[200],
              colors.teal[200],
              colors.lightGreen[200],
              colors.brown[200],
              colors.blueGrey[200]
            ],
            borderWidth: 3,
            borderColor: theme.palette.white,
            hoverBorderColor: [
              colors.deepPurple[400],
              colors.teal[400],
              colors.lightGreen[400],
              colors.brown[400],
              colors.blueGrey[400]
            ]
          }
        ],
        labels: graficoIngressosTipoIngresso.options
      }
      return data
    } else {
      return []
    }
  }

  const generateStrings = () => {
    var data = [] as any
    if (graficoIngressosTipoIngresso && graficoIngressosTipoIngresso.options) {
      data = [
        {
          title: graficoIngressosTipoIngresso.options[0],
          value:
            graficoIngressosTipoIngresso.series[1][0] !== undefined
              ? Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(graficoIngressosTipoIngresso.series[1][0])
              : null,
          color: colors.deepPurple[200],
          quantity: graficoIngressosTipoIngresso.series[0][0]
        },
        {
          title: graficoIngressosTipoIngresso.options[1],
          value:
            graficoIngressosTipoIngresso.series[1][1] !== undefined
              ? Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(graficoIngressosTipoIngresso.series[1][1])
              : null,
          color: colors.teal[200],
          quantity: graficoIngressosTipoIngresso.series[0][1]
        },
        {
          title: graficoIngressosTipoIngresso.options[2],
          value:
            graficoIngressosTipoIngresso.series[1][2] !== undefined
              ? Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(graficoIngressosTipoIngresso.series[1][2])
              : null,
          color: colors.lightGreen[200],
          quantity: graficoIngressosTipoIngresso.series[0][2]
        },
        {
          title: graficoIngressosTipoIngresso.options[3],
          value:
            graficoIngressosTipoIngresso.series[1][3] !== undefined
              ? Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(graficoIngressosTipoIngresso.series[1][3])
              : null,
          color: colors.brown[200],
          quantity: graficoIngressosTipoIngresso.series[0][3]
        },
        {
          title: graficoIngressosTipoIngresso.options[4],
          value:
            graficoIngressosTipoIngresso.series[1][4] !== undefined
              ? Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(graficoIngressosTipoIngresso.series[1][4])
              : null,
          color: colors.blueGrey[400],
          quantity: graficoIngressosTipoIngresso.series[0][4]
        }
      ]
      return data
    } else {
      return data
    }
  }

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    //animation: false,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',

      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        // @ts-ignore
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex]
          var meta = dataset._meta[Object.keys(dataset._meta)[0]]
          var total = meta.total
          var currentValue = dataset.data[tooltipItem.index]
          var percentage = parseFloat(((currentValue / total) * 100).toFixed(1))
          if (!isNaN(percentage)) {
            return (
              Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(currentValue) +
              ' (' +
              percentage.toLocaleString('pt-BR', {
                maximumFractionDigits: 2
              }) +
              '%)'
            )
          } else {
            return null
          }
        },
        // @ts-ignore
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index].toUpperCase()
        }
      }
    }
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        // action={
        //   <IconButton size="small">
        //     <RefreshIcon />
        //   </IconButton>
        // }
        title="VENDAS POR TIPO DE INGRESSO"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          {isFetching || !showValues ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Skeleton
                variant="circle"
                animation={isFetching ? 'pulse' : false}
                height={300}
                width={300}
              />
            </div>
          ) : (
            <Pie data={generateData} options={options} />
          )}
        </div>
        <div className={classes.stats}>
          {isFetching || !showValues ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Skeleton
                animation={isFetching ? 'pulse' : false}
                height={50}
                width="100%"
              />
            </div>
          ) : (
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              spacing={2}
            >
              {generateStrings().map(
                // @ts-ignore
                (tipoDeIngresso) =>
                  tipoDeIngresso.value !== null && (
                    <Grid
                      item
                      xs={4}
                      className={classes.tipoDeIngresso}
                      key={tipoDeIngresso.title}
                    >
                      <Typography
                        style={{ textTransform: 'capitalize' }}
                        variant="body2"
                      >
                        {tipoDeIngresso.title}
                      </Typography>
                      <Typography
                        style={{ color: tipoDeIngresso.color }}
                        variant="h6"
                      >
                        {tipoDeIngresso.value}
                      </Typography>
                      <Typography
                        className={classes.quantity}
                        variant="caption"
                      >
                        {pluralize('ingressos', tipoDeIngresso.quantity, true)}
                      </Typography>
                    </Grid>
                  )
              )}
            </Grid>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

export default GraficoIngressosTipoIngresso
