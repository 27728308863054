export const getPeriodicityString = (periodicity: string): string => {
  switch (periodicity) {
    case 'M':
      return 'Mensal'
    case 'A':
      return 'Anual'
    default:
      return 'Não identificado'
  }
}
