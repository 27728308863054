import { useQuery } from 'react-query'
import { crudService } from '_services'

const getMembershipPlanId = async (id: number) => {
  const { data } = await crudService.get(`planos/${id}`)
  return data
}

export default function useMembershipPlanId(id: number) {
  return useQuery(['membershipPlan-id', id], () => getMembershipPlanId(id), {
    enabled: id > 0,
    cacheTime: 0
  })
}
