import React, { useEffect, useState } from 'react'
import { Header, SkeletonTable } from 'components'
import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select
} from '@material-ui/core'
import { TableMovimentacoesFinanceiras } from './components'
import { useSelector, useDispatch } from 'react-redux'
import { AccessControl } from 'components/Utils/AccessControl'
import {
  movimentacaoFinanceiraAction,
  statusMovimentacaoFinanceiraAction
} from '_actions'
import { isEmpty } from 'validate.js'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(3, 2, 2, 2)
  },
  header: {
    paddingBottom: theme.spacing(2)
  }
}))

const MovimentacoesFinanceirasList = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    movimentacaoFinanceira: movimentacoesFinanceiras,
    isLoading
  } = useSelector((state) => state.movimentacaoFinanceira)
  const {
    statusMovimentacaoFinanceiraSelect,
    isFetching: isFetchingStatusMovimentacaoFinanceira
  } = useSelector((state) => state.statusMovimentacaoFinanceira)
  const [
    statusMovimentacaoFinanceiraId,
    setStatusMovimentacaoFinanceiraId
  ] = useState(1)

  const handleChange = (event) => {
    event.preventDefault()
    setStatusMovimentacaoFinanceiraId(event.target.value)
  }

  useEffect(() => {
    let payload = {
      statusMovimentacaoFinanceiraId: statusMovimentacaoFinanceiraId
      // estabelecimentoId: 0
    }
    dispatch(movimentacaoFinanceiraAction.getAllMovimentacaoFinanceira(payload))
  }, [statusMovimentacaoFinanceiraId])

  useEffect(() => {
    if (
      statusMovimentacaoFinanceiraSelect !== undefined &&
      statusMovimentacaoFinanceiraSelect.length === 0
    ) {
      dispatch(
        statusMovimentacaoFinanceiraAction.getStatusMovimentacaoFinanceiraSelect()
      )
    }
  }, [])

  return (
    <AccessControl
      rule={'movimentacoesFinanceiras.list'} //permissão necessária para acessar conteúdo
      yes={() => (
        <div className={classes.root}>
          <Header
            className={classes.header}
            subtitle="Gerência de movimentações financeiras"
            title="Lista de movimentações financeiras"
          />
          <Grid container alignItems="center">
            <Grid item xs={12} sm={7} md={6} lg={5} xl={4}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="status-movimentacao-financeira-label">
                  Status
                </InputLabel>
                <Select
                  endAdornment={
                    isFetchingStatusMovimentacaoFinanceira && (
                      <CircularProgress size={24} />
                    )
                  }
                  disabled={isFetchingStatusMovimentacaoFinanceira}
                  placeholder="Status"
                  labelId="status-movimentacao-financeira-label"
                  id="status-movimentacao-financeira-select"
                  value={statusMovimentacaoFinanceiraId}
                  onChange={handleChange}
                  label="Status"
                >
                  {!isEmpty(statusMovimentacaoFinanceiraSelect) &&
                    statusMovimentacaoFinanceiraSelect.map((status) => (
                      <MenuItem
                        disabled={
                          status.value == statusMovimentacaoFinanceiraId
                            ? true
                            : false
                        }
                        key={status.value}
                        value={status.value}
                      >
                        {status.text}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {isLoading ? (
            <SkeletonTable />
          ) : (
            <TableMovimentacoesFinanceiras
              movimentacoesFinanceiras={movimentacoesFinanceiras}
            />
          )}
        </div>
      )}
    />
  )
}

export default MovimentacoesFinanceirasList
