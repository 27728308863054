import React from 'react'
import {
  TableBody,
  Table,
  makeStyles,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Toolbar,
  Tooltip,
  IconButton
} from '@material-ui/core'
import PrintRoundedIcon from '@material-ui/icons/PrintRounded'
import ReactToPrint from 'react-to-print'

//? Own imports
import { CancelamentoIngresso } from 'types/api'
import { RowTableCancelamentosIngressos } from './components'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700
  },
  rootToolbarStyles: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  titleToolbarStyles: {
    flex: '1 1 100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    ...constants.shadowCard
  }
}))

const TableCancelamentosIngressos = ({
  ordersCancel
}: {
  ordersCancel: CancelamentoIngresso[]
}) => {
  const classes = useStyles()
  const refTable = React.useRef(null)

  return (
    <div style={{ paddingTop: 15 }}>
      <Typography variant="body2" align="left" gutterBottom>
        {ordersCancel && ordersCancel.length > 0 ? (
          <>
            Total de dados encontrados: <strong>{ordersCancel.length}</strong>
          </>
        ) : (
          <strong>Nenhum dado encontrado.</strong>
        )}
      </Typography>
      {ordersCancel && ordersCancel.length > 0 && (
        <Paper ref={refTable} className={classes.paper}>
          <Toolbar className={classes.rootToolbarStyles}>
            <Typography
              className={classes.titleToolbarStyles}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Lista de solicitações de cancelamento
            </Typography>
            <ReactToPrint
              trigger={() => (
                <Tooltip title="Imprimir">
                  <IconButton aria-label="gerar relatório">
                    <PrintRoundedIcon />
                  </IconButton>
                </Tooltip>
              )}
              content={() => refTable.current}
            />
          </Toolbar>
          {/* usado para 'forçar' a orientação da pagina para 'paisagem' afim de imprimir mostrando todos os dados */}
          <style type="text/css" media="print">
            {'\
             @page { size: landscape; }\
            '}
          </style>
          {/* ------------------------------------------------------------>	 */}
          <TableContainer>
            {ordersCancel !== undefined && ordersCancel.length > 0 && (
              <Table
                className={classes.table}
                aria-label="tabela de cancelamentos de ingressos"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>N.º</TableCell>
                    <TableCell align="center">Usuário</TableCell>
                    <TableCell align="center">Evento</TableCell>
                    <TableCell align="center">Motivo</TableCell>
                    <TableCell align="center">Data solicitação</TableCell>
                    <TableCell align="center">Data finalização</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ordersCancel.map((orderCancelItem) => {
                    return (
                      <RowTableCancelamentosIngressos
                        orderCancelItem={orderCancelItem}
                        key={orderCancelItem.id}
                      />
                    )
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Paper>
      )}
    </div>
  )
}

export default TableCancelamentosIngressos
