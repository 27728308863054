import React, { ChangeEvent } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { Tabs, Tab, Divider, colors, makeStyles } from '@material-ui/core'

//? Own imports
import { Page } from 'components'
import { Info, Header, Orders, Connections, Reviews, Logs } from './components'
import { history } from 'utils'
import { User } from 'types/api'
import { AccessControl } from 'components/Utils'
import AccessDenied from 'views/Errors/AccessDenied'
import { useUserInfo } from 'hooks-querys'

const useStyles = makeStyles((theme) => ({
  root: {},
  inner: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}))

const CustomerManagementDetails = () => {
  const classes = useStyles()
  const { tab, userId } = useParams<{ tab: string; userId: string }>()
  const {
    data: user
  } = useUserInfo(Number(userId))

  const handleTabsChange = (_event: ChangeEvent<{}>, value: string) => {
    history.push(value)
  }

  const tabs = [
    { value: 'informacoes', label: 'Informações' },
    { value: 'pedidos', label: 'Pedidos' },
    { value: 'conexoes', label: 'Conexões' },
    { value: 'avaliacoes', label: 'Avaliações' },
    { value: 'logs', label: 'Logs' }
  ]

  if (!tab) {
    return <Redirect to={`/usuario/${userId}/informacoes`} />
  }

  if (!tabs.find((t) => t.value === tab)) {
    return <Redirect to="/errors/error-404" />
  }

  return (
    // @ts-ignore
    <AccessControl
      rule={'usuarios.listAdmin'}
      // @ts-ignore
      yes={() => (
        <Page title={`Informação do usuário @${user?.username}`}>
          <Header user={user} />
          <div className={classes.inner}>
            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
              value={tab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
            <Divider className={classes.divider} />
            <div className={classes.content}>
              {tab === 'informacoes' && <Info user={user}/>}
              {tab === 'pedidos' && <Orders userId={Number(userId) || 0} />}
              {tab === 'conexoes' && (
                <Connections userId={Number(userId) || 0} />
              )}
              {tab === 'avaliacoes' && <Reviews userId={Number(userId) || 0} />}
              {tab === 'logs' && <Logs userId={Number(userId) || 0} />}
            </div>
          </div>
        </Page>
      )}
      // @ts-ignore
      no={() => <AccessDenied />}
    />
  )
}

export default CustomerManagementDetails
