import { useMutationFinishOrderCancel } from 'hooks-querys'
import { useMutation, useQueryClient, useQuery } from 'react-query'
// import { useTheme } from 'react-native-elements'
// import { showMessage } from 'react-native-flash-message'
import { toast } from 'react-toastify'

//? Own imports
import { CancelamentoIngresso } from '../types/api'
import { crudService } from '_services'

const postCreateOrderCancel = async (payload: CancelamentoIngresso) => {
  const { data } = await crudService.post(
    'cancelamentosIngressos/requestAdmin',
    payload
  )
  return data
}

export default function useMutationCreateOrderCancel() {
  // const queryClient = useQueryClient()
  // const { theme } = useTheme()
  return useMutation(
    'mutationCreateOrderCancel',
    (payload: CancelamentoIngresso) => postCreateOrderCancel(payload)
    // {
    //   onError: (error) => {
    //     toast.error('Erro ao criar cancelamento')
    //   },
    //   onSuccess: (data: CancelamentoIngresso) => {
    //     queryClient.invalidateQueries(['orderDetails', data.pedidoId])
    //     queryClient.invalidateQueries(['ordersCancelUser'])
    //   }
    // }
  )
}
