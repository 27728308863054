import React, { useEffect, useState } from 'react'
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  Button,
  makeStyles,
  Box,
  MenuItem
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { cnpj, cpf } from 'cpf-cnpj-validator'

//? Own imports
import { Cidade, Estado, User } from 'types/api'
import { cepMask, cpfCnpjMask, phoneMask } from 'utils'

type UserEditProps = {
  user: User
  open: boolean
  onClose: () => void
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    [theme.breakpoints.up('sm')]: {
      borderRadius: '8px',
      webkitBorderRadius: '8px',
      mozBorderRadius: '8px'
    },
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}))

type UserEditForm = {
  user: User
  nome: string
  cpfCnpj: string
  apelido?: string
  username: string
  dataNascimento?: Date
  telefone: string
  genero: 'M' | 'F' | 'U'
  cidadeId: number
  cep: string
  endereco: string
  numero: string
  complemento?: string
  bairro: string
  email: string
}

const UserEdit = ({ open, onClose, user }: UserEditProps) => {
  const classes = useStyles()
  const [formState, setFormState] = useState<UserEditForm | any>({
    nome: user?.nome,
    cpfCnpj: user?.cpfCnpj,
    apelido: user?.apelido,
    username: user?.username,
    dataNascimento: user?.dataNascimento,
    telefone: user?.telefone,
    genero: user?.genero,
    cidadeId: user?.cidadeId,
    cep: user?.cep,
    endereco: user?.endereco,
    numero: user?.numero,
    complemento: user?.complemento,
    bairro: user?.bairro,
    email: user?.email
  })

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidCpf', (value) => {
      if (value && value.length <= 14) {
        if (cpf.isValid(value)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    })

    ValidatorForm.addValidationRule('isValidCnpj', (value) => {
      if (value && value.length >= 15) {
        if (cnpj.isValid(value)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    })
    ValidatorForm.addValidationRule('isValidPhone', (value) => {
      if (value && value !== null) {
        if (value.length > 0 && value.length < 14) {
          return false
        }
        return true
      }
      return true
    })
    ValidatorForm.addValidationRule('isValidCep', (value) => {
      if (value.length === 9) {
        return true
      } else {
        return false
      }
    })
    return () => {
      ValidatorForm.removeValidationRule('isValidCpf')
      ValidatorForm.removeValidationRule('isValidCnpj')
      ValidatorForm.removeValidationRule('isValidCep')
      ValidatorForm.removeValidationRule('isValidPhone')
    }
  }, [])

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()
    setFormState((formState: any) => ({
      ...formState,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    }))
  }

  const handleSave = () => {
    console.log(formState)
  }
  if (!open) {
    return null
  }

  return (
    <Modal onClose={onClose} open={open}>
      <Card className={classes.root}>
        <ValidatorForm onSubmit={handleSave} debounceTime={400}>
          <CardContent>
            <Box
              mb={{
                xs: 4,
                sm: 5
              }}
              mt={1}
            >
              <Typography align="center" gutterBottom variant="h3">
                Editar usuário
              </Typography>
            </Box>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={6}>
                <TextValidator
                  autoComplete="nome"
                  name="nome"
                  variant="outlined"
                  fullWidth
                  value={formState.nome}
                  onChange={handleFieldChange}
                  id="nome"
                  label="Nome completo *"
                  // autoFocus
                  validators={['required']}
                  errorMessages={['Campo obrigatório!']}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  variant="outlined"
                  fullWidth
                  id="cpfCnpj"
                  label="CPF/CNPJ *"
                  name="cpfCnpj"
                  type="text"
                  inputProps={{
                    maxLength: '18'
                  }}
                  value={cpfCnpjMask(formState.cpfCnpj)}
                  onChange={handleFieldChange}
                  autoComplete="cpf"
                  validators={['required', 'isValidCpf', 'isValidCnpj']}
                  errorMessages={[
                    'Campo obrigatório!',
                    'CPF inválido!',
                    'CNPJ inválido!'
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  autoComplete="apelido"
                  name="apelido"
                  variant="outlined"
                  fullWidth
                  value={formState.apelido}
                  onChange={handleFieldChange}
                  id="apelido"
                  label="Apelido"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  variant="outlined"
                  id="username"
                  name="username"
                  label="Nome de usuário *"
                  value={formState.username}
                  onChange={handleFieldChange}
                  fullWidth
                  validators={['required']}
                  errorMessages={['Campo obrigatório!']}
                  InputProps={{
                    // endAdornment: (
                    //   <InputAdornment position="end">
                    //     <Tooltip title="Gerar apelido">
                    //       <IconButton
                    //         aria-label="gerar apelido automaticamente"
                    //         onClick={() => generateUsername()}
                    //       >
                    //         <YoutubeSearchedForRoundedIcon />
                    //       </IconButton>
                    //     </Tooltip>
                    //   </InputAdornment>
                    // ),
                    startAdornment: <Typography>@</Typography>
                  }}
                  // onFocus={() =>
                  //   (formState.username === '' ||
                  //     formState.username === null) &&
                  //   generateUsername()
                  // }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <KeyboardDatePicker
                  autoOk
                  fullWidth
                  disableFuture
                  inputVariant="outlined"
                  invalidDateMessage="Data inválida"
                  invalidLabel="dd/mm/aaaa"
                  maxDateMessage="A data não deve ser posterior à data máxima"
                  minDateMessage="A data não deve ser anterior à data mínima"
                  cancelLabel="Cancelar"
                  okLabel="Definir"
                  clearable
                  clearLabel="Limpar"
                  placeholder="dd/mm/aaaa"
                  todayLabel="Hoje"
                  label="Data de Nascimento"
                  format="dd/MM/yyyy"
                  openTo="year"
                  KeyboardButtonProps={{ size: 'small' }}
                  views={['year', 'month', 'date']}
                  inputValue={formState.dataNascimento?.toLocaleString()}
                  value={formState.dataNascimento}
                  onChange={(_, newValue) => handleFieldChange}
                  InputAdornmentProps={{ position: 'start' }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextValidator
                  variant="outlined"
                  fullWidth
                  id="telefone"
                  label="Telefone *"
                  name="telefone"
                  value={phoneMask(formState.telefone)}
                  onChange={handleFieldChange}
                  autoComplete="telefone"
                  validators={['required', 'isValidPhone']}
                  errorMessages={['Campo obrigatório!', 'Telefone inválido!']}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectValidator
                  variant="outlined"
                  id="Sexo"
                  label="Sexo"
                  name="sexo"
                  value={formState.genero}
                  onChange={handleFieldChange}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                  <MenuItem disabled value="">
                    <em>Sexo</em>
                  </MenuItem>
                  <MenuItem value="M">Masculino</MenuItem>
                  <MenuItem value="F">Feminino</MenuItem>
                </SelectValidator>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextValidator
                  variant="outlined"
                  fullWidth
                  id="cep"
                  label="CEP *"
                  name="cep"
                  type="text"
                  inputProps={{
                    maxlength: '9',
                    minlength: '9'
                  }}
                  value={cepMask(formState.cep)}
                  onChange={handleFieldChange}
                  autoComplete="cep"
                  validators={['required', 'isValidCep']}
                  errorMessages={['Campo obrigatório!', 'O CEP não é válido']}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectValidator
                  validators={['required']}
                  errorMessages={['Campo obrigatório!']}
                  variant="outlined"
                  id="estadoId"
                  label="Estado *"
                  name="estadoId"
                  value={formState.cidade?.estadoId || 0}
                  onChange={handleFieldChange}
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  displayEmpty
                  labelId="estadoId"
                >
                  <MenuItem disabled value="">
                    <em>Estado *</em>
                  </MenuItem>
                  {/* {undefined !== estados && estados.length
                    ? estados.map((row) => (
                        <MenuItem key={row.id} value={row.id}>
                          {row.nome}
                        </MenuItem>
                      ))
                    : null} */}
                </SelectValidator>
              </Grid>
              <Grid item xs={12} sm={5}>
                <SelectValidator
                  validators={['required']}
                  errorMessages={['Campo obrigatório!']}
                  variant="outlined"
                  id="cidadeId"
                  name="cidadeId"
                  label="Cidade *"
                  value={formState.cidadeId}
                  onChange={handleFieldChange}
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  displayEmpty
                  labelId="cidadeId"
                >
                  <MenuItem disabled value="">
                    <em>Cidade *</em>
                  </MenuItem>
                  {/* {cidades.map((row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.nome}
                    </MenuItem>
                  ))} */}
                </SelectValidator>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextValidator
                  variant="outlined"
                  fullWidth
                  id="endereco"
                  label="Endereço *"
                  name="endereco"
                  value={formState.endereco}
                  onChange={handleFieldChange}
                  autoComplete="endereco"
                  validators={['required']}
                  errorMessages={['Campo obrigatório!']}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextValidator
                  variant="outlined"
                  fullWidth
                  id="numero"
                  label="Numero *"
                  name="numero"
                  inputProps={{
                    maxlength: '10',
                    minlength: '1'
                  }}
                  value={formState.numero}
                  onChange={handleFieldChange}
                  autoComplete="numero"
                  validators={['required']}
                  errorMessages={['Campo obrigatório!']}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  variant="outlined"
                  fullWidth
                  id="bairro"
                  label="Bairro *"
                  name="bairro"
                  value={formState.bairro}
                  onChange={handleFieldChange}
                  autoComplete="bairro"
                  validators={['required']}
                  errorMessages={['Campo obrigatório!']}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  variant="outlined"
                  fullWidth
                  id="complemento"
                  label="Complemento"
                  name="complemento"
                  value={formState.complemento}
                  onChange={handleFieldChange}
                  autoComplete="complemento"
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button onClick={onClose} variant="outlined" color="primary">
              Cancelar
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Salvar
            </Button>
          </CardActions>
        </ValidatorForm>
      </Card>
    </Modal>
  )
}

UserEdit.displayName = 'UserEdit'

export default UserEdit
