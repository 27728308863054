import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ImgDialog from './ImgDialog'
import { getCroppedImg } from './canvasUtils'
import { useStyles } from './styles'
import ReactImageUploading from 'react-images-uploading'
import { errorsUploadImage } from 'utils'
import {
  CardContent,
  Card,
  Tooltip,
  Divider,
  Grid,
  useTheme,
  CircularProgress
} from '@material-ui/core'
import CloudUploadTwoToneIcon from '@material-ui/icons/CloudUploadTwoTone'
import InsertPhotoTwoTone from '@material-ui/icons/InsertPhotoTwoTone'
import { uploadImage } from '_services'
import { useDispatch, useSelector } from 'react-redux'
import { eventoAction } from '_actions'
import { useConfirm } from 'material-ui-confirm'

const ImageCrop = () => {
  const confirm = useConfirm()
  const theme = useTheme()
  const [imageSrc, setImageSrc] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [images, setImages] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [croppedImage, setCroppedImage] = useState(null)
  const classes = useStyles()
  const evento = useSelector((state) => state.evento)
  const dispatch = useDispatch()

  const removeImage = () => {
    confirm({
      title: 'Deseja remover a imagem?',
      description: '',
      confirmationText: 'Sim, quero remover!',
      cancellationText: 'Nãããoo!'
    }).then(() => {
      dispatch(eventoAction.onChangeProps('imagem', ''))
    })
  }

  const onChange = (imageList) => {
    // data for submit
    setImages(imageList)
    console.log(imageList[0])
    setImageSrc(imageList[0].data_url)
  }
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels)
      setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [imageSrc, croppedAreaPixels])

  const uploadImageClick = async () => {
    try {
      setIsUploading(true)
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels)
      let file = await fetch(croppedImage)
        .then((r) => r.blob())
        .then(
          (blobFile) =>
            new File([blobFile], 'fileImage', {
              type: 'image/png',
              lastModified: new Date(),
              size: 2
            })
        )
      if (file) {
        uploadImage({ file: file, name: 'image.png' })
          .then((response) => {
            dispatch(eventoAction.onChangeProps('imagem', response))
            setImageSrc(null)
            setIsUploading(false)
          })
          .catch((err) => {
            console.log(err)
            setIsUploading(false)
          })
      }
    } catch (e) {
      console.error(e)
    }
  }
  const onClose = useCallback(() => {
    setCroppedImage(null)
  }, [])

  return (
    <div>
      {imageSrc ? (
        <React.Fragment>
          <div className={classes.cropContainer}>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={16 / 10}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className={classes.controls}>
            <Button
              onClick={() => setImageSrc(null)}
              variant="contained"
              color="primary"
              classes={{ root: classes.cancelButton }}
            >
              Cancelar
            </Button>
            <div className={classes.sliderContainer}>
              <Typography
                variant="overline"
                classes={{ root: classes.sliderLabel }}
              >
                Zoom
              </Typography>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                classes={{ root: classes.slider }}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
            <Button
              onClick={showCroppedImage}
              variant="contained"
              color="primary"
              classes={{ root: classes.cropButton }}
            >
              Resultado em tela cheia
            </Button>
            <Button
              disabled={isUploading}
              onClick={uploadImageClick}
              variant="contained"
              color="primary"
              classes={{ root: classes.cropButton }}
              endIcon={isUploading && <CircularProgress size={24} />}
            >
              {isUploading ? 'Enviando imagem...' : 'Salvar'}
            </Button>
          </div>
          <ImgDialog img={croppedImage} onClose={onClose} />
        </React.Fragment>
      ) : (
        <ReactImageUploading
          maxFileSize={2000000}
          onError={(errors) => errorsUploadImage(errors)}
          value={images}
          onChange={onChange}
          dataURLKey="data_url"
        >
          {({ onImageUpload, isDragging, dragProps }) => (
            <Grid
              container
              spacing={4}
              alignContent="center"
              alignItems="center"
            >
              <Grid item>
                <Card
                  {...dragProps}
                  style={{
                    height: 200,
                    width: 320,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    backgroundImage: `url("${evento.imagem}")`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    cursor: evento.imagem ? 'default' : 'pointer',
                    border: evento.imagem ? 'none' : '2px dashed #ccc'
                  }}
                  onClick={
                    (evento.imagem === null || evento.imagem === '') &&
                    onImageUpload
                  }
                >
                  {(evento.imagem === null || evento.imagem === '') && (
                    <CardContent>
                      {isDragging ? (
                        <div
                          style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <CloudUploadTwoToneIcon
                            color="primary"
                            style={{
                              fontSize: 40,
                              marginBottom: theme.spacing(2)
                            }}
                          />
                          <Typography variant="h6" color="initial">
                            Solte a imagem aqui!
                          </Typography>
                        </div>
                      ) : (
                        <div
                          style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <InsertPhotoTwoTone
                            color="primary"
                            style={{
                              fontSize: 40,
                              marginBottom: theme.spacing(2)
                            }}
                          />
                          <Typography variant="h6" color="initial">
                            Clique ou arraste a imagem aqui!
                          </Typography>
                        </div>
                      )}
                    </CardContent>
                  )}
                </Card>
              </Grid>

              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                  alignContent="stretch"
                  wrap="nowrap"
                >
                  <Grid item xs={6}>
                    {evento.imagem !== null && evento.imagem !== '' && (
                      <Button
                        variant="contained"
                        onClick={onImageUpload}
                        color="primary"
                      >
                        Trocar imagem
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    {evento.imagem !== null && evento.imagem !== '' && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={removeImage}
                        style={{ marginBottom: theme.spacing(2) }}
                      >
                        Remover imagem
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Typography>
                  A dimensão recomendada é de <strong>1280x800</strong> pixels.
                </Typography>
                <Typography>
                  Formato <strong>JPEG, GIF ou PNG de no máximo 2MB.</strong>
                </Typography>
                <Typography>
                  Você poderá editar a imagem antes de envia-lá.
                </Typography>
              </Grid>
            </Grid>
          )}
        </ReactImageUploading>
      )}
    </div>
  )
}

export default ImageCrop
