import React from 'react'
import { Button, Grid, Typography, makeStyles } from '@material-ui/core'
import Image from 'material-ui-image'

//? Own imports
import { history } from 'utils'

const useStyles = makeStyles((theme) => ({
  root: {},
  noResultText: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(1),
    textAlign: 'center'
  },
  image: { marginTop: theme.spacing(3) },
  button: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0 9px 40px -14px #FFC20A',
      webkitTransition: ' all 300ms ease',
      transition: 'all 300ms ease',
      letterSpacing: '1.2px'
    }
  }
}))
const EmptyFirstPhotoAlbum = () => {
  const classes = useStyles()

  const handleClickPhotoAlbum = () => {
    history.push(`/photoAlbum/cadastro`)
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
        direction="column"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
      >
        <Grid item>
          <Image
            className={classes.image}
            disableTransition
            style={{ width: '300px', height: '120px' }}
            color="transparent"
            src="/images/first-cupon.svg"
            aspectRatio={16 / 9}
          />
        </Grid>
        <Grid item>
          <Typography
            className={classes.noResultText}
            gutterBottom
            variant="h4"
            color="textPrimary"
          >
            Você ainda não criou nenhum álbum de fotos!
          </Typography>
        </Grid>
        <Grid item>
          <Button
            className={classes.button}
            variant="contained"
            onClick={handleClickPhotoAlbum}
            color="primary"
          >
            Criar álbum! 🎟
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default EmptyFirstPhotoAlbum
