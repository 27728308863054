import { useMutation } from 'react-query'
import { Member } from 'types/api'
import { crudService } from '_services'
import { toast } from 'react-toastify'

const putEditMember = async (payload: Member) => {
  let apiEndpoint = 'membros/' + payload.id
  const { data } = await crudService.put(apiEndpoint, payload)
  return data
}

export default function useMutationMemberEdit() {
  return useMutation(
    'member-edit',
    (payload: Member) => putEditMember(payload),
    {
      onError: (error) => {
        // @ts-ignore
        toast.error(error.message || 'Erro ao editar Membro')
      },
      onSuccess: () => {
        // @ts-ignore
        toast.success('Membro editado com sucesso!')
      }
    }
  )
}
