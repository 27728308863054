/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import {
  List,
  ListItem,
  Button,
  colors,
  Tooltip,
  Divider,
  Typography
} from '@material-ui/core'
import { AccessControl } from '../../../../../../components/Utils/AccessControl'
import { history } from 'utils'

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  tooltipText: {
    textAlign: 'center'
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  subtitle: { paddingLeft: theme.spacing(1) },
  expandIcon: {
    marginLeft: 'auto',
    height: 18,
    width: 18
  }
}))

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
))

const SidebarNav = (props) => {
  const { pages, className, ...rest } = props
  const classes = useStyles()

  const isAddPlace = useSelector((state) => {
    if (state.usuarioEstabelecimento.isAddPlace) {
      return true
    } else {
      return false
    }
  })

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map((page) => (
        <>
          {page.rule !== undefined ? (
            <AccessControl
              rule={page.rule}
              yes={() => (
                <>
                  {page.subtitle && (
                    <Typography
                      className={classes.subtitle}
                      variant="overline"
                      gutterBottom
                    >
                      {page?.subtitle}
                    </Typography>
                  )}
                  <Tooltip
                    title={
                      isAddPlace ? (
                        <span className={classes.tooltipText}>
                          <p>Função bloqueada 🤔 </p>
                          <p>Termine a adição de um evento ou cancele!</p>
                        </span>
                      ) : page.emailVerificado ? (
                        <span className={classes.tooltipText}>
                          <p>Função bloqueada 🤔 </p>
                          <p>Clique para completar seu cadastro!</p>
                        </span>
                      ) : (
                        <span className={classes.tooltipText}>
                          <p>Função bloqueada 🤔 </p>
                          <p>Confirme seu e-mail para continuar!</p>
                        </span>
                      )
                    }
                    arrow
                    onClick={() =>
                      page.emailVerificado &&
                      page.disabled &&
                      history.push(`/estabelecimento/`)
                    }
                    disableFocusListener={!page.disabled}
                    disableHoverListener={!page.disabled}
                    disableTouchListener={!page.disabled}
                  >
                    <ListItem
                      style={{
                        cursor: 'not-allowed',
                        marginTop: page.subtitle ? 15 : 0
                      }}
                      disabled={
                        (page.disabled ||
                          page.emailVerificado === false ||
                          page.emailVerificado === null) &&
                        true
                      }
                      className={classes.item}
                      disableGutters
                      key={page.title}
                    >
                      <Button
                        disabled={
                          (page.disabled ||
                            page.emailVerificado === false ||
                            page.emailVerificado === null) &&
                          true
                        }
                        activeClassName={classes.active}
                        className={classes.button}
                        component={CustomRouterLink}
                        to={page.href}
                        onClick={page.onClick}
                      >
                        <div className={classes.icon}>{page.icon}</div>
                        {page.title}
                        {page?.children && (
                          <>
                            {/* {open ? (
                            <ExpandLessIcon
                              className={classes.expandIcon}
                              color="inherit"
                            />
                            ) : (
                            <ExpandMoreIcon
                            className={classes.expandIcon}
                            color="inherit"
                          />
                            )} */}
                          </>
                        )}
                      </Button>
                      {/* <Collapse in={open}>{children}</Collapse> */}
                    </ListItem>
                  </Tooltip>
                  {page.divider && <Divider className={classes.divider} />}
                </>
              )}
            />
          ) : (
            <>
              {page.subtitle && (
                <Typography
                  className={classes.subtitle}
                  variant="overline"
                  gutterBottom
                >
                  {page?.subtitle}
                </Typography>
              )}
              <Tooltip
                title={
                  isAddPlace ? (
                    <span className={classes.tooltipText}>
                      <p>Função bloqueada 🤔 </p>
                      <p>Termine a adição de um evento ou cancele!</p>
                    </span>
                  ) : page.emailVerificado ? (
                    <span className={classes.tooltipText}>
                      <p>Função bloqueada 🤔 </p>
                      <p>Clique para completar seu cadastro!</p>
                    </span>
                  ) : (
                    <span className={classes.tooltipText}>
                      <p>Função bloqueada 🤔 </p>
                      <p>Confirme seu e-mail para continuar!</p>
                    </span>
                  )
                }
                arrow
                onClick={() =>
                  page.emailVerificado &&
                  page.disabled &&
                  history.push(`/estabelecimento/`)
                }
                disableFocusListener={!page.disabled}
                disableHoverListener={!page.disabled}
                disableTouchListener={!page.disabled}
              >
                <ListItem
                  style={{
                    cursor: 'not-allowed',
                    marginTop: page.subtitle ? 15 : 0
                  }}
                  disabled={
                    (page.disabled ||
                      page.emailVerificado === false ||
                      page.emailVerificado === null) &&
                    true
                  }
                  className={classes.item}
                  disableGutters
                  key={page.title}
                >
                  <Button
                    disabled={
                      (page.disabled ||
                        page.emailVerificado === false ||
                        page.emailVerificado === null) &&
                      true
                    }
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to={page.href}
                    onClick={page.onClick}
                  >
                    <div className={classes.icon}>{page.icon}</div>
                    {page.title}
                  </Button>
                </ListItem>
              </Tooltip>
              {page.divider && <Divider className={classes.divider} />}
            </>
          )}
        </>
      ))}
    </List>
  )
}

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
}

export default SidebarNav
