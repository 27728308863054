import { crudService } from '../_services/'
import { toast } from 'react-toastify'

export const comentarioAction = {
  changeAnswer,
  sendAnswer,
  getComentarioByAvaliacao,
  deleteComment,
  likeComment
}

function changeAnswer(prop, event) {
  let answer = ''
  if (prop === null && event === null) {
    answer = ''
  } else {
    answer = event.target.value
  }
  return {
    type: 'COMENTARIO_CHANGE_ANSWER',
    mensagem: answer
  }
}

function sendAnswer(payload) {
  return (dispatch) => {
    let apiEndpoint = 'comentarios/'
    crudService.post(apiEndpoint, payload).then(() => {
      dispatch(answerComentarioSuccess())
      dispatch(answerAvaliacaoSuccess())
      toast.success('Resposta enviada! 😁')
    })
  }
}

export function answerComentarioSuccess() {
  return {
    type: 'COMENTARIO_ANSWER_SUCCESS'
  }
}

export function answerAvaliacaoSuccess() {
  return {
    type: 'AVALIACAO_ANSWER_SUCCESS'
  }
}

export function getComentarioByAvaliacao(avaliacaoId) {
  return (dispatch) => {
    let apiEndpoint = `comentarios/avaliacao/${avaliacaoId}`
    dispatch(request())
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request() {
    return { type: 'REQUEST_ALL_COMENTARIOS_REQUEST' }
  }

  function success(comentarios) {
    return {
      type: 'REQUEST_ALL_COMENTARIOS_SUCCESS',
      comentarios: comentarios
    }
  }

  function failure(error) {
    return { type: 'REQUEST_ALL_COMENTARIOS_FAILURE', error }
  }
}

function deleteComment(commentId, reload) {
  return (dispatch) => {
    let apiEndpoint = `comentarios/${commentId}`
    dispatch(request())
    crudService
      .deleteDetail(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request() {
    return { type: 'DELETE_COMENTARIO_REQUEST' }
  }

  function success() {
    return {
      type: 'DELETE_COMENTARIO_SUCCESS',
      reload: reload
    }
  }

  function failure(error) {
    return { type: 'DELETE_COMENTARIO_FAILURE', error }
  }
}

function likeComment(commentId) {
  return (dispatch) => {
    let apiEndpoint = `interacoes/curtida/comentario/${commentId}`
    dispatch(request())
    crudService
      .post(apiEndpoint)
      .then(() => {
        dispatch(success())
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request() {
    return { type: 'LIKE_COMENTARIO_REQUEST', commentId: commentId }
  }

  function success() {
    return {
      type: 'LIKE_COMENTARIO_SUCCESS'
    }
  }

  function failure(error) {
    return { type: 'LIKE_COMENTARIO_FAILURE', error }
  }
}
