import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  Grid
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { connect } from 'react-redux'
import { categoriaAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import { Page } from 'components'
import constants from 'theme/constants'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  paper: {
    ...constants.shadowCard
  }
})

class CategoriaEdit extends Component {
  handleChange = (prop) => (event) => {
    const { dispatch } = this.props
    dispatch(categoriaAction.onChangeProps(prop, event))
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props
    const { dispatch } = this.props
    if (params.id) {
      dispatch(categoriaAction.getCategoriaById(params.id))
    } else {
      dispatch(categoriaAction.clear())
    }
  }

  handleSubmit(event) {
    const {
      match: { params }
    } = this.props
    const { dispatch } = this.props

    let payload = {
      id: params.id,
      descricao: this.props.categoria.descricao
    }

    if (params.id) {
      dispatch(categoriaAction.editCategoriaInfo(params.id, payload))
    } else {
      dispatch(categoriaAction.createCategoria(payload))
    }
  }

  render() {
    const { classes } = this.props
    const {
      match: { params }
    } = this.props

    function InsertText() {
      return (
        <Typography variant="h4">
          <b>{'Nova Categoria'}</b>
        </Typography>
      )
    }

    function EditText() {
      return (
        <Typography variant="h4">
          <b>{'Editar Categoria'}</b>
        </Typography>
      )
    }
    function SegHeader() {
      if (params.id) {
        return <EditText />
      }
      return <InsertText />
    }
    return (
      <Page
        className={classes.root}
        title={
          params.id
            ? 'Gerência de Categorias - Editar Categoria'
            : 'Gerência de Categorias - Nova Categoria'
        }
      >
        <Grid item md={12} xs={12}>
          <Card className={classes.paper}>
            <form>
              <ValidatorForm
                className={classes.form}
                ref="formCategoria"
                id="formCategoria"
                onSubmit={(event) => this.handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro Categoria"
                  title={<SegHeader />}
                />
                <Divider />
                <CardContent>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    id="descricao"
                    label="Descrição"
                    margin="normal"
                    onChange={this.handleChange('descricao')}
                    value={this.props.categoria.descricao}
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                  />
                </CardContent>
                <Divider />
                <CardActions>
                  <Button
                    color="secondary"
                    onClick={() => history.push('/categorias')}
                    variant="outlined"
                  >
                    Cancelar
                  </Button>
                  <Button color="primary" type="submit" variant="outlined">
                    {params.id ? 'Atualizar' : 'Salvar'}
                  </Button>
                </CardActions>
              </ValidatorForm>
            </form>
          </Card>
        </Grid>
      </Page>
    )
  }
}
CategoriaEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedCategoriaEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(CategoriaEdit))
)
export { connectedCategoriaEditPage as CategoriaEdit }
