import { useQuery } from 'react-query'

//? Own Imports
import { crudService } from '_services'

type UseOrdersParams = {
  term?: string
  statusPedidoId?: string
  formaPagamentoId?: string
  dataHoraInicio?: string
  dataHoraFim?: string
  eventoId?: string
}

const getOrders = async ({
  term,
  statusPedidoId,
  formaPagamentoId,
  dataHoraInicio,
  dataHoraFim,
  eventoId
}: UseOrdersParams) => {
  const { data } = await crudService.get(
    `pedidos/admin?StatusPedidoId=${statusPedidoId}&FormaPagamentoId=${formaPagamentoId}&Term=${term}&DataHoraInicio=${dataHoraInicio}&DataHoraFim=${dataHoraFim}&EventoId=${eventoId}&Limit=9999`
  )
  return data
}

export default function useOrders(params: UseOrdersParams) {
  return useQuery(
    [
      'orders',
      params.term,
      params.statusPedidoId,
      params.formaPagamentoId,
      params.dataHoraInicio,
      params.dataHoraFim,
      params.eventoId
    ],
    () => getOrders(params)
  )
}
