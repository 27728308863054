import React from 'react'
import {
  TableRow,
  TableCell,
  Grid,
  Tooltip,
  IconButton,
  colors,
  Avatar,
  makeStyles,
  Typography
} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

//? Own imports
import { EstabelecimentoListAdmin } from 'types/api'
import { cepMask, cpfCnpjMask, getInitials } from 'utils'
import { Label } from 'components'

const useStyles = makeStyles((theme) => ({
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  }
}))

const RowTableEstabelecimentos = ({
  estabelecimento
}: {
  estabelecimento: EstabelecimentoListAdmin
}) => {
  const classes = useStyles()

  return (
    <TableRow hover>
      <TableCell component="th" scope="estabelecimento">
        {estabelecimento.id}
      </TableCell>
      <TableCell align="left">
        {estabelecimento && estabelecimento.nome ? (
          <div className={classes.nameCell}>
            <Avatar className={classes.avatar} src={estabelecimento?.imagem}>
              {getInitials(estabelecimento?.nome)}
            </Avatar>
            <div>
              <Typography color="inherit" variant="h6">
                {estabelecimento?.nome}
              </Typography>
              <div>@{estabelecimento?.username}</div>
            </div>
          </div>
        ) : (
          'Estabelecimento não encontrado'
        )}
      </TableCell>
      <TableCell align="center">
        {cpfCnpjMask(estabelecimento.cpfCnpj) || 'Não informado'}
      </TableCell>
      <TableCell align="center">
        <div>
          {`${estabelecimento?.endereco}, ${estabelecimento?.numero} ${
            estabelecimento?.complemento
              ? ` - ${estabelecimento?.complemento}`
              : ''
          }${estabelecimento?.bairro ? ` - ${estabelecimento?.bairro}` : ''}`}
        </div>
        <div>{cepMask(estabelecimento?.cep)}</div>
        {estabelecimento?.cidade ? (
          <div>
            {`${estabelecimento?.cidade?.nome} -
                  ${estabelecimento?.cidade?.estado.uf.toUpperCase()}/${
              estabelecimento?.cidade?.estado?.pais?.nomePt
            }`}
          </div>
        ) : null}
      </TableCell>
      <TableCell align="center">
        <Label
          color={
            estabelecimento.repassarTaxa ? colors.green[600] : colors.red[600]
          }
          variant="contained"
        >
          {estabelecimento.repassarTaxa ? 'Sim' : 'Não'}
        </Label>
      </TableCell>
      <TableCell align="center">
        {estabelecimento?.taxa
          ? `${estabelecimento?.taxa}%`
          : 'Não identificado'}
      </TableCell>
      <TableCell align="center">
        {estabelecimento?.categoria?.descricao || 'Não identificado'}
      </TableCell>
      <TableCell align="right">
        <Grid wrap="nowrap" container justify="flex-end">
          <Grid>
            <Tooltip title="Detalhes">
              <IconButton
                disabled //? Desabilitado temporariamente
                size="small"
                aria-label="detalhes estabelecimento"
                style={{ marginLeft: 10 }}
              >
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
}

export default RowTableEstabelecimentos
