import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Page } from 'components'
import {
  MovimentacoesFinanceirasDetails,
  TableMovimentacoesFinanceiras
} from './components'
import useRouter from 'utils/useRouter'

const useStyles = makeStyles(() => ({
  root: {}
}))

const MovimentacoesFinanceiras = () => {
  const classes = useStyles()
  const { match } = useRouter()
  const { path } = match

  return (
    <Page className={classes.root} title="Lista - Movimentações financeiras">
      {path && path.includes('movimentacaoFinanceira') ? (
        <MovimentacoesFinanceirasDetails />
      ) : (
        <TableMovimentacoesFinanceiras />
      )}
    </Page>
  )
}

export default MovimentacoesFinanceiras
