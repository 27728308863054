import React from 'react'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  LinearProgress,
  makeStyles
} from '@material-ui/core'
import EventNoteTwoToneIcon from '@material-ui/icons/EventNoteTwoTone'
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import constants from 'theme/constants'
import { useEstablishmentDashboard } from 'hooks-querys'
var pluralize = require('pluralize')

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    ...constants.shadowCard
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 46,
    width: 46
  },
  icon: {
    height: 32,
    width: 32
  },
  progress: {
    marginTop: theme.spacing(3),
    height: 5,
    borderRadius: 5
  }
}))

const EventosFuturos = () => {
  const classes = useStyles()
  const { estabelecimentoId } = useSelector(
    // @ts-ignore
    (state) => state.usuarioEstabelecimento
  )
  const { data, isFetching } = useEstablishmentDashboard(
    Number(estabelecimentoId)
  )
  const { eventosFuturos, eventosPassados } = data || {}
  // @ts-ignore
  const { showValues } = useSelector((state) => state.global)

  const percentage = () => {
    if (eventosFuturos && eventosPassados) {
      var total = eventosFuturos + eventosPassados
      var currentValue = eventosFuturos
      var percentage = parseFloat(((currentValue / total) * 100).toFixed(1))

      if (isNaN(percentage)) {
        return ' '
      } else {
        return percentage.toLocaleString('pt-BR', {
          maximumFractionDigits: 2
        })
      }
    } else {
      return ' '
    }
  }
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              EVENTOS FUTUROS
            </Typography>
            <Typography variant="h3">
              {isFetching || !showValues ? (
                <Skeleton animation={isFetching ? 'pulse' : false} />
              ) : (
                pluralize('EVENTOS', eventosFuturos, true)
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <EventNoteTwoToneIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <LinearProgress
          className={classes.progress}
          value={Number(percentage().replace(',', '.'))}
          variant="determinate"
        />
        <Typography variant="body2">
          {isFetching || !showValues ? (
            <Skeleton animation={isFetching ? 'pulse' : false} />
          ) : (
            `${percentage()}% dos eventos`
          )}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default EventosFuturos
