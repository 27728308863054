import { useQuery } from 'react-query'
import { useMemo } from 'react'

//? Own imports
import { crudService } from '_services'
import { Pedido } from 'types/api'

const getOrder = async (orderId: number | undefined) => {
  const { data } = await crudService.get(`pedidos/admin/${orderId}`)
  return data
}

export default function useOrder(
  orderId: number | undefined,
  orderItem: Pedido | undefined
) {
  const initialData = useMemo(() => orderItem, [orderItem])
  return useQuery<Pedido>(['order', orderId], () => getOrder(orderId), {
    initialData,
    enabled: !!orderId
  })
}
