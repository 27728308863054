import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { history } from 'utils'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  Typography,
  Button,
  CircularProgress,
  Box,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { crudService } from '_services/'
import { useTimer } from 'use-timer'
import { toast } from 'react-toastify'

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" style={{ marginLeft: 10 }} display="inline-flex">
      <CircularProgress size={30} variant="static" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          style={{ fontSize: 8 }}
          color="textSecondary"
        >{`${props.value}s`}</Typography>
      </Box>
    </Box>
  )
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and static variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired
}

const useStyles = makeStyles(() => ({
  root: {}
}))

const Notifications = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const { time, start, isRunning } = useTimer({
    initialTime: 99,
    endTime: 0,
    timerType: 'DECREMENTAL'
  })

  const usuarioEstabelecimento = useSelector(
    (state) => state.usuarioEstabelecimento.usuarioEstabelecimento.length
  )

  const emailVerificado = useSelector(
    (state) => state.authentication.user.emailVerificado
  )

  const email = useSelector((state) => state.authentication.user.email)

  const requestEmailVerification = (email) => {
    let payload = {
      email: email
    }
    let apiEndpoint = 'usuarios/request-email-verification'
    crudService
      .post(apiEndpoint, payload)
      .then(() => {
        start()
        toast.success('E-mail enviado! 📩 Verifique a caixa de entrada!')
      })
      .catch(() => {
        toast.error('Aconteceu um erro inesperado ao enviar o e-mail! 😪')
      })
  }
  return (
    <>
      {!emailVerificado && (
        <Card {...rest} className={clsx(classes.root, className)}>
          <Alert
            action={
              !isMobile && (
                <>
                  {isRunning && (
                    <>
                      <Typography
                        style={{ paddingRight: 5 }}
                        variant="caption"
                        color="textSecondary"
                      >
                        Enviar novamente em
                      </Typography>
                      <CircularProgressWithLabel value={time} />
                    </>
                  )}
                  <Button
                    style={{ marginLeft: 25 }}
                    className={classes.buttons}
                    variant="outlined"
                    color="inherit"
                    disabled={isRunning}
                    size="small"
                    onClick={() => requestEmailVerification(email)}
                  >
                    Reenviar e-mail
                  </Button>
                </>
              )
            }
            severity="error"
          >
            <AlertTitle>
              <Typography
                align={isMobile ? 'justify' : 'left'}
                variant="body1"
                color="textPrimary"
              >
                {' '}
                Seu e-mail não foi verificado... 😪<br></br>Para usar a
                plataforma confirme seu endereço email!
              </Typography>
            </AlertTitle>
            <Typography
              align={isMobile ? 'justify' : 'left'}
              variant="caption"
              color="inherit"
            >
              Verifique a caixa de entrada do seu e-mail para continuar com a
              confirmação.
            </Typography>
            {isMobile && (
              <Button
                fullWidth
                style={{ marginTop: 10 }}
                variant="outlined"
                color="inherit"
                disabled={isRunning}
                size="small"
                onClick={() => requestEmailVerification(email)}
              >
                {isRunning ? 'Enviar novamente' : 'Reenviar e-mail'}
                {isRunning && <CircularProgressWithLabel value={time} />}
              </Button>
            )}
          </Alert>
        </Card>
      )}
      {(usuarioEstabelecimento === 0 || usuarioEstabelecimento === undefined) &&
        emailVerificado && (
          <Card {...rest} className={clsx(classes.root, className)}>
            <Alert
              action={
                !isMobile && (
                  <Button
                    className={classes.buttons}
                    variant="outlined"
                    onClick={() => history.push(`/estabelecimento/`)}
                    color="inherit"
                    size="small"
                    disabled={!emailVerificado}
                  >
                    CADASTRAR AGORA
                  </Button>
                )
              }
              severity="warning"
            >
              <AlertTitle>
                <Typography
                  align={isMobile ? 'justify' : 'left'}
                  variant="body1"
                  color="textPrimary"
                >
                  Notamos que você ainda não cadastrou nenhum organizador e/ou
                  estabelecimento.
                  <br></br>
                  Para liberar e aproveitar todas as funções clique no botão ao
                  lado para iniciar seu primeiro cadastro.
                </Typography>
              </AlertTitle>
              <Typography
                align={isMobile ? 'justify' : 'left'}
                variant="caption"
                color="inherit"
              >
                ATENÇÃO! Você precisa cadastrar ao menos um organizador e/ou
                estabelecimento para criar seu evento!
              </Typography>
              {isMobile && (
                <Button
                  style={{ marginTop: 10 }}
                  fullWidth
                  variant="outlined"
                  onClick={() => history.push(`/estabelecimento/`)}
                  color="inherit"
                  size="small"
                  disabled={!emailVerificado}
                >
                  CADASTRAR AGORA
                </Button>
              )}
            </Alert>
          </Card>
        )}
    </>
  )
}

Notifications.propTypes = {
  className: PropTypes.string
}

export default Notifications
