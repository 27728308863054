import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { hashtagAction } from '../../_actions'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import TitleContent from '../TitleContent'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { useDidMountEffect } from 'utils/useDidMountEffect'
///////////////////////////////////////////////////////////////////////////////
// material-ui imports
import {
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  Divider,
  Grid
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { useConfirm } from 'material-ui-confirm'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import CloseIcon from '@material-ui/icons/Close'
import constants from 'theme/constants'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  paper: {
    ...constants.shadowCard
  }
})

const Hashtag = (props) => {
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { hashtag } = props.hashtag
  const [text, setText] = useState('')
  const { categoria } = useSelector((state) => state.categoria)

  const handleChangeText = (event) => {
    setText(event.target.value)
  }

  function searchForHashtag() {
    text !== '' && dispatch(hashtagAction.getHashtags('', '', text, '', ''))
  }

  const handleSubmitSearch = (event) => {
    event.preventDefault()
    searchForHashtag()
  }

  const handleDeleteClick = (id) => {
    confirm({
      title: 'Você deseja excluir esta Hashtag?',
      description:
        'Confirmando essa operação, esta hashtag não estará mais disponível.',
      confirmationText: 'Sim, excluir!',
      cancellationText: 'Nãããoo!'
    }).then(() => {
      dispatch(hashtagAction.deleteHashtagById(id))
      searchForHashtag()
    })
  }

  const clearTextSearch = () => {
    setText('')
    dispatch(hashtagAction.getHashtags('', '', '', '', ''))
  }

  useDidMountEffect(() => {
    text === '' && searchForHashtag()
  }, [text])

  useEffect(() => {
    dispatch(hashtagAction.getHashtags('', '', '', '', ''))
  }, [dispatch])

  function getCategoriaName(categoriaId) {
    const categoriaItem = categoria.find((element) => element.id == categoriaId)
    if (categoriaItem !== (undefined && null)) {
      return categoriaItem.descricao
    }
  }

  return (
    <Page className={classes.root} title="Hashtags">
      <AccessControl
        rule={'hashtags'}
        yes={() => (
          <div>
            {undefined !== hashtag && hashtag.length > 0 ? (
              <TitleContent
                rule={'hashtags.create'}
                length={hashtag.length}
                subTitle={'GERÊNCIA DE HASHTAGS'}
                title={'Lista de Hashtags'}
                href={'/hashtag'}
              />
            ) : (
              <TitleContent
                rule={'hashtags.create'}
                length={0}
                subTitle={'GERÊNCIA DE HASHTAGS'}
                title={'Lista de Hashtags'}
                href={'/hashtag'}
              />
            )}
            <Grid item>
              <Grid item xs={12} sm={6}>
                <form autoComplete="off" onSubmit={handleSubmitSearch}>
                  <TextField
                    align="right"
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {text.length >= 1 && (
                            <>
                              <IconButton
                                onClick={clearTextSearch}
                                className={classes.iconButton}
                              >
                                <CloseIcon opacity="0.8" />
                              </IconButton>
                              <Divider
                                className={classes.divider}
                                orientation="vertical"
                              />
                            </>
                          )}
                          <IconButton
                            type="submit"
                            aria-label="procurar por hashtags"
                          >
                            <SearchRoundedIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    fullWidth
                    value={text}
                    onChange={handleChangeText}
                    variant="outlined"
                    id="textForSearch"
                    label="Procurar"
                    placeholder="Busque hashtags..."
                  />
                </form>
              </Grid>
            </Grid>
            <TableContainer component={Paper} className={classes.paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Nome</TableCell>
                    <TableCell align="center">Categoria</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== hashtag && hashtag.length
                    ? hashtag.map((n) => {
                        return (
                          <TableRow hover key={n.id}>
                            <TableCell align="left" component="th" scope="row">
                              {n.descricao}
                            </TableCell>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {getCategoriaName(n.categoriaId)}
                            </TableCell>
                            <TableCell align="right">
                              <AccessControl
                                rule={'hashtags.edit'}
                                yes={() => (
                                  <IconButton
                                    aria-label="Edit"
                                    component="a"
                                    onClick={() =>
                                      history.push(`/hashtag/${n.id}`)
                                    }
                                    size="small"
                                  >
                                    <Tooltip title="Editar">
                                      <Tooltip title="Editar">
                                        <EditIcon />
                                      </Tooltip>
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                              <AccessControl
                                rule={'hashtags.delete'}
                                yes={() => (
                                  <IconButton
                                    aria-label="Delete"
                                    onClick={() => handleDeleteClick(n.id)}
                                    size="small"
                                  >
                                    <Tooltip title="Excluir">
                                      <DeleteIcon />
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}

Hashtag.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    hashtag: state.hashtag
  }
}

const connectedHashtagPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Hashtag))
)

export { connectedHashtagPage as Hashtag }
