import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  colors,
  makeStyles,
  TableHead,
  Tooltip,
  Box,
  CircularProgress,
  IconButton,
  Grid
} from '@material-ui/core'
import { format } from 'date-fns'
import PrintRoundedIcon from '@material-ui/icons/PrintRounded'
import ReactToPrint from 'react-to-print'

//? Own Imports
import { Label } from 'components'
import constants from 'theme/constants'
import { useUserLogs } from 'hooks-querys'
import { LogDescritivo } from 'types/api'

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    ...constants.shadowCard
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  headCell: {
    padding: theme.spacing(1, 2)
  },
  action: {
    margin: 0
  }
}))

const Logs = ({ userId }: { userId: number }) => {
  const classes = useStyles()
  const refTable = React.useRef(null)
  const {
    data: userLogs,
    isLoading
  } = useUserLogs(userId)

  return (
    <div className={classes.root}>
      <Card ref={refTable} className={classes.card}>
        <CardHeader
          title="Logs do usuário"
          classes={{
            action: classes.action
          }}
          action={
            <ReactToPrint
              trigger={() => (
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  alignContent="center"
                  wrap="nowrap"
                >
                  <Grid item>
                    <Tooltip title="Imprimir">
                      <IconButton size="small" aria-label="gerar relatório">
                        <PrintRoundedIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
              content={() => refTable.current}
            />
          }
        />
        <Divider />
        <CardContent className={classes.content}>
          {isLoading ? (
            <Box marginY={4}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Box mb={2}>
                  <Typography variant="h6">Carregando dados...</Typography>
                </Box>
                <Box>
                  <CircularProgress />
                </Box>
              </Box>
            </Box>
          ) : (
            <PerfectScrollbar options={{ suppressScrollY: true }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headCell} align="left">
                      Tipo
                    </TableCell>
                    <TableCell className={classes.headCell} align="right">
                      Tabela
                    </TableCell>
                    <TableCell className={classes.headCell} align="right">
                      Campo
                    </TableCell>
                    <TableCell className={classes.headCell} align="right">
                      Chave
                    </TableCell>
                    <TableCell className={classes.headCell} align="right">
                      Valor
                    </TableCell>
                    <TableCell className={classes.headCell} align="right">
                      Data e hora
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userLogs.map((log: LogDescritivo) => (
                    <TableRow hover key={log.id}>
                      <TableCell align="left">
                        <Label
                          color={
                            log.tipo === 'I'
                              ? colors.green[600]
                            : log.tipo === 'A'
                              ? colors.orange[600]
                            : colors.red[600]
                          }
                        >
                          {log.tipo === 'I'
                              ? 'Inclusão'
                            : log.tipo === 'A'
                              ? 'Alteração'
                            : 'Exclusão'}
                        </Label>
                      </TableCell>
                      <TableCell align="right">{log.tabela}</TableCell>
                      <TableCell align="right">{log.campo}</TableCell>
                      <TableCell align="right">{log.chave}</TableCell>
                      <TableCell align="right">
                        <Tooltip title="Valor antigo">
                          <Typography
                            variant="inherit"
                            display="inline"
                            style={{
                              textDecoration: 'line-through'
                            }}
                          >{`${log.valorAntigo}`}</Typography>
                        </Tooltip>
                        <Tooltip title="Valor novo">
                          <Typography variant="inherit" display="inline">
                            {` → ${log.valorNovo}`}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right">
                        {format(
                          new Date(log.dataHora),
                          "dd/MM/yyyy 'às' HH:mm:ss"
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          )}
        </CardContent>
      </Card>
    </div>
  )
}

export default Logs
