import { crudService } from '../_services'

export const statusMovimentacaoFinanceiraAction = {
  getStatusMovimentacaoFinanceiraSelect
}

function getStatusMovimentacaoFinanceiraSelect() {
  return (dispatch) => {
    dispatch(request())
    let apiEndpoint = 'statusMovimentacoesFinanceiras/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request() {
    return {
      type: 'FETCHED_STATUS_MOVIMENTACAO_FINANCEIRA_SELECT_REQUEST'
    }
  }

  function success(statusMovimentacaoFinanceiraSelect) {
    return {
      type: 'FETCHED_STATUS_MOVIMENTACAO_FINANCEIRA_SELECT_SUCCESS',
      statusMovimentacaoFinanceiraSelect: statusMovimentacaoFinanceiraSelect
    }
  }

  function failure(error) {
    return {
      type: 'FETCHED_STATUS_MOVIMENTACAO_FINANCEIRA_SELECT_FAILURE',
      error
    }
  }
}
