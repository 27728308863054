import { toLocalDateFormat } from 'utils'
const initialState = {
  anchor: 'left',
  cupomDesconto: [],
  open: false,
  id: '',
  codigo: '',
  percentualDesconto: '',
  valorDesconto: '',
  dataInicioValidade: null,
  dataTerminoValidade: null,
  quantidadeMinima: 1,
  quantidade: null,
  estabelecimentoId: '',
  promoterId: '',
  eventoId: '',
  loteId: '',
  excluido: false,
  validOnAllEventos: false,
  isLoading: false,
  utilizacaoCuponsDesconto: []
}

export function cupomDesconto(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_CUPOMDESCONTO':
      return {
        ...state,
        cupomDesconto: action.cupomDesconto
      }
    case 'FETCHED_ALL_CUPOMDESCONTO_BY_EVENTO':
      return {
        ...state,
        cupomDesconto: action.cupomDesconto
      }
    case 'CUPOMDESCONTO_CLEAR':
      return initialState
    case 'CUPOMDESCONTO_DETAIL':
      return {
        ...state,
        id: action.id,
        codigo: action.codigo.toUpperCase(),
        percentualDesconto: action.percentualDesconto,
        valorDesconto: action.valorDesconto,
        dataInicioValidade: toLocalDateFormat(action.dataInicioValidade),
        dataTerminoValidade: toLocalDateFormat(action.dataTerminoValidade),
        estabelecimentoId: action.estabelecimentoId,
        quantidadeMinima: action.quantidadeMinima,
        quantidade: action.quantidade,
        promoterId: action.promoterId,
        eventoId: action.eventoId,
        loteId: action.loteId,
        excluido: action.excluido,
        validOnAllEventos:
          action.eventoId !== null && action.eventoId !== '' ? false : true
      }
    case 'CUPOMDESCONTO_UPDATED':
      return state
    case 'CUPOMDESCONTO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'REQUEST_UTILIZACAO_CUPOM_DESCONTO':
      return {
        ...state,
        isLoading: true
      }
    case 'REQUEST_UTILIZACAO_CUPOM_DESCONTO_SUCCESS':
      return {
        ...state,
        isLoading: false,
        utilizacaoCuponsDesconto: action.utilizacaoCuponsDesconto
      }
    case 'REQUEST_UTILIZACAO_CUPOM_DESCONTO_FAILURE':
      return {
        ...state,
        isLoading: false
      }
    case 'REQUEST_LISTA_CUPOM_DESCONTO':
      return {
        ...state,
        isLoading: true
      }
    case 'REQUEST_LISTA_CUPOM_DESCONTO_SUCCESS':
      return {
        ...state,
        isLoading: false,
        cupomDesconto: action.cupomDesconto
      }
    case 'REQUEST_LISTA_CUPOM_DESCONTO_FAILURE':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
