import React, { ChangeEvent } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import ListRoundedIcon from '@material-ui/icons/ListRounded'
import CardGiftcardTwoToneIcon from '@material-ui/icons/CardGiftcardTwoTone'
import CasinoTwoToneIcon from '@material-ui/icons/CasinoTwoTone'
import {
  Tabs,
  Tab,
  Divider,
  colors,
  makeStyles,
  useTheme,
  useMediaQuery
} from '@material-ui/core'

//? Own imports
import { Page, Header } from 'components'
import { history } from 'utils'
import { Draw, Generate, TicketsList } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}))

const tabs = [
  { value: 'lista', label: 'Lista', icon: <ListRoundedIcon /> },
  {
    value: 'gerar',
    label: 'Gerar cortesia',
    icon: <CardGiftcardTwoToneIcon />
  },
  {
    value: 'sortear',
    label: 'Sortear ingresso',
    icon: <CasinoTwoToneIcon />
  }
]

const Tickets = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const { tab } = useParams<{ tab: string }>()

  const handleTabsChange = (_event: ChangeEvent<{}>, value: string) => {
    history.push(`/ingressos/${value}`)
  }

  if (!tab) {
    return <Redirect to="/ingressos/lista" />
  }

  if (!tabs.find((t) => t.value === tab)) {
    return <Redirect to="/errors/error-404" />
  }

  const getTitle = () => {
    switch (tab) {
      case 'lista':
        return 'Lista de ingressos'
      case 'gerar':
        return 'Gerar cortesia'
      case 'sortear':
        return 'Sortear ingresso'
      default:
        return ''
    }
  }

  return (
    <div className={classes.root} title="Gerência de ingressos">
      <Header subtitle="Gerência de ingressos" title={getTitle()} />
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        variant={isMobile ? 'fullWidth' : 'standard'}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            icon={tab.icon}
          />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {tab === 'lista' && (
          <Page title="Gerência de ingressos - Listagem">
            <TicketsList />
          </Page>
        )}
        {tab === 'gerar' && (
          <Page title="Gerência de ingressos - Gerar cortesia">
            <Generate />
          </Page>
        )}
        {tab === 'sortear' && (
          <Page title="Gerência de ingressos - Sortear ingresso">
            <Draw />
          </Page>
        )}
      </div>
    </div>
  )
}

export default Tickets
