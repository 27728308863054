import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import {
  makeStyles,
  Tooltip,
  IconButton,
  Typography,
  CircularProgress,
  CircularProgressProps,
  Box
} from '@material-ui/core'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import ReplayRoundedIcon from '@material-ui/icons/ReplayRounded'
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
//? Own imports
import { ImagePreviewClientSide } from '../../PhotoList'
import { albumAction } from '_actions'
import config from 'config/config'
import PhotoEditDialog from 'views/PhotoAlbum/components/PhotoEditDialog'
import { useConfirm } from 'material-ui-confirm'
import el from 'date-fns/esm/locale/el/index.js'
import { useDidMountEffect } from 'utils'

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  const useStyles = makeStyles(() => ({
    textLoadingProgress: {
      color: '#fff',
      fontWeight: 'bold'
    }
  }))

  const classes = useStyles()
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textPrimary"
          className={classes.textLoadingProgress}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      '& $actionsContainer': {
        visibility: 'visible'
      }
    },
    borderRadius: 5,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    backgroundImage: ({ imageUrl }: { imageUrl: string | null | undefined }) =>
      imageUrl ? `url("${encodeURI(imageUrl)}")` : undefined,
    width: '100%',
    height: 110,
    position: 'relative'
  },
  actionsContainer: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    padding: theme.spacing(1),
    visibility: 'hidden',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)'
  },
  deleteButton: {},
  editButton: {},
  textName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    fontSize: 9,
    color: '#fff',
    margin: theme.spacing(0, 1)
  },
  loadingContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 110,
    justifyContent: 'center',
    borderRadius: 5,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  errorContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 110,
    justifyContent: 'center',
    borderRadius: 5,
    backgroundColor: 'rgba(255, 148, 148, 0.5)'
  }
}))

type Props = {
  imagePreview: ImagePreviewClientSide
}

const ImageItem = ({ imagePreview }: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: imagePreview.id })

  const classes = useStyles({ imageUrl: imagePreview.preview })
  const dispatch = useDispatch()
  const confirm = useConfirm()
  const [progress, setProgress] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const foto: ImagePreviewClientSide = useSelector((state) =>
    // @ts-ignore
    state.album.foto.find((item) => item.id === imagePreview.id)
  )
  const {
    fotoCapaId,
    enabledSortableContext
  }: { fotoCapaId: Number; enabledSortableContext: boolean } = useSelector(
    // @ts-ignore
    (state) => state.album
  )

  const handleClickDelete = () => {
    if (foto.file === null) {
      confirm({
        title: 'Você deseja mesmo excluir esta foto?',
        description:
          'Confirmando essa operação, esta foto não aparecerá mais no álbum de fotos',
        confirmationText: 'Sim, excluir!',
        cancellationText: 'Nãããoo!'
      }).then(() => {
        if (fotoCapaId == Number(foto.id)) {
          dispatch(albumAction.setFotoCapaId(null))
        }
        dispatch(albumAction.deleteFoto(foto))
      })
    } else {
      if (fotoCapaId == Number(foto.id)) {
        dispatch(albumAction.setFotoCapaId(null))
      }
      dispatch(albumAction.deleteFoto(foto))
    }
  }

  const handleClickEdit = () => {
    setOpenEditModal(true)
    // dispatch(albumAction.updateFoto(foto))
  }

  useEffect(() => {
    if (foto.id && !foto.uploaded && !isLoading) {
      processUpload()
    }
  }, [])

  useDidMountEffect(() => {
    if (foto.id && !foto.uploaded && !isLoading && foto.retry) {
      processUpload()
    }
  }, [foto.retry])

  const updateFile = (data: ImagePreviewClientSide) => {
    dispatch(
      albumAction.updateFoto({
        ...foto,
        ...data
      })
    )
  }

  const processUpload = () => {
    const data = new FormData()
    data.append('file', imagePreview.file, imagePreview.name)
    setIsLoading(true)
    updateFile({
      ...foto,
      isError: false
    })
    axios
      .create({
        baseURL: config.baseUrl
      })
      .post('uploads/S3', data, {
        onUploadProgress: (event) => {
          const progress = Math.round((event.loaded * 100) / event.total)
          setProgress(progress)
        },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      })
      .then((response) => {
        setIsLoading(false)
        updateFile({
          ...foto,
          uploaded: true,
          isError: false,
          url: response.data.url,
          retry: false
        })
      })
      .catch(() => {
        setIsLoading(false)
        setProgress(progress)
        updateFile({
          ...foto,
          isError: true,
          retry: false
        })
      })
  }

  return (
    <>
      {foto?.preview ? (
        <div
          ref={setNodeRef}
          className={classes.root}
          {...listeners}
          {...attributes}
          style={{
            transform: CSS.Transform.toString(transform),
            transition,
            zIndex: isDragging ? '100' : 'auto',
            opacity: isDragging ? 0.4 : 1,
            cursor: !enabledSortableContext
              ? 'default'
              : isDragging
              ? 'grabbing'
              : 'grab'
          }}
        >
          {foto.isError && (
            <div className={classes.errorContainer}>
              <Tooltip title="Reenviar imagem">
                <IconButton
                  aria-label="reenviar imagem"
                  color="primary"
                  onClick={processUpload}
                >
                  <ReplayRoundedIcon color="primary" />
                </IconButton>
              </Tooltip>
            </div>
          )}
          {isLoading && (
            <div className={classes.loadingContainer}>
              <CircularProgressWithLabel value={progress} />
            </div>
          )}
          <div className={classes.actionsContainer}>
            {foto.isError ? (
              <Tooltip title="Erro ao enviar imagem! Clique para tentar novamente">
                <IconButton
                  size="small"
                  aria-label="reenviar imagem"
                  color="primary"
                  onClick={processUpload}
                >
                  <ErrorOutlineRoundedIcon fontSize="small" color="primary" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Editar">
                <IconButton
                  disabled={!foto.uploaded}
                  size="small"
                  className={classes.editButton}
                  aria-label="Editar"
                  color="primary"
                  onClick={handleClickEdit}
                >
                  <EditRoundedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            <Typography
              align="center"
              className={classes.textName}
              variant="caption"
            >
              {foto.title || foto.name}
            </Typography>
            <Tooltip title="Excluir">
              <IconButton
                size="small"
                className={classes.deleteButton}
                aria-label="Excluir"
                color="primary"
                onClick={handleClickDelete}
              >
                <HighlightOffRoundedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <PhotoEditDialog
              openModal={openEditModal}
              setOpenModal={setOpenEditModal}
              photo={foto}
            />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default ImageItem
