import { useMutation } from 'react-query'
import { Member } from 'types/api'
import { crudService } from '_services'
import { toast } from 'react-toastify'

const postCreateMember = async (payload: Member) => {
  const { data } = await crudService.post('membros/admin', payload)
  return data
}

export default function useMutationMemberCreate() {
  return useMutation(
    'member-create',
    (payload: Member) => postCreateMember(payload),
    {
      onError: (error) => {
        // @ts-ignore
        toast.error(error.message || 'Erro ao criar membro!')
      },
      onSuccess: (payload) => {
        // @ts-ignore
        toast.success(`Membro ${payload.nome} adicionado ao plano com sucesso!`)
      }
    }
  )
}
