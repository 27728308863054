import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const check = (rule, permissaoTipoUsuario, permissaoUsuario) => {
  const itemPermissaoUsuario = permissaoUsuario.find(
    (item) => item.permissao.nome === rule
  )
  if (itemPermissaoUsuario) return itemPermissaoUsuario.permitido
  if (
    permissaoTipoUsuario.some(
      (item) => item.permissao.nome === rule && item.permitido
    )
  )
    return true
  return false
}

const AccessControl = (props) =>
  check(props.rule, props.permissaoTipoUsuario, props.permissaoUsuario)
    ? props.yes()
    : props.no()

AccessControl.defaultProps = {
  yes: () => null,
  no: () => null
}

AccessControl.propTypes = {
  rule: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
  return {
    permissaoTipoUsuario:
      state.authentication.user.tipoUsuario !== undefined &&
      state.authentication.user.tipoUsuario.permissaoTipoUsuario,
    permissaoUsuario:
      state.authentication.user.permissaoUsuario !== undefined &&
      state.authentication.user.permissaoUsuario
  }
}
const connectedAccessControlPage = connect(mapStateToProps)(AccessControl)
export { connectedAccessControlPage as AccessControl }
