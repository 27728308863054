import { useQuery } from 'react-query'

//? Own imports
import { crudService } from '_services'

const getUserReviews = async (userId: Number) => {
  const { data } = await crudService.get(`avaliacoes/usuario/${userId}`)
  return data
}

export default function useUserReviews(userId: Number) {
  return useQuery(['user-reviews', userId], () => getUserReviews(userId))
}
