import { toLocalDateFormat } from 'utils'

const initialState = {
  evento: [],
  activeStep: 0,
  id: '',
  nome: '',
  estabelecimentoId: 0,
  eventoId: 0,
  detalhes: '',
  dataHoraInicio: null,
  dataHoraFim: null,
  imagem: '',
  cidadeId: 0,
  cidade: null,
  categoriaId: '',
  endereco: '',
  prioridadeExibicao: null,
  slug: '',
  cep: '',
  bairro: '',
  latLon: '',
  excluido: false,
  tipoLocalizacao: '',
  categoria: null,
  disponivel: true,
  estadoId: '',
  nomeLocal: '',
  complemento: '',
  numero: 0,
  estabelecimento: null,
  lote: [],
  cupomDesconto: [],
  isLoading: false,
  eventoHashtag: []
}

export function evento(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_EVENTO':
      return {
        ...state,
        evento: action.evento
      }
    case 'FETCHED_ALL_LOTE_EVENTO':
      return {
        ...state,
        lote: action.lote
      }
    case 'DUPLICATE_EVENTO_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'NEXT_STEP_EVENTO_EDIT':
      return {
        ...state,
        activeStep: state.activeStep + 1
      }
    case 'PREV_STEP_EVENTO_EDIT':
      return {
        ...state,
        activeStep: state.activeStep - 1
      }
    case 'DUPLICATE_EVENTO_SUCCESS':
      return {
        ...state,
        id: 0,
        activeStep: 0,
        nome: action.event.evento.nome,
        slug: action.event.evento.slug,
        prioridadeExibicao: action.event.evento.prioridadeExibicao,
        estabelecimentoId: action.event.evento.estabelecimentoId,
        categoriaId: action.event.evento.categoriaId,
        detalhes: action.event.evento.detalhes,
        dataHoraInicio: toLocalDateFormat(action.event.evento.dataHoraInicio),
        dataHoraFim: toLocalDateFormat(action.event.evento.dataHoraFim),
        imagem: action.event.evento.imagem,
        cidadeId: action.event.evento.cidadeId,
        estadoId: action.event.estadoId,
        endereco: action.event.evento.endereco,
        latLon: action.event.evento.latLon,
        excluido: action.event.evento.excluido,
        categoria: action.event.evento.categoria,
        nomeLocal: action.event.evento.nomeLocal,
        complemento: action.event.evento.complemento,
        numero: action.event.evento.numero,
        tipoLocalizacao: action.event.evento.tipoLocalizacao,
        disponivel: action.event.evento.disponivel,
        cidade: action.event.evento.cidade,
        cep: action.event.evento.cep,
        bairro: action.event.evento.bairro,
        estabelecimento: action.event.evento.estabelecimento,
        lote: action.event.lote,
        cupomDesconto: action.event.cupomDesconto,
        eventoHashtag: action.event.eventoHashtag
      }
    case 'DUPLICATE_EVENTO_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case 'COPY_ESTABELECIMENTO_ADDRESS':
      return {
        ...state,
        nomeLocal: action.estabelecimento.nome,
        cidadeId: action.estabelecimento.cidadeId,
        estadoId: action.estabelecimento.cidade.estadoId,
        endereco: action.estabelecimento.endereco,
        cep: action.estabelecimento.cep,
        bairro: action.estabelecimento.bairro,
        complemento: action.estabelecimento.complemento,
        numero: action.estabelecimento.numero,
        latLon: action.estabelecimento.latLon,
        cidade: {
          ...state.cidade,
          ...action.estabelecimento.cidade
        }
      }
    case 'EVENTO_DETAIL':
      return {
        ...state,
        id: action.id,
        activeStep: 0,
        nome: action.nome,
        slug: action.slug,
        estabelecimentoId: action.estabelecimentoId,
        categoriaId: action.categoriaId,
        eventoId: action.eventoId,
        detalhes: action.detalhes,
        dataHoraInicio: toLocalDateFormat(action.dataHoraInicio),
        dataHoraFim: toLocalDateFormat(action.dataHoraFim),
        imagem: action.imagem,
        prioridadeExibicao: action.prioridadeExibicao,
        cidadeId: action.cidadeId,
        estadoId: action.estadoId,
        endereco: action.endereco,
        latLon: action.latLon,
        excluido: action.excluido,
        nomeLocal: action.nomeLocal,
        complemento: action.complemento,
        numero: action.numero,
        tipoLocalizacao: action.tipoLocalizacao,
        categoria: action.categoria,
        disponivel: action.disponivel,
        bairro: action.bairro,
        cidade: action.cidade,
        cep: action.cep,
        estabelecimento: action.estabelecimento,
        lote: action.lote,
        cupomDesconto: action.cupomDesconto,
        eventoHashtag: action.eventoHashtag
      }
    case 'EVENTO_CLEAR':
      return initialState
    case 'EVENTO_UPDATED':
      return state
    case 'ADD_LOTE':
      return {
        ...state,
        lote: [...state.lote, action.lote]
      }
    case 'EVENTO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'EVENTO_CREATED_SUCCESSFULLY':
      return {
        ...state,
        id: action.id
      }
    case 'CLEAR_ADDRESS':
      return {
        ...state,
        cidadeId: '',
        estadoId: '',
        endereco: '',
        cep: '',
        bairro: '',
        latLon: '',
        nomeLocal: '',
        complemento: '',
        numero: ''
      }
    case 'ADD_LOTE_LOCALE':
      return {
        ...state,
        lote: [...state.lote, action.lote]
      }
    case 'DELETE_LOTE_LOCALE':
      return {
        ...state,
        lote: state.lote.filter((item) => action.id !== item.id)
      }
    case 'EDIT_LOTE_LOCALE':
      return {
        ...state,
        lote: state.lote.map((lote) =>
          lote.id === action.id ? action.lote : lote
        )
      }
    case 'EVENTO_CHANGE_COORDINATES':
      return {
        ...state,
        latLon: action.latLon
      }
    case 'REQUEST_ALL_EVENTS_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'REQUEST_ALL_EVENTS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        evento: action.events
      }
    case 'REQUEST_ALL_EVENTS_FAILURE':
      return {
        ...state,
        isLoading: false
      }
    case 'EVENTO_HANDLE_ON_CHANGE_HASHTAG':
      return {
        ...state,
        eventoHashtag: action.hashtags
      }
    case 'SET_STEP_EVENTO_EDIT':
      return {
        ...state,
        activeStep: action.step
      }
    default:
      return state
  }
}
