import React, { useEffect } from 'react'
import {
  Box,
  Grid,
  LinearProgress,
  makeStyles,
  Typography
} from '@material-ui/core'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

//? Own imports
import { Header, Page } from 'components'
import { OrderInfo, OrderItems, OrderTickets } from './components'
import { Pedido } from 'types/api'
import { useOrder } from 'hooks-querys'
import { format } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  header: {
    marginBottom: theme.spacing(3)
  }
}))

const OrderManagementDetails = () => {
  const classes = useStyles()
  //const queryClient = useQueryClient()
  const { pedidoId } = useParams<{ pedidoId: string }>()

  //const order = queryClient.getQueryData(['orders']) as Pedido[]
  //const orderItem = order?.find((order) => order.id === Number(pedidoId))

  const { data, isFetching, refetch, isLoading, dataUpdatedAt } = useOrder(
    Number(pedidoId),
    undefined
  )

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])

  return (
    <>
      {isFetching && (
        <LinearProgress
          style={{
            width: '100%',
            position: 'absolute'
          }}
        />
      )}
      {data ? (
        // @ts-ignore
        <Page className={classes.root} title={`Pedido N.º #${data.id}`}>
          <Header
            className={classes.header}
            title={`Pedido N.º #${data.id}`}
            subtitle="Pedidos"
            isRefreshing={isFetching}
            onRefresh={refetch}
          />
          <Box mb={1}>
            {!isLoading ? (
              <Typography
                display="inline"
                variant="caption"
                color="textSecondary"
              >
                Última atualização:{' '}
                {format(dataUpdatedAt, "dd/MM/yyyy 'às' HH:mm:ss", {
                  locale: brLocale
                })}{' '}
                {isFetching ? '(Atualizando...)' : ''}
              </Typography>
            ) : null}
          </Box>
          <Grid container spacing={3}>
            <Grid item md={5} xl={4} xs={12}>
              <OrderInfo order={data} />
            </Grid>
            <Grid item md={7} xl={8} xs={12}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <OrderItems carrinhoItens={data.carrinhoItem} />
                </Grid>
                {data.ingresso && data.ingresso.length > 0 && (
                  <Grid item md={12} xs={12}>
                    <OrderTickets tickets={data.ingresso} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Page>
      ) : null}
    </>
  )
}

export default OrderManagementDetails
