import React, { useEffect } from 'react'
import { Box, Grid, makeStyles, Typography } from '@material-ui/core'

import {
  Budget,
  TotalEventos,
  EventosFuturos,
  IngressosRecebidos,
  VendasPorEvento,
  ReceitaUltimos6Meses,
  LatestOrders,
  Filters
} from './components'
import { Header, Page } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { globalActions } from '_actions'
import { useParams } from 'react-router-dom'
import { EventoDashboard } from 'views/Eventos/components/EventoDashboard/components'
import { useEstablishmentDashboard, useEventDashboard } from 'hooks-querys'
import { format } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'
import { history, useDidMountEffect } from 'utils'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 1, 1, 1)
    }
  },
  header: {
    marginBottom: theme.spacing(3)
  }
}))

const Dashboard = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { eventoId } = useParams<{ eventoId: string }>()
  const {
    data,
    isLoading: isLoadingEventDashboard,
    isFetching: isFetchingEventDashboard,
    isError: isErrorEventDashboard,
    refetch: refetchEventDashboard,
    dataUpdatedAt: dataUpdatedAtEventDashboard
  } = useEventDashboard(Number(eventoId))

  const { evento } = data || {}
  const { estabelecimentoId } = useSelector(
    // @ts-ignore
    (state) => state.usuarioEstabelecimento
  )

  const {
    isLoading: isLoadingEstablishmentDashboard,
    isFetching: isFetchingEstablishmentDashboard,
    isError: isErrorEstablishmentDashboard,
    refetch: refetchEstablishmentDashboard,
    dataUpdatedAt: dataUpdatedAtEstablishmentDashboard
  } = useEstablishmentDashboard(Number(estabelecimentoId))

  const isLoading = isLoadingEventDashboard || isLoadingEstablishmentDashboard
  const isFetching =
    isFetchingEventDashboard || isFetchingEstablishmentDashboard
  useEffect(() => {
    dispatch(globalActions.setIsVisibleButtonShowValues(true))
    return () => {
      dispatch(globalActions.setIsVisibleButtonShowValues(false))
    }
  }, [])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [eventoId])

  useDidMountEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    history.push('/dashboard')
  }, [estabelecimentoId])

  return (
    <Page
      // @ts-ignore
      className={classes.root}
      title={eventoId ? 'Dashboard por evento' : 'Dashboard'}
    >
      <Header
        className={classes.header}
        title={
          isLoading
            ? 'Carregando...'
            : evento?.nome || 'Dashboard do estabelecimento'
        }
        subtitle={eventoId ? 'Dashboard por evento' : 'Dashboard geral'}
        isRefreshing={isFetching}
        onRefresh={
          Number(eventoId) > 0
            ? refetchEventDashboard
            : refetchEstablishmentDashboard
        }
      />
      <Box mb={2}>
        <Filters />
      </Box>
      <Box mb={1}>
        {!isLoading ? (
          <Typography display="inline" variant="caption" color="textSecondary">
            Última atualização:{' '}
            {format(
              eventoId && Number(eventoId) > 0
                ? dataUpdatedAtEventDashboard
                : dataUpdatedAtEstablishmentDashboard,
              "dd/MM/yyyy 'às' HH:mm:ss",
              {
                locale: brLocale
              }
            )}{' '}
            {isFetching ? '(Atualizando...)' : ''}
          </Typography>
        ) : null}
      </Box>
      {eventoId && Number(eventoId) > 0 ? (
        <EventoDashboard />
      ) : (
        <Grid container spacing={2}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalEventos />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <EventosFuturos />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <IngressosRecebidos />
          </Grid>
          <Grid item lg={8} md={12} xl={9} xs={12}>
            <VendasPorEvento />
          </Grid>
          <Grid item lg={4} md={12} xl={3} xs={12}>
            <ReceitaUltimos6Meses />
          </Grid>
          {/* <Grid item lg={12} md={12} xl={12} xs={12}>
          <LatestOrders />
        </Grid> */}
        </Grid>
      )}
    </Page>
  )
}

export default Dashboard
