import React, { useEffect } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  MenuItem,
  Button,
  Tooltip,
  Grid,
  Checkbox,
  FormControlLabel,
  InputAdornment
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'
import { cupomDescontoAction, eventoAction, loteAction } from '_actions'
import { HelperToolTip, ValidatedDatePicker } from 'components/'
import { toast } from 'react-toastify'
import { toISODateFormat, validateDates } from 'utils'
import { history } from 'utils'
import { isValid } from 'date-fns'
import { Page } from 'components'
import { makeStyles } from '@material-ui/styles'
import { useParams } from 'react-router-dom'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(2)
  },
  paper: {
    ...constants.shadowCard
  }
}))

const CupomDescontoEdit = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { cupomDesconto, usuarioEstabelecimento } = useSelector(
    (state) => state
  )
  const { evento, lote } = useSelector((state) => state.evento)

  const params = useParams()
  const handleChange = (prop) => (event) => {
    dispatch(cupomDescontoAction.onChangeProps(prop, event))
  }

  const selectValidOnAllEventos = () => (event) => {
    dispatch(cupomDescontoAction.selectValidOnAllEventos(event))
  }

  const cancelClick = () => {
    dispatch(cupomDescontoAction.clear())
    history.goBack()
  }

  useEffect(() => {
    if (
      cupomDesconto.eventoId !== null &&
      cupomDesconto.eventoId !== undefined &&
      cupomDesconto.eventoId !== ''
    ) {
      dispatch(loteAction.getLotesByEvento(cupomDesconto.eventoId))
    }
  }, [cupomDesconto.eventoId])

  useEffect(() => {
    if (params.idCupomDesconto) {
      dispatch(cupomDescontoAction.getCupomDescontoById(params.idCupomDesconto))
    } else {
      dispatch(cupomDescontoAction.clear())
    }

    dispatch(
      eventoAction.getEventoByEstabelecimento(
        usuarioEstabelecimento.estabelecimentoId
      )
    )
    ValidatorForm.addValidationRule('isValidDate', (value) => {
      if (value !== null && value !== undefined) {
        return isValid(new Date(toISODateFormat(value)))
      } else {
        return true
      }
    })

    return () => {
      ValidatorForm.removeValidationRule('isValidDate')
    }
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()

    if (
      !validateDates(
        cupomDesconto.dataInicioValidade,
        cupomDesconto.dataTerminoValidade
      )
    ) {
      toast.error('Opss! 😥 Datas incorretas, revise por favor!')
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    } else if (
      (cupomDesconto.percentualDesconto === '' ||
        cupomDesconto.percentualDesconto === null) &&
      (cupomDesconto.valorDesconto === '' ||
        cupomDesconto.valorDesconto === null)
    ) {
      toast.error(
        'Opss! 😥 Você precisa inserir o percentual ou o valor do desconto!'
      )
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    } else {
      let payload = {
        id: params.idCupomDesconto,
        codigo: cupomDesconto.codigo,
        percentualDesconto: cupomDesconto.percentualDesconto,
        valorDesconto: cupomDesconto.valorDesconto,
        dataInicioValidade: toISODateFormat(cupomDesconto.dataInicioValidade),
        dataTerminoValidade: toISODateFormat(cupomDesconto.dataTerminoValidade),
        estabelecimentoId: usuarioEstabelecimento.estabelecimentoId,
        eventoId: cupomDesconto.eventoId,
        loteId: cupomDesconto.loteId,
        quantidadeMinima: cupomDesconto.quantidadeMinima,
        quantidade: cupomDesconto.quantidade
      }

      if (params.idCupomDesconto) {
        dispatch(
          cupomDescontoAction.editCupomDescontoInfo(
            params.idCupomDesconto,
            payload
          )
        )
        dispatch(cupomDescontoAction.clear())
      } else {
        dispatch(cupomDescontoAction.createCupomDesconto(payload))
        dispatch(cupomDescontoAction.clear())
      }
    }
  }

  const handleChangeData = (prop, newValue) => {
    dispatch(cupomDescontoAction.onChangeData(prop, newValue))
  }

  return (
    <Page
      className={classes.root}
      title={
        params.idCupomDesconto
          ? 'Gerência de Cupons de Desconto - Editar Cupom de Desconto'
          : 'Gerência de Cupons de Desconto - Novo Cupom de Desconto'
      }
    >
      <Grid item md={12} xs={12}>
        <Card className={classes.paper}>
          <form>
            <CardHeader
              subheader="Cadastro Cupom de Desconto"
              title={
                params.idCupomDesconto
                  ? 'Editar Cupom de Desconto'
                  : 'Novo Cupom de Desconto'
              }
            />
            <ValidatorForm
              id="formCupomDesconto"
              onSubmit={(event) => handleSubmit(event)}
            >
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={3} lg={3}>
                    <TextValidator
                      variant="outlined"
                      id="codigo"
                      label="Código *"
                      value={cupomDesconto.codigo}
                      onChange={handleChange('codigo')}
                      validators={['required']}
                      errorMessages={['Campo obrigatório!']}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3}>
                    <Tooltip
                      disableHoverListener={
                        cupomDesconto.valorDesconto !== '' &&
                        cupomDesconto.valorDesconto !== null
                          ? false
                          : true
                      }
                      disableFocusListener={
                        cupomDesconto.valorDesconto !== '' &&
                        cupomDesconto.valorDesconto !== null
                          ? false
                          : true
                      }
                      disableTouchListener={
                        cupomDesconto.valorDesconto !== '' &&
                        cupomDesconto.valorDesconto !== null
                          ? false
                          : true
                      }
                      title="Campo desabilitado porque Valor Desconto foi preenchido"
                    >
                      <TextValidator
                        variant="outlined"
                        id="percentual"
                        type="number"
                        label="Percentual Desconto"
                        value={cupomDesconto.percentualDesconto}
                        disabled={
                          cupomDesconto.valorDesconto !== '' &&
                          cupomDesconto.valorDesconto !== null
                            ? true
                            : false
                        }
                        onChange={handleChange('percentualDesconto')}
                        //errorMessages={['Campo obrigatório!']}
                        //validators={['required']}
                        fullWidth
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3}>
                    <Tooltip
                      disableHoverListener={
                        cupomDesconto.percentualDesconto !== '' &&
                        cupomDesconto.percentualDesconto !== null
                          ? false
                          : true
                      }
                      disableFocusListener={
                        cupomDesconto.percentualDesconto !== '' &&
                        cupomDesconto.percentualDesconto !== null
                          ? false
                          : true
                      }
                      disableTouchListener={
                        cupomDesconto.percentualDesconto !== '' &&
                        cupomDesconto.percentualDesconto !== null
                          ? false
                          : true
                      }
                      title="Campo desabilitado porque Percentual Desconto foi preenchido"
                    >
                      <TextValidator
                        variant="outlined"
                        id="valor"
                        label="Valor Desconto"
                        type="number"
                        disabled={
                          cupomDesconto.percentualDesconto !== '' &&
                          cupomDesconto.percentualDesconto !== null
                            ? true
                            : false
                        }
                        value={cupomDesconto.valorDesconto}
                        onChange={handleChange('valorDesconto')}
                        //errorMessages={['Campo obrigatório!']}
                        //validators={['required']}
                        fullWidth
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3}>
                    <TextValidator
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <HelperToolTip>
                              Quantidade mínima de ingressos para o cupom valer
                            </HelperToolTip>
                          </InputAdornment>
                        )
                      }}
                      variant="outlined"
                      id="quantidade_minima"
                      label="Quantidade Mínima *"
                      value={cupomDesconto.quantidadeMinima}
                      onChange={handleChange('quantidadeMinima')}
                      fullWidth
                      type="number"
                      inputProps={{
                        min: '1'
                      }}
                      validators={['required', 'minNumber:1']}
                      errorMessages={[
                        'Campo obrigatório!',
                        'O valor deve ser maior ou igual a 1!'
                      ]}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3}>
                    <TextValidator
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <HelperToolTip>
                              O Limite de Utilização é renovado a cada evento
                            </HelperToolTip>
                          </InputAdornment>
                        )
                      }}
                      variant="outlined"
                      id="quantidade"
                      label="Limite de utilização"
                      value={cupomDesconto.quantidade}
                      onChange={handleChange('quantidade')}
                      fullWidth
                      type="number"
                      inputProps={{
                        min: '1'
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <ValidatedDatePicker
                      validators={['isValidDate']}
                      errorMessages={['Data inválida!']}
                      autoOk
                      id="dataInicioValidade"
                      label="Data/Hora Início Validade"
                      fullWidth
                      animateYearScrolling
                      inputVariant="outlined"
                      ampm={false}
                      value={cupomDesconto.dataInicioValidade}
                      inputValue={cupomDesconto.dataInicioValidade}
                      onChange={(_, newValue) =>
                        handleChangeData('dataInicioValidade', newValue)
                      }
                      disablePast
                      format="dd/MM/yyyy HH:mm"
                      invalidDateMessage="Data inválida!"
                      invalidLabel="dd/MM/aaaa HH:mm"
                      maxDateMessage="A data não deve ser posterior à data máxima"
                      minDateMessage="A data não deve ser anterior à data de hoje"
                      cancelLabel="Cancelar"
                      okLabel="Definir"
                      clearable
                      clearLabel="Limpar"
                      placeholder="dd/MM/aaaa HH:mm"
                      todayLabel="Hoje"
                      defaultValue={undefined}
                      openTo="month"
                      views={['month', 'date', 'hours', 'minutes']}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <ValidatedDatePicker
                      validators={['isValidDate']}
                      errorMessages={['Data inválida!']}
                      autoOk
                      id="dataTerminoValidade"
                      label="Data/Hora Fim Validade"
                      animateYearScrolling
                      fullWidth
                      inputVariant="outlined"
                      ampm={false}
                      value={cupomDesconto.dataTerminoValidade}
                      inputValue={cupomDesconto.dataTerminoValidade}
                      onChange={(_, newValue) =>
                        handleChangeData('dataTerminoValidade', newValue)
                      }
                      disablePast
                      minDate={toISODateFormat(
                        cupomDesconto.dataTerminoValidade
                      )}
                      format="dd/MM/yyyy HH:mm"
                      invalidDateMessage="Data inválida!"
                      invalidLabel="dd/MM/aaaa HH:mm"
                      maxDateMessage="A data não deve ser posterior à data máxima"
                      minDateMessage="A data não deve ser anterior à data de início"
                      cancelLabel="Cancelar"
                      okLabel="Definir"
                      clearable
                      clearLabel="Limpar"
                      placeholder="dd/MM/aaaa HH:mm"
                      todayLabel="Hoje"
                      defaultValue={undefined}
                      openTo="month"
                      views={['month', 'date', 'hours', 'minutes']}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      label="Válido em todos eventos do estabelecimento"
                      control={
                        <Checkbox
                          checked={cupomDesconto.validOnAllEventos}
                          onChange={selectValidOnAllEventos()}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                  {!cupomDesconto.validOnAllEventos && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <SelectValidator
                          validators={
                            !cupomDesconto.validOnAllEventos && ['required']
                          }
                          errorMessages={
                            !cupomDesconto.validOnAllEventos && [
                              'Campo obrigatório!'
                            ]
                          }
                          variant="outlined"
                          id="EventoId"
                          label="Evento"
                          value={cupomDesconto.eventoId}
                          onChange={handleChange('eventoId')}
                          InputLabelProps={{
                            shrink: true
                          }}
                          fullWidth
                          displayEmpty
                          labelId="eventoId"
                          disabled={cupomDesconto.validOnAllEventos}
                        >
                          <MenuItem disabled value="">
                            <em>Evento</em>
                          </MenuItem>
                          {evento &&
                            evento.length > 0 &&
                            evento.map((row) => (
                              <MenuItem key={row.id} value={row.id}>
                                {row.nome}
                              </MenuItem>
                            ))}
                        </SelectValidator>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SelectValidator
                          variant="outlined"
                          id="loteId"
                          label="Lote"
                          disabled={cupomDesconto.eventoId > 0 ? false : true}
                          className={classes.textField}
                          value={cupomDesconto.loteId}
                          onChange={handleChange('loteId')}
                          InputLabelProps={{
                            shrink: true
                          }}
                          fullWidth
                          displayEmpty
                          labelId="loteId"
                        >
                          <MenuItem disabled value="">
                            <em>Lote</em>
                          </MenuItem>
                          <MenuItem value={null}>
                            <strong>Limpar seleção</strong>
                          </MenuItem>
                          {lote !== undefined &&
                            lote.map((row) => (
                              <MenuItem key={row.id} value={row.id}>
                                {row.tipoIngresso
                                  ? `${row.tipoIngresso.descricao} - ${row.descricao}`
                                  : row.descricao}
                              </MenuItem>
                            ))}
                        </SelectValidator>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  onClick={() => cancelClick()}
                  color="secondary"
                  variant="outlined"
                >
                  Cancelar
                </Button>
                <Button type="submit" color="primary" variant="outlined">
                  {params.idCupomDesconto ? 'Atualizar' : 'Salvar'}
                </Button>
              </CardActions>
            </ValidatorForm>
          </form>
        </Card>
      </Grid>
    </Page>
  )
}

export default CupomDescontoEdit
