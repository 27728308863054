import React, { useEffect, useState } from 'react'
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  TextField,
  InputAdornment,
  useTheme,
  useMediaQuery,
  Chip,
  CircularProgress,
  Avatar,
  Typography,
  Button,
  Box
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Autocomplete } from '@material-ui/lab'
import { useConfirm } from 'material-ui-confirm'

//? Own imports
import {
  useEvents,
  useLotesByEvent,
  useMutationCreateFreeTicket,
  useUserSearch
} from 'hooks-querys'
import {
  getGenderFull,
  getInitials,
  toLocalDateFormat,
  useDebouncedEffect
} from 'utils'
import { Evento, UserItem } from 'types/api'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { HelperToolTip } from 'components'
import { toast } from 'react-toastify'

var pluralize = require('pluralize')

const MAX_TICKETS_QUANTITY = 10
const MAX_USERS_QUANTITY = 10

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    width: '100%'
  },
  divider: {
    height: 28,
    margin: 4
  },
  selectedUserModalContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: theme.spacing(1),
    paddingTop: theme.spacing(2)
  },
  selectedUserItem: {
    borderRadius: 8,
    backgroundColor: '#f9f9f9',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },

  avatar: {
    height: 40,
    width: 40,
    marginRight: theme.spacing(1)
  },
  submitButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    minWidth: 200
  }
}))

const Generate = () => {
  const classes = useStyles()
  const theme = useTheme()
  const confirm = useConfirm()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [selectedEvento, setSelectedEvento] = useState<Evento>({} as Evento)
  const [selectedUser, setSelectedUser] = useState<UserItem[]>([] as UserItem[])
  const [quantity, setQuantity] = useState(1)
  const [loteId, setLoteId] = useState<number | null>(null)
  const [eventoInputValue, setEventoInputValue] = useState('')
  const [searchTextValue, setSearchTextValue] = useState('')
  const [searchTextValueToSubmit, setSearchTextValueToSubmit] = useState('')
  const estabelecimentoId = useSelector(
    // @ts-ignore: //ainda não foi tipado o redux state
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const totalTicketsToGenerate = selectedUser?.length * quantity

  const {
    data: events,
    isLoading: isLoadingEvents,
    isFetching: isFetchingEvents
  } = useEvents(estabelecimentoId, false, isMobile)

  const {
    data: lotes,
    isLoading: isLoadingLotes,
    isError: isErrorLotes
  } = useLotesByEvent(selectedEvento?.id)
  const {
    data: resultUserSearch,
    isError: isErrorUserSearch,
    isLoading: isLoadingUserSearch
  } = useUserSearch(searchTextValueToSubmit)

  const mutationCreateFreeTicket = useMutationCreateFreeTicket()

  useDebouncedEffect(
    () => {
      setSearchTextValueToSubmit(searchTextValue)
    },
    500,
    [searchTextValue]
  )

  useEffect(() => {
    setSelectedEvento({} as Evento)
    setLoteId(0)
    setSelectedUser([])
  }, [estabelecimentoId])

  const handleSubmitForm = () => {
    const loteDetails = lotes?.find((lote) => lote.id === loteId)
    confirm({
      title: `Você tem certeza que deseja gerar ${pluralize(
        'ingressos',
        totalTicketsToGenerate,
        true
      )} (${quantity} por usuário)?`,
      confirmationButtonProps: {
        disabled: mutationCreateFreeTicket.isLoading,
        endIcon: mutationCreateFreeTicket.isLoading && (
          <CircularProgress size={18} />
        )
      },
      description: (
        <div>
          <Typography gutterBottom variant="body1" color="textPrimary">
            Evento:{' '}
            <strong>
              {selectedEvento.nome} - (
              {toLocalDateFormat(selectedEvento.dataHoraInicio)})
            </strong>
            {'\n'}
          </Typography>
          <Typography gutterBottom variant="body1" color="textPrimary">
            Lote:{' '}
            <strong>
              {loteDetails
                ? loteDetails.tipoIngresso
                  ? `${loteDetails.tipoIngresso.descricao} - ${
                      loteDetails.descricao
                    } (${getGenderFull(loteDetails.genero)})`
                  : loteDetails.descricao
                : ''}
            </strong>
          </Typography>
          <Box mt={2}>
            <Typography variant="body1" color="textPrimary">
              {pluralize('Os', totalTicketsToGenerate)}{' '}
              {pluralize('ingressos', totalTicketsToGenerate)}{' '}
              {totalTicketsToGenerate === 1 ? 'será' : 'serão'}{' '}
              {pluralize('gerado', totalTicketsToGenerate)} para{' '}
              {pluralize('os', selectedUser?.length)}{' '}
              {pluralize('seguintes', selectedUser?.length)}{' '}
              {pluralize('usuários', selectedUser?.length)}:
            </Typography>
            <Typography variant="caption" color="textPrimary">
              {pluralize('Os', selectedUser?.length)}{' '}
              {pluralize('usuários', selectedUser?.length)}{' '}
              {selectedUser?.length === 1 ? 'receberá' : 'receberão'} uma
              notificação no aplicativo com {pluralize('os', quantity)}{' '}
              {pluralize('ingressos', quantity)}{' '}
              {pluralize('gerados', quantity)}.
            </Typography>
            <Box className={classes.selectedUserModalContainer}>
              {selectedUser?.map((item) => (
                <Box className={classes.selectedUserItem}>
                  <Avatar className={classes.avatar} src={item?.imagem}>
                    {getInitials(item?.nome)}
                  </Avatar>
                  <div>
                    <Typography variant="body1">
                      {`${item?.nome} ${
                        item?.username ? `(@${item?.username})` : ''
                      }`}
                    </Typography>
                  </div>
                </Box>
              ))}
            </Box>
          </Box>
        </div>
      ),
      confirmationText: (
        <>
          {mutationCreateFreeTicket.isLoading
            ? `Gerando ${pluralize('ingressos', totalTicketsToGenerate)}...`
            : `Enviar ${pluralize('cortesia', totalTicketsToGenerate)}`}
        </>
      ),
      cancellationText: 'Cancelar'
    }).then(() => {
      if (selectedUser?.length > 0 && loteId && loteId > 0) {
        const payloadArray = selectedUser?.map((user) => ({
          usuarioId: user?.id,
          loteId: loteId,
          quantidade: quantity
        }))

        mutationCreateFreeTicket.mutateAsync(payloadArray).then(() => {
          setSelectedUser([])
          setSearchTextValue('')
        })
      }
    })
  }

  const showMaxUserQuantityToastError = () => {
    toast.error(
      `Você atingiu o número máximo de usuários permitidos (${MAX_USERS_QUANTITY}).`,
      { toastId: 'maxUserQuantityToastError' }
    )
  }
  return (
    <div className={classes.root}>
      <ValidatorForm id="formGenerateFreeTicket" onSubmit={handleSubmitForm}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <Autocomplete
                loading={isLoadingEvents}
                id="combo-box-evento"
                options={events || []}
                value={selectedEvento}
                onChange={(event, newValue, reason) => {
                  if (reason === 'select-option') {
                    newValue !== null && setSelectedEvento(newValue)
                  }
                  if (reason === 'clear') {
                    //? se o usuário clicar no botão limpar
                    setEventoInputValue('')
                    setSelectedEvento({} as Evento)
                  }
                }}
                getOptionLabel={(evento) =>
                  evento.nome &&
                  `${evento.nome} ${
                    evento.dataHoraInicio
                      ? ` - (${toLocalDateFormat(evento.dataHoraInicio)})`
                      : ''
                  }`
                }
                placeholder="Selecione o evento..."
                onInputChange={(event, newInputValue) => {
                  newInputValue !== null
                    ? setEventoInputValue(newInputValue)
                    : setEventoInputValue('')
                }}
                inputValue={eventoInputValue}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione o evento"
                    placeholder="Selecione o evento..."
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          <InputAdornment position="end">
                            <>
                              {isFetchingEvents && (
                                <CircularProgress
                                  style={{ marginRight: 10 }}
                                  size={20}
                                  color="primary"
                                />
                              )}
                            </>
                          </InputAdornment>
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          {selectedEvento && selectedEvento.id > 0 && (
            <>
              <>
                <Grid item xs={12} sm={12} md={9}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="lote-label">Selecione o lote</InputLabel>
                    <Select
                      disabled={isLoadingLotes}
                      placeholder="Selecione o lote"
                      labelId="lote-label"
                      id="lote-select"
                      value={loteId}
                      onChange={(event) => {
                        setLoteId(Number(event.target.value))
                      }}
                      label="Selecione o lote"
                    >
                      <MenuItem disabled={loteId === 0} key={0} value={0}>
                        {loteId === 0 ? (
                          'Selecione o lote'
                        ) : (
                          <strong>Limpar seleção</strong>
                        )}
                      </MenuItem>
                      {lotes &&
                        lotes.length > 0 &&
                        lotes.map((lote) => (
                          <MenuItem
                            disabled={lote.id === loteId}
                            key={lote.id}
                            value={lote.id}
                          >
                            {lote.tipoIngresso
                              ? `${lote.tipoIngresso.descricao} - ${
                                  lote.descricao
                                } (${getGenderFull(lote.genero)})`
                              : lote.descricao}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <TextValidator
                    name="quantity"
                    id="quantity"
                    validators={[
                      'required',
                      'minNumber:1',
                      `maxNumber:${MAX_TICKETS_QUANTITY}`
                    ]}
                    errorMessages={[
                      'Campo obrigatório!',
                      'O valor deve ser maior ou igual a 1!',
                      `O valor deve ser menor ou igual a ${MAX_TICKETS_QUANTITY}!`
                    ]}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <HelperToolTip>
                            Informe a quantidade de ingressos a serem gerados
                            para cada usuário. O valor deve ser entre 1 e{' '}
                            {MAX_TICKETS_QUANTITY}.
                          </HelperToolTip>
                        </InputAdornment>
                      )
                    }}
                    label="Quantidade de ingressos *"
                    variant="outlined"
                    inputProps={{ type: 'number' }}
                    fullWidth
                    type="number"
                    placeholder={`Digite a quantidade de ingressos (máx: ${MAX_TICKETS_QUANTITY})`}
                    value={quantity}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setQuantity(Number(event?.target?.value))
                    }
                    disabled={mutationCreateFreeTicket?.isLoading}
                  />
                </Grid>
              </>
              {loteId && loteId > 0 ? (
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      getOptionDisabled={(option) =>
                        selectedUser.some((user) => user.id === option.id)
                      }
                      loading={isLoadingUserSearch}
                      id="combo-box-user"
                      multiple
                      options={
                        resultUserSearch?.filter(
                          (userItem) => userItem.tipo === 'usuario'
                        ) || []
                      }
                      value={selectedUser}
                      onFocus={() => {
                        if (selectedUser?.length === MAX_USERS_QUANTITY) {
                          showMaxUserQuantityToastError()
                        }
                      }}
                      onChange={(event, newValue, reason) => {
                        if (reason === 'select-option' && newValue !== null) {
                          if (newValue?.length > MAX_USERS_QUANTITY) {
                            showMaxUserQuantityToastError()
                            return
                          }
                          setSelectedUser(newValue) // Mantém todos os valores selecionados
                        }
                        if (reason === 'clear') {
                          // Se o usuário clicar no botão limpar
                          setSearchTextValue('')
                          setSelectedUser([]) // Limpa os usuários selecionados
                        }
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            avatar={
                              <Avatar src={option?.imagem}>
                                {getInitials(option?.nome)}
                              </Avatar>
                            }
                            variant="outlined"
                            label={`${option?.nome} ${
                              option.username ? `(@${option.username})` : ''
                            }`}
                            onDelete={() => {
                              setSelectedUser(
                                selectedUser?.filter(
                                  (user) => user?.id !== option?.id
                                )
                              )
                            }}
                          />
                        ))
                      }
                      getOptionLabel={(userItem) =>
                        userItem.nome &&
                        `${userItem.nome} ${
                          userItem.username ? ` - (${userItem.username})` : ''
                        }`
                      }
                      placeholder="Buscar por nome ou nome do usuário..."
                      onInputChange={(event, newInputValue) => {
                        newInputValue !== null
                          ? setSearchTextValue(newInputValue)
                          : setSearchTextValue('')
                      }}
                      inputValue={searchTextValue}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecione o(s) usuário(s)"
                          placeholder="Buscar por nome ou nome de usuário..."
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                <InputAdornment position="end">
                                  <>
                                    {isLoadingUserSearch && (
                                      <CircularProgress
                                        style={{ marginRight: 10 }}
                                        size={20}
                                        color="primary"
                                      />
                                    )}
                                  </>
                                </InputAdornment>
                                {params.InputProps.endAdornment}
                              </>
                            )
                          }}
                        />
                      )}
                      noOptionsText={
                        searchTextValueToSubmit.length > 0
                          ? 'Nenhum usuário encontrado'
                          : 'Digite para buscar'
                      }
                      renderOption={(userItem) => (
                        <div className={classes.nameCell}>
                          <Avatar
                            className={classes.avatar}
                            src={userItem?.imagem}
                          >
                            {getInitials(userItem?.nome)}
                          </Avatar>
                          <div>
                            <Typography variant="body1">
                              {`${userItem?.nome} ${
                                userItem.username
                                  ? `(@${userItem.username})`
                                  : ''
                              }`}
                            </Typography>
                          </div>
                        </div>
                      )}
                    />
                  </FormControl>
                </Grid>
              ) : null}
            </>
          )}
          {selectedUser?.length > 0 && loteId && loteId > 0 ? (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                disabled={mutationCreateFreeTicket.isLoading}
                type="submit"
                className={classes.submitButton}
                endIcon={
                  mutationCreateFreeTicket.isLoading && (
                    <CircularProgress size={18} />
                  )
                }
              >
                {mutationCreateFreeTicket.isLoading
                  ? `Gerando ${pluralize(
                      'ingressos',
                      totalTicketsToGenerate
                    )}...`
                  : `Gerar ${pluralize('ingressos', totalTicketsToGenerate)}`}
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </ValidatorForm>
    </div>
  )
}

export default Generate
