import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  Grid,
  InputAdornment
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { tipoIngressoAction } from '../../_actions'
import { history } from '../../utils'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Page } from 'components'
import constants from 'theme/constants'

const MAX_LENGTH = 50
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  paper: {
    ...constants.shadowCard
  }
}))

const TipoIngressoEdit = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { tipoIngresso, usuarioEstabelecimento } = useSelector((state) => state)
  const {
    match: { params }
  } = props

  useEffect(() => {
    if (params.id) {
      dispatch(tipoIngressoAction.getTipoIngressoById(params.id))
    }
  }, [])

  const handleChange = (prop) => (event) => {
    if (event?.target?.value?.length <= MAX_LENGTH) {
      dispatch(tipoIngressoAction.onChangeProps(prop, event))
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    let payload = {
      id: params.id,
      descricao: tipoIngresso.descricao,
      estabelecimentoId: usuarioEstabelecimento.estabelecimentoId
    }

    if (params.id) {
      dispatch(tipoIngressoAction.editTipoIngressoInfo(params.id, payload))
    } else {
      dispatch(tipoIngressoAction.createTipoIngresso(payload, true))
    }
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Tipos de Ingresso - Editar Tipos de Ingresso'
          : 'Gerência de Tipos de Ingresso - Novo Tipo de Ingresso'
      }
    >
      <Grid item md={12} xs={12}>
        <Card className={classes.paper}>
          <ValidatorForm className={classes.form} onSubmit={handleSubmit}>
            <CardHeader
              subheader="Cadastro Tipo de Ingresso"
              title={
                <Typography variant="h4">
                  <b>
                    {params.id
                      ? 'Editar Tipo de Ingresso'
                      : 'Novo Tipo de Ingresso'}
                  </b>
                </Typography>
              }
            />
            <Divider />
            <CardContent>
              <TextValidator
                autoFocus
                validators={['required']}
                errorMessages={['Campo obrigatório!']}
                variant="outlined"
                id="descricao"
                label="Descrição"
                inputProps={{
                  minlength: '3'
                }}
                placeholder="Pista, Camarote, VIP... Passaporte completo, etc."
                value={tipoIngresso.descricao}
                onChange={handleChange('descricao')}
                margin="normal"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <Typography
                          variant="body2"
                          align="right"
                          color={
                            tipoIngresso?.descricao?.length >= MAX_LENGTH
                              ? 'error'
                              : undefined
                          }
                        >
                          {tipoIngresso?.descricao?.length}/{MAX_LENGTH}
                        </Typography>
                      </InputAdornment>
                    </>
                  )
                }}
              />
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                onClick={() => history.push('/tipoIngressos')}
                color="secondary"
                variant="outlined"
              >
                Cancelar
              </Button>
              <Button color="primary" variant="outlined" type="submit">
                {params.id ? 'Atualizar' : 'Salvar'}
              </Button>
            </CardActions>
          </ValidatorForm>
        </Card>
      </Grid>
    </Page>
  )
}

export default TipoIngressoEdit
