import { crudService } from '../_services'
import { history } from '../utils'

export const contaBancariaAction = {
  getContaBancaria,
  getContaBancariaById,
  getContaBancariaByEstabelecimento,
  getContaBancariaByInstituicaoBancaria,
  onChangeProps,
  editContaBancariaInfo,
  createContaBancaria,
  deleteContaBancariaById,
  clear
}
function getContaBancaria() {
  return (dispatch) => {
    let apiEndpoint = 'contasBancarias'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeContasBancariasList(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getContaBancariaByEstabelecimento(estabelecimentoId) {
  return (dispatch) => {
    dispatch(request({ estabelecimentoId }))
    let apiEndpoint = 'contasBancarias/estabelecimento/' + estabelecimentoId
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request(estabelecimentoId) {
    return {
      type: 'FETCHED_CONTABANCARIA_BY_ESTABELECIMENTO_REQUEST',
      estabelecimentoId
    }
  }

  function success(contaBancaria) {
    return {
      type: 'FETCHED_CONTABANCARIA_BY_ESTABELECIMENTO_SUCCESS',
      contaBancaria: contaBancaria
    }
  }

  function failure(error) {
    return { type: 'FETCHED_CONTABANCARIA_BY_ESTABELECIMENTO_FAILURE', error }
  }
}

function getContaBancariaByInstituicaoBancaria(instituicaoBancariaId) {
  return (dispatch) => {
    let apiEndpoint =
      'contasBancarias/instituicaoBancaria/' + instituicaoBancariaId
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeContasBancariasList(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createContaBancaria(payload) {
  return (dispatch) => {
    let apiEndpoint = 'contasBancarias/'
    crudService.post(apiEndpoint, payload).then((response) => {
      dispatch(createUserInfo())
      history.push('/carteira/contasBancarias')
    })
  }
}

function getContaBancariaById(contaBancariaId) {
  return (dispatch) => {
    dispatch(request({ contaBancariaId }))
    let apiEndpoint = 'contasBancarias/' + contaBancariaId
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(editContasBancariasDetails(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request(contaBancariaId) {
    return {
      type: 'GET_CONTABANCARIA_BY_ID_REQUEST',
      contaBancariaId
    }
  }

  function success(contaBancaria) {
    return {
      type: 'GET_CONTABANCARIA_BY_ID_SUCCESS',
      contaBancaria: contaBancaria
    }
  }

  function failure(error) {
    return { type: 'GET_CONTABANCARIA_BY_ID_FAILURE', error }
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}
function editContaBancariaInfo(id, payload) {
  return (dispatch) => {
    let apiEndpoint = 'contasBancarias/' + id
    crudService.put(apiEndpoint, payload).then((response) => {
      dispatch(updatedUserInfo())
      history.push('/carteira/contasBancarias')
    })
  }
}
function deleteContaBancariaById(id) {
  return (dispatch) => {
    let apiEndpoint = 'contasBancarias/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteContasBancariasDetails())
      dispatch(
        contaBancariaAction.getContaBancariaByEstabelecimento(
          response.data.estabelecimentoId
        )
      )
    })
  }
}
export function changeContasBancariasList(contaBancaria) {
  return {
    type: 'FETCHED_ALL_CONTABANCARIA',
    contaBancaria: contaBancaria
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'CONTABANCARIA_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editContasBancariasDetails(contaBancaria) {
  return {
    type: 'CONTABANCARIA_DETAIL',
    id: contaBancaria._id,
    estabelecimentoId: contaBancaria.estabelecimentoId,
    instituicaoBancaria: contaBancaria.instituicaoBancaria,
    instituicaoBancariaId: contaBancaria.instituicaoBancariaId,
    descricao: contaBancaria.descricao,
    agencia: contaBancaria.agencia,
    conta: contaBancaria.conta,
    cpfCnpjTitular: contaBancaria.cpfCnpjTitular,
    nomeTitular: contaBancaria.nomeTitular,
    chavePix: contaBancaria.chavePix,
    tipoChavePix: contaBancaria.tipoChavePix,
    padrao: contaBancaria.padrao
  }
}

export function clear() {
  return {
    type: 'CONTABANCARIA_CLEAR'
  }
}

export function updatedUserInfo() {
  return {
    type: 'CONTABANCARIA_UPDATED'
  }
}
export function createUserInfo() {
  return {
    type: 'CONTABANCARIA_CREATED_SUCCESSFULLY'
  }
}
export function deleteContasBancariasDetails() {
  return {
    type: 'DELETED_CONTABANCARIA_DETAILS'
  }
}
