import { colors } from '@material-ui/core'
import { StatusIngresso } from 'types/api'

export const getStatusIngressoColor = (statusIngresso: StatusIngresso) => {
  if (statusIngresso.pago) {
    return colors.green[500]
  }
  if (statusIngresso.cancelado) {
    return colors.red[500]
  }
  if (statusIngresso.transferido) {
    return colors.green[800]
  }
  if (statusIngresso.liberadoCarteira) {
    return colors.orange[500]
  }
  return colors.grey[500]
}
