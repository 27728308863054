import React from 'react'
import { makeStyles } from '@material-ui/core'

//? Own imports
import { TableOrderManagementList } from 'views/OrderManagementList/components'
import { useUserOrders } from 'hooks-querys'

const useStyles = makeStyles(() => ({
  root: {}
}))

const Orders = ({ userId }: { userId: number }) => {
  const classes = useStyles()
  const {
    data: userOrders
  } = useUserOrders(userId)

  return (
    <div className={classes.root}>
      <TableOrderManagementList orders={userOrders} />
    </div>
  )
}

export default Orders
