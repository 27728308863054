import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Pie } from 'react-chartjs-2'
import { makeStyles, useTheme } from '@material-ui/styles'
import { formatCurrency } from 'utils'
import pluralize from 'pluralize'

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative'
  }
}))

const Chart = (props) => {
  const { data: dataProp, className, ...rest } = props

  const classes = useStyles()
  const theme = useTheme()
  const data = {
    datasets: [
      {
        id: '1',
        data: [],
        backgroundColor: [],
        borderWidth: 5,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white
      },
      {
        id: '2',
        data: [],
        backgroundColor: [],
        borderWidth: 5,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white
      }
    ],
    labels: dataProp && dataProp.options ? dataProp.options : []
  }

  for (let i = 0; i < dataProp.options.length; i++) {
    data.datasets[0].data.push(dataProp.series[0][i])
    data.datasets[0].backgroundColor.push(dataProp.colors[i][200])
    // data.datasets[0].hoverBorderColor.push(color[400])
    data.datasets[1].data.push(dataProp.series[1][i])
    data.datasets[1].backgroundColor.push(dataProp.colors[i][200])
    // data.datasets[1].hoverBorderColor.push(color[400])
  }

  const options = {
    legend: {
      labels: {
        usePointStyle: true
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    // animation: false,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex]
          var meta = dataset._meta[Object.keys(dataset._meta)[0]]
          var total = meta.total
          var currentValue = dataset.data[tooltipItem.index]
          var percentage = parseFloat(((currentValue / total) * 100).toFixed(1))
          if (!isNaN(percentage)) {
            if (dataset.id === '2') {
              return `${formatCurrency(
                currentValue
              )} (${percentage.toLocaleString('pt-BR', {
                maximumFractionDigits: 2
              })}%)`
            } else {
              return (
                pluralize('pedido', currentValue, true) +
                ' (' +
                percentage.toLocaleString('pt-BR', {
                  maximumFractionDigits: 2
                }) +
                '%)'
              )
            }
          } else {
            return null
          }
        },
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index].toUpperCase()
        }
      }
    }
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Pie data={data} options={options} />
    </div>
  )
}

Chart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired
}

export default Chart
