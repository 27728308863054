import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { tipoIngressoAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import { Page } from 'components'
import EmptyFirstTiposIngresso from './EmptyFirstTiposIngresso/EmptyFirstTiposIngresso'
import constants from 'theme/constants'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  paper: {
    ...constants.shadowCard
  }
})

const TipoIngresso = (props) => {
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { tipoIngresso } = props.tipoIngresso

  useEffect(() => {
    dispatch(
      tipoIngressoAction.getTipoIngressoByEstabelecimento(
        props.usuarioEstabelecimento.estabelecimentoId
      )
    )
  }, [dispatch, props.usuarioEstabelecimento.estabelecimentoId])

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir este Tipo de Ingresso?',
      description:
        'Confirmando essa operação, você não poderá cadastrar novos lotes com este tipo de ingresso.',
      confirmationText: 'Sim, excluir!',
      cancellationText: 'Nãããoo!'
    }).then(() => {
      dispatch(tipoIngressoAction.deleteTipoIngressoById(id))
    })
  }

  return (
    <Page className={classes.root} title="Tipos de Ingresso">
      <AccessControl
        rule={'tiposIngressos.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {undefined !== tipoIngresso && tipoIngresso.length > 0 ? (
              <TitleContent
                rule={'tiposIngressos.create'}
                length={tipoIngresso.length}
                subTitle={'GERÊNCIA DE TIPOS DE INGRESSO'}
                title={'Lista de tipos de ingresso'}
                href={'/tipoIngresso'}
              />
            ) : (
              <TitleContent
                rule={'tiposIngressos.create'}
                length={0}
                subTitle={'GERÊNCIA DE TIPOS DE INGRESSO'}
                title={'Lista de tipos de ingresso'}
                href={'/tipoIngresso'}
              />
            )}
            {!props.tipoIngresso.isLoading ? (
              undefined !== tipoIngresso && tipoIngresso.length > 0 ? (
                <TableContainer component={Paper} className={classes.paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Descrição</TableCell>
                        <TableCell align="right">Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tipoIngresso.map((n) => {
                        return (
                          <TableRow hover key={n.id}>
                            <TableCell component="th" scope="row">
                              {n.descricao}
                            </TableCell>
                            <TableCell align="right">
                              <AccessControl
                                rule={'tiposIngressos.edit'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    size="small"
                                    aria-label="Edit"
                                    component="a"
                                    onClick={() =>
                                      history.push(`/tipoIngresso/${n.id}`)
                                    }
                                  >
                                    <Tooltip title="Editar">
                                      <EditIcon />
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                              <AccessControl
                                rule={'tiposIngressos.delete'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    size="small"
                                    aria-label="Delete"
                                    onClick={() => handleClick(n.id)}
                                  >
                                    <Tooltip title="Excluir">
                                      <DeleteIcon />
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <EmptyFirstTiposIngresso />
              )
            ) : (
              <div className={classes.root}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </div>
            )}
          </div>
        )}
      />
    </Page>
  )
}
TipoIngresso.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    tipoIngresso: state.tipoIngresso,
    usuarioEstabelecimento: state.usuarioEstabelecimento
  }
}
const connectedTipoIngressoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(TipoIngresso))
)
export { connectedTipoIngressoPage as TipoIngresso }
