const initialState = {
  parametro: [],
  isLoading: false,
  id: '',
  chave: '',
  valor: '',
  descricao: ''
}

export function parametro(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_PARAMETRO':
      return {
        ...state,
        parametro: action.parametro
      }
    case 'FETCHED_ALL_PARAMETRO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'FETCHED_ALL_PARAMETRO_SUCCESS':
      return {
        ...state,
        isLoading: false
      }
    case 'FETCHED_ALL_PARAMETRO_FAILURE':
      return {
        ...state,
        isLoading: false
      }
    case 'PARAMETRO_DETAIL':
      return {
        ...state,
        id: action.id,
        chave: action.chave,
        valor: action.valor,
        descricao: action.descricao
      }
    case 'PARAMETRO_CREATED_SUCCESSFULLY':
      return {
        ...state,
        id: '',
        chave: '',
        valor: '',
        descricao: '',
        parametro: [...state.parametro, action.parametro]
      }
    case 'PARAMETRO_UPDATED':
      return state
    case 'PARAMETRO_CLEAR':
      return initialState
    case 'PARAMETRO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    default:
      return state
  }
}
