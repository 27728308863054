const initialState = {
  id: 0,
  movimentacaoFinanceira: [],
  statusMovimentacaoFinanceiraId: 0,
  estabelecimentoId: 0,
  tipo: '',
  dataHora: '',
  dataHoraProcessamento: '',
  valor: '',
  contaBancariaId: 0,
  anexo: '',
  motivoCancelamento: '',
  estabelecimento: {},
  contaBancaria: {},
  statusMovimentacaoFinanceira: {},
  movimentacaoFinanceiraIngresso: [],
  isLoading: false
}

export function movimentacaoFinanceira(state = initialState, action) {
  switch (action.type) {
    case 'MOVIMENTACAO_FINANCEIRA_CLEAR_ALL':
      return initialState
    case 'MOVIMENTACAO_FINANCEIRA_CREATE_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'MOVIMENTACAO_FINANCEIRA_CREATE_SUCCESS':
      return {
        ...state,
        isLoading: false
      }
    case 'MOVIMENTACAO_FINANCEIRA_CREATE_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'MOVIMENTACAO_FINANCEIRA_FINISH_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'MOVIMENTACAO_FINANCEIRA_FINISH_SUCCESS':
      return {
        ...state,
        isLoading: false
      }
    case 'MOVIMENTACAO_FINANCEIRA_FINISH_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'MOVIMENTACAO_FINANCEIRA_CANCEL_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'MOVIMENTACAO_FINANCEIRA_CANCEL_SUCCESS':
      return {
        ...state,
        isLoading: false
      }
    case 'MOVIMENTACAO_FINANCEIRA_CANCEL_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'GET_MOVIMENTACAO_FINANCEIRA_BY_ESTABELECIMENTO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'GET_MOVIMENTACAO_FINANCEIRA_BY_ESTABELECIMENTO_SUCCESS':
      return {
        ...state,
        movimentacaoFinanceira: action.movimentacaoFinanceira,
        isLoading: false
      }
    case 'GET_MOVIMENTACAO_FINANCEIRA_BY_ESTABELECIMENTO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'GET_MOVIMENTACAO_FINANCEIRA_BY_ID_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'GET_MOVIMENTACAO_FINANCEIRA_BY_ID_SUCCESS':
      return {
        ...state,
        id: action.movimentacaoFinanceira.id,
        statusMovimentacaoFinanceiraId:
          action.movimentacaoFinanceira.statusMovimentacaoFinanceiraId,
        estabelecimentoId: action.movimentacaoFinanceira.estabelecimentoId,
        tipo: action.movimentacaoFinanceira.tipo,
        dataHora: action.movimentacaoFinanceira.dataHora,
        dataHoraProcessamento:
          action.movimentacaoFinanceira.dataHoraProcessamento,
        valor: action.movimentacaoFinanceira.valor,
        contaBancariaId: action.movimentacaoFinanceira.contaBancariaId,
        anexo: action.movimentacaoFinanceira.anexo,
        motivoCancelamento: action.movimentacaoFinanceira.motivoCancelamento,
        estabelecimento: action.movimentacaoFinanceira.estabelecimento,
        contaBancaria: action.movimentacaoFinanceira.contaBancaria,
        statusMovimentacaoFinanceira:
          action.movimentacaoFinanceira.statusMovimentacaoFinanceira,
        movimentacaoFinanceiraIngresso:
          action.movimentacaoFinanceira.movimentacaoFinanceiraIngresso,
        isLoading: false
      }
    case 'GET_MOVIMENTACAO_FINANCEIRA_BY_ID_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'GET_ALL_MOVIMENTACAO_FINANCEIRA_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'GET_ALL_MOVIMENTACAO_FINANCEIRA_SUCCESS':
      return {
        ...state,
        movimentacaoFinanceira: action.movimentacaoFinanceira,
        isLoading: false
      }
    case 'GET_ALL_MOVIMENTACAO_FINANCEIRA_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'MOVIMENTACAO_FINANCEIRA_DETAIL':
      return {
        ...state,
        id: action.movimentacaoFinanceira.id,
        statusMovimentacaoFinanceiraId:
          action.movimentacaoFinanceira.statusMovimentacaoFinanceiraId,
        estabelecimentoId: action.movimentacaoFinanceira.estabelecimentoId,
        tipo: action.movimentacaoFinanceira.tipo,
        dataHora: action.movimentacaoFinanceira.dataHora,
        dataHoraProcessamento:
          action.movimentacaoFinanceira.dataHoraProcessamento,
        valor: action.movimentacaoFinanceira.valor,
        contaBancariaId: action.movimentacaoFinanceira.contaBancariaId,
        anexo: action.movimentacaoFinanceira.anexo,
        motivoCancelamento: action.movimentacaoFinanceira.motivoCancelamento,
        estabelecimento: action.movimentacaoFinanceira.estabelecimento,
        contaBancaria: action.movimentacaoFinanceira.contaBancaria,
        statusMovimentacaoFinanceira:
          action.movimentacaoFinanceira.statusMovimentacaoFinanceira,
        movimentacaoFinanceiraIngresso:
          action.movimentacaoFinanceira.movimentacaoFinanceiraIngresso
      }
    case 'MOVIMENTACAO_FINANCEIRA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    default:
      return state
  }
}
