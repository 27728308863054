import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  TableRow,
  TableCell,
  Grid,
  Tooltip,
  IconButton,
  Link,
  makeStyles,
  Collapse,
  Box,
  Typography,
  Table,
  TableHead,
  useTheme,
  TableBody
} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

//? Own imports
import { MembershipSubscription } from 'types/api'
import { formatCurrency, toLocalDateFormat } from 'utils'
import { Label } from 'components'
import { TableFinancialTransactionHistory } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const RowTableMemberFinancial = ({
  membershipItem
}: {
  membershipItem: MembershipSubscription
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = useState(false)

  if (!membershipItem) return null

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <Tooltip
            title={
              open
                ? 'Fechar histórico de transações'
                : 'Mostrar histórico de transações'
            }
          >
            <IconButton
              aria-label={
                open
                  ? 'Fechar histórico de transações'
                  : 'Abrir histórico de transações'
              }
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>{membershipItem?.membroPlano?.plano?.nome}</TableCell>
        <TableCell align="right">
          {membershipItem?.membroPlano?.planoValor?.valor
            ? formatCurrency(membershipItem?.membroPlano?.planoValor?.valor)
            : '----------'}
        </TableCell>
        <TableCell align="right">
          {membershipItem?.dataHoraInicio
            ? toLocalDateFormat(membershipItem?.dataHoraInicio)
            : '----------'}
        </TableCell>
        <TableCell align="right">
          {membershipItem?.dataHoraFim
            ? toLocalDateFormat(membershipItem?.dataHoraFim)
            : '----------'}
        </TableCell>

        <TableCell align="right">
          {membershipItem ? (
            <Label
              color={
                membershipItem?.pago
                  ? theme?.palette?.success?.main
                  : theme?.palette?.error?.main
              }
              variant="contained"
            >
              {membershipItem?.pago ? 'PAGO' : 'PAGAMENTO PENDENTE'}
            </Label>
          ) : (
            '----------'
          )}
        </TableCell>
        <TableCell align="right">
          <Grid wrap="nowrap" container justifyContent="flex-end">
            <Grid>
              <Tooltip title="Detalhes">
                <Link component={RouterLink} to={``}>
                  <IconButton
                    size="small"
                    aria-label="Detalhes da subscrição do membro"
                    style={{ marginLeft: 10 }}
                  >
                    <InfoOutlinedIcon />
                  </IconButton>
                </Link>
              </Tooltip>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableFinancialTransactionHistory
              membershipId={membershipItem?.id}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default RowTableMemberFinancial
