import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Typography, colors, Tooltip } from '@material-ui/core'
import { formatCurrency } from 'utils'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
    flexShrink: 0,
    borderRadius: theme.shape.borderRadius,
    lineHeight: '10px',
    fontSize: '10px',
    height: 20,
    minWidth: 20,
    whiteSpace: 'nowrap',
    padding: theme.spacing(0.5, 1)
  },
  rounded: {
    borderRadius: 10,
    padding: theme.spacing(0.5)
  }
}))

const LabelWithValue = (props) => {
  const {
    className,
    variant,
    value,
    shape,
    style,
    oldValue,
    isCurrency,
    isPercentage,
    ...rest
  } = props

  const classes = useStyles()

  const rootClassName = clsx(
    {
      [classes.root]: true,
      [classes.rounded]: shape === 'rounded'
    },
    className
  )

  const finalStyle = { ...style }
  const color =
    value === 0
      ? colors.grey[300]
      : value > 0
      ? colors.green[600]
      : colors.red[600]

  if (variant === 'contained') {
    finalStyle.backgroundColor = color
    finalStyle.color = '#FFF'
  } else {
    finalStyle.border = `1px solid ${color}`
    finalStyle.color = color
  }

  return (
    <Tooltip
      title={`Valor anterior: ${
        isPercentage
          ? oldValue.toLocaleString('pt-BR', {
              maximumFractionDigits: 2
            }) + '%'
          : isCurrency
          ? formatCurrency(oldValue)
          : oldValue
      }`}
    >
      <Typography
        {...rest}
        className={rootClassName}
        style={finalStyle}
        variant="overline"
      >
        {value && value > 0 && '+'}
        {value && value === 0
          ? value
          : typeof value === 'number' &&
            value.toLocaleString('pt-BR', {
              maximumFractionDigits: 2
            })}
        %
      </Typography>
    </Tooltip>
  )
}

LabelWithValue.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  shape: PropTypes.oneOf(['square', 'rounded']),
  style: PropTypes.object,
  variant: PropTypes.oneOf(['contained', 'outlined']),
  value: PropTypes.number,
  oldValue: PropTypes.number,
  isCurrency: PropTypes.bool,
  isPercentage: PropTypes.bool
}

LabelWithValue.defaultProps = {
  style: {},
  color: colors.grey[600],
  variant: 'contained',
  shape: 'square'
}

export default LabelWithValue
