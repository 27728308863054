import React, { useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import ListRoundedIcon from '@material-ui/icons/ListRounded'
import {
  Tabs,
  Tab,
  Divider,
  colors,
  makeStyles,
  useTheme,
  useMediaQuery
} from '@material-ui/core'
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone'
import { Page, Header } from 'components'
import { PhotoAlbumList } from './PhotoAlbumList'
import PhotoAlbumEdit from './PhotoAlbumEdit'
import { useDispatch } from 'react-redux'
import { albumAction } from '_actions'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}))

// @ts-ignore
const PhotoAlbum = (props) => {
  const { match, history } = props
  const { tab } = match.params
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  // @ts-ignore
  const { photoAlbumId, eventoId } = useParams()

  // @ts-ignore
  const handleTabsChange = (event, value) => {
    history.push(`/photoAlbum/${value}`)
  }

  const tabs = [
    { value: 'lista', label: 'Lista', icon: <ListRoundedIcon /> },
    {
      value: 'cadastro',
      label: photoAlbumId ? 'Editar' : 'Cadastrar',
      icon: photoAlbumId ? <EditTwoToneIcon /> : <AddCircleTwoToneIcon />,
      disabled: photoAlbumId ? true : false
    }
  ]

  useEffect(() => {
    return () => {
      dispatch(albumAction.deleteAllFoto())
    }
  }, [])

  if (!tab) {
    return <Redirect to="/photoAlbum/lista" />
  }

  if (!tabs.find((t) => t.value === tab)) {
    return <Redirect to="/errors/error-404" />
  }

  return (
    // @ts-ignore
    <Page className={classes.root} title="Configurações">
      <Header
        subtitle="GERÊNCIA DE ÁLBUNS DE FOTOS"
        title={eventoId ? 'Álbum de foto por evento' : 'Álbuns de Fotos'}
      />
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        variant={isMobile ? 'fullWidth' : 'standard'}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            icon={tab.icon}
            disabled={tab.disabled}
          />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {tab === 'lista' && <PhotoAlbumList />}
        {tab === 'cadastro' && <PhotoAlbumEdit />}
      </div>
    </Page>
  )
}

export default PhotoAlbum
