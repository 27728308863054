import React from 'react'
import {
  Tabs,
  Paper,
  Tab,
  makeStyles,
  useTheme,
  useMediaQuery
} from '@material-ui/core'
import AccountBalanceWalletTwoToneIcon from '@material-ui/icons/AccountBalanceWalletTwoTone'
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone'
import EventAvailableTwoToneIcon from '@material-ui/icons/EventAvailableTwoTone'
import EventBusyTwoToneIcon from '@material-ui/icons/EventBusyTwoTone'
import { history } from 'utils'
import { Redirect, useParams } from 'react-router-dom'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    ...constants.shadowCard,
    padding: 10
  },
  tabs: {
    width: '100%'
  },
  tab: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 11
    },
    textTransform: 'none'
  },
  marginLeft: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0'
    },
    marginLeft: 'auto'
  }
}))

const Navigation = () => {
  const classes = useStyles()
  const { tab } = useParams()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleChange = (event, value) => {
    history.push(`/carteira/${value}`)
  }
  return (
    <Paper variant="outlined" className={classes.paper}>
      <Tabs
        className={classes.tabs}
        value={tab}
        onChange={handleChange}
        variant={isMobile ? 'fullWidth' : 'standard'}
        indicatorColor="none"
        textColor="primary"
        aria-label="icon label tabs example"
      >
        <Tab
          className={classes.tab}
          value="liberados"
          icon={<EventAvailableTwoToneIcon />}
          label="Liberados"
        />
        <Tab
          className={classes.tab}
          value="aliberar"
          icon={<EventBusyTwoToneIcon />}
          label="A liberar"
        />
        <Tab
          className={[classes.tab, classes.marginLeft]}
          value="retiradas"
          icon={<AccountBalanceWalletTwoToneIcon />}
          label="Retiradas"
        />
        <Tab
          className={classes.tab}
          value="contasBancarias"
          icon={<AccountBalanceTwoToneIcon />}
          label={isMobile ? 'Contas' : 'Contas bancárias'}
        />
      </Tabs>
    </Paper>
  )
}

export default Navigation
