import React, { useEffect, useState } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  MenuItem,
  Button,
  Tooltip,
  Grid,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton
} from '@material-ui/core'
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone'
import EditIcon from '@material-ui/icons/Edit'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'
import { cupomDescontoAction, eventoAction, loteAction } from '_actions'
import { HelperToolTip, ValidatedDatePicker } from 'components/'
import { toast } from 'react-toastify'
import { toISODateFormat, validateDates } from 'utils'
import { history } from 'utils'
import { format, isValid } from 'date-fns'
import { Page } from 'components'
import { makeStyles } from '@material-ui/styles'
import { useParams } from 'react-router-dom'
import constants from 'theme/constants'
import { useMembershipPlanId, useMutationMembershipPlanCreate, useMutationMembershipPlanEdit } from 'hooks-querys'
import { MembershipPlan, PlanPrice } from 'types/api'
import { UploadField } from 'components/Utils/Uploads'
import { AccessControl } from 'components/Utils'
import DeleteIcon from '@material-ui/icons/Delete'
import { useMutationPlanPriceCreate, useMutationPlanPriceDelete, useMutationPlanPriceEdit } from 'hooks-querys/members'
import { number } from 'yargs'

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(2)
  },
  paper: {
    ...constants.shadowCard
  },
  buttonAddPrice: {
    // width: "100%",
    height: 55
  }
}))

// @ts-ignore
const MembershipPlanEdit = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  // @ts-ignore
  const { params, membershipPlanId } = useParams()
  const { data: membershipPlanEdit } = useMembershipPlanId(Number(membershipPlanId))
  const [name, setName] = useState<string | null>()
  const [description, setDescription] = useState<string | null>()
  const [image, setImage] = useState<string | null>()
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  // const [checkIn, setCheckIn] = useState(false)
  // const [hoursCheckIn, setHoursCheckIn] = useState<number | null>(null)
  const [membersLimit, setMembersLimit] = useState<number | null>(null)
  const [ticketsNumber, setTicketsNumber] = useState<number | null>(null)
  const [dueDate, setDueDate] = useState<number | null>(null)
  const [daysOfDefault, setDaysOfDefault] = useState<number | null>(null)
  const [periodicity, setPeriodicity] = useState< 'M' | 'A' | null>(null)
  const [price, setPrice] = useState<number | null>(null)
  const [planPrices, setPlanPrices] = useState<PlanPrice[]>([])
  const [isEditPlanPrice, setIsEditPlanPrice] = useState<boolean>(false)
  const [planPriceEditIndex, setPlanPriceEditIndex] = useState<number>(0)
  const estabelecimentoId = useSelector(
    // @ts-ignore: //ainda não foi tipado o redux state
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const mutationCreateMembershipPlan = useMutationMembershipPlanCreate()
  const mutationEditMembershipPlan = useMutationMembershipPlanEdit()
  const mutationEditPlanPrice = useMutationPlanPriceEdit()
  const mutationDeletePlanPrice = useMutationPlanPriceDelete()
  const mutationPlanPriceCreate = useMutationPlanPriceCreate()

  const LoadEditDetails = () => {
    if (!!membershipPlanEdit) {
      setName(membershipPlanEdit.nome)
      setDescription(membershipPlanEdit.descricao)
      setImage(membershipPlanEdit.imagem)
      setStartDate(membershipPlanEdit.dataHoraInicio)
      setEndDate(membershipPlanEdit.dataHoraFim)
      setMembersLimit(membershipPlanEdit.limiteMembros)
      setTicketsNumber(membershipPlanEdit.numeroIngressos)
      setDueDate(membershipPlanEdit.diaVencimento)
      setDaysOfDefault(membershipPlanEdit.diasInadimplencia)
      setPlanPrices(membershipPlanEdit.planoValor)
      // setCheckIn(membershipPlanEdit.checkIn)
      // setHoursCheckIn(membershipPlanEdit.hoursCheckIn)
    }
  }

  useEffect(() => {
    LoadEditDetails()
  }, [membershipPlanEdit])

  // const handleChangeCheckIn = () => {
  //   setCheckIn(!checkIn)
  // }

  const cancelClick = () => {
    history.goBack()
  }

  const handleDeletePlanPrice = (planPriceItem: PlanPrice) => {
    let indexOfPlanPriceItem = planPrices.indexOf(planPriceItem)
    if (membershipPlanId) {
      mutationDeletePlanPrice.mutateAsync(planPriceItem.id).then(() => {
        setPlanPrices(membershipPlanEdit.planoValor)
        planPrices.splice(indexOfPlanPriceItem, 1)
      })
    } else {
      planPrices.splice(indexOfPlanPriceItem, 1)
    }
  }

  const handleEditPlanPrice = (planPriceItem: PlanPrice) => {
    let indexOfPlanPriceEdit = planPrices.indexOf(planPriceItem)
    setPeriodicity(planPrices[indexOfPlanPriceEdit].periodicidade)
    setPrice(planPrices[indexOfPlanPriceEdit].valor)
    setIsEditPlanPrice(true)
    setPlanPriceEditIndex(indexOfPlanPriceEdit)
  }

  const handleEditPlanPriceFinal = () => {
    let payload = {
      id: planPrices[planPriceEditIndex].id,
      periodicidade: periodicity,
      planoId: membershipPlanId,
      valor: Number(price)
    }

    if (membershipPlanId) {
      mutationEditPlanPrice.mutateAsync(payload).then(() => {
        setPlanPrices(membershipPlanEdit.planoValor)
        setPeriodicity(null)
        setPrice(null)
        setIsEditPlanPrice(false)
        setPlanPriceEditIndex(0)
      })
    } else {
      // @ts-ignore
      planPrices[planPriceEditIndex] = payload
      setPeriodicity(null)
      setPrice(null)
      setIsEditPlanPrice(false)
      setPlanPriceEditIndex(0)
    }
  }

  const handleAddPlanPrice = () => {
    if (!periodicity || !price) {
      toast.error('Informe uma periodicidade e valor!')
    } else {
      if (membershipPlanId) {
        let payload = {
          id: 0,
          periodicidade: periodicity,
          planoId: Number(membershipPlanId),
          valor: Number(price)
        }

        mutationPlanPriceCreate.mutateAsync(payload).then(() => {
          setPlanPrices(membershipPlanEdit.planoValor)
          setPeriodicity(null)
          setPrice(null)
        })
      } else {
        planPrices.push({
          id: planPrices ? planPrices.length : 0,
          periodicidade: periodicity,
          planoId: 0,
          valor: Number(price)
        })
        setPeriodicity(null)
        setPrice(null)
      }
    }
  }

  function preparePayload() {
    if (!!membershipPlanEdit) {
      planPrices.forEach((item) => {
        item.planoId === 0 ? item.id = 0 : item.id = item.id
        item.planoId = Number(membershipPlanId)
      })
    } else {
      planPrices.forEach((item) => {
        item.id = 0
      })
    }

    let payload = {
      id: !!membershipPlanEdit ? membershipPlanId : 0,
      estabelecimentoId: !!membershipPlanEdit
                          ? membershipPlanEdit.estabelecimentoId
                          : estabelecimentoId,
      nome: name,
      descricao: description,
      imagem: image,
      // dataHoraInicio: !!startDate ? format(new Date(startDate), 'dd/MM/yyyy HH:mm') : null,
      dataHoraInicio: startDate,
      // dataHoraFim: !!endDate ? format(new Date(endDate), 'dd/MM/yyyy HH:mm') : null,
      dataHoraFim: endDate,
      limiteMembros: membersLimit,
      numeroIngressos: ticketsNumber,
      diaVencimento: dueDate,
      diasInadimplencia: daysOfDefault,
      planoValor: planPrices
      // checkIn: checkIn
    }

    return payload
  }


  const handleSubmit = () => {
    let payload

    if (planPrices.length < 1 ) {
      toast.error('Informe ao menos uma periodicidade e valor!')
    } else if (!!startDate) {
      if (!!endDate && endDate <= startDate ) {
        toast.error('Fim não pode ser anterior ao início do plano!')
      } else {
        payload = preparePayload()

        if (membershipPlanEdit) {
          // @ts-ignore
          editMembershipPlan(payload)
        } else {
          // @ts-ignore
          createMembershipPlan(payload)
        }
      }
    } else {
      toast.error('Informe uma data de início para o plano!')
    }
  }

  const createMembershipPlan = (payload: MembershipPlan) => {
    mutationCreateMembershipPlan.mutateAsync(payload).then(() => {
      history.push('/members/listaPlano')
    })
  }

  const editMembershipPlan = (payload: MembershipPlan) => {
    mutationEditMembershipPlan.mutateAsync(payload).then(() => {
      history.push('/members/listaPlano')
    })
  }

  return (
    <Page
      title={
        membershipPlanId
          ? 'Gerência Plano - Editar Plano de Sócios'
          : 'Gerência Plano - Novo Plano de Sócios'
      }
    >
      <Grid item md={12} xs={12}>
        <Card className={classes.paper}>
          <form>
            <CardHeader
              subheader="Cadastro Plano de Sócios"
              title={
                membershipPlanId
                  ? 'Editar Plano de Sócios'
                  : 'Novo Plano de Sócios'
              }
            />
            <ValidatorForm
              id="formMembershipPlanEdit"
              onSubmit={handleSubmit}
            >
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                      <TextValidator
                        name="name"
                        variant="outlined"
                        id="nome"
                        label="Nome *"
                        value={name || ''}
                        onChange={(event) =>
                          // @ts-ignore
                          setName(event.target.value)
                        }
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        fullWidth
                        multiline
                        InputProps={{
                          endAdornment: (
                            <>
                              <InputAdornment position="end">
                                <>
                                  {!!name && name.length > 100 ? (
                                    <Typography variant="body2" align="right" color="error">
                                      {name?.length}/100
                                    </Typography>
                                  ) :
                                    (<Typography variant="body2" align="right" >
                                      {name?.length}/100
                                    </Typography>)
                                  }
                                </>
                              </InputAdornment>
                            </>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <TextValidator
                        name="descricao"
                        variant="outlined"
                        id="descricao"
                        label="Descrição *"
                        value={description || ''}
                        onChange={(event) =>
                          // @ts-ignore
                          setDescription(event.target.value)
                        }
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        fullWidth
                        multiline
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <TextValidator
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <HelperToolTip>
                                Limite de membros para esse plano de sócios
                              </HelperToolTip>
                            </InputAdornment>
                          )
                        }}
                        name="limiteMembros"
                        variant="outlined"
                        id="limiteMembros"
                        label="Limite de Membros"
                        value={membersLimit || ''}
                        onChange={(event) =>
                          // @ts-ignore
                          setMembersLimit(event.target.value)
                        }
                        fullWidth
                        type="number"
                        inputProps={{
                          min: '1'
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <TextValidator
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <HelperToolTip>
                              Quantidade de ingressos liberada a cada evento para o membro
                              </HelperToolTip>
                            </InputAdornment>
                          )
                        }}
                        name="quantidadeIngressos"
                        variant="outlined"
                        id="quantidadeIngressos"
                        label="Quantidade de Ingressos"
                        value={ticketsNumber || ''}
                        onChange={(event) =>
                          // @ts-ignore
                          setTicketsNumber(event.target.value)
                        }
                        fullWidth
                        type="number"
                        inputProps={{
                          min: '1'
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <ValidatedDatePicker
                        name="dataHoraInicio"
                        // validators={['isValidDate']}
                        // errorMessages={['Data inválida!']}
                        autoOk
                        id="dataHoraInicio"
                        label="Data/Hora Início *"
                        fullWidth
                        animateYearScrolling
                        inputVariant="outlined"
                        ampm={false}
                        value={startDate}
                        //inputValue={startDate}
                        // @ts-ignore
                        onChange={(newValue) =>
                          setStartDate(newValue)
                        }
                        //disablePast
                        format="dd/MM/yyyy HH:mm"
                        invalidDateMessage="Data inválida!"
                        invalidLabel="dd/MM/aaaa HH:mm"
                        maxDateMessage="A data não deve ser posterior à data máxima"
                        //minDateMessage="A data não deve ser anterior à data de hoje"
                        cancelLabel="Cancelar"
                        okLabel="Definir"
                        clearable
                        clearLabel="Limpar"
                        placeholder="dd/MM/aaaa HH:mm"
                        todayLabel="Hoje"
                        defaultValue={undefined}
                        openTo="month"
                        views={['month', 'date', 'hours', 'minutes']}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <ValidatedDatePicker
                        name="dataHoraFim"
                        // validators={['isValidDate']}
                        // errorMessages={['Data inválida!']}
                        autoOk
                        id="dataHoraFim"
                        label="Data/Hora Fim"
                        animateYearScrolling
                        fullWidth
                        inputVariant="outlined"
                        ampm={false}
                        value={endDate}
                        //inputValue={endDate}
                        // @ts-ignore
                        onChange={(newValue) =>
                          setEndDate(newValue)
                        }
                        // disablePast
                        minDate={startDate}
                        format="dd/MM/yyyy HH:mm"
                        invalidDateMessage="Data inválida!"
                        invalidLabel="dd/MM/aaaa HH:mm"
                        maxDateMessage="A data não deve ser posterior à data máxima"
                        minDateMessage="A data não deve ser anterior à data de início"
                        cancelLabel="Cancelar"
                        okLabel="Definir"
                        clearable
                        clearLabel="Limpar"
                        placeholder="dd/MM/aaaa HH:mm"
                        todayLabel="Hoje"
                        defaultValue={undefined}
                        openTo="month"
                        views={['month', 'date', 'hours', 'minutes']}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <TextValidator
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <HelperToolTip>
                              Dia de vencimento para o pagamento da fatura do plano.
                              Se não for especificado, as cobranças serão geradas
                              imediatamente, com a próxima cobrança tendo como base
                              a data de adesão. Se for informado um dia, o pagamento
                              será gerado naquele dia com vencimento para aquele dia.
                              </HelperToolTip>
                            </InputAdornment>
                          )
                        }}
                        name="diaVencimento"
                        variant="outlined"
                        id="diaVencimento"
                        label="Dia Vencimento Pagamento"
                        value={dueDate || ''}
                        onChange={(event) =>
                          // @ts-ignore
                          setDueDate(event.target.value)
                        }
                        fullWidth
                        type="number"
                        inputProps={{
                          min: '1',
                          max: '31'
                        }}
                        // validators={['required']}
                        // errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <TextValidator
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <HelperToolTip>
                                Dias que o membro pode ficar inadimplente e mesmo assim participar dos eventos
                              </HelperToolTip>
                            </InputAdornment>
                          )
                        }}
                        name="daysOfDefault"
                        variant="outlined"
                        id="daysOfDefault"
                        label="Dias de Inadimplência"
                        value={daysOfDefault || ''}
                        onChange={(event) =>
                          // @ts-ignore
                          setDaysOfDefault(event.target.value)
                        }
                        fullWidth
                        type="number"
                        inputProps={{
                          min: '1'
                        }}
                      />
                    </Grid>
                    {/* Check-in ainda nao esta funcional */}
                    {/*<Grid item xs={12}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <FormControlLabel
                        label="Check-In"
                        control={
                          <Checkbox
                            checked={checkIn}
                            onChange={handleChangeCheckIn}
                            color="primary"
                          />
                        }
                      />
                      <HelperToolTip>
                        Se ativado, os membros do plano devem fazer check-in com antecedência
                      </HelperToolTip>
                      <TextValidator
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <HelperToolTip>
                              Quantos horas antes de um evento começar o membro deverá fazer o check-in
                              </HelperToolTip>
                            </InputAdornment>
                          )
                        }}
                        name="hoursCheckIn"
                        variant="outlined"
                        id="hoursCheckIn"
                        label="Horas de Antecedência"
                        value={hoursCheckIn || ''}
                        onChange={(event) =>
                          // @ts-ignore
                          setHoursCheckIn(event.target.value)
                        }
                        type="number"
                        inputProps={{
                          min: '1'
                        }}
                        disabled={!checkIn}
                        placeholder="72"
                      />
                      </div>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <UploadField
                        parentCallback={setImage}
                        url={image}
                        labelText={'Capa do plano'}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                      <SelectValidator
                      name="periodicity"
                      variant="outlined"
                      id="periodicity"
                      label="Periodicidade *"
                      value={periodicity || ''}
                      onChange={(event) =>
                        // @ts-ignore
                        setPeriodicity(event.target.value)
                      }
                      fullWidth
                      displayEmpty
                      labelId="periodicity"
                    >
                      <MenuItem disabled value="">
                      <em>Periodicidade</em>
                    </MenuItem>
                    <MenuItem value="M">Mensal</MenuItem>
                    <MenuItem value="A">Anual</MenuItem>
                  </SelectValidator>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2} lg={2}>
                    <TextValidator
                      name="price"
                      variant="outlined"
                      id="price"
                      label="Valor *"
                      value={price || ''}
                      onChange={(event) =>
                        // @ts-ignore
                        setPrice(event.target.value)
                      }
                      fullWidth
                      type="number"
                      inputProps={{
                        min: '1'
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2} lg={2}>
                    <Button
                    onClick={() => isEditPlanPrice ? handleEditPlanPriceFinal() : handleAddPlanPrice()}
                    color="primary"
                    variant="outlined"
                    fullWidth
                    className={classes.buttonAddPrice}
                    startIcon={isEditPlanPrice ? <EditIcon /> : <AddCircleTwoToneIcon />}
                    >
                      {isEditPlanPrice ? 'Editar Valor': 'Adicionar valor'}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TableContainer component={Paper} className={classes.paper}>
                      <Table size="small">
                        <TableHead>
                          <Typography
                            variant="subtitle2"
                            id="titleTablePrices"
                            align="center"
                            >
                            <strong>Lista de Valores do Plano</strong>
                          </Typography>
                          <TableRow>
                            <TableCell align="left">Periodicidade</TableCell>
                            <TableCell align="center">Valor</TableCell>
                            <TableCell align="right">Ações</TableCell>
                          </TableRow>
                        </TableHead>
                        {!!planPrices && planPrices.length ? (
                        <TableBody>
                          {planPrices.map((n: PlanPrice) => {
                            return (
                              <TableRow hover key={n.id}>
                                <TableCell align="left" component="th" scope="row">
                                  {(n.periodicidade === ('A')) || (n.periodicidade === ('M'))
                                    ? n.periodicidade === 'M'
                                      ? 'Mensal'
                                      : 'Anual'
                                    : 'Não identificado'}
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                  {'R$ '}{n.valor}
                                </TableCell>
                                <TableCell align="right">
                                <AccessControl
                                    rule={'planos.edit'}
                                    // @ts-ignore
                                    yes={() => (
                                      <IconButton
                                        size="small"
                                        aria-label="Edit"
                                        component="a"
                                        onClick={() =>
                                          handleEditPlanPrice(n)
                                        }
                                      >
                                        <Tooltip title="Editar">
                                          <EditIcon />
                                        </Tooltip>
                                      </IconButton>
                                    )}
                                  />
                                  <AccessControl
                                    rule={'planos.delete'}
                                    // @ts-ignore
                                    yes={() => (
                                      <IconButton
                                        size="small"
                                        aria-label="Delete"
                                        component="a"
                                        onClick={() =>
                                          handleDeletePlanPrice(n)
                                        }
                                      >
                                        <Tooltip title="Deletar">
                                          <DeleteIcon />
                                        </Tooltip>
                                      </IconButton>
                                    )}
                                  />
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      ) : '' }
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  onClick={() => cancelClick()}
                  color="secondary"
                  variant="outlined"
                >
                  Cancelar
                </Button>
                <Button type="submit" color="primary" variant="outlined">
                  {membershipPlanId ? 'Atualizar' : 'Salvar'}
                </Button>
              </CardActions>
            </ValidatorForm>
          </form>
        </Card>
      </Grid>
    </Page>
  )
}

export default MembershipPlanEdit
