import React from 'react'
import { makeStyles } from '@material-ui/styles'
import useRouter from 'utils/useRouter'

//? Own imports
import { Page } from 'components'
import {
  // CancelamentosIngressosDetails,
  CancelamentosIngressosList
} from './components'
import OrderCancelDetails from '../OrderCancelDetails'


const useStyles = makeStyles(() => ({
  root: {}
}))

const CancelamentosIngressos = () => {
  const classes = useStyles()
  const { match } = useRouter()
  const { path } = match

  return (
    <Page title="Lista - Cancelamentos Ingressos">
      {path && path.includes('cancelamentoIngresso') ? (
        <OrderCancelDetails />
      ) : (
        <CancelamentosIngressosList />
      )}
      {/* <CancelamentosIngressosList /> */}
    </Page>
  )
}

export default CancelamentosIngressos
