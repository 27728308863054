const initialState = {
  loading: false,
  usuario: [],
  open: false,
  id: '',
  email: '',
  nome: '',
  username: '',
  senha: '',
  telefone: '',
  emailVerificado: false,
  telefoneVerificado: false,
  emailVendas: false,
  openModalRequestPasswordReset: false
}

export function usuario(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_USER_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'CREATE_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        id: action.user.id,
        email: action.user.email,
        nome: action.user.nome,
        username: action.user.username,
        senha: action.user.senha,
        telefone: action.user.telefone,
        telefoneVerificado: action.user.telefoneVerificado,
        emailVerificado: action.user.emailVerificado,
        emailVendas: action.user.emailVendas
      }
    case 'CREATE_USER_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case 'EDIT_USER_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'EDIT_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        id: action.user.id,
        email: action.user.email,
        nome: action.user.nome,
        username: action.user.username,
        senha: action.user.senha,
        telefone: action.user.telefone,
        telefoneVerificado: action.user.telefoneVerificado,
        emailVerificado: action.user.emailVerificado,
        emailVendas: action.user.emailVendas
      }
    case 'EDIT_USER_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case 'REQUEST_RESET_PASSWORD_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'REQUEST_RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        loading: false,
        openModalRequestPasswordReset: false
      }
    case 'REQUEST_RESET_PASSWORD_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case 'RESET_PASSWORD_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        loading: false
      }
    case 'RESET_PASSWORD_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case 'FETCHED_ALL_USUARIO':
      return {
        ...state,
        usuario: action.usuario
      }
    case 'USUARIO_DETAIL':
      return {
        ...state,
        id: action.id,
        email: action.email,
        nome: action.nome,
        username: action.username,
        senha: action.senha,
        telefone: action.telefone,
        telefoneVerificado: action.telefoneVerificado,
        emailVerificado: action.emailVerificado
      }
    case 'USUARIO_ESTABELECIMENTO_ADD_SUCCESS':
      return {
        ...state,
        usuario: [...state.usuario, action.data.usuario]
      }
    case 'USUARIO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'OPEN_MODAL_REQUEST_PASSWORD_RESET':
      return {
        ...state,
        openModalRequestPasswordReset: action.open
      }
    default:
      return state
  }
}
