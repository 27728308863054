import React from 'react'
import Lottie from 'react-lottie'
import { Box, Typography } from '@material-ui/core'

//? Own imports
import animationData from 'assets/animations/casino-draw.json'

export default function DrawingAnimation() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <Box paddingBottom={4} paddingTop={3}>
      <Lottie
        segments={[25, 100]}
        options={defaultOptions}
        height="80%"
        width="80%"
      />
      <Typography variant="h3" align="center">
        Sorteando ingresso...
      </Typography>
    </Box>
  )
}
