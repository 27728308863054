import React, { useState, useEffect } from 'react'
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core'

//? Own imports
import { UserInfo, ExtraInfo, OtherActions } from './components'
import { MemberPlan } from 'types/api'
import { useParams } from 'react-router-dom'
import { useMemberPlanDetails } from 'hooks-querys'

const useStyles = makeStyles(() => ({
  root: {}
}))

const Summary = () => {
  const classes = useStyles()
  const { id: memberPlanId } = useParams<{ tab: string; id: string }>()
  const {
    data: memberPlanDetails,
    isLoading,
    isFetching,
    refetch
  } = useMemberPlanDetails({ memberPlanId: Number(memberPlanId) })

  if (isLoading)
    return (
      <Box marginY={4}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box mb={2}>
            <Typography variant="h6">Carregando dados...</Typography>
          </Box>
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      </Box>
    )

  return (
    <Grid className={classes.root} container spacing={3}>
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <UserInfo memberPlan={memberPlanDetails || ({} as MemberPlan)} />
      </Grid>
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <ExtraInfo memberPlan={memberPlanDetails || ({} as MemberPlan)} />
      </Grid>
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <OtherActions memberPlan={memberPlanDetails || ({} as MemberPlan)} />
      </Grid>
    </Grid>
  )
}

export default Summary
