import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Line } from 'react-chartjs-2'
import { makeStyles, useTheme } from '@material-ui/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { format, isValid } from 'date-fns'
import { ptBR } from 'date-fns/locale'
const useStyles = makeStyles(() => ({
  root: {
    position: 'relative'
  }
}))

const Chart = (props) => {
  const { className, data: dataProp, ...rest } = props
  const { quantidade, valor, labels } = dataProp
  const classes = useStyles()
  const theme = useTheme()

  const data = (canvas) => {
    const ctx = canvas.getContext('2d')
    const gradient = ctx.createLinearGradient(0, 0, 0, 400)

    gradient.addColorStop(0, fade(theme.palette.secondary.main, 0.2))
    gradient.addColorStop(0.9, 'rgba(255,255,255,0)')
    gradient.addColorStop(1, 'rgba(255,255,255,0)')
    const gradientPrimary = ctx.createLinearGradient(0, 0, 0, 400)

    gradientPrimary.addColorStop(0, fade(theme.palette.primary.main, 0.2))
    gradientPrimary.addColorStop(0.9, 'rgba(255,255,255,0)')
    gradientPrimary.addColorStop(1, 'rgba(255,255,255,0)')

    return {
      datasets: [
        {
          label: 'Ingressos vendidos',
          yAxisID: 'A',
          data: quantidade,
          backgroundColor: gradient,
          borderColor: theme.palette.secondary.main,
          pointBorderColor: '#FFFFFF',
          pointBorderWidth: 2,
          pointRadius: 4,
          pointBackgroundColor: theme.palette.secondary.main
        },
        {
          label: 'Valor arrecadado',
          yAxisID: 'B',
          data: valor,
          backgroundColor: gradientPrimary,
          borderColor: theme.palette.primary.main,
          pointBorderColor: '#FFFFFF',
          pointBorderWidth: 2,
          pointRadius: 4,
          pointBackgroundColor: theme.palette.primary.main
        }
      ],
      labels
    }
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    //animation: false,
    legend: {
      display: false
    },
    layout: {
      padding: 0
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary,
            userCallback: function (value) {
              var isValidDate = isValid(new Date(value.replace(/-/g, '/')))
              return isValidDate
                ? format(new Date(value.replace(/-/g, '/')), 'dd/MM')
                : null
            }
          }
          // scaleLabel: {
          //   display: true,
          //   labelString: 'footer texto'
          // }
        }
      ],
      yAxes: [
        {
          id: 'A',
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            maxTicksLimit: 7
          }
        },
        {
          id: 'B',
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            callback: function (label) {
              return Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(label)
            }
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      caretSize: 10,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        title: function (tooltipItem, data) {
          var dateFormatted = format(
            new Date(
              data['labels'][tooltipItem[0]['index']].replace(/-/g, '/')
            ),
            "dd 'de' MMMM 'de' yyyy'",
            {
              locale: ptBR
            }
          )
          return dateFormatted
        },
        label: function (tooltipItems, data) {
          var dataset = data.datasets[tooltipItems.datasetIndex]
          if (dataset.yAxisID === 'A') {
            return `${dataset.label}: ${tooltipItems.yLabel}`
          } else {
            return `${dataset.label}: ${Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            }).format(tooltipItems.yLabel)}
            `
          }
        }
      }
    }
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Line
        data={data}
        options={options}
        //
      />
    </div>
  )
}

Chart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired
}

export default Chart
