import React from 'react'
import { Skeleton } from '@material-ui/lab'

type SkeletonTableProps = {
  count?: number
  style?: React.CSSProperties
}

const SkeletonTable: React.FC<SkeletonTableProps> = ({
  count = 4,
  style: customStyle = {}
}) => {
  const skeletonStyles: React.CSSProperties = {
    paddingTop: 20,
    lineHeight: '25px',
    ...customStyle
  }

  return (
    <div style={skeletonStyles}>
      {Array.from({ length: count }).map((_, index) => (
        <Skeleton key={index} height={25} animation="wave" />
      ))}
    </div>
  )
}

export default SkeletonTable
