import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Divider,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Link,
  makeStyles,
  Grid,
  Typography,
  CircularProgress
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'

//? Own imports
import { CancelamentoIngresso, Pedido } from 'types/api'
import {
  cepMask,
  formatCurrency,
  getLabelGatewayColor,
  getStatusPedidoColor,
  toLocalDateFormat
} from 'utils'
import { Label } from 'components'
import constants from 'theme/constants'
import {
  useMutationCreateOrderCancel,
  useMutationFinishOrderCancel
} from 'hooks-querys'
import OrderCancelDetails from 'views/OrderCancelDetails'

const useStyles = makeStyles((theme) => ({
  root: {
    ...constants.shadowCard
  },
  content: {
    padding: 0
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}))

const OrderInfo = ({ order }: { order: Pedido }) => {
  const classes = useStyles()
  const usuario = order.carrinhoItem[0]?.usuario
  const mutationCreateOrderCancel = useMutationCreateOrderCancel()
  const mutationFinishOrderCancel = useMutationFinishOrderCancel()

  const getTextCupomDesconto = () => {
    if (order.cupomDesconto) {
      if (order.cupomDesconto.percentualDesconto) {
        return `${order.cupomDesconto.codigo} (${order.cupomDesconto.percentualDesconto}%)`
      }
      if (order.cupomDesconto.valorDesconto) {
        return `${order.cupomDesconto.codigo} (${formatCurrency(
          order.cupomDesconto.valorDesconto
        )})`
      }
      return `${order.cupomDesconto.codigo} (SEM DESCONTO)`
    }
    return 'N/A'
  }

  const createOrderCancel = () => {
    mutationCreateOrderCancel
      .mutateAsync({
        id: 0,
        pedidoId: order.id,
        motivoCancelamentoIngressoId: Number(6),
        dataHora: new Date(),
        dataHoraProcessamento: null,
        statusCancelamentoIngressoId: 0
        //chavePix: '',
        //tipoChavePix:
      })
      .then((data) => {
        if (data && data.id) {
          finishOrderCancel(data)
        }
      })
  }

  const finishOrderCancel = (orderCancel: CancelamentoIngresso) => {
    mutationFinishOrderCancel.mutate(orderCancel)
  }

  return (
    <Card className={clsx(classes.root)}>
      <CardHeader title="Detalhes do pedido" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Usuário</TableCell>
              <TableCell>
                <Link component={RouterLink} to={`/usuario/${usuario?.id}`}>
                  {usuario?.nome}
                </Link>
                {usuario?.endereco ? (
                  <div>
                    {`${usuario?.endereco}, ${usuario?.numero} ${
                      usuario?.complemento ? ` - ${usuario?.complemento}` : ''
                    }${usuario?.bairro ? ` - ${usuario?.bairro}` : ''}`}
                  </div>
                ) : (
                  ''
                )}
                <div>{cepMask(usuario?.cep)}</div>
                {usuario?.cidade ? (
                  <div>
                    {`${usuario?.cidade?.nome} -
                  ${usuario?.cidade?.estado.uf.toUpperCase()}/${
                      usuario?.cidade?.estado?.pais?.nomePt
                    }`}
                  </div>
                ) : (
                  ''
                )}
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>ID</TableCell>
              <TableCell>#{order.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Transação</TableCell>
              <TableCell>{order.transacao}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Forma pagamento</TableCell>
              <TableCell>
                <Typography>{order.formaPagamento?.descricao}</Typography>
                {order.formaPagamento?.id === 1 ? (
                  <Typography variant="caption">
                    {order.parcelas &&
                      `em ${order.parcelas}x ${formatCurrency(
                        order.valorParcelamento
                      )}`}
                  </Typography>
                ) : order.formaPagamento?.id === 2 ? (
                  <Typography variant="caption">
                    {order.linhaDigitavelBoleto &&
                      `${order.linhaDigitavelBoleto}`}
                  </Typography>
                ) : order.formaPagamento?.id === 3 ? (
                  <Typography variant="caption">
                    {order.pixBase64 && `${order.pixBase64}`}
                  </Typography>
                ) : (
                  ''
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Plataforma</TableCell>
              <TableCell>{order.plataforma?.toUpperCase()}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Gateway</TableCell>
              <TableCell>
                {order.gateway && (
                  <Label
                    color={getLabelGatewayColor(order.gateway)}
                    variant="outlined"
                  >
                    {order.gateway.toUpperCase()}
                  </Label>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Data compra</TableCell>
              <TableCell>
                {order && order.dataHora
                  ? toLocalDateFormat(order.dataHora)
                  : '----------'}
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Data validade</TableCell>
              <TableCell>
                {order && order.dataHoraValidade
                  ? toLocalDateFormat(order.dataHoraValidade)
                  : '----------'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cupom de desconto</TableCell>
              <TableCell>{getTextCupomDesconto()}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Valor total (R$)</TableCell>
              <TableCell>{formatCurrency(order.valorTotal)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>
                {order.statusPedido ? (
                  <Label
                    color={getStatusPedidoColor(order.statusPedido)}
                    variant="contained"
                  >
                    {order.statusPedido.descricao}
                  </Label>
                ) : (
                  'Status não identificado'
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
      <CardActions>
        <Grid container spacing={2} justify="space-between">
          {order.statusPedido?.finalizado && (
            <Grid item>
              <Button
                disabled={
                  mutationCreateOrderCancel.isLoading ||
                  mutationFinishOrderCancel.isLoading
                }
                color={'primary'}
                size="small"
                variant="outlined"
                endIcon={
                  mutationCreateOrderCancel.isLoading ||
                  mutationFinishOrderCancel.isLoading ? (
                    <CircularProgress size={18} />
                  ) : (
                    <CheckRoundedIcon />
                  )
                }
                onClick={() => {
                  createOrderCancel()
                }}
              >
                {mutationCreateOrderCancel.isLoading
                  ? 'Criando cancelamento...'
                  : mutationFinishOrderCancel.isLoading
                  ? 'Finalizando cancelamento...'
                  : 'Cancelar Pedido'}
              </Button>
            </Grid>
          )}
          {order.statusPedido?.finalizado && (
            <Grid item>
              <Button>
                <ReceiptIcon className={classes.buttonIcon} />
                Reenviar notificações (dummy)
              </Button>
            </Grid>
          )}
          {order.statusPedido?.pendente && (
            <Grid item>
              <Button>
                <CheckRoundedIcon className={classes.buttonIcon} />
                Finalizar pedido
              </Button>
            </Grid>
          )}
        </Grid>
      </CardActions>
    </Card>
  )
}

export default OrderInfo
