import React from 'react'
import {
  Box,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { useMemberSubscription } from 'hooks-querys'

//? Own imports
import AccessDenied from 'views/Errors/AccessDenied'
import { Page, SkeletonTable } from 'components'
import { AccessControl } from 'components/Utils'
import { Filters, Header, TableMemberFinancial } from './components'
import { useSearchQuery } from 'utils'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  root: {}
}))
const Financial = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const { id: memberPlanId } = useParams<{ tab: string; id: string }>()
  const querySearch = useSearchQuery()

  const { data: memberSubscriptions, isLoading } = useMemberSubscription({
    membroPlanoId: Number(memberPlanId),
    pago: querySearch.get('Pago'),
    page: 1,
    limit: 9999
  })

  return (
    <AccessControl
      rule={'subscricoes.list'}
      // @ts-ignore
      yes={() => (
        <Page
          // @ts-ignore
          className={classes.root}
          title="Gerência de Membros - Subscrições"
        >
          <Grid
            container
            spacing={3}
            alignItems={isMobile ? 'stretch' : 'center'}
            direction={isMobile ? 'column-reverse' : 'row'}
          >
            <Grid item xs={12} md={4}>
              <Filters />
            </Grid>
            {/* <Grid item xs={12} md={8}>
              <Header />
            </Grid> */}
          </Grid>
          {isLoading ? (
            <Box marginY={2}>
              <SkeletonTable style={{ paddingTop: 5 }} />
            </Box>
          ) : (
            <TableMemberFinancial
              items={memberSubscriptions?.items || []}
              mt={2}
            />
          )}
        </Page>
      )}
      // @ts-ignore
      no={() => <AccessDenied />}
    />
  )
}

export default Financial
