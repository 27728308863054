import { useQuery } from 'react-query'
import { Evento } from 'types/api'
import { crudService } from '_services'

const getEvent = async (eventoId: number) => {
  try {
    const { data } = await crudService.get(`eventos/${eventoId}`)
    return data.evento
  } catch (e) {
    return e
  }
}

export default function useEvent(eventoId: number) {
  return useQuery<Evento>(['event-by-id', eventoId], () => getEvent(eventoId), {
    enabled: eventoId > 0
  })
}
