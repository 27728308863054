import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Input,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  colors,
  Box,
  Typography,
  CircularProgress,
  Chip,
  Grid
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded'
import PersonAddDisabledRoundedIcon from '@material-ui/icons/PersonAddDisabledRounded'

//? Own Imports
import { getInitials, history } from 'utils'
import constants from 'theme/constants'
import { useUserConnections}  from 'hooks-querys'
import { UserConnection } from 'types/api'

const useStyles = makeStyles((theme) => ({
  root: {
    ...constants.shadowCard
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  search: {
    padding: theme.spacing(2, 3),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    color: theme.palette.text.secondary
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: '14px'
  },
  avatar: {
    height: 60,
    width: 60
  },
  list: {},
  listItem: {
    flexWrap: 'wrap',
    padding: theme.spacing(1, 2)
  },
  listItemText: {
    marginLeft: theme.spacing(2)
  },
  unconnectedButton: {
    marginLeft: 'auto',
    color: '#fff',
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[900]
    },
    cursor: 'default'
  },
  connectedButton: {
    marginLeft: 'auto',
    color: '#fff',
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    },
    cursor: 'default'
  },
  cardHeaderAction: {
    margin: 'auto'
  }
}))

const Connections = ({ userId }: { userId: number }) => {
  const classes = useStyles()
  const [selectedChip, setSelectedChip] = React.useState<
    'followers' | 'following'
  >('followers')
  const {
    data: userConnections,
    isLoading
  } = useUserConnections(userId, selectedChip)

  const getSubheaderText = () => {
    if (isLoading) {
      return 'Carregando...'
    }
    if (userConnections && userConnections.totalRows > 0) {
      switch (selectedChip) {
        case 'followers':
          return `${userConnections?.totalRows} ${
            userConnections?.totalRows === 1 ? 'seguidor' : 'seguidores'
          }`
        case 'following':
          return `seguindo ${userConnections?.totalRows}`
        default:
          return undefined
      }
    } else {
      return 'Nenhuma conexão encontrada.'
    }
  }
  return (
    <Card className={classes.root}>
      <CardHeader
        title="Conexões"
        subheader={getSubheaderText()}
        classes={{
          action: classes.cardHeaderAction
        }}
        action={
          <Grid
            container
            spacing={1}
            direction="row"
            justify="center"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
          >
            <Grid item>
              <Chip
                label="Seguidores"
                color={selectedChip === 'followers' ? 'primary' : 'default'}
                onClick={() => setSelectedChip('followers')}
              />
            </Grid>
            <Grid item>
              <Chip
                label="Seguindo"
                color={selectedChip === 'following' ? 'primary' : 'default'}
                onClick={() => setSelectedChip('following')}
              />
            </Grid>
          </Grid>
        }
      />
      <Divider />
      <div className={classes.search}>
        <SearchIcon className={classes.searchIcon} color="inherit" />
        <Input
          className={classes.searchInput}
          disableUnderline
          fullWidth
          placeholder="Buscar pessoa ou estabelecimento (dummy)"
        />
      </div>
      <Divider />
      <CardContent className={classes.content}>
        {isLoading ? (
          <Box marginY={4}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Box mb={2}>
                <Typography variant="h6">Carregando dados...</Typography>
              </Box>
              <Box>
                <CircularProgress />
              </Box>
            </Box>
          </Box>
        ) : (
          <PerfectScrollbar>
            {userConnections && userConnections.totalRows > 0 && (
              <List disablePadding className={classes.list}>
                {userConnections?.items.map((connection: UserConnection) => (
                  <ListItem
                    className={classes.listItem}
                    disableGutters
                    key={`${connection.id}+${connection.tipo}`}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt="imagem de perfil"
                        className={classes.avatar}
                        component={RouterLink}
                        src={connection.imagem}
                        to={`/usuario/${connection.id}`}
                      >
                        {getInitials(connection.nome)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      className={classes.listItemText}
                      primary={connection.nome}
                      secondary={`@${connection.username}`}
                    />
                    {!connection.usuarioSegue && (
                      <Button
                        disableElevation
                        disableRipple
                        disableTouchRipple
                        disableFocusRipple
                        className={classes.unconnectedButton}
                        size="small"
                        variant="contained"
                        startIcon={<PersonAddDisabledRoundedIcon />}
                      >
                        Não segue
                      </Button>
                    )}
                    {connection.usuarioSegue && selectedChip === 'followers' && (
                      <Button
                        disableElevation
                        disableRipple
                        disableTouchRipple
                        disableFocusRipple
                        className={classes.connectedButton}
                        size="small"
                        variant="contained"
                        startIcon={<PersonAddRoundedIcon />}
                      >
                        Seguindo
                      </Button>
                    )}
                  </ListItem>
                ))}
              </List>
            )}
          </PerfectScrollbar>
        )}
      </CardContent>
    </Card>
  )
}

export default Connections
