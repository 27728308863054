import React, { useState } from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'
import GlobalStyle from './styles/global'
import Upload from './components/Upload'
import ImageItem from './components/ImageItem'
import { useDispatch, useSelector } from 'react-redux'

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy
} from '@dnd-kit/sortable'
import { albumAction } from '_actions'

export type ImagePreviewClientSide = {
  file: File
  id: string
  name: string
  title?: string
  description?: string
  isCover?: boolean
  readableSize: string
  preview: string
  uploaded: boolean
  isError: boolean
  url: string | null
  retry: boolean
}

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    width: '100%'
  },
  listContainer: {
    marginTop: theme.spacing(1)
  }
}))

function PhotoList() {
  const classes = useStyles()
  const dispatch = useDispatch()
  // @ts-ignore
  const { enabledSortableContext } = useSelector((state) => state.album)
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 4
      }
    })
    // useSensor(KeyboardSensor, {
    //   coordinateGetter: sortableKeyboardCoordinates
    // })
  )
  const handleDragStart = (event: DragStartEvent) => {}

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (over && active) {
      if (active.id !== over.id) {
        const oldIndex = foto.findIndex((item) => item.id === active.id)
        const newIndex = foto.findIndex((item) => item.id === over.id)
        dispatch(albumAction.shortFoto(arrayMove(foto, oldIndex, newIndex)))
      }
    }
  }

  const { foto }: { foto: ImagePreviewClientSide[] } = useSelector(
    // @ts-ignore
    (state) => state.album
  )

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Upload />
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        >
          <Grid container spacing={2} className={classes.listContainer}>
            <SortableContext
              disabled={!enabledSortableContext}
              items={foto}
              strategy={rectSortingStrategy}
            >
              {foto.map((imagePreview) => (
                <Grid item xs={6} sm={3} md={2} lg={2} key={imagePreview.id}>
                  <ImageItem imagePreview={imagePreview} />
                </Grid>
              ))}
            </SortableContext>
          </Grid>
        </DndContext>
      </Box>
      <GlobalStyle />
    </Box>
  )
}

export default PhotoList
