import { useMutation } from 'react-query'
import { PlanPrice } from 'types/api'
import { crudService } from '_services'
import { toast } from 'react-toastify'

const putEditPlanPrice = async (payload: PlanPrice) => {
  let apiEndpoint = 'planosValor/' + payload.id
  const { data } = await crudService.put(apiEndpoint, payload)
  return data
}

export default function useMutationPlanPriceEdit() {
  return useMutation(
    'PlanPrice-edit',
    (payload: PlanPrice) => putEditPlanPrice(payload),
    {
      onError: (error) => {
        toast.error(
          // @ts-ignore
          error.message || 'Erro ao editar valor/periodicidade do plano!'
        )
      },
      onSuccess: () => {
        // @ts-ignore
        toast.success('Valor/periodicidade do plano editado com sucesso!')
      }
    }
  )
}
