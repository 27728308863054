import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Card,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  Button,
  withStyles,
  Divider,
  CircularProgress,
  Grid,
  Backdrop,
  Tooltip,
  Box
} from '@material-ui/core'
import Rating from '@material-ui/lab/Rating'
import { withRouter } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import getInitials from 'utils/getInitials'
import CloudUploadTwoToneIcon from '@material-ui/icons/CloudUploadTwoTone'
import { useConfirm } from 'material-ui-confirm'
import ImageUploading from 'react-images-uploading'
import { uploadImage } from '_services'
import { estabelecimentoAction } from '_actions'
import { errorsUploadImage } from 'utils'
import { abbreviateNumber } from 'utils/abbreviateNumber'
import SocialShare from 'components/Share/Share'
import { onShareEstablishment } from 'utils/linkShare'
import constants from 'theme/constants'

const styles = (theme) => ({
  root: {},
  paper: {
    ...constants.shadowCard
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 130,
    width: 130,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const EstabelecimentoDetails = (props) => {
  const { classes, estabelecimento, className, ...rest } = props
  const [images, setImages] = useState([])
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const confirm = useConfirm()

  const [link, setLink] = useState('')

  const generateLink = useCallback(async () => {
    let link = await onShareEstablishment(estabelecimento)
    setLink(link)
  }, [estabelecimento])

  useEffect(() => {
    generateLink()
  }, [estabelecimento.nome, generateLink])

  const removeImage = () => {
    confirm({
      title: 'Deseja remover a imagem?',
      description: '',
      confirmationText: 'Sim, quero remover!',
      cancellationText: 'Nãããoo!'
    }).then(() => {
      let payload = {
        ...estabelecimento,
        imagem: null
      }
      dispatch(
        estabelecimentoAction.editEstabelecimentoInfo(
          estabelecimento.id,
          payload
        )
      )
      dispatch(estabelecimentoAction.onChangeProps('imagem', null))
    })
  }
  const onChange = (imageList) => {
    // data for submit
    setOpen(true)
    setImages(imageList)
    uploadImage(imageList[0])
      .then((response) => {
        let payload = {
          ...estabelecimento,
          imagem: response
        }
        if (estabelecimento.id !== 0) {
          dispatch(
            estabelecimentoAction.editEstabelecimentoInfo(
              estabelecimento.id,
              payload
            )
          )
        }
        dispatch(estabelecimentoAction.onChangeProps('imagem', response))
        setOpen(false)
      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Backdrop className={classes.backdrop} open={open}>
        <Grid
          container
          spacing={3}
          direction="column"
          justify="center"
          alignItems="center"
          alignContent="center"
          wrap="nowrap"
        >
          <Grid item>
            <CircularProgress color="primary" />
          </Grid>
          <Grid item>
            <Typography variant="h4" color="white">
              Enviando imagem...
            </Typography>
          </Grid>
        </Grid>
      </Backdrop>
      <ImageUploading
        maxFileSize={2000000}
        onError={(errors) => errorsUploadImage(errors)}
        value={images}
        onChange={onChange}
        dataURLKey="data_url"
      >
        {({ onImageUpload, isDragging, dragProps }) => (
          <Card {...rest} className={classes.paper}>
            <CardContent {...dragProps} className={classes.content}>
              {isDragging ? (
                <>
                  <CloudUploadTwoToneIcon
                    color="primary"
                    style={{ fontSize: 80 }}
                  />
                  <Typography variant="h3" color="initial">
                    Solte a imagem aqui!
                  </Typography>
                </>
              ) : (
                <>
                  <Tooltip
                    title={
                      estabelecimento.imagem !== null &&
                      estabelecimento.imagem !== ''
                        ? 'Clique para trocar a imagem!'
                        : 'Clique para adicionar uma imagem!'
                    }
                  >
                    <Avatar
                      onClick={onImageUpload}
                      variant="rounded"
                      className={classes.avatar}
                      src={estabelecimento.imagem}
                    >
                      {getInitials(estabelecimento.nome)}
                    </Avatar>
                  </Tooltip>
                  <Typography
                    align="center"
                    className={classes.nome}
                    gutterBottom
                    variant="h3"
                  >
                    {estabelecimento.nome}
                  </Typography>
                  {estabelecimento.id != 0 && (
                    <>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems={'center'}
                        mb={3}
                      >
                        <SocialShare
                          evento={estabelecimento}
                          link={link}
                          place={true}
                        />
                      </Box>
                    </>
                  )}
                  <Rating
                    name="rating"
                    value={estabelecimento.mediaAvaliacoes}
                    precision={0.1}
                    readOnly
                  />
                  <Typography component="h2" variant="overline" gutterBottom>
                    {estabelecimento.totalAvaliacoes > 0
                      ? `${abbreviateNumber(
                          estabelecimento.totalAvaliacoes
                        )} avaliações`
                      : 'Nenhuma avaliação'}
                  </Typography>
                  <Typography noWrap component="h2" variant="overline">
                    {estabelecimento.totalSeguidores > 0
                      ? `${abbreviateNumber(
                          estabelecimento.totalSeguidores
                        )} seguidores`
                      : 'Nenhum seguidor'}
                  </Typography>
                  {/* <Typography color="textPrimary" variant="body1">
                    Capacidade: {estabelecimento.capacidade} pessoas.
                  </Typography>
                  <Typography color="textPrimary" variant="body2">
                    Categoria
                  </Typography> */}
                </>
              )}
            </CardContent>
            <Divider />
            <CardActions className={classes.uploadButton}>
              <Button color="primary" variant="text" onClick={onImageUpload}>
                {estabelecimento.imagem !== null &&
                estabelecimento.imagem !== ''
                  ? 'Trocar imagem'
                  : 'Carregar imagem'}
              </Button>
              {estabelecimento.imagem !== null &&
                estabelecimento.imagem !== '' && (
                  <Button variant="text" onClick={removeImage}>
                    Remover imagem
                  </Button>
                )}
            </CardActions>
          </Card>
        )}
      </ImageUploading>
    </div>
  )
}

EstabelecimentoDetails.propTypes = {
  className: PropTypes.string,
  estabelecimento: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    estabelecimento: state.estabelecimento
  }
}
const connectedEstabelecimentoDetailsPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(EstabelecimentoDetails))
)
export default connectedEstabelecimentoDetailsPage
