import { colors } from '@material-ui/core'
import { Gateway } from 'types/api'

export const getLabelGatewayColor = (gateway: Gateway) => {
  if (gateway === 'pagarMe') {
    return colors.lightGreen[800]
  }
  if (gateway === 'mercadopago') {
    return colors.yellow[500]
  }
  if (gateway === 'juno') {
    return colors.blue[800]
  }
  if (gateway === 'transferência') {
    return colors.orange[500]
  }
  return colors.grey[500]
}
