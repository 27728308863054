import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { cupomDescontoAction, eventoAction, loteAction } from '_actions'
import PropTypes from 'prop-types'
import {
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  Box,
  CircularProgress,
  Select,
  InputLabel,
  FormControl,
  Switch,
  InputAdornment,
  TextField,
  useTheme,
  Chip,
  MenuItem
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { useLocation, useParams, withRouter } from 'react-router-dom'
import { history } from '../../utils'
import ShareIcon from '@material-ui/icons/Share'
import { useConfirm } from 'material-ui-confirm'
import { toLocalDateFormat } from 'utils'
import { AccessControl } from '../../components/Utils/AccessControl'
import DoneRoundedIcon from '@material-ui/icons/DoneRounded'
import DoneAllRoundedIcon from '@material-ui/icons/DoneAllRounded'
import { Page } from 'components'
import EmptyFirstCupomDesconto from './components/EmptyFirstCupomDesconto/EmptyFirstCupomDesconto'
import constants from 'theme/constants'
import { toast } from 'react-toastify'
import { shareEventCouponCode } from 'utils/linkShare'
import { Autocomplete } from '@material-ui/lab'
import { useEvents } from 'hooks-querys'

const styles = (theme) => ({
  root: {},
  paper: {
    ...constants.shadowCard
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
})

function useSearchQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const CuponsDescontoList = (props) => {
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const [selectedEvento, setSelectedEvento] = useState({
    id: 0,
    nome: '',
    dataHoraInicio: null,
    dataHoraFim: null
  })
  const [eventoInputValue, setEventoInputValue] = useState('')
  const [loteId, setLoteId] = useState(0)
  const [showPastEvents, setShowPastEvents] = useState(false)
  const estabelecimentoId = useSelector(
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const { cupomDesconto, isLoading } = useSelector(
    (state) => state.cupomDesconto
  )
  const { lote: lotes } = useSelector((state) => state.evento)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const querySearch = useSearchQuery()
  const {
    data: events,
    isLoading: isLoadingEvents,
    isFetching: isFetchingEvents
  } = useEvents(estabelecimentoId, showPastEvents, isMobile)

  useEffect(() => {
    setSelectedEvento({
      id: 0,
      nome: '',
      dataHoraInicio: null,
      dataHoraFim: null
    })
    setLoteId(0)
    setShowPastEvents(false)
    setEventoInputValue('')
  }, [estabelecimentoId])

  useEffect(() => {
    if (isMobile) {
      dispatch(eventoAction.getAllEventosByEstabelecimento(estabelecimentoId))
    } else {
      dispatch(
        eventoAction.getEventoByEstabelecimento(
          estabelecimentoId,
          showPastEvents
        )
      )
    }
  }, [estabelecimentoId, showPastEvents, isMobile])

  useEffect(() => {
    dispatch(
      cupomDescontoAction.getListCupomDesconto(
        loteId,
        selectedEvento ? selectedEvento.id : 0,
        estabelecimentoId
      )
    )
  }, [selectedEvento, loteId, estabelecimentoId])

  useEffect(() => {
    if (selectedEvento === null || selectedEvento.id === 0) {
      setLoteId(0)
    }
  }, [selectedEvento])

  useEffect(() => {
    if (selectedEvento && selectedEvento.id > 0) {
      dispatch(loteAction.getLotesByEvento(selectedEvento.id))
    }
  }, [selectedEvento])

  useEffect(() => {
    //? função executada para buscar o evento o qual esta na url (?eventId=)
    //? se não existir evento na url não faz nada

    const eventId = querySearch.get('eventoId') || 0
    if (eventId && Number(eventId) > 0) {
      const event =
        events &&
        events.length > 0 &&
        events.find((e) => e.id === Number(eventId))
      //? se encontrar o evento na url, seta o evento selecionado,
      //? se não encontrar, troca a o estado showPastEvents para true,
      //? pois o evento informado pode ser um evento passado, e não existir na lista de eventos;
      //? com isso, a lista de evento efetua um novo fetch para buscar os eventos passados,
      //? e gera um novo efeito colateral caindo nesse useEffect novamente

      if (event) {
        setSelectedEvento(event)
      } else if (selectedEvento === null) {
        setShowPastEvents(!showPastEvents)
      }
    }
  }, [events])

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir esse cupom de desconto?',
      description: 'Confirmando essa operação, ele não valerá mais.',
      confirmationText: 'Sim, excluir!',
      cancellationText: 'Nãããoo!'
    }).then(() => {
      dispatch(cupomDescontoAction.deleteCupomDescontoById(id))
    })
  }

  return (
    <Page className={classes.root} title="Cupons de Desconto">
      <FormControl className={classes.formControl}>
        <Autocomplete
          loading={isLoadingEvents}
          id="combo-box-evento"
          options={events || []}
          value={selectedEvento}
          onChange={(event, newValue, reason) => {
            setSelectedEvento(newValue)
            if (reason === 'clear') {
              //? se o usuário clicar no botão limpar
              setEventoInputValue('')
              history.push('/cupomDescontos')
            }
          }}
          getOptionLabel={(evento) =>
            evento.nome &&
            `${evento.nome} ${
              evento.dataHoraInicio
                ? ` - (${toLocalDateFormat(evento.dataHoraInicio)})`
                : ''
            }`
          }
          placeholder="Selecione o evento..."
          onInputChange={(event, newInputValue) => {
            newInputValue !== null
              ? setEventoInputValue(newInputValue)
              : setEventoInputValue('')
          }}
          inputValue={eventoInputValue}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Selecione o evento"
              placeholder="Selecione o evento..."
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {!isMobile && (
                      <InputAdornment position="end">
                        <>
                          {isFetchingEvents && (
                            <CircularProgress
                              style={{ marginRight: 10 }}
                              size={20}
                              color="primary"
                            />
                          )}
                          <Switch
                            color="primary"
                            onChange={(event) => {
                              setShowPastEvents(event.target.checked)
                            }}
                            checked={showPastEvents}
                            size="small"
                            inputProps={{
                              'aria-label': 'Listar eventos encerrados'
                            }}
                          />
                          <Chip
                            variant="default"
                            size="small"
                            label="Listar eventos encerrados"
                            color={showPastEvents ? 'primary' : 'default'}
                            onClick={() => {
                              setShowPastEvents(!showPastEvents)
                            }}
                          />
                        </>
                      </InputAdornment>
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </FormControl>
      {selectedEvento && selectedEvento.id > 0 && lotes && lotes.length > 0 && (
        <FormControl className={classes.formControl}>
          <InputLabel shrink id="lote-select-label">
            Filtrar por lote
          </InputLabel>
          <Select
            className={classes.selectEmpty}
            variant="outlined"
            id="loteId"
            value={loteId}
            onChange={(event) => setLoteId(event.target.value)}
            fullWidth
            displayEmpty
            labelId="lote-select-label"
          >
            {loteId === 0 ? (
              <MenuItem disabled value={0}>
                <em>Selecione o lote...</em>
              </MenuItem>
            ) : (
              <MenuItem value={0}>
                <strong>Limpar seleção</strong>
              </MenuItem>
            )}
            {lotes &&
              lotes.length > 0 &&
              lotes.map((row) => (
                <MenuItem key={row.id} value={row.id}>
                  {row.tipoIngresso
                    ? `${row.tipoIngresso.descricao} - ${row.descricao}`
                    : row.descricao}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
      {isLoading ? (
        <Box marginY={4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box mb={2}>
              <Typography variant="h6">Carregando dados...</Typography>
            </Box>
            <Box>
              <CircularProgress />
            </Box>
          </Box>
        </Box>
      ) : (
        // <TableUtilizacao cuponsDescontoResult={utilizacaoCuponsDesconto} />
        <AccessControl
          rule={'cuponsDesconto.list'} //permissão necessária para acessar conteúdo
          yes={() => (
            <div>
              {cupomDesconto && cupomDesconto.length > 0 ? (
                <Typography variant="body2" align="left" gutterBottom>
                  Total de dados encontrados: <b>{cupomDesconto.length}</b>
                </Typography>
              ) : null}
              {undefined !== cupomDesconto && cupomDesconto.length ? (
                <TableContainer component={Paper} className={classes.paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Código</TableCell>
                        <TableCell align="center">% Desconto</TableCell>
                        <TableCell align="center">Valor Desconto</TableCell>
                        <TableCell align="center">Quantidade Mínima</TableCell>
                        <TableCell align="center">
                          Limite de Utilização
                        </TableCell>
                        <TableCell align="center">
                          Data Início Validade
                        </TableCell>
                        <TableCell align="center">
                          Data Término Validade
                        </TableCell>
                        <TableCell align="center">Evento</TableCell>
                        <TableCell align="center">Lote</TableCell>
                        <TableCell align="right">Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cupomDesconto.map((n) => {
                        return (
                          <TableRow hover key={n.id}>
                            <TableCell component="th" scope="row">
                              {n.codigo}
                            </TableCell>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {n.percentualDesconto !== null &&
                              n.percentualDesconto !== ''
                                ? `${n.percentualDesconto}%`
                                : '-----'}
                            </TableCell>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {n.valorDesconto !== null &&
                              n.valorDesconto !== ''
                                ? Intl.NumberFormat('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                  }).format(n.valorDesconto)
                                : '-----'}
                            </TableCell>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {n.quantidadeMinima}
                            </TableCell>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {n.quantidade}
                            </TableCell>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {toLocalDateFormat(n.dataInicioValidade)}
                            </TableCell>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {toLocalDateFormat(n.dataTerminoValidade)}
                            </TableCell>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {n.eventoId ? (
                                <Tooltip title="Cadastrado para um evento">
                                  <DoneRoundedIcon color="primary" />
                                </Tooltip>
                              ) : (
                                <Tooltip title="Cadastrado para todos eventos do estabelecimento">
                                  <DoneAllRoundedIcon color="primary" />
                                </Tooltip>
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {n.loteId ? (
                                <Tooltip title="Cadastrado para um lote">
                                  <DoneRoundedIcon color="primary" />
                                </Tooltip>
                              ) : (
                                <Tooltip title="Cadastrado para todos lotes do evento">
                                  <DoneAllRoundedIcon color="primary" />
                                </Tooltip>
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <Tooltip title="Copiar link para evento com aplicação automática do cupom">
                                {selectedEvento && selectedEvento.id > 0 ? (
                                  <IconButton
                                    size="small"
                                    aria-label="CopyLink"
                                    component="a"
                                    disabled={showPastEvents}
                                    onClick={async () => {
                                      const link = await shareEventCouponCode(
                                        selectedEvento,
                                        n
                                      )
                                      navigator.clipboard.writeText(link)
                                      toast.success(
                                        `Link para o evento com aplicação automática do cupom ${n.codigo} copiado com sucesso! 🎉`,
                                        {
                                          autoClose: 4000
                                        }
                                      )
                                    }}
                                  >
                                    <ShareIcon fontSize="small" />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    size="small"
                                    aria-label="CopyLink"
                                    component="a"
                                    onClick={async () => {
                                      toast.warn(
                                        'Selecione um evento para poder copiar o link com a aplicação automática deste cupom!',
                                        {
                                          autoClose: 8000
                                        }
                                      )
                                    }}
                                  >
                                    <ShareIcon fontSize="small" />
                                  </IconButton>
                                )}
                              </Tooltip>
                              <AccessControl
                                rule={'cuponsDesconto.edit'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    size="small"
                                    aria-label="Edit"
                                    component="a"
                                    onClick={() =>
                                      history.push(
                                        `/cupomDesconto/cadastro/${n.id}`
                                      )
                                    }
                                  >
                                    <Tooltip title="Editar">
                                      <EditIcon />
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                              <AccessControl
                                rule={'cuponsDesconto.delete'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    size="small"
                                    aria-label="Delete"
                                    onClick={() => handleClick(n.id)}
                                  >
                                    <Tooltip title="Excluir">
                                      <DeleteIcon />
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <EmptyFirstCupomDesconto />
              )}
            </div>
          )}
        />
      )}
    </Page>
  )
}
CuponsDescontoList.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    cupomDesconto: state.cupomDesconto,
    estabelecimentoId: state.usuarioEstabelecimento.estabelecimentoId
  }
}
const connectedCupomDescontoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(CuponsDescontoList))
)
export { connectedCupomDescontoPage as CuponsDescontoList }
