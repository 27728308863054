import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import { Checkbox, Typography } from '@material-ui/core'
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
var pluralize = require('pluralize')

const useStyles = makeStyles((theme) => ({
  table: {},
  header: {
    paddingBottom: theme.spacing(1)
  }
}))

export default function TablePermissoes(props) {
  const classes = useStyles()
  const { permissoes, onCheckedPermissao } = props

  return (
    <>
      <div className={classes.header}>
        {permissoes.length !== 0 && (
          <Typography align="left" variant="caption" color="textPrimary">
            {`${pluralize('permissões', permissoes.length, true)} ${pluralize(
              'encontrada',
              permissoes.length
            )}:`}
          </Typography>
        )}
      </div>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">Permissão</TableCell>
              <TableCell align="right">Permitido</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {permissoes.map((row) => (
              <TableRow
                style={
                  row.permissao.nome.includes('.')
                    ? {}
                    : { backgroundColor: 'rgba(225, 194, 10, 0.1)' }
                }
                hover
                key={row.id}
              >
                <TableCell component="th" scope="row">
                  {row.permissao.descricao}
                </TableCell>
                <TableCell align="right">
                  <Checkbox
                    style={{ height: '25px' }}
                    icon={<LockOutlinedIcon fontSize="small" />}
                    checkedIcon={<LockOpenOutlinedIcon fontSize="small" />}
                    size="small"
                    checked={row.permitido}
                    color="primary"
                    value={row.id}
                    onChange={() => onCheckedPermissao(row.id)}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

TablePermissoes.propTypes = {
  permissoes: PropTypes.array.isRequired,
  onCheckedPermissao: PropTypes.func
}
