import { crudService } from '../_services'
import { toast } from 'react-toastify'

export const permissaoUsuarioAction = {
  getPermissaoUsuario,
  getPermissaoUsuarioByChave,
  onChangePropsList,
  createPermissaoUsuario,
  clear,
  handleCheckedPermitido,
  handleCheckAllPermissaoUsuario,
  handleChangeCheckAll
}
function getPermissaoUsuario(id) {
  return (dispatch) => {
    let apiEndpoint = 'permissoesUsuarios/' + id
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changePermissaoUsuariosList(response.data, 1, id))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function getPermissaoUsuarioByChave(chave) {
  return (dispatch) => {
    let apiEndpoint = 'permissoesUsuarios/' + chave
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(editPermissaoUsuariosDetails(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createPermissaoUsuario(payload) {
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'permissoesUsuarios'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request.status === 200) {
          var responseJSON = JSON.parse(response.request.responseText)
          dispatch(success(responseJSON))
          toast.success('Permissões alteradas com sucesso! 👍', {})
        } else {
          dispatch(failure(response))
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        return err
      })
  }

  function request(payload) {
    return { type: 'PERMISSAO_USUARIO_CREATE_REQUEST', payload }
  }

  function success(responseJSON) {
    return {
      type: 'PERMISSAO_USUARIO_CREATE_SUCCESS',
      response: responseJSON
    }
  }

  function failure(error) {
    return { type: 'PERMISSAO_USUARIO_CREATE_FAILURE', error }
  }
}
function onChangePropsList(props, event, position) {
  return (dispatch) => {
    dispatch(handleOnChangePropsList(props, event.target.checked, position))
  }
}
export function changePermissaoUsuariosList(
  permissaoUsuario,
  totalPages,
  usuarioId
) {
  return {
    type: 'FETCHED_ALL_PERMISSAO_USUARIO',
    permissaoUsuario: permissaoUsuario,
    totalPages: totalPages,
    usuarioId: usuarioId
  }
}
export function clear() {
  return {
    type: 'PERMISSAO_USUARIO_CLEAR'
  }
}

export function handleCheckedPermitido(idPermissaoUsuario) {
  return {
    type: 'HANDLE_CHECKED_PERMITIDO_PERMISSAO_USUARIO',
    id: idPermissaoUsuario
  }
}
export function handleChangeCheckAll() {
  return {
    type: 'HANDLE_CHANGE_CHECK_ALL_PERMISSAO_USUARIO'
  }
}
export function handleCheckAllPermissaoUsuario(checked) {
  return {
    type: 'HANDLE_CHECK_ALL_PERMISSAO_USUARIO',
    checked: checked
  }
}

export function handleOnChangePropsList(props, value, position) {
  return {
    type: 'HANDLE_ON_CHANGE_LIST_PERMISSAO_USUARIO',
    props: props,
    value: value,
    position: position
  }
}
export function editPermissaoUsuariosDetails(permissaoUsuario) {
  return {
    type: 'PERMISSAO_USUARIO_DETAIL',
    id: permissaoUsuario.id,
    idPermissao: permissaoUsuario.idPermissao,
    idUsuario: permissaoUsuario.idUsuario,
    permitido: permissaoUsuario.permitido
  }
}
export function createUserInfo() {
  return {
    type: 'PERMISSAO_USUARIO_CREATED_SUCCESSFULLY'
  }
}
export function deletePermissaoUsuariosDetails() {
  return {
    type: 'DELETED_PERMISSAO_USUARIO_DETAILS'
  }
}
