import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Grid
} from '@material-ui/core'

import { Chart } from './components'
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import { getRandomColorsChart } from 'utils'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    ...constants.shadowCard
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  chartContainer: {
    padding: theme.spacing(3),
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  chart: {
    height: 281
  },
  title: {
    fontSize: '10px'
  },
  statsContainer: {
    display: 'flex'
  },
  statsItem: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(1.5, 2),
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  }
}))

const PaymentTypeSales = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()
  const { isLoading } = useSelector((state) => state.dashboard)
  const { graficoVendasPorFormaPagamento } = useSelector(
    (state) => state.dashboard.dashboardAdmin
  )
  const { showValues } = useSelector((state) => state.global)

  const percentageCalculator = (value, total) => {
    return (value * 100) / total
  }

  const dataProp = {
    ...graficoVendasPorFormaPagamento,
    colors: getRandomColorsChart(graficoVendasPorFormaPagamento.options.length)
  }
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Vendas por forma de pagamento" />
      <Divider />
      <CardContent className={classes.content}>
        <div className={classes.chartContainer}>
          {showValues &&
          !isLoading &&
          dataProp &&
          dataProp.options &&
          dataProp.options.length > 0 ? (
            <Chart className={classes.chart} data={dataProp} />
          ) : (
            <Skeleton
              variant="circle"
              animation={isLoading ? 'pulse' : 'false'}
              height={281}
              width={281}
            />
          )}
        </div>
        <Divider />
        <Grid justify="center" container>
          {dataProp && dataProp.options && dataProp.options.length > 0 ? (
            dataProp.options.map((plataforma, index) => (
              <Grid item xs className={classes.statsItem} key={plataforma}>
                <Typography
                  align="center"
                  component="h5"
                  gutterBottom
                  variant="overline"
                  className={classes.title}
                >
                  {plataforma}
                </Typography>
                <Typography
                  align="center"
                  variant="h5"
                  style={{ color: dataProp.colors[index][400] }}
                >
                  {showValues && !isLoading ? (
                    percentageCalculator(
                      dataProp.series[0][index],
                      dataProp.series[0].reduce(
                        (a, b) => a + b // soma todos os valores
                      )
                    ).toLocaleString('pt-BR', {
                      maximumFractionDigits: 2
                    }) + '%'
                  ) : (
                    <Skeleton
                      animation={isLoading ? 'pulse' : 'false'}
                      width="100%"
                    />
                  )}
                </Typography>
              </Grid>
            ))
          ) : (
            <Grid item xs className={classes.statsItem}>
              <Typography align="center" variant="h6">
                Nenhum resultado encontrado
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

PaymentTypeSales.propTypes = {
  className: PropTypes.string
}

export default PaymentTypeSales
