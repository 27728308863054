import React from 'react'
// import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { history } from '../utils'
import clsx from 'clsx'
import { AccessControl } from '../components/Utils/AccessControl'

const useStyles = makeStyles(() => ({
  root: {}
}))

const TitleContent = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="flex-end" container justify="space-between" spacing={3}>
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline">
            {props.subTitle}
          </Typography>
          <Typography component="h1" variant="h3">
            {props.title}
          </Typography>
        </Grid>
        <AccessControl
          rule={props.rule} //permissão necessária para acessar conteúdo
          yes={() => (
            <Grid item>
              <Grid item xs={6} align="right">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push(`${props.href}`)}
                >
                  NOVO
                </Button>
              </Grid>
            </Grid>
          )}
        />
      </Grid>
      <br></br>
      {props.length !== undefined && props.length !== 0 ? (
        <Grid item>
          <Typography variant="body2" align="left" gutterBottom>
            Total de dados encontrados: <b>{props.length}</b>
          </Typography>
        </Grid>
      ) : (
        <Typography variant="body2" align="left" gutterBottom>
          <b>{'Nenhum dado encontrado.'}</b>
        </Typography>
      )}
    </div>
  )
}

TitleContent.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  buttonOnClick: PropTypes.object.isRequired,
  rule: PropTypes.string
}
export default TitleContent
