import { crudService } from '../_services'

export const tipoUsuarioAction = {
  getTipoUsuario,
  getTipoUsuarioSelect,
  addTipoUsuario,
  getTipoUsuarioById,
  onChangeProps,
  editTipoUsuarionfo,
  editTiposUsuariosDetails,
  createTipoUsuario,
  deleteTipoUsuarioById,
  clear,
  clearAll
}

function getTipoUsuario() {
  return (dispatch) => {
    let apiEndpoint = 'tiposUsuarios'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeTiposUsuariosList(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getTipoUsuarioSelect() {
  return (dispatch) => {
    let apiEndpoint = 'tiposUsuarios/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeTiposUsuariosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createTipoUsuario(payload) {
  return (dispatch) => {
    let apiEndpoint = 'tiposUsuarios/'
    crudService.post(apiEndpoint, payload).then(() => {
      dispatch(createTipoUsuarioInfo())
      dispatch(clear())
    })
  }
}

function getTipoUsuarioById(id) {
  return (dispatch) => {
    let apiEndpoint = 'tiposUsuarios/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editTiposUsuariosDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    dispatch(handleOnChangeProps(props, event.target.value))
  }
}

function editTipoUsuarionfo(id, payload) {
  return (dispatch) => {
    let apiEndpoint = 'tiposUsuarios/' + id
    crudService.put(apiEndpoint, payload).then(() => {
      dispatch(updatedTipoUsuarioInfo())
      dispatch(clear())
    })
  }
}

function deleteTipoUsuarioById(id) {
  return (dispatch) => {
    let apiEndpoint = 'tiposUsuarios/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteTipoUsuarioById())
      dispatch(getTipoUsuario())
    })
  }
}

export function addTipoUsuario(payload) {
  return [{ type: 'ADD_TIPOUSUARIO', tipoUsuario: payload }, clear()]
}

export function changeTiposUsuariosList(tipoUsuario) {
  return {
    type: 'FETCHED_ALL_TIPOUSUARIO',
    tipoUsuario: tipoUsuario
  }
}

export function clear() {
  return {
    type: 'TIPOUSUARIO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'TIPOUSUARIO_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'TIPOUSUARIO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editTiposUsuariosDetails(tipoUsuario) {
  return {
    type: 'TIPOUSUARIO_DETAIL',
    id: tipoUsuario.id,
    descricao: tipoUsuario.descricao
  }
}

export function updatedTipoUsuarioInfo() {
  return {
    type: 'TIPOUSUARIO_UPDATED'
  }
}

export function createTipoUsuarioInfo() {
  return {
    type: 'TIPOUSUARIO_CREATED_SUCCESSFULLY'
  }
}

export function deleteTiposUsuariosDetails() {
  return {
    type: 'DELETED_TIPOUSUARIO_DETAILS'
  }
}
