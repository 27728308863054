import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { movimentacaoFinanceiraAction, ingressoAction } from '_actions'
import { MessageData } from 'components'

const SuccessMessage = () => {
  const dispatch = useDispatch()
  const estabelecimentoId = useSelector(
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  useEffect(() => {
    return () => {
      dispatch(movimentacaoFinanceiraAction.clear())
      dispatch(
        ingressoAction.getIngressosLiberadosByEstabelecimento(estabelecimentoId)
      )
    }
  }, [])
  return (
    <MessageData
      text="Solicitação de retirada realizada com sucesso! "
      srcImage="/images/success-transfer.svg"
      buttonText="Consultar minhas retiradas"
      buttonHref="retiradas"
    />
  )
}

export default SuccessMessage
