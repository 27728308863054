import React, { useEffect, useState } from 'react'
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
  Divider
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { eventoAction, avaliacaoAction, usuarioAction } from '_actions'
import { useDidMountEffect } from 'utils/useDidMountEffect'
import { useParams } from 'react-router-dom'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  divider: {
    height: 28,
    margin: 4
  }
}))

const SelectEvent = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const estabelecimentoId = useSelector(
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const eventos = useSelector((state) => state.evento.evento)
  const [eventoId, setEventoId] = useState('')
  const params = useParams()

  const handleChange = (event) => {
    setEventoId(event.target.value)
    setTexto('')
  }
  const [texto, setTexto] = useState('')

  const handleChangeTexto = (event) => {
    setTexto(event.target.value)
  }

  function search() {
    const payload = { EventoId: eventoId, SearchText: texto }
    eventoId > 0 &&
      dispatch(avaliacaoAction.searchAvaliacaoByEventId(payload, eventoId))
  }

  const handleSubmitSearch = (event) => {
    event.preventDefault()
    search()
  }

  const clearTextSearch = () => {
    setTexto('')
  }

  useDidMountEffect(() => {
    texto === '' && search()
  }, [texto])

  useEffect(() => {
    if (eventoId === '') {
      dispatch(avaliacaoAction.clearAll())
    }
    dispatch(eventoAction.getEventoByEstabelecimento(estabelecimentoId, true))
    dispatch(usuarioAction.getUsuario())
  }, [estabelecimentoId])

  useDidMountEffect(() => {
    setEventoId('')
    dispatch(avaliacaoAction.clearAll())
    setTexto('')
  }, [estabelecimentoId])

  useEffect(() => {
    !!params.eventoId && params.eventoId > 0 && setEventoId(params.eventoId)
  }, [params.eventoId])

  useDidMountEffect(() => {
    search()
  }, [eventoId])

  useEffect(() => {
    return () => {
      dispatch(avaliacaoAction.clearAll())
    }
  }, [])

  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl}>
            <InputLabel shrink id="evento-select-label">
              Evento
            </InputLabel>
            <Select
              className={classes.selectEmpty}
              variant="outlined"
              id="eventoId"
              value={eventoId}
              onChange={handleChange}
              fullWidth
              displayEmpty
              labelId="evento-select-label"
            >
              <MenuItem disabled value="">
                <em>Selecione o evento...</em>
              </MenuItem>
              {eventos !== undefined &&
                eventos.map((row) => (
                  <MenuItem key={row.id} value={row.id}>
                    {row.nome}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        {eventoId > 0 && (
          <>
            <Grid item xs={12} sm={12}>
              <form autoComplete="off" onSubmit={handleSubmitSearch}>
                <TextField
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {texto.length >= 1 && (
                          <>
                            <IconButton
                              onClick={clearTextSearch}
                              className={classes.iconButton}
                            >
                              <CloseIcon opacity="0.8" />
                            </IconButton>
                            <Divider
                              className={classes.divider}
                              orientation="vertical"
                            />
                          </>
                        )}
                        <IconButton
                          type="submit"
                          aria-label="pesquisar por mensagem/cliente"
                        >
                          <SearchRoundedIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  fullWidth
                  required
                  value={texto}
                  onChange={handleChangeTexto}
                  variant="outlined"
                  id="textForSearch"
                  label="Buscar"
                  placeholder="Busque por palavras em Mensagem..."
                />
              </form>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  )
}

export default SelectEvent
