const initialState = {
  anchor: 'left',
  conquista: [],
  open: false,
  id: 0,
  nome: '',
  descricao: '',
  experiencia: null,
  imagem: null
}

export function conquista(state = initialState, action) {
  switch (action.type) {
    case 'CONQUISTA_FETCHED_ALL':
      return {
        ...state,
        conquista: action.conquista
      }
    case 'CONQUISTA_DETAIL':
      return {
        ...state,
        id: action.id,
        nome: action.nome,
        descricao: action.descricao,
        experiencia: action.experiencia,
        imagem: action.imagem
      }
    case 'CONQUISTA_UPDATED':
      return state
    case 'CONQUISTA_CLEAR':
      return initialState
    case 'CONQUISTA_CLEAR_ALL':
      return {
        ...state,
        conquista: []
      }
    case 'CONQUISTA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'DUPLICATE_CONQUISTA_REQUEST':
      return {
        state
      }
    case 'DUPLICATE_CONQUISTA_SUCCESS':
      return {
        ...state,
        id: 0,
        nome: action.conquista.nome,
        descricao: action.conquista.descricao,
        experiencia: action.conquista.experiencia,
        imagem: action.conquista.imagem
      }
    case 'DUPLICATE_CONQUISTA_FAILURE':
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}
