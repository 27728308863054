const initialState = {
  foto: [],
  fotoCapaId: null,
  enabledSortableContext: false
}

export function album(state = initialState, action) {
  switch (action.type) {
    case 'ADD_PHOTO':
      return {
        ...state,
        foto: [...state.foto, ...action.foto]
      }
    case 'SHORT_PHOTO':
      return {
        ...state,
        foto: [...action.foto]
      }
    case 'UPDATE_PHOTO':
      return {
        ...state,
        foto: state.foto.map((fotoItem) => {
          return action.data.id === fotoItem.id
            ? { ...fotoItem, ...action.data }
            : fotoItem
        })
      }
    case 'DELETE_PHOTO':
      return {
        ...state,
        foto: state.foto.filter((item) => action.foto.id !== item.id)
      }
    case 'DELETE_ALL_PHOTO':
      return initialState
    case 'SET_FOTO_CAPA_ID':
      return {
        ...state,
        foto: state.foto.map((fotoItem) => {
          return state.fotoCapaId === fotoItem.id
            ? { ...fotoItem, isCover: false }
            : fotoItem
        }),
        fotoCapaId: action.fotoCapaId
      }
    case 'TOGGLE_ENABLED_SORTABLE_CONTEXT':
      return {
        ...state,
        enabledSortableContext: !state.enabledSortableContext
      }
    default:
      return state
  }
}
