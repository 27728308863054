const initialState = {
  ingresso: [],
  ingressoALiberar: [],
  id: '',
  clienteId: '',
  loteId: '',
  dataHoraCompra: '',
  formaPagamentoId: '',
  status: '',
  dataHoraCancelamento: '',
  valor: '',
  validado: '',
  dataHoraValidacao: '',
  qrcode: '',
  cupomDescontoId: '',
  transacao: '',
  excluido: false,
  isLoading: false
}

export function ingresso(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_INGRESSO':
      return {
        ...state,
        ingresso: action.ingresso
      }
    case 'FETCHED_INGRESSOS_ESTABELECIMENTO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'FETCHED_INGRESSOS_ESTABELECIMENTO_SUCCESS':
      return {
        ...state,
        isLoading: false,
        ingresso: action.ingressosList
      }
    case 'FETCHED_INGRESSOS_ESTABELECIMENTO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'GET_INGRESSOS_LIBERADOS_BY_ESTABELECIMENTO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'GET_INGRESSOS_LIBERADOS_BY_ESTABELECIMENTO_SUCCESS':
      return {
        ...state,
        isLoading: false,
        ingresso: action.ingressos
      }
    case 'GET_INGRESSOS_LIBERADOS_BY_ESTABELECIMENTO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'GET_INGRESSOS_NAO_LIBERADOS_BY_ESTABELECIMENTO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'GET_INGRESSOS_NAO_LIBERADOS_BY_ESTABELECIMENTO_SUCCESS':
      return {
        ...state,
        isLoading: false,
        ingressoALiberar: action.ingressos
      }
    case 'GET_INGRESSOS_NAO_LIBERADOS_BY_ESTABELECIMENTO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'SEARCH_INGRESSOS_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'SEARCH_INGRESSOS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        ingresso: action.ingressosList
      }
    case 'INGRESSO_CLEAR_ALL':
      return initialState
    case 'SEARCH_INGRESSOS_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'INGRESSO_DETAIL':
      return {
        ...state,
        id: action.id,
        clienteId: action.clienteId,
        loteId: action.loteId,
        dataHoraCompra: action.dataHoraCompra,
        formaPagamentoId: action.formaPagamentoId,
        status: action.status,
        dataHoraCancelamento: action.dataHoraCancelamento,
        valor: action.valor,
        validado: action.validado,
        dataHoraValidacao: action.dataHoraValidacao,
        qrcode: action.qrcode,
        cupomDescontoId: action.cupomDescontoId,
        transacao: action.transacao,
        excluido: action.excluido
      }
    case 'INGRESSO_UPDATED':
      return state
    case 'INGRESSO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    default:
      return state
  }
}
