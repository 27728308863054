import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  makeStyles
} from '@material-ui/core'
import { Chart } from './components'
import { Skeleton } from '@material-ui/lab'
import { useSelector } from 'react-redux'
import constants from 'theme/constants'
import { useParams } from 'react-router-dom'
import { useEventDashboard } from 'hooks-querys'

const useStyles = makeStyles((theme) => ({
  root: {
    ...constants.shadowCard
  },
  content: {},
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
  inner: {
    height: 375,
    minWidth: 500
  },
  chart: {
    height: '100%'
  }
}))

const GraficoVendasPorDia = () => {
  const classes = useStyles()
  const { eventoId } = useParams<{ eventoId: string }>()
  const { data: queryData, isFetching } = useEventDashboard(Number(eventoId))

  // @ts-ignore
  const { showValues } = useSelector((state) => state.global)
  const { graficoIngressosDia } = queryData || {}
  const data = graficoIngressosDia
    ? {
        quantidade: graficoIngressosDia.series[0],
        valor: graficoIngressosDia.series[1],
        labels: graficoIngressosDia.options
      }
    : undefined

  return (
    <Card className={classes.root}>
      <CardHeader title="VENDAS POR DIA" />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            {isFetching || !showValues ? (
              <Skeleton
                animation={isFetching ? 'pulse' : false}
                style={{ marginTop: -140 }}
                height={500}
              />
            ) : (
              // @ts-ignore
              <Chart className={classes.chart} data={data} />
            )}
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  )
}

export default GraficoVendasPorDia
