import React, { useEffect, useState, useMemo } from 'react'
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  TextField,
  InputAdornment,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Typography,
  Button,
  Box,
  FormLabel,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Backdrop
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Autocomplete } from '@material-ui/lab'

//? Own imports
import { useEvents, useLotesByEvent, useUsersDrawTicket } from 'hooks-querys'
import { getGenderFull, toLocalDateFormat } from 'utils'
import { Evento, UserItem } from 'types/api'
import { ModalDrawTicket } from './components'
import { HelperToolTip } from 'components'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    width: '100%'
  },
  submitButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    minWidth: 200
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  helperText: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const Draw = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [selectedEvento, setSelectedEvento] = useState<Evento>({} as Evento)
  const [loteId, setLoteId] = useState<number | null>(null)
  const [eventoInputValue, setEventoInputValue] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [selectedDrawRules, setSelectedDrawRules] = useState({
    segueEstabelecimento: false,
    favoritouEvento: false
  })
  const estabelecimentoId = useSelector(
    // @ts-ignore: //ainda não foi tipado o redux state
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const {
    data: events,
    isLoading: isLoadingEvents,
    isFetching: isFetchingEvents
  } = useEvents(estabelecimentoId, false, isMobile)

  const {
    data: lotes,
    isLoading: isLoadingLotes,
    isError: isErrorLotes
  } = useLotesByEvent(selectedEvento?.id)

  useEffect(() => {
    setSelectedEvento({} as Evento)
    setLoteId(0)
  }, [estabelecimentoId])

  const {
    data: numberOfUsers,
    isLoading: isLoadingNumberOfUsers,
    isSuccess: isSuccessNumberOfUsers
  } = useUsersDrawTicket({
    loteId: loteId || 0,
    favoritouEvento: selectedDrawRules.favoritouEvento,
    segueEstabelecimento: selectedDrawRules.segueEstabelecimento
  })

  const handleDrawRulesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedDrawRules({
      ...selectedDrawRules,
      [event.target.name]: event.target.checked
    })
  }

  const handleClickSubmit = () => {
    setOpenModal(true)
  }
  const errorDrawRules = useMemo(() => {
    if (
      !selectedDrawRules.segueEstabelecimento &&
      !selectedDrawRules.favoritouEvento
    ) {
      return 'Selecione ao menos uma regra de sorteio'
    }
    return ''
  }, [selectedDrawRules])

  return (
    <div className={classes.root}>
      <ModalDrawTicket
        setOpenModal={setOpenModal}
        openModal={openModal}
        loteId={loteId || 0}
        favoritouEvento={selectedDrawRules.favoritouEvento}
        segueEstabelecimento={selectedDrawRules.segueEstabelecimento}
      />
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={6}>
          <FormControl className={classes.formControl}>
            <Autocomplete
              loading={isLoadingEvents}
              id="combo-box-evento"
              options={events || []}
              value={selectedEvento}
              onChange={(_event, newValue, reason) => {
                if (reason === 'select-option') {
                  newValue !== null && setSelectedEvento(newValue)
                }
                if (reason === 'clear') {
                  //? se o usuário clicar no botão limpar
                  setEventoInputValue('')
                  setSelectedEvento({} as Evento)
                }
              }}
              getOptionLabel={(evento) =>
                evento.nome &&
                `${evento.nome} ${
                  evento.dataHoraInicio
                    ? ` - (${toLocalDateFormat(evento.dataHoraInicio)})`
                    : ''
                }`
              }
              placeholder="Selecione o evento..."
              onInputChange={(_event, newInputValue) => {
                newInputValue !== null
                  ? setEventoInputValue(newInputValue)
                  : setEventoInputValue('')
              }}
              inputValue={eventoInputValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Selecione o evento"
                  placeholder="Selecione o evento..."
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        <InputAdornment position="end">
                          <>
                            {isFetchingEvents && (
                              <CircularProgress
                                style={{ marginRight: 10 }}
                                size={20}
                                color="primary"
                              />
                            )}
                          </>
                        </InputAdornment>
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
        {selectedEvento && selectedEvento.id > 0 && (
          <>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="lote-label">Selecione o lote</InputLabel>
                <Select
                  disabled={isLoadingLotes}
                  placeholder="Selecione o lote"
                  labelId="lote-label"
                  id="lote-select"
                  value={loteId}
                  onChange={(event) => {
                    setLoteId(Number(event.target.value))
                  }}
                  label="Selecione o lote"
                >
                  <MenuItem disabled={loteId === 0} key={0} value={0}>
                    {loteId === 0 ? (
                      'Selecione o lote'
                    ) : (
                      <strong>Limpar seleção</strong>
                    )}
                  </MenuItem>
                  {lotes &&
                    lotes.length > 0 &&
                    lotes.map((lote) => (
                      <MenuItem
                        disabled={lote.id === loteId}
                        key={lote.id}
                        value={lote.id}
                      >
                        {lote.tipoIngresso
                          ? `${lote.tipoIngresso.descricao} - ${
                              lote.descricao
                            } (${getGenderFull(lote.genero)})`
                          : lote.descricao}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            {loteId && loteId > 0 ? (
              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormLabel component="legend">
                    Selecione as regras para o sorteio
                  </FormLabel>
                </FormControl>
                <FormControl
                  required
                  error={errorDrawRules !== ''}
                  component="fieldset"
                  className={classes.formControl}
                  color="primary"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedDrawRules.favoritouEvento}
                          onChange={handleDrawRulesChange}
                          name="favoritouEvento"
                          color="primary"
                        />
                      }
                      label={
                        <div className={classes.helperText}>
                          <Typography variant="inherit">
                            Favoritou o evento
                          </Typography>
                          <HelperToolTip>
                            O usuário precisa ter favoritado o evento para poder
                            participar do sorteio.
                          </HelperToolTip>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedDrawRules.segueEstabelecimento}
                          onChange={handleDrawRulesChange}
                          name="segueEstabelecimento"
                          color="primary"
                        />
                      }
                      label={
                        <div className={classes.helperText}>
                          <Typography variant="inherit">
                            Segue o estabelecimento
                          </Typography>
                          <HelperToolTip>
                            O usuário precisa seguir o perfil do estabelecimento
                            para poder participar do sorteio.
                          </HelperToolTip>
                        </div>
                      }
                    />
                  </FormGroup>
                  <FormHelperText>{errorDrawRules}</FormHelperText>
                </FormControl>
              </Grid>
            ) : null}
          </>
        )}

        {!isLoadingNumberOfUsers && isSuccessNumberOfUsers ? (
          <Grid item xs={12}>
            {numberOfUsers > 0 ? (
              <Box>
                <Typography variant="h3" color="textPrimary">
                  <strong>{`${numberOfUsers} ${
                    numberOfUsers === 1 ? 'pessoa' : 'pessoas'
                  }`}</strong>
                </Typography>
                <Typography variant="h6" color="textPrimary">
                  {`${
                    numberOfUsers === 1 ? 'está' : 'estão'
                  } participando deste sorteio!`}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography variant="h6" color="textPrimary">
                  Não foram encontrados usuários participantes deste sorteio!
                </Typography>
              </Box>
            )}
          </Grid>
        ) : null}

        {isSuccessNumberOfUsers && numberOfUsers > 0 ? (
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickSubmit}
              className={classes.submitButton}
            >
              Sortear ingresso
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <Backdrop className={classes.backdrop} open={isLoadingNumberOfUsers}>
        <Box marginY={4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box mb={2} paddingX={6}>
              <Typography variant="h5" color="inherit" align="center">
                Buscando participantes para o sorteio...
              </Typography>
            </Box>
            <Box>
              <CircularProgress />
            </Box>
          </Box>
        </Box>
      </Backdrop>
    </div>
  )
}

export default Draw
