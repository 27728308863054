import React from 'react'
import {
  TableBody,
  Table,
  makeStyles,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Toolbar,
  Tooltip,
  IconButton
} from '@material-ui/core'
import { formatCurrency } from 'utils'
import ReactToPrint from 'react-to-print'
import { CSVLink } from 'react-csv'
import PrintRoundedIcon from '@material-ui/icons/PrintRounded'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
import constants from 'theme/constants'
import { toast } from 'react-toastify'

const headers = [
  { label: 'Código do Cupom', key: 'cupomDesconto' },
  { label: 'N.º de Utilizações', key: 'quantidadeUtilizacao' },
  { label: 'Valor Total (R$)', key: 'valorTotal' },
  { label: 'Valor Desconto (R$)', key: 'valorDesconto' }
]

const prepareDataForCSV = (cuponsDescontoResult) => {
  return cuponsDescontoResult.map((row) => ({
    cupomDesconto: row.cupomDesconto,
    quantidadeUtilizacao: row.quantidadeUtilizacao,
    valorTotal: row.valorTotal,
    valorDesconto: row.valorDesconto
  }))
}

const handleDownload = () => {
  toast.success('Download concluído com sucesso! 👍', {
    position: 'top-right'
  })
}

const useStyles = makeStyles((theme) => ({
  rootToolbarStyles: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  titleToolbarStyles: {
    flex: '1 1 100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    ...constants.shadowCard
  }
}))

const TableUtilizacao = ({ cuponsDescontoResult }) => {
  const classes = useStyles()
  const refTable = React.useRef(null)
  const csvData = prepareDataForCSV(cuponsDescontoResult)
  return (
    <>
      <div>
        {cuponsDescontoResult && cuponsDescontoResult.length !== 0 ? (
          <>
            <Typography variant="body2" align="left" gutterBottom>
              Total de dados encontrados: <b>{cuponsDescontoResult.length}</b>
            </Typography>
            <Paper ref={refTable} className={classes.paper}>
              <Toolbar className={classes.rootToolbarStyles}>
                <Typography
                  className={classes.titleToolbarStyles}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Relatório de Utilizações
                </Typography>
                <CSVLink
                  data={csvData}
                  headers={headers}
                  target="_blank"
                  filename={'utilizacao-de-cupons.csv'}
                  onClick={() => {
                    handleDownload()
                  }}
                >
                  <Tooltip title="Exportar em CSV">
                    <IconButton aria-label="exportar em csv">
                      <GetAppRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </CSVLink>
                <ReactToPrint
                  trigger={() => (
                    <Tooltip title="Imprimir">
                      <IconButton aria-label="gerar relatorio">
                        <PrintRoundedIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  content={() => refTable.current}
                />
              </Toolbar>
              <TableContainer>
                {cuponsDescontoResult && cuponsDescontoResult.length > 0 && (
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Código</TableCell>
                        <TableCell align="center">N.º de Utilizações</TableCell>
                        <TableCell align="right">Valor Total (R$)</TableCell>
                        <TableCell align="right">Valor Desconto (R$)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cuponsDescontoResult.map((row) => (
                        <TableRow hover key={row.name}>
                          <TableCell component="th" scope="row">
                            {row.cupomDesconto ? row.cupomDesconto : '-----'}
                          </TableCell>
                          <TableCell align="center">
                            {row.quantidadeUtilizacao
                              ? row.quantidadeUtilizacao
                              : '-----'}
                          </TableCell>
                          <TableCell align="right">
                            {formatCurrency(row.valorTotal)}
                          </TableCell>
                          <TableCell align="right">
                            {formatCurrency(row.valorDesconto)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </Paper>
          </>
        ) : (
          <Typography variant="body2" align="left" gutterBottom>
            <b>{'Nenhum dado encontrado.'}</b>
          </Typography>
        )}
      </div>
    </>
  )
}

export default TableUtilizacao
