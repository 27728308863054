import React from 'react'
import {
  TableRow,
  TableCell,
  Box,
  Typography,
  Table,
  TableHead,
  useTheme,
  TableBody,
  IconButton,
  Tooltip
} from '@material-ui/core'

//? Own imports
import { formatCurrency, toLocalDateFormat } from 'utils'
import { Label, SkeletonTable } from 'components'
import { useMembershipPayment } from 'hooks-querys'
import { AccessControl } from 'components/Utils'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded'
import ShareIcon from '@material-ui/icons/Share'
import { toast } from 'react-toastify'

const RowTableMemberFinancial = ({
  membershipId
}: {
  membershipId: number
}) => {
  const theme = useTheme()
  const { data, isLoading } = useMembershipPayment({
    membershipId: membershipId
  })

  const handleOpenLinkBoleto = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  }

  const handleCopyLinhaDigitavelBoleto = (link: string) => {
    navigator.clipboard.writeText(link)
    toast.success(
      'Linha digitável do boleto copiada com sucesso!',
      {
        autoClose: 4000
      }
    )
  }



  if (!membershipId) return null

  const totalItems = data?.length
  return (
    <Box margin={1}>
      <Typography variant="h6" gutterBottom component="div">
        Histórico de transações
      </Typography>
      {isLoading ? (
        <SkeletonTable style={{ paddingTop: 5 }} />
      ) : totalItems && totalItems > 0 ? (
        <Table
          size="small"
          aria-label="purchases"
          style={{ borderRadius: 6, overflow: 'hidden' }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">Data criação</TableCell>
              <TableCell>Forma Pagamento</TableCell>
              <TableCell>Data validade</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="right">Valor Total (R$)</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((payment) => (
              <TableRow key={payment?.id}>
                <TableCell>{toLocalDateFormat(payment.dataHora)}</TableCell>
                <TableCell component="th" scope="row">
                  {payment?.formaPagamento?.descricao}
                </TableCell>
                <TableCell>
                  {toLocalDateFormat(payment?.dataHoraValidade)}
                </TableCell>
                <TableCell align="center">
                  <Label
                    color={
                      payment?.statusPagamento?.finalizado
                        ? theme?.palette?.success?.main
                        : theme?.palette?.error?.main
                    }
                    variant="outlined"
                  >
                    {payment?.statusPagamento?.descricao}
                  </Label>
                </TableCell>

                <TableCell align="right">
                  {formatCurrency(payment?.valor)}
                </TableCell>
                <TableCell align="right">
                  <AccessControl
                    rule={'membros.edit'}
                    // @ts-ignore
                    yes={() => (
                      <IconButton
                        size="small"
                        aria-label="Edit"
                        component="a"
                        onClick={() =>
                          handleOpenLinkBoleto(payment.linkBoleto)
                        }
                        disabled={payment?.formaPagamento?.id != 2}
                      >
                        <Tooltip title="Abrir Boleto">
                          <FileCopyRoundedIcon fontSize="small" />
                        </Tooltip>
                      </IconButton>
                    )}
                  />
                  <AccessControl
                    rule={'membros.edit'}
                    // @ts-ignore
                    yes={() => (
                      <IconButton
                        size="small"
                        aria-label="Edit"
                        component="a"
                        onClick={() =>
                          handleCopyLinhaDigitavelBoleto(payment.linhaDigitavelBoleto)
                        }
                        disabled={payment?.formaPagamento?.id != 2}
                      >
                        <Tooltip title="Copiar linha digitável boleto">
                          <ShareIcon fontSize="small" />
                        </Tooltip>
                      </IconButton>
                    )}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Box>
          <Typography>Não há dados para essa subscrição</Typography>
        </Box>
      )}
    </Box>
  )
}

export default RowTableMemberFinancial
