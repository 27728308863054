import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Button,
  Dialog,
  Hidden,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Typography,
  IconButton,
  Tooltip,
  makeStyles,
  TextField
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import PropTypes from 'prop-types'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import BusinessIcon from '@material-ui/icons/Business'

//? Own imports
import { history } from 'utils'
import { usuarioEstabelecimentoAction } from '_actions'
import { estabelecimentoAction } from '_actions'
import { UsuarioEstabelecimento } from 'types/api'

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 250,
    width: '100%'
  },
  dialogActions: { padding: theme.spacing(2, 3) }
}))

const SelectEstabelecimento = () => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [disabled, setDisabled] = React.useState(false)
  const [
    estabelecimentoInputValue,
    setEstabelecimentoInputValue
  ] = React.useState('')

  const { usuarioEstabelecimento, estabelecimento } = useSelector(
    // @ts-ignore: //ainda não foi tipado o redux state
    (state) => state.usuarioEstabelecimento
  )
  const isAddPlace = useSelector((state) => {
    // @ts-ignore: //ainda não foi tipado o redux state
    return state.usuarioEstabelecimento.isAddPlace
  })

  const hasEstabelecimentoSelected = useSelector((state) => {
    if (
      // @ts-ignore: //ainda não foi tipado o redux state
      state.usuarioEstabelecimento.estabelecimentoId === '' ||
      // @ts-ignore: //ainda não foi tipado o redux state
      state.usuarioEstabelecimento.estabelecimentoId === undefined
    ) {
      return false
    }
    return true
  })

  const dispatch = useDispatch()

  const emailVerificado = useSelector(
    // @ts-ignore: //ainda não foi tipado o redux state
    (state) => state.authentication.user.emailVerificado
  )

  useEffect(() => {
    setInitialUsuarioEstabelecimento()
  }, [usuarioEstabelecimento])

  const handleChange = (establishment: UsuarioEstabelecimento) => {
    if (!establishment) return
    dispatch(
      usuarioEstabelecimentoAction.changeDetailsUsuarioEstabelecimento(
        establishment
      )
    )
    setOpen(false)
  }
  const handleClickOpen = () => {
    setOpen(true)
    if (undefined !== usuarioEstabelecimento && usuarioEstabelecimento.length) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }

  const setInitialUsuarioEstabelecimento = () => {
    if (
      usuarioEstabelecimento.length === 0 ||
      usuarioEstabelecimento === undefined
    ) {
      return null
    } else if (
      undefined !== usuarioEstabelecimento &&
      usuarioEstabelecimento.length === 1
    ) {
      dispatch(
        usuarioEstabelecimentoAction.changeDetailsUsuarioEstabelecimento(
          usuarioEstabelecimento[0]
        )
      )
    } else if (!hasEstabelecimentoSelected) {
      setOpen(true)
      setDisabled(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setDisabled(false)
  }

  const getUsuarioEstabelecimentoId = useSelector((state) => {
    // @ts-ignore: //ainda não foi tipado o redux state
    return state.usuarioEstabelecimento.id
  })

  const getBackupIndex = useSelector((state) => {
    // @ts-ignore: //ainda não foi tipado o redux state
    let indexBackup = state.usuarioEstabelecimento.usuarioEstabelecimento.findIndex(
      // @ts-ignore: //ainda não foi tipado o redux state
      (item) => item.id === state.usuarioEstabelecimento.backupId
    )
    return indexBackup
  })

  const handleAddPlace = () => {
    setOpen(false)
    if (!isAddPlace) {
      dispatch(
        usuarioEstabelecimentoAction.setIsAddPlace(
          true,
          getUsuarioEstabelecimentoId
        )
      )
      dispatch(estabelecimentoAction.clearFields())
      history.push('/estabelecimento/general')
    } else {
      dispatch(
        usuarioEstabelecimentoAction.changeDetailsUsuarioEstabelecimento(
          usuarioEstabelecimento[getBackupIndex]
        )
      )
      dispatch(usuarioEstabelecimentoAction.setIsAddPlace(false, ''))
    }
  }

  const returnContent = () => {
    return (
      <div>
        {/* @ts-ignore */}
        <Hidden smDown>
          <Typography variant="overline" color="inherit">
            Estabelecimento selecionado:
            <Typography variant="button" color="inherit">
              {' '}
              <b>{estabelecimento.nome}</b>
            </Typography>
          </Typography>
        </Hidden>
        <Tooltip title="Trocar estabelecimento">
          <IconButton color="inherit" onClick={handleClickOpen}>
            {/* @ts-ignore */}
            <Hidden mdUp>
              <BusinessIcon />
            </Hidden>
            <ArrowDropDownIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={open}
          fullWidth
          onClose={handleClose}
          maxWidth="sm"
        >
          <DialogTitle>
            <Typography variant="overline" color="inherit">
              meus estabelecimentos
            </Typography>
          </DialogTitle>
          <DialogContent>
            <FormControl className={classes.formControl}>
              <Autocomplete
                id="autocomplete-select-establishment:UsuarioEstabelecimento"
                fullWidth
                options={usuarioEstabelecimento}
                value={usuarioEstabelecimento.find(
                  (item: UsuarioEstabelecimento) =>
                    item.estabelecimentoId === estabelecimento.id
                )}
                onChange={(event, newValue, reason) => {
                  if (reason === 'select-option') {
                    newValue !== null && handleChange(newValue)
                  }
                }}
                getOptionLabel={(
                  usuarioEstabelecimento: UsuarioEstabelecimento
                ) => usuarioEstabelecimento?.estabelecimento?.nome}
                placeholder="Buscar por nome..."
                onInputChange={(event, newInputValue) => {
                  newInputValue !== null
                    ? setEstabelecimentoInputValue(newInputValue)
                    : setEstabelecimentoInputValue('')
                }}
                inputValue={estabelecimentoInputValue}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione o estabelecimento"
                    placeholder="Buscar por nome..."
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button disabled={disabled} onClick={handleClose} color="primary">
              Cancelar
            </Button>
            {/* <Button onClick={handleClose} color="primary">
              Selecionar
        </Button> */}
            <Button
              disabled={disabled}
              onClick={handleAddPlace}
              color="primary"
              variant="contained"
            >
              {isAddPlace ? 'Cancelar Adição' : 'Adicionar'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  return (
    <div>
      {usuarioEstabelecimento.length === 0 ||
      usuarioEstabelecimento === undefined
        ? emailVerificado && (
            //@ts-ignore
            <Hidden smDown>
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => history.push(`/estabelecimento/`)}
              >
                Completar cadastro
              </Button>
            </Hidden>
          )
        : usuarioEstabelecimento.length === 1
        ? null
        : returnContent()}
    </div>
  )
}

SelectEstabelecimento.propTypes = {
  className: PropTypes.string
}
export default SelectEstabelecimento
