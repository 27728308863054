import React from 'react'
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Tabs, Tab, useMediaQuery, useTheme } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import EventAvailableTwoToneIcon from '@material-ui/icons/EventAvailableTwoTone'
import BusinessTwoToneIcon from '@material-ui/icons/BusinessTwoTone'
import { Page } from 'components'
import { PlaceRating, EventRating } from './components'
import TitleContentWithoutButton from 'components/Utils/TitleContentWithoutButton'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  tabs: { marginBottom: theme.spacing(4) }
})

const Avaliacoes = (props) => {
  const { classes, match, history } = props
  const { tab } = match.params
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const { avaliacao } = useSelector((state) => state.avaliacao)

  const handleTabsChange = (event, value) => {
    history.push(value)
  }

  const tabs = [
    {
      value: 'placeRating',
      label: 'Estabelecimento',
      icon: <BusinessTwoToneIcon />
    }
  ]
  {
    tabs.push({
      value: 'eventRating',
      label: 'Eventos',
      icon: <EventAvailableTwoToneIcon />
    })
  }

  if (!tab) {
    return <Redirect to={`/avaliacoes/placeRating`} />
  }

  if (!tabs.find((t) => t.value === tab)) {
    return <Redirect to="/errors/error-404" />
  }

  return (
    <Page className={classes.root}>
      {undefined !== avaliacao && avaliacao.length > 0 ? (
        <TitleContentWithoutButton
          length={avaliacao.length}
          subTitle={'GERÊNCIA DE AVALIAÇÕES'}
          title={'Lista de avaliações'}
        />
      ) : (
        <TitleContentWithoutButton
          length={0}
          subTitle={'GERÊNCIA DE AVALIAÇÕES'}
          title={'Lista de avaliações'}
        />
      )}
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        variant={isMobile ? 'fullWidth' : 'standard'}
        centered={!isMobile}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            icon={tab.icon}
          />
        ))}
      </Tabs>
      <div className={classes.content}>
        {tab === 'placeRating' && <PlaceRating />}
        {tab === 'eventRating' && <EventRating />}
      </div>
    </Page>
  )
}

Avaliacoes.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

const connectedEventoPage = withRouter(
  connect(null, null, null, {
    pure: false
  })(withStyles(styles)(Avaliacoes))
)

export { connectedEventoPage as Avaliacoes }
