import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useOrdersCancel } from 'hooks-querys'
import { useLocation } from 'react-router-dom'

//? Own imports
import { Header, SkeletonTable } from 'components'
import { Filters, TableCancelamentosIngressos } from './components'
import { AccessControl } from 'components/Utils/AccessControl'
import { AccessDenied } from 'views'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(3, 2, 2, 2)
  },
  header: {
    paddingBottom: theme.spacing(2)
  }
}))

function useSearchQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const CancelamentosIngressosList = () => {
  const classes = useStyles()
  const querySearch = useSearchQuery()
  const [filters, setFilters] = useState({
    // page: Number(querySearch.get('page')) || 1,
    textSearch: querySearch.get('textSearch') || '',
    statusOrderCancelId: querySearch.get('statusOrderCancelId') || '1',
    reasonOrderCancelId: querySearch.get('reasonOrderCancelId') || '0'
  })

  useEffect(() => {
    setFilters({
      // page: Number(querySearch.get('page')) || 1,
      textSearch: querySearch.get('textSearch') || '',
      statusOrderCancelId: querySearch.get('statusOrderCancelId') || '1',
      reasonOrderCancelId: querySearch.get('reasonOrderCancelId') || '0'
    })
  }, [querySearch])

  const { isLoading, data } = useOrdersCancel({
    page: 1, // valor fixo para paginação, pois o componente de paginação não está implementado
    direction: 'desc',
    order: 'dataHora',
    limit: 10000, //limite alto para não haver paginação
    term: filters.textSearch,
    statusCancelamentoIngressoId: filters.statusOrderCancelId,
    motivoCancelamentoIngressoId: filters.reasonOrderCancelId
  })

  return (
    <AccessControl
      rule={'cancelamentosIngressos.listAdmin'} //permissão necessária para acessar conteúdo
      // @ts-ignore
      yes={() => (
        <div className={classes.root}>
          <Header
            className={classes.header}
            subtitle="Gerência de solicitações de cancelamento de ingressos"
            title="Lista de cancelamentos de ingressos"
          />
          <Filters />
          {isLoading ? (
            <SkeletonTable />
          ) : (
            <TableCancelamentosIngressos ordersCancel={data.items} />
          )}
        </div>
      )}
      // @ts-ignore
      no={() => <AccessDenied />}
    />
  )
}

export default CancelamentosIngressosList
