import { colors } from '@material-ui/core'
import { StatusPedido } from 'types/api'

export const getStatusPedidoColor = (statusPedido: StatusPedido) => {
  if (statusPedido.finalizado) {
    return colors.green[500]
  }
  if (statusPedido.cancelado) {
    return colors.red[500]
  }
  if (statusPedido.pendente) {
    return colors.orange[500]
  }
  return colors.grey[500]
}
