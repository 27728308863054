import { crudService, cepService } from '_services/'
import { history } from 'utils/'
import { toast } from 'react-toastify'
import { slugMask } from 'utils/masks'

export const estabelecimentoAction = {
  getEstabelecimento,
  getEstabelecimentoById,
  getEstabelecimentoByUser,
  onChangeProps,
  editEstabelecimentoInfo,
  createEstabelecimento,
  deleteEstabelecimentoById,
  editEstabelecimentosDetails,
  getEnderecoByCep,
  changeCoordinates,
  clearFields,
  handleOnChangeHashtag
}

function getEstabelecimento() {
  return (dispatch) => {
    let apiEndpoint = 'estabelecimentos'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(clearEstabelecimento())
        dispatch(changeEstabelecimentosList(response.data))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

function getEstabelecimentoByUser(usuarioId) {
  return (dispatch) => {
    let apiEndpoint = 'estabelecimentos/usuario/' + usuarioId
    crudService
      .get(apiEndpoint)
      .then((response) => {
        // dispatch(clearEstabelecimento())
        dispatch(changeEstabelecimentosList(response.data))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

function createEstabelecimento(payload, bAddPlace) {
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'estabelecimentos/'
    if (!bAddPlace) {
      crudService
        .post(apiEndpoint, payload)
        .then((response) => {
          dispatch(success(response.data))
          history.push('/home')
          toast.success('Cadastro realizado com sucesso! 🎉', {
            autoClose: 4000
          })
          toast('Plataforma desbloqueada! Use sem moderação 😎', {
            autoClose: 6000
          })
        })
        .catch((error) => {
          dispatch(failure(error))
          history.push('/estabelecimento/general')
        })
    } else {
      crudService
        .post(apiEndpoint, payload)
        .then((response) => {
          dispatch(success(response.data))
          history.push('/home')
          toast.success('Estabelecimento adicionado com sucesso! 🎉', {
            autoClose: 4000
          })
          toast('Use sem moderação 😎', {
            autoClose: 6000
          })
        })
        .catch((error) => {
          dispatch(failure(error))
          history.push('/estabelecimento/general')
        })
    }
  }

  function request(payload) {
    return { type: 'CREATE_ESTABELECIMENTO_REQUEST', payload }
  }

  function success(responseData) {
    let estabelecimento = {
      ...responseData
    }
    delete estabelecimento.usuarioEstabelecimento
    let usuarioEstabelecimento = {
      ...responseData.usuarioEstabelecimento[0],
      estabelecimento: estabelecimento
    }
    return {
      type: 'CREATE_ESTABELECIMENTO_SUCCESS',
      estabelecimento: estabelecimento,
      usuarioEstabelecimento: usuarioEstabelecimento
    }
  }

  function failure(error) {
    return { type: 'CREATE_ESTABELECIMENTO_FAILURE', error }
  }
}

function editEstabelecimentoInfo(id, payload) {
  return (dispatch) => {
    if (payload.slug === '') {
      payload.slug = slugMask(payload.nome)
    }
    dispatch(request(id))
    let apiEndpoint = 'estabelecimentos/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(getEstabelecimentoById(id))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request(payload) {
    return { type: 'EDIT_ESTABELECIMENTO_REQUEST', payload }
  }

  function success() {
    return {
      type: 'EDIT_ESTABELECIMENTO_SUCCESS'
    }
  }

  function failure(error) {
    return { type: 'EDIT_ESTABELECIMENTO_FAILURE', error }
  }
}

export function getEstabelecimentoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'estabelecimentos/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editEstabelecimentosDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem' || props === 'banner') {
      dispatch(handleOnChangeProps(props, event))
    } else if (props === 'username') {
      dispatch(handleOnChangeProps(props, slugMask(event.target.value)))
    } else if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function changeCoordinates(coordinates) {
  return {
    type: 'ESTABELECIMENTO_CHANGE_COORDINATES',
    latLon: coordinates
  }
}

function deleteEstabelecimentoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'estabelecimentos/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteEstabelecimentosDetails())
      dispatch(clearEstabelecimento())
      dispatch(estabelecimentoAction.getEstabelecimento())
    })
  }
}

export function getEnderecoByCep(cep) {
  return (dispatch) => {
    dispatch(request(cep))
    cepService
      .get(cep)
      .then((response) => {
        if (response.data) {
          dispatch(success(response.data))
          dispatch(getCidadeByIbge(response.data.ibge))
        }
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request(payload) {
    return { type: 'SEARCH_ENDERECO_BY_CEP_REQUEST', payload }
  }

  function success(cepData) {
    return {
      type: 'SEARCH_ENDERECO_BY_CEP_SUCCESS',
      endereco: cepData.logradouro,
      complemento: cepData.complemento,
      bairro: cepData.bairro,
      ibge: cepData.ibge
    }
  }

  function failure(error) {
    return { type: 'SEARCH_ENDERECO_BY_CEP_FAILURE', error }
  }
}

function getCidadeByIbge(id) {
  return (dispatch) => {
    dispatch(request(id))
    let apiEndpoint = 'cidades/ibge/' + id
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request(payload) {
    return { type: 'SEARCH_CIDADE_BY_IBGE_REQUEST', payload }
  }

  function success(cidade) {
    return {
      type: 'SEARCH_CIDADE_BY_IBGE_SUCCESS',
      cidadeId: cidade ? cidade.id : null,
      estadoId: cidade ? (cidade.estado ? cidade.estado.id : null) : null,
      paisId: cidade
        ? cidade.estado
          ? cidade.estado.pais
            ? cidade.estado.pais.id
            : null
          : null
        : null
    }
  }

  function failure(error) {
    return { type: 'SEARCH_CIDADE_BY_IBGE_FAILURE', error }
  }
}

export function changeEstabelecimentosList(estabelecimento) {
  return {
    type: 'FETCHED_ALL_ESTABELECIMENTO',
    estabelecimento: estabelecimento
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'ESTABELECIMENTO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editEstabelecimentosDetails(estabelecimento) {
  return [
    { type: 'ESTABELECIMENTO_DETAIL', estabelecimento },
    {
      type: 'ESTABELECIMENTO_ESTADO_DETAIL',
      estadoId: estabelecimento.cidade.estadoId
    }
  ]
}

export function clearEstabelecimento() {
  return {
    type: 'ESTABELECIMENTO_CLEAR'
  }
}

export function deleteEstabelecimentosDetails() {
  return {
    type: 'DELETED_ESTABELECIMENTO_DETAILS'
  }
}

export function clearFields() {
  return {
    type: 'ESTABELECIMENTO_CLEAR'
  }
}

export function handleOnChangeHashtag(hashtags) {
  return {
    type: 'ESTABELECIMENTO_HASHTAG_CHANGE',
    hashtags: hashtags
  }
}
