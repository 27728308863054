import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import ShareIcon from '@material-ui/icons/Share'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import LinkIcon from '@material-ui/icons/Link'
import { toast } from 'react-toastify'
import { useMediaQuery, useTheme } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  shareIcons: {
    '&:hover': {
      color: theme.palette.primary.main,
      transform: 'rotate(-6deg)'
    }
  }
}))

function SocialShare({ evento, link, place }) {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const linkWhatsApp = isMobile
    ? 'whatsapp://send?text='
    : 'https://wa.me/?text='

  const actions = [
    {
      icon: <FacebookIcon className={classes.shareIcons} color="action" />,
      name: 'Facebook',
      href: `https://www.facebook.com/sharer/sharer.php?u=${link}`
    },
    {
      icon: <TwitterIcon className={classes.shareIcons} color="action" />,
      name: 'Twitter',
      href: `https://twitter.com/intent/tweet?url=${link}&text=${evento.nome}`
    },
    {
      icon: <LinkedInIcon className={classes.shareIcons} color="action" />,
      name: 'LinkedIn',
      href: `https://www.linkedin.com/shareArticle?mini=true&url=${link}&title=${evento.nome}&summary=${evento.nome}&source=`
    },
    {
      icon: <WhatsAppIcon className={classes.shareIcons} color="action" />,
      name: 'WhatsApp',
      href: place
        ? `${linkWhatsApp}Olá, veja o perfil do organizador ${evento.nome} disponível no Corujas! ${link}`
        : `${linkWhatsApp}Olá, veja só esta indicação de evento disponível no Corujas! ${evento.nome} - ${link}`
    }
  ]

  return (
    <div>
      <SpeedDial
        FabProps={{ size: 'small' }}
        ariaLabel="Compartilhar"
        className={classes.speedDial}
        icon={<ShareIcon fontSize="small" />}
        open={true}
        hidden={true}
        direction="rigth"
      >
        {actions.map((action) => (
          <SpeedDialAction
            FabProps={{ size: 'small' }}
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipPlacement={'bottom'}
            onClick={() => window.open(action.href, '_blank')}
          />
        ))}
        <SpeedDialAction
          FabProps={{ size: 'small' }}
          key="Copiar link"
          icon={<LinkIcon className={classes.shareIcons} color="action" />}
          tooltipTitle={
            place
              ? 'Copiar link para perfil do estabelecimento'
              : 'Copiar link para o evento'
          }
          tooltipPlacement={'bottom'}
          onClick={() => {
            navigator.clipboard.writeText(link)
            toast('Link copiado para área de trabalho')
          }}
        />
      </SpeedDial>
    </div>
  )
}

export default SocialShare
