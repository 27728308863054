import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  // Chip,
  withStyles,
  Tooltip,
  CardContent,
  CardMedia,
  Card,
  CardActionArea,
  Typography,
  Zoom,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core'
import LocationIcon from '@material-ui/icons/LocationOn'
import EventTwoToneIcon from '@material-ui/icons/EventTwoTone'
import PermContactCalendarTwoToneIcon from '@material-ui/icons/PermContactCalendarTwoTone'
// import HomeWorkTwoToneIcon from '@material-ui/icons/HomeWorkTwoTone';
import { Item } from '@mui-treasury/components/flex'
import { format } from 'date-fns'
import { toISODateFormat } from 'utils'

const styles = (theme) => ({
  card: {
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
    border: '2px solid transparent',
    '&:hover': {
      boxShadow: '0px 0px 20px 8px rgba(0,0,0,0.18)',
      border: '2px solid rgba(225, 194, 10, 1)'
    },
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 15,
    marginBottom: -35
  },
  cardMedia: {
    paddingTop: '56.25%' // 16:9
  }
})

const EventoCard = (props) => {
  const { classes } = props
  const evento = props.evento

  return (
    <Zoom in={true}>
      <Card Paper elevation={3} className={classes.card}>
        <CardMedia
          className={classes.cardMedia}
          image={evento.imagem}
          title={evento.nome}
        />
        <CardContent>
          <Typography
            noWrap
            align="center"
            style={{ marginTop: '-8px' }}
            gutterBottom
            variant="h5"
          >
            <strong>{evento.nome}</strong>
          </Typography>
          <Divider variant="middle" />
          <List dense="true">
            <Item
              justifyContent="center"
              justifyItems="center"
              alignContent="center"
              display="flex"
            >
              <LocationIcon
                color="primary"
                style={{ fontSize: '1rem', marginBottom: '-2px' }}
              />
              {/* <Typography
                    style={{ fontSize: '0.85rem', paddingLeft: '5px' }}
                    noWrap
                    variant="caption"
                    color="primary"
                  >
                    {evento.cidade !== null && evento.cidade !== undefined
                      ? `${evento.cidade.nome} - ${evento.cidade.estado.uf}`
                      : evento.estabelecimento.cidade !== null &&
                        evento.estabelecimento.cidade !== undefined
                      ? `${evento.estabelecimento.cidade.nome} - ${evento.estabelecimento.cidade.estado.uf}`
                      : 'Localização não definida'}
                  </Typography> */}
            </Item>
            <ListItem className={classes.estabelecimentoItem}>
              <ListItemIcon>
                <PermContactCalendarTwoToneIcon
                  className={classes.estabelecimentoItemIcon}
                  fontSize="small"
                />
              </ListItemIcon>
              <ListItemText
                style={{ marginLeft: '-15px' }}
                primary={
                  <Typography
                    className={classes.estabelecimentoItem}
                    noWrap
                    color="textPrimary"
                  >
                    {props.estabelecimento.estabelecimento}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem style={{ marginTop: '-12px' }}>
              <ListItemIcon>
                <EventTwoToneIcon fontSize="small" />
              </ListItemIcon>
              {evento.dataHoraInicio !== null &&
              evento.dataHoraInicio !== undefined ? (
                <ListItemText
                  style={{ marginLeft: '-15px' }}
                  primary={
                    <Typography noWrap variant="body1" color="textPrimary">
                      {format(
                        new Date(toISODateFormat(evento.dataHoraInicio)),
                        "dd/MM/yyyy 'às' hh:mm"
                      )}
                    </Typography>
                  }
                />
              ) : (
                <ListItemText
                  style={{ marginLeft: '-15px' }}
                  primary={
                    <Typography noWrap variant="body1" color="textPrimary">
                      Sem data definida
                    </Typography>
                  }
                />
              )}
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </Zoom>
  )
}
EventoCard.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles)(EventoCard)
