import React from 'react'
import {
  TableBody,
  Table,
  makeStyles,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Toolbar,
  Tooltip,
  IconButton
} from '@material-ui/core'
import ReactToPrint from 'react-to-print'
import { CSVLink } from 'react-csv'
import PrintRoundedIcon from '@material-ui/icons/PrintRounded'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
import { toast } from 'react-toastify'

//? Own imports
import {
  cpfCnpjMask,
  formatCurrency,
  getStatusIngressoColor,
  phoneMask
} from 'utils'
import { Label } from 'components'
import { Ingresso } from 'types/api'
import constants from 'theme/constants'

const headers = [
  { label: 'N.º', key: 'id' },
  { label: 'Cliente', key: 'usuario.nome' },
  { label: 'CPF/CNPJ', key: 'usuario.cpfCnpj' },
  { label: 'Celular', key: 'usuario.telefone' },
  { label: 'Email', key: 'usuario.email' },
  { label: 'Tipo Ingresso', key: 'tipoIngresso' },
  { label: 'Valor Pago (R$)', key: 'valorPago' },
  { label: 'Status', key: 'statusIngresso' }
]

export const prepareDataForCSV = (tickets: Ingresso[]) => {
  return tickets.map((ticket) => ({
    id: ticket.id,
    'usuario.nome': ticket.usuario.nome,
    'usuario.cpfCnpj': cpfCnpjMask(ticket.usuario.cpfCnpj),
    'usuario.telefone': phoneMask(ticket.usuario.telefone),
    'usuario.email': ticket.usuario.email,
    tipoIngresso: `${ticket.lote.tipoIngresso.descricao} - (${ticket.lote.descricao})`,
    valorPago: ticket.valor - ticket.valorDesconto,
    statusIngresso: ticket.statusIngresso.descricao
  }))
}

const handleDownload = () => {
  toast.success('Download concluído com sucesso! 👍', {
    position: 'top-right'
  })
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700
  },
  rootToolbarStyles: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  titleToolbarStyles: {
    flex: '1 1 100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    ...constants.shadowCard
  }
}))

const TableIngressos = ({ tickets }: { tickets: Ingresso[] }) => {
  const classes = useStyles()
  const refTable = React.useRef(null)
  const csvData = prepareDataForCSV(tickets)
  return (
    <div style={{ paddingTop: 15 }}>
      <Typography variant="body2" align="left" gutterBottom>
        {tickets && tickets.length > 0 ? (
          <>
            Total de dados encontrados: <strong>{tickets.length}</strong>
          </>
        ) : (
          <strong>Nenhum dado encontrado.</strong>
        )}
      </Typography>
      {tickets && tickets.length > 0 && (
        <Paper ref={refTable} className={classes.paper}>
          <Toolbar className={classes.rootToolbarStyles}>
            <Typography
              className={classes.titleToolbarStyles}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Lista de Ingressos
            </Typography>
            <CSVLink
              data={csvData}
              headers={headers}
              target="_blank"
              filename={'lista-de-ingressos.csv'}
              onClick={() => {
                handleDownload()
              }}
            >
              <Tooltip title="Exportar em CSV">
                <IconButton aria-label="exportar em csv">
                  <GetAppRoundedIcon />
                </IconButton>
              </Tooltip>
            </CSVLink>
            <ReactToPrint
              trigger={() => (
                <Tooltip title="Imprimir">
                  <IconButton aria-label="gerar relatório">
                    <PrintRoundedIcon />
                  </IconButton>
                </Tooltip>
              )}
              content={() => refTable.current}
            />
          </Toolbar>
          {/* usado para 'forçar' a orientação da pagina para 'paisagem' afim de imprimir mostrando todos os dados */}
          <style type="text/css" media="print">
            {'\
             @page { size: landscape; }\
            '}
          </style>
          {/* ------------------------------------------------------------>	 */}
          <TableContainer>
            {tickets !== undefined && tickets.length > 0 && (
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell>N.º</TableCell>
                    <TableCell align="center">Cliente</TableCell>
                    <TableCell align="center">CPF/CNPJ</TableCell>
                    <TableCell align="center">Celular</TableCell>
                    <TableCell align="center">Email</TableCell>
                    <TableCell align="center">Tipo Ingresso</TableCell>
                    <TableCell align="center">Valor Pago (R$)</TableCell>
                    <TableCell align="right">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tickets.map((ticket) => (
                    <TableRow hover key={ticket.id}>
                      <TableCell component="th" scope="ticket">
                        {ticket.id}
                      </TableCell>
                      <TableCell align="center">
                        {ticket.usuario.nome}
                      </TableCell>
                      <TableCell align="center">
                        {cpfCnpjMask(ticket.usuario.cpfCnpj)}
                      </TableCell>
                      <TableCell align="center">
                        {phoneMask(ticket.usuario.telefone)}
                      </TableCell>
                      <TableCell align="center">
                        {ticket.usuario.email}
                      </TableCell>
                      <TableCell align="center">
                        {ticket.lote.tipoIngresso.descricao} - (
                        {ticket.lote.descricao})
                      </TableCell>
                      <TableCell align="center">
                        {formatCurrency(ticket.valor - ticket.valorDesconto)}
                      </TableCell>
                      <TableCell align="right">
                        <Label
                          color={getStatusIngressoColor(ticket.statusIngresso)}
                          variant="outlined"
                        >
                          {ticket.statusIngresso.descricao}
                        </Label>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Paper>
      )}
    </div>
  )
}

export default TableIngressos
