import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Divider, Drawer, withStyles } from '@material-ui/core'
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone'
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone'
import LoyaltyTwoToneIcon from '@material-ui/icons/LoyaltyTwoTone'
import LocalConvenienceStoreTwoToneIcon from '@material-ui/icons/LocalConvenienceStoreTwoTone'
import LaptopMacTwoToneIcon from '@material-ui/icons/LaptopMacTwoTone'
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone'
import TitleTwoToneIcon from '@material-ui/icons/TitleTwoTone'
import ConfirmationNumberTwoToneIcon from '@material-ui/icons/ConfirmationNumberTwoTone'
import EventTwoToneIcon from '@material-ui/icons/EventTwoTone'
import PhotoAlbumTwoToneIcon from '@material-ui/icons/PhotoAlbumTwoTone'
import CategoryTwoToneIcon from '@material-ui/icons/CategoryTwoTone'
import PollTwoToneIcon from '@material-ui/icons/PollTwoTone'
import { authActions } from '../../../../_actions'
import { connect, useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Profile, SidebarNav } from './components'
import LiveHelpTwoToneIcon from '@material-ui/icons/LiveHelpTwoTone'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import LocalAtmTwoToneIcon from '@material-ui/icons/LocalAtmTwoTone'
import BuildTwoToneIcon from '@material-ui/icons/BuildTwoTone'
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone'
import LabelTwoToneIcon from '@material-ui/icons/LabelTwoTone'
import SportsEsportsTwoToneIcon from '@material-ui/icons/SportsEsportsTwoTone'
import ThumbsUpDownTwoToneIcon from '@material-ui/icons/ThumbsUpDownTwoTone'
import PieChartTwoToneIcon from '@material-ui/icons/PieChartTwoTone'
import MoneyOffRoundedIcon from '@material-ui/icons/MoneyOffRounded'
import ShoppingCartTwoToneIcon from '@material-ui/icons/ShoppingCartTwoTone'
import HomeWorkTwoToneIcon from '@material-ui/icons/HomeWorkTwoTone'
import SupervisorAccountTwoToneIcon from '@material-ui/icons/SupervisorAccountTwoTone'
import GroupTwoToneIcon from '@material-ui/icons/GroupTwoTone'

const styles = (theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
})

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props
  const dispatch = useDispatch()
  const { user } = props

  const logout = () => {
    dispatch(authActions.logout())
  }
  const { classes } = props

  const isDisabled = useSelector((state) => {
    if (
      state.usuarioEstabelecimento.usuarioEstabelecimento.length <= 0 ||
      !state.authentication.user.emailVerificado ||
      state.usuarioEstabelecimento.isAddPlace
    ) {
      return true
    }
    return false
  })

  const isAddPlace = useSelector((state) => {
    if (state.usuarioEstabelecimento.isAddPlace) {
      return true
    } else {
      return false
    }
  })

  const emailVerificado = useSelector(
    (state) => state.authentication.user.emailVerificado
  )

  const pages = [
    {
      title: 'Home',
      href: '/home',
      icon: <LaptopMacTwoToneIcon />,
      disabled: isAddPlace
    },
    {
      disabled: isDisabled,
      emailVerificado: emailVerificado,
      title: 'Carteira',
      href: '/carteira',
      rule: 'movimentacoesFinanceiras.list',
      icon: <LocalAtmTwoToneIcon />
    },
    {
      disabled: isDisabled,
      emailVerificado: emailVerificado,
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardTwoToneIcon />,
      divider: true
    },
    {
      disabled: isDisabled,
      subtitle: 'Módulos extras',
      emailVerificado: emailVerificado,
      title: 'Membros',
      href: '/members',
      rule: 'membros',
      icon: <GroupTwoToneIcon />,
      divider: true,
      children: [
        {
          title: 'Planos',
          href: '/members/plan'
        },
        {
          title: 'Membros',
          href: '/members/members'
        }
      ]
    },
    {
      emailVerificado: emailVerificado,
      title: 'Estabelecimento',
      href: `/estabelecimento`,
      rule: 'estabelecimentos',
      icon: <LocalConvenienceStoreTwoToneIcon />
    },
    {
      disabled: isDisabled,
      emailVerificado: emailVerificado,
      title: 'Eventos',
      href: '/eventos',
      rule: 'eventos',
      icon: <EventTwoToneIcon />
    },
    {
      disabled: isDisabled,
      emailVerificado: emailVerificado,
      title: 'Fotos',
      href: '/photoAlbum',
      rule: 'albuns.list',
      icon: <PhotoAlbumTwoToneIcon />
    },
    {
      disabled: isDisabled,
      emailVerificado: emailVerificado,
      title: 'Ingressos',
      href: '/ingressos',
      rule: 'ingressos',
      icon: <ConfirmationNumberTwoToneIcon />
    },
    {
      disabled: isDisabled,
      emailVerificado: emailVerificado,
      title: 'Avaliações',
      href: '/avaliacoes',
      rule: 'avaliacoes',
      icon: <ThumbsUpDownTwoToneIcon />
    },
    {
      disabled: isDisabled,
      emailVerificado: emailVerificado,
      title: 'Tipos de Ingresso',
      href: '/tipoIngressos',
      rule: 'tiposIngressos',
      icon: <TitleTwoToneIcon />
    },
    {
      disabled: isDisabled,
      emailVerificado: emailVerificado,
      title: 'Cupons de Desconto',
      href: '/cupomDescontos',
      rule: 'cuponsDesconto',
      icon: <LoyaltyTwoToneIcon />,
      divider: true
    },
    // {
    //   title: 'Meu estabelecimentos',
    //   href: '/estabelecimentos',
    //   icon: < LocalConvenienceStoreTwoToneIcon />
    // },
    {
      disabled: isDisabled,
      emailVerificado: emailVerificado,
      title: 'Movimentações Financeiras',
      href: '/movimentacoesFinanceiras',
      rule: 'movimentacoesFinanceiras.finish',
      icon: <AccountBalanceTwoToneIcon />
    },
    {
      disabled: isDisabled,
      emailVerificado: emailVerificado,
      title: 'Cancelamentos Ingressos',
      href: '/cancelamentosIngressos',
      rule: 'cancelamentosIngressos.finish',
      icon: <MoneyOffRoundedIcon />
    },
    {
      disabled: isDisabled,
      emailVerificado: emailVerificado,
      title: 'Dashboard Administrativo',
      href: '/dashboardAdministrativo',
      rule: 'dashboardAdmin',
      icon: <PieChartTwoToneIcon />
    },
    {
      disabled: isDisabled,
      emailVerificado: emailVerificado,
      title: 'Pedidos',
      href: '/pedidos/admin',
      rule: 'pedidos.listAdmin',
      icon: <ShoppingCartTwoToneIcon />
    },
    {
      disabled: isDisabled,
      emailVerificado: emailVerificado,
      title: 'Estabelecimentos',
      href: '/estabelecimentos',
      rule: 'estabelecimentos.listAdmin',
      icon: <HomeWorkTwoToneIcon />
    },
    {
      disabled: isDisabled,
      emailVerificado: emailVerificado,
      title: 'Usuários',
      href: '/usuarios',
      rule: 'usuarios.listAdmin',
      icon: <SupervisorAccountTwoToneIcon />
    },
    {
      disabled: isDisabled,
      emailVerificado: emailVerificado,
      title: 'Taxas',
      href: '/taxas',
      rule: 'taxas',
      icon: <PollTwoToneIcon />
    },
    {
      disabled: isDisabled,
      emailVerificado: emailVerificado,
      title: 'Categorias',
      href: '/categorias',
      rule: 'categorias.edit',
      icon: <CategoryTwoToneIcon />
    },
    {
      disabled: isDisabled,
      emailVerificado: emailVerificado,
      title: 'Hashtags',
      href: '/hashtags',
      rule: 'hashtags.edit',
      icon: <LabelTwoToneIcon />
    },
    {
      disabled: isDisabled,
      emailVerificado: emailVerificado,
      title: 'Conquistas',
      href: '/conquistas',
      rule: 'conquistas.edit',
      icon: <SportsEsportsTwoToneIcon />
    },
    {
      disabled: isDisabled,
      emailVerificado: emailVerificado,
      title: 'Parâmetros',
      href: '/parametros',
      rule: 'parametros',
      icon: <BuildTwoToneIcon />
    },
    {
      disabled: isDisabled,
      emailVerificado: emailVerificado,
      title: 'Permissões',
      href: '/permissoes',
      rule: 'permissoes',
      icon: <VpnKeyIcon />,
      divider: true
    },
    // {
    //   title: 'Promoters',
    //   href: '/promoters',
    //   icon: < RecordVoiceOverTwoToneIcon />
    // },
    {
      title: 'Ajuda',
      href: '/ajuda',
      disabled: isAddPlace,
      icon: <LiveHelpTwoToneIcon />
    },
    {
      title: 'Configurações',
      href: '/settings',
      disabled: isAddPlace,
      icon: <SettingsTwoToneIcon />
    },
    {
      title: 'Sair',
      href: '/login',
      icon: <ExitToAppTwoToneIcon />,
      onClick: () => {
        logout()
      }
    }
  ]

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <PerfectScrollbar>
        <div {...rest} className={clsx(classes.root, className)}>
          <Profile user={user} />
          <Divider className={classes.divider} />
          <SidebarNav className={classes.nav} pages={pages} />
        </div>
      </PerfectScrollbar>
    </Drawer>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user
  }
}
const connectedSidebarPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Sidebar))
)
export { connectedSidebarPage as Sidebar }
