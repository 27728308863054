import React from 'react'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { MdCheckCircle, MdError, MdLink } from 'react-icons/md'

import { Container, FileInfo, Preview } from './styles'

const FileList = ({ files }) => (
  <Container>
    {files.map((uploadedFile) => (
      <li key={uploadedFile.id}>
        <FileInfo>
          <Preview src={uploadedFile.preview} />
          <div>
            <strong>{uploadedFile.name}</strong>
            <span>
              {uploadedFile.readableSize}{' '}
              {/* {!!uploadedFile.url && (
                <button onClick={() => onDelete(uploadedFile.id)}>
                  Excluir
                </button>
              )} */}
            </span>
          </div>
        </FileInfo>

        <div>
          {!uploadedFile.uploaded && !uploadedFile.error && (
            <CircularProgressbarWithChildren
              value={uploadedFile.progress}
              styles={{
                root: { width: 100 },
                path: { stroke: '#FFC20A' },
                textSize: '16px',
                strokeLinecap: 'butt'
              }}
            >
              {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
              <img
                style={{ width: 35, marginTop: -5 }}
                // eslint-disable-next-line no-undef
                src={require('../../../../../img/logo_progress.png')}
              />
              <div style={{ fontSize: 14, marginTop: 5 }}>
                <strong>{uploadedFile.progress}</strong> %
              </div>
            </CircularProgressbarWithChildren>
          )}
          {uploadedFile.url && (
            <a
              href={uploadedFile.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MdLink style={{ marginRight: 8 }} size={24} color="#222" />
            </a>
          )}

          {uploadedFile.uploaded && <MdCheckCircle size={24} color="#78e5d5" />}
          {uploadedFile.error && <MdError size={24} color="#e57878" />}
        </div>
      </li>
    ))}
  </Container>
)

export default FileList
