import { colors } from '@material-ui/core'

export default function GetRandomColorsChart(numberOfColors) {
  const colorsArray = Object.keys(colors)

  //remove o index 'grey' and 'black'
  colorsArray.splice(colorsArray.indexOf('grey' || 'black'), 1)

  const colorsArrayRandom = []
  for (let i = 0; i < numberOfColors; i++) {
    const randomColor =
      colorsArray[Math.floor(Math.random() * colorsArray.length)]
    if (!colorsArrayRandom.some((color) => color === colors[randomColor])) {
      colorsArrayRandom.push(colors[randomColor])
    } else {
      i--
    }
  }
  return colorsArrayRandom
}
