import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
  Divider
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

const Parametros = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Parâmetros" />
      <Divider />
      <CardContent>
        <form>
          <Grid container spacing={6} wrap="wrap">
            <Grid className={classes.item} item md={4} sm={6} xs={12}>
              <Typography gutterBottom variant="h6">
                Sistema
              </Typography>
              <Typography gutterBottom variant="body2">
                Você receberá os e-mails do sistema
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked //
                  />
                }
                label="Alertas de novas vendas"
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked //
                  />
                }
                label={
                  <Fragment>
                    <Typography variant="body1">Phone calls</Typography>
                    <Typography variant="caption">
                      Short voice phone updating you
                    </Typography>
                  </Fragment>
                }
              /> */}
            </Grid>
            {/* <Grid className={classes.item} item md={4} sm={6} xs={12}>
              <Typography gutterBottom variant="h6">
                Chat App
              </Typography>
              <Typography gutterBottom variant="body2">
                You will recieve emails in your business email address
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked //
                  />
                }
                label="Email"
              />
              <FormControlLabel
                control={<Checkbox color="primary" defaultChecked />}
                label="Push notifications"
              />
            </Grid> */}
          </Grid>
        </form>
      </CardContent>
      <Divider />
      <CardActions>
        <Button variant="contained" color="primary">
          Salvar alterações
        </Button>
      </CardActions>
    </Card>
  )
}

Parametros.propTypes = {
  className: PropTypes.string
}

export default Parametros
