import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { CardRetiradas } from './components'
import { useDispatch, useSelector } from 'react-redux'
import { movimentacaoFinanceiraAction } from '_actions'
import { AccessControl } from 'components/Utils/AccessControl'

const useStyles = makeStyles((theme) => ({
  root: {},
  value: {
    fontWeight: theme.typography.fontWeightMedium
  },
  card: {
    borderRadius: '8px',
    boxShadow: '0px 2px 8px rgb(0 0 0 / 8%)',
    height: 200
  }
}))

const Retiradas = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const { movimentacaoFinanceira } = useSelector(
    (state) => state.movimentacaoFinanceira
  )
  const estabelecimentoId = useSelector(
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const movimentacaoFinanceiraEmAndamento = movimentacaoFinanceira.filter(
    (c) => c.statusMovimentacaoFinanceira.id === 1
  )
  const movimentacaoFinanceiraConcluido = movimentacaoFinanceira.filter(
    (c) => c.statusMovimentacaoFinanceira.id !== 1
  )
  useEffect(() => {
    dispatch(
      movimentacaoFinanceiraAction.getMovimentacaoFinanceiraByEstabelecimento(
        estabelecimentoId
      )
    )
    return () => {
      dispatch(movimentacaoFinanceiraAction.clear())
    }
  }, [estabelecimentoId])

  return (
    <AccessControl
      rule={'movimentacoesFinanceiras.list'}
      yes={() => (
        <Grid
          spacing={2}
          container
          {...rest}
          className={clsx(classes.root, className)}
        >
          <Grid item md={6} xs={12}>
            <CardRetiradas
              className={classes.card}
              movimentacoesFinanceiras={movimentacaoFinanceiraEmAndamento}
              title="Retiradas em andamento"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CardRetiradas
              className={classes.card}
              movimentacoesFinanceiras={movimentacaoFinanceiraConcluido}
              title="Retiradas finalizadas"
            />
          </Grid>
        </Grid>
      )}
    />
  )
}

Retiradas.propTypes = {
  className: PropTypes.string
}

export default Retiradas
