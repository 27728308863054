import React from 'react'
import Lottie from 'react-lottie'
import { Avatar, Box, Fade, Typography, makeStyles } from '@material-ui/core'

//? Own imports
import animationData from 'assets/animations/surprise-box.json'
import { User } from 'types/api'
import { getInitials } from 'utils'

const useStyles = makeStyles((theme) => ({
  avatar: {
    [theme.breakpoints.down('xs')]: {
      width: '18vw',
      height: '18vw'
    },
    [theme.breakpoints.up('sm')]: {
      width: '15vw',
      height: '15vw'
    },
    [theme.breakpoints.up('md')]: {
      width: '8vw',
      height: '8vw'
    },
    border: '3px solid #fff',
    boxShadow: '0 0 0 2px #fff'
  },
  avatarContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    top: '-7px',
    left: '-2px'
  },
  animation: {
    width: '100%',
    height: '100%',
    position: 'relative'
  }
}))

export default function WinnerAnimation({ user }: { user: User | undefined }) {
  const [showUser, setShowUser] = React.useState(false)
  const classes = useStyles()
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <>
      {user && user.id ? (
        <div
          style={{
            overflow: 'hidden',
            overflowY: 'hidden',
            overflowX: 'hidden'
          }}
        >
          <Box padding={2}>
            <Typography variant="h3" align="center" gutterBottom>
              Já temos um vencedor!
            </Typography>
            <Fade in={showUser}>
              <Typography variant="h3" align="center">
                Parabéns, {user.apelido || user.nome}! (@{user.username})
              </Typography>
            </Fade>
            <div className={classes.animation}>
              <Lottie
                eventListeners={[
                  {
                    eventName: 'complete',
                    callback: () => {
                      setShowUser(true)
                    }
                  }
                ]}
                speed={0.7}
                options={defaultOptions}
                height="95%"
                width="95%"
              />
              <div className={classes.avatarContainer}>
                <Fade in={showUser}>
                  <Avatar className={classes.avatar} src={user.imagem}>
                    {getInitials(user.nome)}
                  </Avatar>
                </Fade>
              </div>
            </div>
          </Box>
        </div>
      ) : null}
    </>
  )
}
