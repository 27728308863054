import React from 'react'
import { Grid } from '@material-ui/core'

import {
  PedidosPendentes,
  CuponsAtivos,
  IngressosDisponiveis,
  IngressosVendidos,
  GraficoVendasPorDia,
  GraficoIngressosTipoIngresso
} from '../../components'

const EventoDashboard = () => {
  return (
    <Grid container spacing={2}>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <PedidosPendentes />
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <CuponsAtivos />
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <IngressosDisponiveis />
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <IngressosVendidos />
      </Grid>
      <Grid item lg={8} md={12} xl={9} xs={12}>
        <GraficoVendasPorDia />
      </Grid>
      <Grid item lg={4} md={12} xl={3} xs={12}>
        <GraficoIngressosTipoIngresso />
      </Grid>
      {/* <Grid item lg={12} md={12} xl={12} xs={12}>
          <LatestOrders />
        </Grid> */}
    </Grid>
  )
}

export default EventoDashboard
