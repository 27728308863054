const initialState = {
  anchor: 'left',
  permissaoTipoUsuario: [],
  open: false,
  id: 0,
  permissaoId: 0,
  tipoUsuarioId: 0,
  permitido: false,
  checkedAll: false,
  isLoading: false
}

export function permissaoTipoUsuario(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_PERMISSAO_TIPO_USUARIO':
      return {
        ...state,
        permissaoTipoUsuario: action.permissaoTipoUsuario,
        tipoUsuarioId: action.tipoUsuarioId
      }
    case 'PERMISSAO_TIPO_USUARIO_DETAIL':
      return {
        ...state,
        id: action.id,
        permissaoId: action.permissaoId,
        tipoUsuarioId: action.tipoUsuarioId,
        permitido: action.permitido
      }
    case 'PERMISSAO_TIPO_USUARIO_CLEAR':
      return initialState
    case 'PERMISSAO_TIPO_USUARIO_CLEAR_ALL':
      return {
        ...state,
        permissaoTipoUsuario: []
      }
    case 'PERMISSAO_TIPO_USUARIO_CREATE_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'PERMISSAO_TIPO_USUARIO_CREATE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        tipoUsuarioId: 0,
        checkedAll: false,
        permissaoTipoUsuario: []
      }
    case 'PERMISSAO_TIPO_USUARIO_CREATE_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'PERMISSAO_TIPO_USUARIO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'HANDLE_CHANGE_CHECK_ALL_PERMISSAO_TIPO_USUARIO':
      return {
        ...state,
        checkedAll: !state.checkedAll
      }
    case 'HANDLE_CHECKED_PERMITIDO_PERMISSAO_TIPO_USUARIO':
      return {
        ...state,
        permissaoTipoUsuario: state.permissaoTipoUsuario.map(
          (permissaoTipoUsuario) =>
            permissaoTipoUsuario.id === action.id
              ? {
                ...permissaoTipoUsuario,
                permitido: !permissaoTipoUsuario.permitido
              }
              : permissaoTipoUsuario
        )
      }
    case 'HANDLE_CHECK_ALL_PERMISSAO_TIPO_USUARIO':
      return {
        ...state,
        permissaoTipoUsuario: state.permissaoTipoUsuario.map(
          (permissaoTipoUsuario) => ({
            ...permissaoTipoUsuario,
            permitido: action.checked
          })
        )
      }
    default:
      return state
  }
}
