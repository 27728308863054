import { useQuery } from 'react-query'
import { toast } from 'react-toastify'

//? Own imports
import { crudService } from '_services/'
import {
  MemberPlanDetailsParams,
  MemberPlanDetailsReturn,
  UseQueryOptions
} from './member'

const getMemberPlanDetails = async (
  params?: MemberPlanDetailsParams
): Promise<MemberPlanDetailsReturn> => {
  try {
    const { data } = await crudService.get(
      `membrosplanos/${params?.memberPlanId}`
    )
    if (data) {
      return data
    } else {
      throw new Error('Invalid response')
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export default function useMemberPlanDetails(
  params?: MemberPlanDetailsParams,
  options?: UseQueryOptions<MemberPlanDetailsReturn>
) {
  return useQuery<MemberPlanDetailsReturn>(
    ['memberPlanDetails', params],
    () => getMemberPlanDetails(params),
    {
      enabled: !!params?.memberPlanId,
      staleTime: 600000, // 10 minutes
      ...options?.config,
      onError: (error) => {
        // @ts-ignore
        toast.error(error?.message || 'Erro ao buscar detalhes do membro', {
          toastId: 'memberPlanDetails'
        })
      }
    }
  )
}
