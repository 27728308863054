import React from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  colors,
  makeStyles
} from '@material-ui/core'
import {
  differenceInMonths,
  differenceInWeeks,
  format,
  formatRelative,
  parseISO
} from 'date-fns'
import { useParams } from 'react-router-dom'
import brLocale from 'date-fns/locale/pt-BR'
import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded'
import PeopleOutlineRoundedIcon from '@material-ui/icons/PeopleOutlineRounded'

//? Own imports
import { Label } from 'components'
import { MemberPlan } from 'types/api'
import constants from 'theme/constants'
import { formatCurrency, getPeriodicityString, history } from 'utils'

const calculateMembershipTime = (startDate: string, endDate: string) => {
  const start = parseISO(startDate)
  const end = parseISO(endDate)

  const months = differenceInMonths(end, start)
  const weeks = differenceInWeeks(end, start) - months * 4

  if (months === 1 && weeks <= 3) {
    return `${months} mês e ${weeks} semanas`
  } else if (months > 1 && weeks <= 3) {
    return `${months} meses e ${weeks} semanas`
  } else if (months === 1 && weeks > 3) {
    return `${months + 1} meses`
  } else if (months > 1 && weeks > 3) {
    return `${months + 1} meses`
  } else if (months === 0 && weeks === 1) {
    return `${weeks} semana`
  } else if (months === 0 && weeks > 1) {
    return `${weeks} semanas`
  } else {
    return `${months} meses`
  }
}

const useStyles = makeStyles(() => ({
  root: {
    ...constants.shadowCard
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  content: {
    padding: 0
  }
}))

type ExtraInfoProps = {
  memberPlan: MemberPlan
}

const ExtraInfo = ({ memberPlan }: ExtraInfoProps) => {
  const classes = useStyles()
  const { id: memberId } = useParams<{ tab: string; id: string }>()
  const { plano, planoValor } = memberPlan

  return (
    <Card className={classes.root}>
      <CardHeader title="Dados da associação" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Plano</TableCell>
              <TableCell>{plano?.nome}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Status</TableCell>
              <TableCell>
                <div>
                  <Label
                    color={
                      memberPlan?.statusMembroPlanoId === 1 ? colors.green[600] : colors.red[600]
                    }
                  >
                    {memberPlan?.statusMembroPlano ? memberPlan?.statusMembroPlano.descricao : 'INDEFINIDO'}
                  </Label>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Periodicidade</TableCell>
              <TableCell>
                {getPeriodicityString(planoValor?.periodicidade || '')}
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Valor</TableCell>
              <TableCell>{formatCurrency(planoValor?.valor)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Data de início</TableCell>
              <TableCell>
                {memberPlan?.dataHoraInicio
                  ? format(new Date(memberPlan.dataHoraInicio), 'dd/MM/yyyy')
                  : 'Não informado'}
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Data de término</TableCell>
              <TableCell>
                {memberPlan?.dataHoraFim
                  ? format(new Date(memberPlan.dataHoraFim), 'dd/MM/yyyy')
                  : 'Não informado'}
                <br></br>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tempo de associação</TableCell>
              <TableCell>
                {memberPlan?.dataHoraInicio
                  ? calculateMembershipTime(
                      memberPlan.dataHoraInicio,
                      memberPlan.dataHoraFim || new Date()?.toISOString()
                    )
                  : 'Não informado'}
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Próxima cobrança</TableCell>
              <TableCell>
                {memberPlan && memberPlan?.dataHoraFim
                  ? format(new Date(memberPlan?.dataHoraFim), 'dd/MM/yyyy')
                  : 'Não informado'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          variant="outlined"
          startIcon={<PeopleOutlineRoundedIcon />}
          disabled={true}
          // onClick={}
        >
          Ver plano
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<AccountBalanceRoundedIcon />}
          onClick={() =>
            history.push(`/members/memberPlanDetails/${memberId}/financeiro`)
          }
        >
          Financeiro
        </Button>
      </CardActions>
    </Card>
  )
}

export default ExtraInfo
