import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { permissaoUsuarioAction, usuarioAction } from '../../../../_actions'
import {
  MenuItem,
  Grid,
  makeStyles,
  FormControl,
  Select,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Button,
  useTheme,
  useMediaQuery,
  CircularProgress
} from '@material-ui/core'
import { Page } from 'components'
import TablePermissoes from '../TablePermissoes/TablePermissoes'
import DoneRoundedIcon from '@material-ui/icons/DoneRounded'
import { AccessControl } from 'components/Utils/AccessControl'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2, 2, 2)
  },
  formControl: {
    minWidth: 260
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  content: {
    marginBottom: theme.spacing(2)
  },
  buttonSubmit: {
    marginTop: theme.spacing(3)
  }
}))

const PermissaoUsuario = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { usuario, permissaoUsuario } = useSelector((state) => state)
  const { usuarioId, isLoading } = permissaoUsuario
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isEmptyPermissaoUsuario =
    permissaoUsuario.permissaoUsuario.length === 0 ? true : false

  useEffect(() => {
    dispatch(usuarioAction.getUsuarioSelect())
    dispatch(permissaoUsuarioAction.clear())
  }, [])

  useEffect(() => {
    dispatch(
      permissaoUsuarioAction.handleCheckAllPermissaoUsuario(
        permissaoUsuario.checkedAll
      )
    )
  }, [permissaoUsuario.checkedAll])

  const handleChangeSelected = () => (event) => {
    if (event.target.value && event.target.value > 0) {
      dispatch(permissaoUsuarioAction.getPermissaoUsuario(event.target.value))
    } else {
      dispatch(permissaoUsuarioAction.clear())
    }
  }

  const handleChangePermissaoUsuario = (idPermissaoUsuario) => {
    dispatch(permissaoUsuarioAction.handleCheckedPermitido(idPermissaoUsuario))
  }

  const handleSubmitPermissaoUsuario = () => {
    dispatch(
      permissaoUsuarioAction.createPermissaoUsuario(
        permissaoUsuario.permissaoUsuario
      )
    )
  }
  const handleChangeCheckedAll = () => {
    dispatch(permissaoUsuarioAction.handleChangeCheckAll())
  }

  return (
    <AccessControl
      rule="permissoesUsuarios"
      yes={() => (
        <Page title="Permissões - Usuário">
          <Grid
            className={classes.content}
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth={isMobile ? true : false}
                size="small"
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="permissao-usuario-label">Usuário</InputLabel>
                <Select
                  label="Usuário"
                  labelId="permissao-usuario-label"
                  id="permissaoUsuario  "
                  value={usuarioId}
                  onChange={handleChangeSelected('usuarioId')}
                >
                  <MenuItem disabled value="">
                    <em>Selecione...</em>
                  </MenuItem>
                  {usuario.usuario.map((row) => (
                    <MenuItem key={row.value} value={row.value}>
                      {row.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              style={isMobile ? { textAlign: 'left' } : { textAlign: 'right' }}
              item
              xs={12}
              sm={6}
            >
              <FormControlLabel
                disabled={isEmptyPermissaoUsuario}
                control={
                  <Checkbox
                    checked={permissaoUsuario.checkedAll}
                    onChange={handleChangeCheckedAll}
                    name="Marcar/desmarcar todos"
                    color="primary"
                  />
                }
                label="Marcar/desmarcar todos"
              />
            </Grid>
          </Grid>
          {!isEmptyPermissaoUsuario && (
            <>
              <TablePermissoes
                permissoes={permissaoUsuario.permissaoUsuario}
                onCheckedPermissao={handleChangePermissaoUsuario}
              ></TablePermissoes>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-end"
              >
                <Grid item xs={12}>
                  <Button
                    disabled={isLoading}
                    endIcon={
                      isLoading ? (
                        <CircularProgress color="inherit" size={24} />
                      ) : (
                        <DoneRoundedIcon />
                      )
                    }
                    className={classes.buttonSubmit}
                    variant="contained"
                    onClick={handleSubmitPermissaoUsuario}
                    color="primary"
                  >
                    {isLoading ? 'Enviando informações...' : 'Salvar'}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Page>
      )}
    ></AccessControl>
  )
}

export default PermissaoUsuario
