import { useQuery } from 'react-query'

//? Own imports
import { crudService } from '_services'
import { User } from '../types/api'

const getUserInfo = async (id: number) => {
  const { data } = await crudService.get(`usuarios/admin/${id}`)
  return data
}

export default function useUserInfo(id: number) {
  return useQuery<User>(['user-info', id], () => getUserInfo(id), {
    enabled: id > 0
  })
}
