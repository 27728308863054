import { useQuery } from 'react-query'

import { crudService } from '_services'
import { Cidade } from '../../types/api'

const getCitiesByStateId = async (id: number) => {
  const { data } = await crudService.get(`cidades/estado/${id}`)
  return data
}

export default function useCitiesByStateId(id: number) {
  return useQuery<Cidade[]>(
    ['citiesByStateId-list'],
    () => getCitiesByStateId(id),
    {
      enabled: id > 0
    }
  )
}
