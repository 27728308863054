import React, { useEffect, useState } from 'react'
import {
  IconButton,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Slide,
  Grid,
  FormControl,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  Button,
  useTheme,
  useMediaQuery,
  Typography,
  Zoom,
  Tooltip,
  CircularProgress
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { contaBancariaAction, movimentacaoFinanceiraAction } from '_actions'
import { formatCurrency } from 'utils'
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded'
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded'
import CloseIcon from '@material-ui/icons/Close'
import { CheckCircleTwoTone } from '@material-ui/icons'
import { SuccessMessage, CardContaBancaria } from './components'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500]
  },
  totalLabel: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      bottom: theme.spacing(8),
      right: theme.spacing(2)
    }
  }
}))

export default function ModalSolicitarRetirada({
  ingressos,
  setOpenModal,
  openModal,
  valorTotalARetirar
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const movimentacaoFinanceira = useSelector(
    (state) => state.movimentacaoFinanceira
  )
  const { isLoading } = movimentacaoFinanceira
  const { contaBancaria } = useSelector((state) => state.contaBancaria)
  const estabelecimentoId = useSelector(
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const [contaBancariaSelecionada, setContaBancariaSelecionada] = useState('')
  const [showRadioSelect, setShowRadioSelect] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  function findContaBancaria(id) {
    const contaBancariaNew = contaBancaria.find((x) => x.id == id)
    return contaBancariaNew
  }
  const handleChange = (event) => {
    const contaBancariaNew = findContaBancaria(event.target.value)
    setContaBancariaSelecionada(contaBancariaNew)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const createObjectIngressos = (ingressos) => {
    const ingressosFormatted = []
    ingressos.map((ingresso) => {
      let ingressoNew = {
        id: 0,
        movimentacaoFinanceiraId: 0,
        ingressoId: ingresso.id,
        excluido: null
      }
      ingressosFormatted.push(ingressoNew)
    })
    return ingressosFormatted
  }

  const handleSubmit = () => {
    let payload = {
      id: 0,
      statusMovimentacaoFinanceiraId: 0,
      estabelecimentoId: estabelecimentoId,
      dataHoraProcessamento: '',
      valor: 0,
      contaBancariaId: contaBancariaSelecionada.id,
      anexo: null,
      motivoCancelamento: null,
      movimentacaoFinanceiraIngresso: createObjectIngressos(ingressos)
    }
    dispatch(movimentacaoFinanceiraAction.createMovimentacaoFinanceira(payload))
  }

  useEffect(() => {
    dispatch(
      contaBancariaAction.getContaBancariaByEstabelecimento(estabelecimentoId)
    )
  }, [estabelecimentoId])

  useEffect(() => {
    setContaBancariaSelecionada(contaBancaria.find((x) => x.padrao === true))
  }, [contaBancaria])

  useEffect(() => {
    dispatch(movimentacaoFinanceiraAction.clear())
  }, [])

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      TransitionComponent={Transition}
      open={openModal}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle disableTypography id="responsive-dialog-title">
        <Typography variant="h4">Solicitação de retirada</Typography>
        <IconButton
          size="small"
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {movimentacaoFinanceira !== undefined &&
        movimentacaoFinanceira.id > 0 ? (
          <SuccessMessage />
        ) : (
          <Grid
            spacing={3}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            alignContent="space-between"
          >
            <Grid item xs={12}>
              <CardContaBancaria contaBancaria={contaBancariaSelecionada} />
              {contaBancaria !== undefined && contaBancaria.length > 1 && (
                <Button
                  onClick={() => setShowRadioSelect(!showRadioSelect)}
                  size="small"
                  style={{ marginTop: 15 }}
                  variant="outlined"
                  color="primary"
                  startIcon={
                    showRadioSelect ? (
                      <ExpandLessRoundedIcon />
                    ) : (
                      <ExpandMoreRoundedIcon />
                    )
                  }
                >
                  Trocar conta/Pix
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              {showRadioSelect && (
                <Zoom in={showRadioSelect}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Contas bancárias</FormLabel>
                    <RadioGroup
                      aria-label="conta bancaria"
                      name="contaBancaria"
                      value={
                        contaBancariaSelecionada !== undefined &&
                        contaBancariaSelecionada.id
                      }
                      onChange={handleChange}
                    >
                      {contaBancaria.map((contaBancaria) => {
                        return (
                          <FormControlLabel
                            key={contaBancaria.id}
                            value={contaBancaria.id}
                            control={<Radio color="primary" size="small" />}
                            label={
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  flexWrap: 'wrap'
                                }}
                              >
                                <Typography
                                  style={{ paddingRight: 10 }}
                                  color="initial"
                                >
                                  {contaBancaria.conta === '0'
                                    ? `${contaBancaria.descricao} (pix)`
                                    : contaBancaria.descricao}
                                </Typography>
                                {contaBancaria.padrao && (
                                  <Tooltip title="Conta padrão">
                                    <CheckCircleTwoTone
                                      color="primary"
                                      fontSize="small"
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            }
                          />
                        )
                      })}
                    </RadioGroup>
                  </FormControl>
                </Zoom>
              )}
            </Grid>
          </Grid>
        )}
        {movimentacaoFinanceira !== undefined &&
          movimentacaoFinanceira.id === 0 && (
            <>
              <Typography
                gutterBottom
                align="justify"
                variant="caption"
                color="textPrimary"
              >
                Se estiver tudo certo com seu pedido, o dinheiro estará em sua
                conta bancária em até dois dias úteis.
              </Typography>
              <Typography
                className={classes.totalLabel}
                variant="h4"
                align="right"
                color="textPrimary"
              >
                {`Valor total à retirar: ${formatCurrency(valorTotalARetirar)}`}
              </Typography>
            </>
          )}
      </DialogContent>
      {movimentacaoFinanceira !== undefined && movimentacaoFinanceira.id === 0 && (
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={isLoading}
            color="primary"
            autoFocus
            endIcon={isLoading && <CircularProgress size={24} />}
          >
            {isLoading ? 'Solicitando...' : 'Confirmar'}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
