import { crudService } from '../_services/'
import { history } from '../utils'
import { toast } from 'react-toastify'

export const tipoIngressoAction = {
  getTipoIngresso,
  getTipoIngressoById,
  getTipoIngressoByEstabelecimento,
  onChangeProps,
  editTipoIngressoInfo,
  createTipoIngresso,
  deleteTipoIngressoById,
  clear
}
function getTipoIngresso() {
  return (dispatch) => {
    let apiEndpoint = 'tiposIngressos'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeTipoIngressosList(response.data))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
function getTipoIngressoByEstabelecimento(estabelecimentoId) {
  return (dispatch) => {
    dispatch(request({ estabelecimentoId }))
    let apiEndpoint = 'tiposIngressos/estabelecimento/' + estabelecimentoId
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(changeTipoIngressosList(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request(estabelecimentoId) {
    return { type: 'FETCHED_ALL_TIPOINGRESSO_REQUEST', estabelecimentoId }
  }

  function success() {
    return {
      type: 'FETCHED_ALL_TIPOINGRESSO_SUCCESS'
    }
  }

  function failure(error) {
    return { type: 'FETCHED_ALL_TIPOINGRESSO_FAILURE', error }
  }
}

function createTipoIngresso(payload, redirect = false) {
  return (dispatch) => {
    let apiEndpoint = 'tiposIngressos/'
    crudService.post(apiEndpoint, payload).then((response) => {
      dispatch(createTipoDeIngressoInfo(response.data))
      toast.success('Feito! Tipo de Ingresso criado! 😁')
      if (redirect) {
        history.push('/tipoIngressos')
      }
    })
  }
}
function getTipoIngressoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'tiposIngressos/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editTipoIngressosDetails(response.data))
    })
  }
}
function onChangeProps(props, event) {
  return (dispatch) => {
    dispatch(handleOnChangeProps(props, event.target.value))
  }
}
function editTipoIngressoInfo(id, payload) {
  return (dispatch) => {
    let apiEndpoint = 'tiposIngressos/' + id
    crudService.put(apiEndpoint, payload).then(() => {
      dispatch(updatedUserInfo())
      history.push('/tipoIngressos')
    })
  }
}
function deleteTipoIngressoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'tiposIngressos/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteTipoIngressosDetails())
      dispatch(
        tipoIngressoAction.getTipoIngressoByEstabelecimento(
          response.data.estabelecimentoId
        )
      )
    })
  }
}
export function changeTipoIngressosList(tipoIngresso) {
  return {
    type: 'FETCHED_ALL_TIPOINGRESSO',
    tipoIngresso: tipoIngresso
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'TIPOINGRESSO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editTipoIngressosDetails(tipoIngresso) {
  return {
    type: 'TIPOINGRESSO_DETAIL',
    id: tipoIngresso._id,
    descricao: tipoIngresso.descricao,
    estabelecimentoId: tipoIngresso.estabelecimentoId,
    excluido: tipoIngresso.excluido,
    estabelecimento: tipoIngresso.estabelecimento,
    lote: tipoIngresso.lote
  }
}

export function clear() {
  return {
    type: 'TIPOINGRESSO_CLEAR'
  }
}

export function updatedUserInfo() {
  return {
    type: 'TIPOINGRESSO_UPDATED'
  }
}
export function createTipoDeIngressoInfo(tipoDeIngresso) {
  return {
    type: 'TIPOINGRESSO_CREATED_SUCCESSFULLY',
    tipoIngresso: tipoDeIngresso
  }
}
export function deleteTipoIngressosDetails() {
  return {
    type: 'DELETED_TIPOINGRESSO_DETAILS'
  }
}
