import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import multi from 'redux-multi'
import reducer from './_reducers'
import { unregister as unregisterServiceWorker } from './registerServiceWorker'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-NXLCXWX'
}

TagManager.initialize(tagManagerArgs)

const persistConfig = {
  key: 'coruja-admin-root',
  storage,
  blacklist: ['authentication', 'album']
}
const persistedReducer = persistReducer(persistConfig, reducer)
const devTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//Define a store do redux (agrupa todos reducers da aplicação, atravez do arquivo reducer from './_reducers';)
const store = applyMiddleware(thunk, multi)(createStore)(
  persistedReducer,
  devTools
)
const persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
unregisterServiceWorker()
