import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

//? Own imports
import { CancelamentoIngresso } from 'types/api'
import { crudService } from '_services'

const postFinishOrderCancel = async (orderCancel: CancelamentoIngresso) => {
  const { data } = await crudService.post(
    `cancelamentosIngressos/finish/${orderCancel.id}`
  )
  return data
}

export default function useMutationFinishOrderCancel() {
  const queryClient = useQueryClient()
  return useMutation(
    'mutationFinishOrderCancel',
    (payload: CancelamentoIngresso) => postFinishOrderCancel(payload),
    {
      onError: (error) => {
        // @ts-ignore
        toast.error(error.message || 'Erro ao finalizar cancelamento')
      },
      onSuccess: (data: CancelamentoIngresso) => {
        queryClient.cancelQueries(['ordersCancel'])
        queryClient.invalidateQueries(['ordersCancel'])
        toast.success('Cancelamento finalizado com sucesso!')
      }
    }
  )
}
