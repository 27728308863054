const initialState = {
  tipoIngresso: [],
  isLoading: false,
  id: '',
  descricao: '',
  estabelecimentoId: 0,
  excluido: false
}

export function tipoIngresso(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_TIPOINGRESSO':
      return {
        ...state,
        tipoIngresso: action.tipoIngresso
      }
    case 'FETCHED_ALL_TIPOINGRESSO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'FETCHED_ALL_TIPOINGRESSO_SUCCESS':
      return {
        ...state,
        isLoading: false
      }
    case 'FETCHED_ALL_TIPOINGRESSO_FAILURE':
      return {
        ...state,
        isLoading: false
      }
    case 'TIPOINGRESSO_DETAIL':
      return {
        ...state,
        id: action.id,
        descricao: action.descricao,
        estabelecimentoId: action.estabelecimentoId,
        excluido: action.excluido,
        estabelecimento: action.estabelecimento,
        lote: action.lote
      }
    case 'TIPOINGRESSO_CREATED_SUCCESSFULLY':
      return {
        ...state,
        id: '',
        descricao: '',
        estabelecimentoId: 0,
        excluido: false,
        tipoIngresso: [...state.tipoIngresso, action.tipoIngresso]
      }
    case 'TIPOINGRESSO_UPDATED':
      return state
    case 'TIPOINGRESSO_CLEAR':
      return initialState
    case 'TIPOINGRESSO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    default:
      return state
  }
}
