import React, { useEffect, useState } from 'react'
import {
  Typography,
  Grid,
  Button,
  ButtonGroup,
  Chip,
  makeStyles,
  useTheme,
  useMediaQuery
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import {
  format,
  subDays,
  differenceInDays,
  isAfter,
  isBefore,
  endOfDay,
  startOfDay,
  startOfYesterday,
  endOfYesterday
} from 'date-fns'
import CalendarTodayIcon from '@material-ui/icons/CalendarTodayOutlined'
import { useLocation } from 'react-router-dom'

//? Own imports
import { history } from 'utils'
import { useDidMountEffect } from 'utils/useDidMountEffect'
import { Evento } from 'types/api'
import { useEvents } from 'hooks-querys'
import { useSelector } from 'react-redux'

type ChipType = {
  label: string
  value: string
}
const chipOptions: ChipType[] = [
  { label: 'Hoje', value: '0' },
  { label: 'Ontem', value: '0' },
  { label: '7 dias', value: '7' },
  { label: '30 dias', value: '30' },
  { label: '90 dias', value: '90' },
  { label: '1 ano', value: '365' }
]

const useStyles = makeStyles((theme) => ({
  root: {},
  dates: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
        marginBottom: theme.spacing(2)
      }
    }
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  },
  chip: {
    marginRight: theme.spacing(1)
  },
  calendarTodayGroup: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(1)
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1)
    }
  }
}))

function useSearchQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const Header = () => {
  const classes = useStyles()
  const querySearch = useSearchQuery()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [selectedEvento, setSelectedEvento] = useState<Evento | null>(null)
  const [selectEdge, setSelectEdge] = useState<null | 'start' | 'end'>(null)
  const [calendarDate, setCalendarDate] = useState(new Date())
  const [dateRange, setDateRange] = useState({
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
    value: '0'
  })
  const [filters, setFilters] = useState({
    textSearch: querySearch.get('Term') || '',
    selectedStatusPedidoId: querySearch.get('StatusPedidoId') || '0',
    selectedFormaPagamentoId: querySearch.get('FormaPagamentoId') || '0',
    dataHoraInicio: querySearch.get('DataHoraInicio') || '',
    dataHoraFim: querySearch.get('DataHoraFim') || '',
  })
  const [showPastEvents, setShowPastEvents] = useState(false)
  const estabelecimentoId = useSelector(
    // @ts-ignore: //ainda não foi tipado o redux state
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const {
    data: events,
    isLoading: isLoadingEvents,
    isFetching: isFetchingEvents
  } = useEvents(estabelecimentoId, showPastEvents, isMobile)

  const runQuery = () => {
    history.push(
      `/pedidos/admin?StatusPedidoId=${
        filters.selectedStatusPedidoId
      }&FormaPagamentoId=${
        filters.selectedFormaPagamentoId
          ? filters.selectedFormaPagamentoId
          : 0
      }&Term=${filters.textSearch
      }&DataHoraInicio=${format(dateRange.startDate, 'yyyy/MM/dd HH:mm:ss')
      }&DataHoraFim=${format(dateRange.endDate, 'yyyy/MM/dd HH:mm:ss')
      }&EventoId=${selectedEvento && selectedEvento.id ? selectedEvento.id : ''}`
    )
  }
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)

  const handleCalendarOpen = (edge: string) => {
    setSelectEdge(edge as 'start' | 'end')
  }

  const handleCalendarChange = (date: Date) => {
    setCalendarDate(date)
  }

  const handleCalendarClose = () => {
    setCalendarDate(new Date())
    setSelectEdge(null)
  }

  const handleCalendarAccept = (date: Date) => {
    setCalendarDate(new Date())

    if (selectEdge === 'start') {
      setDateRange({ ...dateRange, startDate: startOfDay(date) })

      if (isAfter(date, dateRange.endDate)) {
        setDateRange({ ...dateRange, endDate: endOfDay(date) })
      }
    } else {
      setDateRange({ ...dateRange, endDate: endOfDay(date) })

      if (isBefore(date, dateRange.startDate)) {
        setDateRange({ ...dateRange, startDate: startOfDay(date) })
      }
    }

    setFilters((prev) => ({
      ...prev,
      dataHoraInicio: format(dateRange.startDate, 'yyyy/MM/dd HH:mm:ss'),
      dataHoraFim: format(dateRange.endDate, 'yyyy/MM/dd HH:mm:ss')
    }))
    setSelectEdge(null)
  }

  const handleRangeChangeChip = (item: ChipType) => {
    if (item.label === 'Ontem') {
      setDateRange({
        startDate: startOfYesterday(),
        endDate: endOfYesterday(),
        value: item.value
      })
    } else {
      setDateRange({
        startDate: startOfDay(subDays(new Date(), Number(item.value))),
        endDate: endOfDay(new Date()),
        value: item.value
      })
    }
    setFilters((prev) => ({
      ...prev,
      dataHoraInicio: format(dateRange.startDate, 'yyyy/MM/dd HH:mm:ss'),
      dataHoraFim: format(dateRange.endDate, 'yyyy/MM/dd HH:mm:ss') }))
  }

  const open = Boolean(selectEdge)

  useEffect(() => {
    runQuery()
  }, [dateRange.startDate, dateRange.endDate])

  useEffect(() => {
    const eventId = querySearch.get('EventoId') || ''
    if (eventId && Number(eventId) > 0) {
      const event =
        events &&
        events.length > 0 &&
        events.find((e) => e.id === Number(eventId))
      if (event) {
        setSelectedEvento(event)
      } else if (selectedEvento === null) {
        setShowPastEvents(!showPastEvents)
      }
    }
  }, [events])

  useDidMountEffect(() => {
    const eventId = querySearch.get('EventoId') || ''
    if (eventId && Number(eventId) > 0) {
      const event =
        events &&
        events.length > 0 &&
        events.find((e) => e.id === Number(eventId))

      if (event) {
        setSelectedEvento(event)
      }
    } else {
      setSelectedEvento(null)
    }

    setFilters((prev) => ({
      ...prev,
      selectedStatusPedidoId: querySearch.get('StatusPedidoId') || '0',
      selectedFormaPagamentoId: querySearch.get('FormaPagamentoId') || '',
      textSearch: querySearch.get('Term') || '',
      dataHoraInicio: querySearch.get('DataHoraInicio') || '',
      dataHoraFim: querySearch.get('DataHoraFim') || ''
    }))
  }, [querySearch])

  useDidMountEffect(() => {
    runQuery()
  }, [dateRange.startDate, dateRange.endDate])

  return (
    <div className={classes.root}>
      <Grid container justify="space-between" spacing={3}>
        <Grid item md={5} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Gerência de Pedidos
          </Typography>
          <Typography component="h1" gutterBottom variant="h3">
            Lista de Pedidos
          </Typography>
        </Grid>
        <Grid className={classes.dates} item md={7} xs={12}>
          {chipOptions &&
            chipOptions.length > 0 &&
            chipOptions.map((option) => (
              <Chip
                className={classes.chip}
                key={option.value}
                label={option.label}
                clickable
                onClick={() => handleRangeChangeChip(option)}
                color={
                  option.value != '0' &&
                  differenceInDays(dateRange.endDate, dateRange.startDate) ===
                    Number(option.value)
                    ? 'primary'
                    : (option.label === 'Hoje' &&
                        format(dateRange.startDate, 'dd/MM/yyyy') ===
                          format(today, 'dd/MM/yyyy')) ||
                      (option.label === 'Ontem' &&
                        format(dateRange.startDate, 'dd/MM/yyyy') ===
                          format(yesterday, 'dd/MM/yyyy') &&
                        format(dateRange.endDate, 'dd/MM/yyyy') ===
                          format(yesterday, 'dd/MM/yyyy'))
                    ? 'primary'
                    : 'default'
                }
              />
            ))}
          <ButtonGroup
            variant="contained"
            className={classes.calendarTodayGroup}
          >
            <Button onClick={() => handleCalendarOpen('start')}>
              <CalendarTodayIcon className={classes.calendarTodayIcon} />
              {dateRange && format(dateRange.startDate, 'dd/MM/yyyy')}
            </Button>
            <Button onClick={() => handleCalendarOpen('end')}>
              <CalendarTodayIcon className={classes.calendarTodayIcon} />
              {dateRange && format(dateRange.endDate, 'dd/MM/yyyy')}
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <DatePicker
        disableFuture
        // @ts-ignore
        onAccept={handleCalendarAccept}
        // @ts-ignore
        onChange={handleCalendarChange}
        onClose={handleCalendarClose}
        open={open}
        style={{ display: 'none' }} // Temporal fix to hide the input element
        value={calendarDate}
        variant="dialog"
        cancelLabel="Cancelar"
        okLabel="Definir"
      />
    </div>
  )
}

export default Header
