import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Divider,
  CircularProgress
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { usuarioAction } from '_actions'
import { phoneMask } from 'utils/masks'
import constants from 'theme/constants'

const useStyles = makeStyles(() => ({
  root: {
    ...constants.shadowCard
  }
}))

const GeneralSettings = (props) => {
  const { className, ...rest } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const usuario = useSelector((state) => state.authentication.user)
  const { loading } = useSelector((state) => state.usuario)

  const [values, setValues] = useState({
    nome: '',
    telefone: ''
  })
  const [editable, setEditable] = useState(false)

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidPhone', (value) => {
      if (value !== null && value) {
        if (value.length > 0 && value.length < 14) {
          return false
        }
        return true
      }
      return true
    })
  }, [])

  useEffect(() => {
    // antes de desmontar o componente
    return () => {
      ValidatorForm.removeValidationRule('isValidPhone')
    }
  }, [])

  useEffect(() => {
    setValues({
      ...values,
      nome: usuario.nome,
      telefone: usuario.telefone
    })
  }, [usuario])

  const handleChange = (event) => {
    event.persist()

    setValues({
      ...values,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    })
  }

  const handleClickEdit = (event) => {
    event.preventDefault()
    setEditable(!editable)
  }
  const handleClickCancel = (event) => {
    event.preventDefault()
    setEditable(!editable)
    setValues({
      ...values,
      nome: usuario.nome,
      telefone: usuario.telefone
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    let payload = {
      ...usuario,
      nome: values.nome,
      telefone:
        values.telefone !== null &&
        values.telefone !== undefined &&
        values.telefone !== ''
          ? values.telefone.replace(/\D/g, '')
          : values.telefone
    }
    dispatch(usuarioAction.editUsuarioInfo(usuario.id, payload))
    setEditable(false)
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <ValidatorForm className={classes.form} onSubmit={handleSubmit}>
        <CardHeader title="Perfil" />
        <Divider />
        <CardContent>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <TextValidator
                autoComplete="nome"
                name="nome"
                variant="outlined"
                fullWidth
                value={values.nome}
                onChange={handleChange}
                id="nome"
                disabled={!editable}
                label="Nome completo *"
                validators={['required']}
                errorMessages={['Campo obrigatório!']}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                variant="outlined"
                fullWidth
                id="telefone"
                disabled={!editable}
                label="Telefone"
                name="telefone"
                value={phoneMask(values.telefone)}
                onChange={handleChange}
                autoComplete="telefone"
                validators={['isValidPhone']}
                errorMessages={['Telefone inválido!']}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          {editable ? (
            <>
              <Button type="submit" color="primary" variant="outlined">
                {loading ? 'Enviando informações...' : 'Salvar Alterações'}
              </Button>
              <Button
                onClick={handleClickCancel}
                variant="outlined"
                color="secondary"
              >
                Cancelar
              </Button>
            </>
          ) : (
            <Button
              onClick={handleClickEdit}
              color="primary"
              variant="outlined"
              disabled={loading}
              endIcon={
                loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )
              }
            >
              {loading ? 'Enviando informações... ' : 'Editar Informações'}
            </Button>
          )}
        </CardActions>
      </ValidatorForm>
    </Card>
  )
}

GeneralSettings.propTypes = {
  className: PropTypes.string
}

export default GeneralSettings
