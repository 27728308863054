import React from 'react'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  Button,
  makeStyles
} from '@material-ui/core'
import AttachMoneyTwoToneIcon from '@material-ui/icons/AttachMoneyTwoTone'
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import FormatListBulletedTwoToneIcon from '@material-ui/icons/FormatListBulletedTwoTone'
import { history } from 'utils'
import { useParams } from 'react-router-dom'
import { AccessControl } from 'components/Utils/AccessControl'
import constants from 'theme/constants'
import { useEventDashboard } from 'hooks-querys'
var pluralize = require('pluralize')

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    ...constants.shadowCard
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: '#FFFFFF',
    color: theme.palette.primary.main,
    height: 46,
    width: 46
  },
  icon: {
    height: 22,
    width: 22
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(-1),
    borderColor: '#FFFFFF',
    color: '#FFFFFF',
    '&:hover': {
      borderColor: '#FFFFFF'
    }
  }
}))

const IngressosVendidos = () => {
  const classes = useStyles()
  const { eventoId } = useParams<{ eventoId: string }>()
  const { data, isFetching } = useEventDashboard(Number(eventoId))

  const { quantidadeIngressos, valorIngressos } = data || {}
  // @ts-ignore
  const { showValues } = useSelector((state) => state.global)
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid direction="row" container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="inherit"
              gutterBottom
              variant="body2"
            >
              TOTAL VENDIDO
            </Typography>
            <Typography color="inherit" variant="h3">
              {isFetching || !showValues ? (
                <Skeleton animation={isFetching ? 'pulse' : false} />
              ) : (
                Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(valorIngressos || 0)
              )}
            </Typography>
            <Typography color="inherit" variant="body2">
              {isFetching || !showValues ? (
                <Skeleton animation={isFetching ? 'pulse' : false} />
              ) : (
                pluralize('INGRESSOS', quantidadeIngressos, true)
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <AttachMoneyTwoToneIcon className={classes.icon} />
            </Avatar>
          </Grid>
          <Grid item xs={12}>
            <AccessControl
              rule={'ingressos.list'} //permissão necessária para acessar conteúdo
              // @ts-ignore
              yes={() => (
                <Button
                  size="small"
                  className={classes.button}
                  variant="outlined"
                  color="secondary"
                  startIcon={<FormatListBulletedTwoToneIcon />}
                  onClick={() =>
                    history.push(
                      `/ingressos/lista?eventId=${eventoId}&textSearch=&statusId=0`
                    )
                  }
                >
                  VER LISTA DE INGRESSOS
                </Button>
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default IngressosVendidos
