import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Avatar,
  Typography,
  Button,
  colors,
  Grid,
  CircularProgress,
  Backdrop
} from '@material-ui/core'
import AddPhotoIcon from '@material-ui/icons/AddPhotoAlternate'
import { useSelector, useDispatch } from 'react-redux'
import CloudUploadTwoToneIcon from '@material-ui/icons/CloudUploadTwoTone'
import ImageUploading from 'react-images-uploading'
import { uploadImage } from '_services'
import { estabelecimentoAction } from '_actions'
import { errorsUploadImage } from 'utils'

const useStyles = makeStyles((theme) => ({
  root: {},
  cover: {
    position: 'relative',
    height: 360,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    '&:before': {
      position: 'absolute',
      content: '" "',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      backgroundImage:
        'linear-gradient(-180deg, rgba(0,0,0,0.00) 58%, rgba(0,0,0,0.32) 100%)'
    },
    '&:hover': {
      '& $changeButton': {
        visibility: 'visible'
      }
    }
  },
  coverDragging: {
    height: 360
  },
  changeButton: {
    visibility: 'hidden',
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    backgroundColor: colors.blueGrey[900],
    color: theme.palette.white,
    [theme.breakpoints.down('md')]: {
      top: theme.spacing(3),
      bottom: 'auto'
    },
    '&:hover': {
      backgroundColor: colors.blueGrey[900]
    }
  },
  addPhotoIcon: {
    marginRight: theme.spacing(1)
  },
  container: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    padding: theme.spacing(2, 3),
    margin: '0 auto',
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  avatar: {
    border: `2px solid ${theme.palette.white}`,
    height: 120,
    width: 120,
    top: -60,
    left: theme.spacing(3),
    position: 'absolute',
    boxShadow: '0px 0px 7px 6px rgba(0,0,0,0.12)',
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px'
  },
  details: {
    marginLeft: 136,
    [theme.breakpoints.down('xs')]: {
      maxWidth: theme.breakpoints.values.sm,
      marginLeft: 0,
      marginTop: 60
    }
  },
  actions: {
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1)
    },
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  pendingButton: {
    color: theme.palette.white,
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[900]
    }
  },
  personAddIcon: {
    marginRight: theme.spacing(1)
  },
  mailIcon: {
    marginRight: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const Header = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()

  const { estabelecimento } = useSelector((state) => ({
    estabelecimento: state.estabelecimento
  }))
  const [images, setImages] = useState([])
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const onChange = (imageList) => {
    // data for submit
    setOpen(true)
    setImages(imageList)
    uploadImage(imageList[0])
      .then((response) => {
        let payload = {
          ...estabelecimento,
          banner: response
        }
        if (estabelecimento.id !== 0) {
          dispatch(
            estabelecimentoAction.editEstabelecimentoInfo(
              estabelecimento.id,
              payload
            )
          )
        }
        dispatch(estabelecimentoAction.onChangeProps('banner', response))
        setOpen(false)
      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Backdrop className={classes.backdrop} open={open}>
        <Grid
          container
          spacing={3}
          direction="column"
          justify="center"
          alignItems="center"
          alignContent="center"
          wrap="nowrap"
        >
          <Grid item>
            <CircularProgress color="primary" />
          </Grid>
          <Grid item>
            <Typography variant="h4" color="white">
              Enviando imagem...
            </Typography>
          </Grid>
        </Grid>
      </Backdrop>
      <ImageUploading
        maxFileSize={2000000}
        onError={(errors) => errorsUploadImage(errors)}
        value={images}
        onChange={onChange}
        dataURLKey="data_url"
      >
        {({ onImageUpload, isDragging, dragProps }) => (
          <div
            {...dragProps}
            className={classes.cover}
            style={
              isDragging
                ? {}
                : { backgroundImage: `url("${estabelecimento.banner}")` }
            }
          >
            {isDragging && (
              <Grid
                className={classes.coverDragging}
                container
                spacing={3}
                direction="column"
                justify="center"
                alignItems="center"
                alignContent="center"
              >
                <Grid item>
                  <CloudUploadTwoToneIcon
                    color="primary"
                    style={{ fontSize: 80 }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h3" color="initial">
                    Solte a imagem aqui!
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Button
              className={classes.changeButton}
              onClick={onImageUpload}
              variant="contained"
            >
              <AddPhotoIcon className={classes.addPhotoIcon} />
              {estabelecimento.banner !== null
                ? 'Trocar capa'
                : 'Adicionar capa'}
            </Button>
          </div>
        )}
      </ImageUploading>
      <div className={classes.container}>
        <Avatar
          variant="rounded"
          alt="Person"
          className={classes.avatar}
          src={estabelecimento.imagem}
        />
        <div className={classes.details}>
          <Typography noWrap component="h2" gutterBottom variant="overline">
            @{estabelecimento.username}
          </Typography>
          <Typography noWrap component="h1" variant="h4">
            {estabelecimento.nome}
          </Typography>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string
}

export default Header
