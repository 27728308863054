import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  Typography,
  Grid,
  CardHeader,
  Tooltip,
  IconButton
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import CachedRoundedIcon from '@material-ui/icons/CachedRounded'
import { useDispatch, useSelector } from 'react-redux'
import { dashboardAction } from '_actions'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    ...constants.shadowCard
  },
  content: {
    padding: 0
  },
  item: {
    padding: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  h2: {
    paddingTop: theme.spacing(1)
  },
  header: {
    marginBottom: theme.spacing(-7)
  }
}))

const Statistics = (props) => {
  const { className, dashboardHome, isLoading, ...rest } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const { estabelecimentoId } = useSelector(
    (state) => state.usuarioEstabelecimento
  )
  const handleClickRefresh = () => {
    if (estabelecimentoId !== '') {
      dispatch(dashboardAction.getDashboardHome(estabelecimentoId))
    }
  }
  const { showValues } = useSelector((state) => state.global)
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        className={classes.header}
        action={
          <Tooltip title="Atualizar">
            <IconButton
              disabled={isLoading || estabelecimentoId === ''}
              onClick={handleClickRefresh}
              edge="end"
              size="small"
            >
              <CachedRoundedIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <Grid alignItems="center" container justify="space-between">
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Typography gutterBottom variant="overline">
            Pedidos aguardando pagamento
          </Typography>
          <Typography className={classes.h2} variant="h2">
            {isLoading || !showValues ? (
              <Skeleton animation={isLoading ? 'pulse' : 'false'} />
            ) : (
              dashboardHome.quantidadePedidosPendentes
            )}
          </Typography>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Typography gutterBottom variant="overline">
            Pedidos realizados últimos 7 dias
          </Typography>
          <Typography className={classes.h2} variant="h2">
            {isLoading || !showValues ? (
              <Skeleton animation={isLoading ? 'pulse' : 'false'} />
            ) : (
              dashboardHome.quantidadePagamentos7Dias
            )}
          </Typography>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Typography gutterBottom variant="overline">
            Valor recebido últimos 7 dias
          </Typography>
          <Typography className={classes.h2} variant="h2">
            {isLoading || !showValues ? (
              <Skeleton animation={isLoading ? 'pulse' : 'false'} />
            ) : (
              Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(dashboardHome.valorPagamentos7Dias)
            )}
          </Typography>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Typography gutterBottom variant="overline">
            Eventos ativos
          </Typography>
          <Typography className={classes.h2} variant="h2">
            {isLoading || !showValues ? (
              <Skeleton animation={isLoading ? 'pulse' : 'false'} />
            ) : (
              dashboardHome.eventosAtivos
            )}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  )
}

Statistics.propTypes = {
  className: PropTypes.string,
  dashboardHome: PropTypes.object.isRequired
}

export default Statistics
