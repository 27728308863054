import React from 'react'
import clsx from 'clsx'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableRow,
  TableCell,
  Typography,
  colors,
  TableBody,
  Avatar
} from '@material-ui/core'

import CircularProgress from './components/CircularProgress'
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import { formatCurrency, getInitials } from 'utils'
import constants from 'theme/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    ...constants.shadowCard
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  emptyContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      minHeight: 532
    }
  },
  textEmptyContent: {
    marginTop: theme.spacing(2)
  },
  inner: {
    minWidth: 500
  },
  details: {
    display: 'flex',
    alignItems: 'center'
  },
  image: {
    marginRight: theme.spacing(2),
    flexShrink: 0,
    height: 56,
    width: 56,
    borderRadius: '50%'
  },
  subscriptions: {
    fontWeight: theme.typography.fontWeightMedium
  },
  price: {
    whiteSpace: 'nowrap'
  },
  value: {
    color: colors.green[600],
    fontWeight: theme.typography.fontWeightMedium
  },
  conversion: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  conversionStats: {
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1)
  }
}))

const MostProfitableOrganizes = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const { isLoading } = useSelector((state) => state.dashboard)
  const { showValues } = useSelector((state) => state.global)
  const { estabelecimentosMaisRentaveis } = useSelector(
    (state) => state.dashboard.dashboardAdmin
  )
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Estabelecimentos mais rentáveis" />
      <Divider />
      {estabelecimentosMaisRentaveis &&
      estabelecimentosMaisRentaveis.length > 0 ? (
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableBody>
                  {estabelecimentosMaisRentaveis.map(
                    (estabelecimentoObject) => (
                      <TableRow
                        hover
                        key={estabelecimentoObject.estabelecimento.id}
                      >
                        <TableCell>
                          <div className={classes.details}>
                            <Avatar
                              alt="Imagem de perfil do estabelecimento"
                              className={classes.image}
                              src={estabelecimentoObject.estabelecimento.imagem}
                            >
                              {getInitials(
                                estabelecimentoObject.estabelecimento.nome
                              )}
                            </Avatar>
                            <div>
                              <Typography variant="h6">
                                {estabelecimentoObject.estabelecimento.nome}
                              </Typography>
                              <Typography variant="subtitle2">
                                <span className={classes.subscriptions}>
                                  @
                                  {
                                    estabelecimentoObject.estabelecimento
                                      .username
                                  }
                                </span>
                              </Typography>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h6">Valor total</Typography>
                          <Typography
                            className={classes.price}
                            variant="subtitle2"
                          >
                            {showValues && !isLoading ? (
                              <span className={classes.value}>
                                {formatCurrency(
                                  estabelecimentoObject.valorTotal
                                )}
                              </span>
                            ) : (
                              <Skeleton
                                animation={isLoading ? 'pulse' : 'false'}
                                width="100%"
                              />
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <div className={classes.conversion}>
                            <div className={classes.conversionStats}>
                              <Typography align="right" variant="h6">
                                {showValues && !isLoading ? (
                                  `${estabelecimentoObject.percentualLucroLiquido.toLocaleString(
                                    'pt-BR',
                                    {
                                      maximumFractionDigits: 2
                                    }
                                  )}%`
                                ) : (
                                  <Skeleton
                                    animation={isLoading ? 'pulse' : 'false'}
                                    width="100%"
                                  />
                                )}
                              </Typography>
                              <Typography variant="subtitle2">
                                Lucro liquido
                              </Typography>
                            </div>
                            {showValues && !isLoading ? (
                              <CircularProgress
                                value={
                                  estabelecimentoObject.percentualLucroLiquido
                                }
                              />
                            ) : (
                              <Skeleton
                                variant="circle"
                                animation={isLoading ? 'pulse' : 'false'}
                                width={56}
                                height={56}
                              />
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
      ) : (
        <CardContent className={classes.emptyContent}>
          <Typography className={classes.textEmptyContent} variant="h5">
            Não há dados para exibir
          </Typography>
        </CardContent>
      )}
      {/* <Divider /> */}
      {/* <CardActions className={classes.actions}>
        <Button
          color="primary"
          component={RouterLink}
          size="small"
          to="#"
          variant="text"
        >
          Ver todos
          <ArrowForwardIcon className={classes.arrowForwardIcon} />
        </Button>
      </CardActions> */}
    </Card>
  )
}

MostProfitableOrganizes.propTypes = {
  className: PropTypes.string
}

export default MostProfitableOrganizes
