import React from 'react'
import { Redirect, useParams } from 'react-router-dom'
import ListRoundedIcon from '@material-ui/icons/ListRounded'
import {
  Tabs,
  Tab,
  Divider,
  colors,
  makeStyles,
  useTheme,
  useMediaQuery
} from '@material-ui/core'
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone'
import PieChartTwoToneIcon from '@material-ui/icons/PieChartTwoTone'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone'
import { Page, Header } from 'components'
import { CuponsDescontoList } from './CuponsDescontoList'
import CupomDescontoEdit from './CupomDescontoEdit'
import CuponsDescontoUtilizacao from './CuponsDescontoUtilizacao/CuponsDescontoUtilizacao'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}))

const CuponsDesconto = (props) => {
  const { match, history } = props
  const { tab } = match.params
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const { idCupomDesconto, eventoId } = useParams()
  const handleTabsChange = (event, value) => {
    history.push(`/cupomDescontos/${value}`)
  }

  const tabs = [
    { value: 'lista', label: 'Lista', icon: <ListRoundedIcon /> },
    {
      value: 'cadastro',
      label: idCupomDesconto ? 'Editar' : 'Cadastrar',
      icon: idCupomDesconto ? <EditTwoToneIcon /> : <AddCircleTwoToneIcon />,
      disabled: idCupomDesconto ? true : false
    },
    {
      value: 'utilizacao',
      label: 'Utilização',
      icon: <PieChartTwoToneIcon />
    }
  ]

  if (!tab) {
    return <Redirect to="/cupomDescontos/lista" />
  }

  if (!tabs.find((t) => t.value === tab)) {
    return <Redirect to="/errors/error-404" />
  }

  return (
    <Page className={classes.root} title="Configurações">
      <Header
        subtitle="GERÊNCIA DE CUPONS DE DESCONTO"
        title={
          eventoId ? 'Cupons de desconto por evento' : 'Cupons de desconto'
        }
      />
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        variant={isMobile ? 'fullWidth' : 'standard'}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            icon={tab.icon}
            disabled={tab.disabled}
          />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {tab === 'lista' && <CuponsDescontoList />}
        {tab === 'cadastro' && <CupomDescontoEdit />}
        {tab === 'utilizacao' && <CuponsDescontoUtilizacao />}
      </div>
    </Page>
  )
}

export default CuponsDesconto
