import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  dialogContent: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  card: {
    width: '5.4cm',
    height: '8.5cm',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '0.45cm',
    overflow: 'hidden',
    border: `1px solid ${theme.palette.divider}`
  },
  avatar: {
    backgroundColor: theme.palette.background.paper,
    padding: '0.05cm',
    height: '1.5cm',
    width: '1.5cm'
  },
  header: {
    height: '2.5cm',
    backgroundColor: 'transparent',
    color: theme.palette.background.paper,
    padding: '0.2cm',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  hole: {
    zIndex: 9999,
    width: '1cm',
    height: '0.3cm',
    marginBottom: '0.2cm',
    borderRadius: '0.2cm',
    backgroundColor: theme.palette.background.paper
  },
  content: {
    padding: '0.25cm'
  },
  title: {
    fontSize: '0.28cm'
  },
  subtitle: { fontSize: '0.28cm' },
  divider: {
    margin: '0.25cm 0'
  },
  logo: {
    height: '0.5cm',
    width: 'auto'
  }
}))
