import { useQuery } from 'react-query'

import { crudService } from '_services'
import { Estado } from '../../types/api'

const getStates = async () => {
  const { data } = await crudService.get(`estados`)
  return data
}

export default function useCountryStates() {
  return useQuery<Estado[]>(['states-list'], () => getStates())
}
